import { EolasIcons } from "Assets";
import { Button, Avatar, Text } from "UIKit";
import { ButtonColorScheme, ButtonVariant } from "UIKit/Button/types";

interface UserItemProps {
  email: string;
  notAssignable?: boolean;
  actionButton?: {
    buttonText: string;
    color?: string | undefined;
    backgroundColor?: ButtonColorScheme;
    iconLeft?: EolasIcons | null | undefined;
    onClick?: () => void;
    disabled?: boolean;
    variant?: ButtonVariant;
  };
  renderUserAvatar?: () => React.ReactNode;
}

export const UserItem: React.FC<UserItemProps> = ({
  email,
  actionButton,
  notAssignable = false,
  renderUserAvatar,
}: UserItemProps) => {
  return (
    <div
      className={`flex flex-1 justify-between p-3 text-base font-bold border-b border-blue-50${
        notAssignable ? " text-grey-500 rounded bg-grey-100" : ""
      }`}
    >
      {renderUserAvatar ? renderUserAvatar() : <Avatar label={email} />}
      {actionButton ? (
        <div className="flex items-center">
          <Button
            type="submit"
            variant={actionButton.variant || "solid"}
            disabled={notAssignable}
            style={{ borderRadius: "3rem" }}
            iconLeft={actionButton.iconLeft ? actionButton.iconLeft : null}
            color={actionButton.backgroundColor ? actionButton.backgroundColor : undefined}
            className={`h-10 sm:h-10 px-8 mr-1" ${
              actionButton.color ? `text-${actionButton.color}` : "text-blue-500 border-blue-500"
            }`}
            onClick={actionButton.onClick}
            data-testid="assign-user"
          >
            <Text level={1} className={`${actionButton.iconLeft ? "ml-2" : ""}`}>
              {actionButton.buttonText}
            </Text>
          </Button>
        </div>
      ) : null}
    </div>
  );
};
