import { CheckboxEmptyIcon, CheckboxTickedIcon } from "Assets";
import { useOverrideClick } from "Hooks/useOverrideClick";

export type SelectFileToggleProps = {
  isSelected: boolean;
  onCheckboxClick: () => void;
};

// TODO: Future PR refactor to new design
export const SelectFileToggle = ({ isSelected, onCheckboxClick }: SelectFileToggleProps) => {
  const onClickToggle = () => {
    if (onCheckboxClick) {
      onCheckboxClick();
    }
  };

  const handleClick = useOverrideClick(onClickToggle);

  return (
    <button className="bg-transparent border-none p-2" onClick={handleClick}>
      {isSelected ? (
        <CheckboxTickedIcon width={18} height={18} />
      ) : (
        <CheckboxEmptyIcon width={18} height={18} />
      )}
    </button>
  );
};
