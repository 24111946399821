import { useQueryClient } from "@tanstack/react-query";
import medicationsKeys from "./medications.queryKeys";

const useRefreshMedicationsHighlights = () => {
  const queryClient = useQueryClient();

  const onRefreshMedicationsHighlights = async () => {
    return queryClient.refetchQueries(medicationsKeys.highlights());
  };

  return { onRefreshMedicationsHighlights };
};

export default useRefreshMedicationsHighlights;
