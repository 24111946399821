import { uploadFileToPresignedS3, uploadFileToS3 } from "API/app.actions";
import { contentClient, getPreSignUpload, hasStringProp, sectionStore } from "@eolas-medical/core";
import { getBlobType, getFileName } from "Utilities/fileHelpers";
import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useS3FileUpload = () => async (key: string, file: Blob, onUploadProgress?: any) => {
  if (!key.startsWith("public")) {
    key = "public/" + key;
  }
  const preSignUploadData = await getPreSignUpload(key);
  await uploadFileToS3(preSignUploadData, file, onUploadProgress);
};

export const useS3FileUploadV2 =
  () =>
  async ({
    file,
    onUploadProgress,
    isPublic,
    isOrganisationFile,
    mainSectionId,
  }: {
    file: File | Blob;
    onUploadProgress?: (progress: number) => void;
    isPublic?: boolean;
    isOrganisationFile?: boolean;
    mainSectionId?: string;
  }) => {
    const sectionType = mainSectionId
      ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionId)
      : undefined;

    const { mediaId, uploadUrl, publicUrl } = await contentClient.getUploadPresignedUrl({
      fileName: hasStringProp(file, "name") ? getFileName(file) : uuidv4(),
      fileType: getBlobType(file.type),
      organisationId: sectionStore.organisationID,
      sectionType: sectionType || "otherSectionType",
      spaceId: sectionStore.appID,
      size: file.size,
      isPublic,
      isOrganisationFile,
    });
    await uploadFileToPresignedS3(uploadUrl, file, onUploadProgress);

    return { mediaId, publicUrl };
  };
