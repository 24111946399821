import React from "react";

import { CheckIcon, CircleIcon } from "Assets/Icons";

export interface InputTypeButtonProps {
  children: React.ReactNode;
  label?: string;
  className?: string;
  isSelected: boolean;
  isDisabled?: boolean;
  icon: React.ReactNode;
  onClick(): void;
}

const iconSize = { width: 20, height: 20 };

export const InputTypeButton: React.FC<InputTypeButtonProps> = ({
  icon,
  onClick,
  children,
  isSelected,
  isDisabled,
  className = "",
  ...props
}: InputTypeButtonProps) => {
  return (
    <button
      type="button"
      onClick={isDisabled ? undefined : onClick}
      className={`
        flex items-center h-16 px-2 sm:px-4 space-x-2 sm:space-x-4
        rounded-md border-2 border-grey-300 hover:bg-grey-200
        ${isDisabled ? "bg-grey hover:bg-grey cursor-not-allowed" : "bg-white"}
        ${className}
      `}
      {...props}
    >
      {icon}

      <div className="flex justify-start flex-1 text-base font-semibold ">{children}</div>

      {isSelected ? (
        <CheckIcon className="text-green" {...iconSize} />
      ) : (
        <CircleIcon {...iconSize} />
      )}
    </button>
  );
};
