import { copilotClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { useLaunchDarkly } from "Contexts";
import { getEntityTypeBasedOnFlag } from "../functions/getEntityTypeBasedOnFlag";

interface CopilotSuggestionsRestResponse {
  suggestions: string[];
}

const defaultResponse: CopilotSuggestionsRestResponse = {
  suggestions: [],
};

const useCopilotSuggestedSearch = ({
  searchValue,
  knowledgeSearchDisabled,
}: {
  searchValue: string;
  knowledgeSearchDisabled?: boolean;
}) => {
  const { flags } = useLaunchDarkly();
  const entityType = getEntityTypeBasedOnFlag(flags);

  const queryFn = (searchInput: string) =>
    copilotClient.getSemanticSearchSuggestions({
      query: searchInput,
      limit: 2,
      entityType,
    });

  const { data, isFetching } = useQuery<CopilotSuggestionsRestResponse>(
    ["knowledgeSuggestedSearch", { searchValue }],
    () => queryFn(searchValue),
    {
      staleTime: 60000,
      enabled: !knowledgeSearchDisabled && searchValue.length >= 2,
      initialData: defaultResponse,
    },
  );

  return { suggestedSearch: data.suggestions, loadingSuggestedSearch: isFetching };
};

export default useCopilotSuggestedSearch;
