import React from "react";
import { success } from "Assets";
import { FormError } from "UIKit/FormElements";
import { LottieWithHeader } from "UIKit/LottieWithHeader";
import { ProgressBar } from "UIKit/ProgressBar";

interface UploadProgressAndSuccessStepProps {
  fileUploading: boolean;
  uploadProgress: number;
  fileUploadError: string | undefined;
  fileUploadedSuccess: boolean;
  onFinishUpload: () => void;
}

export const UploadProgressAndSuccessStep: React.FC<UploadProgressAndSuccessStepProps> = ({
  fileUploading,
  uploadProgress,
  fileUploadError,
  fileUploadedSuccess,
  onFinishUpload,
}: UploadProgressAndSuccessStepProps) => {
  return (
    <div>
      {fileUploading && <ProgressBar progress={uploadProgress} />}

      {fileUploadError && <FormError error={fileUploadError} />}

      {fileUploadedSuccess && (
        <LottieWithHeader
          text=""
          animation={success}
          onComplete={onFinishUpload}
          data-testid="success-animation"
        />
      )}
    </div>
  );
};
