import { useTranslation } from "react-i18next";
import { KnowledgeHubIcon, LocalIcon } from "Assets";
import { SearchTypeSection } from "Components/MasterSearch/types";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import { useMediaQuery } from "Hooks";

interface FilterPillsProps {
  selectedPill: SearchTypeSection;
  knowledgeSearchDisabled?: boolean;
  localSearchDisabled?: boolean;
  knowledgeCopilotSearchDisabled?: boolean;
  onClickPill: (searchType: SearchTypeSection) => void;
}

const FilterPills = ({
  selectedPill,
  knowledgeSearchDisabled = false,
  localSearchDisabled = false,
  knowledgeCopilotSearchDisabled = false,
  onClickPill,
}: FilterPillsProps) => {
  const { t } = useTranslation();

  const showPills = !(localSearchDisabled || knowledgeSearchDisabled);

  const activeKnowledge = knowledgeCopilotSearchDisabled ? "knowledge" : "knowledgeCopilot";

  const media = useMediaQuery();
  const isMobile = media === "xs";

  const commonClass = "w-32 text-center cursor-pointer";
  const inactiveClass = "bg-transparent border border-grey-400";
  const activeClass = "bg-blue-500 text-white";

  const pillSize = isMobile ? Sizes.EXTRA_SMALL : Sizes.MEDIUM;

  const pillWidth = isMobile ? "w-full" : "";

  const containerClass = isMobile
    ? "py-4 mt-2 grid grid-cols-2 gap-2"
    : "flex flex-1 space-x-3 justify-center p-4 mt-4";

  const handlePillClick = (searchType: SearchTypeSection) => {
    onClickPill(searchType);
  };

  const isPillActive = (searchType: SearchTypeSection) => {
    if (selectedPill) {
      return selectedPill === searchType;
    }
    return searchType === "local"; // local pill is selected by default when no selectedPill
  };

  return (
    <div className={containerClass} data-testid="master-search-filters">
      {showPills ? (
        <>
          <Pill
            value={t("masterSearch_local")}
            className={`${commonClass} ${
              isPillActive("local") ? activeClass : inactiveClass
            } ${pillWidth}`}
            onClick={() => handlePillClick("local")}
            icon={<LocalIcon className="h-4 w-4" />}
            size={pillSize}
          />
          <Pill
            value={t("masterSearch_knowledge")}
            className={`${commonClass} ${
              isPillActive(activeKnowledge) ? activeClass : inactiveClass
            } ${pillWidth}`}
            onClick={() => handlePillClick(activeKnowledge)}
            icon={<KnowledgeHubIcon className="h-4 w-4" />}
            size={pillSize}
          />
        </>
      ) : null}
    </div>
  );
};

export default FilterPills;
