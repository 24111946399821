import React from "react";
import { InnerModalWrapper } from "UIKit/Modal";
import { Carousel } from "UIKit/Carousel/Carousel";

const CarouselModal = ({
  children,
  onComplete,
}: {
  children: React.ReactNode[];
  onComplete: () => void;
}) => {
  return (
    <InnerModalWrapper className="xl:w-50vw h-95vh max-w-screen-lg">
      <Carousel
        showDots
        showNextButtons
        partialVisible={false}
        showArrowButtons={false}
        centerMode={false}
        onComplete={onComplete}
      >
        {children}
      </Carousel>
    </InnerModalWrapper>
  );
};

export default CarouselModal;
