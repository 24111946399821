import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  EolasFile,
  OrganisationLevelSection,
  eolasLogger,
  sectionStore,
} from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, Modal, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { PatientLeafletQRModal } from "../../components";
import { useSelectPatientLeafletOnMount } from "../../hooks/useSelectPatientLeafletOnMount";
import { NavBackButton } from "shared/components/LogicComponents";
import AddPatientLeafletWizard from "../../components/AddPatientLeafletWizard/AddPatientLeafletWizard";
import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useNotifications } from "Components/Notifications";
import EditPatientLeafletWizard from "../../components/AddPatientLeafletWizard/EditPatientLeafletWizard";

export const OrgPatientLeafletsSection = observer(() => {
  useMainSectionVisit({ mainSectionId: OrganisationLevelSection.orgPatientLeaflets });
  const { isAdmin } = useSpacesContext();
  const { showNotification } = useNotifications();

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { spaceSubSection } = useSpaceSubSection(OrganisationLevelSection.orgPatientLeaflets);
  const { isShareFileEnabled, handleOnShareFile, shareModal, isShareModalOpen, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const onCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    setModal(null);
  };

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

  const mainSectionId = sectionStore.getChildReferenceByMainSectionType(
    OrganisationLevelSection.orgPatientLeaflets,
  )?.id;

  const onAddFile = () => {
    if (!mainSectionId) {
      eolasLogger.error(new Error("Could not find main section for patient leaflets"));
      showNotification({
        type: "error",
        autoHideTimeout: 3000,
        description: t("general_error"),
      });
      return null;
    } else {
      const patientLeafletProps = {
        mainSectionId,
        parentId: spaceSubSection.subSectionId,
        onSuccess: onCloseModal,
        ownerType: EntityOwnerType.ORGANISATION,
      };
      setModal(
        <AddPatientLeafletWizard
          onClose={onCloseModal}
          patientLeafletProps={patientLeafletProps}
        />,
      );
    }
  };
  const onEditFile = (contentItem: EolasFile) => {
    setModal(<EditPatientLeafletWizard contentItem={contentItem} onClose={onCloseModal} />);
  };
  const onDeleteFile = (file: EolasFile) => {
    setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
  };
  const onShowQR = useCallback((file: EolasFile) => {
    setModal(<PatientLeafletQRModal file={file} />);
  }, []);

  useSelectPatientLeafletOnMount({ onSelectFile: onShowQR });

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSubSection.subSectionName ?? ""} />

        <NavBackButton modePreference="removeFromPath" />

        {isAdmin && <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditFile}
          onAddItem={onAddFile}
          onSelectFile={onShowQR}
          onDelete={onDeleteFile}
          mainSectionId={OrganisationLevelSection.orgPatientLeaflets}
          subSectionId={spaceSubSection.subSectionId}
          onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      </InnerPageWrapper>

      <Modal open={isModalOpen} onClose={onCloseModal}>
        {modal || shareModal || removeFavouriteModal}
      </Modal>
    </>
  );
});
