/**
 * Check if the error message is related to the video autoplay error.
 * This is a common error that occurs when trying to autoplay a video without user interaction.
 */
export const isVideoAutoPlayError = (errorMessage: string): boolean => {
  return errorMessage.includes(
    "play() failed because the user didn't interact with the document first.",
  );
};

export function transformLinksToOpenInNewTabs(htmlContent: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const links = doc.querySelectorAll("a");
  links.forEach((link) => link.setAttribute("target", "_blank"));
  return doc.documentElement.outerHTML;
}
