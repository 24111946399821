import { overrideTailwindClasses as tw } from "tailwind-override";
import { useController, ControllerRenderProps, Control } from "react-hook-form";

import { FormLabel } from "./FormLabel";
import { FormFieldError } from "./FormError";
import { Text } from "UIKit/Typography";

interface FormElementChildrenProps {
  error?: string;
  isValid?: boolean;
  className?: string;
}

interface FieldProps extends Omit<ControllerRenderProps, "ref"> {
  id: string;
  className?: string;
}

export interface FormElementProps {
  id: string;
  label?: string;
  className?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  renderError?: boolean;
  errorMessage?: string;
  errorClassName?: string;
  labelClassName?: string;
  warningText?: string;
  children: (fieldProps: FieldProps, props: FormElementChildrenProps) => React.ReactElement;
}

export const FormElement: React.FC<FormElementProps> = ({
  id,
  label,
  control,
  children,
  errorMessage,
  className = "",
  errorClassName = "",
  labelClassName = "",
  required = false,
  warningText,
  ...props
}: FormElementProps) => {
  const {
    field: { ref: _ref, ...field },
    fieldState,
  } = useController({ name: id, control });

  const error = errorMessage || fieldState?.error?.message;
  const isValid = fieldState.isDirty && !fieldState.invalid;
  const inputClassName = error ? "input-error" : "input";

  return (
    <div {...props} className={tw(`flex flex-col ${className}`)}>
      {label ? (
        <FormLabel
          htmlFor={id}
          required={required}
          labelClassName={tw(`font-bold mb-1 ${labelClassName}`)}
        >
          {label}
        </FormLabel>
      ) : null}
      {warningText ? (
        <Text level={1} className={"text-red-600 font-bold"}>
          {warningText}
        </Text>
      ) : null}

      {children(
        {
          ...field,
          id,
          className: inputClassName,
        },
        { error, isValid, className: inputClassName },
      )}
      <FormFieldError className={`mt-1 ${errorClassName}`} data-testid="inputError">
        {error}
      </FormFieldError>
    </div>
  );
};
