import { useReducer } from "react";

export interface MasterSearchState {
  showResults: boolean;
  showRecentSearches: boolean;
  searchText: string;
}

interface Action {
  type: string;
  payload?: { text: string };
}

const initialMasterSearchState: MasterSearchState = {
  showResults: false,
  showRecentSearches: false,
  searchText: "",
};

enum ActionTypes {
  ClickSearchbox = "CLICK_SEARCHBOX",
  ClickRecentSearch = "CLICK_RECENT_SEARCH",
  ClickPill = "CLICK_PILL",
  Clear = "CLEAR",
  Type = "TYPE",
  ClickOutside = "CLICK_OUTSIDE",
  ClickSearch = "CLICK_SEARCH",
}

const reducer = (state: MasterSearchState, action: Action): MasterSearchState => {
  switch (action.type) {
    case ActionTypes.ClickSearchbox:
      return {
        ...state,
        showResults: false,
        showRecentSearches: true,
      };
    case ActionTypes.ClickRecentSearch:
      return {
        showResults: true,
        showRecentSearches: false,
        searchText: action.payload?.text || "",
      };
    case ActionTypes.ClickPill:
      return {
        ...state,
        showResults: false,
        showRecentSearches: true,
      };
    case ActionTypes.Clear:
      return {
        showResults: false,
        showRecentSearches: true,
        searchText: "",
      };
    case ActionTypes.ClickSearch:
      return {
        ...state,
        showResults: true,
        showRecentSearches: false,
      };
    case ActionTypes.Type:
      return {
        ...state,
        showResults: false,
        showRecentSearches: true,
        searchText: action.payload?.text || "",
      };
    case ActionTypes.ClickOutside:
      return {
        ...state,
        showResults: false,
        showRecentSearches: false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useMasterSearchState = (): {
  state: MasterSearchState;
  onClickSearchbox: () => void;
  onClickRecentSearch: (text: string) => void;
  onClickPill: () => void;
  onClear: () => void;
  onClickSearch: () => void;
  onType: (text: string) => void;
  onClickOutside: () => void;
} => {
  const [state, dispatch] = useReducer(reducer, initialMasterSearchState);

  const onClickSearchbox = (): void => {
    dispatch({ type: ActionTypes.ClickSearchbox });
  };

  const onClickRecentSearch = (text: string): void => {
    dispatch({ type: ActionTypes.ClickRecentSearch, payload: { text } });
  };

  const onClickPill = (): void => {
    dispatch({ type: ActionTypes.ClickPill });
  };

  const onClear = (): void => {
    dispatch({ type: ActionTypes.Clear });
  };

  const onClickSearch = (): void => {
    dispatch({ type: ActionTypes.ClickSearch });
  };

  const onType = (text: string): void => {
    dispatch({ type: ActionTypes.Type, payload: { text } });
  };

  const onClickOutside = (): void => {
    dispatch({ type: ActionTypes.ClickOutside });
  };

  return {
    state,
    onClickSearchbox,
    onClickRecentSearch,
    onClickPill,
    onClear,
    onClickSearch,
    onType,
    onClickOutside,
  };
};

export default useMasterSearchState;
