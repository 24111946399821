import {
  AnalyticsEvents,
  BulkCopyItemsResponse,
  BulkCopyItemsRestParams,
  ContentItem,
  CopyItemFailure,
  CopyItemSuccess,
  ShadowCopiesCreatedPayload,
  contentClient,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";
import { isPromiseFulfilledResult, isPromiseRejectedResult } from "shared/functions/typeguards";
import { useMemo } from "react";
import { trackEvent } from "API/Analytics";
import { useRefetchAppData } from "Hooks";

type MutationParams = {
  contentItems: ContentItem[];
  destinationSectionId: string;
};

type Results = {
  wasCompleteFailure: boolean;
  wasCompleteSuccess: boolean;
  hasFailedItems: boolean;
  failedItems: CopyItemFailure[];
  successfulItems: CopyItemSuccess[];
};

const mutationFn = async ({
  contentItems,
  destinationSectionId,
}: MutationParams): Promise<
  | { type: "flat"; data: BulkCopyItemsResponse }
  | { type: "allSettled"; data: PromiseSettledResult<BulkCopyItemsResponse>[] }
> => {
  if (contentItems.length <= 100) {
    const data = await contentClient.bulkCopyContentItems({
      targetSection: destinationSectionId,
      items: contentItems.map(mapContentItemToCopyItemParam),
    });
    return { type: "flat", data };
  }

  const chunks = [];
  const chunkSize = 100;

  for (let i = 0; i < contentItems.length; i += chunkSize) {
    chunks.push(contentItems.slice(i, i + chunkSize));
  }

  const data = await Promise.allSettled(
    chunks.map((chunk) =>
      contentClient.bulkCopyContentItems({
        targetSection: destinationSectionId,
        items: chunk.map(mapContentItemToCopyItemParam),
      }),
    ),
  );

  return { type: "allSettled", data };
};

export const useCopyItems = () => {
  const { refetch } = useRefetchAppData();

  const { mutateAsync, isError, isLoading, error, data, variables } = useMutation({
    mutationFn: mutationFn,
    mutationKey: contentKeys.copy(),
    onSuccess: (result) => {
      let items: BulkCopyItemsResponse = [];
      if (result.type === "allSettled") {
        const fulfilledResults = result.data
          .filter(isPromiseFulfilledResult)
          .map((result) => result.value);
        items = fulfilledResults.flat();
      } else {
        items = result.data;
      }

      const successfulItems: CopyItemSuccess[] = items.filter(isSuccessfulResult);
      const hasSuccessfulItems = successfulItems.length > 0;

      if (hasSuccessfulItems) {
        trackEvent<ShadowCopiesCreatedPayload>(AnalyticsEvents.SHADOW_COPIES_CREATED, {
          numberOfCopies: successfulItems.length,
          fileIds: successfulItems.map((item) => item.value.id),
          destinationSectionId: variables?.destinationSectionId || "",
        });
        refetch();
      }
    },
  });

  const results: Results | null = useMemo(() => {
    const requestItems = variables?.contentItems;
    if (!data || !requestItems) {
      return null;
    }

    let failedResults: CopyItemFailure[] = [];
    let successfulResults: CopyItemSuccess[] = [];
    let didAllChunksReject = false;

    if (data.type === "allSettled") {
      const rejectedPromises = data.data.filter(isPromiseRejectedResult);
      const fulfilledPromises = data.data.filter(isPromiseFulfilledResult);

      didAllChunksReject = rejectedPromises.length === data.data.length;
      const fulfilledResults = fulfilledPromises.map((result) => result.value).flat();

      failedResults = fulfilledResults.filter(isFailedResult);
      successfulResults = fulfilledResults.filter(isSuccessfulResult);
    } else {
      failedResults = data.data.filter(isFailedResult);
      successfulResults = data.data.filter(isSuccessfulResult);
    }

    const wasCompleteFailure = didAllChunksReject || failedResults.length === data.data.length;
    const wasCompleteSuccess = successfulResults.length === data.data.length;

    const failedItems = didAllChunksReject
      ? requestItems.map(
          (item): CopyItemFailure => ({ id: item.id, status: "error", reason: "unknownError" }),
        )
      : failedResults;

    if (wasCompleteFailure) {
      return {
        wasCompleteFailure: true,
        wasCompleteSuccess: false,
        failedItems: failedItems,
        hasFailedItems: true,
        successfulItems: [],
      };
    }

    if (wasCompleteSuccess) {
      return {
        wasCompleteFailure: false,
        wasCompleteSuccess: true,
        failedItems: [],
        hasFailedItems: false,
        successfulItems: successfulResults,
      };
    }

    return {
      wasCompleteFailure: false,
      wasCompleteSuccess: false,
      failedItems: failedItems,
      successfulItems: successfulResults,
      hasFailedItems: failedItems.length > 0,
    };
  }, [data, variables]);

  return {
    copyItems: mutateAsync,
    isLoading,
    isError,
    error,
    results,
  };
};

// Todo: This map will need to be extended to support other types of content items e.g. knowledge items
const mapContentItemToCopyItemParam = (
  contentItem: ContentItem,
): BulkCopyItemsRestParams["items"][number] => ({
  id: contentItem.id,
  type: "file",
  origin: "section",
});

const isSuccessfulResult = (
  result: CopyItemFailure | CopyItemSuccess,
): result is CopyItemSuccess => {
  return result.status === "success";
};

const isFailedResult = (result: CopyItemFailure | CopyItemSuccess): result is CopyItemFailure => {
  return result.status === "error";
};
