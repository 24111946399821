import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { LocationDescriptorObject } from "history";
import { MainSectionBaseTile } from "UIKit/MainSectionTile/MainSectionBaseTile";

import {
  AnalyticsEvents,
  Maybe,
  SponsoredSlot,
  SponsoredSlotPayload,
  eolasLogger,
  knowledgeContentClient,
} from "@eolas-medical/core";
import { makeSponsoredContentRoute } from "../functions/makeSponsoredContentRoute";
import { Megaphone } from "Assets";
import { useMediaQuery, useRunOnMountUnmount } from "Hooks";
import { trackEvent } from "API/Analytics";

export const UserSponsoredSlotTile = ({ slot }: { slot: SponsoredSlot }) => {
  const { id, name, squareBannerUrl, bannerUrl, sponsoredType, url } = slot;

  const { t } = useTranslation();

  const media = useMediaQuery();

  const { data } = useQuery({
    queryFn: () => knowledgeContentClient.getSectionById({ sectionId: slot.id }),
    queryKey: ["getSponsoredSlotSection", slot.id],
    enabled: sponsoredType === "CONTENT",
  });

  const finalBannerUrl = Object.values<string>(["lg", "xl", "2xl"]).includes(media || "lg")
    ? squareBannerUrl
    : bannerUrl;

  let to: Maybe<LocationDescriptorObject> = undefined;
  switch (sponsoredType) {
    case "BANNER":
      break;
    case "URL":
      {
        to = { pathname: url ?? "" };
      }
      break;
    case "CONTENT": {
      if (data) {
        to = makeSponsoredContentRoute(data);
      }
      break;
    }
    default:
      eolasLogger.error(new Error("UserSponsoredSlotTile: unrecognised sponsoredType"), { slot });
  }

  const sponsoredSlotAnalyticsPayload = {
    id,
    name,
    type: sponsoredType,
  };

  useRunOnMountUnmount({
    onMount: () => {
      trackEvent<SponsoredSlotPayload>(
        AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_SEEN,
        sponsoredSlotAnalyticsPayload,
      );
    },
  });

  return (
    <MainSectionBaseTile
      key={id}
      to={to ?? ""}
      isClickDisabled={!to}
      shouldApplyPadding={false}
      isLinkBlankTarget={sponsoredType === "URL"}
      onClick={() => {
        trackEvent<SponsoredSlotPayload>(
          sponsoredType === "URL"
            ? AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_URL_CLICKED
            : AnalyticsEvents.KNOWLEDGE_SPONSORED_SLOT_CONTENT_CLICKED,
          sponsoredSlotAnalyticsPayload,
        );
      }}
    >
      <div className="w-full h-full relative">
        <img
          src={finalBannerUrl}
          srcSet={finalBannerUrl}
          alt=""
          className="w-full h-full object-cover object-center"
        />
        {slot.isPaid ? (
          <div className="bg-black bg-opacity-60 rounded-md px-2 py-1 absolute right-3 xl:right-5 top-3 xl:top-5 z-10 flex flex-row justify-center items-center">
            <Megaphone className="h-4 w-4 mr-1" />
            <span className="text-xs text-white">{t("knowledge_sponsored_badge")}</span>
          </div>
        ) : null}
      </div>
    </MainSectionBaseTile>
  );
};
