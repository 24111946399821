import { useRef, useState } from "react";
import Smartlook from "smartlook-client";

import {
  fileStore,
  userStore,
  sectionStore,
  profilesClient,
  AnalyticsEvents,
  getFilesByMainSectionsAndUpdatedAt,
  contentClient,
  authenticationClient,
  eolasLogger,
  spacesClient,
  getCompletedChecklistsAndIncidentReportFiles,
} from "@eolas-medical/core";

import { useSentry } from "Contexts";
import { trackEvent } from "API/Analytics";

import { AccessRequestStatus, FindSpaceStep, SearchSpaceResult } from "../types";

import { useRequestAccess, useShouldAllowAccess } from "modules/spaces/data";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { addRetries } from "Utilities/helpers";
import { ShouldAllowUserAccessResponse } from "@eolas-medical/core/lib/src/clients/access/access.types";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useNavigateToSpace } from "./useNavigateToSpace";
import { useMutation } from "@tanstack/react-query";

type OnSelectSpaceResponse = "granted" | "denied" | "pending";

export const useSelectSpaceActions = (initialState?: FindSpaceStep, forwardUrl?: string) => {
  const { userID = "" } = userStore;
  const { addBreadcrumb } = useSentry();
  const { onSelectSpace: onSelectSpaceAction } = useSpacesContext();
  const { navigateToSpace } = useNavigateToSpace();

  const { shouldAllowUserAccess, shouldAllowAccessLoading, userAccess, error } =
    useShouldAllowAccess();

  const { requestAccess, requestingAccess, requestAccessSuccessful } = useRequestAccess(userID);

  const [selectSpaceStep, setSelectSpaceStep] = useState<FindSpaceStep>(
    initialState ?? "select-space",
  );

  const selectedAppRef = useRef<SearchSpaceResult>({ appID: "", name: "", orgID: "" });

  const onAllowAccessSuccess = async ({
    status,
    data,
  }: ShouldAllowUserAccessResponse): Promise<OnSelectSpaceResponse> => {
    if (
      status === AccessRequestStatus.EXISTING_APP_USER ||
      status === AccessRequestStatus.APP_USER_CREATED ||
      status === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
    ) {
      await authenticationClient.getToken({ forceRefresh: true });

      const { appUser, sections, app, organisation } = data;

      if (app && appUser) {
        addBreadcrumb({
          category: "Select Organisation",
          message: "Selecting the app and updating the store...",
        });

        try {
          sectionStore.setStoreFromApi({ app, organisation, sections }, true);

          const [refreshedProfile, completedContent, orgUserData] = await addRetries(() =>
            Promise.all([
              profilesClient.getUserProfile(userStore.userID!),
              contentClient.getCompletedContentItems({
                userId: userStore.userID,
                spaceId: app.id,
                organisationId: app.organisationID,
              }),
              spacesClient.getOrgSpaceUserInfo({ spaceId: app.id }),
            ]),
          )();

          // REST endpoint returns legacy content which can cause errors, so remove:
          delete appUser.completedFiles;
          delete appUser.completedFilesMap;

          userStore.updateData({
            orgUserData: orgUserData ?? undefined,
            appUser,
            user: refreshedProfile,
            completedContent,
          });

          const files = await getFilesByMainSectionsAndUpdatedAt({ updatedAt: undefined });
          fileStore.upsertFiles(files, true);
          localSearchStore.initialiseNewDb();

          userStore.signInOrganisation({});

          if (process.env.REACT_APP_SENTRY_ENABLED !== "false" && refreshedProfile.id) {
            Smartlook.identify(refreshedProfile.id, { displayName: appUser.id });
          }
          onSelectSpaceAction(app);
          navigateToSpace(app.name, forwardUrl);

          if (appUser.accessLevel === "admin") {
            getCompletedChecklistsAndIncidentReportFiles({
              updatedAt: undefined,
            })
              .then((files) => {
                fileStore.upsertFiles(files);
              })
              .catch(eolasLogger.error);
          }

          trackEvent(AnalyticsEvents.EOLAS_SESSION_START);

          return "granted";
        } catch (error: unknown) {
          eolasLogger.error(error);
          return "denied";
        }
      }
    }

    if (status === AccessRequestStatus.NO_APP_ACCESS) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "No app access",
      });

      setSelectSpaceStep("request-access");
      return "denied";
    }

    if (status === AccessRequestStatus.ACCESS_REQUESTED) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("request-sent");
      return "pending";
    }

    if (status === AccessRequestStatus.NO_TRUSTED_EMAIL) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("no-trusted-email");
      return "denied";
    }

    return "denied";
  };

  const { mutateAsync: signIntoSpace, isLoading: isLoadingSignIntoSpace } = useMutation({
    mutationFn: onAllowAccessSuccess,
    mutationKey: ["fetchSpaceDataAndSignIntoSpace"],
  });

  const onSelectSpace = async (spaceId: string): Promise<OnSelectSpaceResponse> => {
    addBreadcrumb({
      category: "Select Organisation",
      message: "Selecting organisation",
    });

    return new Promise<OnSelectSpaceResponse>((resolve) => {
      shouldAllowUserAccess(spaceId, {
        onSuccess: async (data) => {
          const res = await signIntoSpace(data);
          resolve(res);
        },
        onError: () => {
          resolve("denied");
        },
      });
    });
  };

  const onRequestAccess = async (spaceId: string, requestText?: string) => {
    addBreadcrumb({
      category: "Select Organisation",
      message: `Requesting access to organisation: ${selectedAppRef.current.appID}`,
    });

    trackEvent(AnalyticsEvents.ORGANISATION_REQUEST_ACCESS);
    if (userID.length) {
      requestAccess(
        { requestText, spaceId },
        {
          onSuccess: () => {
            setSelectSpaceStep("request-sent");
          },
        },
      );
    }
  };

  return {
    error,
    requestingAccess,
    requestAccessSuccessful,
    shouldAllowAccessLoading: shouldAllowAccessLoading || isLoadingSignIntoSpace,
    selectedAppRef,
    selectSpaceStep,
    userAccess,
    setSelectSpaceStep,
    onSelectSpace,
    onRequestAccess,
    shouldAllowUserAccess,
  };
};
