import { useState } from "react";
import { EnrichedFileVersionWithDayAndTime } from "../types";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { useContentVersions } from "shared/hooks/useContentVersions/useContentVersions";

export const useVersionHistory = (contentId: string) => {
  const [selectedVersion, setSelectedVersion] = useState<EnrichedFileVersionWithDayAndTime>();
  const { fileVersionsWithDayAndTime, isLoadingContentVersions } = useContentVersions(contentId);

  const { searchInstanceId, searchResults, isSearchActive, handleSearchClick, handleClearSearch } =
    useLocalMobxSearch({
      data: fileVersionsWithDayAndTime,
      keysToSearch: ["name", "day"],
    });

  return {
    searchInstanceId,
    fileVersionsWithDayAndTime,
    searchResults,
    isSearchActive,
    handleSearchClick,
    handleClearSearch,
    isLoadingContentVersions,
    selectedVersion,
    onClickVersionTile: (item: EnrichedFileVersionWithDayAndTime) => setSelectedVersion(item),
    onClickBack: () => setSelectedVersion(undefined),
  };
};
