import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

import ContentRepositoryContext, {
  defaultUseBulkDeleteContentItemsHook,
} from "shared/pages/ContentRepository/context/contentRepository.context";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useKnowledgeContentParams from "./hooks/useKnowledgeContentParams";
import useKnowledgeContentRepository from "modules/knowledgeContent/data/useKnowledgeContentRepository";
import useKnowledgeContentSections from "modules/knowledgeContent/data/useKnowledgeContentSections";
import useKnowledgeContentSection from "modules/knowledgeContent/data/useKnowledgeContentSection";
import useKnowledgeContentItems from "modules/knowledgeContent/data/useKnowledgeContentItems";
import useAddKnowledgeContentSection from "modules/knowledgeContent/data/useAddKnowledgeContentSection";
import useEditKnowledgeContentSection from "modules/knowledgeContent/data/useEditKnowledgeContentSection";
import useDeleteKnowledgeContentSection from "modules/knowledgeContent/data/useDeleteKnowledgeContentSection";
import useAddKnowledgeContentItem from "modules/knowledgeContent/data/useAddKnowledgeContentItem";
import useAddKnowledgeContentFlashcardItem from "modules/knowledgeContent/data/useAddKnowledgeContentFlashcardItem";
import useAddKnowledgeContentEolasEditorItem from "modules/knowledgeContent/data/useAddKnowledgeContentEolasEditorItem";
import useEditKnowledgeContentItem from "modules/knowledgeContent/data/useKnowledgeContentItem";
import useEditKnowledgeContentFlashcardItem from "modules/knowledgeContent/data/useEditKnowledgeContentFlashcardItem";
import useEditKnowledgeContentEolasEditorItem from "modules/knowledgeContent/data/useEditKnowledgeContentEolasEditorItem";
import useDeleteKnowledgeContentItem from "modules/knowledgeContent/data/useDeleteKnowledgeContentItem";
import useSearchKnowledgeContentSections from "modules/knowledgeContent/data/useSearchKnowledgeContentSections";
import useSearchKnowledgeContentItems from "modules/knowledgeContent/data/useSearchKnowledgeContentItems";
import { KnowledgeContentRepositoryProps } from "./KnowledgeContentRepository";
import { KnowledgeContentInfoContext } from "./context/knowledgeContentInfoContext";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { ContentRepositoryContextValue } from "shared/pages/ContentRepository/context/types";

type KnowledgeContentRepositoryProviderProps = {
  children: React.ReactNode;
} & KnowledgeContentRepositoryProps;

const KnowledgeContentRepositoryProvider = observer(
  ({
    children,
    domain,
    shouldAllowBlankHeroImageSelection = false,
    onLoadContentItem,
    onClickContentSection,
    onSearchContentItem,
    onSearchContentSection,
    hasCopilotData,
  }: KnowledgeContentRepositoryProviderProps) => {
    const {
      contentRepositoryId: knowledgeContentSectionId,
      mainSectionId,
      subSectionId,
    } = useKnowledgeContentParams({
      domain,
    });

    const { pathname } = useLocation();

    const { flags } = useLaunchDarkly();

    const useContentRepositoryHook = useKnowledgeContentRepository({
      domain,
      contentRepositoryId: knowledgeContentSectionId,
    });

    const useContentSectionHook = useKnowledgeContentSection({
      domain,
      subSectionId,
    });

    const useContentSectionsHook = useKnowledgeContentSections({
      knowledgeContentSectionId,
      domain,
    });

    const useContentItemsHook = useKnowledgeContentItems({ domain, sectionId: subSectionId });

    const useAddContentSectionHook = useAddKnowledgeContentSection({
      mainSectionId,
      parentSectionId: knowledgeContentSectionId,
      domain,
    });

    const useEditContentSectionHook = useEditKnowledgeContentSection({ domain });

    const useDeleteContentSectionHook = useDeleteKnowledgeContentSection({ domain });

    const useAddContentItemHook = useAddKnowledgeContentItem({
      mainSectionId,
      parentId: subSectionId,
      domain,
    });

    const useAddFlashcardItemHook = useAddKnowledgeContentFlashcardItem({
      mainSectionId,
      parentId: subSectionId,
      domain,
    });

    const useAddEolasEditorItemHook = useAddKnowledgeContentEolasEditorItem({
      mainSectionId,
      parentId: subSectionId,
      domain,
    });

    const useEditContentItemHook = useEditKnowledgeContentItem({ parentId: subSectionId, domain });

    const useEditFlashcardItemHook = useEditKnowledgeContentFlashcardItem({
      parentId: subSectionId,
      domain,
    });

    const useEditEolasEditorItemHook = useEditKnowledgeContentEolasEditorItem({
      parentId: subSectionId,
      domain,
    });

    const useDeleteContentItemHook = useDeleteKnowledgeContentItem({
      parentId: subSectionId,
      domain,
    });

    const useReorderContentHook = useReorderContent(
      subSectionId || knowledgeContentSectionId || "",
    );

    const useSearchContentSectionsHook = useSearchKnowledgeContentSections({
      parentId: knowledgeContentSectionId,
      domain,
      onSearchContentSection,
    });

    const useSearchContentItemsHook = useSearchKnowledgeContentItems({
      parentId: subSectionId,
      domain,
      onSearchContentItem,
    });

    const getBlobViewerUrl: ContentRepositoryContextValue["getBlobViewerUrl"] = ({ blob }) => {
      return `${pathname}/${blob.id}`;
    };

    const getFlashcardViewerUrl: ContentRepositoryContextValue["getFlashcardViewerUrl"] = ({
      flashcard,
    }) => {
      return `${pathname}/${flashcard.id}`;
    };

    const getEolasEditorViewerUrl: ContentRepositoryContextValue["getEolasEditorViewerUrl"] = ({
      eolasEditor,
    }) => {
      return `${pathname}/${eolasEditor.id}`;
    };

    const isUserAdmin = flags[LDFlagNames.KNOWLEDGE_ADMIN_ENABLED] ?? false;

    return (
      <KnowledgeContentInfoContext.Provider value={{ domain, isUserAdmin }}>
        <ContentRepositoryContext.Provider
          value={{
            contextDomain: "knowledge",
            useContentRepositoryHook,
            useContentSectionsHook,
            useContentSectionHook,
            useContentItemsHook,
            useAddContentSectionHook,
            useEditContentSectionHook,
            useDeleteContentSectionHook,
            useAddContentItemHook,
            useAddFlashcardItemHook,
            useEditContentItemHook,
            useEditFlashcardItemHook,
            useDeleteContentItemHook,
            useAddEolasEditorItemHook,
            useEditEolasEditorItemHook,
            useReorderContentHook,
            useSearchContentSectionsHook,
            useSearchContentItemsHook,
            getBlobViewerUrl,
            getFlashcardViewerUrl,
            onClickContentSection,
            onLoadContentItem,
            getEolasEditorViewerUrl,
            canOnlyCreateFileSection: true,
            shouldAllowBlankHeroImageSelection,
            hasCopilotData,
            knowledgeContentDomain: domain,
            // TODO: Implement this when knowledge content items are supported
            useBulkDeleteItemsHook: defaultUseBulkDeleteContentItemsHook,
            contentItemConfig: {
              shouldAllowFavourites: domain === "smpcs",
              shouldAllowSharing: domain === "smpcs",
              shouldHideExpiryDate: true,
              shouldShowBreadcrumbs: false,
            },
          }}
        >
          {children}
        </ContentRepositoryContext.Provider>
      </KnowledgeContentInfoContext.Provider>
    );
  },
);

export default KnowledgeContentRepositoryProvider;
