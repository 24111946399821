import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { ContentSection } from "modules/contentRepository/types";
import { mapToContentSection } from "modules/contentRepository/helpers";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { UseContentSectionsHook } from "shared/pages/ContentRepository/context/types";

const useKnowledgeContentSections = ({
  domain,
  knowledgeContentSectionId,
}: {
  domain: KnowledgeContentDomain;
  knowledgeContentSectionId: string | null;
}): UseContentSectionsHook => {
  const { data, isInitialLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: knowledgeContentKeys.contentSections(domain),
      queryFn: ({ pageParam }) =>
        knowledgeContentClient.getSectionsByParentId({
          parentId: knowledgeContentSectionId ?? "",
          pageNumber: pageParam,
          domain,
        }),
      getNextPageParam: (lastPage) => (lastPage?.nextPage ? lastPage.nextPage : undefined),
      staleTime: 20000,
      retry: false,
      enabled: Boolean(knowledgeContentSectionId),
    });

  const contentSections: ContentSection[] = useMemo(() => {
    return data?.pages?.flatMap((page) => page.sections)?.map(mapToContentSection) ?? [];
  }, [data?.pages]);

  return {
    contentSections,
    contentSectionsLoading: isInitialLoading,
    fetchMoreSections: fetchNextPage,
    hasMoreSections: hasNextPage,
    isFetchingMoreSections: isFetchingNextPage,
  };
};

export default useKnowledgeContentSections;
