import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Space, userStore } from "@eolas-medical/core";
import { Loader, Text, Title } from "UIKit";

import { SpaceItem } from "./components/SpaceItem/SpaceItem";
import useUserSpaces from "modules/spaces/data/useUserSpaces";

const YourSpaces = () => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { userSpaces, userSpacesLoading } = useUserSpaces(userID);

  const renderSpaceItem = useCallback((space: Space) => {
    return (
      <div key={space.id}>
        <SpaceItem space={space} />
      </div>
    );
  }, []);

  const renderSpaceList = () => {
    if (userSpacesLoading) {
      return <Loader className="bg-transparent h-30vh" />;
    }

    if (userSpaces.length === 0) {
      return (
        <div className="flex flex-col px-4 sm:px-40 py-28 text-center items-center space-y-4">
          <Title level={8} className="text-grey-700">
            {t("selectSpace_no_member_of_orgs")}
          </Title>

          <Text level={1} className="text-grey-700">
            {t("selectSpace_explore")}
          </Text>
        </div>
      );
    }

    return userSpaces.map(renderSpaceItem);
  };

  return (
    <div className="flex flex-col mt-32 md:mt-0">
      <div className="flex flex-col relative md:mt-24 space-y-4">{renderSpaceList()}</div>
    </div>
  );
};

export default YourSpaces;
