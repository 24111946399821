import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FallbackProps } from "react-error-boundary";
import { error as errorAnimation } from "Assets/LottieAnimations";
import { BaseAppRoute } from "Utilities/types";
import { PortfolioError } from "Pages/MeTab/pages/Portfolio/errors";

const PortfolioErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const { t } = useTranslation();

  const renderPortfolioError = () => {
    switch (error.message) {
      case PortfolioError.NOT_THE_ASSESSOR:
      case PortfolioError.FEEDBACK_ALREADY_EXIST:
      case PortfolioError.EVENT_NOT_FOUND:
      case PortfolioError.EVENT_STATUS_NOT_FOUND:
      case PortfolioError.FEEDBACK_NOT_FOUND:
      case PortfolioError.EVENT_OWNER:
        return <div>{error.message}</div>;
      default:
        throw new Error(error.message);
    }
  };

  return (
    <div
      className="flex flex-col justify-start items-center flex-1 pt-16"
      data-testid="portfolio-error-fallback"
    >
      <Lottie style={{ width: "12rem", height: "12rem" }} animationData={errorAnimation} />
      <span className="font-semibold text-lg">{t("error_fallback_title")}</span>
      <span className="mt-4 mb-8 text-grey-dark">{renderPortfolioError()}</span>

      <Link
        to={`/${BaseAppRoute.myProfile}/portfolio`}
        replace
        onClick={resetErrorBoundary}
        className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
      >
        {t("portfolio_error_fallback_button")}
      </Link>
    </div>
  );
};

export default PortfolioErrorFallback;
