import { MedicinalForms } from "@eolas-medical/core";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import FormGroup from "./FormGroup";
import { Divider } from "UIKit";
import { MedicinalFormsStrings } from "./MedicinalForms.strings";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";

type MedicinalFormsProps = {
  section: MedicinalForms;
} & InfoIdProps;

const FORM_GROUP_BASE_KEY = "eolas_form_group";

const MedicinalFormsContent = ({ section, ...rest }: MedicinalFormsProps) => {
  return (
    <BnfCollapsible title={MedicinalFormsStrings.medicinalForms} {...rest}>
      <Divider className="flex flex-col justify-start space-y-4">
        <div className="pt-2">
          <BnfContentElement htmlContent={section.initialStatement} className="text-sm" />
        </div>
        {section.medicinalForms.map((group, index) => {
          return (
            <div key={FORM_GROUP_BASE_KEY + index} className="pt-4">
              <FormGroup form={group} />
            </div>
          );
        })}
      </Divider>
    </BnfCollapsible>
  );
};

export default MedicinalFormsContent;
