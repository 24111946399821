import { useEolasForm } from "Hooks/useEolasForm";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModalWizardButtons, FormElement, FormError, Input, ProgressBar } from "UIKit";
import { blobValidationSchema } from "../validations/schema";

export interface BlobFormProps {
  initialName?: string;
  existingFileNames: string[];
  isLoading: boolean;
  progress?: number;
  errorMessage?: string;
  currentStep?: number;
  isEdit?: boolean;
  onSubmit: (name: string) => void;
  onBack: () => void;
  onNext?: () => void;
}

export const BlobForm = ({
  initialName,
  currentStep,
  existingFileNames,
  isLoading,
  progress = 0,
  errorMessage = "",
  isEdit,
  onSubmit,
  onBack,
  onNext,
}: BlobFormProps) => {
  const { t } = useTranslation();
  const { control, getFieldState, handleSubmit, setValue, getValues, reset } = useEolasForm({
    defaultValues: { name: initialName },
    validationSchema: blobValidationSchema(existingFileNames, isEdit ? initialName : ""),
  });

  const UploadProgress = useMemo(() => {
    if (isLoading && progress > 0) {
      return <ProgressBar progress={progress} />;
    }
    return null;
  }, [isLoading, progress]);

  const handleSave = handleSubmit(({ name }) => {
    if (currentStep !== 0) onSubmit(name);
  });

  useEffect(() => {
    if (initialName && !getValues("name")) setValue("name", initialName);
  }, [initialName, setValue, getValues]);

  const handleNext = useCallback(() => {
    if (!onNext) return;
    reset();
    setValue("name", initialName);
    onNext();
  }, [initialName, onNext, setValue, reset]);

  return (
    <form className="add-file-form">
      {initialName && currentStep === 1 && (
        <>
          <FormElement
            required
            id="name"
            control={control}
            labelClassName="font-semibold"
            label={t("file_name_label")}
            errorMessage={getFieldState("name").error?.message}
          >
            {(field) => (
              <Input
                {...field}
                size="lg"
                disabled={false}
                data-testid="form-element-link"
                placeholder={t("file_name_placeholder")}
                className={getFieldState("name").error ? "border-red-focus input-error" : "input"}
              />
            )}
          </FormElement>

          {UploadProgress}
        </>
      )}

      <FormError error={t(errorMessage)} />

      <ModalWizardButtons
        maxSteps={onNext ? 2 : undefined}
        currentStep={currentStep}
        onBack={onBack}
        isLoading={isLoading}
        nextDisabled={currentStep === 0 && !initialName}
        onNext={onNext && currentStep === 0 ? handleNext : handleSave}
        backLabel={currentStep === 1 && onNext ? "Back" : "Cancel"}
        nextLabel={currentStep === 0 ? "Next" : "Save"}
      />
    </form>
  );
};
