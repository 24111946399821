import { DrugContentElement } from "@eolas-medical/core";
import BnfContentElement from "../BnfContentElement/BnfContentElement";
import BnfPill from "../BnfPill/BnfPill";
import { InfoContentStrings } from "./InfoContent.strings";

interface InfoContentProps {
  element: DrugContentElement;
  showContentForTag: boolean;
}
const InfoContent = ({ element, showContentForTag }: InfoContentProps) => {
  return (
    <div className="flex flex-col space-y-3 p-4">
      {showContentForTag ? (
        <div className="flex">
          <BnfPill type="drug" value={InfoContentStrings.title(element.contentFor)} />
        </div>
      ) : null}
      <BnfContentElement htmlContent={element.content} />
    </div>
  );
};

export default InfoContent;
