import { useFileOperations, useRefetchAppData } from "Hooks";
import { EolasFile, eolasLogger } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

export const useDeleteDepartmentArea = (department?: EolasFile) => {
  const { removeFile } = useFileOperations();
  const { refetch } = useRefetchAppData();

  const {
    error: deleteError,
    isLoading: isDeleteLoading,
    isSuccessful: isDeleteSuccessful,
    setRequestStatus: setDeleteStatus,
  } = useRequestStatus();

  const onDelete = async () => {
    setDeleteStatus({ status: "pending", error: "" });
    try {
      await removeFile({
        variables: {
          id: department?.id,
          parentID: department?.parentID,
        },
      });
      refetch();
      setDeleteStatus({ status: "success", error: "" });
    } catch (err) {
      eolasLogger.error(err);
      setDeleteStatus({
        status: "error",
        error: err instanceof Error ? err.message : "An error ocurred",
      });
    }
  };

  return {
    onDelete,
    deleteError,
    isDeleteLoading,
    isDeleteSuccessful,
  };
};
