import { useState } from "react";

import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useQuery } from "@tanstack/react-query";

export const makeGetLoginTokensQueryKey = (spaceId: string | null) => ["getLoginTokens", spaceId];

export const useLoginTokens = () => {
  const { spaceId } = useGetRelevantSpaceDetails();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { flags } = useLaunchDarkly();

  const {
    data: loginTokens,
    error,
    isLoading,
  } = useQuery({
    queryFn: async () => {
      if (!spaceId) {
        throw new Error("No spaceId: this should not happen");
      }
      const { data: newData } = await loginTokenService.listTokens({
        appID: spaceId,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });
      return newData;
    },
    queryKey: makeGetLoginTokensQueryKey(spaceId),
    select: (newData) => {
      return newData.map(({ id }) => id);
    },
    enabled: Boolean(spaceId),
  });

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  return {
    error: error && error instanceof Error ? error.message : "",
    isLoading,
    loginTokens,
    isModalOpen,
    onOpenModal,
    onCloseModal,
  };
};
