import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { SearchSortList } from "shared/components/Lists";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import { FeedbackStatus, FeedbackSummary, TimelineData } from "modules/portfolio/types";
import { FilterSection } from "Pages/MeTab/pages/Portfolio/components/FilterSection/FilterSection";
import NoEventsMessage from "Pages/MeTab/pages/Portfolio/components/NoEventsMessage/NoEventsMessage";
import TimelineCard from "Pages/MeTab/pages/Portfolio/components/TimelineItem/TimelineCard";
import useFeedbackList from "modules/portfolio/data/useFeedbackList";
import useFilteredEventList from "Pages/MeTab/pages/Portfolio/hooks/useFilteredEventList";
import { orderFeedbackList } from "Pages/MeTab/pages/Portfolio/helpers";

export const FeedbackList = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { feedbackList, feedbackListLoading } = useFeedbackList();

  const {
    filteredEventList,
    filterObject,
    searchInput,
    areFiltersApplied,
    onUpdateFilters,
    onSearchInputChange,
  } = useFilteredEventList(feedbackList);

  const groupedResults = useMemo(() => {
    return orderFeedbackList([...filteredEventList]);
  }, [filteredEventList]);

  const statuses = Object.values(FeedbackStatus);

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("feedback_and_forms")} />
        <NavButton to={url.replace(`/${PortfolioConstants.FEEDBACK_URL}`, "")} />
      </div>

      <div>
        <FilterSection
          filterObject={filterObject}
          eventStatuses={statuses}
          areFiltersApplied={areFiltersApplied}
          onUpdateFilters={onUpdateFilters}
          searchInput={searchInput}
          onSearchInputChange={onSearchInputChange}
        />

        <SearchSortList<TimelineData>
          items={groupedResults}
          renderItem={(item: TimelineData) => {
            const feedbackItem = item as FeedbackSummary;
            return (
              <TimelineCard
                date={feedbackItem.date!}
                title={feedbackItem.title}
                type={feedbackItem.type}
                status={feedbackItem.status!}
                url={`${url}/${feedbackItem.type.toLowerCase()}/${feedbackItem.eventId}`}
              />
            );
          }}
          isLoading={feedbackListLoading}
          emptyItemsComponent={
            <NoEventsMessage
              searchText={searchInput}
              areFiltersApplied={areFiltersApplied}
              emptyTextKey="portfolio_assessment_empty"
            />
          }
        />
      </div>
    </InnerPageWrapper>
  );
};
