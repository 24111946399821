import { useTranslation } from "react-i18next";

import { Button, Text } from "UIKit";
import { FooterItem } from "../types";
import { useFormState } from "react-hook-form";

export const DsmFooter = ({ item: { context } }: { item: FooterItem }) => {
  const { onSubmit, reset, control } = context;
  const { t } = useTranslation();
  const { errors } = useFormState({ control });

  const isFormErrors = Object.values(errors).length > 0;

  return (
    <div className="flex flex-col gap-4 width-full my-4">
      <Button variant="solid" color="white" onClick={() => reset()}>
        {t("dsm_reset")}
      </Button>
      {isFormErrors ? (
        <Text level={2} className="text-red-500 text-center" element="label">
          {t("dsm_check_form")}
        </Text>
      ) : null}
      <Button variant="solid" onClick={onSubmit}>
        {t("dsm_submit")}
      </Button>
    </div>
  );
};
