import { expectNever } from "Utilities/helpers";
import { EolasTileProps, LabelColor, RectangularEolasTileProps } from "./EolasTile.types";
import { EolasCard } from "UIKit/EolasCard/EolasCard";
import { DragIcon } from "Assets/Icons/monocolored";
import { Title, Text } from "UIKit/Typography";
import { hasStringProp } from "@eolas-medical/core";
import { EolasTag } from "UIKit/EolasTag/EolasTag";

/**
 * EolasTile is a versatile tile component that can be used to display various types of content as tiles.
 * It's ideal use is as a Base for building more specific tile components.
 * You should not change styling in this component unless that change is intended to be applied to all tiles.
 * If you need to change the styling of a specific tile, create a new component that consumes this one, most of the props can be supplied React components.
 * Therefore you can pass in your own components to customize the tile. e.g. Adding a different spacing between the primary and secondary text.
 * @example
 * <EolasTile variant="rectangular" primaryText="Emergency Action Cards" secondaryText="Section with Subsections" icon={<RoundedRectangleIcon />} rightAction={<CaretRight className="h-6 w-6 text-grey-600" />} />
 */
export const EolasTile = (props: EolasTileProps) => {
  if (props.variant === "rectangular") {
    return <EolasRectangularTile {...props} />;
  }

  // If we reach this point, it means we have an unexpected variant, did you add a new one?
  expectNever(props.variant);

  return null;
};

const EolasRectangularTile = ({
  onClick,
  shouldShowDragHandle,
  primaryText,
  secondaryText: secondaryTextProp,
  leftAction,
  icon,
  rightAction,
  rightLabel: rightLabelProp,
  isDisabled,
}: RectangularEolasTileProps) => {
  const secondaryText = secondaryTextProp ?? null;
  const rightLabel = rightLabelProp ?? null;
  return (
    <EolasCard onClick={onClick} isDisabled={isDisabled}>
      <div className={`flex p-4 flex-row gap-4 items-center ${isDisabled ? "opacity-50" : ""}`}>
        {shouldShowDragHandle ? (
          <DragIcon
            data-testid="drag-icon"
            className="col-span-1 text-grey-500 self-start h-4 w-4"
          />
        ) : null}
        {leftAction ? leftAction : null}
        {icon ? <div className="w-16 h-16">{icon}</div> : null}
        <div className="flex flex-col flex-1">
          {isString(primaryText) ? (
            <Title level={8} className="line-clamp-1">
              {primaryText}
            </Title>
          ) : (
            primaryText
          )}
          {isString(secondaryText) ? <Text level={2}>{secondaryText}</Text> : secondaryText}
        </div>
        {isLabelObject(rightLabel) ? (
          <EolasTag color={rightLabel.color} text={rightLabel.text} />
        ) : (
          rightLabel
        )}
        {rightAction ? rightAction : null}
      </div>
    </EolasCard>
  );
};

const isLabelObject = (value: unknown): value is { text: string; color: LabelColor } => {
  return hasStringProp(value, "text") && hasStringProp(value, "color");
};

const isString = (value: unknown): value is string => {
  return typeof value === "string";
};
