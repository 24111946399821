import { action, makeAutoObservable } from "mobx";

type IsSectionCollapsedRecord = Partial<Record<string, Partial<Record<string, boolean>>>>;

class BnfStore {
  constructor() {
    {
      makeAutoObservable(this);
    }
  }

  private _isSectionCollapsedRecord: IsSectionCollapsedRecord = {};

  private shouldIgnoreUpdate = false;

  get isSectionCollapsedRecord() {
    return this._isSectionCollapsedRecord;
  }

  @action public updateIsSectionCollapsedRecord({
    newIsCollapsed,
    itemId,
    sectionId,
  }: {
    newIsCollapsed: boolean;
    itemId: string;
    sectionId: string;
  }) {
    this._isSectionCollapsedRecord[itemId] = {
      ...(this._isSectionCollapsedRecord[itemId] ?? {}),
      [sectionId]: newIsCollapsed,
    };
  }

  @action public cleanupItem(itemId: string | null) {
    if (this.shouldIgnoreUpdate || !itemId) {
      return;
    }
    delete this._isSectionCollapsedRecord[itemId];
  }

  @action setShouldIgnoreUpdate() {
    this.shouldIgnoreUpdate = true;
    setTimeout(() => {
      action(() => {
        this.shouldIgnoreUpdate = false;
      })();
    }, 200);
  }
}

export const bnfStore = new BnfStore();
