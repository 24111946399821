import { mapToContentItem } from "modules/contentRepository/helpers";
import { useContentVersions } from "../useContentVersions/useContentVersions";
import { EnrichedFileVersionWithDayAndTime } from "shared/pages/ContentRepository/ContentItems/components/ShowVersionsModal/types";
import { ContentItem } from "@eolas-medical/core";

export const useContentVersion = ({
  contentId,
  versionNo,
}: {
  contentId: string;
  versionNo: number;
}):
  | {
      isLoadingContentVersions: true;
      isUserAllowed: undefined;
      fileVersion: undefined;
      versionContentItem: undefined;
    }
  | {
      isLoadingContentVersions: false;
      isUserAllowed: false;
      fileVersion: undefined;
      versionContentItem: undefined;
    }
  | {
      isLoadingContentVersions: false;
      isUserAllowed: true;
      fileVersion: EnrichedFileVersionWithDayAndTime;
      versionContentItem: ContentItem;
    } => {
  const { fileVersionsWithDayAndTime, isLoadingContentVersions, isUserAllowed } =
    useContentVersions(contentId);
  const fileVersion = fileVersionsWithDayAndTime.find((v) => v.versionNo === versionNo);

  if (isLoadingContentVersions) {
    return {
      isLoadingContentVersions: true,
      isUserAllowed: undefined,
      fileVersion: undefined,
      versionContentItem: undefined,
    };
  }

  if (!isUserAllowed) {
    return {
      isUserAllowed,
      fileVersion: undefined,
      versionContentItem: undefined,
      isLoadingContentVersions,
    };
  }

  if (!fileVersion) throw new Error(`Version ${versionNo} for file with id ${contentId} not found`);

  return {
    isUserAllowed: true,
    fileVersion,
    isLoadingContentVersions,
    versionContentItem: mapToContentItem({ ...fileVersion, id: fileVersion.fileId }),
  };
};
