import { MonographType } from "@eolas-medical/core";
import BnfPill from "./BnfPill";
import { Sizes } from "UIKit/types";

export const BnfLabelPill = ({ type }: { type: MonographType | null }) => {
  if (!type) {
    return null;
  }
  return (
    <BnfPill
      type={type === "bnf" ? "adult" : "child"}
      value={type === "bnf" ? "BNF" : "BNFC"}
      className="w-16"
      size={Sizes.SMALL}
    />
  );
};
