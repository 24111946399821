import {
  AppLevelSection,
  ChildReference,
  CommunityLevelSection,
  Maybe,
  MonographType,
  bnfToSubSectionMap,
  communitySubsectionNames,
  favouriteEntityTypeToMainSectionTypeMapper,
  fromAwsJSON,
  isEolasCalculatorResultMetadata,
  isNationalResourceMetadata,
  isNiceGuidelinesMetadata,
  sectionStore,
} from "@eolas-medical/core";
import { isFavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites/typeguards";
import {
  EntityOwnerType,
  FavouriteEntityType,
} from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { KnowledgeResult, LocalResult } from "Components/MasterSearch/types";
import { isBnfMetadata } from "Hooks/useSelectFileModal/typeguards";

export const isEntityFromKnowledge = (entityOwnerType: Maybe<string>) => {
  return entityOwnerType === EntityOwnerType.KNOWLEDGE;
};

export const isMonographType = (name: string): name is MonographType => {
  return name === "bnf" || name === "bnfc";
};
export const getParentName = ({
  parentSectionName,
  entityOwnerType,
  entityType,
}: {
  parentSectionName: Maybe<string>;
  entityOwnerType: EntityOwnerType | null;
  entityType: string | null;
}) => {
  // BNF and BNFC section names are displayed differently
  if (parentSectionName && isMonographType(parentSectionName)) {
    const subSection = bnfToSubSectionMap[parentSectionName];
    return communitySubsectionNames[subSection];
  } else if (
    isEntityFromKnowledge(entityOwnerType) &&
    entityType &&
    isFavouriteEntityType(entityType)
  ) {
    const mainSectionType = favouriteEntityTypeToMainSectionTypeMapper(entityType);
    if (mainSectionType) {
      const mainSectionName =
        sectionStore.getChildReferenceByMainSectionType(mainSectionType)?.description;
      return mainSectionName;
    }
  }
  return parentSectionName;
};
export const getParentNameCustomColor = ({
  parentSectionName,
  entityOwnerType,
}: {
  parentSectionName?: string;
  entityOwnerType: string;
}) => {
  if (entityOwnerType === EntityOwnerType.SPACE) {
    return "text-blue-500";
  }

  if (entityOwnerType === EntityOwnerType.ORGANISATION) {
    return "text-orange-500";
  }

  if (parentSectionName === "bnf") {
    return "text-blue-500";
  }

  if (parentSectionName === "bnfc") {
    return "text-red-500";
  }

  return "text-grey-600";
};

export const getMainSectionOfMyFavourite = (entityType: string): ChildReference | null => {
  if (isFavouriteEntityType(entityType)) {
    const mainSectionType = favouriteEntityTypeToMainSectionTypeMapper(entityType);
    if (mainSectionType) {
      return sectionStore.getChildReferenceByMainSectionType(mainSectionType);
    }
  }
  return null;
};

export const localSearchResultToEntityType = (searchResult: LocalResult): FavouriteEntityType => {
  if (searchResult.file.mainSectionID) {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(
      searchResult.file.mainSectionID,
    );
    if (mainSectionType === AppLevelSection.checklists) {
      return FavouriteEntityType.CHECKLIST_TEMPLATE;
    }
  }
  return FavouriteEntityType.FILE;
};

export const knowledgeSearchResultToEntityType = (
  searchResult: KnowledgeResult,
): FavouriteEntityType | null => {
  const metadata = fromAwsJSON(searchResult.knowledgeFile.metadata);

  if (!metadata) {
    return null;
  }

  if (isBnfMetadata(metadata)) {
    if (metadata.medicationMonographType === "BNF") {
      return FavouriteEntityType.BNF;
    }

    if (metadata.medicationMonographType === "BNFC") {
      return FavouriteEntityType.BNFC;
    }
  }

  if (isNiceGuidelinesMetadata(metadata)) {
    return FavouriteEntityType.NICE;
  }

  if (isNationalResourceMetadata(metadata)) {
    if (searchResult.searchSubType === CommunityLevelSection.clinicalCalculators) {
      return FavouriteEntityType.CALCULATOR;
    }

    if (searchResult.searchSubType === CommunityLevelSection.communityPatientLeaflets) {
      return FavouriteEntityType.LEAFLET;
    }

    if (searchResult.searchSubType === CommunityLevelSection.nationalGuidelines) {
      return FavouriteEntityType.GUIDELINE;
    }
  }

  if (isEolasCalculatorResultMetadata(metadata)) {
    return FavouriteEntityType.CALCULATOR;
  }

  return null;
};

export const getEntityIdFromKnowledgeSearchResult = (
  searchResult: KnowledgeResult,
): string | null => {
  const metadata = fromAwsJSON(searchResult.knowledgeFile.metadata);

  if (!metadata) {
    return null;
  }

  if (isBnfMetadata(metadata)) {
    return metadata.bnfId;
  }

  if (isNiceGuidelinesMetadata(metadata)) {
    return metadata.niceGuidanceId;
  }

  if (isNationalResourceMetadata(metadata)) {
    return metadata.nationalResourceFileId;
  }

  if (isEolasCalculatorResultMetadata(metadata)) {
    return metadata.id;
  }

  return null;
};
