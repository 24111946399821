import { Text } from "UIKit";
import { MedicinalFormsStrings } from "./MedicinalForms.strings";
import { BnfLabel } from "@eolas-medical/core";
import { removeTags } from "modules/bnf/helpers";

const FormGroupLabel = ({ label }: { label: BnfLabel }) => {
  return (
    <div className="flex flex-col space-y-2">
      <Text level={2}>{MedicinalFormsStrings.labelDetail(label.number, label.qualifier)}</Text>
      <Text level={3} className="font-normal">
        {removeTags(label.englishRecommendation)}
      </Text>
      <Text level={3} className="font-normal italic">
        {removeTags(label.welshRecommendation)}
      </Text>
    </div>
  );
};

export default FormGroupLabel;
