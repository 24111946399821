import React from "react";

import { useUpdateApp } from "Hooks";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";
import { useTranslation } from "react-i18next";

export const useTrustedEmail = (email: string) => {
  const { t } = useTranslation();
  const { updateApp } = useUpdateApp();
  const [tooltipText, setTooltipText] = React.useState<string>(t("manageUsers_copy_email"));
  const { spaceId, relevantSpace } = useGetRelevantSpaceDetails();
  const trustedEmail = relevantSpace?.trustedEmail ?? "";
  const queryClient = useQueryClient();

  const onCopyClicked = () => {
    navigator.clipboard.writeText(email);
    setTooltipText(t("manageUsers_email_copied"));
  };

  const afterTooltipHide = () => {
    return setTimeout(() => {
      setTooltipText(t("manageUsers_copy_email"));
    }, 250);
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ["deleteTrustedEmail"],
    mutationFn: async () => {
      if (!spaceId) {
        throw new Error("Delete trusted email: spaceId is null");
      }
      const mails = trustedEmail.split(";");
      const updatedMails = mails.filter((m: string) => m !== email).join(";");

      const newEmail = updatedMails.length === 0 ? "adminAuthentication" : updatedMails;
      // @TODO: replace this with REST call. Currently PATCH endpoint on spaces service does not accept trustedEmail
      return updateApp({
        id: spaceId,
        trustedEmail: newEmail,
      });
    },
    onSuccess: () => {
      if (spaceId) {
        queryClient.refetchQueries(spacesKeys.detail(spaceId));
      }
    },
  });

  return {
    isLoading,
    tooltipText,
    onDeleteEmail: () => mutateAsync(),
    onCopyClicked,
    afterTooltipHide,
  };
};
