import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { getBlobType } from "Utilities/fileHelpers";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { EditFlashcardItemDto } from "modules/contentRepository/types";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { mapToUpdateFlashcardDto } from "modules/contentRepository/helpers";

interface UseEditFlashcardItemProps {
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useEditKnowledgeContentFlashcardItem = ({ parentId, domain }: UseEditFlashcardItemProps) => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleEditFlashcardItem = async ({ flashcard, editItem, file }: EditFlashcardItemDto) => {
    let fileType;

    if (file) {
      fileType = getBlobType(file.type);
    }

    const flashcardItemDto = mapToUpdateFlashcardDto({
      name: editItem.name,
      description: editItem.description,
      flashcardContent: editItem.flashcardContent,
      specialty: editItem.specialty,
      fileType: fileType,
      url: file ? undefined : editItem.imageUrl,
      isDraft: editItem.isDraft,
      queriesForHit: editItem.queriesForHit,
      documentIdentifiers: editItem.documentIdentifiers,
      isKnowledge: true,
    });

    const { uploadUrl } = await knowledgeContentClient.updateFlashcardItem({
      contentId: flashcard.id,
      updateFlashcardItemDto: flashcardItemDto,
    });

    if (file && uploadUrl) {
      await uploadFileToPresignedS3(uploadUrl, file, setUploadProgress);

      setUploadProgress(50);
    }

    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({ flashcard, editItem, file }: EditFlashcardItemDto) =>
      handleEditFlashcardItem({ flashcard, editItem, file }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
        setUploadProgress(100);
      },
    },
  );

  return {
    editFlashcardItem: mutate,
    editingFlashcardItem: isLoading,
    editFlashcardItemSuccess: isSuccess,
    editFlashcardItemError: error instanceof Error ? error.message : "",
    editFlashcardItemProgress: uploadProgress,
  };
};

export default useEditKnowledgeContentFlashcardItem;
