import { Route, Redirect, Switch } from "react-router-dom";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import { FileViewer } from "./FileViewer";
import FlashcardViewer from "./components/FlashcardViewer/FlashcardViewer";
import EolasEditorViewer from "./components/EolasEditorViewer/EolasEditorViewer";
import { useLaunchDarkly } from "Contexts";
import { StandaloneDsmViewer } from "shared/pages/ContentViewer/DsmViewer/DsmViewer";

export const FileViewerRoutes = () => {
  const { flags } = useLaunchDarkly();

  return (
    <Switch>
      <Route exact path={`/${BaseAppRoute.viewer}/:fileId`} component={FileViewer} />

      <Route
        exact
        path={`/${BaseAppRoute.viewer}/:mainSectionId/flash-cards/:flashcardId`}
        component={FlashcardViewer}
      />

      <Route
        exact
        path={`/${BaseAppRoute.viewer}/:mainSectionId/eolas-editor/:contentItemId`}
        component={EolasEditorViewer}
      />

      <Route
        exact
        path={`/${BaseAppRoute.viewer}/:mainSectionId/dsm-viewer/:contentItemId`}
        component={StandaloneDsmViewer}
      />

      {flags[LDFlagNames.VERSION_HISTORY] ? (
        <>
          <Route
            exact
            path={`/${BaseAppRoute.viewer}/:mainSectionId/eolas-editor/:contentItemId/version/:versionNo`}
            component={EolasEditorViewer}
          />
          <Route
            exact
            path={`/${BaseAppRoute.viewer}/:mainSectionId/flash-cards/:flashcardId/version/:versionNo`}
            component={FlashcardViewer}
          />
          <Route
            exact
            path={`/${BaseAppRoute.viewer}/:fileId/version/:versionNo`}
            component={FileViewer}
          />
        </>
      ) : null}

      <Redirect to={`/${BaseAppRoute.viewer}/:fileId`} />
    </Switch>
  );
};
