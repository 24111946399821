import { MonographType } from "@eolas-medical/core";
import { useGetBorderlineSubstance } from "./useGetBorderlineSubstance";
import { useProcessItems } from "../../hooks/useProcessItems";

export const useProcessBorderlineSubstance = (args: {
  idOrSid: string | null;
  type: MonographType;
}) => {
  const { data, isLoading } = useGetBorderlineSubstance(args);
  const { makeOnClick, goBackOverride, activeItem } = useProcessItems({
    fetchedItems: data?.borderlineSubstance ? [data.borderlineSubstance] : undefined,
  });

  const activeBorderlineSubstance = activeItem ?? data?.borderlineSubstance ?? null;

  return {
    isLoading,
    activeBorderlineSubstance,
    goBackSubstance: goBackOverride,
    makeOnClick,
    title: activeBorderlineSubstance?.title,
  };
};
