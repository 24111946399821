import { hasStringProp } from "@eolas-medical/core";
import { ChecklistItem } from "../pages";

export const isChecklistItem = (item: unknown): item is ChecklistItem => {
  return (
    hasStringProp(item, "id") &&
    hasStringProp(item, "mainSectionType") &&
    item.mainSectionType === "checklists" &&
    hasStringProp(item, "type") &&
    item.type === "checklistTemplate"
  );
};
