import { AppLevelSection, fileStore, sectionStore } from "@eolas-medical/core";
import { useMemo } from "react";
import { useCompleteFile } from "Hooks";
import { NewsFeed } from "../types";

interface useNewsFeeds {
  newsFeeds: NewsFeed[];
}

const useNewsFeeds = (): useNewsFeeds => {
  const { getCompletedFileProps } = useCompleteFile();

  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.newsFeed)!;

  const childrenOrder = sectionStore.getChildrenOrder(id);

  const newsFeeds: NewsFeed[] = useMemo(() => {
    return fileStore.getFiles(childrenOrder).map((f) => {
      return {
        ...f,
        isHighlighted: !!f.highlighted && f.highlighted !== "false",
        ...getCompletedFileProps(f),
        isRead: getCompletedFileProps(f).isCompleted,
        createdBy: f.info,
      };
    });
  }, [childrenOrder, getCompletedFileProps]);

  return { newsFeeds };
};

export default useNewsFeeds;
