/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-duplicate-imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
  condition?: Types.Maybe<Types.ModelUserConditionInput>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?:
    | {
        __typename?: "User";
        id: string;
        given_name?: string | null | undefined;
        family_name?: string | null | undefined;
        email?: string | null | undefined;
        emailToVerify?: string | null | undefined;
        jobTitle?: string | null | undefined;
        levelOfTraining?: string | null | undefined;
        specialtyInterests?: Array<string | null | undefined> | null | undefined;
        acceptedTerms?: string | null | undefined;
        completedTour?: string | null | undefined;
        metadata?: any | null | undefined;
        owner?: string | null | undefined;
        createdAt?: any | null | undefined;
        updatedAt?: any | null | undefined;
        verifiedEmails?:
          | Array<
              | {
                  __typename?: "VerifiedEmail";
                  email?: string | null | undefined;
                  updatedAt?: string | null | undefined;
                  createdAt?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        appUsers?:
          | {
              __typename?: "ModelAppUserConnection";
              nextToken?: string | null | undefined;
              items: Array<
                | {
                    __typename?: "AppUser";
                    id: string;
                    appID: string;
                    userID: string;
                    deleted?: string | null | undefined;
                    accessLevel?: string | null | undefined;
                    hospitalAccessLevel?: string | null | undefined;
                    pnTokens?: Array<string | null | undefined> | null | undefined;
                    owner?: string | null | undefined;
                    readGroups?: Array<string | null | undefined> | null | undefined;
                    createdAt?: any | null | undefined;
                    updatedAt?: any | null | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type VerifyEmailMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  code: Types.Scalars["String"];
  userID?: Types.Maybe<Types.Scalars["String"]>;
}>;

export type VerifyEmailMutation = {
  __typename?: "Mutation";
  verifyEmail?: string | null | undefined;
};

export type ChangePrimaryEmailMutationVariables = Types.Exact<{
  newPrimaryEmail: Types.Scalars["String"];
}>;

export type ChangePrimaryEmailMutation = {
  __typename?: "Mutation";
  changePrimaryEmail?: string | null | undefined;
};

export type SendEmailVerificationCodeMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type SendEmailVerificationCodeMutation = {
  __typename?: "Mutation";
  sendEmailVerificationCode?: string | null | undefined;
};

export type RemoveVerifiedEmailMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type RemoveVerifiedEmailMutation = {
  __typename?: "Mutation";
  removeVerifiedEmail?: string | null | undefined;
};

export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      given_name
      family_name
      email
      verifiedEmails {
        email
        updatedAt
        createdAt
      }
      emailToVerify
      jobTitle
      levelOfTraining
      specialtyInterests
      acceptedTerms
      completedTour
      metadata
      owner
      appUsers {
        items {
          id
          appID
          userID
          deleted
          accessLevel
          hospitalAccessLevel
          pnTokens
          owner
          readGroups
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($email: String!, $code: String!, $userID: String) {
    verifyEmail(email: $email, code: $code, userID: $userID)
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options,
  );
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const ChangePrimaryEmailDocument = gql`
  mutation ChangePrimaryEmail($newPrimaryEmail: String!) {
    changePrimaryEmail(newPrimaryEmail: $newPrimaryEmail)
  }
`;
export type ChangePrimaryEmailMutationFn = Apollo.MutationFunction<
  ChangePrimaryEmailMutation,
  ChangePrimaryEmailMutationVariables
>;

/**
 * __useChangePrimaryEmailMutation__
 *
 * To run a mutation, you first call `useChangePrimaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePrimaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePrimaryEmailMutation, { data, loading, error }] = useChangePrimaryEmailMutation({
 *   variables: {
 *      newPrimaryEmail: // value for 'newPrimaryEmail'
 *   },
 * });
 */
export function useChangePrimaryEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePrimaryEmailMutation,
    ChangePrimaryEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePrimaryEmailMutation, ChangePrimaryEmailMutationVariables>(
    ChangePrimaryEmailDocument,
    options,
  );
}
export type ChangePrimaryEmailMutationHookResult = ReturnType<typeof useChangePrimaryEmailMutation>;
export type ChangePrimaryEmailMutationResult = Apollo.MutationResult<ChangePrimaryEmailMutation>;
export type ChangePrimaryEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangePrimaryEmailMutation,
  ChangePrimaryEmailMutationVariables
>;
export const SendEmailVerificationCodeDocument = gql`
  mutation SendEmailVerificationCode($email: String!) {
    sendEmailVerificationCode(email: $email)
  }
`;
export type SendEmailVerificationCodeMutationFn = Apollo.MutationFunction<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>;

/**
 * __useSendEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationCodeMutation, { data, loading, error }] = useSendEmailVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendEmailVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >(SendEmailVerificationCodeDocument, options);
}
export type SendEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useSendEmailVerificationCodeMutation
>;
export type SendEmailVerificationCodeMutationResult =
  Apollo.MutationResult<SendEmailVerificationCodeMutation>;
export type SendEmailVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>;
export const RemoveVerifiedEmailDocument = gql`
  mutation RemoveVerifiedEmail($email: String!) {
    removeVerifiedEmail(email: $email)
  }
`;
export type RemoveVerifiedEmailMutationFn = Apollo.MutationFunction<
  RemoveVerifiedEmailMutation,
  RemoveVerifiedEmailMutationVariables
>;

/**
 * __useRemoveVerifiedEmailMutation__
 *
 * To run a mutation, you first call `useRemoveVerifiedEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVerifiedEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVerifiedEmailMutation, { data, loading, error }] = useRemoveVerifiedEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveVerifiedEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVerifiedEmailMutation,
    RemoveVerifiedEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveVerifiedEmailMutation, RemoveVerifiedEmailMutationVariables>(
    RemoveVerifiedEmailDocument,
    options,
  );
}
export type RemoveVerifiedEmailMutationHookResult = ReturnType<
  typeof useRemoveVerifiedEmailMutation
>;
export type RemoveVerifiedEmailMutationResult = Apollo.MutationResult<RemoveVerifiedEmailMutation>;
export type RemoveVerifiedEmailMutationOptions = Apollo.BaseMutationOptions<
  RemoveVerifiedEmailMutation,
  RemoveVerifiedEmailMutationVariables
>;
