import { FormConfig, useEolasForm } from "Hooks/useEolasForm";
import { FieldValues } from "react-hook-form";
import { FormElement } from "modules/portfolio/types";
import { makeValidationSchema } from "./helpers";

interface UseNewEventFormProps extends Omit<FormConfig, "validationSchema"> {
  formElements: FormElement[];
}

const useEventForm = ({ formElements, defaultValues }: UseNewEventFormProps) => {
  const validationSchema = makeValidationSchema({ formElements });
  const formData = useEolasForm<FieldValues>({ validationSchema, defaultValues });
  return formData;
};
export default useEventForm;
