export const Strings = {
  formulation: "Formulation:",
  energy: "Energy:",
  kj: "kJ",
  kcal: "kcal",
  g: "g",
  protein: "Protein:",
  carbohydrate: "Carbohydrate:",
  fat: "Fat:",
  fibre: "Fibre:",
  specialCharact: "Special characteristics:",
  acbsInds: "ACBS Indications:",
  inds: "Indications:",
  nil: "Nil",
};
