import { useTranslation } from "react-i18next";
import {
  Title,
  Button,
  ModalBody,
  ModalHeader,
  InnerModalWrapper,
  Text,
  FormElement,
  ToggleButton,
} from "UIKit";
import { useAddChecklistOwner } from "../hooks";
import { ChecklistNotifications, ChecklistOwner } from "../types";
import Select, { Option } from "UIKit/Select/Select";
import { useMemo } from "react";
import { AdminIcon } from "Assets";
import { SpaceUser } from "clients/departments/types";

interface ChecklistOwnerModalProps {
  onCancel: () => void;
  onSubmit: (owner: ChecklistOwner) => void;
  possibleOwners: {
    user?: SpaceUser["user"];
    userID: string;
  }[];
  owner?: ChecklistOwner;
}

export const ChecklistOwnerModal: React.FC<ChecklistOwnerModalProps> = ({
  onCancel,
  onSubmit,
  possibleOwners,
  owner,
}: ChecklistOwnerModalProps) => {
  const { t } = useTranslation();

  const possibleOwnersOptions = useMemo(() => {
    return possibleOwners.map(({ user, userID }) => ({
      label: `${user?.given_name ?? ""} ${user?.family_name ?? ""}`,
      value: userID,
      optionIcon: AdminIcon,
      extraInfo: user?.email ?? "",
    }));
  }, [possibleOwners]);

  const {
    control,
    handleSubmit,
    onClickChecklistCompleted,
    onClickIssuesFlagged,
    ACTIVE_NOTIFICATIONS,
    OWNER,
  } = useAddChecklistOwner(
    owner
      ? {
          defaultActiveNotifications: owner.activeNotifications,
          defaultOwner: possibleOwnersOptions.find((o) => o.value === owner.id),
        }
      : {},
  );

  const handleSubmitOwner = ({
    activeNotifications,
    owner,
  }: {
    activeNotifications: ChecklistNotifications[];
    owner?: Option;
  }) => {
    onSubmit({ activeNotifications, id: owner?.value as string });
    onCancel();
  };

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {owner
            ? t("checklistBuilder_edit_checklist_owner")
            : t("checklistBuilder_add_checklist_owner")}
        </Title>
        <Text level={3} className="text-grey-600">
          {t("checklistBuilder_owners_info")}
        </Text>
      </ModalHeader>

      <ModalBody>
        <form onSubmit={handleSubmit(handleSubmitOwner)}>
          <FormElement required label={t("checklistBuilder_owner")} id={OWNER} control={control}>
            {({ value, ...rest }) => {
              return (
                <Select
                  {...rest}
                  placeholder={t("checklistBuilder_select_owner")}
                  options={possibleOwnersOptions}
                  defaultValue={value}
                  controlIcon={AdminIcon}
                />
              );
            }}
          </FormElement>
          <FormElement
            required
            label={t("checklistBuilder_notify_owner_when")}
            id={ACTIVE_NOTIFICATIONS}
            control={control}
          >
            {({ value }) => {
              return (
                <div>
                  <ToggleButton
                    isSelected={value.includes(ChecklistNotifications.IS_COMPLETED)}
                    onClick={() => onClickChecklistCompleted(value)}
                    text={t("checklistBuilder_checklist_is_completed")}
                  />
                  <ToggleButton
                    isSelected={value.includes(ChecklistNotifications.ISSUES_FLAGGED)}
                    onClick={() => onClickIssuesFlagged(value)}
                    text={t("checklistBuilder_issues_are_flagged")}
                  />
                </div>
              );
            }}
          </FormElement>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
            <Button size="lg" color="grey" variant="outline" type="button" onClick={onCancel}>
              {t("general_cancel")}
            </Button>

            <Button size="lg" type="submit">
              {owner ? t("general_save") : t("general_finish")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
