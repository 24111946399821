import { sectionStore } from "@eolas-medical/core";
import { ContentSection } from "../types";
import { mapToContentSection } from "../helpers";

interface UseContentSection {
  contentSection?: ContentSection;
  contentSectionLoading: boolean;
}

const useContentSection = (contentSectionId: string): UseContentSection => {
  const contentSection = sectionStore.getChildReferenceOfSection(contentSectionId);

  return {
    contentSection: contentSection ? mapToContentSection(contentSection) : undefined,
    contentSectionLoading: false,
  };
};

export default useContentSection;
