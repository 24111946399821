import { CommunityLevelSection, sectionStore } from "@eolas-medical/core";

const useMedicationsMainSection = () => {
  const medicationSection = sectionStore.getChildReferenceByMainSectionType(
    CommunityLevelSection.communityMedications,
  );

  return medicationSection;
};

export default useMedicationsMainSection;
