import ReactSelect, {
  Props,
  Options,
  ActionMeta,
  SingleValue,
  components as rsComponents,
} from "react-select";

import { theme } from "../../theme";

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectValue = SingleValue<SelectOption>;

export type SelectOnChange = (
  value: SingleValue<SelectOption>,
  actionMeta: ActionMeta<SelectOption>,
) => void;

export type SelectSize = "xs" | "sm" | "md" | "lg";

export interface SelectProps extends Props<SelectOption, false> {
  size?: SelectSize;
  ariaLabel?: string;
  defaultValue?: SelectValue;
  options: Options<SelectOption>;
}

const selectHeights: Record<SelectSize, string> = {
  xs: "1.5rem",
  sm: "2rem",
  md: "2.5rem",
  lg: "3rem",
};

export const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  ariaLabel,
  size = "md",
  defaultValue,
  components = { Control: rsComponents.Control },
  ...props
}: SelectProps) => {
  return (
    <ReactSelect
      options={options}
      onChange={onChange}
      aria-label={ariaLabel}
      components={components}
      defaultValue={defaultValue}
      styles={{
        control: (style, { menuIsOpen }) => {
          return {
            ...style,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.blue[100],
            borderRadius: "0.5rem",
            cursor: "pointer",
            height: selectHeights[size],
            backgroundColor: theme.colors.white,
            boxShadow: menuIsOpen ? theme.extend.boxShadow.blue : "",
            ":focus": {
              border: `1px solid ${theme.colors.blue[100]}`,
              borderColor: theme.colors.blue[100],
              boxShadow: theme.extend.boxShadow.blue,
            },
            ":hover": {
              borderColor: theme.colors.blue[100],
              boxShadow: theme.extend.boxShadow.blue,
            },
          };
        },
        menuPortal: (style) => ({ ...style, zIndex: 9999 }),
        option: (style) => ({ ...style, cursor: "pointer" }),
      }}
      {...props}
    />
  );
};
