import { PatientGroup } from "@eolas-medical/core";
import BnfColouredContainer from "../../BnfColouredContainer/BnfTextContainer";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";

interface DoseStatementProps {
  type: PatientGroup;
  text: string;
}

const DoseStatement = ({ type, text }: DoseStatementProps) => {
  return (
    <BnfColouredContainer type={type}>
      <BnfContentElement htmlContent={text} />
    </BnfColouredContainer>
  );
};

export default DoseStatement;
