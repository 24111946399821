import { clinicalQuestionsClient, RecentQuestion, userStore } from "@eolas-medical/core";
import { QueryEnvironment, useEolasQuery } from "Hooks";
import { useMemo } from "react";

const sortByDate = (recentQuestions: RecentQuestion[]) =>
  [...recentQuestions].sort((a, b) => b.createdAt.localeCompare(a.createdAt));

export const useGetRecentQuestions = () => {
  const queryFn = async () => {
    const data = await clinicalQuestionsClient.getRecentQuestionsByUser({
      userID: userStore.userID,
    });
    return data?.recentQuestions;
  };

  const { data, error, isFetching } = useEolasQuery({
    queryKey: ["getRecentQuestions"],
    environment: QueryEnvironment.loggedIn,
    shouldPersist: false,
    queryFn,
    options: { enabled: Boolean(userStore.userID) },
  });

  const sortedData = useMemo(() => sortByDate(data ?? []), [data]);

  return { list: sortedData, error, isFetching };
};
