import { MonographType } from "@eolas-medical/core";

const ALL_KEY = "bnf-data" as const;

const bnfKeys = {
  all: [ALL_KEY],
  bnfItem: (itemId: string | null, type: MonographType | null, isInteractionsEnabled?: boolean) => [
    ALL_KEY,
    "bnfItem",
    itemId,
    type,
    isInteractionsEnabled,
  ],
  multiDrugInteractions: <T extends unknown[]>(other: [...T]) => {
    return [ALL_KEY, "multiDrugInteractions", ...other] as const;
  },
  singleDrugInteractions: (sid: string | undefined, type: MonographType | undefined) => [
    ALL_KEY,
    "singleDrugInteractions",
    sid,
    type,
  ],
  getInteractants: (type: MonographType) => [ALL_KEY, "interactants", type],
};

export default bnfKeys;
