import {
  JobTitleOptions,
  JobTitleValue,
  SeniorityOptions,
  SeniorityValue,
  SpecialtyOptions,
  SpecialtyValue,
  SponsoredInfoSection,
  eolasLogger,
  jobTitleOptions,
  seniorityOptions,
  specialtyOptions,
} from "@eolas-medical/core";
import { slotOptions } from "../data/slotOptions";
import { ManageSlotWizardState, SlotType } from "../types/general";

export const makeInitialWizardState = (
  existingSection?: SponsoredInfoSection,
): ManageSlotWizardState => {
  if (existingSection) {
    let type: SlotType;
    if (existingSection.sponsoredInfo.sponsoredType === "CONTENT") {
      type = existingSection.childrenType === "CONTENT" ? "CONTENT_ITEMS" : "CONTENT_SECTIONS";
    } else {
      type = existingSection.sponsoredInfo.sponsoredType;
    }
    let option = slotOptions.find(({ value }) => value === type) ?? null;
    if (!option) {
      eolasLogger.error(new Error("No existing option found in ManageSlotWizard"), {
        exisitingType: type,
      });
      option = slotOptions[0];
    }
    const { seniorities, specialties, jobTitles } = convertToOptionArrays(
      existingSection.sponsoredInfo,
    );
    return {
      name: existingSection.name,
      description: existingSection.description || "",
      type: option,
      seniorities: seniorities.length ? seniorities : undefined,
      specialties: specialties.length ? specialties : undefined,
      jobTitles: jobTitles.length ? jobTitles : undefined,
      countries: existingSection.sponsoredInfo.countries?.map((str) => ({
        value: str,
        label: str,
      })),
      externalUrl: existingSection.sponsoredInfo.url ?? "",
      existingSection,
      isPaid: existingSection.sponsoredInfo.isPaid
        ? { label: "Yes", value: "true" }
        : { label: "No", value: "false" },
    };
  }
  return {
    name: "",
    description: "",
    type: slotOptions[0],
    specialties: [],
    seniorities: [],
    countries: [],
    jobTitles: [],
    externalUrl: "",
    isPaid: { label: "Yes", value: "true" },
  };
};

const convertToOptionArrays = ({
  specialties,
  jobTitles,
  seniorities,
}: {
  specialties?: string[];
  jobTitles?: string[];
  seniorities?: string[];
}) => {
  const newSpecialties: SpecialtyOptions[] = [];
  const newJobTitles: JobTitleOptions[] = [];
  const newSeniorities: SeniorityOptions[] = [];
  const failedStrings: string[] = [];
  const makePushToFunction =
    <T extends SpecialtyValue | JobTitleValue | SeniorityValue>({
      lookupOptions,
      newArr,
      failedStrings,
    }: {
      lookupOptions: { value: T; label: string }[];
      newArr: { value: T; label: string }[];
      failedStrings: string[];
    }) =>
    (item: string) => {
      const option = lookupOptions.find(({ value }) => {
        const strValue: string = value;
        return strValue === item;
      });
      if (option) {
        newArr.push(option);
      } else {
        failedStrings.push(item);
      }
    };

  if (specialties) {
    const pushFn = makePushToFunction({
      lookupOptions: specialtyOptions,
      newArr: newSpecialties,
      failedStrings,
    });
    specialties.forEach(pushFn);
  }

  if (jobTitles) {
    const pushFn = makePushToFunction({
      lookupOptions: jobTitleOptions,
      newArr: newJobTitles,
      failedStrings,
    });
    jobTitles.forEach(pushFn);
  }

  if (seniorities) {
    const pushFn = makePushToFunction({
      lookupOptions: seniorityOptions,
      newArr: newSeniorities,
      failedStrings,
    });
    seniorities.forEach(pushFn);
  }

  if (failedStrings.length) {
    eolasLogger.error(new Error("Manage Slots: some values for job stats do no have lookups"), {
      failedStrings,
    });
  }

  return { specialties: newSpecialties, seniorities: newSeniorities, jobTitles: newJobTitles };
};
