import {
  CommunityMedicationsIcon,
  GuidelinesIcon,
  MagnifyingGlassIcon,
  MyFilesIcon,
  MyPortfolioIcon,
  SpaceIcon,
  knowledgeBackground,
  knowledgePhone,
  meBackground,
  mePhone,
  spaceBackground,
  spacePhone,
} from "Assets";
import React from "react";
import { useTranslation } from "react-i18next";

const usePostCognitoSteps = () => {
  const { t } = useTranslation();

  const postCognitoSteps = [
    {
      backgroundImg: meBackground,
      phoneImg: mePhone,
      title: { text: t("general_me_tab"), color: "yellow-500" },
      content: [
        {
          icon: <MyPortfolioIcon className="w-6 h-6" />,
          text: t("post_cognito_create_profile_description"),
        },
        {
          icon: <MyFilesIcon className="w-6 h-6" />,
          text: t("post_cognito_file_storage_description"),
        },
      ],
    },
    {
      backgroundImg: spaceBackground,
      phoneImg: spacePhone,
      title: { text: t("general_spaces_tab"), color: "blue-500" },
      content: [
        {
          icon: <SpaceIcon className="w-6 h-6" />,
          text: t("post_cognito_access_workspace_description"),
        },
        {
          icon: <MagnifyingGlassIcon className="w-6 h-6" />,
          text: t("post_cognito_discover_spaces_description"),
        },
      ],
    },
    {
      backgroundImg: knowledgeBackground,
      phoneImg: knowledgePhone,
      title: { text: t("general_knowledge_tab"), color: "green-500" },
      content: [
        {
          icon: <CommunityMedicationsIcon className="w-6 h-6" />,
          text: t("post_cognito_explore_medications_description"),
        },
        {
          icon: <GuidelinesIcon className="w-6 h-6" />,
          text: t("post_cognito_access_resources_description"),
        },
      ],
    },
  ];

  return { postCognitoSteps };
};

export default usePostCognitoSteps;
