import * as icons from "Assets/Icons";

import { FormError } from "../../FormElements";
import { Title, Text } from "../../Typography";
import { Button, ButtonProps } from "../../Button";
import { ModalBody, ModalHeader, InnerModalWrapper } from "../components";

export interface ConfirmationModalProps {
  error?: string;
  title: string;
  description: string;
  acceptLabel?: string;
  declineLabel?: string;
  iconType?: icons.EolasIcons;
  acceptButtonProps?: ButtonProps;
  declineButtonProps?: ButtonProps;
  onAccept(e: React.MouseEvent<HTMLButtonElement>): void;
  onDecline(e: React.MouseEvent<HTMLButtonElement>): void;
  closeModal?: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  error,
  title,
  iconType,
  description,
  acceptButtonProps,
  declineButtonProps,
  acceptLabel = "OK",
  declineLabel = "Cancel",
  onAccept,
  onDecline,
  closeModal,
}: ConfirmationModalProps) => {
  const Icon = iconType ? icons[iconType] : null;

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        {Icon && <Icon className="h-12 w-12 mb-4" />}
        <Title level={5}>{title}</Title>
      </ModalHeader>

      <ModalBody className="space-y-4 sm:space-y-8">
        <Text level={1} className="text-center">
          {description}
        </Text>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
          <Button
            size="lg"
            color="grey"
            variant="outline"
            onClick={(e) => {
              onDecline(e);
              closeModal?.();
            }}
            className="order-1 sm:order-0"
            {...declineButtonProps}
          >
            {declineLabel}
          </Button>

          <Button
            size="lg"
            onClick={(e) => {
              onAccept(e);
              closeModal?.();
            }}
            className="order-0 sm:order-1"
            {...acceptButtonProps}
          >
            {acceptLabel}
          </Button>
        </div>

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
