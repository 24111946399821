import { MutateOptions, useQueryClient } from "@tanstack/react-query";
import useAddKnowledgeContentLink from "./useAddKnowledgeContentLink";
import { mapToBlobItemDto, mapToLinkItemDto } from "modules/contentRepository/helpers";
import { AddContentItemDto } from "modules/contentRepository/types";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import useAddKnowledgeContentFile from "./useAddKnowledgeContentFile";
import { getBlobType } from "Utilities/fileHelpers";
import { KnowledgeContentDomain } from "@eolas-medical/core";

interface UseAddKnowledgeContentItemProps {
  mainSectionId: string | null;
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useAddKnowledgeContentItem = ({
  mainSectionId,
  parentId,
  domain,
}: UseAddKnowledgeContentItemProps) => {
  const queryClient = useQueryClient();

  const { addLink, addingLink, addingLinkSuccess, addingLinkError } = useAddKnowledgeContentLink();

  const { addFile, addingFile, addingFileSuccess, addingFileError, addingFileProgress } =
    useAddKnowledgeContentFile();

  const handleAddContentItem = (
    item: AddContentItemDto,
    options: MutateOptions<void, unknown, AddContentItemDto, unknown> | undefined,
  ) => {
    if (!mainSectionId || !parentId) {
      throw new Error("MainSectionId or parentId missing");
    }
    if (item.itemType === "link" && item.linkUrl) {
      return addLink(
        mapToLinkItemDto({
          mainSectionId,
          parentId,
          name: item.name,
          url: item.linkUrl,
          createdBy: item.createdBy,
          isDraft: item.isDraft,
        }),
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(knowledgeContentKeys.items(parentId, domain));
            if (options && options.onSuccess) {
              options.onSuccess(data, item, undefined);
            }
          },
        },
      );
    }

    if (item.itemType === "blob" && item.file) {
      const fileType = getBlobType(item.file.type);

      return addFile(
        {
          blobDto: mapToBlobItemDto({
            mainSectionId,
            parentId,
            name: item.name,
            createdBy: item.createdBy,
            description: item.description,
            expiryDate: item.expiryDate,
            keywords: item.keywords,
            fileType,
            isDraft: item.isDraft,
          }),
          file: item.file,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(knowledgeContentKeys.items(parentId, domain));
          },
        },
      );
    }
  };

  return {
    addContentItem: handleAddContentItem,
    addingContentItem: addingLink || addingFile,
    addContentItemSuccess: addingLinkSuccess || addingFileSuccess,
    addContentItemError: addingLinkError || addingFileError,
    addContentItemProgress: addingFileProgress,
  };
};

export default useAddKnowledgeContentItem;
