import { GreenPadlockIcon } from "Assets";
import { Button, InnerModalWrapper, Modal, ModalBody, ModalHeader, Title } from "UIKit";
import { useTranslation } from "react-i18next";

interface RedirectInfoModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const RedirectInfoModal = ({ handleClose, isOpen }: RedirectInfoModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("landingPage_redirect_info_title")}</Title>
        </ModalHeader>

        <ModalBody className="space-y-4 sm:space-y-8 flex flex-col items-center justify-center">
          <GreenPadlockIcon className="w-32 h-32" />
          <p className="text-xl font-semibold text-center">{t("landingPage_redirect_info_text")}</p>

          <Button color="blue" onClick={handleClose}>
            {t("accessLink_button_confirm")}
          </Button>
        </ModalBody>
      </InnerModalWrapper>
    </Modal>
  );
};
