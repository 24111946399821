import { FavouriteToggle } from "UIKit";
import {
  EntityAvailability,
  EntityOwnerType,
  FavouriteEntityType,
} from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { ShowItemOwner } from "./ShowOwnerItem";
import {
  AnalyticsEvents,
  FavouriteGeneralPayload,
  MeLevelSection,
  getEntityIdSuffix,
} from "@eolas-medical/core";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { trackEvent } from "API/Analytics";
import { useTranslation } from "react-i18next";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

interface MyFavouritesTileProps {
  entityOwnerType: EntityOwnerType;
  entityAvailability?: EntityAvailability;
  entityType: FavouriteEntityType;
  entityId: string;
  title: string;
  ownerName?: string;
  mainSectionName: string;
  subSectionName?: string;
  Icon: React.ReactNode;
  favouriteId: string;
  analyticsCallback: () => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
  onShare?: () => void;
}

const MyFavouritesTile = ({
  entityOwnerType,
  Icon,
  title,
  entityType,
  entityId,
  ownerName,
  subSectionName,
  mainSectionName,
  showRemoveFavouriteModal,
  favouriteId,
  analyticsCallback,
  onShare,
  entityAvailability,
}: MyFavouritesTileProps) => {
  const shouldShowShare = !!onShare;
  const shouldShowFavouriteToggle = !!showRemoveFavouriteModal;
  const { t } = useTranslation();
  const handleTileClick = (e: React.MouseEvent) => {
    analyticsCallback();
    if (entityAvailability === EntityAvailability.AVAILABLE) {
      return;
    }
    if (!showRemoveFavouriteModal) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const getErrorTitle = (): string | undefined => {
      switch (entityAvailability) {
        case EntityAvailability.DELETED:
          return t("my_favourite_deleted_error_title");
        case EntityAvailability.NOT_AVAILABLE:
          if (entityOwnerType === EntityOwnerType.KNOWLEDGE) {
            return t("my_favourite_region_access_error_title");
          }
          return t("my_favourite_access_denied_title", { entityOwnerType });
      }
    };

    showRemoveFavouriteModal({
      entityId,
      originForAnalytics: MeLevelSection.myFavourites,
      entityType,
      favouriteId,
      title,
      modalTitle: getErrorTitle(),
      modalDescription: t("my_favourite_remove_description"),
      wasFileDeleted: entityAvailability === EntityAvailability.DELETED,
    });
    return;
  };
  return (
    <div
      onClick={handleTileClick}
      className="bg-white w-full rounded-lg p-4 cursor-pointer border border-1 hover:bg-blue-50 border-grey-300  grid grid-cols-8"
    >
      <div className="col-start-1 col-end-7 flex flex-col items-start">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-4 h-4" data-testid="circle-icon">
            {Icon}
          </div>
          <div className="flex">
            <span className="text-grey-600 font-semibold ml-2">{`${mainSectionName} ${
              subSectionName ? ">" : ""
            } ${subSectionName ?? ""}`}</span>
          </div>
        </div>

        <p className="line-clamp-2 mt-6 font-bold text-lg">{title}</p>

        <ShowItemOwner
          entityType={entityType}
          ownerName={ownerName}
          entityOwnerType={entityOwnerType}
          parentSectionName={subSectionName}
        />
      </div>
      {shouldShowShare || shouldShowFavouriteToggle ? (
        <div className="col-start-8 col-end-8 flex flex-col items-start">
          <div className="self-end">
            {shouldShowFavouriteToggle && (
              <FavouriteToggle
                isLoading={false}
                onClick={() =>
                  showRemoveFavouriteModal({
                    entityId,
                    originForAnalytics: MeLevelSection.myFavourites,
                    entityType,
                    favouriteId,
                    title,
                  })
                }
                entityId={entityId + getEntityIdSuffix(entityType)}
              />
            )}
            {shouldShowShare && (
              <ShareFileButton
                onShareFile={() => {
                  trackEvent<FavouriteGeneralPayload>(AnalyticsEvents.FAVOURITE_SHARED, {
                    fileId: entityId,
                    title,
                  });
                  onShare();
                }}
                id={title}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyFavouritesTile;
