import { useQuery } from "@tanstack/react-query";
import {
  MedicationItemDetails,
  MonographType,
  bnfClient,
  drugTransformer,
} from "@eolas-medical/core";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";
import bnfKeys from "./bnf.queryKeys";
import { itemIsDrug } from "../typeguards";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { mapToListableItem } from "../mappers";
import { ListableDisplayItem } from "../types";
import { errorStore } from "Stores/ErrorStore";

interface UseGetMedicationsItem {
  drugItemArray: ListableDisplayItem[];
  title: string | null;
  isLoading: boolean;
}

const useGetMedicationItem = ({
  idOrSid,
  type,
}: {
  idOrSid: string | null;
  type: MonographType;
}): UseGetMedicationsItem => {
  const { flags } = useLaunchDarkly();

  const isInteractionsEnabled = !!flags[LDFlagNames.BNF_INTERACTIONS_FEATURE];

  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.bnfItem(idOrSid, type, isInteractionsEnabled),
    queryFn: async () => {
      if (!idOrSid) {
        throw new Error("Unable to get BNF item: idOrSid is null");
      }
      return bnfClient.getItemDetailsByIdOrSid({ itemIdOrSid: idOrSid, type });
    },
    select: (data: MedicationItemDetails) => {
      if (itemIsDrug(data)) {
        return {
          title: data.name,
          array: drugTransformer(data.data.drug, isInteractionsEnabled).map(mapToListableItem),
        };
      }
      errorStore.captureError({
        error: new Error(`Unexpected item for BNF medication item. Contains: ${Object.keys(data)}`),
        source: "user",
      });
      return null;
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
    enabled: Boolean(type),
  });

  return {
    drugItemArray: data?.array ?? [],
    title: data?.title ?? null,
    isLoading: isInitialLoading,
  };
};

export default useGetMedicationItem;
