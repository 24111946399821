// All config keys are snake_case ;(
/* eslint-disable camelcase */

import { IAllProps as EditorProps } from "@tinymce/tinymce-react";

export const DEFAULT_MCE_CONFIG: { plugins: string[]; init: EditorProps["init"] } = {
  plugins: ["lists", "link", "image", "code"],
  init: {
    relative_urls: false,
    remove_script_host: false,
    content_style: `
    @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
    body { font-family: 'Nunito', sans-serif; font-size: 14px; }
  `,
    font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
    font_family_formats:
      "Nunito=Nunito, sans-serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
    promotion: false,
  },
};
