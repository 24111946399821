import { ReactPlayerProps } from "react-player";
import { useCallback, useState, useRef } from "react";
import {
  isFileCompleted,
  sectionStore,
  EolasFile,
  CompletedContentStatus,
} from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { useCompleteFile, useSelectFile } from "Hooks";

const VIDEO_COMPLETION_THRESHOLD = 0.75;

export const useLearningManagement = () => {
  const isAdmin = sectionStore.isAdmin;
  const { error } = useRequestStatus();
  const { onCompleteFile } = useCompleteFile();
  const { onSelectFile: selectFile } = useSelectFile();

  const videoMarkedAsComplete = useRef(false);
  const videoRef = useRef<HTMLHeadingElement>(null);
  const [modal, setModal] = useState<React.ReactNode>(null);
  const [video, setVideo] = useState({ name: "", url: "", fileId: "", isCompleted: false });

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onCloseVideo = () => setVideo({ name: "", url: "", fileId: "", isCompleted: false });

  const onVideoProgress: ReactPlayerProps["onProgress"] = (playerState) => {
    if (
      (playerState.played >= VIDEO_COMPLETION_THRESHOLD || isFileCompleted(video.fileId)) &&
      !video.isCompleted
    ) {
      if (!videoMarkedAsComplete.current) {
        videoMarkedAsComplete.current = true;
        if (isAdmin) return;
        onCompleteFile(video.fileId, CompletedContentStatus.SEEN);
      }
    }
  };

  const onSelectFile = async ({ id = "", key = "", type = "", moreInfoTwo = "" }: EolasFile) => {
    const isCompleted = isFileCompleted(id);

    const url = await selectFile({ id, type, key, parentID: "", ownerID: "", sharedID: "" });

    if (type === "mp4") {
      setVideo({ name: moreInfoTwo as string, url, fileId: id, isCompleted });

      videoMarkedAsComplete.current = false;
      if (videoRef.current) {
        videoRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    } else if (!isCompleted) {
      if (isAdmin) return;
      onCompleteFile(id, CompletedContentStatus.SEEN);
    }
  };

  return {
    error,
    modal,
    video,
    setVideo,
    setModal,
    videoRef,
    onSelectFile,
    onCloseModal,
    onCloseVideo,
    onVideoProgress,
  };
};
