import { HandleClickRemoveFavouriteProps, RemoveFavouriteModal } from "../RemoveFavouriteModal";
import { useModalState } from "shared/hooks/useModalState/useModalState";

export const useRemoveFavouriteModal = () => {
  const { modal, openModal, closeModal, isOpen } = useModalState();

  const showRemoveFavouriteModal = (props: HandleClickRemoveFavouriteProps) => {
    return openModal(<RemoveFavouriteModal {...props} onCloseModal={closeModal} />);
  };

  return {
    showRemoveFavouriteModal,
    removeFavouriteModal: modal,
    closeFavouriteModal: closeModal,
    isFavouriteModalOpen: isOpen,
  };
};
