import { useHistory } from "react-router-dom";
import { memo } from "react";
import { Variants, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { SponsoredInfoSection } from "@eolas-medical/core";
import { DeleteIcon, EditMonoIcon, EyeIcon, OperationsIcon } from "Assets";
import { useModal } from "Hooks/useModal";
import { Button, Dropdown, DropdownOption, IconButton, Pill, Text, Title } from "UIKit";
import { Sizes } from "UIKit/types";
import { Action } from "Utilities/types";
import DeleteSponsoredSlotModal from "./DeleteSponsoredSlotModal";
import { useOverrideClick } from "Hooks/useOverrideClick";
import { ManageSlotWizardModal } from "./ManageSlotWizardModal";
import { PublishConfirmationModal } from "./PublishConfirmationModal";
import { usePublishDraftSponsoredSlot } from "modules/sponsoredSlots/data/usePublishDraftSponsoredSlot";
import { slotOptions } from "../data/slotOptions";
import { SlotType } from "../types/general";
import { makeSponsoredContentRoute } from "../functions/makeSponsoredContentRoute";
import { useNotifications } from "Components/Notifications";
import { defaultReactQueryOnError } from "config";
import { PreviewModal } from "./PreviewModal";

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const menuOptions: DropdownOption[] = [
  {
    label: Action.DELETE,
    value: "delete",
    icon: <DeleteIcon width={16} height={16} />,
  },
  {
    label: Action.EDIT,
    value: "edit",
    icon: <EditMonoIcon width={16} height={16} />,
  },
  {
    label: Action.PREVIEW,
    value: "preview",
    icon: <EyeIcon width={16} height={16} />,
  },
];

export const SponsoredSlotTile = memo(({ section }: { section: SponsoredInfoSection }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { showNotification } = useNotifications();

  const { showModal: showDeleteModal, modal: deleteModal } = useModal({
    Modal: DeleteSponsoredSlotModal,
    modalProps: { section },
  });

  const { showModal: onClickEdit, modal: editSlotModal } = useModal({
    Modal: ManageSlotWizardModal,
    modalProps: { mainSectionId: section.mainSectionId, existingSection: section },
    modalContainerProps: { shouldCloseOnOverlayClick: false },
  });

  const { publish, isLoadingPublish } = usePublishDraftSponsoredSlot({
    onSuccess: () => {
      showNotification({
        type: "success",
        description: t("content_item_publish_success"),
        autoHideTimeout: 5000,
      });
    },
    onError: (error) => {
      showNotification({
        type: "error",
        description: t("content_item_publish_error"),
        autoHideTimeout: 5000,
      });
      return defaultReactQueryOnError(error);
    },
  });

  const { showModal: onClickPublish, modal: publishSlotModal } = useModal({
    Modal: PublishConfirmationModal,
    modalProps: { handleAccept: () => publish(section) },
  });

  const { showModal: onClickPreview, modal: previewModal } = useModal({
    Modal: PreviewModal,
    modalProps: {
      bannerUrl: section.sponsoredInfo.bannerUrl,
      squareBannerUrl: section.sponsoredInfo.squareBannerUrl,
    },
  });

  const handleActionMenuSelect = (option: DropdownOption) => {
    if (option.value === "delete") {
      showDeleteModal();
    }

    if (option.value === "edit") {
      onClickEdit();
    }

    if (option.value === "preview") {
      onClickPreview();
    }
  };

  const onClickPublishDraft = useOverrideClick(onClickPublish);

  let type: SlotType = "BANNER";

  if (section.sponsoredInfo.sponsoredType === "CONTENT") {
    if (section.childrenType === "CONTENT") {
      type = "CONTENT_ITEMS";
    }
    if (section.childrenType === "SECTION") {
      type = "CONTENT_SECTIONS";
    }
  }
  if (section.sponsoredInfo.sponsoredType === "URL") {
    type = "URL";
  }

  const sponsoredTypeLabel = slotOptions.find(({ value }) => value === type)?.label;

  const handleClick = (): boolean => {
    switch (section.sponsoredInfo.sponsoredType) {
      case "BANNER":
        return false;
      case "CONTENT":
        push(makeSponsoredContentRoute(section));
        return true;
      case "URL":
        window.open(section.sponsoredInfo.url, "_blank");
        return true;
    }
  };

  return (
    <>
      <motion.div
        variants={childrenVariants}
        whileHover={{ scale: 1.01 }}
        className={`grid grid-cols-12 transition-all bg-white h-40 rounded-lg shadow-sm${
          section.sponsoredInfo.sponsoredType !== "BANNER" ? " cursor-pointer" : ""
        }`}
        onClick={handleClick}
      >
        <div className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 rounded-l-lg overflow-hidden relative">
          <img
            src={section.sponsoredInfo.squareBannerUrl}
            srcSet={section.sponsoredInfo.squareBannerUrl}
            alt=""
            className="w-full h-full object-cover rounded-l-lg"
          />
        </div>

        <div className="row-start-1 col-start-4 col-end-10 lg:col-start-3 flex flex-col justify-between py-5 pl-5 pr-0">
          <div>
            <Title className="line-clamp-2" level={9}>
              {section.name}
            </Title>
            <Text level={1} className="text-grey-700 line-clamp-2">
              {section.description}
            </Text>
            {sponsoredTypeLabel ? (
              <div className="flex mt-2">
                <Pill
                  value={sponsoredTypeLabel}
                  className="bg-blue-50 border border-blue-600 text-blue-600"
                  size={Sizes.EXTRA_SMALL}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="row-start-1 row-end-2 col-start-12 flex justify-center p-5">
          {section.isDraft ? (
            <Pill
              value={t("content_item_is_draft")}
              className="bg-yellow-100 text-yellow-800 h-6 text-sm w-12 font-bold self-start"
              size={Sizes.MEDIUM}
            />
          ) : (
            <Pill
              value={t("content_item_is_published")}
              className="bg-green-50 text-green-500 h-6 text-sm w-20 font-bold self-start"
              size={Sizes.MEDIUM}
            />
          )}
        </div>
        <div className="row-start-1 row-end-2 col-start-13  gap-y-0.5 flex p-5 flex-col items-end justify-start">
          {menuOptions.length > 0 && (
            <Dropdown
              dropdownToggle={
                <IconButton
                  type="button"
                  variant="rounded"
                  icon={<OperationsIcon />}
                  className="bg-transparent"
                />
              }
              options={menuOptions}
              onSelect={handleActionMenuSelect}
            />
          )}
          {section.isDraft ? (
            <div className="self-end">
              <Button
                onClick={onClickPublishDraft}
                value={t("content_item_is_draft")}
                className="text-xs px-2"
                childrenContainerClassname="py-1"
                color="blue"
                variant="outline"
                size={Sizes.SMALL}
                isLoading={isLoadingPublish}
              >
                {t("content_item_publish")}
              </Button>
            </div>
          ) : null}
        </div>
      </motion.div>
      {deleteModal}
      {editSlotModal}
      {publishSlotModal}
      {previewModal}
    </>
  );
});
