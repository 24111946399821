import { sectionStore } from "@eolas-medical/core";
import { LocalResult } from "Components/MasterSearch/types";
import { useHistory } from "react-router-dom";

type UseGoToFile = {
  onGoToFile: (file: LocalResult) => Promise<void>;
};

// Helper function to remove the "SEC#" prefix from IDs.
const cleanID = (id: string, isGenericContentRepo: boolean): string =>
  isGenericContentRepo ? id.replace("SEC#", "") : id;

export const useGoToFile = (): UseGoToFile => {
  const { push } = useHistory();

  const onGoToFile = async (file: LocalResult): Promise<void> => {
    const isGenericContentRepo = file.searchSubType === "genericContentRepository";

    const isOrgFile = sectionStore.hospitalSectionsOrder
      .map((section) => section.id)
      .includes(file?.file?.mainSectionID ?? "");

    // Extract the part of the path to keep (organization or space name and any subsequent segments)
    const pathSegments = location.pathname.split("/");
    // Assuming the first segment after either 'spaces' or 'organisation' is the name
    const basePathIndex =
      pathSegments.findIndex((segment) => segment === "spaces" || segment === "organisation") + 1;
    const basePathSegments = pathSegments.slice(basePathIndex);
    const newPathSegments = [""];

    // switch between space and organisation if needed
    if (isOrgFile) {
      newPathSegments.push("organisation", ...basePathSegments);
    } else {
      newPathSegments.push("spaces", ...basePathSegments);
    }

    const newPath = newPathSegments.join("/");

    // Determine the subsection if needed.
    const subSection =
      file.file.mainSectionID !== file.file.parentID
        ? `/${encodeURIComponent(cleanID(file?.file?.parentID ?? "", isGenericContentRepo))}`
        : "";

    // Determine the main section type and ID.
    const mainSectionID = encodeURIComponent(
      cleanID(file?.file?.mainSectionID ?? "", isGenericContentRepo),
    );
    const mainSectionType = isGenericContentRepo
      ? `content-repository/${mainSectionID}`
      : file.searchSubType;

    const fileRoute =
      file.searchSubType === "checklists"
        ? `/details/${encodeURIComponent(file.file.id ?? "")}`
        : "";

    const fileQueryParam = `?fileId=${encodeURIComponent(file.file.id ?? "")}`;

    let route = `${newPath}/${mainSectionType}${subSection}${fileRoute}${fileQueryParam}`;

    if (file.searchSubType === "medusaMedications") {
      const sectionID = encodeURIComponent(cleanID(file.file.parentID ?? "", isGenericContentRepo));
      route = `${newPath}/${mainSectionType}/${encodeURIComponent(
        file.subSectionName ?? "",
      )}/${sectionID}${fileQueryParam}`;
    }
    push(route);
  };

  return { onGoToFile };
};
