/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import { ClinicalCalculatorsSubSection } from "@eolas-medical/core";

import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { Button, ModalHeader, Title, InnerModalWrapper } from "UIKit";

import { ResultSummary } from "./ResultSummary";
import { BodyWeightResults } from "./BodyWeightResults";
import { ExplanationComponents } from "./ExtraInformation";
import { GentamicinDoseResults } from "./GentamicinResults";
import { CreatinineClearanceResults } from "./CreatinineClearanceResults";

interface ResultsModalProps {
  type: ClinicalCalculatorsSubSection;
  result: any;
  values: any;
  handleClose: () => void;
}

export const ResultsModal: React.FC<ResultsModalProps> = ({
  result,
  values,
  type,
  handleClose,
}: ResultsModalProps) => {
  const summary = useMemo(() => {
    return <ResultSummary {...values} creatinineClearance={result.creatinineClearance} />;
  }, [values, result]);

  const content = useMemo(() => {
    switch (type) {
      case "gentamicinDose":
        return <GentamicinDoseResults results={result} />;
      case "idealBodyWeight":
        return <BodyWeightResults results={result} />;
      case "creatinineClearance":
        return <CreatinineClearanceResults results={result} />;
      default:
        return null;
    }
  }, [type, result]);

  const moreInfo = useMemo(() => ExplanationComponents[type](), [type]);

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>Results</Title>
      </ModalHeader>

      <div className="flex flex-col h-full overflow-y-auto p-10">
        {summary}
        {content}

        <Collapsible title="Information" variant="blue">
          {<div className="p-6 bg-grey-light rounded-lg mt-2">{moreInfo}</div>}
        </Collapsible>
        <div className="flex justify-end mt-8 mb-4">
          <Button onClick={handleClose} size="md" color="blue" type="button">
            Close
          </Button>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
