import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useNotifications } from "Components/Notifications";
import useRemoveFavourite from "modules/myFavourites/data/useRemoveFavourite";
import { useTranslation } from "react-i18next";

type Props = {
  entityId: string | null;
  /** Knowledge Search results in theory could return null here so its good to handle it */
  entityType: FavouriteEntityType | null;
};

export const useRemoveSearchFavourite = ({ entityId, entityType }: Props) => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const showSuccessNotification = () => {
    showNotification({
      type: "success",
      description: t("my_favourites_removed_successful"),
      autoHideTimeout: 5000,
    });
  };

  const showNotificationError = () => {
    showNotification({
      type: "error",
      description: t("my_favourites_remove_error"),
      autoHideTimeout: 5000,
    });
  };

  const { removeFavourite, isRemovingFavourite } = useRemoveFavourite({
    entityId,
    entityType,
    onSuccess: showSuccessNotification,
    onError: showNotificationError,
  });

  return {
    removeFavourite,
    isRemovingFavourite,
  };
};
