import {
  EolasGroupedSection,
  MedicationSummaryItem,
  MonographType,
  Summaries,
  bnfClient,
  makeSectionDataForAdditionalContent,
} from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";

import { BNF_CACHE_TIME, BNF_STALE_TIME } from "modules/bnf/constants";
import bnfKeys from "modules/bnf/data/bnf.queryKeys";

export type GroupedSectionWithId = EolasGroupedSection<MedicationSummaryItem> & { id: string };

export const useGetAdditionalContentSummary = ({ type }: { type: MonographType }) => {
  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.all,
    queryFn: () => bnfClient.getSummaryData(),
    select: (data: Summaries): GroupedSectionWithId[] => {
      const sectionData = makeSectionDataForAdditionalContent(data[type].additionalContentItems);
      return sectionData.map((item) => ({ ...item, id: item.title }));
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return { data, isInitialLoading };
};
