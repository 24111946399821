import { useMemo } from "react";
import "./styles.css";

export interface CheckboxProps {
  children: React.ReactNode;
  id: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?(checked: boolean): void;
  className?: string;
  UnCheckedIcon?: JSX.Element;
  CheckedIcon?: JSX.Element;
  "data-testid"?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  children,
  className = "",
  UnCheckedIcon,
  CheckedIcon,
  "data-testid": dataTestId = "uiCheckbox",
  onChange = () => {
    return;
  },
  ...props
}: CheckboxProps) => {
  const customIcon = useMemo(() => {
    if (checked) {
      return CheckedIcon;
    }
    return UnCheckedIcon;
  }, [UnCheckedIcon, CheckedIcon, checked]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
      className={`flex items-center ${className}`}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        data-testid={dataTestId}
        className={`${customIcon ? "invisible absolute" : "checkboxInput"}`}
        onChange={() => {
          onChange(!checked);
        }}
        {...props}
      />
      <label
        htmlFor={id}
        className={`
          ${customIcon ? "" : "control"}
          flex items-center cursor-pointer text-sm lg:text-base
        `}
      >
        {customIcon}
        {children}
      </label>
    </div>
  );
};
