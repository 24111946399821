import { useCallback } from "react";
import { useFileOperations, useRefetchAppData } from "Hooks";
import { EolasFile, contentClient, sectionStore, AppLevelSection } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const useDeleteSubmittedChecklist = (checklist: EolasFile) => {
  const { removeFile } = useFileOperations();
  const { setRequestStatus, isSuccessful, isLoading, error } = useRequestStatus();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const onDelete = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    const removeFilePromise = useAppServicesEndpoints
      ? removeFile({
          variables: {
            id: checklist.id,
            parentID: checklist.parentID,
          },
        })
      : contentClient.deleteContentItem({
          mainSectionId: sectionStore.getChildReferenceByMainSectionType(AppLevelSection.checklists)
            ?.id as string,
          itemId: checklist.id,
        });

    removeFilePromise
      .then(async () => {
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [
    setRequestStatus,
    checklist.id,
    checklist.parentID,
    removeFile,
    useAppServicesEndpoints,
    refetch,
  ]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDelete,
  };
};
