import { KnowledgeContentDomain } from "@eolas-medical/core";
import useRemoteIcons from "Hooks/useRemoteIcons";
import { modalStore } from "Stores/ModalStore";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import { ContentSection } from "modules/contentRepository/types";
import useEditKnowledgeContentSection from "modules/knowledgeContent/data/useEditKnowledgeContentSection";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";

type Props = {
  contentSection: ContentSection;
  domain: KnowledgeContentDomain;
  variant?: ContentSectionVariants;
};

export const EditKnowledgeContentSectionWizard = ({ contentSection, variant, domain }: Props) => {
  const { remoteIcons } = useRemoteIcons();
  const { wizardControl, submitWizard } = useWizard();

  const { editContentSection, editingContentSection, editContentSectionError } =
    useEditKnowledgeContentSection({ domain });

  const handleSubmit = ({
    name,
    iconName,
    description,
  }: {
    name: string;
    iconName: string;
    description?: string;
  }) => {
    editContentSection(
      {
        id: contentSection.id,
        name,
        description,
        iconName,
      },
      {
        onSuccess: () => {
          modalStore.closeModal();
        },
      },
    );
  };

  const editSectionStep = useConfigureSectionStep<"section">({
    title: "Edit Section",
    stepName: "section",
    iconsList: remoteIcons,
    section: {
      name: contentSection.name || "",
      iconName: contentSection.iconName || "",
      iconUrl: contentSection.iconUrl || "",
      description: contentSection.description || "",
    },
    isLoading: editingContentSection,
    error: editContentSectionError,
    showDescription: variant === "titleAndDescription",
    disableIconPicker: variant === "titleAndDescription",
    onSubmit: handleSubmit,
    onEnter: submitWizard,
    onCancel: modalStore.closeModal,
  });

  const steps: Step<"section">[] = [editSectionStep];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};
