import { useEolasQuery, QueryEnvironment } from "Hooks";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import portfolioKeys from "./portfolio.queryKeys";
import { Event } from "../types";

interface UseEvent {
  event: Event;
  eventLoading: boolean;
}
const useEvent = (eventId: string): UseEvent => {
  const { isLoading, data } = useEolasQuery({
    queryFn: async () => portfolioService.getEvent(eventId).then((data) => data.data),
    queryKey: portfolioKeys.event(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 3000,
      staleTime: 6000,
      enabled: eventId.length > 0,
    },
  });

  return {
    event: data,
    eventLoading: isLoading,
  };
};

export default useEvent;
