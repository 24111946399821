import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";

import { success } from "Assets";
import { removeLastUrlPath } from "Utilities";
import {
  Modal,
  Button,
  FormError,
  PageTitle,
  NavButton,
  InnerPageWrapper,
  LottieWithHeader,
  ConfirmationModal,
} from "UIKit";

import { useChecklistSubmission } from "../hooks";
import { Section, SubmissionIssues } from "../components";
import { sectionStore } from "@eolas-medical/core";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

interface ChecklistSubmissionRouteParams {
  sectionID: string;
  checklistID: string;
}

export const ChecklistSubmission: React.FC<RouteComponentProps<ChecklistSubmissionRouteParams>> = ({
  history: { push },
  match: {
    url,
    params: { checklistID: encodedChecklistID },
  },
}: RouteComponentProps<ChecklistSubmissionRouteParams>) => {
  const checklistID = decodeURIComponent(encodedChecklistID);
  const { t } = useTranslation();
  const checklist = sectionStore.getSection(checklistID);
  const { mainSectionID, parentID } = checklist;
  const {
    error,
    isLoading,
    modalState,
    formMethods,
    isSuccessful,
    template: { checklistSections, checklistName },
    onSubmit,
    onCloseModal,
    onConfirmExit,
    submitChecklist,
    onLeaveChecklist,
  } = useChecklistSubmission(checklistID);

  if (isSuccessful) {
    return (
      <LottieWithHeader
        animationSize="50%"
        animation={success}
        text="Successfully submitted the checklist"
        onComplete={() => push(removeLastUrlPath(url, 2))}
      />
    );
  }

  return (
    <>
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName
          mainSectionId={mainSectionID}
          parentId={parentID ?? ""}
          fileName={checklistName}
        />
        <PageTitle title={checklistName} />
        <NavButton onClick={onLeaveChecklist} />

        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit} className="flex flex-col">
            <div className="flex flex-col space-y-4">
              {checklistSections.map((section, index) => {
                return (
                  <Section
                    section={section}
                    sectionIndex={index}
                    key={`${section.checklistSectionName}-${index}`}
                  />
                );
              })}

              <SubmissionIssues />
            </div>

            <Button
              size="lg"
              type="submit"
              isLoading={isLoading}
              className="mt-8 self-center w-full sm:w-auto"
            >
              {t("checklistSubmission_submit_button")}
            </Button>

            <FormError error={error} />
          </form>
        </FormProvider>
      </InnerPageWrapper>

      <Modal open={modalState !== null} onClose={onCloseModal}>
        {modalState === "confirmSubmission" ? (
          <ConfirmationModal
            error={error}
            iconType="ChecklistIcon"
            onDecline={onCloseModal}
            acceptButtonProps={{ isLoading }}
            onAccept={() => submitChecklist(formMethods.getValues())}
            title={t("checklistSubmission_submit_confirmation_title")}
            acceptLabel={t("checklistSubmission_submit_confirmation_accept_label")}
            description={t("checklistSubmission_submit_confirmation_descritiption")}
            declineLabel={t("checklistSubmission_submit_confirmation_decline_label")}
          />
        ) : null}
        {modalState === "confirmExit" ? (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onConfirmExit}
            iconType="ExitChecklistIcon"
            acceptButtonProps={{ color: "red" }}
            title={t("checklistSubmission_exit_confirmation_title")}
            acceptLabel={t("checklistSubmission_exit_confirmation_accept_label")}
            description={t("checklistSubmission_exit_confirmation_descritiption")}
            declineLabel={t("checklistSubmission_exit_confirmation_decline_label")}
          />
        ) : null}
        /
      </Modal>
    </>
  );
};
