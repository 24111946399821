import { useCallback } from "react";

export const useOverrideClick = <T extends () => unknown>(callback: T) => {
  return useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      callback();
    },
    [callback],
  );
};
