import { WoundManagementChild, WoundManagementItem } from "@eolas-medical/core";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { Strings } from "./WoundCareHistoryItem.strings";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";
import { SummaryGroup } from "../../AdditionalContentHome/components/SummaryGroup";

export const WoundCareTopLevelItem = ({
  topLevelItem,
  makeOnClick,
  title,
}: {
  topLevelItem: WoundManagementItem | null;
  makeOnClick: (nextSection: WoundManagementChild) => () => void;
  title: string | undefined;
}) => {
  if (!topLevelItem) {
    return null;
  }
  return (
    <>
      {topLevelItem.text ? (
        <Collapsible title={Strings.introTitle(title ?? "")}>
          <BnfContentElement htmlContent={topLevelItem.text} />
        </Collapsible>
      ) : null}
      <SummaryGroup
        makeOnClick={makeOnClick}
        items={topLevelItem.children}
        itemTitleProperty="title"
        title={title ?? ""}
      />
    </>
  );
};
