import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import {
  Button,
  FileTypeItemIcon,
  InnerModalWrapper,
  Loader,
  SuccessModal,
  Text,
  Title,
} from "UIKit";

import {
  AppLevelSection,
  EolasFile,
  SupportedFileType,
  OrganisationLevelSection,
} from "@eolas-medical/core";
import { format } from "date-fns";
import useGetContentItemAnalytics from "modules/contentRepository/data/useGetContentItemAnalytics";

export interface NewsItemStatsProps {
  newsItem: EolasFile;
  sectionType: AppLevelSection.newsFeed | OrganisationLevelSection.communicationPortal;
  onSendReminder: (id: string) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  onCompleteSuccess?: () => void;
  canSendReminder?: boolean;
}

export const NewsItemStatsModal: React.FC<NewsItemStatsProps> = ({
  newsItem,
  sectionType,
  onSendReminder,
  isLoading,
  isSuccess,
  onCompleteSuccess,
  canSendReminder,
}: NewsItemStatsProps) => {
  const { t } = useTranslation();

  const { contentItemAnalytics, isLoadingContentItemAnalytics, contentItemAnalyticsError } =
    useGetContentItemAnalytics({
      mainSectionId: newsItem.mainSectionID,
      contentId: newsItem.id,
    });

  const statsData = {
    type: "",
    labels: [t("analytics_stats_opened"), t("analytics_stats_not_opened")],
    datasets: [
      {
        data: [contentItemAnalytics?.totalRead, contentItemAnalytics?.totalUnread],
        backgroundColor: ["#3374ED", "#D0DFFB"],
        borderColor: ["#3374ED", "#D0DFFB"],
        borderWidth: 2,
      },
    ],
  };

  if (isSuccess && onCompleteSuccess) {
    return (
      <SuccessModal onComplete={onCompleteSuccess} text={t("analytics_stats_reminder_sent")} />
    );
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-col px-8 py-4 lg:py-6 border-b border-grey-300">
        <>
          <div className="flex">
            <FileTypeItemIcon type={newsItem.type as SupportedFileType} className="w-8 h-8 mr-1" />
            <Title level={5}>{newsItem.name}</Title>
          </div>
          <Text level={2} className="text-grey-600">
            {newsItem.createdBy}
          </Text>
          <Text level={2} className="text-grey-600">
            {newsItem.createdAt ? format(new Date(newsItem.createdAt), "do MMMM yyyy") : null}
          </Text>
        </>
      </div>
      <div className="flex flex-col p-4 lg:p-8">
        <div className="self-center h-64 w-64 mb-8 relative">
          {contentItemAnalyticsError && (
            <div className="h-20 flex items-center justify-center">
              <span className="text-red text-center">{contentItemAnalyticsError.message}</span>
            </div>
          )}
          {isLoadingContentItemAnalytics ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col items-center absolute -bottom-6 -right-6">
                <Text level={1} data-testid="totalOpens" className="font-bold">
                  {contentItemAnalytics?.readRatio} %
                </Text>
                <Title level={9} className="text-grey-600 font-bold">
                  {t("analytics_stats_total_opens")}
                </Title>
              </div>
              <Doughnut data={statsData} type="line" />
            </>
          )}
        </div>
        <Button
          onClick={() => onSendReminder(newsItem.id)}
          isLoading={isLoading}
          disabled={!!contentItemAnalyticsError || !canSendReminder}
          className="self-center mt-6"
        >
          {t(`${sectionType}_send_read_reminder`)}
        </Button>
        <div className="mx-auto text-center">
          <Text level={3} className="text-blue-500">
            {t("analytics_stats_reminder_information")}
          </Text>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
