import { EventStatus, EventType, FeedbackStatus } from "modules/portfolio/types";
import { theme } from "UIKit";

const useTimelineButtonConfig = () => {
  const colors = theme.colors;

  const eventStatusButtonConfig = {
    [EventStatus.IN_PROGRESS]: {
      badgeText: "Draft",
      badgeTextColor: "text-grey-900",
      status: EventStatus.IN_PROGRESS,
      backgroundColor: "bg-grey-300",
      fill: colors.grey[600],
    },
    [EventStatus.PENDING_REVIEW]: {
      badgeText: "Pending",
      badgeTextColor: "text-yellow-700",
      status: EventStatus.PENDING_REVIEW,
      backgroundColor: "bg-yellow-50",
      fill: colors.yellow[600],
    },
    [EventStatus.DECLINED]: {
      badgeText: "Declined",
      badgeTextColor: "text-red-600",
      status: EventStatus.DECLINED,
      backgroundColor: "bg-red-100",
      fill: colors.red[500],
    },
    [EventStatus.COMPLETE]: {
      badgeText: "Completed",
      badgeTextColor: "text-green-500",
      status: EventStatus.COMPLETE,
      backgroundColor: "bg-green-50",
      fill: colors.green[500],
    },
  };

  const feedbackStatusButtonConfig = {
    [FeedbackStatus.REVIEW_IN_PROGRESS]: {
      badgeText: "Pending Review",
      badgeTextColor: "text-yellow-700",
      status: FeedbackStatus.REVIEW_IN_PROGRESS,
      backgroundColor: "bg-yellow-50",
      fill: colors.yellow[600],
    },
    [FeedbackStatus.COMPLETE]: {
      badgeText: "Completed",
      badgeTextColor: "text-green-500",
      status: FeedbackStatus.COMPLETE,
      backgroundColor: "bg-green-50",
      fill: colors.green[500],
    },
    [FeedbackStatus.DECLINED]: {
      badgeText: "Declined",
      badgeTextColor: "text-red-600",
      status: FeedbackStatus.DECLINED,
      backgroundColor: "bg-red-100",
      fill: colors.red[500],
    },
  };

  const eventTypeButtonConfig: { [key in EventType]: { badgeText: string } } = {
    [EventType.AUDIT_QI_PROJECT]: {
      badgeText: "Audit/QI Project",
    },
    [EventType.CLINICAL_EVENT]: {
      badgeText: "Clinical Event",
    },
    [EventType.MEETING]: {
      badgeText: "Meeting",
    },
    [EventType.MULTI_SOURCE_FEEDBACK]: {
      badgeText: "Multi source feedback",
    },
    [EventType.PDP]: {
      badgeText: "Personal Development Plan",
    },
    [EventType.PROCEDURAL_SKILL]: {
      badgeText: "Procedural Skill",
    },
    [EventType.REFLECTION]: {
      badgeText: "Reflection",
    },
    [EventType.TEACHING_DELIVERY]: {
      badgeText: "Teaching Delivery",
    },
    [EventType.ULTRASOUND_LOG]: {
      badgeText: "Ultrasound log",
    },
    [EventType.CASE_DISCUSSION]: {
      badgeText: "Case Discussion",
    },
    [EventType.ULTRASOUND_VASCULAR_ACCESS]: {
      badgeText: "Ultrasound - Vascular Access",
    },
    [EventType.ULTRASOUND_CARDIAC]: {
      badgeText: "Ultrasound - Cardiac",
    },
    [EventType.ULTRASOUND_DVT]: {
      badgeText: "Ultrasound - DVT",
    },
    [EventType.ULTRASOUND_THORACIC]: {
      badgeText: "Ultrasound - Thoracic",
    },
    [EventType.ULTRASOUND_ABDOMINAL_RENAL]: {
      badgeText: "Ultrasound - Abdominal/Renal",
    },
    [EventType.ULTRASOUND_AAA]: {
      badgeText: "Ultrasound - AAA",
    },
    [EventType.ULTRASOUND_ECHO_IN_LIFE_SUPPORT]: {
      badgeText: "Ultrasound - Echo in Life Support",
    },
    [EventType.ULTRASOUND_FAST]: {
      badgeText: "Ultrasound - FAST",
    },
    [EventType.ACUTE_CARE_ASSESSMENT_TOOL]: {
      badgeText: "Acute Care Assessment Tool",
    },
    [EventType.DIRECT_OBSERVED_PROCEDURAL_SKILL]: {
      badgeText: "Directly observed procedural Skills (DoPs)",
    },
    [EventType.ADVANCED_PRACTICE_ANNUAL_APPRAISAL]: {
      badgeText: "Advanced Practice Annual Appraisal",
    },
    [EventType.EXTENDED_SUPERVISED_LEARNING_EVENT]: {
      badgeText: "Extended Supervised Learning Events (ESLE)",
    },
  };

  return { eventStatusButtonConfig, feedbackStatusButtonConfig, eventTypeButtonConfig };
};

export default useTimelineButtonConfig;
