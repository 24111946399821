import { FormElement, Input } from "UIKit";
import { ChooseFileTypeValues } from "../types";
import { FieldErrors, Control } from "react-hook-form";

interface LinkFormProps {
  control: Control<ChooseFileTypeValues>;
  onEnter: () => void;
  nameLabel: string;
  namePlaceholder: string;
  urlLabel: string;
  urlPlaceholder: string;
  errors?: FieldErrors<ChooseFileTypeValues>;
  keywordsLabel: string;
  keywordsPlaceholder: string;
}

export const LinkForm = ({
  control,
  nameLabel,
  namePlaceholder,
  urlLabel,
  urlPlaceholder,
  errors,
  keywordsLabel,
  keywordsPlaceholder,
  onEnter,
}: LinkFormProps) => {
  return (
    <form onSubmit={onEnter} className="link-form">
      <FormElement
        required
        id="link.name"
        control={control}
        labelClassName="font-semibold"
        label={nameLabel}
        errorMessage={errors?.link?.name?.message}
      >
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            data-testid="form-element-link"
            placeholder={namePlaceholder}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="link.url"
        control={control}
        labelClassName="font-semibold"
        label={urlLabel}
        errorMessage={errors?.link?.url?.message}
      >
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            data-testid="form-element-name"
            placeholder={urlPlaceholder}
          />
        )}
      </FormElement>
      <FormElement
        id="link.keywords"
        control={control}
        labelClassName="font-semibold"
        label={keywordsLabel}
      >
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            data-testid="form-element-name"
            placeholder={keywordsPlaceholder}
          />
        )}
      </FormElement>
    </form>
  );
};
