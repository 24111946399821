import { BaseModalProps } from "Hooks/useModal";
import { ConfirmationModal } from "UIKit";
import { useTranslation } from "react-i18next";

export const PublishConfirmationModal = ({
  handleAccept,
  closeModal,
}: { handleAccept: () => void } & BaseModalProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      closeModal={closeModal}
      onDecline={closeModal}
      onAccept={handleAccept}
      iconType="GreenTickIcon"
      title={t("content_item_publish_confirmation")}
      description={t("content_item_publish_irreversible_notice")}
      acceptLabel={t("content_item_publish_confirmation_yes")}
      declineLabel={t("content_item_publish_confirmation_no")}
    />
  );
};
