import { useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import useRemoteIcons from "Hooks/useRemoteIcons";
import useCreateSection from "modules/myFiles/data/useCreateSection";
import { SuccessModal } from "UIKit";
import { FileSectionDto } from "modules/myFiles/types";
import { useTranslation } from "react-i18next";
import { ConfigureSectionModal } from "UIKit/SectionManagement";
import useSections from "modules/myFiles/data/useSections";
import { observer } from "mobx-react-lite";
import { userStore } from "@eolas-medical/core";

interface AddFileSectionModalProps {
  onClose?: () => void;
}

const AddFileSectionModal = observer(
  ({
    onClose = () => {
      return;
    },
  }: AddFileSectionModalProps) => {
    const { t } = useTranslation();
    const { remoteIcons } = useRemoteIcons();
    const user = userStore.userData;
    const { createSection, creatingSection, createSectionSuccessful, createSectionError } =
      useCreateSection(user.id || "");

    const { sectionsList } = useSections(user.id || "");

    const handleCreateSection = useCallback(
      (values: FieldValues) => {
        const fileSection: FileSectionDto = { name: values.name, icon: values.icon };
        createSection(fileSection);
      },
      [createSection],
    );

    const sectionListItems = useMemo(() => {
      return sectionsList.map(({ name }) => name);
    }, [sectionsList]);

    if (createSectionSuccessful) {
      return <SuccessModal onComplete={onClose} text={t("my_files_add_section_successful")} />;
    }

    return (
      <ConfigureSectionModal
        title={t("my_files_add_section")}
        inputLabel={t("my_files_add_section_label")}
        nextLabel={t("my_files_add_section_next")}
        doneLabel={t("my_files_add_section_done")}
        cancelLabel={t("my_files_add_section_cancel")}
        iconsList={remoteIcons}
        isLoading={creatingSection}
        onCancel={onClose}
        onSubmit={handleCreateSection}
        error={createSectionError}
        validationItems={sectionListItems}
        requiredError={t("my_files_error_section_name_required")}
        minLengthError={t("my_files_error_section_name_min")}
        existsError={t("my_files_error_section_name_exists")}
      />
    );
  },
);

export default AddFileSectionModal;
