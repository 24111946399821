// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
// eslint-disable-next-line no-duplicate-imports
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type DisableMedusaMutationVariables = Types.Exact<{
  sectionId: Types.Scalars["String"];
}>;

export type DisableMedusaMutation = {
  __typename?: "Mutation";
  disableMedusa?:
    | { __typename?: "EnableMedusaOutput"; status?: Types.MedusaStatus | null | undefined }
    | null
    | undefined;
};

export const DisableMedusaDocument = gql`
  mutation DisableMedusa($sectionId: String!) {
    disableMedusa(sectionId: $sectionId) {
      status
    }
  }
`;
export type DisableMedusaMutationFn = Apollo.MutationFunction<
  DisableMedusaMutation,
  DisableMedusaMutationVariables
>;

/**
 * __useDisableMedusaMutation__
 *
 * To run a mutation, you first call `useDisableMedusaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMedusaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMedusaMutation, { data, loading, error }] = useDisableMedusaMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useDisableMedusaMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableMedusaMutation, DisableMedusaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableMedusaMutation, DisableMedusaMutationVariables>(
    DisableMedusaDocument,
    options,
  );
}
export type DisableMedusaMutationHookResult = ReturnType<typeof useDisableMedusaMutation>;
export type DisableMedusaMutationResult = Apollo.MutationResult<DisableMedusaMutation>;
export type DisableMedusaMutationOptions = Apollo.BaseMutationOptions<
  DisableMedusaMutation,
  DisableMedusaMutationVariables
>;
