import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import {
  CheckIcon,
  AttentionIcon,
  PiechartIcon,
  StarIcon,
  StarOutlineIcon,
  DeleteIcon,
} from "Assets";

import { FavouriteToggle, IconButton } from "../Button";
import { ControlsWrapper, FileTileProps, RegularFileWrapper } from "./shared";
import { DownloadFileButton } from "Components";
import { ShareFileButton } from "./shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { sectionStore } from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";

export interface NewsFeedFileTileProps extends FileTileProps {
  isCompleted?: boolean;
  shouldShowAnalytics?: boolean;
}

export const NewsFeedFileTile: React.FC<NewsFeedFileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  isCompleted,
  highlightLoading,
  shouldShowAnalytics,
  showRemoveFavouriteModal,
  onEdit = () => {
    return;
  },
  onDelete = () => {
    return;
  },
  onHighlight = () => {
    return;
  },
  onSelectFile = () => {
    return;
  },
  onShareFile,
}: NewsFeedFileTileProps) => {
  const { t } = useTranslation();
  const { createdAt } = eolasFile;
  const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(eolasFile.id);

  const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(eolasFile.mainSectionID);

  const shouldShowFavouritesToggle = isMarkFavouriteEnabled && !isAdmin && mainSectionType;

  return (
    <RegularFileWrapper
      className="h-40"
      testId={eolasFile?.name ?? ""}
      canDrag={canDrag}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 h-full justify-between">
        <span className="font-semibold text-lg line-clamp-1 leading-10">{eolasFile?.name}</span>
        <div className="flex flex-col">
          <span className="text-sm text-grey-dark line-clamp-2">{eolasFile?.info}</span>
          {createdAt ? (
            <span className="text-sm text-grey-dark">
              {format(new Date(createdAt as string), "do MMMM yyyy, HH:mm")}
            </span>
          ) : null}
        </div>
        {!isAdmin ? (
          <div className="flex items-center mt-2">
            {isCompleted ? (
              <CheckIcon
                data-testid="completed-new-item"
                className="text-green"
                height={12}
                width={12}
              />
            ) : (
              <AttentionIcon
                data-testid="incomplete-new-item"
                className="text-red"
                height={12}
                width={12}
              />
            )}
            <span className="text-sm text-grey-dark ml-2">
              {t(isCompleted ? "newsFeed_item_viewed" : "newsFeed_item_not_viewed")}
            </span>
          </div>
        ) : null}
      </div>
      <div className="w-fit self-start flex flex-col items-end justify-between self-center h-full">
        {shouldShowFavouritesToggle ? (
          <FavouriteToggle
            entityId={eolasFile.id}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: eolasFile.id,
                    favouriteId,
                    title: eolasFile.name,
                    originForAnalytics: mainSectionType,
                    entityType: FavouriteEntityType.FILE,
                  })
                : handleAddFavourite({
                    item: eolasFile,
                    entityType: FavouriteEntityType.FILE,
                  })
            }
            isLoading={!!isMarkingAsFavourite}
          />
        ) : null}
        <DownloadFileButton eolasFile={eolasFile} />
        {onShareFile && !isAdmin ? (
          <ShareFileButton onShareFile={() => onShareFile(eolasFile)} id={eolasFile.id} />
        ) : null}
      </div>
      {isAdmin ? (
        <ControlsWrapper>
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onHighlight(eolasFile);
            }}
            isLoading={highlightLoading}
            data-testid={`highlightButtonFor-${eolasFile.name}`}
            icon={isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
          />
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(eolasFile);
            }}
            icon={<DeleteIcon />}
            data-testid={`deleteButtonFor-${eolasFile.name}`}
          />
          {shouldShowAnalytics ? (
            <IconButton
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(eolasFile);
              }}
              icon={<PiechartIcon />}
              data-testid={`pieChartButton-${eolasFile.name}`}
            />
          ) : null}
        </ControlsWrapper>
      ) : null}
    </RegularFileWrapper>
  );
};
