import { SectionIcon, SectionSubSectionIcon } from "Assets";
import { CardCheck } from "UIKit";
import { ChildrenType } from "modules/contentRepository/types";
import { useTranslation } from "react-i18next";

interface SectionTypeOption {
  key: ChildrenType;
  title: string;
  icon: React.ReactNode;
}

interface SectionTypeFormProps {
  onChangeSectionType: (sectionType: ChildrenType) => void;
  selectedType: string;
  isCreatingMainSection: boolean;
}

const SectionTypeForm: React.FC<SectionTypeFormProps> = ({
  onChangeSectionType,
  selectedType,
  isCreatingMainSection,
}: SectionTypeFormProps) => {
  const { t } = useTranslation();

  const sectionTypeOptions: SectionTypeOption[] = [
    {
      key: "section",
      title: t(
        isCreatingMainSection ? "create_section_type_section" : "create_subSection_type_section",
      ),
      icon: <SectionSubSectionIcon />,
    },
    {
      key: "file",
      title: t(isCreatingMainSection ? "create_section_type_file" : "create_subSection_type_file"),
      icon: <SectionIcon />,
    },
  ];

  const handleOptionClick = (sectionType: ChildrenType) => {
    onChangeSectionType(sectionType);
  };

  return (
    <form className="flex flex-col space-y-4">
      <div className="flex justify-center space-x-4">
        {sectionTypeOptions.map((sectionType: SectionTypeOption) => {
          const isSelected = selectedType === sectionType.key;

          return (
            <CardCheck
              key={sectionType.key}
              isSelected={isSelected}
              onClick={() => handleOptionClick(sectionType.key)}
            >
              {sectionType.icon}
              <div className="text-sm font-bold md:text-base">{sectionType.title}</div>
            </CardCheck>
          );
        })}
      </div>
    </form>
  );
};

export default SectionTypeForm;
