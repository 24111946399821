import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { isUrlValid } from "Utilities";
import { WizardState } from "../../../types";
import { hasProp } from "@eolas-medical/core";

export type LinkDetailsValues = {
  name: string;
  keywords: string;
  url: string;
};

export const useSelectLink = (state: WizardState) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .required(t("validation_name_required"))
        .test("notJustWhiteSpace", t("respository_name_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
      keywords: Yup.string()
        .required(t("repository_keywords_required"))
        .test("notJustWhiteSpace", t("repository_keywords_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
      url: Yup.string()
        .required("repository_url_required")
        .test("validUrl", t("repository_invalid_url"), function (newValue) {
          const isValid = newValue ? isUrlValid(newValue) : false;
          return isValid;
        }),
    });
  }, [t]);

  let existingKeywords: string | undefined = undefined;
  let existingName: string | undefined = undefined;
  let existingUrl: string | undefined = undefined;

  if (!state.isExistingFileWithNewType) {
    if (state.dto.keywords) {
      existingKeywords = state.dto.keywords.join(", ");
    } else if (state.dto.description) {
      // Some legacy items keep keywords on description:
      existingKeywords = state.dto.description;
    }
    existingName = state.dto.name;
    existingUrl = state.dto.key;
  } else {
    if (hasProp(state.dto.newProperties, "keywords")) {
      existingKeywords = state.dto.newProperties.keywords.join(", ");
    }
    if (hasProp(state.dto.newProperties, "key")) {
      existingUrl = state.dto.newProperties.key;
    }

    existingName = state.dto.newProperties.name;
  }

  const { handleSubmit, control } = useForm<LinkDetailsValues>({
    defaultValues: {
      name: existingName,
      keywords: existingKeywords,
      url: existingUrl,
    },
    resolver: yupResolver(validationSchema),
  });

  return { handleSubmit, control };
};
