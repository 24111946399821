import { NationalFunding } from "@eolas-medical/core";
import { NationalFundingComponent } from "./NationalFundingComponents";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";

type NationalFundingProps = {
  section: NationalFunding;
} & InfoIdProps;

const PREP_CONTENT = "eolas_prep_content_national_funding";

const NationalFundingContent = ({ section, ...rest }: NationalFundingProps) => {
  return (
    <BnfCollapsible title={section.potName} {...rest}>
      {section.drugContent ? <NationalFundingComponent content={section.drugContent} /> : null}
      {section.prepContent.length
        ? section.prepContent.map((content, index) => {
            return <NationalFundingComponent content={content} key={PREP_CONTENT + index} />;
          })
        : null}
    </BnfCollapsible>
  );
};

export default NationalFundingContent;
