import { useState } from "react";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import { useSectionTypeStep } from "./SectionTypeForm/useSectionTypeStep";
import { ChildrenType } from "modules/contentRepository/types";
import useRemoteIcons from "Hooks/useRemoteIcons";
import { useTranslation } from "react-i18next";

type CreateContentRepositorySteps = "type" | "section";

interface CreateContentRepositoryWizardProps {
  onCreate: (params: {
    name: string;
    description?: string;
    icon: string;
    childrenType: ChildrenType;
  }) => void;
  isLoading: boolean;
  onClose: () => void;
  isCreatingMainSection: boolean;
}

const CreateContentRepositoryWizard = ({
  onCreate,
  isLoading,
  onClose,
  isCreatingMainSection,
}: CreateContentRepositoryWizardProps) => {
  const { wizardControl, submitWizard } = useWizard();

  const { t } = useTranslation();

  const { remoteIcons } = useRemoteIcons();

  const [childrenType, setChildrenType] = useState<ChildrenType>("section");

  const handleSubmitSectionType = (childrenType: ChildrenType) => {
    setChildrenType(childrenType);
  };

  const handleSubmitCreateSection = ({
    name,
    description,
    iconName,
  }: {
    name: string;
    description?: string;
    iconName: string;
  }) => {
    onCreate({
      name,
      description,
      icon: iconName,
      childrenType,
    });
  };

  const sectionTypeStep = useSectionTypeStep({
    onSubmit: handleSubmitSectionType,
    onPrev: onClose,
    isCreatingMainSection,
  });

  const createSectionStep = useConfigureSectionStep<"section">({
    title: t("addSection_modal_title"),
    stepName: "section",
    iconsList: remoteIcons,
    onSubmit: handleSubmitCreateSection,
    onEnter: submitWizard,
    isLoading,
    showDescription: isCreatingMainSection,
    cancelLabel: t("general_back"),
  });

  const steps: Step<CreateContentRepositorySteps>[] = [sectionTypeStep, createSectionStep];

  return <Wizard wizardControl={wizardControl} steps={steps} showSteps={true} />;
};

export default CreateContentRepositoryWizard;
