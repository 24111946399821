import { makeApolloClient } from "API/functions/makeApolloClient";

import envConfig from "env-config";
import { ListAppUsersByAppDocument } from "./departments.graphql";
import { SpaceUser } from "./types";

const apiUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/proxy/graphql`;

export const departmentClient = makeApolloClient(apiUrl);

// @TODO: replace this with a REST endpoint

export const getDepartmentUsers = async ({ departmentId }: { departmentId: string }) => {
  let nextToken: string | null = null;
  let users: SpaceUser[] = [];
  let data: { listAppUsersByApp: { items: SpaceUser[]; nextToken: string | null } };
  do {
    ({ data } = await departmentClient.query({
      query: ListAppUsersByAppDocument,
      variables: { appID: departmentId, nextToken },
    }));
    users = [...users, ...data.listAppUsersByApp.items];
    nextToken = data.listAppUsersByApp.nextToken;
  } while (nextToken);

  return users;
};
