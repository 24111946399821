import { DrugCContent, MonitoringRequirements } from "@eolas-medical/core";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";

type MonitoringRequirementsProps = {
  section: MonitoringRequirements;
} & InfoIdProps;

const MonitoringRequirementsContent = ({ section, ...rest }: MonitoringRequirementsProps) => {
  return (
    <BnfCollapsible title={section.potName} {...rest}>
      {section.drugClassContent.map((element, index) => {
        return <MonitoringContent key={"drugClassContent" + index} element={element} />;
      })}
      {section.drugContent ? <MonitoringContent element={section.drugContent} /> : null}
    </BnfCollapsible>
  );
};

export default MonitoringRequirementsContent;

const MonitoringContent = ({ element }: { element: DrugCContent }) => {
  return (
    <>
      {element.monitoringOfPatientParameters ? (
        <BnfContentElement htmlContent={element.monitoringOfPatientParameters} />
      ) : null}
      {element.patientMonitoringProgrammes ? (
        <BnfContentElement htmlContent={element.patientMonitoringProgrammes} />
      ) : null}
      {element.therapeuticDrugMonitoring ? (
        <BnfContentElement htmlContent={element.therapeuticDrugMonitoring} />
      ) : null}
    </>
  );
};
