import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

interface ModalBodyProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  className = "",
}: ModalBodyProps) => {
  return (
    <div
      className={tw(`
        flex flex-col
        p-6 sm:p-8 lg:p-10 xl:p-12
        ${className}
      `)}
    >
      {children}
    </div>
  );
};
