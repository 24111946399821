import React, { useState } from "react";
import { Text } from "UIKit";
import { CaretDownIcon } from "Assets";
import { twMerge } from "tailwind-merge";
import { CollapsibleVariant } from "./types";

export interface CollapsibleProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  arrowPosition?: "left" | "right";
  variant?: CollapsibleVariant;
  shouldMountExpanded?: boolean;
  parentState?: {
    isCollapsed: boolean;
    setIsCollapsed: (newState: boolean) => void;
  };
}

export const Collapsible = ({
  children,
  title = "More",
  className = "",
  arrowPosition = "left",
  variant = "white",
  shouldMountExpanded,
  parentState,
}: CollapsibleProps) => {
  let isCollapsed: boolean;
  let setIsCollapsed: (newState: boolean) => void;
  [isCollapsed, setIsCollapsed] = useState(!shouldMountExpanded);

  if (parentState) {
    ({ isCollapsed, setIsCollapsed } = parentState);
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const containerClassVariant =
    variant === "white" ? "bg-white border border-t-1 border-b-1 border-grey-300" : "bg-blue-500 ";

  const contentClassVariant =
    variant === "white" ? "bg-white border border-grey-300" : "border border-grey-300";

  const titleClassVariant = variant === "white" ? "text-black" : "text-white";

  const caretIcon = (
    <CaretDownIcon
      className={twMerge(
        "w-4 h-4 text-white transition-all",
        ` ${!isCollapsed ? "transform rotate-180" : ""}`,
        titleClassVariant,
      )}
    />
  );

  return (
    <div>
      <div
        className={twMerge(
          "flex h-10 items-center rounded-t-md p-2 cursor-pointer space-x-1",
          containerClassVariant,
          isCollapsed ? "rounded-b-md border-b-1" : "border-b-0",
          className,
        )}
        onClick={toggleCollapse}
      >
        {arrowPosition === "left" && caretIcon}
        <Text level={1} className={`${titleClassVariant} overflow-ellipsis line-clamp-1`}>
          {title}
        </Text>
        {arrowPosition === "right" && caretIcon}
      </div>
      {!isCollapsed && (
        <div className={twMerge("rounded-b-md  px-4 py-2", contentClassVariant)}>{children}</div>
      )}
    </div>
  );
};
