import { useTranslation } from "react-i18next";
import { Variants, motion } from "framer-motion";
import { MembersIcon, PrivacyIcon, circularLoading, defaultSpace } from "Assets";
import { Text, IconText, LottieWithHeader } from "UIKit";
import { getImageUrl } from "Utilities";
import SpecialtiesList from "../SpecialtiesList/SpecialtiesList";
import { useEffect, useRef, useState } from "react";

interface SpaceTileProps {
  name: string;
  membersCount: number;
  isPrivate?: boolean;
  description?: string;
  imageUrl?: string;
  "data-testid"?: string;
  onClick(): void;
  specialties?: string[];
  isLoading?: boolean;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const SpaceTile = ({
  name,
  description,
  membersCount,
  isPrivate = false,
  imageUrl,
  specialties,
  onClick,
  "data-testid": dataTestId,
  isLoading,
}: SpaceTileProps) => {
  const { t } = useTranslation();
  const [calculatedHeight, setCalculatedHeight] = useState("13rem");

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tileHeight = ref.current?.getBoundingClientRect().height;
    setCalculatedHeight(`${tileHeight}px`);
  }, []);

  const imgUrl = imageUrl ? getImageUrl(imageUrl) : defaultSpace;

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={`grid grid-cols-12 transition-all bg-white rounded-lg shadow-sm cursor-pointer ${
        isLoading ? "pointer-events-none" : ""
      }`}
      style={{ minHeight: "13rem" }}
      onClick={!isLoading ? onClick : undefined}
      data-testid={dataTestId}
    >
      <div
        className="row-start-1 col-start-1 col-end-5 md:col-end-4 flex bg-blue-50 rounded-l-lg relative"
        style={{ height: calculatedHeight }}
      >
        <img
          srcSet={imgUrl}
          src={imgUrl}
          alt=""
          className="w-full h-full object-cover rounded-l-lg"
        />
      </div>

      <div
        ref={ref}
        className="row-start-1 col-start-5 md:col-start-4 col-end-13 flex flex-col justify-between p-5 space-y-4"
      >
        <div className="flex flex-col space-y-3">
          <div>
            <div className="text-xl md:text-2xl font-bold line-clamp-1">{name}</div>

            <div className="flex flex-col gap-y-1 md:flex-row md:space-x-2 md:gap-y-0">
              <IconText level={1} icon={<MembersIcon />}>
                {membersCount}
                {membersCount > 1 ? t("space_details_members") : t("space_details_member")}{" "}
              </IconText>
              {isPrivate && (
                <IconText level={2} icon={<PrivacyIcon />}>
                  {t("selectSpace_is_private")}
                </IconText>
              )}
            </div>
          </div>

          {description && (
            <Text level={1} className="text-grey-700 line-clamp-2">
              {description}
            </Text>
          )}
        </div>

        {specialties && <SpecialtiesList specialties={specialties} />}
      </div>

      {isLoading && (
        <div className="absolute bg-white w-full h-full rounded-lg opacity-50">
          <LottieWithHeader
            text=""
            animation={circularLoading}
            lottieOptions={{ loop: true }}
            animationSize="10rem"
          />
        </div>
      )}
    </motion.div>
  );
};

export default SpaceTile;
