import { useTranslation } from "react-i18next";
import envConfig from "env-config";
import AccessQRCodeModal from "UIKit/Modal/AccessQRCodeModal/AccessQRCodeModal";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { AccessLinkToken } from "Pages/Authentication/pages/Invitations/pages/AccessLink/AccessLink";
import { AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";

type AccessQRCodeProps = {
  onClose: () => void;
};

const AccessQRCode = ({ onClose }: AccessQRCodeProps) => {
  const { t } = useTranslation();
  const { selectedSpace } = useSpacesContext();
  const accessTokenList = selectedSpace?.accessTokens || [];
  const accessToken = accessTokenList[0];

  const shareSpaceRoute = `${envConfig.REACT_APP_PROTOCOL}://${envConfig.REACT_APP_WEB_DOMAIN}/access-link/${accessToken}`;

  const handleCopyLink = () => {
    trackEvent(AnalyticsEvents.SPACE_ACCESS_LINK_COPIED, { spaceId: selectedSpace?.id });
  };

  const handleExportToImage = () => {
    trackEvent(AnalyticsEvents.SPACE_ACCESS_QR_EXPORTED, { spaceId: selectedSpace?.id });
  };

  return (
    <AccessQRCodeModal
      titleText={t("space_details_share_space_title")}
      descriptionText={t("space_details_share_space_subtitle")}
      onDone={onClose}
      accessToken={accessToken as AccessLinkToken}
      onGenerateAccessToken={() => {
        return;
      }}
      onCopyLink={handleCopyLink}
      onExportToImage={handleExportToImage}
      accessRoute={shareSpaceRoute}
      exportedQRCodeName="share-space"
    />
  );
};

export default AccessQRCode;
