/* eslint-disable @typescript-eslint/no-explicit-any */
import { addMonths } from "date-fns";
import {
  sectionStore,
  AppLevelSection,
  MajorIncidentType,
  MajorIncidentSubSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

import { convertToLink, createSearchField } from "Utilities/general";
import { PublishFileDTO, IncidentReportingResolveStatus, NO_EXPIRY_DATE } from "Utilities/types";

import { InputMapperCreator } from "./types";

export const createItemWithFileMapper: InputMapperCreator =
  ({ user, sectionID, mainSectionID }) =>
  ({ name, description, type, key, metadata }) => {
    const { givenName = "", familyName = "" } = user;
    const info = `${givenName?.toLowerCase()} ${familyName?.toLowerCase()}`;
    const parentID =
      sectionID ?? sectionStore.getChildReferenceByMainSectionType(mainSectionID)!.id;

    const searchField = createSearchField({
      name,
      type,
      description,
      createdBy: info,
      date: new Date().toISOString(),
    });

    const input = {
      name,
      info,
      type,
      description,
      searchField,
      key: type === "link" ? convertToLink(key) : key,
      metadata,
    };

    return {
      input,
      parentID,
    };
  };

export const createItemWithAttachmentMapper: InputMapperCreator =
  ({ user, sectionID, mainSectionID }) =>
  ({ name, description, type, key }) => {
    const { givenName = "", familyName = "" } = user;
    const searchField = createSearchField({ name, description });

    const parentID =
      sectionID ?? sectionStore.getChildReferenceByMainSectionType(mainSectionID)!.id;

    const input = {
      type,
      name,
      description,
      searchField,
      info: `${givenName} ${familyName}`,
      key: type === "link" ? convertToLink(key) : key,
    };

    return {
      input,
      parentID,
    };
  };

export const createItemWithOrganisationMapper: InputMapperCreator =
  ({ sectionID }) =>
  ({ key, name, info, type, moreInfo, expiryDate, description, hasExpiryDate }) => {
    const searchField = createSearchField({
      name,
      type,
      description,
      moreInfo: `${key} ${info}`,
    });

    const expiresIn =
      hasExpiryDate && expiryDate instanceof Date ? expiryDate.toISOString() : NO_EXPIRY_DATE;

    const input = {
      name,
      type,
      info,
      moreInfo,
      description,
      searchField,
      expiryDate: expiresIn,
      key: type === "link" ? convertToLink(key) : key,
    };
    return {
      input,
      parentID: sectionID!,
    };
  };

export const createItemWithExpirationInMonthsMapper: InputMapperCreator =
  ({ user, sectionID }) =>
  ({ name, key, type, moreInfo, description, hasExpiryDate }) => {
    const parentID = sectionStore.getSection(sectionID!).id;

    const { givenName, familyName } = user;

    const info = `${givenName} ${familyName}`;

    const searchField = createSearchField({ name, description });

    const expiryDate = hasExpiryDate
      ? addMonths(new Date(), parseInt(moreInfo, 10)).toISOString()
      : undefined;

    const input = {
      name,
      info,
      type,
      expiryDate,
      description,
      searchField,
      moreInfo: hasExpiryDate ? moreInfo : "",
      key: type === "link" ? convertToLink(key) : key,
    };

    return {
      parentID,
      input,
    };
  };

export const createHospitalInputMapper: InputMapperCreator =
  () =>
  ({ info, name, type, key = "", moreInfo, description, moreInfoTwo }) => {
    const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(
      AppLevelSection.hospitalList,
    )!;

    const searchField = createSearchField({
      name,
      description,
      moreInfo: Array.isArray(moreInfoTwo) ? moreInfoTwo.join(" ") : "",
    });

    const input = {
      key,
      name,
      type,
      description,
      searchField,
      info: JSON.stringify(info),
      moreInfo: JSON.stringify(moreInfo),
      moreInfoTwo: JSON.stringify(moreInfoTwo),
    };

    return {
      input,
      parentID,
    };
  };

export const createContactInputMapper: InputMapperCreator =
  ({ mainSectionID, sectionID }) =>
  ({ type, name, info, moreInfo, description }) => {
    const parentID =
      sectionID ?? sectionStore.getChildReferenceByMainSectionType(mainSectionID)!.id;

    const searchField = createSearchField({
      name,
      description,
      moreInfo: moreInfo.toLowerCase(),
    });

    const input = {
      key: "",
      name,
      type,
      moreInfo,
      description,
      searchField,
      info: type === "extension" ? info : "N/A",
    };

    return {
      input,
      parentID,
    };
  };

export const createMajorIncidentDocumentInputMapper: InputMapperCreator =
  ({ user, sectionID, mainSectionID }) =>
  ({ name, type, key, info }) => {
    const { id } = sectionStore.getChildReferenceByMainSectionType(mainSectionID)!;

    const parentSection = sectionStore.getChildrenOrder(id);
    const { id: parentID } = parentSection.find((child) => child!.icon === sectionID)!;

    if (sectionID === MajorIncidentSubSection.incidents) {
      const { givenName = "", familyName = "" } = user;

      const searchField = createSearchField({
        name,
        date: new Date().toISOString(),
        createdBy: `${givenName} ${familyName}`,
      });

      const input: PublishFileDTO = {
        key,
        name,
        type,
        searchField,
        info: JSON.stringify(info),
        moreInfoTwo: MajorIncidentType.active,
        moreInfo: `${givenName} ${familyName}`,
      };

      return {
        input,
        parentID,
      };
    }

    const searchField = createSearchField({
      name,
    });

    const input = {
      name,
      type,
      searchField,
      key: type === "link" ? convertToLink(key) : key,
    };

    return {
      input,
      parentID,
    };
  };

export const createIncidentReportingInputMapper: InputMapperCreator =
  ({ user, mainSectionID }) =>
  ({ key, name, info, type }) => {
    const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(mainSectionID)!;

    const { email, givenName = "", familyName = "" } = user;

    const moreInfo = JSON.stringify({
      email,
      name: `${givenName} ${familyName}`,
    });

    const input = {
      key,
      type,
      name,
      moreInfo,
      info: JSON.stringify({ ...(info as any), title: name }),
      moreInfoTwo: IncidentReportingResolveStatus.notResolved,
    };

    return {
      input,
      parentID,
    };
  };

export const createDepartmentAreaInputMapper: InputMapperCreator =
  () =>
  ({ name, description }) => {
    const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(
      AppLevelSection.theDepartment,
    )!;

    const input = {
      key: "",
      type: "",
      name,
      description,
    };

    return {
      input,
      parentID,
    };
  };

export const createNewsItemMapper: InputMapperCreator =
  ({ user, sectionID, mainSectionID }) =>
  ({ key, name, type, description }) => {
    const parentID =
      sectionID ?? sectionStore.getChildReferenceByMainSectionType(mainSectionID)!.id;

    const { givenName = "", familyName = "" } = user;

    const searchField = createSearchField({
      name,
      description,
      date: new Date().toISOString(),
      moreInfo: `${givenName} ${familyName} ${key}`,
    });

    const input = {
      name,
      type,
      description,
      searchField,
      info: `${givenName} ${familyName}`,
      key: type === "link" ? convertToLink(key) : key,
    };

    return {
      input,
      parentID,
    };
  };

export const createDrugMonitoringInputMapper: InputMapperCreator =
  ({ user }) =>
  ({ key, name, type, description }) => {
    const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(
      OrganisationLevelSection.drugMonitoring,
    )!;

    const { givenName = "", familyName = "" } = user;

    const searchField = createSearchField({
      name,
      description,
      date: new Date().toISOString(),
      moreInfo: `${givenName} ${familyName} ${key}`,
    });

    const input = {
      name,
      type,
      searchField,
      description,
      key: type === "link" ? convertToLink(key) : key,
    };

    return {
      input,
      parentID,
    };
  };
