import { useMemo } from "react";
import { ContentType, useGetAllContentRepository } from "../useGetAllContentRepository";

export const useContentForSearch = (contentRepositoryId: string) => {
  const { finalItemList } =
    useGetAllContentRepository({
      contentRepositoryId,
      contentType: ContentType.Item,
    }) ?? [];

  return useMemo(() => {
    return finalItemList.reduce<Record<string, string>>(
      (acc, current) => ({
        ...acc,
        [current.id]: current.id,
      }),
      {},
    );
  }, [finalItemList]);
};
