import { Button, ButtonProps } from "UIKit/Button";
import { ListMenuAction, ListMenuActionEvent } from "../EolasVersatileList.types";
import { useTranslation } from "react-i18next";
import { defaultKeyExtractor } from "../functions/helpers";

const MENU_ACTION_ORDER: ListMenuAction[] = ["selectAll", "copyTo", "moveTo", "delete"];

type ListActionMenuComponentProps<T> = {
  selectedItems: Record<string, T>;
  allItems: T[];
  menuActions: ListMenuAction[];
  onMenuAction: (event: ListMenuActionEvent<T>) => void;
  keyExtractor?: (item: T) => string;
};

export const ListActionMenuComponent = <T,>({
  menuActions,
  onMenuAction,
  selectedItems,
  allItems,
  keyExtractor,
}: ListActionMenuComponentProps<T>) => {
  const isAllSelected = Object.keys(selectedItems).length === allItems.length;
  const hasSelectedItems = Object.keys(selectedItems).length > 0;
  const sortedMenuActions = menuActions.sort(
    (a, b) => MENU_ACTION_ORDER.indexOf(a) - MENU_ACTION_ORDER.indexOf(b),
  );
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center gap-2">
      {sortedMenuActions.map((action) => {
        const { translationString, icon, isDisabled } = makeMenuActionProps(
          action,
          isAllSelected,
          hasSelectedItems,
        );
        return (
          <Button
            key={action}
            color="white"
            size="sm"
            disabled={isDisabled}
            onClick={() =>
              onMenuAction(
                makeMenuActionEvent(action, isAllSelected, selectedItems, allItems, keyExtractor),
              )
            }
            className="flex items-center justify-center p-2 cursor-pointer"
            iconLeft={icon}
          >
            {t(translationString)}
          </Button>
        );
      })}
    </div>
  );
};

const makeMenuActionEvent = <T,>(
  action: ListMenuAction,
  isDeselectAll: boolean,
  selectedItems: Record<string, T>,
  allItems: T[],
  keyExtractor?: (item: T) => string,
): ListMenuActionEvent<T> => {
  if (action === "selectAll") {
    if (isDeselectAll) {
      return {
        selectedItems: {},
        type: "deselectAll",
      };
    }

    const allItemsMap = allItems.reduce(
      (acc, item, index) => {
        const id = keyExtractor ? keyExtractor(item) : defaultKeyExtractor(item, index);
        acc[id] = item;
        return acc;
      },
      {} as Record<string, T>,
    );

    return {
      selectedItems: allItemsMap,
      type: "selectAll",
    };
  }

  switch (action) {
    case "moveTo":
    case "copyTo":
    case "delete":
      return { type: action, selectedItems };
  }
};

const makeMenuActionProps = (
  action: ListMenuAction,
  isDeselectAll: boolean,
  hasSelectedItems: boolean,
): { translationString: string; icon?: ButtonProps["iconLeft"]; isDisabled?: boolean } => {
  switch (action) {
    case "selectAll":
      if (isDeselectAll) {
        return {
          translationString: "component_eolasVersatileList_deselectAll",
        };
      }
      return {
        translationString: "component_eolasVersatileList_selectAll",
        icon: "TickIcon",
      };
    case "moveTo":
      return {
        translationString: "component_eolasVersatileList_move_to",
        icon: "FolderIcon",
        isDisabled: !hasSelectedItems,
      };
    case "copyTo":
      return {
        translationString: "component_eolasVersatileList_copy_to",
        icon: "CopyIcon",
        isDisabled: !hasSelectedItems,
      };
    case "delete":
      return {
        translationString: "component_eolasVersatileList_delete",
        icon: "DeleteIcon",
        isDisabled: !hasSelectedItems,
      };
  }
};
