import { GeneralCategory, NiceSummaryItem } from "@eolas-medical/core";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useNiceGuidelinesList } from "../hooks/useNiceGuidelinesList";
import { LocalItemsList } from "shared/components/Lists";
import { useCallback } from "react";
import { NiceGuidelineTile } from "../components/NiceGuidelineTile";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { trackNiceSearch } from "modules/niceGuidelines/helpers";
import { isNiceSummaryItem } from "modules/niceGuidelines/typeguards";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

export const NiceGuidelinesList = () => {
  const { itemsToDisplay, type, handleClickTile, title, isLoading } = useNiceGuidelinesList();
  const history = useHistory();
  const { t } = useTranslation();
  const { handleOnShareFile, shareModal, isShareFileEnabled, isShareModalOpen, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    removeFavouriteModal,
    closeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);
  const renderItem = useCallback(
    (item: GeneralCategory | NiceSummaryItem) => {
      const canShare = isShareFileEnabled && isNiceSummaryItem(item);
      return (
        <NiceGuidelineTile
          item={item}
          onClick={() => handleClickTile(item)}
          onShare={canShare ? () => handleOnShareFile(item) : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [handleClickTile, isShareFileEnabled, handleOnShareFile, showRemoveFavouriteModal],
  );
  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle title={title ?? t("community_niceGuidelines_title")} />
        <NavButton onClick={() => history.goBack()} />
        {type === "categories" ? (
          <LocalItemsList<GeneralCategory>
            items={itemsToDisplay}
            isLoading={isLoading}
            keysToSearch={["title"]}
            renderItem={renderItem}
            onSearchAnalytics={trackNiceSearch}
            searchType="click"
          />
        ) : (
          <LocalItemsList<NiceSummaryItem>
            items={itemsToDisplay}
            isLoading={isLoading}
            keysToSearch={["title", "keywords"]}
            renderItem={renderItem}
            onSearchAnalytics={trackNiceSearch}
            searchType="click"
          />
        )}
      </InnerPageWrapper>
    </>
  );
};
