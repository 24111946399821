import {
  PortfolioNativeComponent,
  PortfolioComplexComponent,
  FormElement,
} from "modules/portfolio/types";
import * as Yup from "yup";

interface ValidationObject {
  [key: string]: Yup.AnySchema;
}

export const makeValidationSchema = ({ formElements }: { formElements: FormElement[] }) => {
  const validationSchema: ValidationObject = {};
  formElements.forEach(({ componentConfig, fieldName, required }) => {
    const { type } = componentConfig;
    switch (type) {
      case PortfolioNativeComponent.DATE_PICKER:
        validationSchema[fieldName] = validationObjectMakers.date({ required });
        break;
      case PortfolioComplexComponent.ATTACH_BUTTON:
        validationSchema[fieldName] = validationObjectMakers.array({ required });
        break;
      case PortfolioNativeComponent.TEXT_INPUT:
      case PortfolioNativeComponent.BEST_ANSWER_SELECTOR:
      case PortfolioNativeComponent.RADIO_BUTTON:
      case PortfolioNativeComponent.BINARY_SELECTOR:
        validationSchema[fieldName] = validationObjectMakers.string({ required });
        break;
      case PortfolioNativeComponent.NUMBER_FEEDBACK:
        validationSchema[fieldName] = validationObjectMakers.number({ required });
        break;
      default:
        // console.error(`Component Type "${type}" not recognised`);
        validationSchema[fieldName] = validationObjectMakers.string({ required });
    }
  });
  return Yup.object().shape(validationSchema);
};

enum FormInputType {
  number = "number",
  string = "string",
  date = "date",
  array = "array",
}

type ValidationObjectMaker = (params: {
  required?: boolean;
  min?: number;
  max?: number;
}) => Yup.AnySchema;

interface ValidationObjectMakers {
  [FormInputType.number]: ValidationObjectMaker;
  [FormInputType.string]: ValidationObjectMaker;
  [FormInputType.date]: ValidationObjectMaker;
  [FormInputType.array]: ValidationObjectMaker;
}

export const validationObjectMakers: ValidationObjectMakers = {
  [FormInputType.number]: ({ required = true, min, max }) => {
    const baseValidator = Yup.number().nullable();
    switch (true) {
      case required && !min && !max:
        return baseValidator.required(validationMessageMakers.required());
      case required && Boolean(min && max):
        return baseValidator
          .required(validationMessageMakers.required())
          .min(min!, validationMessageMakers.numberTooLow(min))
          .max(max!, validationMessageMakers.numberTooHigh(max));
      case !required && Boolean(min && max):
        return baseValidator
          .min(min!, validationMessageMakers.numberTooLow(min))
          .max(max!, validationMessageMakers.numberTooHigh(max));
      case !required && !min && !max:
        return baseValidator;
      default:
        console.error("Incorrect params given to validation object maker");
        return baseValidator;
    }
  },
  [FormInputType.string]: ({ required = true }) => {
    const baseValidator = Yup.string();
    if (required) {
      return baseValidator.required(validationMessageMakers.required());
    }
    return baseValidator;
  },
  [FormInputType.date]: ({ required = true }) => {
    const baseValidator = Yup.date().max(new Date(), validationMessageMakers.futureDate());

    if (required) {
      return baseValidator.required(validationMessageMakers.required());
    }
    return baseValidator;
  },
  [FormInputType.array]: ({ required = true }) => {
    const baseValidator = Yup.array();
    if (required) {
      return baseValidator
        .required(validationMessageMakers.required())
        .min(1, validationMessageMakers.required());
    }
    return baseValidator;
  },
};

export const validationMessageMakers = {
  number: () => "Please enter a number",
  required: () => "This field is mandatory",
  numberTooLow: (min = 1) => `Number must be above ${min}`,
  numberTooHigh: (max = 15) => `Number must be below ${max}`,
  futureDate: () => "You can't select a date in the future",
};
