import { ContentItem, CopyItemFailure } from "@eolas-medical/core";
import { InformationIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { Button, FileTypeItemIcon, InnerModalWrapper, Text, Title } from "UIKit";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { mapContentItemToSupportedFileType } from "shared/pages/ContentRepository/ContentItems/functions/helpers";

type Props = {
  failedItems: CopyItemFailure[];
  originalItems: ContentItem[];
  retryFailedItems: (items: ContentItem[]) => void;
  isRetrying: boolean;
  didRetryFail: boolean;
};

export const CopyItemsPartialSuccess = ({
  originalItems,
  failedItems,
  isRetrying,
  didRetryFail,
  retryFailedItems,
}: Props) => {
  const { t } = useTranslation();
  const items = useMemo(
    () =>
      originalItems.filter((item) => failedItems.some((failedItem) => failedItem.id === item.id)),
    [originalItems, failedItems],
  );
  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-60vh">
        <div>
          <InformationIcon className="h-16 w-16" />
        </div>
        <Title level={8} className="mt-4">
          {t("copy_files_modal_partial_success_error")}
        </Title>
        <div className="flex flex-col h-full w-full">
          <div className="flex h-2/3">
            <EolasVersatileList
              className="w-full"
              items={items}
              renderItem={FailedItem}
              isInModal
              outerContainerClassName="bg-grey-300 rounded-2xl py-2 mt-2"
              innerContainerClassName="px-4 first:pt-2 last:pb-2 h-full w-full"
            />
          </div>
          {didRetryFail ? <Text level={1}>{t("copy_files_modal_retry_failed")}</Text> : null}
          <div className="flex flex-row items-center justify-center gap-4">
            <Button
              size="lg"
              color="blue"
              variant="outline"
              isLoading={isRetrying}
              disabled={isRetrying || didRetryFail}
              onClick={() => retryFailedItems(items)}
              className="w-1/4 self-center sm:w-full mt-4 sm:mt-8"
            >
              {t("copy_files_modal_retry_failed_items")}
            </Button>
            <Button
              size="lg"
              color="blue"
              variant="solid"
              onClick={() => modalStore.closeModal()}
              className="w-1/4 self-center sm:w-full mt-4 sm:mt-8"
            >
              {t("copy_files_modal_continue_without_failed")}
            </Button>
          </div>
        </div>
      </div>
    </InnerModalWrapper>
  );
};

const FailedItem = ({ item }: EolasVersatileListRenderItemProps<ContentItem>) => {
  return (
    <div className="pb-2">
      <EolasTile
        variant="rectangular"
        icon={
          <FileTypeItemIcon
            type={mapContentItemToSupportedFileType(item)}
            className="w-full h-full p-4 items-center justify-center bg-grey-300 rounded-md"
          />
        }
        primaryText={item.name}
      />
    </div>
  );
};
