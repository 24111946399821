import { useTranslation } from "react-i18next";

import { SpinningDotsIcon } from "Assets";
import { Footer, PageTitle } from "UIKit";
import { appConfig, repoConfig } from "AppTypeConfig";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { useRunOnMountUnmount } from "Hooks";
import { useAutoLogin } from "./useAutologin";

const { commitId, version } = repoConfig;

export const AutoLogin = () => {
  const { t } = useTranslation();

  const { token } = useParamsDecoded<{ token: string }>();

  const { autoLogin, isError, isLoading } = useAutoLogin();

  useRunOnMountUnmount({ onMount: () => autoLogin(token) });

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="flex flex-col flex-1 items-center justify-start pt-8 md:pt-0 md:justify-center">
          <PageTitle className="mb-8" subTitle={t("pageSubTitle")} title={appConfig.appName} />
          {isError || !isLoading ? t("auto_login_error") : null}
          {isLoading ? (
            <>
              {t("auto_login_loading")}
              <div className="mt-10">
                <SpinningDotsIcon className="animate-spin" width={60} height={60} fill="#3375ed" />
              </div>
            </>
          ) : null}
        </div>
        <div className="ml-5 mb-5">
          <Footer commitId={commitId} version={version} />
        </div>
      </div>
    </>
  );
};
