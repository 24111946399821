import { Collapsible, CollapsibleProps } from "UIKit/Collapsible/Collapsible";
import { bnfStore } from "../../stores/bnf.store";
import { observer } from "mobx-react-lite";

export type InfoIdProps = { itemId: string; sectionId: string };

type BnfCollapsibleProps = Exclude<CollapsibleProps, "parentState"> & InfoIdProps;

export const BnfCollapsible = observer(
  ({ itemId, sectionId, shouldMountExpanded, ...rest }: BnfCollapsibleProps) => {
    const storedIsCollapsed = bnfStore.isSectionCollapsedRecord[itemId]?.[sectionId];

    const setIsCollapsed = (newState: boolean) => {
      bnfStore.updateIsSectionCollapsedRecord({
        newIsCollapsed: newState,
        itemId,
        sectionId,
      });
    };

    const isCollapsed =
      typeof storedIsCollapsed === "boolean" ? storedIsCollapsed : !shouldMountExpanded;

    return <Collapsible parentState={{ isCollapsed, setIsCollapsed }} {...rest} />;
  },
);
