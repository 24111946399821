import { FC, useMemo } from "react";

import { UrineIcon } from "Assets";
import { CreatinineClearanceReturnValues } from "@eolas-medical/core";

import { ResultBox } from "./ResultBox";

interface CreatinineClearanceResultsProps {
  results: CreatinineClearanceReturnValues;
}

export const CreatinineClearanceResults: FC<CreatinineClearanceResultsProps> = ({
  results,
}: CreatinineClearanceResultsProps) => {
  const {
    actualWeightCrCl,
    idealWeightCrCl,
    adjustedWeightCrCl,
    idealBodyWeight,
    adjustedBodyWeight,
    error,
  } = results;

  const showActualWeightOnly = useMemo(
    () => idealWeightCrCl === null && adjustedWeightCrCl == null,
    [idealWeightCrCl, adjustedWeightCrCl],
  );

  const details = useMemo(() => {
    return makeDetails(actualWeightCrCl, showActualWeightOnly);
  }, [actualWeightCrCl, showActualWeightOnly]);

  return (
    <>
      <ResultBox
        error={error}
        details={details}
        detailsTitle="Details"
        title="Based on actual weight:"
        text={`${actualWeightCrCl} mL/min`}
        icon={<UrineIcon className="w-6 h-6 mr-4" />}
      />
      {!showActualWeightOnly && (
        <>
          <ResultBox
            detailsTitle="Details"
            title="Based on ideal weight:"
            text={`${idealWeightCrCl} mL/min`}
            details={makeDetails(idealBodyWeight, false)}
          />
          <ResultBox
            detailsTitle="Details"
            title="Based on adjusted weight:"
            text={`${adjustedWeightCrCl} mL/min`}
            details={makeDetails(adjustedBodyWeight, false)}
          />
        </>
      )}
    </>
  );
};

const makeDetails = (weight: number | null | string, showActualWeightOnly: boolean) => {
  if (showActualWeightOnly) {
    return [
      `Weight used: ${weight + "kg"}\nCalculated using Cockcroft-Gault formula.`,
      "As actual body weight is less than ideal body weight, only actual body weight should be used to calculate creatinine clearance.",
    ];
  }
  return [`Weight used: ${weight + "kg"}\nCalculated using Cockcroft-Gault formula.`];
};
