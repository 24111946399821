import React, { useCallback, useState } from "react";
import {
  InnerModalWrapper,
  AddButton,
  ModalHeader,
  Title,
  SearchBox,
  Button,
  FormError,
  Text,
  ModalBody,
  Loader,
} from "UIKit";

interface BaseFile {
  id: string;
  name: string;
}

export interface AttachableFiles<F extends BaseFile> {
  label?: string;
  fileList: F[];
}

interface AttachFileModalProps<F extends BaseFile> {
  attachableFiles: AttachableFiles<F>[];
  renderFile: (file: F, isSelected: boolean) => React.ReactNode;
  modalTitle?: string;
  modalSubtitle?: string;
  searchPlaceholder?: string;
  addNewFileButtonText?: string;
  attachFileButtonText?: string;
  error?: string;
  filesLoading?: boolean;
  onAttachFile?: (file: F) => void;
  onAddNewFile?: () => void;
  onSearchInputChange?: (inputText: string) => void;
}

export const AttachFileModal = <F extends BaseFile>({
  attachableFiles,
  renderFile,
  modalTitle = "My Files",
  modalSubtitle = "Select and attach a file or",
  searchPlaceholder = "Search...",
  addNewFileButtonText = "Add New File",
  attachFileButtonText = "Attach File",
  error,
  filesLoading,
  onAttachFile = () => {
    return;
  },
  onAddNewFile = () => {
    return;
  },
  onSearchInputChange = () => {
    return;
  },
}: AttachFileModalProps<F>) => {
  const [selectedFile, setSelectedFile] = useState<F>();

  const handleAddNewFile = useCallback(() => {
    onAddNewFile();
  }, [onAddNewFile]);

  const handleAttachFile = useCallback(() => {
    if (selectedFile) {
      onAttachFile(selectedFile);
    }
  }, [onAttachFile, selectedFile]);

  const renderFileList = () => {
    if (filesLoading) {
      return (
        <div className="m-auto">
          <Loader />
        </div>
      );
    }

    return (
      <>
        {attachableFiles.map(({ label, fileList }) => {
          return (
            <div key={label} className="mt-2 space-y-1">
              <Text level={2}>{label}</Text>
              {fileList.map((f) => (
                <div
                  key={f.id}
                  data-testid={`attach-file-modal-file-${f.name}`}
                  onClick={() => setSelectedFile(f)}
                >
                  {renderFile(f, f.id === selectedFile?.id)}
                </div>
              ))}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <InnerModalWrapper data-testid="attach-file-modal">
      <ModalHeader>
        <Title level={5}>{modalTitle}</Title>
        <Text level={5} className="text-grey-600 mt-4">
          {modalSubtitle}
        </Text>

        <AddButton
          data-testid="add-new-file-button"
          onClick={handleAddNewFile}
          className="mt-2 mx-2"
        >
          {addNewFileButtonText}
        </AddButton>
      </ModalHeader>

      <ModalBody>
        <SearchBox
          onChangeText={(text: string) => onSearchInputChange(text)}
          data-testid="attach-file-searchbox-input"
          placeholder={searchPlaceholder}
          className="mb-8"
        />

        <div
          data-testid="attach-file-modal-files"
          className="max-h-30vh overflow-y-scroll overflow-x-hidden px-3 pb-1"
        >
          {renderFileList()}
        </div>

        <div className="flex justify-center m-auto mt-10">
          <FormError error={error} />
          <Button
            disabled={!selectedFile}
            onClick={handleAttachFile}
            data-testid="attach-file-modal-button"
            className="w-48"
          >
            {attachFileButtonText}
          </Button>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
