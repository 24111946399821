import { useTranslation } from "react-i18next";

import { useFileDetails } from "./useFileDetails";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { NormalStepComponentProps, NormalStepData } from "../../types";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import {
  ChangeContentTypeBody,
  ChangeToDSMBody,
  ChangeToEditorBody,
  ChangeToFileBody,
  eolasLogger,
  EolasSupportedMiscellaneousType,
  hasProp,
  NewContentType,
} from "@eolas-medical/core";
import { isSupportedBlobType } from "../../typeguards";
import { getFinalStepName } from "../../functions/getFinalStepName";
import {
  handleUpdateWizardDraftStatus,
  useMakePenultimateStepButtonProps,
} from "../../../../hooks/useMakePenultimateStepButtonProps";
import { FileDetailsFormComponent } from "./FileDetailsFormComponent";

const FileDetails = ({ onClickBack, onClickForward, wizardState }: NormalStepComponentProps) => {
  const { handleSubmit, control } = useFileDetails(wizardState);

  const { t } = useTranslation();

  const submitFunc = ({ isDraft }: { isDraft: boolean }) => {
    handleSubmit((values) => {
      let newState = makeMutableWizardState(wizardState);
      if (values.hasExpiryDate && values.expiryDate) {
        if (!newState.isExistingFileWithNewType) {
          newState.dto.expiryDate = values.expiryDate;
        } else if (isFileDetailsCompatibleChangeTypeDto(newState.dto)) {
          newState.dto.newProperties.expiryDate = values.expiryDate;
        }
      } else {
        if (hasProp(newState.dto, "expiryDate") && newState.dto.expiryDate) {
          newState.dto.expiryDate = null;
        }
        if (
          newState.isExistingFileWithNewType &&
          hasProp(newState.dto.newProperties, "expiryDate") &&
          newState.dto.newProperties.expiryDate
        ) {
          // This will have been added during the wizard, not yet saved to BE:
          delete newState.dto.newProperties.expiryDate;
        }
      }
      const keywords = values.keywords.split(",").map((str) => str.trim());
      if (!newState.isExistingFileWithNewType) {
        newState.dto.name = values.name;
        newState.dto.keywords = keywords;
      } else {
        newState.dto.newProperties.name = values.name;
        if (isFileDetailsCompatibleChangeTypeDto(newState.dto)) {
          newState.dto.newProperties.keywords = keywords;
        }
      }
      newState = handleUpdateWizardDraftStatus({ state: newState, isDraft });
      onClickForward(newState);
    })();
  };

  const buttonProps = useMakePenultimateStepButtonProps({
    currentWizardState: wizardState,
    handleClickBack: onClickBack,
    handleClickForward: () => submitFunc({ isDraft: false }),
    handleAltClickForward: () => submitFunc({ isDraft: true }),
  });

  return (
    <EolasWizardModalWrapper
      header={{
        title: wizardState.fileId
          ? t("repository_edit_item_title")
          : t("repository_add_item_title"),
      }}
      buttonRowProps={buttonProps}
    >
      <FileDetailsFormComponent control={control} />
    </EolasWizardModalWrapper>
  );
};

export const fileDetailsData: NormalStepData = {
  StepComponent: FileDetails,
  props: {
    getPreviousStepName: () => "selectFile",
    getNextStepName: (_, state) => {
      let type: string | undefined = undefined;
      if (state.isExistingFileWithNewType) {
        type = state.dto.newType;
      } else {
        type = state.type;
      }
      if (!type) {
        eolasLogger.error("Manage Content Modal File Details step: should have type defined", {
          type,
        });
        return "fileDetails";
      }
      if (type === EolasSupportedMiscellaneousType.EOLAS_EDITOR) {
        return "eolasEditorCreator";
      }
      if (type === EolasSupportedMiscellaneousType.DSM) {
        return getFinalStepName(state);
      }
      if (isSupportedBlobType(type) || type === NewContentType.FILE) {
        return getFinalStepName(state);
      }
      eolasLogger.error("Manage Content Modal File Details step: unrecognised type", { type });
      return "fileDetails";
    },
  },
};

// If any other types are added / support the file details step, add here:
const isFileDetailsCompatibleChangeTypeDto = (
  dto: ChangeContentTypeBody,
): dto is ChangeToFileBody | ChangeToEditorBody | ChangeToDSMBody => {
  return [NewContentType.FILE, NewContentType.DSM].includes(dto.newType);
};
