import { FieldPath, FieldValues, UnpackNestedValue } from "react-hook-form";
import { FormItem } from "./types";
import { Input, Textarea } from "UIKit/FormElements";
import Select, { Option } from "UIKit/Select/Select";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";
import { isOption } from "./typeguards";

interface RenderFormItemProps<FormStepValues extends FieldValues> {
  field: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (value: any) => void;
    onBlur: () => void;
    value: UnpackNestedValue<FormStepValues[FieldPath<FormStepValues>]>;
  };
  formItem: FormItem<FormStepValues>;
}

export const renderFormItem = <FormStepValues extends FieldValues>({
  field,
  formItem,
}: RenderFormItemProps<FormStepValues>) => {
  const { value, ...rest } = field;
  switch (formItem.itemType) {
    case "input":
      return (
        <Input
          {...rest}
          size={formItem.inputSize}
          placeholder={formItem.fieldPlaceholder}
          value={value || ""}
        />
      );
    case "text-area":
      return <Textarea {...rest} placeholder={formItem.fieldPlaceholder} value={value || ""} />;
    case "select":
      return (
        <Select
          {...rest}
          defaultValue={value}
          options={formItem.selectOptions}
          isMulti={formItem.isMultiSelect}
          isSearchable={formItem.isSearchable}
        />
      );
    case "eolas-select": {
      let defaultOption: Option | Option[] | null = null;
      if (isOption(value) || (Array.isArray(value) && isOption(value[0]))) {
        defaultOption = value;
      }
      return (
        <EolasSelect
          {...rest}
          mode="synchronous"
          defaultValue={defaultOption}
          isMulti={formItem.isMultiSelect}
          shouldAllowCreate={formItem.shouldAllowCreateInSelect}
          options={formItem.selectOptions}
          isSearchable={formItem.isSearchable}
        />
      );
    }

    default:
      return (
        <Input
          {...rest}
          size={formItem.inputSize}
          placeholder={formItem.fieldPlaceholder}
          value={value || ""}
        />
      );
  }
};
