import {
  AxiosError,
  CompletedContentResponseItem,
  contentClient,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import useWebNotifications from "modules/webNotifications/data/useWebNotifications";
import { useMutation } from "@tanstack/react-query";
import { WebNotification } from "modules/webNotifications/types";
import { errorStore } from "Stores/ErrorStore";
import useDismissBulkNotifications from "modules/webNotifications/data/useDismissBulkNotifications";

interface MutationContext {
  onSuccess?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;
}

type MutationReturnType = CompletedContentResponseItem[];

interface UseMarkAllAsReadProps {
  sectionID: string;
}

const useMarkAllAsComplete = ({ sectionID }: UseMarkAllAsReadProps) => {
  const { mainSectionID } = sectionStore.getSection(sectionID);
  const { userID } = userStore;

  const completeAllSectionContentDto = { parentId: sectionID, userId: userID };
  const { appID: spaceId, organisationID: organisationId } = sectionStore;
  const { dismissBulkNotifications } = useDismissBulkNotifications();
  const { webNotifications, refetchWebNotifications } = useWebNotifications({
    userId: userID,
    spaceId,
    organisationId,
  });

  const updateNotificationDisplay = async (
    readContent: CompletedContentResponseItem[],
    notifications: WebNotification[],
  ) => {
    if (readContent.length) {
      const filteredNotificationIds = notifications
        .filter(
          (notification) =>
            notification.notificationType === "newsFeed" &&
            readContent.some((item) => item.fileId === notification.metadata?.fileID),
        )
        .map((notification) => notification.id);

      dismissBulkNotifications(filteredNotificationIds);
    }
  };

  const { mutate, isLoading, error, isSuccess } = useMutation<
    MutationReturnType,
    AxiosError,
    void,
    MutationContext
  >(
    () =>
      contentClient.completeAllSectionContent({
        mainSectionId: mainSectionID,
        completeAllSectionContentDto,
      }),

    {
      onSuccess: async (completedContent: CompletedContentResponseItem[]) => {
        userStore.updateData({ completedContent });
        await refetchWebNotifications();
        updateNotificationDisplay(completedContent, webNotifications);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any, _, context) => {
        errorStore.captureError({
          error: error.message,
          source: "user",
        });
        if (context?.onError) {
          context.onError(error);
        }
      },
    },
  );

  return {
    markAllAsRead: mutate,
    markingAsReadInProgress: isLoading,
    markAllAsReadError: error?.message,
    markAllAsReadSuccess: isSuccess,
  };
};

export default useMarkAllAsComplete;
