import { useParams } from "react-router-dom";
import { EventType } from "modules/portfolio/types";

interface UsePortfolioParams {
  eventId: string;
  eventType: EventType;
  superviseeId: string;
  superviseeName: string;
}

const usePortfolioParams = (): UsePortfolioParams => {
  const {
    eventId = "",
    eventType = "",
    superviseeId,
    superviseeName,
  } = useParams<{
    eventId: string;
    eventType: string;
    superviseeId: string;
    superviseeName: string;
  }>();

  const eventTypeUpperCase: EventType = eventType.toUpperCase() as EventType;

  return { eventId, eventType: eventTypeUpperCase, superviseeId, superviseeName };
};

export default usePortfolioParams;
