import { CommunitySpacesIcon, CreateSpaceIcon, MyPortfolioIcon, SpaceIcon } from "Assets";

interface GetStartedModalProps {
  onGoToKnowledgeTab: () => void;
  onGoToMeTab: () => void;
  onGoToSpacesTab: () => void;
  onGoToCreateSpace: () => void;
}

export const useGetStartedSteps = ({
  onGoToKnowledgeTab,
  onGoToMeTab,
  onGoToSpacesTab,
  onGoToCreateSpace,
}: GetStartedModalProps) => {
  const getStartedSteps = [
    {
      icon: <CommunitySpacesIcon className="h-12 w-12" />,
      titleKey: "explore_knowledge_tab_title",
      descriptionKey: "explore_knowledge_tab_description",
      onClick: onGoToKnowledgeTab,
      titleColor: "green-500",
    },
    {
      icon: <SpaceIcon className="h-12 w-12" />,
      titleKey: "access_existing_space_title",
      descriptionKey: "access_existing_space_description",
      onClick: onGoToSpacesTab,
      titleColor: "blue-500",
    },
    {
      icon: <CreateSpaceIcon className="h-12 w-12" />,
      titleKey: "create_new_space_title",
      descriptionKey: "create_new_space_description",
      onClick: onGoToCreateSpace,
      titleColor: "blue-500",
    },
    {
      icon: <MyPortfolioIcon className="h-12 w-12" />,
      titleKey: "explore_me_tab_title",
      descriptionKey: "explore_me_tab_description",
      onClick: onGoToMeTab,
      titleColor: "yellow-600",
    },
  ];

  return { getStartedSteps };
};
