import React from "react";
import { twMerge } from "tailwind-merge";

interface TagProps {
  label: string;
  className?: string;
  size?: "sm" | "md" | "lg";
}

const Tag = ({ label, className = "", size = "md", ...rest }: TagProps) => {
  const sizeClasses = {
    sm: "px-2 py-0.5 text-xs block",
    md: "px-3 py-1 text-sm sm:block",
    lg: "px-4 py-1.5 text-base sm:block",
  };

  return (
    <div
      {...rest}
      className={twMerge(
        `hidden bg-quartz-50 rounded-sm font-bold leading-5 text-quartz-600 ${sizeClasses[size]}`,
        className,
      )}
    >
      {label}
    </div>
  );
};

export default Tag;
