import { useRefetchAppData } from "Hooks";
import { modalStore } from "Stores/ModalStore";
import { DeleteModal, SuccessModal } from "UIKit";
import useDeleteContentSection from "modules/contentRepository/data/useDeleteContentSection";
import { ContentSection } from "modules/contentRepository/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  contentSection: ContentSection;
};

export const DeleteSpaceOrgSectionModal = ({ contentSection }: Props) => {
  const { t } = useTranslation();
  const { refetch } = useRefetchAppData();

  // Note: This hook deliberately does not consume context as the context adds no value and is an issue when used in a mobx modal.
  const onSuccessCallback = useCallback(async () => {
    refetch();
    modalStore.closeModal();
  }, [refetch]);
  const {
    deleteContentSection,
    deletingContentSection,
    deleteContentSectionSuccess,
    deleteContentSectionError,
  } = useDeleteContentSection({ onSuccess: onSuccessCallback });

  const handleDeleteSection = () => {
    deleteContentSection(contentSection);
  };

  if (deleteContentSectionSuccess) {
    return (
      <SuccessModal
        onComplete={modalStore.closeModal}
        text={t("my_files_delete_section_successful")}
      />
    );
  }

  return (
    <div data-testid="delete-content-section-modal">
      <DeleteModal
        title={t("my_files_delete_section")}
        onCloseModal={modalStore.closeModal}
        onDelete={handleDeleteSection}
        isLoading={deletingContentSection}
        error={deleteContentSectionError}
        description={t("deleteModal_delete_section_description")}
      />
    </div>
  );
};
