import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, Space } from "@eolas-medical/core";
import Fuse from "fuse.js";
import { ClickSearchBox, FilterButton, Modal } from "UIKit";
import { trackEvent } from "API/Analytics";
import useSearchSpace from "modules/spaces/data/useSearchSpace";
import SearchFiltersModal from "../SerchFiltersModal/SearchFiltersModal";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { Pill } from "UIKit/Pill";
import { XCircleIcon } from "Assets";

interface SpaceSearch {
  onSearchSuccessful: (searchResult: Space[], searchInput?: string) => void;
  onClearSearch: () => void;
}

const SpaceSearch = ({ onSearchSuccessful, onClearSearch }: SpaceSearch) => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const [searchInput, setSearchInput] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);

  const { searchSpace, searchingSpace, searchSpaceError } = useSearchSpace();

  const handleChange = (text: string) => {
    setSearchInput(text.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchInput("");
    onClearSearch();
    setHasResults(false);
    setSelectedSpecialties([]);
  };

  const fuseOptions = {
    keys: ["specialty"],
    distance: 90,
    threshold: 0.15,
  };

  const handleSearch = () => {
    trackEvent(AnalyticsEvents.ORGANISATION_SEARCH, { searchString: searchInput });
    searchSpace(searchInput, {
      onSuccess: (searchResponse: Space[]) => {
        searchResponse.length > 0 && setHasResults(true);
        onSearchSuccessful(searchResponse, searchInput);
      },
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClickFilter = () => {
    setShowFilterModal(true);
  };

  const handleCloseFilters = () => {
    setShowFilterModal(false);
  };

  const handleRemoveSpecialty = (specialty: string) => {
    const updatedSpecialties = selectedSpecialties.filter((s) => s !== specialty);
    setSelectedSpecialties(updatedSpecialties);
    handleApplyFilters(updatedSpecialties);
  };

  const handleApplyFilters = (specialties: string[] = selectedSpecialties) => {
    searchSpace(searchInput, {
      onSuccess: (searchResponse: Space[]) => {
        const results =
          specialties.length > 0
            ? filterSpacesBySpecialties(searchResponse, specialties)
            : searchResponse;

        onSearchSuccessful(results);
      },
    });
    handleCloseFilters();
  };

  const filterSpacesBySpecialties = (spaces: Space[], specialties: string[]): Space[] => {
    const fuse = new Fuse<Space>(spaces, fuseOptions);
    const resultsMap = new Map<number, Space>();

    specialties.forEach((specialty) => {
      const results = fuse.search(specialty).map((result) => result.item);
      results.forEach((space) => resultsMap.set(space.id, space));
    });

    return Array.from(resultsMap.values());
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      onClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const showFilters = !!flags[LDFlagNames.DISCOVER_SPACES_FILTER] && hasResults;

  return (
    <>
      <div>
        <div className="flex mx-auto w-full max-w-sm sm:max-w-xl md:max-w-2xl xl:max-w-3xl space-x-0 sm:space-x-2">
          <ClickSearchBox
            searchBoxSize="xl"
            onChangeText={handleChange}
            onKeyDown={handleKeyDown}
            onClickSearch={handleSearch}
            onClearSearch={handleClearSearch}
            data-testid="search-spaces-input"
            iconClassName="hidden md:inline text-blue-500"
            className={"rounded-3xl shadow-xl w-full z-10"}
            placeholder={t("selectSpace_searchPlaceholder")}
            isLoading={searchingSpace}
            value={searchInput}
          />
          {showFilters && (
            <FilterButton
              onClick={handleClickFilter}
              buttonText={t("general_filter")}
              size="xl"
              className="shadow-xl flex-1"
            />
          )}
        </div>
        <div className="flex flex-wrap mt-2">
          {selectedSpecialties.map((specialty, index) => (
            <div key={index} className="flex items-center mr-2 mb-2">
              <Pill
                value={specialty}
                icon={<XCircleIcon />}
                className="bg-grey-300 font-bold text-xs cursor-pointer"
                onClick={() => handleRemoveSpecialty(specialty)}
              />
            </div>
          ))}
        </div>
      </div>
      {searchSpaceError && <span className="text-red text-center">{searchSpaceError}</span>}
      <Modal open={!!showFilterModal} onClose={handleCloseFilters}>
        <SearchFiltersModal
          selectedSpecialties={selectedSpecialties}
          onSelectSpecialties={setSelectedSpecialties}
          onApply={handleApplyFilters}
        />
      </Modal>
    </>
  );
};

export default SpaceSearch;
