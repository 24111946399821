import localforage from "localforage";
import envConfig from "env-config";
import Smartlook from "smartlook-client";

import {
  userStore,
  fileStore,
  sectionStore,
  initialiseFileStore,
  initialiseUserStore,
  initialiseCommunityClient,
  initialiseAccessClient,
  initialiseProfilesClient,
  initialiseSearchClient,
  initialiseApolloClient,
  initialiseInviteClient,
  initialiseSectionStore,
  initialiseLoginTokenClient,
  initHandleErrors,
  AxiosError,
  authenticationClient,
  initialiseAuthenticationClient,
  initialiseNationalResourcesClient,
  initialiseNotificationsClient,
  initialiseSpacesClient,
  initialiseContentClient,
  initialiseCopilotClient,
  initialiseKnowledgeContentClient,
  initialiseBnfClient,
  initialiseNiceGuidelinesClient,
  initialiseMyFavouritesClient,
  initialiseMyFavouritesStore,
  initialiseSponsoredSlotClient,
  initialiseDocumentClient,
  initialiseClinicalQuestionsClient,
  initialiseClinicalQuestionStore,
  ApiClientParams,
} from "@eolas-medical/core";

import { Sentry } from "Contexts";
import { AppEnv } from "Utilities/types";
import { initMixpanel } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";

import { API_ENV_PATH_PREFIXS } from "./general";
import { EolasBabel } from "./babel";
import { Auth, Hub, Amplify } from "aws-amplify";

import { onLogout, logout, onSuccessfulLogin } from "Pages/Authentication/auth.actions";
import { AuthClientParams } from "@eolas-medical/core/lib/src/clients/authentication/authentication.types";
import { initialiseLoginTokenService } from "API/LoginTokenService";
import { apiErrorsToIgnore, apolloClientDefaultOptions, apolloConfig, apolloLinks } from "config";
import { initialiseWebStore } from "Stores/WebStore";
import { initialisePortfolioService } from "modules/portfolio/client/PortfolioService";
import { initialiseMyFilesService } from "modules/myFiles/client/myFiles.client";
import { initialisePortfolioWizardService } from "modules/portfolio/client/PortfolioWizardService";
import { initialiseMedusaService } from "API/MedusaService";

const authServiceBaseUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/auth/v1`;

export const startup = () => {
  initHandleErrors(apiErrorsToIgnore);
  const eolasBabel = new EolasBabel();

  const awsConfig = {
    Auth: {
      identityPoolId: envConfig.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolId: envConfig.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: envConfig.REACT_APP_WEB_CLIENT_ID,
      endpoint: `${authServiceBaseUrl}/proxy`,
      region: envConfig.REACT_APP_AWS_REGION,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
    oauth: {
      domain: envConfig.REACT_APP_COGNITO_APP_DOMAIN,
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
    storage: eolasBabel,
  };

  const authConfig: AuthClientParams = {
    amplifyClasses: { Auth, Hub },
    storage: eolasBabel,
    federatedDomains: envConfig.REACT_APP_SSO_DOMAINS.split(", "),
    baseUrl: authServiceBaseUrl,
    onSignIn: onSuccessfulLogin,
    onSignOut: onLogout,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRetrieveTokenError: (error: any) => {
      Sentry.addBreadcrumb({
        category: "auth-client",
        message: `"unable to retrieve a valid token": ${error}`,
      });
      errorStore.captureError(error);
    },
  };

  const handleAxiosError = async (error: AxiosError) => {
    if (!userStore?.userSession.isLoggedIn) {
      return;
    }

    if ([401, 403].includes(error.response?.status ?? 0)) {
      const isGet = ["get", "GET"].includes(error.config?.method ?? "");
      const isFileFetchError =
        error.config?.url?.match("content-repository/v1/sections/.*/content") && isGet;
      const isProfileFetchError = error.config?.url?.match("profiles/v1/profiles/.*") && isGet;

      // These are 2 important fetches, if they fail due to unauthorised errors, we should log out the user:
      if (isFileFetchError || isProfileFetchError) {
        logout();
      }
    }
  };

  const initialiseClientsConfig: ApiClientParams = {
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl: `https://${envConfig.REACT_APP_API_BASE_URL}/`,
    handleAxiosError,
  };

  initialiseWebStore();

  initialiseSectionStore(
    {
      stringify: false,
      debugMode: false,
      name: "sectionStore",
      storage: localforage,
      expireIn: 24 * 60 * 60 * 1000,
    },
    false,
  );

  initialiseFileStore(
    {
      stringify: false,
      debugMode: false,
      name: "fileStore",
      storage: localforage,
      expireIn: 24 * 60 * 60 * 1000,
    },
    false,
  );

  initialiseUserStore(
    {
      stringify: false,
      debugMode: false,
      name: "userStore",
      storage: localforage,
      expireIn: 24 * 60 * 60 * 1000,
    },
    false,
  );

  initialiseMyFavouritesStore({
    stringify: false,
    debugMode: false,
    name: "myFavouritesStore",
    storage: localforage,
    expireIn: 24 * 60 * 60 * 1000,
  });

  initialiseClinicalQuestionStore({
    stringify: false,
    debugMode: false,
    name: "clinicalQuestionStore",
    storage: localforage,
  });

  Amplify.configure(awsConfig);
  initialiseAuthenticationClient(authConfig);

  initialiseApolloClient({
    config: apolloConfig,
    customClientLinks: apolloLinks,
    defaultOptions: apolloClientDefaultOptions,
  });

  initialiseInviteClient(initialiseClientsConfig);

  initialiseCommunityClient({ ...initialiseClientsConfig });

  initialiseProfilesClient(initialiseClientsConfig);

  initialiseAccessClient(initialiseClientsConfig);

  initialiseNationalResourcesClient(initialiseClientsConfig);

  initialiseLoginTokenService(initialiseClientsConfig);

  initialisePortfolioService(initialiseClientsConfig);

  initialiseMyFilesService(initialiseClientsConfig);

  initialiseNotificationsClient(initialiseClientsConfig);

  initialisePortfolioWizardService(initialiseClientsConfig);

  initialiseSpacesClient({
    ...initialiseClientsConfig,
    searchBaseUrl:
      envConfig.REACT_APP_APP_ENV === AppEnv.PRODUCTION
        ? "https://search.eolas.click"
        : `https://${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}.search.eolas.click`,
  });

  initialiseContentClient(initialiseClientsConfig);

  initialiseKnowledgeContentClient({
    ...initialiseClientsConfig,
    searchBaseUrl:
      envConfig.REACT_APP_APP_ENV === AppEnv.PRODUCTION
        ? "https://search.eolas.click"
        : `https://${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}.search.eolas.click`,
  });

  initialiseCopilotClient(initialiseClientsConfig);

  initialiseSearchClient({
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl:
      envConfig.REACT_APP_APP_ENV === AppEnv.PRODUCTION
        ? "https://search.eolas.click"
        : `https://${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}.search.eolas.click`,
    // handleRetryAttempt,
  });

  initialiseLoginTokenClient({
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl: `https://${envConfig.REACT_APP_API_BASE_URL}/`,
  });

  initialiseBnfClient(initialiseClientsConfig);

  initialiseNiceGuidelinesClient(initialiseClientsConfig);

  initialiseNationalResourcesClient(initialiseClientsConfig);

  initialiseMyFavouritesClient(initialiseClientsConfig);

  initialiseSponsoredSlotClient(initialiseClientsConfig);

  initialiseClinicalQuestionsClient(initialiseClientsConfig);

  initialiseDocumentClient(initialiseClientsConfig);

  initialiseMedusaService(initialiseClientsConfig);

  initMixpanel();
};

const initiateSessionSentry = () => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => {
      event.contexts = {
        smartLook: {
          // eslint-disable-next-line camelcase
          session_id: Smartlook.sessionId,
          timestamp: new Date().getTime(),
        },
      };
      return event;
    });
  });
};

export const smartLookStartRecording = () => {
  initiateSessionSentry();
  Smartlook.init(envConfig.REACT_APP_SMARTLOOK_KEY);
};

export const globalWaitForHydration = async () => {
  const hydrationResults = await Promise.allSettled([
    sectionStore.isHydrated,
    userStore.isHydrated,
    fileStore.isHydrated,
    authenticationClient.isHydrated,
  ]);

  hydrationResults.forEach((outcome) => {
    if (outcome.status === "rejected") {
      errorStore.captureError(outcome.reason);
    }
  });
};
