import { useState, useCallback } from "react";
import { Modal, FilterButton } from "UIKit";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { FilterTimelineModal } from "./FilterTimelineModal";
import FiltersAppliedList from "./FiltersAppliedList";
import { FilterObjectType, EventStatus, FeedbackStatus } from "modules/portfolio/types";

interface FilterSectionProps {
  eventStatuses?: (FeedbackStatus | EventStatus)[];
  onUpdateFilters: (filterObject: FilterObjectType) => void;
  searchInput: string;
  filterObject: FilterObjectType;
  areFiltersApplied: boolean;
  onSearchInputChange: (text: string) => void;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  eventStatuses = [],
  searchInput,
  filterObject,
  areFiltersApplied,
  onUpdateFilters,
  onSearchInputChange,
}: FilterSectionProps) => {
  const [isModalOpened, setModalState] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => setModalState(false), [setModalState]);

  const handleClickFilterButton = useCallback(() => {
    setModalState((isModalOpened) => !isModalOpened);
  }, [setModalState]);

  const handleUpdateFilters = useCallback(
    (filterObject: FilterObjectType) => {
      onUpdateFilters(filterObject);
    },
    [onUpdateFilters],
  );

  return (
    <>
      <Modal open={isModalOpened} onClose={handleCloseModal}>
        <FilterTimelineModal
          eventStatuses={eventStatuses}
          filterObject={filterObject}
          onCloseModal={handleCloseModal}
          onSubmit={handleUpdateFilters}
        />
      </Modal>
      <div className="w-full justify-center justify-self-center flex align-middle mb-8">
        <SearchBox
          value={searchInput}
          placeholder="Search events..."
          onChangeText={onSearchInputChange}
          data-testid="portfolio-search-input"
          autoFocus
          className="rounded-2xl"
        />
        <FilterButton areFiltersApplied={areFiltersApplied} onClick={handleClickFilterButton} />
      </div>
      {areFiltersApplied && (
        <FiltersAppliedList filterObject={filterObject} onUpdateFilters={handleUpdateFilters} />
      )}
    </>
  );
};
