import { useMutation } from "@tanstack/react-query";
import { ContentSection } from "../types";
import { contentClient, sectionStore } from "@eolas-medical/core";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseDeleteContentSectionProps<T, R> {
  onSuccess: (params?: T) => Promise<R>;
}

const useDeleteContentSection = <T, R>({ onSuccess }: UseDeleteContentSectionProps<T, R>) => {
  const { mutate, isLoading, isSuccess, error, reset } = useMutation(
    async (contentSection: ContentSection) => {
      /**
       * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
       */
      const section: any = sectionStore.getSection(contentSection.id);
      await contentClient.deleteSectionRest(contentSection.id);
      return section;
    },
    {
      onSuccess: async (section) => {
        if (section.id) {
          intermediateUpdateForUi({ type: "section", action: "delete", section });
        }
        await onSuccess();
        reset();
      },
    },
  );

  return {
    deleteContentSection: mutate,
    deletingContentSection: isLoading,
    deleteContentSectionSuccess: isSuccess,
    deleteContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useDeleteContentSection;
