import { InnerModalWrapper, Title } from "UIKit";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { useVersionHistory } from "./hooks/useVersionHistory";
import { EnrichedFileVersionWithDayAndTime } from "./types";
import { VersionTile } from "./components/VersionTile";
import { VersionDetails } from "./components/VersionDetails";
import { useTranslation } from "react-i18next";
import { ContentItem } from "@eolas-medical/core";

export const ShowVersionsModal = ({
  contentId,
  handleClickVersionPreview,
}: {
  contentId: string;
  handleClickVersionPreview: (item: ContentItem, versionNo: number) => void;
}) => {
  const {
    searchInstanceId,
    searchResults,
    isSearchActive,
    handleSearchClick,
    handleClearSearch,
    isLoadingContentVersions,
    selectedVersion,
    onClickVersionTile,
    fileVersionsWithDayAndTime,
    onClickBack,
  } = useVersionHistory(contentId);

  const { t } = useTranslation();

  const renderItem = (args: { item: EnrichedFileVersionWithDayAndTime }) => {
    const { item } = args;
    return <VersionTile version={item} onClickTile={() => onClickVersionTile(item)} />;
  };

  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-70vh w-full">
        <div className="w-full h-full">
          {selectedVersion ? (
            <VersionDetails
              version={selectedVersion}
              onClickBack={onClickBack}
              handleClickVersionPreview={handleClickVersionPreview}
            />
          ) : (
            <>
              <Title level={4} className="text-center px-9 line-clamp-2 mb-2">
                {t("version_history")}
              </Title>
              <EolasVersatileList
                items={isSearchActive ? searchResults : fileVersionsWithDayAndTime}
                isSearchable
                renderItem={renderItem}
                searchInstanceId={searchInstanceId}
                outerContainerClassName="w-full h-full mt-3"
                isSearchLoading={isLoadingContentVersions}
                onClearSearch={handleClearSearch}
                onClickSearch={handleSearchClick}
                searchMode="click"
                isInModal
                context={{ isInitialLoading: isLoadingContentVersions }}
              />
            </>
          )}
        </div>
      </div>
    </InnerModalWrapper>
  );
};
