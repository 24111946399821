import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, EolasFile, fileStore, sectionStore } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { formatDate } from "Utilities/general";
import { IssuesIcon, noFilesFound } from "Assets";
import { DeleteFileModal, EolasFileList } from "Components";
import { IncidentReportingResolveStatus } from "Utilities/types";
import { Modal, Button, NavButton, PageTitle, InnerPageWrapper, LottieWithHeader } from "UIKit";

import { IncidentReportModal, UnresolvedReportsModal } from "../components";

const filterIncidentReports = (searchInput: string) => (incidentReport: EolasFile) => {
  const author = JSON.parse(incidentReport.moreInfo!);
  const info = JSON.parse(incidentReport.info!);

  const searchThrough: string[] = [
    info.title.toLowerCase(),
    author.name.toLowerCase(),
    formatDate(info.date, "PPPPpppp").toLowerCase(),
    formatDate(incidentReport.createdAt as string, "PPPPpppp").toLowerCase(),
  ];

  return searchThrough.some((term) => term.includes(searchInput));
};

const sortFn = (first: EolasFile, second: EolasFile) => {
  return new Date(second.createdAt!).valueOf() - new Date(first.createdAt!).valueOf();
};

export const AdminIncidentReporting: React.FC<RouteComponentProps> = observer(
  ({ match: { url } }) => {
    useMainSectionVisit({ mainSectionId: AppLevelSection.incidentReporting });

    const { t } = useTranslation();
    const { name, id } = sectionStore.getChildReferenceByMainSectionType(
      AppLevelSection.incidentReporting,
    )!;

    const files = fileStore.getFiles(sectionStore.getChildrenOrder(id));

    const [modal, setModal] = useState<React.ReactNode>(null);

    const unresolvedIncidents = files.filter(
      (incident) => incident.moreInfoTwo === IncidentReportingResolveStatus.notResolved,
    );

    const onCloseModal = useCallback(() => {
      setModal(null);
    }, [setModal]);

    const onSelectReport = (incidentReport: EolasFile) => {
      setModal(<IncidentReportModal incidentReport={incidentReport} onCloseModal={onCloseModal} />);
    };

    const onDeleteReport = useCallback(
      (incidentReport: EolasFile) => {
        setModal(
          <DeleteFileModal
            file={incidentReport}
            onCloseModal={onCloseModal}
            description={t("deleteModal_delete_incidentReporting_description")}
          />,
        );
      },
      [setModal, onCloseModal, t],
    );

    const showUnresolvedReports = () => {
      const incidents = [...unresolvedIncidents];
      incidents.sort(sortFn);

      setModal(
        <UnresolvedReportsModal onSelectReport={onSelectReport} unresolvedIncidents={incidents} />,
      );
    };

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={name ?? t("incident_reporting_reported_incidents")} />
          <NavButton to={url.replace(`/${AppLevelSection.incidentReporting}`, "")} />

          {unresolvedIncidents.length > 0 && (
            <Button
              color="red"
              variant="outline"
              className="self-center"
              onClick={showUnresolvedReports}
              data-testid="unresolvedIncidentButton"
            >
              {t("incident_reporting_unresolved_incidents_button")}
              <IssuesIcon className="ml-2 text-red" width={24} height={24} />
            </Button>
          )}

          <EolasFileList
            isAdmin
            defaultSort="date"
            draggable={false}
            onDelete={onDeleteReport}
            onSelectFile={onSelectReport}
            adminNoFiles={<AdminNoFiles />}
            filterFn={filterIncidentReports}
            mainSectionId={AppLevelSection.incidentReporting}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);

const AdminNoFiles = () => {
  const { t } = useTranslation();
  return (
    <LottieWithHeader
      animationSize="25%"
      animation={noFilesFound}
      data-testid="no-files-found"
      lottieOptions={{ loop: true }}
      text={t("general_noFilesFound")}
    />
  );
};
