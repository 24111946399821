import QRCode from "qrcode.react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

import { EolasFile } from "@eolas-medical/core";
import { Button, InnerModalWrapper, FormError, Text, ModalBody } from "UIKit";

import { useQRLeaflet } from "../hooks/useQRLeaflet";
import { generateS3PublicImageURL } from "Utilities";

interface PatientLeafletQRModalProps {
  file: EolasFile;
}

export const PatientLeafletQRModal: React.FC<PatientLeafletQRModalProps> = ({
  file,
}: PatientLeafletQRModalProps) => {
  const { t } = useTranslation();
  const { error, QRValue, videoURL, isLoading, setVideoURL, onSelectFile, shouldShowPlayer } =
    useQRLeaflet(file);

  return (
    <InnerModalWrapper>
      <ModalBody className="flex flex-col justify-center items-center">
        {shouldShowPlayer ? (
          <div className="flex flex-col mt-4 sm:mt-8">
            <div className="flex flex-col">
              <ReactPlayer
                playing
                controls
                width="100%"
                height="100%"
                stopOnUnmount
                url={videoURL}
                style={{ width: "100%" }}
                data-testid="videoPlayer"
              />

              <Button
                color="grey"
                weight="regular"
                className="self-center my-4"
                onClick={() => setVideoURL("")}
              >
                {t("EdPortal_videoPlayer_button")}
              </Button>
            </div>
          </div>
        ) : (
          <QRCode data-testid="leaflet-QR-code" value={QRValue} size={260} className="mb-8" />
        )}

        {!shouldShowPlayer ? (
          <>
            {file.type === "pdf" || file.type === "ms-office" ? (
              <Button
                color="blue"
                className="self-center mb-4"
                onClick={() => window.open(generateS3PublicImageURL(file.key ?? ""), "_blank")}
              >
                {t("patientLeaflets_preview_leaflet_button")}
              </Button>
            ) : (
              <Button
                color="blue"
                isLoading={isLoading}
                className="self-center mb-4"
                onClick={onSelectFile}
              >
                {t("patientLeaflets_preview_leaflet_button")}
              </Button>
            )}
          </>
        ) : null}

        <FormError error={error} />

        <div className="flex flex-col space-y-2">
          <Text level={2} className="text-sm text-center">
            {t("patientLeaflets_QR_iphone")}
          </Text>
          <Text level={2} className="text-sm text-center">
            {t("patientLeaflets_QR_android")}
          </Text>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
