import { useRefetchAppData } from "Hooks/useAppSync";
import useRemoteIcons from "Hooks/useRemoteIcons";
import { modalStore } from "Stores/ModalStore";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import useEditContentSection from "modules/contentRepository/data/useEditContentSection";
import { ContentSection } from "modules/contentRepository/types";
import { useCallback } from "react";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";

type Props = {
  contentSection: ContentSection;
  variant?: ContentSectionVariants;
};

export const EditSpaceOrgContentSectionWizard = ({ contentSection, variant }: Props) => {
  const { remoteIcons } = useRemoteIcons();
  const { wizardControl, submitWizard } = useWizard();
  const { refetch } = useRefetchAppData();

  // Note: This hook deliberately does not consume context as the context adds no value and is an issue when used in a mobx modal.
  const onSuccessCallback = useCallback(async () => {
    refetch();
    modalStore.closeModal();
  }, [refetch]);
  const { editContentSection, editingContentSection, editContentSectionError } =
    useEditContentSection({ onSuccess: onSuccessCallback });

  const handleSubmit = ({
    name,
    iconName,
    description,
  }: {
    name: string;
    iconName: string;
    description?: string;
  }) => {
    editContentSection(
      {
        id: contentSection.id,
        name,
        description,
        iconName,
      },
      {
        onSuccess: () => {
          modalStore.closeModal();
        },
      },
    );
  };

  const editSectionStep = useConfigureSectionStep<"section">({
    title: "Edit Section",
    stepName: "section",
    iconsList: remoteIcons,
    section: {
      name: contentSection.name || "",
      iconName: contentSection.iconName || "",
      iconUrl: contentSection.iconUrl || "",
      description: contentSection.description || "",
    },
    isLoading: editingContentSection,
    error: editContentSectionError,
    showDescription: variant === "titleAndDescription",
    disableIconPicker: variant === "titleAndDescription",
    onSubmit: handleSubmit,
    onEnter: submitWizard,
    onCancel: modalStore.closeModal,
  });

  const steps: Step<"section">[] = [editSectionStep];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};
