import { AnalyticsEvents, isLinkContentItem } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { KnowledgeContentAnalyticsCallbacks } from "Pages/Knowledge/pages/KnowledgeContent";

export const analyticsCallbacks: KnowledgeContentAnalyticsCallbacks = {
  onLoadContentItem: (item) => {
    const payload = {
      id: item.id,
      name: item.name || "",
      type: item.type,
    };

    if (isLinkContentItem(item)) {
      trackEvent(AnalyticsEvents.DRUG_RESOURCES_CONTENT_VIEWED, {
        ...payload,
        urlTarget: item.urlTarget,
      });
      return;
    }

    trackEvent(AnalyticsEvents.DRUG_RESOURCES_CONTENT_VIEWED, payload);
  },
  onClickContentSection: (section) => {
    trackEvent(AnalyticsEvents.DRUG_RESOURCES_SECTION_SELECTED, {
      id: section.id,
      name: section.name || "",
    });
  },
  onSearchContentItem: (searchValue) => {
    trackEvent(AnalyticsEvents.DRUG_RESOURCES_SEARCH_PERFORMED, {
      query: searchValue,
      searchType: "Content",
    });
  },
  onSearchContentSection(searchValue) {
    trackEvent(AnalyticsEvents.DRUG_RESOURCES_SEARCH_PERFORMED, {
      query: searchValue,
      searchType: "Section",
    });
  },
};
