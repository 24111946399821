import { Synonyms } from "@eolas-medical/core";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import { Strings } from "./SynonymsContent.strings";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";

type SynonymsProps = {
  section: Synonyms;
} & InfoIdProps;

const SynonymsContent = ({ section: synonyms, ...rest }: SynonymsProps) => {
  return (
    <BnfCollapsible title={Strings.title} {...rest}>
      {synonyms.map((text) => {
        return <BnfContentElement key={text} htmlContent={text} />;
      })}
    </BnfCollapsible>
  );
};

export default SynonymsContent;
