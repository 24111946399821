import { useQuery } from "@tanstack/react-query";
import { getDepartmentUsers } from "clients/departments/departments.client";
import spacesKeys from "./spaces.queryKeys";

const useSpaceUsers = (spaceId: string | null) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getDepartmentUsers({ departmentId: spaceId ?? "" }),
    queryKey: spacesKeys.usersList(spaceId ?? ""),
    select: (newData) => {
      return [...newData].sort((a, b) => {
        const nameA = `${a.user?.given_name ?? ""} ${a.user?.family_name ?? ""}`;
        const nameB = `${b.user?.given_name ?? ""} ${b.user?.family_name ?? ""}`;
        return nameA.localeCompare(nameB);
      });
    },
    enabled: Boolean(spaceId),
  });

  return {
    spaceUsers: data ?? [],
    isLoadingSpaceUsers: isLoading,
  };
};

export default useSpaceUsers;
