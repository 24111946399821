/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { Loader, Text, Title, Avatar, Panel } from "UIKit";
import PortfolioReadOnlyElement from "Pages/MeTab/pages/Portfolio/components/PortfolioReadOnlyElement/PortfolioReadOnlyElement";
import useAssessorFeedback from "modules/portfolio/data/useAssessorFeedback";

interface AssessorFeedbackProps {
  eventId: string;
}
const AssessorFeedback = ({ eventId }: AssessorFeedbackProps) => {
  const { t } = useTranslation();
  const { assessorFeedback, assessorFeedbackLoading } = useAssessorFeedback(eventId);

  if (assessorFeedbackLoading) {
    return <Loader className="bg-transparent" />;
  }

  if (assessorFeedback.length === 0) {
    return (
      <div
        data-testid="no-assigned-assessor-text"
        className="rounded-xl border border-grey-400 bg-white w-full flex justify-center items-center py-9"
      >
        <Text className="text-center text-grey-600" level={1}>
          {t("portfolio_no_assessor_assigned")}
        </Text>
      </div>
    );
  }

  return (
    <div data-testid="assessors-feedback">
      {assessorFeedback.map(({ feedbackId, formElements, assessor }) => {
        return (
          <div key={feedbackId}>
            <Panel>
              <div className="flex flex-col space-y-2 md:flex-row justify-between items-center mb-6">
                <Title level={6}>{t("portfolio_assessor_feedback")}</Title>
                <Avatar label={assessor.email} />
              </div>
              {formElements.map((el: any) => {
                return (
                  <div key={el.fieldName} className="mb-4 last:mb-0">
                    <PortfolioReadOnlyElement element={el} />
                  </div>
                );
              })}
            </Panel>
          </div>
        );
      })}
    </div>
  );
};

export default AssessorFeedback;
