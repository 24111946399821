import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { validatePassword, PasswordRules } from "Utilities/authValidations";

import { PasswordValidator } from "./PasswordValidator";

interface PasswordValidationRulesProps {
  onChange?: (valid: boolean) => void;
  password?: string;
}

export const PasswordValidationRules: React.FC<PasswordValidationRulesProps> = ({
  password = "",
  onChange,
}: PasswordValidationRulesProps) => {
  const { t } = useTranslation();

  const onValidate = useCallback((pass: string, rule: keyof PasswordRules) => {
    if (pass) {
      return validatePassword(pass, rule);
    }
    return false;
  }, []);

  useEffect(() => {
    const valid =
      onValidate(password, "LOWERCASE") &&
      onValidate(password, "UPPERCASE") &&
      onValidate(password, "NUMBERS") &&
      onValidate(password, "MIN_LENGTH") &&
      onValidate(password, "SPECIAL_CHAR");

    if (onChange) {
      onChange(valid);
    }
  }, [password, onValidate, onChange]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
      <PasswordValidator
        condition={t("createAccount_password_lowercase")}
        conditionMet={onValidate(password, "LOWERCASE")}
      />
      <PasswordValidator
        condition={t("createAccount_password_uppercase")}
        conditionMet={onValidate(password, "UPPERCASE")}
      />
      <PasswordValidator
        condition={t("createAccount_password_numbers")}
        conditionMet={onValidate(password, "NUMBERS")}
      />

      <PasswordValidator
        condition={t("createAccount_password_symbols")}
        conditionMet={onValidate(password, "SPECIAL_CHAR")}
      />
      <PasswordValidator
        condition={t("createAccount_password_min")}
        conditionMet={onValidate(password, "MIN_LENGTH")}
      />
    </div>
  );
};

export default PasswordValidationRules;
