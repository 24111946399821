import { useQuery } from "@tanstack/react-query";
import {
  InfoSection,
  MedicationItemDetails,
  MonographType,
  bnfClient,
  isObject,
} from "@eolas-medical/core";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";
import bnfKeys from "./bnf.queryKeys";
import { errorStore } from "Stores/ErrorStore";
import { parseInfoItemData } from "../helpers";

interface UseGetInfoItem {
  itemArray: InfoSection[];
  title: string | null;
  isLoading: boolean;
  infoType: "guidance" | "treatmentSummaries" | null;
}

const useGetContentInfoItem = ({
  idOrSid,
  type,
}: {
  idOrSid: string | null;
  type: MonographType;
}): UseGetInfoItem => {
  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.bnfItem(idOrSid, type),
    queryFn: async () => {
      if (!idOrSid) {
        throw new Error("Unable to get BNF item: idOrSid is null");
      }
      return bnfClient.getItemDetailsByIdOrSid({ itemIdOrSid: idOrSid, type });
    },
    select: (data: MedicationItemDetails) => {
      const parsedData = parseInfoItemData(data.data);
      if (!parsedData) {
        const dataForDebugging = isObject(data.data) ? data.data : {};
        errorStore.captureError({
          error: new Error(
            `Unexpected item for BNF medication item. Contains: ${Object.keys(dataForDebugging)}`,
          ),
          source: "user",
        });
        return null;
      }
      return { title: data.name, array: parsedData.sections, infoType: parsedData.infoType };
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
    enabled: Boolean(type),
  });

  return {
    itemArray: data?.array ?? [],
    title: data?.title ?? null,
    infoType: data?.infoType ?? null,
    isLoading: isInitialLoading,
  };
};

export default useGetContentInfoItem;
