import envConfig from "env-config";
import { AppEnv, LDFlags, LDFlagNames } from "Utilities/types";

const DEFAULT_FLAGS: LDFlags = {
  // features
  [LDFlagNames.WEB_MAINTENANCE]: false,
  [LDFlagNames.MULTIPLE_SUBSECTIONS]: false,
  [LDFlagNames.VERSION_HISTORY]: false,

  // department tab sections
  [LDFlagNames.ADMISSION_GUIDES_SECTION]: true,
  [LDFlagNames.CHECKLIST_SECTION]: true,
  [LDFlagNames.CONTACTS_SECTION]: true,
  [LDFlagNames.EDUCATION_PORTAL_SECTION]: true,
  [LDFlagNames.EMERGENCY_ACTION_CARDS_SECTION]: true,
  [LDFlagNames.EQUIPMENT_LOCATIONS_SECTION]: true,
  [LDFlagNames.GUIDELINES_SECTION]: true,
  [LDFlagNames.HIGHLIGHTS_SECTION]: true,
  [LDFlagNames.HOSPITAL_LISTS_SECTION]: true,
  [LDFlagNames.HOW_TO_SECTION]: true,
  [LDFlagNames.IMPORTANT_LINKS_SECTION]: true,
  [LDFlagNames.INCIDENT_REPORTING_SECTION]: true,
  [LDFlagNames.MAJOR_INCIDENT_SECTION]: true,
  [LDFlagNames.MEET_THE_TEAM_SECTION]: true,
  [LDFlagNames.NEWS_FEED_SECTION]: true,
  [LDFlagNames.NOT_USED]: true,
  [LDFlagNames.PATIENT_LEAFLETS_SECTION]: true,
  [LDFlagNames.ROTA_SECTION]: true,
  [LDFlagNames.SURVEYS_SECTION]: true,
  [LDFlagNames.THE_DEPARTMENT_SECTION]: true,
  [LDFlagNames.WELLBEING_SECTION]: true,

  // hosptial tab sections
  [LDFlagNames.DRUG_MONITORING_SECTION]: true,
  [LDFlagNames.HOSPITAL_GUIDELINES_SECTION]: true,
  [LDFlagNames.HOSPITAL_CONTACTS_SECTION]: true,
  [LDFlagNames.HOSPITAL_CLINICAL_CALCULATORS_SECTION]: false,

  [LDFlagNames.FORCE_COOKIES]: false,
  [LDFlagNames.UPDATED_SEARCH]: false,
  [LDFlagNames.MEDUSA_WEB_VIEW]: false,
  [LDFlagNames.WHATS_NEW]: "",
  [LDFlagNames.SHOULD_ENABLE_TINYMCE_CLOUD]: false,
};

export interface AppConfig {
  appName: string;
  appType: string;
  googlePlay: string;
  appleAppStore: string;
  defaultLDFlags: LDFlags;
  termlyID: string;
  termlyPrivacyPolicy: string;
  termlyTermsOfService: string;
  termlyDisclaimer: string;
}

export const appConfig: AppConfig = {
  appName: "Eolas Medical",
  appType: "EOLAS",
  defaultLDFlags: DEFAULT_FLAGS,
  googlePlay: "https://play.google.com/store/apps/details?id=com.eolasmedical.eolas&hl=en_GB&gl=US",
  appleAppStore: "https://apps.apple.com/us/app/eolas-app/id6447954935",
  termlyID: "a7db239c-ed1c-4f30-93d7-35a27fca4dac",
  termlyPrivacyPolicy:
    "https://app.termly.io/document/privacy-policy/604fa71c-ba57-4572-83b0-aa326a6c8ced",
  termlyTermsOfService:
    "https://app.termly.io/document/terms-and-conditions/d56ee62d-191e-402e-a32d-78212d295035",
  termlyDisclaimer:
    "https://app.termly.io/document/disclaimer/9a250a5d-a7f2-4cb3-bc06-acbacc220265",
};

type RepoConfig = {
  commitId: string;
  version: string;
};

export const repoConfig: RepoConfig = (() => {
  const commitId = envConfig.REACT_APP_COMMIT_ID?.slice(0, 7) ?? "";
  return {
    commitId,
    version:
      envConfig.REACT_APP_APP_ENV === AppEnv.DEVELOPMENT
        ? `${envConfig.REACT_APP_VERSION}${commitId ? `-${commitId}` : ""}`
        : envConfig.REACT_APP_VERSION ?? "",
  };
})();
