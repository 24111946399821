import { useRequestStatus } from "Utilities";
import {
  fileStore,
  getCreateChecklistPdfBody,
  documentClient,
  eolasLogger,
} from "@eolas-medical/core";
import axios from "axios";
import fileDownload from "js-file-download";

export const useDownloadPDF = () => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const downloadBlob = (url: string, fileName: string) => {
    axios
      .get(url, { responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, fileName);
      })
      .catch((error) => {
        eolasLogger.error(error, {
          info: `Download error: ${error.message}`,
        });
      });
  };

  const downloadPDF = async (checklistID: string) => {
    setRequestStatus({ status: "pending", error: "" });

    const file = fileStore.getFile(checklistID);

    try {
      const { s3DownloadUrl } = await documentClient.createChecklistPdf(
        getCreateChecklistPdfBody(file),
      );
      downloadBlob(
        s3DownloadUrl,
        file.name || file.name
          ? `${file.name} ${file.createdAt}`
          : `Completed Checklist ${file.createdAt}`,
      );
    } catch (error) {
      eolasLogger.error(error);
      setRequestStatus({ status: "error", error: "Download error" });
    }
  };

  return {
    error,
    isLoading,
    downloadPDF,
    setRequestStatus,
  };
};
