import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { hasProp, hasStringProp, Maybe } from "@eolas-medical/core";
import { getFileName } from "Utilities/fileHelpers";
import { WizardState } from "../../types";

export type FileDetailsValues = {
  name: string;
  keywords: string;
  expiryDate: Maybe<string>;
  hasExpiryDate: boolean;
};

export const useFileDetails = (state: WizardState) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      hasExpiryDate: Yup.boolean().nullable().notRequired(),
      expiryDate: Yup.mixed().when("hasExpiryDate", {
        is: (value: string) => !!value,
        then: Yup.mixed().test({
          test: (v) => !!v,
          /* FIXME: this is not wrapped in t() due to integration with other
           * components, but when other components are touched this should be changed
           */
          message: "fieldError_expiry_date_required",
        }),
        otherwise: Yup.mixed().nullable().notRequired(),
      }),
      name: Yup.string()
        .required(t("validation_name_required"))
        .test("notJustWhiteSpace", t("respository_name_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
      keywords: Yup.string()
        .required(t("repository_keywords_required"))
        .test("notJustWhiteSpace", t("repository_keywords_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
    });
  }, [t]);

  let existingKeywords: string | undefined = undefined;
  let existingName: string | undefined = undefined;
  let existingExpiryDate: string | undefined = undefined;

  if (!state.isExistingFileWithNewType) {
    if (state.dto.keywords?.length) {
      existingKeywords = state.dto.keywords.join(", ");
    } else if (state.dto.description) {
      // Some legacy items keep keywords on description:
      existingKeywords = state.dto.description;
    }
    existingName = state.dto.name;
    existingExpiryDate = state.dto.expiryDate || undefined;
  } else {
    if (hasProp(state.dto.newProperties, "keywords")) {
      existingKeywords = state.dto.newProperties.keywords.join(", ");
    }
    if (hasStringProp(state.dto.newProperties, "expiryDate")) {
      existingExpiryDate = state.dto.newProperties.expiryDate;
    }
    existingName = state.dto.newProperties.name;
  }

  const { handleSubmit, control } = useForm<FileDetailsValues>({
    defaultValues: {
      name: existingName || (state.blob ? getFileName(state.blob) : ""),
      expiryDate: existingExpiryDate,
      keywords: existingKeywords,
      hasExpiryDate: Boolean(existingExpiryDate),
    },
    resolver: yupResolver(validationSchema),
  });

  return { handleSubmit, control };
};
