import { MonographType, WoundManagementChild } from "@eolas-medical/core";
import { useGetWoundCareSummary } from "./useGetWoundCareSummary";
import { useProcessItems } from "../../hooks/useProcessItems";

const woundManagementChildren: WoundManagementChild[] = [];

export const useProcessWoundCare = (args: { idOrSid: string | null; type: MonographType }) => {
  const { isLoading, data } = useGetWoundCareSummary(args);
  const {
    activeItem: historyItem,
    makeOnClick,
    goBackOverride,
  } = useProcessItems({
    fetchedItems: woundManagementChildren,
  });
  const topLevelItem = data?.woundCare ?? null;

  return {
    isLoading,
    historyItem,
    topLevelItem,
    makeOnClick,
    goBackOverride,
    topLevelTitle: data?.title,
  };
};
