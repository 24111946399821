import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { AddIcon } from "Assets";
import { IconButton, Title, Modal } from "UIKit";

import { TrustedEmail } from "./TrustedEmail";
import { useTrustedEmails } from "./useTrustedEmails";
import { AddTrustedEmailModal } from "./AddTrustedEmailModal";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";

export const TrustedEmails = observer(() => {
  const { t } = useTranslation();

  const { relevantSpace } = useGetRelevantSpaceDetails();

  const trustedEmail = relevantSpace?.trustedEmail;

  const { isModalOpen, onCloseModal, onOpenModal } = useTrustedEmails();

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <Title level={6}>{t("manageUsers_trusted_email_authentication")}</Title>

          <IconButton
            size="sm"
            variant="rounded"
            icon={<AddIcon />}
            onClick={onOpenModal}
            className="bg-blue hover:bg-blue-400 text-white"
          />
        </div>

        {trustedEmail === "adminAuthentication" && (
          <div className="flex items-center justify-center bg-grey-100 rounded-md h-24">
            <Title className="text-grey-500" level={7}>
              {t("manageUsers_trusted_email_not_set")}
            </Title>
          </div>
        )}

        {trustedEmail === "publicAccess" && (
          <div className="flex items-center justify-center bg-grey-100 rounded-md h-20">
            <Title className="text-grey-700" level={7}>
              {t("manageUsers_trusted_email_public_allowed_status")}
            </Title>
          </div>
        )}

        {trustedEmail !== "adminAuthentication" && trustedEmail !== "publicAccess" && (
          <EmailList trustedEmail={trustedEmail ?? ""} />
        )}
      </div>

      <Modal open={isModalOpen} onClose={onCloseModal}>
        <AddTrustedEmailModal onCloseModal={onCloseModal} />
      </Modal>
    </>
  );
});

const EmailList: React.FC<{ trustedEmail: string }> = ({
  trustedEmail,
}: {
  trustedEmail: string;
}) => {
  const emails = trustedEmail.split(";");

  return (
    <div className="flex flex-col rounded-md bg-white divide-y divide-grey-300 border border-grey-300">
      {emails.map((email) => {
        return <TrustedEmail key={email} email={email} />;
      })}
    </div>
  );
};
