import { useState } from "react";
import { fuseSearch } from "./fuseSearch";
import { UseLocalSearchProps } from "./types";
import { useInitSearchBox } from "Hooks/useSearchboxMobx";

export const useLocalMobxSearch = <T extends { id: string }>({
  data,
  keysToSearch,
  customSearchCallback,
}: UseLocalSearchProps<T> & { customSearchCallback?: (text: string) => T[] | Promise<T[]> }) => {
  const [results, setResults] = useState<T[] | null>(null);
  const { searchInstanceId } = useInitSearchBox();

  const handleSearchClick = async (text: string) => {
    if (customSearchCallback) {
      const newResults = await customSearchCallback(text);
      setResults(newResults);
      return;
    }
    const { results } = fuseSearch(text, data, keysToSearch);
    setResults(results);
  };

  const handleClearSearch = () => {
    setResults(null);
  };

  return {
    handleSearchClick,
    searchInstanceId,
    handleClearSearch,
    searchResults: results ?? [],
    isSearchActive: !!results,
  };
};
