import {
  AnalyticsEvents,
  GeneralCategory,
  GuidanceReference,
  NiceSummaryItem,
} from "@eolas-medical/core";
import {
  isGeneralCategory,
  isGeneralCategoryArray,
  isGuidanceReferenceArray,
} from "modules/niceGuidelines/typeguards";
import { useHistory, useLocation } from "react-router-dom";
import { makeNiceGuidelinesRoute } from "../routes/makeNiceGuidelinesRoute";
import { errorStore } from "Stores/ErrorStore";
import { useGetNiceGuidelineData } from "modules/niceGuidelines/data/useGetNiceGuidelineData";
import { BaseAppRoute } from "Utilities/types";
import { constructSummaryArray } from "modules/niceGuidelines/helpers";
import { useCallback, useMemo } from "react";
import { trackEvent } from "API/Analytics";

export type PossibleChild = GeneralCategory | GuidanceReference;

interface State {
  item: GeneralCategory;
}

export type NiceListType = "categories" | "summaryItems";

type BaseReturn = {
  isLoading: boolean;
  handleClickTile: (item: GeneralCategory | NiceSummaryItem) => void;
  title: string;
};

export type CategoryReturn = {
  type: Extract<NiceListType, "categories">;
  itemsToDisplay: GeneralCategory[];
};

export type SummaryReturn = {
  type: Extract<NiceListType, "summaryItems">;
  itemsToDisplay: NiceSummaryItem[];
};

export const useNiceGuidelinesList = (): BaseReturn & (CategoryReturn | SummaryReturn) => {
  const { pathname: currentPath } = useLocation();
  const { state } = useLocation<State | undefined>();
  const items = state?.item.children;
  const title = state?.item.title ?? "";

  const { push } = useHistory();

  const { data, isLoading } = useGetNiceGuidelineData();

  const handleClickTile = useCallback(
    (item: GeneralCategory | NiceSummaryItem) => {
      if (isGeneralCategory(item)) {
        trackEvent(AnalyticsEvents.NICE_CATEGORY_VISITED, {
          id: item.id,
          title: item.title,
          type: "Category",
        });
        push({
          pathname: makeNiceGuidelinesRoute({ currentPath, item }),
          state: { item },
        });
      } else {
        push({ pathname: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${item.id}` });
      }
    },
    [push, currentPath],
  );

  const constructedData: CategoryReturn | SummaryReturn = useMemo(() => {
    if (!items) {
      return { type: "summaryItems", itemsToDisplay: [] };
    }
    if (isGeneralCategoryArray(items)) {
      return { itemsToDisplay: items, type: "categories" };
    }
    if (isGuidanceReferenceArray(items)) {
      const itemsToDisplay = constructSummaryArray({ items, summariesData: data?.summaries }) ?? [];
      return { itemsToDisplay, type: "summaryItems" };
    }
    errorStore.captureError({
      error: new Error("Unrecognised item children type in Nice Guidelines List"),
      source: "user",
      data: { items },
    });
    return { itemsToDisplay: [], type: "summaryItems" };
  }, [items, data]);

  return { handleClickTile, title, isLoading, ...constructedData };
};
