import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { FavouriteFileTypeIcon } from "./FavouriteFileTypeIcon";

export const MyFavouriteTileIcon = ({
  entityOwnerType,
  fileType,
  mainSectionIconUrl,
}: {
  entityOwnerType: EntityOwnerType;
  fileType: string;
  mainSectionIconUrl: string;
}) => {
  const { getIconComponent } = useIconComponent();

  if (entityOwnerType === EntityOwnerType.KNOWLEDGE) {
    return getIconComponent(mainSectionIconUrl ?? "");
  }

  return <FavouriteFileTypeIcon type={fileType} className="w-4 h-4" />;
};
