import { AppLevelSection, fileStore, sectionStore } from "@eolas-medical/core";
import { TeamMember } from "../types";
import { useMemo } from "react";

interface UseTeamMembers {
  teamMembers: TeamMember[];
}

const useTeamMembers = (): UseTeamMembers => {
  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam)!;

  const childrenOrder = sectionStore.getChildrenOrder(id);

  const teamMembers: TeamMember[] = useMemo(() => {
    return fileStore.getFiles(childrenOrder).map((f) => ({
      id: f.id,
      name: f.name || "",
      role: f.description || "",
      bio: f.infoTwo,
      photoUrl: f.key,
      createdAt: f.createdAt,
      updatedAt: f.updatedAt,
      isHighlighted: !!f.highlighted && f.highlighted !== "false",
    }));
  }, [childrenOrder]);

  return { teamMembers };
};

export default useTeamMembers;
