import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EolasFilePicker } from "UIKit";
import { BlobValues } from "./hooks/useSelectFileBlobStep";

export const SelectFile = ({
  control,
  allowedFileExtensionsOverride,
  mediaName,
}: {
  control: Control<BlobValues>;
  allowedFileExtensionsOverride?: string[];
  mediaName: string | null;
}) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: "blob" });

  return (
    <EolasFilePicker
      error={error?.message}
      onChange={onChange}
      value={value?.name || mediaName || undefined}
      fileType="blob"
      formLabel={t("filePicker_file_to_upload_label")}
      uploadLabel={t("filePicker_upload_file_button")}
      changeLabel={t("filePicker_change_file")}
      descriptionText={t("filePicker_helper_text")}
      allowedFileExtensionsOverride={allowedFileExtensionsOverride}
    />
  );
};
