import { appConfig } from "AppTypeConfig";
import { apple, google } from "Assets";
import { InnerPageWrapper, NavButton, PageTitle, Text, Title } from "UIKit";
import { removeLastUrlPath } from "Utilities";
import { useRouteMatch } from "react-router-dom";

interface ComingSoonProps {
  titleText: string;
  subTitleText: string;
  informationText: string;
  disclaimerText?: string;
}

const ComingSoon = ({
  titleText,
  subTitleText,
  informationText,
  disclaimerText,
}: ComingSoonProps) => {
  const { url } = useRouteMatch();
  const backUrl = removeLastUrlPath(url, 1);

  return (
    <InnerPageWrapper>
      <PageTitle title={titleText} />

      <NavButton to={backUrl} />

      <div className="flex flex-col justify-center items-center space-y-5 text-center">
        <Title level={6} className="mt-4">
          {subTitleText}
        </Title>
        <div className="flex flex-col">
          <Text level={1} className="text-grey-700">
            {informationText}
          </Text>
          <Text level={1} className="text-grey-700 ">
            {disclaimerText}
          </Text>
        </div>

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6 relative top-4">
          <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
            <img src={apple} className="h-12 rounded-lg" alt="app-store" />
          </a>

          <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
            <img src={google} className="h-12 rounded-lg" alt="play-store" />
          </a>
        </div>
      </div>
    </InnerPageWrapper>
  );
};

export default ComingSoon;
