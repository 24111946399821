import { ContentItem, isEolasEditorContentItem, isFlashCardContentItem } from "@eolas-medical/core";
import { ConfirmationModal, SuccessModal } from "UIKit";
import { ErrorModal } from "UIKit/Modal/modals/ErrorModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";

type Props = {
  contentItem: ContentItem;
  onClose: () => void;
};

export const PublishContentItemModal = ({ contentItem, onClose }: Props) => {
  const { t } = useTranslation();

  const { useEditContentItemHook, useEditFlashcardItemHook, useEditEolasEditorItemHook } =
    useContentRepositoryContext();
  const { editContentItem, editingContentItem, editContentItemError } = useEditContentItemHook;
  const { editFlashcardItem, editingFlashcardItem, editFlashcardItemError } =
    useEditFlashcardItemHook;
  const { editEolasEditorItem, editingEolasEditorItem, editEolasEditorItemError } =
    useEditEolasEditorItemHook;

  const [isPublishing, setIsPublishing] = useState(false);

  const isLoading = editingContentItem || editingFlashcardItem || editingEolasEditorItem;

  if (isLoading && !isPublishing) setIsPublishing(true);

  const isError = editContentItemError || editFlashcardItemError || editEolasEditorItemError;

  const handlePublishDraft = () => {
    if (isFlashCardContentItem(contentItem)) {
      editFlashcardItem({
        flashcard: contentItem,
        editItem: {
          isDraft: false,
        },
      });
    } else if (isEolasEditorContentItem(contentItem)) {
      editEolasEditorItem({
        id: contentItem.id,
        isDraft: false,
      });
    } else {
      editContentItem({
        contentItem,
        editItem: {
          isDraft: false,
        },
      });
    }
  };

  if (isError) {
    return (
      <ErrorModal
        onClose={() => {
          onClose();
          setIsPublishing(false);
        }}
        text={t("content_item_publish_error")}
      />
    );
  }
  if (isPublishing && !isLoading) {
    return (
      <SuccessModal
        onComplete={() => {
          onClose();
          setIsPublishing(false);
        }}
        text={t("content_item_publish_success")}
      />
    );
  }

  return (
    <div data-testid="delete-content-item-modal">
      <ConfirmationModal
        onDecline={onClose}
        onAccept={handlePublishDraft}
        iconType="GreenTickIcon"
        title={t("content_item_publish_confirmation")}
        description={t("content_item_publish_irreversible_notice")}
        acceptLabel={t("content_item_publish_confirmation_yes")}
        declineLabel={t("content_item_publish_confirmation_no")}
        acceptButtonProps={{ isLoading: isLoading }}
      />
    </div>
  );
};
