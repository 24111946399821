import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useNameAndUrlStep,
  useConfigureFileStep,
  useWizard,
  useUploadProgressAndSuccessStep,
} from "UIKit/Wizard";
import { useMemo } from "react";
import { useEditFileStep } from "UIKit/Wizard/templates/EditFileStep";

import { EolasFile } from "@eolas-medical/core";
import { useEditPatientLeafletActions } from "./hooks/useEditPatientLeafletActions";

interface EditContentItemModalProps {
  contentItem: EolasFile;
  onClose(): void;
}

const EditContentItemModal = ({ contentItem, onClose }: EditContentItemModalProps) => {
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<"edit-item">();

  const {
    wizardState,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
    handleChangeFileItemDetails,
    handleUpdateFileItem,
    handleUpdateLinkItem,
  } = useEditPatientLeafletActions({ contentItem, onClose });

  const editLinkDetailsStep = useNameAndUrlStep<"edit-item">({
    stepName: "edit-item",
    title: t("repository_edit_item_title"),
    onSubmit: handleUpdateLinkItem,
    isLoading: editingContentItem,
    nameLabel: t("repository_file_name_label"),
    namePlaceholder: t("repository_file_name_placeholder"),
    urlLabel: t("filePicker_url_label"),
    keyWordsLabel: t("filePicker_link_keywords_label"),
    keyWordsPlaceholder: t("filePicker_link_keywords_placeholder"),
    urlPlaceholder: t("filePicker_url_placeholder"),
    initialValues: {
      name: contentItem.name,
      url: contentItem.key || "",
      keywords: contentItem.keywords ? contentItem.keywords.toString() : "",
    },
    nextLabel: t("general_done"),
    backLabel: t("general_cancel"),
  });

  const editFileName = `${contentItem.name}.${contentItem?.type}`;

  const editFile = useEditFileStep<"edit-item">({
    stepName: "edit-item",
    filePickerValue: wizardState?.blob?.name ?? editFileName,
    title: t("repository_edit_item_title"),
    onSubmit: handleUpdateFileItem,
    isLoading: editingContentItem,
    fileFormLabel: t("filePicker_file_to_upload_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    fileDescriptionText: t("filePicker_helper_text"),
    backDisabled: true,
  });

  const editFileDetails = useConfigureFileStep<"edit-item-details">({
    stepName: "edit-item-details",
    title: t("repository_edit_item_title"),
    onEnter: submitWizard,
    onSubmit: handleChangeFileItemDetails,
    isLoading: editingContentItem,
    item: {
      name: contentItem.name || undefined,
      expiryDate: contentItem.expiryDate || undefined,
      keywords: contentItem.keywords ? contentItem.keywords.toString() : undefined,
    },
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t("general_done"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
    stepName: "upload-progress",
    fileUploading: editingContentItem,
    uploadProgress: editContentItemProgress,
    fileUploadError: editContentItemError,
    fileUploadedSuccess: editContentItemSuccess,
    onFinishUpload: onClose,
  });

  const steps: Step<"edit-item" | "edit-item-details" | "upload-progress">[] = useMemo(() => {
    if (contentItem.type === "link") {
      return [editLinkDetailsStep];
    }

    return [editFile, editFileDetails, uploadProgressAndSuccessStep];
  }, [editFileDetails, editLinkDetailsStep, uploadProgressAndSuccessStep, contentItem, editFile]);

  return (
    <div data-testid="edit-content-item-modal">
      <Wizard steps={steps} wizardControl={wizardControl} />
    </div>
  );
};

export default EditContentItemModal;
