import { hasStringProp } from "@eolas-medical/core";
import { isDev } from "Utilities/helpers";
import { EolasVersatileListProps, EolasVersatileListContext } from "../EolasVersatileList.types";
import { UserSortOrderParams } from "Hooks/useSortOrder";

export const defaultKeyExtractor = <T>(item: T, index: number): string => {
  if (hasStringProp(item, "id")) {
    return item.id;
  }
  if (hasStringProp(item, "key")) {
    return item.key;
  }

  if (isDev()) {
    console.warn(
      `EolasVersatileList - No key or id found on item ${JSON.stringify(
        item,
      )}. Please provide a keyExtractor function to the list component.`,
    );
  }

  return index.toString();
};

/**
 * This is an intended noop function that will be called if no onMenuAction prop is provided to the EolasVersatileList component.
 */
export const defaultMenuAction = () => {
  if (isDev()) {
    console.warn(
      "EolasVersatileList - No onMenuAction prop provided, You most likely forgot to provide the onMenuAction prop to the EolasVersatileList component.",
    );
  }
};

export const sortDateByToSortDateByOption = <T, C extends EolasVersatileListContext<T>>(
  sortDateBy: EolasVersatileListProps<T, C>["sortDateBy"],
): UserSortOrderParams["sortDateBy"] => {
  switch (sortDateBy) {
    case "createdAt":
      return "createdAt";
    case "updatedAt":
      return "updatedAt";
    case "favouritedOn":
      return "favouritedOn";
  }

  if (isDev() && sortDateBy) {
    console.error(
      `EolasVersatileList - Unfortunately the sort date by option ${sortDateBy} is not supported with the current hook, please provide an alternative or implement the missing sort method.`,
    );
  }
};
