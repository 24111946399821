/* eslint-disable camelcase */
import { Loader } from "UIKit/Loader";
import { RefObject, useEffect, useState } from "react";
import { FormError } from "UIKit";
import { useTranslation } from "react-i18next";
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useHandleImageUpload } from "../hooks/useHandleImageUpload";
import {
  EolasMCEEditor,
  Props as EolasMCEProps,
} from "shared/components/EolasMCEEditor/EolasMCEEditor";
import { ITinyEvents } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { DeeplinkConfig } from "shared/components/EolasMCEEditor/hooks/useDeeplinkingSetup";

interface EolasEditorStepProps {
  editorContent?: string;
  onEditorInit: EolasMCEProps["onEditorInit"];
  isLoading: boolean;
  contentRepositoryId: ContentRepositoryId;
  error?: string;
  onContentChange: (content: string) => void;
  modalBodyRef?: RefObject<HTMLDivElement>;
  editorConfigs?: {
    deeplinkingConfig?: DeeplinkConfig;
  };
}

const getEditorConfig = (
  handleImageUpload: (blobInfo: unknown, progress: (percent: number) => void) => Promise<string>,
  editorHeight: number,
) => ({
  height: editorHeight,
  resize: true,
  menubar: "file edit view insert format tools table help",
  plugins: [
    "typography",
    "preview",
    "powerpaste",
    "casechange",
    "importcss",
    "searchreplace",
    "autolink",
    "autosave",
    "save",
    "directionality",
    "advcode",
    "visualblocks",
    "visualchars",
    "fullscreen",
    "image",
    "link",
    "codesample",
    "table",
    "charmap",
    "pagebreak",
    "nonbreaking",
    "anchor",
    "advlist",
    "lists",
    "wordcount",
    "editimage",
    "help",
    "formatpainter",
    "permanentpen",
    "charmap",
    "quickbars",
    "linkchecker",
    "emoticons",
    "advtable",
    "mergetags",
    "autocorrect",
    "typography",
    "advtemplate",
  ],
  toolbar:
    "undo redo spellcheckdialog  | blocks fontfamily fontsize | bold italic underline forecolor backcolor | align numlist bullist | link image | table | outdent indent | strikethrough forecolor backcolor | code fullscreen preview",
  autosave_ask_before_unload: true,
  powerpaste_allow_local_images: true,
  powerpaste_word_import: "prompt",
  powerpaste_html_import: "prompt",
  paste_data_images: true,
  image_uploadtab: true,
  images_upload_handler: handleImageUpload,
  relative_urls: false,
  remove_script_host: false,
  inline_styles: true,
});

export const EolasEditorStep = ({
  isLoading,
  editorContent,
  contentRepositoryId,
  modalBodyRef,
  error,
  onEditorInit,
  onContentChange,
  editorConfigs,
}: EolasEditorStepProps) => {
  const [editorHeight, setEditorHeight] = useState(500);
  const { t } = useTranslation();
  const handleImageUpload = useHandleImageUpload(contentRepositoryId);
  const editorConfiguration = getEditorConfig(handleImageUpload, editorHeight);

  useEffect(() => {
    // Measure the height of the editor div and update the state
    if (modalBodyRef?.current) {
      const height = modalBodyRef.current.offsetHeight;
      setEditorHeight(height - 300);
    }
  }, [modalBodyRef]);

  // Todo: we should not be using MCE editor as a controlled component due to performance considerations
  // https://www.tiny.cloud/docs/tinymce/latest/react-ref/#using-the-tinymce-react-component-as-a-uncontrolled-component
  const handleContentChange = (...args: Parameters<ITinyEvents["onChange"]>) => {
    const [_, editor] = args;
    const content = editor.getContent();
    onContentChange(content);
  };

  return (
    <div className="flex-grow">
      {isLoading ? <Loader /> : null}
      <EolasMCEEditor
        onEditorInit={onEditorInit}
        onKeyUp={handleContentChange}
        onChange={handleContentChange}
        initialValue={editorContent ?? ""}
        init={editorConfiguration}
        deeplinkConfig={editorConfigs?.deeplinkingConfig}
      />

      {error ? <FormError error={t(error)} /> : null}
    </div>
  );
};
