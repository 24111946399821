import { twMerge } from "tailwind-merge";

import { Strings } from "./BrandItem.strings";
import {
  DeviceBrand,
  DrugInfo,
  extractMedicalDeviceBrandName,
  isMedicalDeviceIndsAndDose,
  isMedicalDeviceInfoItem,
  isMedicalDevicePreparations,
  makeMedicalDeviceRenderArray,
} from "@eolas-medical/core";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { removeTags } from "modules/bnf/helpers";
import { DrugIndicationsAndDoseBaseComponent } from "../../../../components/BnfDrugViewer/DrugIndicationsAndDoseContent/DrugIndicationsAndDoseBaseComponent";
import BnfPreparation from "../../../../components/BnfPreparation/BnfPreparation";
import { Divider, Text } from "UIKit";
import BnfDrugInfo from "../../../../components/BnfDrugInfo/BnfDrugInfo";

export const BrandItem = ({ brand }: { brand: DeviceBrand }) => {
  const name = extractMedicalDeviceBrandName(brand);

  const renderArray = makeMedicalDeviceRenderArray(brand);

  return (
    <Collapsible title={removeTags(name)}>
      <Divider>
        {renderArray.map((item) => {
          if (isMedicalDeviceInfoItem(item)) {
            return <InfoGroup key={item.name} info={item.value} />;
          }
          if (isMedicalDeviceIndsAndDose(item)) {
            return (
              <div key={item.name}>
                <Title text={item.value.potName} />
                <DrugIndicationsAndDoseBaseComponent indsAndDose={item.value} />
              </div>
            );
          }
          if (isMedicalDevicePreparations(item) && item.value.length) {
            return (
              <div key={item.name}>
                <Title text={Strings.deviceTypes} className="pb-6" />
                {brand.preparations.map((prep, subIndex) => {
                  return <BnfPreparation key={prep.name + subIndex} prep={prep} />;
                })}
              </div>
            );
          }
          return null;
        })}
      </Divider>
    </Collapsible>
  );
};

const InfoGroup = ({ info }: { info: DrugInfo }) => {
  return (
    <div>
      <Title text={info.potName} />
      <BnfDrugInfo info={info} showContentForTag={false} />
    </div>
  );
};

const baseClass = "py-3";

const Title = ({ text, className }: { text: string; className?: string }) => {
  return (
    <Text level={2} className={className ? twMerge(baseClass, className) : baseClass}>
      {Strings.name(text)}
    </Text>
  );
};
