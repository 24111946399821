import { useLocation } from "react-router-dom";
import { Variants, motion } from "framer-motion";
import { MainSectionTile } from "UIKit";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { CommunityLevelSection } from "@eolas-medical/core";
import { KnowledgeMainListItem } from "modules/mainSections/data/useKnowledgeSections";
import { isUserSponsoredSlot } from "modules/sponsoredSlots/functions/typeguards";
import { UserSponsoredSlotTile } from "Pages/Knowledge/pages/SponsoredSlots/pages/ManageSlotsPage/components/UserSponsoredSlotTile";

interface KnowledgeSectionListProps {
  knowledgeSectionList: KnowledgeMainListItem[];
}

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const outerMotionClassName = "mt-4 flex flex-col divide-y divide-grey-light";

const KnowledgeSectionList: React.FC<KnowledgeSectionListProps> = ({
  knowledgeSectionList,
}: KnowledgeSectionListProps) => {
  const { getIconComponent } = useIconComponent();

  const { pathname } = useLocation();

  const getSectionRoute = (knowledgeSection: MainSection) => {
    switch (knowledgeSection.iconName) {
      case CommunityLevelSection.clinicalQuestions:
        return `${pathname}/${knowledgeSection.routeId}`;
      case CommunityLevelSection.sponsoredSlots:
        return `${pathname}/${knowledgeSection.iconName}/${knowledgeSection.id}`;
      default:
        return `${pathname}/${knowledgeSection.iconName}`;
    }
  };

  return (
    <>
      {knowledgeSectionList.map((knowledgeSectionListItem: KnowledgeMainListItem) => {
        let innerContent: JSX.Element;
        if (isUserSponsoredSlot(knowledgeSectionListItem)) {
          innerContent = <UserSponsoredSlotTile slot={knowledgeSectionListItem} />;
        } else {
          const { id, iconUrl, name, description, disabled } = knowledgeSectionListItem;
          const link = getSectionRoute(knowledgeSectionListItem);
          innerContent = (
            <MainSectionTile
              icon={iconUrl && getIconComponent(iconUrl)}
              title={name || ""}
              description={description || ""}
              disabled={disabled}
              to={link}
              data-testid={`knowledge-section-${id}`}
            />
          );
        }
        return (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className={outerMotionClassName}
            key={knowledgeSectionListItem.id}
          >
            {innerContent}
          </motion.div>
        );
      })}
    </>
  );
};

export default KnowledgeSectionList;
