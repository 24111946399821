import { useState } from "react";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import OneSignal from "react-onesignal";
import { useHistory } from "react-router-dom";

import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { checkInvite } from "../functions/createSpaceUser";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import { BaseAppRoute } from "Utilities/types";
import { timeEvent, trackEvent } from "API/Analytics";
import { ModalState } from "../modals/AutoAccessBaseModal";
import { useRunOnMountUnmount } from "Hooks";

export type InviteData = { userEmail?: string | null; type: "sign-in" | "sign-up" };

export const useCheckInvitation = () => {
  const { token } = useParamsDecoded<{ token: string }>();
  const { isLoggedIn: isUserLoggedIn, isInOrganisation } = userStore.userSession;
  const { userID: currentUserId } = userStore;
  const { push } = useHistory();
  const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: false });
  const [state, setState] = useState<{
    handleCloseModal: () => void;
    outcome: ModalState;
  } | null>(null);

  useRunOnMountUnmount({
    onMount: async () => {
      if (token) {
        userStore.setInviteId(token);
        timeEvent(AnalyticsEvents.ACCEPT_INVITE_DURATION);
        trackEvent(AnalyticsEvents.ACCEPT_INVITE_STARTED);
      }
      let isLoggedIn = isUserLoggedIn;
      OneSignal.sendTag("startedQuickDepartmentAccessDate", new Date().toISOString());
      if (isLoggedIn && isInOrganisation) {
        signoutSpace();
      }
      if (!isLoggedIn && currentUserId) {
        userStore.setLoggedStatus(true);
        isLoggedIn = true;
      }
      const { outcome: apiOutcome, email, userID } = await checkInvite(token);
      let outcome: ModalState = apiOutcome;
      let inviteData: InviteData | undefined = undefined;
      if (outcome === "nil") {
        inviteData = {
          userEmail: email,
          type: userID ? "sign-in" : "sign-up",
        };
        if (isLoggedIn) {
          push(`/${BaseAppRoute.selectSpace}`);
        } else {
          outcome = "pleaseLogin";
          const handleCloseModal = () => {
            push("/", { inviteData });
          };
          setState({
            outcome,
            handleCloseModal,
          });
        }
      } else {
        userStore.setAccessLinkId(null);
        const handleCloseModal = () => {
          if (outcome === "inviteCompleted") {
            userStore.setAccessLinkId(null);
            inviteData = {
              userEmail: email,
              type: "sign-in",
            };
          }
          isLoggedIn ? push(`/${BaseAppRoute.selectSpace}`) : push("/", { inviteData });
        };
        setState({
          outcome,
          handleCloseModal,
        });
      }
    },
  });

  return { isLoggedIn: isUserLoggedIn, state };
};
