import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  apolloClient,
  eolasLogger,
  getUserProfile,
  isAxiosError,
  profilesClient,
  userStore,
} from "@eolas-medical/core";

import { errorStore } from "Stores/ErrorStore";

import { Title, Button, ModalBody, FormError, ModalHeader, InnerModalWrapper } from "UIKit";

import {
  ChangePrimaryEmailDocument,
  RemoveVerifiedEmailDocument,
} from "Hooks/graphql/user-mutations.generated";
import { useRequestStatus } from "Utilities";
import { AddAnotherEmailInput } from "./AddAnotherEmailInput";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export type EditEmailsModalStates = "ADD_ANOTHER_EMAIL" | "VERIFY_CODE" | "SUCCESS";

interface AddAnotherEmailStateProps {
  sendVerificationCode: (email: string) => void;
  sendingCode: boolean;
  initialShowAddAnotherEmailInput?: boolean;
}

export const AddAnotherEmailState: React.FC<AddAnotherEmailStateProps> = ({
  sendingCode,
  sendVerificationCode,
  initialShowAddAnotherEmailInput,
}: AddAnotherEmailStateProps) => {
  const { t } = useTranslation();
  const { verifiedEmails, email } = userStore?.userData || {};

  const [performingAction, setPreformingAction] = useState<string>("");

  const [showAddAnotherEmailInput, setShowAddAnotherEmailInput] = useState<boolean>(
    () => initialShowAddAnotherEmailInput ?? verifiedEmails?.length === 0,
  );

  const { error, setRequestStatus } = useRequestStatus();

  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const onMakePrimary = async (newPrimaryEmail: string) => {
    setPreformingAction(`makePrimary#${newPrimaryEmail}`);
    setRequestStatus({ status: "pending", error: "" });

    if (useAppServicesEndpoints) {
      const { errors } = await apolloClient.mutate({
        mutation: ChangePrimaryEmailDocument,
        variables: {
          newPrimaryEmail,
        },
      });

      if (errors) {
        const message = errorStore.captureError({ error: errors[0], source: "user" });
        setRequestStatus({ status: "error", error: message });
      } else {
        userStore.updateData({ user: { email: newPrimaryEmail } });
        setRequestStatus({ status: "success", error: "" });
      }
    } else {
      try {
        await profilesClient.changePrimaryEmail(userStore.userID, { newPrimaryEmail });
        userStore.updateData({ user: { email: newPrimaryEmail } });
        setRequestStatus({ status: "success", error: "" });
      } catch (error) {
        if (!isAxiosError(error)) throw error;
        const { relevantMessage } = eolasLogger.error(error);
        setRequestStatus({ status: "error", error: relevantMessage });
      }
    }

    setPreformingAction("");
  };

  const onRemoveEmail = async (emailToRemove: string) => {
    setPreformingAction(`removeEmail#${emailToRemove}`);
    setRequestStatus({ status: "pending", error: "" });

    if (useAppServicesEndpoints) {
      const { errors } = await apolloClient.mutate({
        mutation: RemoveVerifiedEmailDocument,
        variables: {
          email: emailToRemove,
        },
      });
      if (errors) {
        const message = errorStore.captureError({ error: errors[0], source: "user" });
        setRequestStatus({ status: "error", error: message });
      } else {
        const { user } = await getUserProfile();
        userStore.updateData({ user });
      }
    } else {
      try {
        await profilesClient.removeVerifiedEmail(userStore.userID, {
          emailToDelete: emailToRemove,
        });
        const { user } = await getUserProfile();
        userStore.updateData({ user });
      } catch (error) {
        if (!isAxiosError(error)) throw error;
        const { relevantMessage } = eolasLogger.error(error);
        setRequestStatus({ status: "error", error: relevantMessage });
      }
    }

    setPreformingAction("");
  };

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("my-medical_personal_details_emails_label")}</Title>
        {error}
      </ModalHeader>

      <ModalBody>
        <div className="pb-4 text-lg">
          {email}
          <div className="mt-3 text-base text-grey-dark">
            {t("my-medical_personal_details_emails_primary")}
          </div>
        </div>
        <div className="border-b border-grey"></div>

        {verifiedEmails &&
          verifiedEmails.map(
            (verifiedEmail) =>
              verifiedEmail?.email !== email && (
                <div key={verifiedEmail?.email}>
                  <div className="pt-4 text-lg">{verifiedEmail?.email}</div>
                  <div className="flex my-4 gap-4">
                    <Button
                      color="grey"
                      weight="regular"
                      className="flex-1"
                      disabled={!!performingAction}
                      onClick={() =>
                        verifiedEmail?.email ? onMakePrimary(verifiedEmail?.email) : {}
                      }
                      isLoading={performingAction === `makePrimary#${verifiedEmail?.email}`}
                    >
                      {t("my-medical_personal_details_emails_make_primary")}
                    </Button>

                    <Button
                      color="grey"
                      weight="regular"
                      className="flex-1"
                      disabled={!!performingAction}
                      onClick={() =>
                        verifiedEmail?.email ? onRemoveEmail(verifiedEmail?.email) : {}
                      }
                      isLoading={performingAction === `removeEmail#${verifiedEmail?.email}`}
                    >
                      {t("my-medical_personal_details_emails_remove_email")}
                    </Button>
                  </div>
                  <div className="border-b border-grey"></div>
                </div>
              ),
          )}

        {error && <FormError error={typeof error === "string" ? error : ""} />}

        {!showAddAnotherEmailInput && (
          <div className="mt-8">
            <span
              className="text-blue text-lg cursor-pointer"
              onClick={() => setShowAddAnotherEmailInput(true)}
            >
              {t("my-medical_personal_details_emails_add_another_email")}
            </span>
          </div>
        )}

        {showAddAnotherEmailInput && (
          <AddAnotherEmailInput
            sendingCode={sendingCode}
            sendVerificationCode={sendVerificationCode}
          />
        )}
      </ModalBody>
    </InnerModalWrapper>
  );
};
