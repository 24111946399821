import { sectionStore } from "@eolas-medical/core";
import { useHistory } from "react-router-dom";
import { removeBracketsAndPercent } from "Utilities/helpers";
import { BaseAppRoute } from "Utilities/types";

export const useNavigateToSpace = () => {
  const { push } = useHistory();

  const navigateToSpace = (spaceName: string, forwardUrl?: string) => {
    if (forwardUrl) {
      push(forwardUrl);
    } else {
      const formattedName = removeBracketsAndPercent(spaceName);
      push(
        `/${
          sectionStore.activeDepartmentTab
            ? `${BaseAppRoute.spaces}/${encodeURIComponent(formattedName || "")}`
            : `${BaseAppRoute.organisation}/${encodeURIComponent(formattedName || "")}`
        }`,
        {
          shouldAllowUserAccess: true,
        },
      );
    }
  };

  return { navigateToSpace };
};
