import { DrugInfo } from "@eolas-medical/core";
import InfoContent from "./InfoContent";
import { Divider } from "UIKit";

const DRUG_CLASS_BASE_KEY = "eolas_drug_class";
const PREP_BASE_KEY = "eolas_prep_content";

interface BnfDrugInfo {
  info: DrugInfo;
  showContentForTag?: boolean;
}

const BnfDrugInfo = ({ info, showContentForTag = true }: BnfDrugInfo) => {
  return (
    <Divider className="flex flex-col">
      {info.drugClassContent.map((element, index) => {
        return (
          <InfoContent
            key={DRUG_CLASS_BASE_KEY + info.potName + index}
            element={element}
            showContentForTag={showContentForTag}
          />
        );
      })}
      {info.drugContent ? (
        <InfoContent element={info.drugContent} showContentForTag={showContentForTag} />
      ) : null}
      {info.prepContent.map((element, index) => {
        return (
          <InfoContent
            key={PREP_BASE_KEY + info.potName + index}
            element={element}
            showContentForTag={showContentForTag}
          />
        );
      })}
    </Divider>
  );
};

export default BnfDrugInfo;
