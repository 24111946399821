import { useState, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, InnerPageWrapper, PageTitle, NavButton, Modal } from "UIKit";

import { SimpleSectionFileModal } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { AddItemWithAttachmentModal } from "Pages/Spaces/components/AddEolasFile";
import { useShareFile } from "shared/hooks/useShareFile";
import {
  isFavouriteFileSupportedForDynamicPage,
  isShareFileSupportedForDynamicPage,
} from "../../DynamicPages/functions/helpers";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

export interface SimpleSectionProps extends RouteProps {
  mainSectionId: AppLevelSection;
  match?: {
    url: string;
  };
}

export const SimpleSection: React.FC<SimpleSectionProps> = observer(
  ({ mainSectionId: mainSectionType, match }) => {
    const { t } = useTranslation();

    useMainSectionVisit({ mainSectionId: mainSectionType });

    const { isAdmin } = useSpacesContext();
    const { spaceSection } = useSpaceSection(mainSectionType);
    const { isShareFileEnabled, isShareModalOpen, closeShareModal, handleOnShareFile, shareModal } =
      useShareFile();
    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();
    const canShare = isShareFileSupportedForDynamicPage({ mainSectionType, isShareFileEnabled });
    const canFavourite = isFavouriteFileSupportedForDynamicPage({ mainSectionType });
    const [modal, setModal] = useState<ReactNode | null>(null);
    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const onCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      setModal(null);
    };

    const onAddFile = () => {
      setModal(
        <AddItemWithAttachmentModal
          fileRequired={false}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionType}
        />,
      );
    };

    const onDeleteFile = (file: EolasFile) => {
      setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
    };

    const onEditFile = (file: EolasFile) => {
      setModal(
        <AddItemWithAttachmentModal
          file={file}
          fileRequired={false}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionType}
        />,
      );
    };

    const onViewFile = (file: EolasFile) => {
      setModal(<SimpleSectionFileModal file={file} />);
    };

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection?.name ?? t(mainSectionType)} />
          <NavButton to={match?.url.replace(`/${mainSectionType}`, "")} />

          {isAdmin && <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>}

          <EolasFileList
            isAdmin={isAdmin}
            onEdit={onEditFile}
            onAddItem={onAddFile}
            onDelete={onDeleteFile}
            onSelectFile={onViewFile}
            mainSectionId={mainSectionType}
            onShareFile={canShare ? handleOnShareFile : undefined}
            showRemoveFavouriteModal={canFavourite ? showRemoveFavouriteModal : undefined}
          />
        </InnerPageWrapper>

        <Modal open={isModalOpen} onClose={onCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
      </>
    );
  },
);
