import { createContext } from "react";
import {
  ContentRepositoryContextValue,
  UseAddContentSectionHook,
  UseEditContentSectionHook,
  UseDeleteContentSectionHook,
  UseAddContentItemHook,
  UseEditContentItemHook,
  UseDeleteContentItemHook,
  UseContentItemsHook,
  UseContentSectionsHook,
  UseContentRepositoryHook,
  UseContentSectionHook,
  UseReorderContentHook,
  UseAddFlashcardItemHook,
  UseEditFlashcardItemHook,
  UseSearchContentSectionsHook,
  UserSearchContentItemsHook,
  UseAddEolasEditorItemHook,
  UseEditEolasEditorItemHook,
  UseBulkDeleteContentItemHook,
  CommonContentRepositoryContextValueProperties,
  SpaceOrgContentRepositoryContextValue,
  KnowledgeContentRepositoryContextValue,
} from "./types";
import { EolasSupportedMiscellaneousType, getObjectEntries } from "@eolas-medical/core";
import { BaseAppRoute } from "Utilities/types";

export const defaultUseContentRepositoryHook: UseContentRepositoryHook = {
  contentRepository: {
    id: "",
    name: "",
    description: "",
    childrenType: "",
  },
  contentRepositoryLoading: false,
};

export const defaultUseContentItemsHook: UseContentItemsHook = {
  contentItems: [],
  contentItemsLoading: false,
};

export const defaultUseContentSectionsHook: UseContentSectionsHook = {
  contentSections: [],
  contentSectionsLoading: false,
};

export const defaultUseContentSectionHook: UseContentSectionHook = {
  contentSection: {
    id: "",
    name: "",
    routeId: "",
    iconUrl: "",
    iconName: "",
  },
  contentSectionLoading: false,
};

export const defaultUseAddContentSectionHook: UseAddContentSectionHook = {
  addContentSection: () => {
    return;
  },
  addingContentSection: false,
  addContentSectionSuccess: false,
  addContentSectionError: "",
};

export const defaultUseEditContentSectionHook: UseEditContentSectionHook = {
  editContentSection: () => {
    return;
  },
  editingContentSection: false,
  editContentSectionSuccess: false,
  editContentSectionError: "",
};

export const defaultUseDeleteContentSectionHook: UseDeleteContentSectionHook = {
  deleteContentSection: () => {
    return;
  },
  deletingContentSection: false,
  deleteContentSectionSuccess: false,
  deleteContentSectionError: "",
};

export const defaultUseAddContentItemHook: UseAddContentItemHook = {
  addContentItem: () => {
    return;
  },
  addingContentItem: false,
  addContentItemSuccess: false,
  addContentItemError: "",
  addContentItemProgress: 0,
};

export const defaultUseAddFlashcardItemHook: UseAddFlashcardItemHook = {
  addFlashcardItem: () => {
    return;
  },
  addingFlashcardItem: false,
  addFlashcardItemSuccess: false,
  addFlashcardItemError: "",
  addFlashcardItemProgress: 0,
};

export const defaultUseAddEolasEditorItemHook: UseAddEolasEditorItemHook = {
  addEolasEditorItem: async () => {
    return;
  },
  addingEolasEditorItem: false,
  addEolasEditorItemSuccess: false,
  addEolasEditorItemError: "",
  addEolasEditorItemProgress: 0,
};

export const defaultUseEditContentItemHook: UseEditContentItemHook = {
  editContentItem: () => {
    return;
  },
  editingContentItem: false,
  editContentItemSuccess: false,
  editContentItemError: "",
  editContentItemProgress: 0,
};

export const defaultUseEditFlashcardItemHook: UseEditFlashcardItemHook = {
  editFlashcardItem: () => {
    return;
  },
  editingFlashcardItem: false,
  editFlashcardItemSuccess: false,
  editFlashcardItemError: "",
  editFlashcardItemProgress: 0,
};

export const defaultUseEditEolasEditorItemHook: UseEditEolasEditorItemHook = {
  editEolasEditorItem: () => {
    return;
  },
  editingEolasEditorItem: false,
  editEolasEditorItemSuccess: false,
  editEolasEditorItemError: "",
  editEolasEditorItemProgress: 0,
};

export const defaultUseDeleteContentItemHook: UseDeleteContentItemHook = {
  deleteContentItem: () => {
    return;
  },
  deletingContentItem: false,
  deleteContentItemSuccess: false,
  deleteContentItemError: "",
};

export const defaultUseBulkDeleteContentItemsHook: UseBulkDeleteContentItemHook = {
  bulkDeleteItems: async () => {
    return;
  },
  isLoading: false,
  isError: false,
  error: "",
};

export const defaultUseReorderContentHook: UseReorderContentHook = {
  reorderContent: () => {
    return;
  },
};

export const defaultUseSearchContentSectionsHook: UseSearchContentSectionsHook = {
  searchedSections: [],
  searchingSections: false,
  searchInput: "",
  searchValue: "",
  onClearSearch: () => {
    return;
  },
  onSearch: () => {
    return;
  },
  onSearchInputChange: () => {
    return;
  },
};

export const defaultUseSearchContentItemsHook: UserSearchContentItemsHook = {
  searchedItems: [],
  searchingItems: false,
  searchInput: "",
  searchValue: "",
  onClearSearch: () => {
    return;
  },
  onSearch: () => {
    return;
  },
  onSearchInputChange: () => {
    return;
  },
};

const addVersionNo = (versionNo?: number) => {
  return versionNo ? `/version/${versionNo}` : "";
};

const addSearchParams = <T extends { [key: string]: string | undefined }>(params: T) => {
  const searchParams = new URLSearchParams();
  getObjectEntries(params).forEach(([key, value]) => {
    if (typeof key === "string" && value) {
      searchParams.append(key, value);
    }
  });
  if (searchParams.size) {
    return `?${searchParams.toString()}`;
  }
  return "";
};

export const defaultGetBlobViewerUrl: ContentRepositoryContextValue["getBlobViewerUrl"] = ({
  blob,
  versionNo,
  ...rest
}) => {
  const type: string = blob.media.type;
  if (type === EolasSupportedMiscellaneousType.DSM) {
    return (
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        blob.mainSectionId,
      )}/dsm-viewer/${encodeURIComponent(blob.id)}${addVersionNo(versionNo)}` +
      addSearchParams(rest)
    );
  }
  return (
    `/${BaseAppRoute.viewer}/${encodeURIComponent(blob.id)}${addVersionNo(versionNo)}` +
    addSearchParams(rest)
  );
};

export const defaultGetFlashcardViewerUrl: ContentRepositoryContextValue["getFlashcardViewerUrl"] =
  ({ flashcard, versionNo, ...rest }) => {
    return (
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        flashcard.mainSectionId,
      )}/flash-cards/${encodeURIComponent(flashcard.id)}${addVersionNo(versionNo)}` +
      addSearchParams(rest)
    );
  };

export const defaultGetEolasEditorViewerUrl: ContentRepositoryContextValue["getEolasEditorViewerUrl"] =
  ({ eolasEditor, versionNo, ...rest }) => {
    return (
      `/${BaseAppRoute.viewer}/${encodeURIComponent(
        eolasEditor.mainSectionId,
      )}/eolas-editor/${encodeURIComponent(eolasEditor.id)}${addVersionNo(versionNo)}` +
      addSearchParams(rest)
    );
  };

const defaultCommonContentRepositoryValues: CommonContentRepositoryContextValueProperties = {
  useContentRepositoryHook: defaultUseContentRepositoryHook,
  useContentSectionsHook: defaultUseContentSectionsHook,
  useContentSectionHook: defaultUseContentSectionHook,
  useContentItemsHook: defaultUseContentItemsHook,
  useAddContentSectionHook: defaultUseAddContentSectionHook,
  useEditContentSectionHook: defaultUseEditContentSectionHook,
  useDeleteContentSectionHook: defaultUseDeleteContentSectionHook,
  useAddContentItemHook: defaultUseAddContentItemHook,
  useAddFlashcardItemHook: defaultUseAddFlashcardItemHook,
  useAddEolasEditorItemHook: defaultUseAddEolasEditorItemHook,
  useEditContentItemHook: defaultUseEditContentItemHook,
  useEditFlashcardItemHook: defaultUseEditFlashcardItemHook,
  useEditEolasEditorItemHook: defaultUseEditEolasEditorItemHook,
  useDeleteContentItemHook: defaultUseDeleteContentItemHook,
  useReorderContentHook: defaultUseReorderContentHook,
  useSearchContentSectionsHook: defaultUseSearchContentSectionsHook,
  useSearchContentItemsHook: defaultUseSearchContentItemsHook,
  useBulkDeleteItemsHook: defaultUseBulkDeleteContentItemsHook,
  getBlobViewerUrl: defaultGetBlobViewerUrl,
  getFlashcardViewerUrl: defaultGetFlashcardViewerUrl,
  getEolasEditorViewerUrl: defaultGetEolasEditorViewerUrl,
  contentSectionConfig: {
    shouldAllowSharing: false,
    canDownloadSectionMap: false,
  },
  contentItemConfig: {
    shouldAllowSharing: false,
    shouldAllowFavourites: false,
    shouldHideExpiryDate: false,
    shouldShowBreadcrumbs: false,
  },
};

export const defaultSpaceOrgContentRepositoryValues: SpaceOrgContentRepositoryContextValue = {
  contextDomain: "spaceOrg",
  ...defaultCommonContentRepositoryValues,
};

export const defaultKnowledgeContentRepositoryValues: KnowledgeContentRepositoryContextValue = {
  contextDomain: "knowledge",
  knowledgeContentDomain: "smpcs",
  ...defaultCommonContentRepositoryValues,
};

const ContentRepositoryContext = createContext<ContentRepositoryContextValue>(
  defaultSpaceOrgContentRepositoryValues,
);

export default ContentRepositoryContext;
