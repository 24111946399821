import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { SwitchToAnotherSpace } from "./SwitchToAnotherSpace";
import { NoAccessToSpace } from "./NoAccessToSpace";
import { CheckAccess } from "./CheckAccess";

export const CheckSpaceAccessRoutes: React.FC<RouteComponentProps> = ({
  match: { path },
}: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={`${path}/no-access`} component={NoAccessToSpace} />
      <Route exact path={`${path}/:spaceId/:sectionType?/:sectionId?`} component={CheckAccess} />
      <Route
        exact
        path={`${path}/switch-space/:spaceId/:spaceName/:sectionType?/:sectionId?`}
        component={SwitchToAnotherSpace}
      />
    </Switch>
  );
};
