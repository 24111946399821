import { sectionStore } from "@eolas-medical/core";
import { ContentRepository, ContentRepositoryId } from "../types";

interface UseContentRepository {
  contentRepository: ContentRepository;
  contentRepositoryLoading: boolean;
}

const useContentRepository = (contentRepositoryId: ContentRepositoryId): UseContentRepository => {
  const childReference = sectionStore.getChildReferenceOfSection(contentRepositoryId);
  const section = sectionStore.getSection(contentRepositoryId);

  return {
    contentRepository: {
      id: section.id,
      name: childReference?.name || "",
      description: childReference?.description || "",
      childrenType: section.childrenType,
    },
    contentRepositoryLoading: false,
  };
};

export default useContentRepository;
