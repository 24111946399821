import { useSpacesContext } from "modules/spaces";
import { Space, userStore } from "@eolas-medical/core";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpace from "modules/spaces/data/useSpace";

export const useGetRelevantSpaceDetails = (): {
  spaceId: string | null;
  isPublic: boolean;
  relevantSpace: Space | null;
} => {
  const { selectedSpace } = useSpacesContext();
  const { activeTab } = useEolasNavigation();
  const mainSpaceId = selectedSpace?.id ?? null;
  const orgSpaceId = userStore.orgUserData?.spaceId ?? null;
  const spaceId = activeTab === "organisation" ? orgSpaceId : mainSpaceId;
  const { space: relevantSpace } = useSpace(spaceId);

  return {
    spaceId,
    isPublic: relevantSpace?.accessType === "public",
    relevantSpace: relevantSpace ?? null,
  };
};
