import { useQuery } from "@tanstack/react-query";
import { MedicationSummaryItem, MonographType, Summaries, bnfClient } from "@eolas-medical/core";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";
import bnfKeys from "./bnf.queryKeys";

interface UseGetInfoContentList {
  summaryList: MedicationSummaryItem[];
  isLoading: boolean;
}

export type InfoType = "guidanceItems" | "treatmentSummaryItems";

const useGetInfoContentList = ({
  type,
  infoType,
}: {
  type: MonographType;
  infoType: InfoType;
}): UseGetInfoContentList => {
  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.all,
    queryFn: () => bnfClient.getSummaryData(),
    select: (data: Summaries) => {
      const items = [...data[type][infoType]].sort((a, b) => a.name.localeCompare(b.name));
      return items;
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return {
    summaryList: data || [],
    isLoading: isInitialLoading,
  };
};

export default useGetInfoContentList;
