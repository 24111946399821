import React, { memo, useState } from "react";
import ReactDOM from "react-dom";
import { Button, IconButton, InnerModalWrapper, ModalBody, Title, Text } from "UIKit";
import { useTranslation } from "react-i18next";
import useExportToImage from "Hooks/useExportToImage";
import QRCode from "qrcode.react";
import { XIcon } from "Assets";
import { ModalProps, getFileName } from "shared/hooks/useShareFile";
import { generateLinkToItem } from "shared/functions/generateLinkToItem";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { isEnrichedFavourite } from "modules/myFavourites/typeguards";
import { AnalyticsEvents, DeeplinkGeneratedPayload } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";

export type Props = ModalProps;
type ModalContent = "shareEntireDocument" | "shareSpecificPage";
type ErrorString =
  | "share_item_page_number_too_high"
  | "share_item_page_number_too_low"
  | "share_item_page_number_nan"
  | "share_item_link_failure";

const ShareFileModal = ({
  onClose,
  exportedQRCodeName,
  link: linkProp,
  pageNumber,
  totalPages = 100,
  item,
}: Props) => {
  const { t } = useTranslation();
  const { activeTab } = useEolasNavigation();
  const [didClickCopy, setDidClickCopy] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>("shareEntireDocument");
  const [page, setPage] = useState<number>(pageNumber ?? 1);
  const [errorString, setErrorString] = useState<ErrorString | null>(null);
  const [link, setLink] = useState<string>(linkProp);

  const { parentRef, exportToImage } = useExportToImage();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setDidClickCopy(true);
  };

  const handleExportToImage = () => {
    exportToImage(exportedQRCodeName);
  };

  const handlePageNumberChange = (e: React.ChangeEvent<HTMLInputElement> | number) => {
    const value = typeof e === "number" ? e : parseInt(e.target.value, 10);

    if (didClickCopy) {
      setDidClickCopy(false);
    }

    if (errorString) {
      setErrorString(null);
    }

    if (isNaN(value)) {
      setErrorString("share_item_page_number_nan");
      setPage(value);
      return;
    }

    if (value > totalPages) {
      setErrorString("share_item_page_number_too_low");
      setPage(value);
      return;
    }

    if (value < 1) {
      setErrorString("share_item_page_number_too_high");
      setPage(value);
      return;
    }

    if (!item) {
      setErrorString("share_item_link_failure");
      setPage(value);
      return;
    }

    if (!isEnrichedFavourite(item)) {
      const result = generateLinkToItem(item, activeTab, undefined, value);
      if (!result) {
        setErrorString("share_item_link_failure");
        setPage(value);
        return;
      }

      trackEvent<DeeplinkGeneratedPayload>(AnalyticsEvents.DEEPLINK_GENERATED, {
        entityId: item.id,
        type: "file",
        pageNo: value.toString(),
        url: result.link,
        title: getFileName(item),
        origin: result.origin === "null" ? "genericContentRepository" : result.origin,
      });

      setLink(result.link);
    }
    setPage(value);
  };

  const handleSwitchModalContent = () => {
    if (errorString) {
      setErrorString(null);
    }
    if (didClickCopy) {
      setDidClickCopy(false);
    }
    if (modalContent === "shareEntireDocument") {
      setModalContent("shareSpecificPage");
      // Trigger a new link generation when switching to specific page
      handlePageNumberChange(page);
    } else {
      // Reset the link to the original link
      setLink(linkProp);
      setModalContent("shareEntireDocument");
    }
  };

  const copyLinkButtonText = didClickCopy ? t("copy_to_clipboard_tooltip") : t("copy_to_clipboard");

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <InnerModalWrapper>
        <div className="relative max-h-80vh overflow-y-auto">
          <ModalBody>
            <Title level={5} className="text-center">
              {t("share_item_link_modal_title")}
            </Title>
            <Text level={1} className="text-center">
              {t("share_item_link_modal_description")}
            </Text>

            {totalPages > 1 ? (
              <Button variant="link" onClick={handleSwitchModalContent}>
                {modalContent === "shareSpecificPage"
                  ? t("share_item_entire_document")
                  : t("share_item_specific_page")}
              </Button>
            ) : null}

            {modalContent === "shareSpecificPage" ? (
              <div className="flex items-center justify-center space-x-2 flex-col">
                <div className="flex items-center justify-center">
                  <Text level={1}>{t("share_item_page")} </Text>
                  <input
                    type="number"
                    max={totalPages}
                    min="1"
                    value={page}
                    onChange={handlePageNumberChange}
                    className="w-16 p-2 mx-2 border border-grey-200 rounded"
                  />
                  <Text level={1}>{t("share_item_page_of", { totalPages })} </Text>
                </div>
                {errorString ? (
                  <Text level={1} className="text-red-500">
                    {t(errorString)}
                  </Text>
                ) : null}
              </div>
            ) : null}

            <div className="my-6 self-center" ref={parentRef}>
              <QRCode size={220} value={link} fgColor={errorString ? "#c0bfbf" : undefined} />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <Button
                data-testid="copy-clipboard-button"
                size="sm"
                iconLeft={didClickCopy ? undefined : "CopyIcon"}
                color="grey"
                variant="outline"
                className="order-1 sm:order-0"
                onClick={handleCopyLink}
                disabled={!!errorString}
              >
                <Text className="ml-2 text-sm sm:text-base" level={1}>
                  {copyLinkButtonText}
                </Text>
              </Button>

              <Button
                data-testid="export-png-button"
                size="sm"
                iconLeft="ExportIcon"
                color="grey"
                variant="outline"
                className="order-0 sm:order-1"
                onClick={handleExportToImage}
                disabled={!!errorString}
              >
                <Text className="ml-2 text-sm sm:text-base" level={1}>
                  {t("export_as_png")}
                </Text>
              </Button>
            </div>
          </ModalBody>
          <IconButton
            size="sm"
            type="button"
            icon={<XIcon />}
            variant="rounded"
            onClick={onClose}
            data-testid="modal-close-button"
            className="absolute top-4 right-4 z-10"
          />
        </div>
      </InnerModalWrapper>
    </div>,
    document.body,
  );
};

export default memo(ShareFileModal);
