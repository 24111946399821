import { AnalyticsEvents, CommunityLevelSection, DeeplinkTappedPayload } from "@eolas-medical/core";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { useMediaQuery } from "Hooks";
import { expectNever } from "Utilities/helpers";
import { LDFlagNames } from "Utilities/types";
import {
  getDeeplinkEntityId,
  getDeeplinkMainSectionIdentity,
  redirectToLinkedDocument,
  redirectToWikiEmComingSoon,
} from "deeplinking/functions/helpers";
import { isSupportedKnowledgeContentDeepLinkType } from "deeplinking/functions/typeguards";
import { DeepLinkParams, DeeplinkError } from "deeplinking/types/general.types";
import React, { useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useWikEmDeeplinks } from "./knowledge/useWikEmDeeplinks";
import { useMedicationsDeeplinks } from "./knowledge/useMedicationsDeeplink";
import { useNiceGuidelinesDeeplink } from "./knowledge/useNiceGuidelinesDeeplink";
import { useClinicalResourceDeeplink } from "./knowledge/useClinicalResourceDeeplink";
import { trackEvent } from "API/Analytics";

export const useHandleKnowledgeDeeplink = () => {
  const didFireAnalyticsEvent = React.useRef(false);
  const { search } = useLocation();
  const media = useMediaQuery();
  const { type } = useParams<DeepLinkParams>();
  const history = useHistory();
  const { flags } = useLaunchDarkly();
  const { toggleDrawerHidden } = useEolasNavigation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [deeplinkError, setDeeplinkError] = React.useState<DeeplinkError | null>(null);
  const [shouldDisplayDirectToMobile, setShouldDisplayDirectToMobile] =
    React.useState<boolean>(false);
  const isMobile = media === "xs";
  const shouldDirectToMobileFlag =
    flags[LDFlagNames.SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE] ?? false;

  const { handleWikemDeeplink } = useWikEmDeeplinks();
  const { handleMedicationDeeplink } = useMedicationsDeeplinks();
  const { handleNiceGuidelinesDeeplink } = useNiceGuidelinesDeeplink();
  const { handleClinicalResourceDeeplink } = useClinicalResourceDeeplink();

  const setDisplayDirectToMobile = React.useCallback(() => {
    setShouldDisplayDirectToMobile(true);
    toggleDrawerHidden(true);
  }, [toggleDrawerHidden]);

  useEffect(() => {
    if (type && isSupportedKnowledgeContentDeepLinkType(type)) {
      if (didFireAnalyticsEvent.current === false) {
        didFireAnalyticsEvent.current = true;
        trackEvent<DeeplinkTappedPayload>(AnalyticsEvents.DEEPLINK_TAPPED, {
          entityId: getDeeplinkEntityId(search, type),
          type: "file",
          mainSectionType: getDeeplinkMainSectionIdentity(type),
        });
      }

      switch (type) {
        case CommunityLevelSection.communityMedications:
          {
            setIsLoading(false);
            const result = handleMedicationDeeplink(search, type);

            if (result === "DISPLAY_DIRECT_TO_MOBILE") {
              setDisplayDirectToMobile();
            }

            if (result === "PROCEED_TO_DEEPLINK") {
              redirectToLinkedDocument({
                basePath: "/knowledge",
                legacyType: type,
                history,
                searchParams: search,
              });
            }

            if (result === "INVALID_PARAMS") {
              setDeeplinkError("invalidParams");
            }
          }
          break;
        case CommunityLevelSection.wikiEm:
          {
            setIsLoading(false);
            const result = handleWikemDeeplink(search, type);

            if (result === "DISPLAY_DIRECT_TO_MOBILE") {
              setDisplayDirectToMobile();
            }

            if (result === "PROCEED_TO_DEEPLINK") {
              redirectToWikiEmComingSoon(history);
            }

            if (result === "INVALID_PARAMS") {
              setDeeplinkError("invalidParams");
            }
          }
          break;
        case CommunityLevelSection.niceGuidelines:
          {
            setIsLoading(false);
            const result = handleNiceGuidelinesDeeplink(search, type);

            if (result === "DISPLAY_DIRECT_TO_MOBILE") {
              setDisplayDirectToMobile();
            }

            if (result === "PROCEED_TO_DEEPLINK") {
              redirectToLinkedDocument({
                basePath: "/knowledge",
                legacyType: type,
                history,
                searchParams: search,
              });
            }

            if (result === "INVALID_PARAMS") {
              setDeeplinkError("invalidParams");
            }
          }
          break;
        case CommunityLevelSection.nationalGuidelines:
        case CommunityLevelSection.clinicalCalculators:
        case CommunityLevelSection.communityPatientLeaflets:
          {
            setIsLoading(false);
            const result = handleClinicalResourceDeeplink(search, type);

            if (result === "DISPLAY_DIRECT_TO_MOBILE") {
              setDisplayDirectToMobile();
            }

            if (result === "PROCEED_TO_DEEPLINK") {
              redirectToLinkedDocument({
                basePath: "/knowledge",
                legacyType: type,
                history,
                searchParams: search,
              });
            }

            if (result === "INVALID_PARAMS") {
              setDeeplinkError("invalidParams");
            }
          }

          break;
        default: {
          // If the below line throws a type error, it means that the type is not handled in the switch statement above
          expectNever(type);
        }
      }
    } else {
      setIsLoading(false);
      setDeeplinkError("unSupportedType");
    }

    return () => {
      toggleDrawerHidden(false);
    };
  }, [
    search,
    type,
    history,
    setDisplayDirectToMobile,
    toggleDrawerHidden,
    shouldDirectToMobileFlag,
    handleMedicationDeeplink,
    handleWikemDeeplink,
    handleNiceGuidelinesDeeplink,
    handleClinicalResourceDeeplink,
  ]);

  return {
    isLoading,
    hasError: !!deeplinkError,
    error: deeplinkError,
    shouldDisplayDirectToMobile: shouldDisplayDirectToMobile,
    isMobileBrowser: isMobile,
    type,
    searchString: search,
  };
};
