import { MonographType, bnfClient, sortInteractions } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import bnfKeys from "./bnf.queryKeys";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";

type HookArgs = { selectedDrugs: { value: string; label: string }[]; type: MonographType };

const queryFn = async ({ selectedDrugs, type }: HookArgs) => {
  const selectedSids = selectedDrugs.map(({ value }) => value);
  const interactions = await bnfClient.getMultiDrugInteractions({ sids: selectedSids, type });
  return sortInteractions({ interactions });
};

export const useGetInteractionsForCombination = (args: HookArgs) => {
  const { selectedDrugs } = args;

  const { data, refetch, error, isInitialLoading } = useQuery({
    queryKey: bnfKeys.multiDrugInteractions([args]),
    queryFn: () => queryFn(args),
    enabled: selectedDrugs.length > 1 && selectedDrugs.length < 41,
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return { data: data ?? [], error, isInitialLoading, refetch };
};
