import { Control, UseFormSetValue } from "react-hook-form";
import { ClinicalQuestion } from "../hooks/useClinicalQuestionInput";
import { useState } from "react";
import { RecentQuestions } from "./RecentQuestions";
import { UserPrompt } from "./UserPrompt";
import { useTranslation } from "react-i18next";
import { QuestionBar } from "./QuestionBar";

export type NewQuestionProps = {
  control: Control<ClinicalQuestion>;
  onSubmit: () => void;
  isSubmitting: boolean;
  errorMessage?: string;
  setValue: UseFormSetValue<ClinicalQuestion>;
};
export const NewQuestionView = ({
  control,
  onSubmit,
  isSubmitting,
  errorMessage,
  setValue,
}: NewQuestionProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => {
    setIsFocused(true);
  };
  const unfocusInput = () => {
    setIsFocused(false);
  };

  const { t } = useTranslation();

  return (
    <div className="w-90vw sm:w-60vw">
      <QuestionBar
        onFocus={onFocus}
        onBlur={unfocusInput}
        isFocused={isFocused}
        fieldName="question"
        placeholder={t("ask_me_anything")}
        onSubmit={onSubmit}
        control={control}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
      />
      {isFocused || errorMessage || isSubmitting ? (
        <UserPrompt />
      ) : (
        <RecentQuestions onSubmit={onSubmit} setValue={setValue} />
      )}
    </div>
  );
};
