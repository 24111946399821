import React from "react";
import { SearchTypeSection } from "Components/MasterSearch/types";
import { useTranslation } from "react-i18next";
import { Title } from "UIKit";

interface NoResultsMessageProps {
  searchValue: string;
  searchType: SearchTypeSection;
  knowledgeSearchDisabled?: boolean;
  onClickMessage: (searchType: SearchTypeSection) => void;
}

const NoResultsMessage: React.FC<NoResultsMessageProps> = ({
  searchValue,
}: NoResultsMessageProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col items-center justify-center space-y-2 mt-10 mb-24"
      data-testid="master-search-no-results"
    >
      <Title level={7} className="text-grey-700">
        {t("master_search_no_results", { searchText: `"${searchValue}"` })}
      </Title>
    </div>
  );
};

export default NoResultsMessage;
