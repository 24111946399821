import { isArrayComponentType } from "Pages/MeTab/pages/Portfolio/helpers";
import { FormElement, PortfolioFieldNames, FormValues } from "modules/portfolio/types";
import { useMemo } from "react";

const useEventFormInitialValues = (
  formElements: FormElement[],
  areAttachmentsShared: boolean,
): FormValues => {
  const initialFormValues = useMemo(() => {
    const initialValues = formElements?.reduce((prev, fe) => {
      if (fe.fieldName === PortfolioFieldNames.date && !fe.value) {
        return {
          ...prev,
          [fe.fieldName]: new Date().toISOString(),
        };
      }

      if (fe.fieldName === PortfolioFieldNames.title && !fe.value) {
        return {
          ...prev,
          [fe.fieldName]: "",
        };
      }

      if (isArrayComponentType(fe.type)) {
        return {
          ...prev,
          [fe.fieldName]: fe.value || [],
        };
      }

      return {
        ...prev,
        [fe.fieldName]: fe.value || "",
      };
    }, {}) as FormValues;

    return {
      ...initialValues,
      [PortfolioFieldNames.shareAttachments]: areAttachmentsShared,
    };
  }, [formElements, areAttachmentsShared]);

  return initialFormValues;
};

export default useEventFormInitialValues;
