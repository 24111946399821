import { useTranslation } from "react-i18next";
import { EolasVersatileListContext } from "../EolasVersatileList.types";
import { Text } from "UIKit/Typography/Text";
import { Loader } from "UIKit/Loader/Loader";

export type ListEmptyComponentProps<T, C extends EolasVersatileListContext<T>> = {
  context: C;
};

export const ListEmptyComponent = <T, C extends EolasVersatileListContext<T>>({
  context,
}: ListEmptyComponentProps<T, C>) => {
  const { t } = useTranslation();
  const { isInitialLoading, listEmptyText } = context;

  if (isInitialLoading) {
    return <Loader className="pt-10vh bg-transparent" />;
  }

  return (
    <div className="flex flex-col items-center h-64 mt-16">
      <Text level={1}>{listEmptyText || t("general_noFilesFound")}</Text>
    </div>
  );
};
