import { useTranslation } from "react-i18next";
import { NormalStepComponentProps, NormalStepData } from "../../../types";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { FormElement, Input, Textarea } from "UIKit";
import { makeMutableWizardState } from "../../../functions/makeMutableWizardState";
import { useFlashCardDetails } from "./hooks/useFlashCardDetails";
import { NewContentType } from "@eolas-medical/core";

const FlashcardDetails = ({
  wizardState,
  onClickBack,
  onClickForward,
}: NormalStepComponentProps) => {
  const { handleSubmit, control } = useFlashCardDetails(wizardState);

  const { t } = useTranslation();

  return (
    <EolasWizardModalWrapper
      header={{
        title: wizardState.fileId ? t("flashcards_edit_page_title") : t("flashcards_page_title"),
      }}
      buttonRowProps={{
        previousButton: { onClick: onClickBack },
        forwardButton: {
          onClick: handleSubmit((values) => {
            const newState = makeMutableWizardState(wizardState);
            if (!newState.isExistingFileWithNewType) {
              newState.dto.name = values.name;
              newState.dto.description = values.description;
            } else if (newState.dto.newType === NewContentType.FLASHCARD) {
              newState.dto.newProperties.name = values.name;
              newState.dto.newProperties.description = values.description;
            }
            onClickForward(newState);
          }),
        },
      }}
    >
      <FormElement required id="name" control={control} label={t("flashcards_title_label")}>
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            placeholder={t("flashcards_title_placeholder")}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="description"
        control={control}
        label={t("flashcards_description_label")}
      >
        {(field) => <Textarea {...field} placeholder={t("flashcards_description_placeholder")} />}
      </FormElement>
    </EolasWizardModalWrapper>
  );
};

export const flashcardDetailsData: NormalStepData = {
  StepComponent: FlashcardDetails,
  props: { getPreviousStepName: () => "selectFile", getNextStepName: () => "editFlashcardContent" },
};
