import * as Types from "./EolasRadio.types";
import { CheckCircle } from "./components/CheckCircleRadio";

/**
 * Eolas Radio component
 * There are multiple styles of radios, the style displayed depends on the variant prop
 * @example
 * <EolasRadio variant="checkCircle" isChecked={true} size="lg" />
 */
export const EolasRadio = (props: Types.AllProps): JSX.Element => {
  switch (props.variant) {
    case "checkCircle":
      return <CheckCircle {...props} />;
  }
};
