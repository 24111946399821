import { PatientGroup } from "@eolas-medical/core";
import { twMerge } from "tailwind-merge";
import { Pill, PillProps } from "UIKit";
import { Sizes } from "UIKit/types";

interface BnfPill extends PillProps {
  type?: PatientGroup | "drug";
}

const BnfPill = ({ value, type, size = Sizes.EXTRA_SMALL, className, ...otherProps }: BnfPill) => {
  let pillClass = "";

  switch (type) {
    case "adult":
      pillClass = "bg-blue-50 border border-blue-600 text-blue-600";
      break;
    case "child":
      pillClass = "bg-red-50 border border-red-600 text-red-600";
      break;
    case "neonate":
      pillClass = "bg-grey-100 border border-black text-black";
      break;
    case "drug":
      pillClass = "bg-white border border-black text-black";
      break;
    default:
      pillClass = "bg-grey-100 border border-black text-black";
  }

  return (
    <div className="flex">
      <Pill {...otherProps} value={value} className={twMerge(pillClass, className)} size={size} />
    </div>
  );
};

export default BnfPill;
