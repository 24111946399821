import { useTranslation } from "react-i18next";
import ComingSoon from "shared/pages/MiniApp/ComingSoon";

export const WikEm = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ComingSoon
      titleText={t("community_wikem_title")}
      subTitleText={t("coming_soon_subtitle_wikEm")}
      informationText={t("coming_soon_information_wikEm")}
    />
  );
};
