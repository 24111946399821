import {
  AnalyticsEvents,
  CommunityLevelSection,
  DeeplinkGeneratedPayload,
  eolasLogger,
  getMainSectionOwnerType,
  isMedicationMetadata,
  isSmpcMetadata,
  sectionStore,
  fromAwsJSON,
} from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import {
  isSupportedBNFMedicationType,
  isSupportedKnowledgeContentDeepLinkType,
  isSupportedOrganisationContentDeepLinkType,
  isSupportedSpaceContentDeepLinkType,
} from "deeplinking/functions/typeguards";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { generateLinkToItem } from "shared/functions/generateLinkToItem";
import { KnowledgeResult, LocalResult } from "../../types";
import {
  isKnowledgeSearchResult,
  isLocalSearchResult,
  isShareableMasterSearchFile,
} from "../../functions/typeguards";
import {
  createEntityItemFromSearchResult,
  mainSectionOwnerTypeToActiveTab,
} from "./functions/helpers";
import { trackEvent } from "API/Analytics";
import { getFileName } from "shared/hooks/useShareFile";

export const useShareSearchResult = () => {
  const { flags } = useLaunchDarkly();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const isShareFileEnabled = !!flags[LDFlagNames.SHARE_FILE];

  const checkIsSearchResultShareable = useCallback(
    (item: LocalResult | KnowledgeResult): boolean => {
      if (!isShareFileEnabled) {
        return false;
      }

      const file = isLocalSearchResult(item) ? item.file : item.knowledgeFile;

      if (isKnowledgeSearchResult(item)) {
        const metadata = fromAwsJSON(item.knowledgeFile.metadata);
        // We don't allow sharing of SMPC results as they are entire sections
        if (isSmpcMetadata(metadata)) {
          return false;
        }

        if (
          isMedicationMetadata(metadata) &&
          !isSupportedBNFMedicationType(metadata.bnfSubsectionType)
        ) {
          return false;
        }
      }

      if (!file.mainSectionID) {
        return false;
      }

      const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);
      const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
        file.mainSectionID,
      );

      const type = mainSectionIdentity === "genericContentRepository" ? "null" : mainSectionType;

      if (type === null) {
        return false;
      }

      if (isSupportedSpaceContentDeepLinkType(type)) {
        return true;
      }

      if (isSupportedOrganisationContentDeepLinkType(type)) {
        return true;
      }

      // TODO: Remove when wanting to share wikiEM
      if (type === CommunityLevelSection.wikiEm) {
        return false;
      }

      if (isSupportedKnowledgeContentDeepLinkType(type)) {
        return true;
      }

      return false;
    },
    [isShareFileEnabled],
  );

  const handleOnShareLocalResult = useCallback(
    (item: LocalResult) => {
      if (!isShareFileEnabled) {
        return;
      }

      const { file } = item;

      if (!isShareableMasterSearchFile(file)) {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
        return;
      }

      const mainSection = sectionStore.getSection(file.mainSectionID);

      const ownerType = getMainSectionOwnerType(mainSection);

      const result = generateLinkToItem(file, mainSectionOwnerTypeToActiveTab(ownerType));
      if (result) {
        trackEvent<DeeplinkGeneratedPayload>(AnalyticsEvents.DEEPLINK_GENERATED, {
          entityId: item.id,
          type: "file",
          url: result.link,
          title: getFileName(item),
          origin: "masterSearch",
        });

        navigator.clipboard.writeText(result.link);
        showNotification({
          type: "success",
          autoHideTimeout: 3000,
          description: t("share_item_link_copied"),
        });
      } else {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
      }
    },
    [isShareFileEnabled, showNotification, t],
  );

  const handleOnShareKnowledgeResult = useCallback(
    (item: KnowledgeResult) => {
      if (!isShareFileEnabled) {
        return;
      }

      const entityItem = createEntityItemFromSearchResult(item);

      if (!entityItem) {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
        eolasLogger.error(new Error("Failed to create entity item from knowledge result"), {
          item,
        });
        return;
      }

      const result = generateLinkToItem(entityItem, "knowledge");
      if (result) {
        trackEvent<DeeplinkGeneratedPayload>(AnalyticsEvents.DEEPLINK_GENERATED, {
          entityId: item.id,
          type: "file",
          url: result.link,
          title: getFileName(item),
          origin: "masterSearch",
        });

        navigator.clipboard.writeText(result.link);
        showNotification({
          type: "success",
          autoHideTimeout: 3000,
          description: t("share_item_link_copied"),
        });
      } else {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
      }
    },
    [isShareFileEnabled, showNotification, t],
  );

  return {
    checkIsSearchResultShareable,
    handleOnShareLocalResult,
    handleOnShareKnowledgeResult,
  };
};
