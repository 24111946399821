import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import medicationsKeys from "./medications.queryKeys";
import { SpecialtyValue, contentClient, isFlashCardContentItem } from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";

interface UseMedicationsHighlightsItemsProps {
  medicationsHighlightsSectionId: string;
  specialties?: SpecialtyValue[];
}

const useMedicationHighlightsItems = ({
  medicationsHighlightsSectionId,
  specialties,
}: UseMedicationsHighlightsItemsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: medicationsKeys.highlights(specialties),
    queryFn: () =>
      contentClient.getFlashcardsByMainSectionId(medicationsHighlightsSectionId, specialties),
  });

  const medicationsHighlightsItems = useMemo(() => {
    return data?.map(mapToContentItem).filter(isFlashCardContentItem) || [];
  }, [data]);

  return {
    medicationsHighlightsItems,
    medicationsHighlightsItemsLoading: isLoading,
  };
};

export default useMedicationHighlightsItems;
