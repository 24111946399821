import { FC } from "react";
import { BodyWeightCalcResults } from "@eolas-medical/core";

import { ScaleIcon, WeightIcon } from "Assets";

import { ResultBox } from "./ResultBox";

interface BodyWeightResultProps {
  results: BodyWeightCalcResults;
}

export const BodyWeightResults: FC<BodyWeightResultProps> = ({
  results,
}: BodyWeightResultProps) => {
  const { idealBodyWeight, error, actualBodyWeight, adjustedBodyWeight } = results;

  return (
    <>
      <ResultBox
        title="Actual Body Weight:"
        text={`${actualBodyWeight}kg`}
        icon={<WeightIcon className="w-6 h-6 mr-4" />}
        error={error}
      />
      <ResultBox
        title="Ideal Body Weight:"
        text={`${idealBodyWeight}kg`}
        icon={<ScaleIcon className="w-6 h-6 mr-4" />}
      />

      {adjustedBodyWeight && (
        <ResultBox
          title="Adjusted Body Weight:"
          text={`${adjustedBodyWeight}kg`}
          icon={<ScaleIcon className="w-6 h-6 mr-4" />}
        />
      )}
    </>
  );
};
