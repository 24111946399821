import { createContext } from "react";
import { ContentViewerContextValue, UseContentItemHook } from "./types";

export const defaultUseContentItemHook: UseContentItemHook = {
  contentItem: undefined,
  isContentItemLoading: false,
  isContentItemError: false,
  contentItemError: undefined,
};

const ContentViewerContext = createContext<ContentViewerContextValue>({
  useContentItemHook: defaultUseContentItemHook,
});

export default ContentViewerContext;
