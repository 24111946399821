import { useMutation } from "@tanstack/react-query";
import { contentClient } from "@eolas-medical/core";

const useSendContentItemReminder = () => {
  const { mutate, isLoading, isSuccess, reset, error } = useMutation(
    async ({ mainSectionId, contentId }: { mainSectionId: string; contentId: string }) => {
      return contentClient.sendMainSectionItemReminder({ mainSectionId, contentId });
    },
  );

  return {
    sendContentItemReminder: mutate,
    sendingContentItemReminder: isLoading,
    sendContentItemReminderSuccess: isSuccess,
    resetContentItemReminder: reset,
    errorSendingContentItemReminder: error,
  };
};

export default useSendContentItemReminder;
