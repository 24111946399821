import { AccessLevel, accessClient, sectionStore } from "@eolas-medical/core";

import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

export interface UpdateAccessLevelParams {
  userId: string;
  accessLevel: AccessLevel;
  limitedAccess?: string[];
}

export const useAdminEntry = () => {
  const { spaceId } = useGetRelevantSpaceDetails();
  const queryClient = useQueryClient();

  const updateAccessLevel = useMutation({
    mutationFn: async ({ userId, accessLevel, limitedAccess }: UpdateAccessLevelParams) => {
      if (!spaceId) {
        throw new Error("Update Access level: spaceId is null");
      }
      return accessClient.setUserAccessLevel({
        departmentId: spaceId,
        userId,
        accessLevel,
        limitedAccess,
      });
    },
    mutationKey: ["updateAccessLevel"],
    onSuccess: () => {
      if (spaceId) {
        queryClient.refetchQueries(spacesKeys.usersList(spaceId));
      }
    },
  });

  const updateLimitedAccess = useMutation({
    mutationFn: async ({ userId, limitedAccess }: { userId: string; limitedAccess: string[] }) => {
      if (!spaceId) {
        throw new Error("Update limited access: spaceId is null");
      }
      return accessClient.updateLimitedAccess({
        departmentId: sectionStore.appID,
        userId,
        limitedAccess,
      });
    },
    mutationKey: ["updateLimitedAccess"],
    onSuccess: () => {
      if (spaceId) {
        queryClient.refetchQueries(spacesKeys.usersList(spaceId));
      }
    },
  });

  const error = updateAccessLevel.error || updateLimitedAccess.error;

  return {
    error: error instanceof Error ? error.message : "",
    isLoading: updateAccessLevel.isLoading || updateLimitedAccess.isLoading,
    onUpdateAccessLevel: updateAccessLevel.mutateAsync,
    onUpdateLimitedAccess: updateLimitedAccess.mutateAsync,
  };
};
