import { useTranslation } from "react-i18next";

import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { Loader } from "UIKit";
import { useGetDraftFile } from "./hooks/useGetDraftFile";
import { ErrorComponent } from "./components/ErrorComponent";
import { ManageContentItemChildModal } from "./ManageContentItemChildModal";

export type ManageContentItemWizardModalProps = {
  sectionId: string;
  fileId: string | null;
};

export const ManageContentItemWizardModal = (props: ManageContentItemWizardModalProps) => {
  const { data, isFetching, isError } = useGetDraftFile(props.fileId);

  const { t } = useTranslation();

  if (isFetching) {
    return (
      <EolasWizardModalWrapper header={{ title: t("general_loading") }}>
        <div className="flex flex-col items-center justify-center width-full">
          <div className="mt-6">
            <Loader />
          </div>
        </div>
      </EolasWizardModalWrapper>
    );
  }

  if (isError) {
    return <ErrorComponent />;
  }

  if (props.fileId) {
    if (data?.hasDrafts) {
      return (
        <ManageContentItemChildModal
          sectionId={props.sectionId}
          fileId={props.fileId}
          draftFile={data.draftFile}
        />
      );
    }
    return (
      <ManageContentItemChildModal
        sectionId={props.sectionId}
        fileId={props.fileId}
        draftFile={null}
      />
    );
  }

  return <ManageContentItemChildModal sectionId={props.sectionId} fileId={null} />;
};
