import { AlertTwoIcon } from "Assets";
import { Text, Title, Button, ModalBody, FormError, ModalHeader, InnerModalWrapper } from "UIKit";

interface BuilderSubmitConfirmationModalProps {
  error?: string;
  isLoading?: boolean;
  onCancel(): void;
  onSubmit(): void;
}

export const BuilderSubmitConfirmationModal: React.FC<BuilderSubmitConfirmationModalProps> = ({
  error,
  isLoading,
  onCancel,
  onSubmit,
}: BuilderSubmitConfirmationModalProps) => {
  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>Submit checklist?</Title>
      </ModalHeader>

      <ModalBody className="items-center space-y-4 sm:space-y-8">
        <AlertTwoIcon className="w-16 h-16" />
        <Text level={1}>
          There is unsaved progress in this checklist. Are you sure you want to submit?
        </Text>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Button size="lg" color="grey" onClick={onCancel}>
            Cancel
          </Button>
          <Button isLoading={isLoading} size="lg" onClick={onSubmit}>
            Submit
          </Button>
        </div>

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
