import React, { useEffect } from "react";
import { useLocalMasterSearch } from "../../hooks/useLocalMasterSearch";
import MasterSearchResults from "../MasterSearchResults";

import { MasterSearchResultsParams } from "Components/MasterSearch/types";

const MasterSearchResultsLocal = (params: MasterSearchResultsParams) => {
  const { onSearchLocalFiles, localFiles, localFilesLoading } = useLocalMasterSearch();
  const { searchValue, searchInput } = params;

  useEffect(() => {
    onSearchLocalFiles(searchValue);
  }, [searchValue, onSearchLocalFiles]);

  return (
    <MasterSearchResults
      searchLoading={localFilesLoading ? searchInput.length > 0 : false}
      searchResult={localFiles}
      {...params}
      masterSearchPlaceholder={"master_search_local_placeholder"}
    />
  );
};

export default MasterSearchResultsLocal;
