import {
  contentClient,
  EolasFile,
  eolasLogger,
  isNoAccessContentItemResponse,
} from "@eolas-medical/core";
import { useAppSync } from "Hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { intermediateUpdateForUi } from "../../../functions/intermediateUpdateForUi";

export const useUnpublishItem = (file: EolasFile | null) => {
  const { t } = useTranslation();
  const { refetch } = useAppSync();

  const unpublishItem = useCallback(() => {
    if (!file) {
      return;
    }

    modalStore.openModal({
      name: "unPublishItem",
      variant: "dialogue",
      layoutType: "stacked",
      title: t("content_item_unpublish_title"),
      message: t("content_item_unpublish_description"),
      icon: "warning",
      isDismissible: true,
      onConfirmAsync: async () => {
        const latestFile = await contentClient.getContentItemById(file.id);
        if (!isNoAccessContentItemResponse(latestFile)) {
          if (latestFile.hasDrafts) {
            await contentClient.updateDraftFile({
              mainSectionId: latestFile.mainSectionID,
              contentId: latestFile.id,
              draftFileDto: { isDraft: true },
              shouldMergeChanges: true,
            });
          } else {
            await contentClient.updateContentItem({
              contentId: file.id,
              mainSectionId: file.mainSectionID,
              contentDto: { isDraft: true },
            });
          }
          const newFile: EolasFile = { ...latestFile, hasDrafts: false, isDraft: true };
          intermediateUpdateForUi({ file: newFile, action: "update", type: "file" });
          refetch();
        } else {
          eolasLogger.error(
            new Error(
              "Error unpublishing content item - expected to receive full object but was only given partial",
            ),
            { latestFile },
          );
        }
      },
    });
  }, [refetch, file, t]);

  return { unpublishItem };
};
