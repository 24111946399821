import { get, isEmpty } from "lodash";
import { Cropper } from "react-cropper";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";

import "cropperjs/dist/cropper.css";

import { DeleteIcon, RefreshCWIcon, PictureIcon, ZoomInIcon, ZoomOutIcon } from "Assets";

import { theme } from "../theme";
import { Text } from "../Typography";
import { Button, IconButton } from "../Button";
import { FormFieldError } from "UIKit/FormElements";
import { convertFileToDataURL } from "./helpers";

import useImageCompression from "./hooks/useImageCompression";
import useImageCropping from "./hooks/useImageCropping";

export interface ImageUploaderProps {
  id?: string;
  fileName?: string;
  error?: string;
  croppingEnabled?: boolean;
  defaultValue?: File | string;
  fileToLargeError?: string;
  maxSize?: number;
  shouldCompress?: boolean;
  getRemotePreview?(fileKey: string): string;
  onChange: (file: File | null, filePreview?: string) => void;
  cropperAspectRatio?: number;
}

export interface ImageUploaderState {
  imagePreview: string;
  file: File | null;
}

const defaultState: ImageUploaderState = {
  file: null,
  imagePreview: "",
};

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  id,
  defaultValue,
  croppingEnabled,
  fileName,
  fileToLargeError,
  shouldCompress = true,
  maxSize = 2000000,
  onChange,
  getRemotePreview,
  cropperAspectRatio = NaN,
}: ImageUploaderProps) => {
  const { t } = useTranslation();
  const [pickerState, setPickerState] = useState<ImageUploaderState>(defaultState);
  const [error, setError] = useState("");
  const { imageRef, onCropEnd, onZoomIn, onZoomOut } = useImageCropping(
    pickerState,
    onChange,
    fileName,
  );

  const { compressImage } = useImageCompression();

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue instanceof File) {
        convertFileToDataURL(defaultValue).then((imagePreview) => {
          setPickerState({
            file: defaultValue,
            imagePreview,
          });
        });
      }
      let imagePreview;
      if (getRemotePreview) {
        imagePreview = getRemotePreview(defaultValue as string);
      }

      setPickerState({
        file: null,
        imagePreview: imagePreview || (defaultValue as string),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: [".png", ".jpg", ".jpeg"],
    onDrop: async (files: File[], rejectedFiles) => {
      if (!isEmpty(rejectedFiles)) {
        const error = rejectedFiles[0].errors[0];
        if (error.code === "file-too-large") {
          setError(fileToLargeError ? fileToLargeError : error.message);
        }
        return;
      }

      let image = files[0];

      if (shouldCompress) {
        image = await compressImage(files[0]);
      }

      const imagePreview = await convertFileToDataURL(image);

      setPickerState({
        file: image,
        imagePreview,
      });
      setError("");
      onChange(image, imagePreview);
    },
    maxSize,
  });

  const onRemoveImage = useCallback(() => {
    setPickerState(defaultState);
    setError("");
    onChange(null, "");
  }, [onChange, setPickerState]);

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? { backgroundColor: get(theme, "colors.grey.hover") } : {};
  }, [isDragActive]);

  if (croppingEnabled && pickerState.file && pickerState.imagePreview) {
    return (
      <>
        <div
          className={`
          flex flex-col p-4 rounded-md relative
          bg-white border-solid border-2
          ${error ? "border-red-focus" : "border-grey-400"}
        `}
        >
          <div className="absolute top-2 right-2 sm:top-4 sm:right-4 flex space-x-4 z-50">
            <div {...getRootProps()}>
              <IconButton type="button" variant="rounded" icon={<RefreshCWIcon />} />
            </div>

            <IconButton
              type="button"
              variant="rounded"
              icon={<DeleteIcon />}
              onClick={onRemoveImage}
            />
          </div>

          <Cropper
            viewMode={2}
            modal={false}
            ref={imageRef}
            background={false}
            cropend={onCropEnd}
            src={pickerState.imagePreview}
            style={{ maxHeight: 420, backgroundColor: "transparent", opacity: 1 }}
            aspectRatio={cropperAspectRatio}
          />

          <div className="flex justify-center space-x-2 mt-2">
            <IconButton type="button" onClick={onZoomIn} icon={<ZoomInIcon />} />
            <IconButton type="button" onClick={onZoomOut} icon={<ZoomOutIcon />} />
          </div>

          <input {...getInputProps()} />
        </div>
        <FormFieldError>{error}</FormFieldError>
      </>
    );
  }

  if (pickerState.imagePreview) {
    return (
      <>
        <div
          className={`
          flex flex-col justify-center items-center rounded-md h-56 relative
          bg-white border-solid border-2 
          ${error ? "border-red-focus" : "border-grey-400"}
          `}
        >
          <img
            alt="preview"
            className="max-h-48"
            data-testid="image-preview"
            src={pickerState.imagePreview}
          />

          <div className="flex space-x-3 absolute top-3 right-3">
            <div {...getRootProps()}>
              <IconButton type="button" variant="rounded" icon={<RefreshCWIcon />} />
            </div>

            <IconButton
              type="button"
              variant="rounded"
              icon={<DeleteIcon />}
              onClick={onRemoveImage}
            />
          </div>

          <input id={id} {...getInputProps()} />
        </div>

        <FormFieldError>{error}</FormFieldError>
      </>
    );
  }

  return (
    <>
      <div
        className={`
        flex flex-col justify-center items-center rounded-md h-56 space-y-4
        bg-grey-50 border-2 border-dashed
        ${error ? "border-red-focus" : "border-grey-400"}
        `}
        {...getRootProps({ style: dropzoneStyle })}
      >
        <PictureIcon className="fill-current" width={40} height={40} />

        <div className="flex flex-col items-center">
          <Text level={1} className="text-grey-600">
            {t("imagePicker_helper_text")}
          </Text>
          <Text level={1} className="text-grey-600">
            {t("fileDropzone_or")}
          </Text>
        </div>

        <Button color="grey" variant="outline" data-testid="image-picker-button">
          {t("imagePicker_upload_button")}
        </Button>

        <input id={id} {...getInputProps()} data-testid="image-picker-input" />
      </div>
      <FormFieldError>{error}</FormFieldError>
    </>
  );
};
