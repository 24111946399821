import { sectionStore } from "@eolas-medical/core";
import { InnerPageWrapper, LoadingOverlay, PageTitle, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { DownLoadTheApp } from "deeplinking/components/DownloadTheApp";
import { OnlyAvailableOnMobile } from "deeplinking/components/OnlyAvailableOnMobile";
import { mapMainSectionToDisplayName } from "deeplinking/functions/helpers";
import { isSupportedSpaceContentDeepLinkType } from "deeplinking/functions/typeguards";
import { useHandleSpacesDeeplink } from "deeplinking/hooks/useHandleSpacesDeeplink";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const SpacesLandingPage = observer(() => {
  const { t } = useTranslation();
  const {
    isLoading,
    hasError,
    error,
    shouldDisplayDirectToMobile,
    isMobileBrowser,
    type,
    searchString,
  } = useHandleSpacesDeeplink();
  return (
    <div className="flex flex-col justify-center items-center flex-1 w-full">
      <div className="flex flex-col justify-center items-center flex-1 w-full">
        {isLoading ? <LoadingOverlay /> : null}

        {hasError ? (
          <InnerPageWrapper>
            <div className="flex flex-col justify-start items-center flex-1 pt-16">
              <PageTitle
                title={t(
                  error === "unSupportedType"
                    ? "unsupported_deeplink_title"
                    : "invalid_deeplink_title",
                )}
              />
              <Text className="text-center mb-2" level={1}>
                {t(
                  error === "unSupportedType"
                    ? "unsupported_deeplink_description"
                    : "invalid_deeplink_description",
                )}
              </Text>
              <Link
                to={`/${
                  sectionStore.activeDepartmentTab ? BaseAppRoute.spaces : BaseAppRoute.organisation
                }`}
                className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
              >
                {t("take_me_home")}
              </Link>
            </div>
          </InnerPageWrapper>
        ) : null}

        {!isLoading && !hasError && shouldDisplayDirectToMobile && isMobileBrowser ? (
          <DownLoadTheApp backgroundType="space" type={type ?? null} searchString={searchString} />
        ) : null}

        {!isLoading && !hasError && shouldDisplayDirectToMobile && !isMobileBrowser ? (
          <OnlyAvailableOnMobile
            backgroundType="space"
            featureDisplayName={
              // This will always be true in this context
              type && isSupportedSpaceContentDeepLinkType(type)
                ? mapMainSectionToDisplayName(type)
                : undefined
            }
          />
        ) : null}
      </div>
    </div>
  );
});
