import { BnfSearchParams } from "../routes/urlParams";
import { useGetSearchParams } from "Utilities/useGetSearchParams";

export const useGetBnfSearchParams = () => {
  const { idOrSid, title } = useGetSearchParams({
    paramList: [BnfSearchParams.idOrSid, BnfSearchParams.title],
  });

  return { title, idOrSid };
};
