import { Control, useController } from "react-hook-form";
import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { Editor as TinyMCEEditor } from "tinymce";

import { EditFlashcardValues } from "../hooks/useEditFlashcardContent";
import { FlashcardCreatorStep } from "UIKit";
import { WizardState } from "../../../../types";
import { DeeplinkConfig } from "shared/components/EolasMCEEditor/hooks/useDeeplinkingSetup";
import { hasStringProp } from "@eolas-medical/core";

export const FlashcardEditor = ({
  state,
  control,
  sectionId,
  modalBodyRef,
}: {
  state: WizardState;
  control: Control<EditFlashcardValues>;
  sectionId: string;
  modalBodyRef?: RefObject<HTMLDivElement>;
}) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const {
    field: { onChange, value: currentEditorContent },
    formState: { errors },
  } = useController({ control, name: "content" });

  const [isLoading, setIsLoading] = useState(true);

  const editorConfigs: { deeplinkingConfig: DeeplinkConfig } = useMemo(
    () => ({
      deeplinkingConfig: {
        isEnabled: true,
        disabledIds: state.fileId ? [state.fileId] : undefined,
      },
    }),
    [state],
  );

  const onEditorInit = useCallback((_: unknown, editor: TinyMCEEditor) => {
    setIsLoading(false);
    editorRef.current = editor;
  }, []);

  let flashcardTitle = "";
  let flashcardDescription = "";

  if (state.isExistingFileWithNewType) {
    flashcardTitle = state.dto.newProperties.name ?? "";
    flashcardDescription = hasStringProp(state.dto.newProperties, "description")
      ? state.dto.newProperties.description
      : "";
  } else {
    flashcardTitle = state.dto.name ?? "";
    flashcardDescription = state.dto.description ?? "";
  }

  return (
    <FlashcardCreatorStep
      contentRepositoryId={sectionId}
      onContentChange={onChange}
      onEditorInit={onEditorInit}
      editorContent={currentEditorContent}
      error={errors?.content?.message}
      flashcardTitle={flashcardTitle}
      flashcardDescription={flashcardDescription}
      modalBodyRef={modalBodyRef}
      editorConfigs={editorConfigs}
      isLoading={isLoading}
    />
  );
};
