import { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import envConfig from "env-config";
import { useGetAllAccessRequests } from "Hooks/usersService";
import { InnerPageWrapper, PageTitle, NavButton, Modal } from "UIKit";
import {
  UserList,
  LoginTokens,
  TrustedEmails,
  ManageAdminsModal,
  UserBreakdownModal,
  AccessRequestsModal,
} from "./components";
import { trackEvent } from "API/Analytics";
import { ManageUsersButtons } from "./components/ManageUserButtons/ManageUsersButtons";
import InviteUsersModal from "./components/InviteUser/InviteUsersModal/InviteUsersModal";
import InviteUsersByEmailModal from "./components/InviteUser/InviteUsersByEmailModal/InviteUsersByEmailModal";

import useGenerateAccessToken from "modules/spaces/data/useGenerateAccessToken";
import AccessQRCodeModal from "UIKit/Modal/AccessQRCodeModal/AccessQRCodeModal";
import AccessType from "./components/AccessType/AccessType";

import { AccessLinkToken } from "Pages/Authentication/pages/Invitations/pages/AccessLink/AccessLink";
import useSpaceUsers from "modules/spaces/data/useSpaceUsers";
import useGetAccessToken from "modules/spaces/data/useGetAccessToken";
import { useGetRelevantSpaceDetails } from "./hooks/useGetRelevantSpaceDetails";
import { observer } from "mobx-react-lite";

export type ManageUsersModalState =
  | "InviteUsers"
  | "AccessRequests"
  | "UserBreakdown"
  | "ManageAdmins"
  | "InviteByEmail"
  | "InviteByLink";

const ManageUsers = observer(() => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  /**
   * @TODO: remove modal stable in favour of modalStore
   */
  const [modalState, setModalState] = useState<ManageUsersModalState | null>(null);

  const { userID: userId = "" } = userStore;
  const { spaceId, isPublic } = useGetRelevantSpaceDetails();
  const { spaceUsers } = useSpaceUsers(spaceId);
  const { count: accessRequestsCount } = useGetAllAccessRequests(spaceId);

  const { accessToken, gettingAccessToken } = useGetAccessToken(spaceId);

  const { generateAccessToken, generatingAccessToken } = useGenerateAccessToken(spaceId);

  const inviteUsersByLinkRoute = `${envConfig.REACT_APP_PROTOCOL}://${envConfig.REACT_APP_WEB_DOMAIN}/access-link/${accessToken}`;

  const handleCopyLink = useCallback(() => {
    trackEvent(AnalyticsEvents.ACCESS_LINK_COPIED, { userId });
  }, [userId]);

  const handleExportToImage = useCallback(() => {
    trackEvent(AnalyticsEvents.ACCESS_QR_EXPORTED, { userId });
  }, [userId]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("manageUsers_pageTitle")} />
        <NavButton to={url.replace("/manageUsers", "")} />

        <ManageUsersButtons
          accessRequestsCount={accessRequestsCount}
          setModalState={setModalState}
        />

        <LoginTokens />

        <AccessType />

        {!isPublic ? <TrustedEmails /> : null}

        <UserList users={spaceUsers} />
      </InnerPageWrapper>
      <Modal open={!!modalState} onClose={() => setModalState(null)}>
        {modalState === "UserBreakdown" ? <UserBreakdownModal users={spaceUsers} /> : null}

        {modalState === "ManageAdmins" ? <ManageAdminsModal appUsers={spaceUsers} /> : null}

        {modalState === "AccessRequests" ? <AccessRequestsModal spaceId={spaceId} /> : null}

        {modalState === "InviteUsers" ? (
          <InviteUsersModal
            isPublic={isPublic}
            onClickViaLink={() => setModalState("InviteByLink")}
            onClickViaEmail={() => setModalState("InviteByEmail")}
          />
        ) : null}

        {modalState === "InviteByEmail" ? <InviteUsersByEmailModal /> : null}

        {modalState === "InviteByLink" ? (
          <AccessQRCodeModal
            titleText={t("manageUsers_invite_by_link_title")}
            descriptionText={t("manageUsers_invite_by_link_subtitle")}
            onDone={() => setModalState(null)}
            onGenerateAccessToken={generateAccessToken}
            accessToken={accessToken as AccessLinkToken}
            onCopyLink={handleCopyLink}
            onExportToImage={handleExportToImage}
            isLoading={gettingAccessToken || generatingAccessToken}
            accessRoute={inviteUsersByLinkRoute}
            showRegenerate
          />
        ) : null}
      </Modal>
    </>
  );
});

export default ManageUsers;
