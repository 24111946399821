import { SquareIcon } from "UIKit";

interface TemplateProps {
  title: string;
  description: string;
  onClick?: () => void;
  icon: React.ReactNode;
}

export const TemplateCard: React.FC<TemplateProps> = ({
  title,
  description,
  onClick,
  icon,
}: TemplateProps) => {
  return (
    <div
      onClick={onClick}
      className="p-2 xl:p-6 cursor-pointer bg-white hover:bg-blue-50 shadow-md rounded-lg flex xl:flex-col items-center justify-between xl:h-48" // Set a fixed height for larger screens
    >
      <SquareIcon icon={icon} className="flex-1" />
      <div className="flex flex-col ml-2 xl:ml-0 xl:items-center">
        <div className="xl:text-lg font-bold mb-2 text-blue flex-1 flex xl:items-center justify-center">
          {title}
        </div>
        <div className="text-sm xl:text-md text-gray-600 flex-1 flex xl:items-center justify-center">
          {description}
        </div>
      </div>
    </div>
  );
};
