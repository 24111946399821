import { ContentItem } from "@eolas-medical/core";
import { DeleteModal, SuccessModal } from "UIKit";
import { useTranslation } from "react-i18next";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";

interface DeleteContentItemModalProps {
  contentItem: ContentItem;
  onClose: () => void;
}

const DeleteContentItemModal = ({ contentItem, onClose }: DeleteContentItemModalProps) => {
  const { t } = useTranslation();

  const { useDeleteContentItemHook } = useContentRepositoryContext();
  const {
    deleteContentItem,
    deletingContentItem,
    deleteContentItemSuccess,
    deleteContentItemError,
  } = useDeleteContentItemHook;

  const handleDeleteSection = () => {
    deleteContentItem(contentItem);
  };

  if (deleteContentItemSuccess) {
    return <SuccessModal onComplete={onClose} text={t("repository_item_delete_successful")} />;
  }

  return (
    <div data-testid="delete-content-item-modal">
      <DeleteModal
        title={t("repository_item_delete_title")}
        onCloseModal={onClose}
        onDelete={handleDeleteSection}
        isLoading={deletingContentItem}
        error={deleteContentItemError}
        description={t("repository_item_delete_description")}
      />
    </div>
  );
};

export default DeleteContentItemModal;
