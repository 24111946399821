import { contentClient, DraftFile, fileStore } from "@eolas-medical/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRunOnMountUnmount } from "Hooks";

const makeGetDraftFileQueryKey = (fileId: string | null, timeStamp?: string) => [
  "getDraftFile",
  fileId,
  timeStamp,
];

export const useGetDraftFile = (fileId: string | null, timeStamp?: string) => {
  const queryClient = useQueryClient();

  const { data, isFetching, isError } = useQuery({
    queryKey: makeGetDraftFileQueryKey(fileId, timeStamp),
    queryFn: async (): Promise<
      { hasDrafts: true; draftFile: DraftFile } | { hasDrafts: false; draftFile: null }
    > => {
      if (!fileId) {
        throw new Error("useGetDraftFile: No fileId provided. This should not happen");
      }
      const file = fileStore.getFile(fileId);
      if (!file) {
        throw new Error("useGetDraftFile: No file found in fileStore");
      }
      if (!file.hasDrafts) {
        return { hasDrafts: false, draftFile: null };
      }
      const draftFile = await contentClient.getDraftFile({
        contentId: file.id,
        mainSectionId: file.mainSectionID,
      });
      return { hasDrafts: true, draftFile };
    },
    enabled: Boolean(fileId),
    staleTime: 50000,
    cacheTime: 50000,
  });

  useRunOnMountUnmount({
    onUnmount: () => {
      if (!fileId) {
        return;
      }
      queryClient.removeQueries(makeGetDraftFileQueryKey(fileId, timeStamp));
    },
  });

  return { data, isFetching: !data && isFetching, isError };
};
