import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, RouteComponentProps } from "react-router-dom";
import Sections from "./pages/Sections/Sections";
import Files from "./pages/Files/Files";
import { FileViewer } from "./pages/Files/FileViewer";
import { useMainSectionVisit } from "Hooks";
import { MeLevelSection } from "@eolas-medical/core";

const MyFiles: React.FC<RouteComponentProps> = ({ match: { path } }: RouteComponentProps) => {
  useMainSectionVisit({
    mainSectionId: MeLevelSection.myFiles,
  });

  return (
    <ErrorBoundary FallbackComponent={() => <div></div>}>
      <Route exact path={path} component={Sections} />
      <Route exact path={`${path}/:sectionId`} component={Files} />
      <Route exact path={`${path}/file/:fileId`} component={FileViewer} />
    </ErrorBoundary>
  );
};

export default MyFiles;
