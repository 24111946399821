import { ChildReference } from "@eolas-medical/core";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { makeShouldIncludeSectionHelpers } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useMemo } from "react";
import { ActiveTab } from "Utilities/types";

export const useGetMainSectionAdminAccess = ({
  activeTab,
  isOnAdminView,
}: {
  activeTab: ActiveTab;
  isOnAdminView: boolean;
}) => {
  const adminStatus = useGetAdminStatus({ activeTab });
  const limitedAccess = useGetLimitedAccess({ activeTab });
  const filterByAdminStatus = useMemo(() => {
    const { filterFnForChildren } = makeShouldIncludeSectionHelpers(limitedAccess);
    return (childRef: ChildReference) => {
      if (!isOnAdminView) {
        return true;
      }
      if (adminStatus === "limitedAdmin") {
        return filterFnForChildren(childRef.id);
      }
      return true;
    };
  }, [isOnAdminView, limitedAccess, adminStatus]);

  return { filterByAdminStatus };
};
