import { Redirect, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PageContainer } from "Components";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { BaseAppRoute } from "Utilities/types";
import { MeTabRoutes } from "../Pages/MeTab/MeTabRoutes";
import { KnowledgeRoutes } from "Pages/Knowledge";
import SpacesRoutes from "Pages/Spaces/SpacesRoutes";
import { FileViewerRoutes } from "Pages/FileViewer/FileViewerRoutes";
import { LinkedDocumentsViewer } from "Pages/FileViewer/LinkedDocumentsViewer";
import { CheckSpaceAccessRoutes } from "Pages/Spaces/pages/CheckSpaceAccess/CheckSpaceAccessRoutes";
import useUserDetails from "Hooks/useUserDetails";
import { LinkedKnowledgeDocumentsViewer } from "Pages/FileViewer/LinkedKnowledgeDocumentsViewer";

export const MainRouter = observer(() => {
  const { hasKnowledgeTab } = useEolasNavigation();
  const { isSharedAccount } = useUserDetails();

  return (
    <>
      <PageContainer>
        <Switch>
          <Route exact path="/" component={SpacesRoutes} />
          <Route
            path={`/${BaseAppRoute.spaces}/linked-documents/:type`}
            component={LinkedDocumentsViewer}
          />
          <Route
            path={`/${BaseAppRoute.organisation}/linked-documents/:type`}
            component={LinkedDocumentsViewer}
          />
          <Route
            path={`/${BaseAppRoute.knowledge}/linked-documents/:type`}
            component={LinkedKnowledgeDocumentsViewer}
          />
          <Route path={"/check-space-access"} component={CheckSpaceAccessRoutes} />
          <Route path={`/${BaseAppRoute.spaces}`} component={SpacesRoutes} />
          <Route path={`/${BaseAppRoute.organisation}`} component={SpacesRoutes} />

          {!isSharedAccount && (
            <Route path={`/${BaseAppRoute.myProfile}`} component={MeTabRoutes} />
          )}

          {hasKnowledgeTab && (
            <Route path={`/${BaseAppRoute.knowledge}`} component={KnowledgeRoutes} />
          )}

          <Route path={`/${BaseAppRoute.viewer}`} component={FileViewerRoutes} />

          <Redirect to="/" />
        </Switch>
      </PageContainer>
    </>
  );
});
