import { useState } from "react";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { AddFlashcardItemModalState } from "../types";
import { FlashcardFormStep } from "./useFormStepConfig";
import { FlashCardHeroImage, userStore } from "@eolas-medical/core";

export const useAddFlashcardItemActions = () => {
  const [wizardState, setWizardState] = useState<AddFlashcardItemModalState>();

  const user = userStore.userData;
  const { useAddFlashcardItemHook, shouldAllowBlankHeroImageSelection } =
    useContentRepositoryContext();

  const {
    addFlashcardItem,
    addingFlashcardItem,
    addFlashcardItemError,
    addFlashcardItemSuccess,
    addFlashcardItemProgress,
  } = useAddFlashcardItemHook;

  const handleAddFlashcardDetails = (data: FlashcardFormStep) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        ...data,
        specialty: data.specialty ? data.specialty?.map((s) => s.value) : [],
        queriesForHit: data.queriesForHit
          ? data.queriesForHit.map((item) => item.value)
          : undefined,
        documentIdentifiers: data.documentIdentifiers
          ? data.documentIdentifiers.map((item) => item.value)
          : undefined,
      };
    });
  };

  const handleAddFlashcardContent = (content: string) => {
    setWizardState((prevState) => ({
      ...prevState,
      content,
    }));
  };

  const handleSubmitFlashcardImageStep = async ({
    image,
    imageUrl,
    isDraft,
  }: {
    image?: File;
    imageUrl?: string;
    isDraft?: boolean;
  }) => {
    let heroImageUrl = imageUrl || "";
    if (shouldAllowBlankHeroImageSelection && !image && !imageUrl) {
      heroImageUrl = FlashCardHeroImage.blankImage;
    }
    setWizardState((prevState) => ({
      ...prevState,
      image,
      heroImageUrl,
    }));
    addFlashcardItem({
      name: wizardState?.title || "",
      description: wizardState?.description,
      flashcardContent: wizardState?.content || "",
      specialty: wizardState?.specialty,
      queriesForHit: wizardState?.queriesForHit,
      documentIdentifiers: wizardState?.documentIdentifiers,
      imageUrl: heroImageUrl ? heroImageUrl : undefined,
      itemType: "flashcard",
      file: image,
      createdBy: user.id || "",
      isDraft,
    });
  };

  return {
    wizardState,
    addFlashcardItemError,
    addingFlashcardItem,
    addFlashcardItemSuccess,
    addFlashcardItemProgress,
    onAddFlashcardDetails: handleAddFlashcardDetails,
    onAddFlashcardContent: handleAddFlashcardContent,
    onSubmitFlashcardImage: handleSubmitFlashcardImageStep,
    shouldAllowBlankHeroImageSelection,
  };
};
