import { useTranslation } from "react-i18next";
import { FallbackProps } from "react-error-boundary";
import { GenericError } from "UIKit/GenericError/GenericError";

export type ErrorFallbackProps = FallbackProps;

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  const { t } = useTranslation();

  return (
    <GenericError
      errorButtonText={t("error_fallback_button")}
      errorDescription={t("error_fallback_description")}
      errorTitle={t("error_fallback_title")}
      onClick={resetErrorBoundary}
    />
  );
};
