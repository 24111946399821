import React from "react";
import { useTranslation } from "react-i18next";
import { AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";

import {
  Input,
  Button,
  Title,
  Textarea,
  ModalBody,
  FormError,
  FilePicker,
  FormElement,
  ProgressBar,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useIsScrollable, useAllowedFileTypes } from "Hooks";
import { useAddEolasFile } from "../AddEolasFile/useAddEolasFile";

export interface AddNewsItemModalProps {
  onCloseModal(): void;
  mainSectionID?: AppLevelSection | OrganisationLevelSection;
}

export const AddNewsItemModal: React.FC<AddNewsItemModalProps> = ({
  onCloseModal,
  mainSectionID = AppLevelSection.newsFeed,
}: AddNewsItemModalProps) => {
  const { t } = useTranslation();
  const { ref, ScrollHint } = useIsScrollable();
  const allowedFileTypes = useAllowedFileTypes();

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    mainSectionID,
  });

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text={`${mainSectionID}_add_file_success`} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t(`${mainSectionID}_add_item_modal_title`)}</Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col space-y-2 add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              control={control}
              labelClassName="font-semibold"
              label={t(`${mainSectionID}_news_item_name_label`)}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  disabled={isLoading}
                  data-testid="itemName"
                  placeholder={t(`${mainSectionID}_news_item_name_placeholder`)}
                />
              )}
            </FormElement>
            <FormElement
              required
              control={control}
              id="description"
              labelClassName="font-semibold"
              label={t(`${mainSectionID}_news_item_content_label`)}
            >
              {(field) => (
                <Textarea
                  {...field}
                  disabled={isLoading}
                  data-testid="itemContent"
                  placeholder={t(`${mainSectionID}_news_item_content_placeholder`)}
                />
              )}
            </FormElement>
            <FilePicker
              isDisabled={isLoading}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              onSelectType={onSelectFileType}
              error={formErrors.key?.message}
              required={filePickerType !== ""}
              allowedFileTypes={allowedFileTypes}
            />

            <div className="flex justify-center items-center">
              <Button
                type="submit"
                size="lg"
                disabled={isLoading}
                isLoading={isLoading}
                className="w-full md:w-1/3"
              >
                {t(`${mainSectionID}_add_item_modal_button`)}
              </Button>
            </div>
            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
