import { EventForm, EventType } from "modules/portfolio/types";
import { getEventForm, getEventFormTemplate } from "../client/portfolio.client";
import { useEolasQuery, QueryEnvironment } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { mapEventForm } from "../helpers";

interface UseEventForm {
  eventForm?: EventForm;
  eventFormLoading: boolean;
}

const useEventForm = (eventType: EventType, eventId?: string, ownerId?: string): UseEventForm => {
  const getForm = () => {
    if (!eventId) {
      return () => getEventFormTemplate(eventType);
    }

    return () => getEventForm(eventId, ownerId);
  };

  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: getForm(),
    queryKey: eventId ? portfolioKeys.detail(eventId, ownerId) : portfolioKeys.template(eventType),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), // 15 mins
    },
  });

  return { eventForm: data ? mapEventForm(data) : data, eventFormLoading: isLoading, ...rest };
};

export default useEventForm;
