import { KnowledgeContentDomain, knowledgeContentClient, sectionStore } from "@eolas-medical/core";
import { useQuerySearch } from "shared/hooks/useQuerySearch/useQuerySearch";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { mapToContentSection } from "modules/contentRepository/helpers";
import { ContentSection } from "modules/contentRepository/types";

const useSearchKnowledgeContentSections = ({
  parentId,
  domain,
  onSearchContentSection,
}: {
  parentId: string | null;
  domain: KnowledgeContentDomain;
  onSearchContentSection: (searchValue: string, noHits?: number) => void;
}) => {
  const communityId = sectionStore.data.community?.id;

  const {
    files,
    isLoading,
    searchInput,
    searchValue,
    onClearSearch,
    onSearch,
    onSearchInputChange,
  } = useQuerySearch<ContentSection>({
    key: knowledgeContentKeys.items(parentId ?? "", domain),
    queryFn: async () => {
      if (!parentId) {
        throw new Error("ParentId is null");
      }
      const searchResponse = await knowledgeContentClient.search({
        query: searchValue,
        ownerId: communityId || "",
        type: "Section",
        parentId,
      });
      return searchResponse.sections.map(mapToContentSection);
    },
    options: {
      staleTime: 100000,
    },
    onSearchCallback: onSearchContentSection,
  });

  return {
    searchedSections: files,
    searchingSections: isLoading,
    searchValue,
    searchInput,
    onClearSearch,
    onSearch,
    onSearchInputChange,
  };
};

export default useSearchKnowledgeContentSections;
