import { InnerPageWrapper, Loader, NavButton, PageTitle } from "UIKit";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { removeLastUrlPath } from "Utilities";
import { SearchSortList } from "shared/components/Lists";
import useMedicationSections, {
  MixedMedicationSubsection,
} from "modules/medications/data/useMedicationSections";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import MedicationsHighlightsCarousel from "./components/MedicationsHighlightsCarrousel/MedicationsHighlightsCarrousel";
import useMedicationsMainSection from "modules/medications/data/useMedicationsMainSection";
import ContentSectionTile from "shared/pages/ContentRepository/ContentSections/components/ContentSectionTile/ContentSectionTile";
import { isMedicationSubsection } from "modules/medications/typeguards";
import { ContentSection } from "modules/contentRepository/types";
import { makeBnfRoute } from "./pages/Bnf/routes/BnfRoutes";
import { mapToContentSection } from "modules/contentRepository/helpers";
import { mapFromCommunityMedicationSubsection } from "modules/medications/helpers";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";
import { sectionStore } from "@eolas-medical/core";

const Medications = observer(() => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const { url } = useRouteMatch();
  const { replace, push } = useHistory();
  const medicationSection = useMedicationsMainSection();
  const mainsectionId = medicationSection?.id || "";
  const { medicationSections, medicationSectionsLoading } = useMedicationSections(mainsectionId);
  const { name } = sectionStore.getChildReferenceOfSection(mainsectionId) ?? {};
  const backUrl = removeLastUrlPath(url, 1);

  const isAdmin = !!flags[LDFlagNames.KNOWLEDGE_ADMIN_ENABLED];

  const renderItem = useCallback(
    (section: MixedMedicationSubsection) => {
      let contentSection: ContentSection;
      let handleClickSection: (section: ContentSection) => void;
      let variant: ContentSectionVariants;

      if (isMedicationSubsection(section)) {
        contentSection = mapFromCommunityMedicationSubsection(section);
        handleClickSection = (contentSection: ContentSection) => {
          if (contentSection) {
            push(
              makeBnfRoute({
                routeKey: section.id,
              }),
            );
          }
        };
        variant = "titleAndIcon";
      } else {
        contentSection = mapToContentSection(section);
        handleClickSection = (contentSection: ContentSection) => {
          push(`${url}/pharmaResources/${encodeURIComponent(contentSection.routeId)}`);
        };
        variant = "titleIconAndDescription";
      }

      return (
        <div id={contentSection.id} data-testid="content-section-list-item">
          <ContentSectionTile
            section={contentSection}
            onClick={handleClickSection}
            variant={variant}
          />
        </div>
      );
    },
    [push, url],
  );

  return (
    <>
      <InnerPageWrapper>
        <div className="flex flex-col justify-center">
          <PageTitle className="my-4" title={name || t("medications_page_title")} />
          <NavButton onClick={() => replace(backUrl)} />
        </div>
        {medicationSectionsLoading ? (
          <Loader className="pt-10vh bg-transparent" />
        ) : (
          <>
            <MedicationsHighlightsCarousel isAdmin={isAdmin} />
            <div data-testid="content-section-list">
              <SearchSortList<MixedMedicationSubsection>
                items={medicationSections}
                renderItem={renderItem}
                isDragable={false}
              />
            </div>
          </>
        )}
      </InnerPageWrapper>
    </>
  );
});

export default Medications;
