import { Route, Switch, useRouteMatch } from "react-router-dom";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import ManageExpiredItems from "./pages/ManageExpiredItems/ManageExpiredItems";
import SpaceContentSections from "./pages/SpaceContentSections/SpaceContentSections";
import SpaceContentItems from "./pages/SpaceContentItems/SpaceContentItems";
import SpaceContentRepositoryProvider from "./SpaceContentRepositoryProvider";
import useSpaceContentRepositoryParams from "./hooks/useSpaceContentRepositoryParams";
import { sectionStore } from "@eolas-medical/core";

const SubsectionComponent = () => {
  const { subSectionId } = useSpaceContentRepositoryParams();

  const section = sectionStore.getSection(subSectionId);

  return section.childrenType === "section" ? <SpaceContentSections /> : <SpaceContentItems />;
};

const SpaceContentRepository = () => {
  const { path } = useRouteMatch();
  const { contentRepositoryId } = useSpaceContentRepositoryParams();

  const { contentRepository } = useContentRepository(contentRepositoryId);

  if (contentRepository.childrenType === "section") {
    return (
      <SpaceContentRepositoryProvider>
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => (
              <SpaceContentRepositoryProvider>
                <SpaceContentSections />
              </SpaceContentRepositoryProvider>
            )}
          />
          <Route
            exact
            path={`${path}/expired-items`}
            render={() => (
              <SpaceContentRepositoryProvider>
                <ManageExpiredItems contentRepositoryId={contentRepositoryId} />
              </SpaceContentRepositoryProvider>
            )}
          />
          <Route
            exact
            path={`${path}/:subSectionIds*`}
            render={() => (
              <SpaceContentRepositoryProvider>
                <SubsectionComponent />
              </SpaceContentRepositoryProvider>
            )}
          />
        </Switch>
      </SpaceContentRepositoryProvider>
    );
  }

  return (
    <SpaceContentRepositoryProvider>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <SpaceContentRepositoryProvider>
              <SpaceContentItems />
            </SpaceContentRepositoryProvider>
          )}
        />
        <Route
          exact
          path={`${path}/expired-items`}
          render={() => (
            <SpaceContentRepositoryProvider>
              <ManageExpiredItems contentRepositoryId={contentRepositoryId} />
            </SpaceContentRepositoryProvider>
          )}
        />
      </Switch>
    </SpaceContentRepositoryProvider>
  );
};

export default SpaceContentRepository;
