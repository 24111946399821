import { SendOutlineIcon } from "Assets";
import { IconButton, Text } from "UIKit";
import React from "react";

interface ImageCardProps {
  imageUrl: string;
  title: string;
  description?: string;
  onClick?: () => void;
  onShare?: () => void;
}

const ImageCard: React.FC<ImageCardProps> = ({
  imageUrl,
  title,
  description,
  onClick,
  onShare,
}: ImageCardProps) => {
  const handleShare = (e: React.MouseEvent) => {
    if (onShare) {
      e.preventDefault();
      e.stopPropagation();
      onShare();
    }
  };

  return (
    <div className="mx-auto bg-white rounded-lg overflow-hidden shadow-lg h-64" onClick={onClick}>
      <img className="w-full object-cover h-32" src={imageUrl} alt={title} />
      <div className="flex flex-col p-4">
        <div className="flex flex-row justify-between">
          <Text level={1} className="line-clamp-1 self-center">
            {title}
          </Text>
          {onShare && (
            <IconButton
              type="button"
              variant="rounded"
              size="sm"
              icon={<SendOutlineIcon />}
              onClick={handleShare}
              // Rotated svgs can make the svg look off-center, so we need to adjust the position slightly
              className="[&_svg]:-translate-x-px [&_svg]:translate-y-px bg-transparent self-start"
            />
          )}
        </div>
        {description && (
          <Text level={2} className="text-grey-600 line-clamp-3">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
};

export default ImageCard;
