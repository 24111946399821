import { DomainIcon, FilesIcon, FlashcardIcon, MP4Icon, PDFIcon } from "Assets";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SupportedFileType } from "@eolas-medical/core";

import { Text } from "../../../Typography";

export interface FileTypeOptionProps {
  type: SupportedFileType;
  isDisabled?: boolean;
  isSelected?: boolean;
  onSelect: (type: SupportedFileType) => void;
}

export const FileTypeOption = ({ type, onSelect, isSelected = false }: FileTypeOptionProps) => {
  const { t } = useTranslation();

  const Icon = useMemo(() => {
    switch (type) {
      case "pdf":
        return <PDFIcon width={32} height={32} />;
      case "mp4":
        return <MP4Icon width={32} height={32} />;
      case "link":
        return <DomainIcon width={32} height={32} />;
      case "blob":
        return <FilesIcon width={32} height={32} />;
      case "flashcard":
        return <FlashcardIcon width={32} height={32} />;
      default:
        return null;
    }
  }, [type]);

  const label = useMemo(() => {
    switch (type) {
      case "pdf":
        return t("filePicker_pdf");
      case "mp4":
        return t("filePicker_mp4");
      case "link":
        return t("filePicker_link");
      case "blob":
        return t("filePicker_blob");
      case "flashcard":
        return t("filePicker_flashcard");
      default:
        return "";
    }
  }, [type, t]);

  return (
    <button
      type="button"
      onClick={() => onSelect(type)}
      className={`flex items-center h-20 px-2 sm:px-4 rounded-md border-2 border-grey-300 hover:bg-grey-200 flex-col justify-center ${
        isSelected ? "bg-blue-100" : "bg-white"
      }`}
    >
      {Icon}

      <Text level={3} className="font-semibold mt-2">
        {label}
      </Text>
    </button>
  );
};
