import { meTabIcons } from "Assets/Icons/meTabIcons";
import { ActionButtonType } from "modules/portfolio/types";
import { ButtonColorScheme, ButtonVariant } from "UIKit/Button/types";

type ActionButtonsConfig = {
  [key in ActionButtonType]: {
    icon?: keyof typeof meTabIcons | null;
    text: string;
    buttonColor?: ButtonColorScheme;
    variant?: ButtonVariant;
    textColor?: string;
    onClick?: () => void;
    shouldShowErrorMessage?: boolean;
  };
};

const useActionButtonConfig = (): ActionButtonsConfig => {
  return {
    [ActionButtonType.ADD_SECTION]: {
      text: "Add Section",
      buttonColor: "blue",
      variant: "solid",
    },
    [ActionButtonType.SAVE_DRAFT]: {
      text: "Save Draft",
      buttonColor: "green",
      variant: "solid",
    },
    [ActionButtonType.GENERATE_FEEDBACK]: {
      text: "Submit form & Generate QR Code",
      buttonColor: "blue",
      variant: "solid",
      shouldShowErrorMessage: true,
    },
    [ActionButtonType.DELETE_FORM]: {
      text: "Delete Form",
      buttonColor: "red",
      variant: "solid",
    },
    [ActionButtonType.DECLINE_FEEDBACK]: {
      text: "Decline",
      buttonColor: "white",
      variant: "solid",
      textColor: "text-red-500",
    },
    [ActionButtonType.VIEW_QR_CODE]: {
      icon: "QrCode",
      text: "View QR Code",
      buttonColor: "white",
      variant: "solid",
    },
    [ActionButtonType.COMPLETE_EVENT]: {
      text: "Complete Event",
      buttonColor: "blue",
      variant: "solid",
      shouldShowErrorMessage: true,
    },
    [ActionButtonType.COMPLETE_EVENT_WITHOUT_FEEDBACK]: {
      text: "Complete Event without Feedback",
      buttonColor: "blue",
      variant: "solid",
    },
    [ActionButtonType.VIEW_FEEDBACK]: {
      text: "View Feedback",
      buttonColor: "blue",
      variant: "solid",
    },
    [ActionButtonType.SUBMIT_FEEDBACK]: {
      text: "Submit Feedback",
      buttonColor: "blue",
      variant: "solid",
    },
    [ActionButtonType.SHARE_ATTACHMENTS_TOGGLE]: {
      text: "Share attachments with assessor",
    },
    [ActionButtonType.OPTIONAL_ASSIGN_ASSESSOR_AND_SUBMIT]: {
      text: "Continue",
      buttonColor: "blue",
      variant: "solid",
      shouldShowErrorMessage: true,
    },
    [ActionButtonType.MANDATORY_ASSIGN_ASSESSOR_AND_SUBMIT]: {
      text: "Assign Assessor & Submit Event",
      buttonColor: "blue",
      variant: "solid",
    },
    [ActionButtonType.EXPORT_EVENT_PDF]: {
      icon: "ExportIcon",
      text: "Export Event as PDF",
      variant: "outline",
      buttonColor: "blue",
    },
    [ActionButtonType.DELETE_FEEDBACK_AND_EVENT]: {
      text: "Delete Event",
      variant: "solid",
      buttonColor: "red",
    },
  };
};

export default useActionButtonConfig;
