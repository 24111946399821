import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import "./toggle.css";
import { Text } from "../../Typography";

export interface ToggleProps {
  id?: string;
  label?: string;
  checked: boolean;
  className?: string;
  labelClassName?: string;
  labelPosition?: "right" | "left";
  onChange(value: boolean): void;
  isDisabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  id,
  label,
  checked,
  className,
  labelClassName,
  onChange,
  isDisabled = false,
  labelPosition = "right",
}: ToggleProps) => {
  const labelOrderClass = labelPosition === "left" ? "order-1" : "order-2";
  const inputOrderClass = labelPosition === "left" ? "order-2" : "order-1";

  return (
    <div className="flex items-center gap-2">
      {label && (
        <Text
          level={2}
          htmlFor={id}
          element="label"
          className={tw(`cursor-pointer ${labelClassName} ${labelOrderClass}`)}
        >
          {label}
        </Text>
      )}

      <input
        id={id}
        type="checkbox"
        disabled={isDisabled}
        checked={checked}
        onChange={() => onChange(!checked)}
        className={tw(`
          eolas-toggle-input bg-grey-200 checked:bg-blue-100 h-6 w-10 cursor-pointer ${className} ${inputOrderClass}
        `)}
      />
    </div>
  );
};
