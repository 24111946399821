import { useMemo } from "react";
import { userStore } from "@eolas-medical/core";
import { ActiveTab } from "Utilities/types";
import { sanitiseLimitedAdminArray } from "../functions/filterLimitedSections";

export const useGetLimitedAccess = ({ activeTab }: { activeTab: ActiveTab }): string[] => {
  const { limitedAccess: spaceLimitedAccess } = userStore.appUserData;
  const orgLimitedAccess = userStore.orgUserData?.limitedAccess;
  return useMemo(() => {
    if (activeTab === "spaces") {
      return sanitiseLimitedAdminArray(spaceLimitedAccess);
    }
    if (activeTab === "organisation") {
      return sanitiseLimitedAdminArray(orgLimitedAccess);
    }
    return [];
  }, [spaceLimitedAccess, orgLimitedAccess, activeTab]);
};
