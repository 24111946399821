import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { userStore } from "@eolas-medical/core";

import { Modal } from "UIKit";

import { TnCModal } from "../components";
import useUpdateUserProfile from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserProfile";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { parseISO } from "date-fns";
import { errorStore } from "Stores/ErrorStore";

export const SelfSetup = observer(() => {
  const { userData, userSession, updateData, userID } = userStore;
  const { flags } = useLaunchDarkly();
  const tncLastUpdated = flags[LDFlagNames.TNC_LAST_UPDATED] ?? "";

  const [modal, setModal] = useState<ReactNode>(null);
  const [closeIconEnabled, setCloseIconEnabled] = useState(false);

  const hasAccessToken = Boolean(userSession.inviteId || userSession.accessLinkId);

  const { updateUser, updatingUser, updateUserError } = useUpdateUserProfile();

  const handleCloseModal = useCallback(() => setModal(null), [setModal]);

  const handleTnCSuccess = useCallback(() => {
    if (userID) {
      const completedTour = new Date().toISOString();
      updateUser(
        { userId: userID, userData: { completedTour } },
        {
          onSuccess: (user) => {
            updateData({ user });
            handleCloseModal();
          },
          onError: () => {
            updateData({ user: { ...userData, completedTour } });
            setCloseIconEnabled(true);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseModal, userData, userID, updateUser]);

  useEffect(() => {
    const completedTourDate = userData.completedTour ? new Date(userData.completedTour) : null;
    let tncUpdatedDate = null;
    try {
      tncUpdatedDate = tncLastUpdated ? parseISO(tncLastUpdated) : null;
    } catch (error) {
      console.error("Failed to parse tncLastUpdated date:", error);
      errorStore.captureError({
        error: new Error(
          "LD string date cannot be parsed! Check the date string of flag 'terms-and-conditions-last-updated'",
        ),
        source: "user",
      });
    }

    const shouldShowTermsModal =
      userID &&
      (!userData.completedTour ||
        (completedTourDate && tncUpdatedDate && completedTourDate < tncUpdatedDate)) &&
      !hasAccessToken;

    if (shouldShowTermsModal && !modal) {
      setModal(
        <TnCModal
          onSubmit={handleTnCSuccess}
          isLoading={updatingUser}
          error={updateUserError}
          termsUpdated={!!completedTourDate && tncUpdatedDate && completedTourDate < tncUpdatedDate}
        />,
      );
    }
  }, [
    userID,
    userData,
    handleTnCSuccess,
    updatingUser,
    hasAccessToken,
    updateUserError,
    tncLastUpdated,
    modal,
  ]);

  return (
    <Modal
      open={!!modal}
      hasCloseIcon={closeIconEnabled}
      onClose={handleCloseModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {modal}
    </Modal>
  );
});
