import { ContentItem, EolasSupportedMiscellaneousType } from "@eolas-medical/core";

/**
 * NOTE: this only exists because we are mapping to Knowledge Content items
 * This is a hack
 */
export const isItemDsmItem = (item: ContentItem) => {
  const type: string | undefined = item.media?.type;
  return type === EolasSupportedMiscellaneousType.DSM;
};
