import { webStore } from "Stores/WebStore";
import { isDev } from "Utilities/helpers";
import { useLocation } from "react-router-dom";

export const usePostLoginRedirect = () => {
  const { pathname, search } = useLocation();

  const postLoginRedirectPath = webStore.postLoginRedirect;

  const capturePostLoginRedirect = () => {
    if (pathname !== "/") {
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.log(
          "Tried to navigate to a protected route when unauthenticated - Redirecting to login and setting postLoginRedirectPath to: ",
          `${pathname}${search}`,
        );
      }
      webStore.setPostLoginRedirect(`${pathname}${search}`);
    }
  };

  return {
    capturePostLoginRedirect,
    postLoginRedirectPath,
  };
};
