import { apple, downloadTheApp, google } from "Assets";
import { Button } from "UIKit";
import React from "react";
import { useTranslation } from "react-i18next";
import { getMobilePlatform } from "Utilities/helpers";
import { appConfig } from "AppTypeConfig";
import { useRunOnMountUnmount } from "Hooks";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents, DeeplinkResolvedPayload } from "@eolas-medical/core";
import { getDeeplinkEntityId, getDeeplinkMainSectionIdentity } from "deeplinking/functions/helpers";
import { isSupportedDeeplinkType } from "deeplinking/functions/typeguards";

const MOBILE_PLATFORM = getMobilePlatform();

type BackgroundType = "organisation" | "space" | "knowledge";

type BackgroundTypeMap = {
  [key in BackgroundType]: string;
};

const backgroundTypeMap: BackgroundTypeMap = {
  organisation: "bg-organisation-background",
  space: "bg-spaces-background",
  knowledge: "bg-knowledge-background",
};

type Props = {
  backgroundType: BackgroundType;
  type: string | null;
  searchString: string;
};

export const DownLoadTheApp = ({ backgroundType, searchString, type }: Props): JSX.Element => {
  const { t } = useTranslation();

  const shouldRenderSingleButton = MOBILE_PLATFORM === "ios" || MOBILE_PLATFORM === "android";

  useRunOnMountUnmount({
    onMount: () => {
      // This will always be true for the condition this component is used in
      if (type && searchString && isSupportedDeeplinkType(type)) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: getDeeplinkEntityId(searchString, type),
          type: "file",
          mainSectionType: getDeeplinkMainSectionIdentity(type),
        });
      }
    },
  });

  return (
    <>
      <div className="flex flex-col justify-start items-center flex-1 w-full">
        <div
          className={`flex flex-col ${backgroundTypeMap[backgroundType]} w-full h-80 relative`}
          style={{
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
            zIndex: "-1",
          }}
        >
          <img
            src={downloadTheApp}
            className="relative z-10 mt-16 justify-center mx-auto"
            style={{
              height: "20rem",
            }}
            alt=""
          />
          <div className="flex flex-col justify-center items-center mx-auto p-6 w-full md:w-5/6">
            <div className={"text-center text-black font-bold text-xl sm:text-3xl"}>
              {t("deep_link_download_the_app_title")}
            </div>
            <div className="flex flex-col mx-auto space-y-4 mb-8 sm:mb-0 text-center">
              {t("deep_link_download_the_app_description")}
              <br />
            </div>
            {shouldRenderSingleButton ? (
              <Button
                color="blue"
                type="button"
                className="self-center sm:w-1/3"
                onClick={() => {
                  window.location.href =
                    MOBILE_PLATFORM === "ios" ? appConfig.appleAppStore : appConfig.googlePlay;
                }}
              >
                {t("deeplink_download_app_button")}
              </Button>
            ) : (
              <div className="flex row space-x-6">
                <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
                  <img src={apple} className="h-12 rounded-md" alt="app-store" />
                </a>

                <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
                  <img src={google} className="h-12 rounded-md" alt="play-store" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
