import { CreateBlobItemDto, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { useState } from "react";

const useAddKnowledgeContentFile = () => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ blobDto, file }: { blobDto: CreateBlobItemDto; file: File }) => {
      const { uploadUrl } = await knowledgeContentClient.createBlobItem(blobDto);

      if (uploadUrl && file) {
        await uploadFileToPresignedS3(uploadUrl, file, setUploadProgress);
      }

      setUploadProgress(100);
    },
  );

  return {
    addFile: mutate,
    addingFile: isLoading,
    addingFileSuccess: isSuccess,
    addingFileError: error instanceof Error ? error.message : "",
    addingFileProgress: uploadProgress,
  };
};

export default useAddKnowledgeContentFile;
