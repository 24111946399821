import React from "react";
import { ProfileVerificationStatus } from "@eolas-medical/core";
import { getVerificationStatusClass } from "Pages/MeTab/helpers";
import { Avatar, Button, IconText, Text } from "UIKit";
import { useTranslation } from "react-i18next";
import { ProfileStatusLabel } from "Pages/MeTab/types";
import { twMerge } from "tailwind-merge";

type ProfileDetailsProps = {
  firstName: string;
  lastName: string;
  imageUrl: string;
  verificationStatus?: ProfileVerificationStatus;
  onClick: () => void;
};

const ProfileDetails = ({
  firstName,
  lastName,
  imageUrl,
  verificationStatus,
  onClick,
}: ProfileDetailsProps) => {
  const { t } = useTranslation();

  const { icon, className } = getVerificationStatusClass(verificationStatus);

  return (
    <div className="space-y-3 relative">
      <div className="relative space-y-4 rounded-2xl">
        <div className="h-40 w-40 md:h-56 md:w-56 rounded-2xl flex justify-center items-center mx-auto border-6 border-white bg-white shadow-2xl">
          <Avatar
            label={`${firstName} ${lastName}`}
            showInitials
            className="text-4xl md:text-6xl rounded-2xl"
            imageUrl={imageUrl}
          />
          {verificationStatus ? (
            <div onClick={onClick} className="absolute bottom-1 overflow-hidden">
              <div className="bg-white rounded-t-2xl w-auto h-10 p-4 flex justify-center">
                <IconText
                  level={2}
                  className={twMerge(className, "w-auto bg-transparent translate-y-1")}
                  icon={icon}
                >
                  {ProfileStatusLabel[verificationStatus]}
                </IconText>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <Text level={1}>{t("my-medical_dashboard_greeting")}</Text>
        <p className="text-3xl md:text-4xl font-extrabold text-center ">{`${firstName} ${lastName}`}</p>
      </div>

      <div className="flex justify-center">
        <Button
          color="white"
          variant="outline"
          className="border-0 bg-opacity-60 hover:bg-opacity-50"
          onClick={onClick}
          iconLeft="EditPencilIcon"
          size="sm"
        >
          <Text level={1} className="ml-2 font-bold">
            {t("my-medical_personal_details_edit_profile")}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default ProfileDetails;
