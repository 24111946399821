import { MonographType } from "@eolas-medical/core";
import { useGetMedicalDevice } from "./useGetMedicalDevice";
import { useProcessItems } from "../../hooks/useProcessItems";

export const useProcessMedicalDevices = (args: { idOrSid: string | null; type: MonographType }) => {
  const { data, isInitialLoading } = useGetMedicalDevice(args);
  const fetchedItems = data?.medicalDevice ?? [];
  const { activeItem, goBackOverride, makeOnClick } = useProcessItems({
    fetchedItems,
  });

  return {
    isInitialLoading,
    activeMedicalDeviceSubsection: activeItem,
    medicalDeviceSections: fetchedItems,
    goBackMedicalDevice: goBackOverride,
    makeOnClick,
    title: activeItem?.title ?? data?.title,
  };
};
