import ReactPlayer from "react-player";

import { EolasFile } from "@eolas-medical/core";
import { InnerModalWrapper, ModalHeader, Title, ModalBody } from "UIKit";

interface VideoModalProps {
  file: EolasFile;
  videoURL: string;
}

export const VideoModal: React.FC<VideoModalProps> = ({ file, videoURL }: VideoModalProps) => {
  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{file.name}</Title>
      </ModalHeader>

      <ModalBody>
        <ReactPlayer
          playing
          controls
          width="unset"
          stopOnUnmount
          url={videoURL}
          data-testid="videoPlayer"
        />
      </ModalBody>
    </InnerModalWrapper>
  );
};
