import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { EolasFile, AppLevelSection } from "@eolas-medical/core";

import { SearchIcon, noFilesFound } from "Assets";
import { GuidelineFileTile } from "UIKit/FileTile";
import { generateS3PublicImageURL } from "Utilities";
import { useMainSectionVisit, useSelectFile, useSearchMainSectionFiles } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";

import {
  Input,
  Modal,
  Button,
  NavButton,
  PageTitle,
  AddButton,
  InnerPageWrapper,
  LottieWithHeader,
} from "UIKit";

import { adminSpaceRoutes } from "../../../SpaceAdminRoutes";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

export const PolicyDocuments: React.FC<RouteComponentProps> = observer(
  ({ history: { push }, match: { url } }) => {
    const { t } = useTranslation();
    const { onSelectFile: selectFile } = useSelectFile();
    const { isAdmin } = useSpacesContext();
    const { spaceSection } = useSpaceSection(AppLevelSection.policyDocuments);

    const { isShareFileEnabled, isShareModalOpen, closeShareModal, handleOnShareFile, shareModal } =
      useShareFile();
    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    useMainSectionVisit({ mainSectionId: AppLevelSection.policyDocuments });

    const {
      filteredFiles: guidelines,
      onSearchChange,
      searchValue,
      isLoading,
    } = useSearchMainSectionFiles(AppLevelSection.policyDocuments);

    const { modal, onCloseModal, onAddSection, onEditSection, onDeleteSection } =
      useSectionManagement(spaceSection.id);

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      onCloseModal();
    };

    const onSelectFile = useCallback(
      (file: EolasFile) => {
        selectFile(file);
      },
      [selectFile],
    );

    const noGuidelinesFound = searchValue && guidelines.length === 0 && !isLoading;
    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(AppLevelSection.policyDocuments)} />
          <NavButton to={url.replace(`/${AppLevelSection.policyDocuments}`, "")} />

          {!isAdmin && (
            <Input
              size="lg"
              value={searchValue}
              onChange={onSearchChange}
              iconLeft={<SearchIcon />}
              data-testid="contact-search-all-contacts"
              placeholder={t("guidelines_search_all_placeholder")}
              className="bg-white border-grey-200"
            />
          )}

          {isAdmin && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <AddButton onClick={onAddSection} data-testid="add-new-subSection">
                {t("addNewSection_button")}
              </AddButton>

              <Button
                size="lg"
                color="grey"
                weight="bold"
                variant="outline"
                onClick={() => push(adminSpaceRoutes.policyDocumentsManageExpired)}
              >
                {t("policy_manage_expired_documents")}
              </Button>
            </div>
          )}

          {noGuidelinesFound ? (
            <LottieWithHeader
              animation={noFilesFound}
              lottieOptions={{ loop: true }}
              text={t("contacts_no_contacts")}
            />
          ) : guidelines.length > 0 ? (
            <div className="flex flex-col items-stretch ">
              {guidelines.map((guideline) => (
                <GuidelineFileTile
                  isAdmin={false}
                  key={guideline.id}
                  eolasFile={guideline}
                  onSelectFile={onSelectFile}
                  onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
                  organisationImageURL={generateS3PublicImageURL(guideline.moreInfo!)}
                  showRemoveFavouriteModal={showRemoveFavouriteModal}
                />
              ))}
            </div>
          ) : (
            <SectionList
              isAdmin={isAdmin}
              sectionID={spaceSection.id}
              onEdit={onEditSection}
              onAddItem={onAddSection}
              onDelete={onDeleteSection}
            />
          )}
        </InnerPageWrapper>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
      </>
    );
  },
);
