import { Route, Redirect, Switch } from "react-router-dom";

import { LDFlagNames } from "Utilities/types";
import { MaintenancePage } from "Pages/Maintenance";
import { SignUp } from "Pages/Authentication/pages";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";
import Authentication from "Pages/Authentication/Authentication";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { observer } from "mobx-react-lite";
import { usePostLoginRedirect } from "shared/hooks/usePostLoginRedirect/usePostLoginRedirect";
import { useRunOnMountUnmount } from "Hooks";

export const AuthenticationRoutes = observer((): JSX.Element => {
  const { flags } = useLaunchDarkly();
  const { capturePostLoginRedirect } = usePostLoginRedirect();
  const { isNewUser: isNewUserStr, userEmail } = useGetSearchParams({
    paramList: ["isNewUser", "userEmail"],
  });

  useRunOnMountUnmount({
    onMount: capturePostLoginRedirect,
  });

  const isNewUser = isNewUserStr === "true";

  if (flags[LDFlagNames.WEB_MAINTENANCE]) {
    // allow access to the login page
    return (
      <Switch>
        <Route exact path="/" component={MaintenancePage} />
        <Route exact path="/login" component={Authentication} />
        <Redirect to="/" />
      </Switch>
    );
  }

  if (isNewUser) {
    return (
      <Switch>
        <Redirect to={{ pathname: "/signup", state: { userEmail } }} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/" component={Authentication} />
      <Route exact path={"/signup"} component={SignUp} />
      <Redirect to={{ pathname: "/", state: { userEmail } }} />
    </Switch>
  );
});
