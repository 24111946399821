import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { createResourcesManifestFromHtml, mapToUpdateEolasEditorDto } from "../helpers";
import { useState } from "react";
import { EditEolasEditorItemDto } from "../types";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface useEditEolasEditorItemProps<T, R> {
  mainSectionId: string;
  onSuccess: (params?: T) => Promise<R>;
}

const useEditEolasEditorItem = <T, R>({
  mainSectionId,
  onSuccess,
}: useEditEolasEditorItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const editContentItem = async (dto: EditEolasEditorItemDto) => {
    const { id, eolasEditorContent, ...rest } = dto;

    const resourcesManifest = eolasEditorContent
      ? await createResourcesManifestFromHtml(eolasEditorContent)
      : undefined;

    const eolasEditorItemDto = mapToUpdateEolasEditorDto({
      contentItem: {
        eolasEditorContent,
        resourcesManifest,
        ...rest,
      },
      isKnowledge: false,
    });

    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const result: any = await contentClient.updateContentItem({
      mainSectionId,
      contentId: id,
      contentDto: eolasEditorItemDto,
    });

    setUploadProgress(50);

    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentItemDto: EditEolasEditorItemDto) => editContentItem(contentItemDto),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  return {
    editEolasEditorItem: mutate,
    editingEolasEditorItem: isLoading,
    editEolasEditorItemSuccess: isSuccess,
    editEolasEditorItemError: error instanceof Error ? error.message : "",
    editEolasEditorItemProgress: uploadProgress,
  };
};

export default useEditEolasEditorItem;
