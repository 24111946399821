import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  UserData,
  specialtyOptions,
  seniorityOptions,
  jobTitleOptions,
  JobTitleOptions,
  SeniorityOptions,
  SpecialtyOptions,
  userStore,
} from "@eolas-medical/core";
import {
  Button,
  FormElement,
  ImageUploader,
  InnerModalWrapper,
  Input,
  ModalBody,
  ModalHeader,
  Textarea,
  Title,
} from "UIKit";
import { ProfileIcon } from "Assets/Icons";
import { useTranslation } from "react-i18next";
import useGetLocations from "Hooks/useGetLocations";
import { formatLocation } from "Utilities/helpers";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";

interface ProfileModalState {
  blob: Blob | null;
  error: string | undefined;
}

interface EditProfileModalValues {
  firstName: string | null;
  surname: string | null;
  jobTitle: JobTitleOptions;
  seniority: SeniorityOptions;
  specialty: SpecialtyOptions[];
  aboutMe: string;
  location: {
    label: string;
    value: string;
  };
}

export interface EditProfileModalProps {
  onSubmit: (profileData: UserData, file: { blob: Blob | null }) => void;
  shouldAllowUpdateProfileImage?: boolean;
  onClose?: () => void;
  isLoading: boolean;
  defaultJobTitle?: JobTitleOptions | undefined;
  defaultSeniority?: SeniorityOptions | undefined;
  defaultSpecialties?: SpecialtyOptions[] | undefined;
  errorDraggedImageText?: string;
}

const EditProfileModal = ({
  onSubmit,
  onClose,
  isLoading = false,
  defaultJobTitle,
  defaultSeniority,
  defaultSpecialties,
  shouldAllowUpdateProfileImage = true,
}: EditProfileModalProps) => {
  const { t } = useTranslation();

  const { location, givenName, familyName, photoUrl } = userStore.userData;

  const { getLocations } = useGetLocations();

  const [state, setState] = useState<ProfileModalState>({
    blob: null,
    error: "",
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    surname: yup.string().required("Surname is required"),
    jobTitle: yup.object().required("Job Title is required"),
    seniority: yup.object().required("Seniority is required"),
    specialty: yup.array().min(1, "Specialty is required").nullable(),
    location: yup.object().required("Location is required"),
  });

  const preloadedProfileValues = {
    firstName: givenName,
    surname: familyName,
    jobTitle: defaultJobTitle,
    seniority: defaultSeniority,
    specialty: defaultSpecialties,
    userPhoto: photoUrl,
    location: location ? formatLocation({ location }) : undefined,
  };

  const { control, handleSubmit, formState } = useForm<EditProfileModalValues>({
    defaultValues: preloadedProfileValues,
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;

  const handleEditProfile = useCallback(
    ({
      firstName,
      surname,
      jobTitle,
      seniority,
      specialty,
      location: selectedLocation,
    }: EditProfileModalValues) => {
      const [city, country] = selectedLocation ? selectedLocation.value.split(",") : ["", ""];
      const profileData: Partial<UserData> = {
        givenName: firstName,
        familyName: surname,
        jobTitle: jobTitle.value,
        seniority: seniority.value,
        specialty: specialty.map(({ value }: { label: string; value: string }) => value),
        location: { city, country },
      };
      onSubmit(profileData, state);
    },
    [onSubmit, state],
  );

  const handleBlobChange = useCallback((file: File | null) => {
    setState(() => ({ error: "", blob: file }));
  }, []);

  const handleFetchLocations = async (query: string) => {
    const locations = await getLocations(query);
    return locations.map((location) => {
      return formatLocation({ location });
    });
  };

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={8}>{t("my-medical_personal_details_edit_profile_title")}</Title>
      </ModalHeader>

      <ModalBody>
        <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
          <form
            onSubmit={handleSubmit(handleEditProfile)}
            className="overflow-y-auto grid grid-cols-2 gap-x-6 gap-y-2 p-2"
          >
            <FormElement
              labelClassName="text-base"
              required
              id="firstName"
              control={control}
              label={t("my-medical_personal_details_edit_profile_first_name")}
            >
              {(fieldProps) => {
                const { value, ...rest } = fieldProps;
                return (
                  <Input
                    {...rest}
                    size="md"
                    className="col-start-1 col-end-2 input pl-4 box-content text-base rounded-md"
                    iconLeft={
                      <ProfileIcon className={"h-8 w-8 mr-4 hidden sm:inline text-blue-500"} />
                    }
                    placeholder={t(
                      "my-medical_personal_details_edit_profile_first_name_placeholder",
                    )}
                    value={value}
                  />
                );
              }}
            </FormElement>
            <FormElement
              labelClassName="text-base"
              required
              id="surname"
              control={control}
              label={t("my-medical_personal_details_edit_profile_surname")}
            >
              {(fieldProps) => {
                const { value, ...rest } = fieldProps;
                return (
                  <Input
                    {...rest}
                    data-testid="second-input"
                    size="md"
                    className="col-end-3 input pl-4 box-content rounded-md"
                    iconLeft={
                      <ProfileIcon className={"h-8 w-8 mr-4 hidden sm:inline text-red-500"} />
                    }
                    placeholder={t("my-medical_personal_details_edit_profile_surname_placeholder")}
                    value={value}
                  />
                );
              }}
            </FormElement>
            <div className="col-span-2">
              <FormElement
                labelClassName="text-base"
                required
                id="jobTitle"
                control={control}
                label={t("my-medical_personal_details_edit_profile_job_title")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <EolasSelect
                      {...rest}
                      mode="synchronous"
                      defaultValue={defaultJobTitle}
                      options={jobTitleOptions}
                      isSearchable
                    />
                  );
                }}
              </FormElement>
            </div>

            <div className="col-span-2">
              <FormElement
                labelClassName="text-base"
                required
                id="seniority"
                control={control}
                label={t("my-medical_personal_details_edit_profile_seniority")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <EolasSelect
                      {...rest}
                      mode="synchronous"
                      defaultValue={defaultSeniority}
                      options={seniorityOptions}
                      isSearchable
                    />
                  );
                }}
              </FormElement>
            </div>

            <div className="col-span-2">
              <FormElement
                labelClassName="text-base"
                required
                id="specialty"
                control={control}
                label={t("my-medical_personal_details_edit_profile_specialty")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <EolasSelect
                      {...rest}
                      mode="synchronous"
                      isMulti
                      options={specialtyOptions}
                      isSearchable
                      defaultValue={defaultSpecialties}
                    />
                  );
                }}
              </FormElement>
              <FormElement
                labelClassName="text-base"
                required
                id="location"
                control={control}
                label={t("my-medical_personal_details_edit_profile_location")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <EolasSelect
                      {...rest}
                      mode="asynchronous"
                      placeholder={t(
                        "my-medical_personal_details_edit_profile_location_placeholder",
                      )}
                      isSearchable
                      loadSuggestions={handleFetchLocations}
                      defaultValue={location ? formatLocation({ location }) : undefined}
                    />
                  );
                }}
              </FormElement>
              {shouldAllowUpdateProfileImage ? (
                <FormElement
                  labelClassName="text-base"
                  id="userPhoto"
                  control={control}
                  label={t("my-medical_personal_details_edit_profile_picture")}
                >
                  {(fieldProps) => {
                    const { value, ...rest } = fieldProps;

                    return (
                      <ImageUploader
                        {...rest}
                        croppingEnabled
                        onChange={handleBlobChange}
                        defaultValue={photoUrl || ""}
                        fileToLargeError={t("imagePicker_too_large_error")}
                        error={state.error}
                      />
                    );
                  }}
                </FormElement>
              ) : null}
            </div>

            <div>
              <Button
                type="button"
                disabled={!onClose}
                color="grey"
                onClick={onClose}
                variant="outline"
                className="w-full col-span-1"
                data-testid="cancel-button"
              >
                {t("my-medical_personal_details_edit_profile_cancel_button")}
              </Button>
            </div>

            <div>
              <Button
                isLoading={isLoading}
                type="submit"
                className="w-full col-span-2"
                data-testid="done-button"
                disabled={Boolean(!isDirty && state.blob === null)}
              >
                {t("my-medical_personal_details_edit_profile_confirm_button")}
              </Button>
            </div>
          </form>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default EditProfileModal;
