import { accessClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";

const useGetAccessToken = (spaceId: string | null) => {
  const { data, isLoading } = useQuery({
    queryFn: () => accessClient.getDepartmentAccessToken(spaceId ?? ""),
    queryKey: spacesKeys.spaceAccessToken(spaceId ?? ""),
    enabled: Boolean(spaceId),
  });

  const accessToken = data ? data.accessTokens[0]?.id : undefined;

  return {
    accessToken,
    gettingAccessToken: isLoading,
  };
};

export default useGetAccessToken;
