import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import SpaceImage from "./SpaceImage";
import { useState } from "react";
import { DefaultImage } from "Pages/Spaces/pages/SelectSpace/types";

interface useSpaceDescriptionStepProps {
  onSubmit: ({ image, imageUrl }: { image?: File; imageUrl?: string }) => void;
  defaultImageUrl?: string;
  defaultImage?: DefaultImage;
  nextLabel?: string;
  isEditing?: boolean;
  isLoading?: boolean;
}

export const useSpaceImageStep = ({
  onSubmit,
  defaultImage,
  defaultImageUrl,
  nextLabel,
  isEditing = false,
  isLoading = false,
}: useSpaceDescriptionStepProps): Step<"image"> => {
  const { t } = useTranslation();

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string | undefined>(defaultImageUrl);

  const handleSubmitSpaceImage = async () => {
    onSubmit({ image, imageUrl });
  };

  const handleSubmitImage = (file: File | null) => {
    if (file) {
      setImage(file);
    }
  };

  const handleSelectImage = (imageUrl: string) => {
    setImageUrl(imageUrl);
  };

  return {
    name: "image",
    title: t(isEditing ? "space_edit_cover_image_step" : "space_create_cover_image_step"),
    component: (
      <SpaceImage
        onChangeBlob={handleSubmitImage}
        onChangeImageUrl={handleSelectImage}
        defaultImage={defaultImage}
      />
    ),
    onSubmit: handleSubmitSpaceImage,
    nextDisabled: !(image || imageUrl),
    nextLabel,
    isLoading,
  };
};
