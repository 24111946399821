import { useTranslation } from "react-i18next";

import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { NormalStepComponentProps, NormalStepData } from "../../types";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { useFileDetails } from "../FileDetails/useFileDetails";
import { FileDetailsFormComponent } from "../FileDetails/FileDetailsFormComponent";
import { hasProp, NewContentType } from "@eolas-medical/core";

const EolasEditorFileDetails = ({
  onClickBack,
  onClickForward,
  wizardState,
}: NormalStepComponentProps) => {
  const { handleSubmit, control } = useFileDetails(wizardState);

  const { t } = useTranslation();

  return (
    <EolasWizardModalWrapper
      header={{
        title: wizardState.fileId
          ? t("repository_edit_item_title")
          : t("repository_add_item_title"),
      }}
      buttonRowProps={{
        previousButton: { onClick: onClickBack },
        forwardButton: {
          onClick: handleSubmit((values) => {
            const newState = makeMutableWizardState(wizardState);
            if (values.hasExpiryDate && values.expiryDate) {
              if (!newState.isExistingFileWithNewType) {
                newState.dto.expiryDate = values.expiryDate;
              } else if (newState.dto.newType === NewContentType.EOLAS_EDITOR) {
                newState.dto.newProperties.expiryDate = values.expiryDate;
              }
            } else {
              if (hasProp(newState.dto, "expiryDate") && newState.dto.expiryDate) {
                newState.dto.expiryDate = null;
              }
              if (
                newState.isExistingFileWithNewType &&
                hasProp(newState.dto.newProperties, "expiryDate") &&
                newState.dto.newProperties.expiryDate
              ) {
                // This will have been added during the wizard, not yet saved to BE:
                delete newState.dto.newProperties.expiryDate;
              }
            }
            const keywords = values.keywords.split(",").map((str) => str.trim());
            if (!newState.isExistingFileWithNewType) {
              newState.dto.name = values.name;
              newState.dto.keywords = keywords;
            } else {
              newState.dto.newProperties.name = values.name;
              if (newState.dto.newType === NewContentType.EOLAS_EDITOR) {
                newState.dto.newProperties.keywords = keywords;
              }
            }
            onClickForward(newState);
          }),
        },
      }}
    >
      <FileDetailsFormComponent control={control} />
    </EolasWizardModalWrapper>
  );
};

export const editorFileDetailsData: NormalStepData = {
  StepComponent: EolasEditorFileDetails,
  props: {
    getPreviousStepName: () => "selectFile",
    getNextStepName: () => "eolasEditorCreator",
  },
};
