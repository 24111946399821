export const Strings = {
  title:
    "Only users who work in organisations within the NHS / Irish healthcare can access the BNF. You can easily add a recognised healthcare work email to your profile or verify your professional profile to gain access.",
  titlePending:
    "Only users who work in organisations within the NHS / Irish healthcare can access the BNF. Your professional profile is currently pending verification, however you can still add a recognised healthcare work email to get access.",
  description: "e.g. emails ending in: nhs.uk, nhs.net, hscni.net, nhs.scot, hse.ie, tcd.ie",
  addEmailButton: "Add email",
  or: "or",
  verifyProfile: "Verify profile",
  pendingProfileVerification: "Verification pending",
};
