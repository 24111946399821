import { userStore } from "@eolas-medical/core";
import useUserDetails from "Hooks/useUserDetails";
import { useEffect, useState } from "react";

function useCompleteProfileModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { isSharedAccount } = useUserDetails();
  const { specialty, completedTour } = userStore.userData;
  const { userID } = userStore;

  const hasAccessToken = Boolean(
    userStore.userSession.inviteId || userStore.userSession.accessLinkId,
  );

  const forceProfileUpdate =
    userID && !specialty && completedTour && !hasAccessToken && !isSharedAccount;

  useEffect(() => {
    if (forceProfileUpdate) {
      setModalIsOpen(true);
    }
  }, [forceProfileUpdate]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return { modalIsOpen, onCloseModal: handleCloseModal, forceProfileUpdate };
}

export default useCompleteProfileModal;
