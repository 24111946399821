/* eslint-disable camelcase */
import { CommunityLevelSection } from "@eolas-medical/core";
import { BaseAppRoute } from "Utilities/types";

import { objectToArray } from "Utilities/helpers";
import { BnfRouteKey } from "modules/bnf/types";

import { BnfSearchParams } from "./urlParams";
import { LocationDescriptorObject } from "history";

const bnfParamsMap: Record<
  BnfRouteKey,
  {
    parentKey: BnfRouteKey | null;
  }
> = {
  comingSoonAdult: { parentKey: null },
  comingSoonChild: { parentKey: null },
  adultMedicationsBnf: { parentKey: null },
  paediatricMedicationsBnfc: { parentKey: null },
  bnf: { parentKey: "adultMedicationsBnf" },
  bnfc: { parentKey: "paediatricMedicationsBnfc" },
  guidanceBnf: { parentKey: null },
  guidanceBnfc: { parentKey: null },
  guidance_adult: { parentKey: "guidanceBnf" },
  guidance_child: { parentKey: "guidanceBnfc" },
  treatmentSummariesBnf: { parentKey: null },
  treatmentSummariesBnfc: { parentKey: null },
  treatment_summaries_adult: { parentKey: "treatmentSummariesBnf" },
  treatment_summaries_child: { parentKey: "treatmentSummariesBnfc" },
  additionalContentAdult: { parentKey: null },
  additionalContentChild: { parentKey: null },
  singleDrugInteractionsBnf: { parentKey: null },
  singleDrugInteractionsBnfc: { parentKey: null },
  interactions_adult: { parentKey: null },
  interactions_child: { parentKey: null },
  about_adult: { parentKey: "additionalContentAdult" },
  about_child: { parentKey: "additionalContentChild" },
  medical_devices_adult: { parentKey: "additionalContentAdult" },
  medical_devices_child: { parentKey: "additionalContentChild" },
  borderline_substances_adult: { parentKey: "additionalContentAdult" },
  borderline_substances_child: { parentKey: "additionalContentChild" },
  borderline_substances_intro_adult: { parentKey: "additionalContentAdult" },
  borderline_substances_intro_child: { parentKey: "additionalContentChild" },
  wound_management_adult: { parentKey: "additionalContentAdult" },
  wound_management_child: { parentKey: "additionalContentChild" },
  wound_management_intro_adult: { parentKey: "additionalContentAdult" },
  wound_management_intro_child: { parentKey: "additionalContentChild" },
  dental_formulary_adult: { parentKey: "additionalContentAdult" },
  dental_formulary_child: { parentKey: "additionalContentChild" },
  nurse_prescribers_formulary_adult: { parentKey: "additionalContentAdult" },
  nurse_prescribers_formulary_child: { parentKey: "additionalContentChild" },
  nurse_prescribers_formulary_intro_adult: { parentKey: "additionalContentAdult" },
  nurse_prescribers_formulary_intro_child: { parentKey: "additionalContentChild" },
};

type BnfRoutes = Record<BnfRouteKey, string>;

export const bnfRoutes: BnfRoutes = objectToArray(bnfParamsMap).reduce(
  (acc, [name, { parentKey }]) => {
    const newAcc = { ...acc };
    const parentPageSegment = parentKey ? `${parentKey}/` : "";
    newAcc[
      name
    ] = `/${BaseAppRoute.knowledge}/${CommunityLevelSection.communityMedications}/${parentPageSegment}${name}`;
    return newAcc;
  },
  {} as BnfRoutes,
);

type MakeRouteParams = { routeKey: BnfRouteKey; idOrSid?: string; title?: string };

export const makeBnfRoute = ({
  routeKey,
  idOrSid,
  title,
}: MakeRouteParams): LocationDescriptorObject<undefined> => {
  const route = bnfRoutes[routeKey];

  const params = new URLSearchParams();
  if (idOrSid) {
    params.set(BnfSearchParams.idOrSid, idOrSid);
  }
  if (title) {
    params.set(BnfSearchParams.title, title);
  }
  return { pathname: route, search: params.toString() };
};
