import { clinicalQuestionStore, userStore } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";

export const DisclaimerModal = () => {
  const { t } = useTranslation();

  const consentToDisclaimer = () => {
    clinicalQuestionStore.setTermsAccepted(userStore.userID);
    modalStore.closeModal();
  };

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("general_attention")}</Title>
      </ModalHeader>

      <ModalBody>
        <Text className="mb-8 sm:mb-12" level={1}>
          {t("ecq_disclaimer_description")}
        </Text>
        <Button className="w-36 lg:w-56 rounded-xl self-center py-3" onClick={consentToDisclaimer}>
          {t("general_i_understand")}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
