export const sheetIndices = {
  summary: 0,
  qAndA: 1,
  permutations: 2,
  infoData: 3,
  conditionData: 4,
} as const;

export enum SummaryProperties {
  name = "Name",
  questions = "Questions",
  permutations = "Permutations",
  regimens = "Regimens",
}

export enum QaProperties {
  questionNumber = "Question Number",
  questionType = "Question Type",
  question = "Question",
}

export enum PermutationsProperties {
  regimen = "Regimen",
}

export enum InfoDataProperties {
  questionNumber = "Question Number",
  associatedInformation = "Associated Information",
}

export enum ConditionDataProperties {
  regimen = "Regimen",
  text = "Text",
}
