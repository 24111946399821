/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";
import {
  FilterFieldNames,
  FilterObjectType,
  EventStatus,
  FeedbackStatus,
} from "modules/portfolio/types";
import { DateFilter } from "./DateFilter";
import { FilterSectionElement } from "./FilterSectionElement";
import useTimelineFilterForm from "./hooks/useTimelineFilterForm";
import useEventFormTypes from "modules/portfolio/data/useEventFormTypes";

type FilterModalType = {
  eventStatuses: (FeedbackStatus | EventStatus)[];
  filterObject: FilterObjectType;
  onCloseModal: () => void;
  onSubmit: (filters: FilterObjectType) => void;
};

export const FilterTimelineModal: FC<FilterModalType> = ({
  eventStatuses,
  filterObject,
  onCloseModal,
  onSubmit,
}: FilterModalType) => {
  const { t } = useTranslation();
  const formTypes = useEventFormTypes();

  const { control, getValues, errors, handleSubmit, trigger } = useTimelineFilterForm(filterObject);

  const handleSubmitFilters = useCallback(() => {
    const values = getValues();
    onSubmit(values as any);
    onCloseModal();
  }, [getValues, onSubmit, onCloseModal]);

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{t("portfolio_filter_modal_title")}</Title>
      </ModalHeader>
      <ModalBody>
        <div className="max-h-60vh overflow-y-scroll">
          <DateFilter
            className="mb-4"
            control={control}
            errors={errors}
            sectionTitle={"Event Date"}
            trigger={trigger as any}
          />
          <div className="pb-2">
            <FilterSectionElement
              className="mb-2"
              control={control}
              errors={errors}
              fieldList={formTypes}
              fieldName={FilterFieldNames.eventType}
              sectionTitle="Event Type"
              type={FilterFieldNames.eventType}
            />
          </div>
          <div className="pb-2">
            <FilterSectionElement
              control={control}
              errors={errors}
              fieldList={eventStatuses}
              fieldName={FilterFieldNames.eventStatus}
              sectionTitle="Event Status"
              type={FilterFieldNames.eventStatus}
            />
          </div>
          <div />
        </div>
        <Button onClick={handleSubmit(handleSubmitFilters)}>
          <Text level={3}>{t("general_submit")}</Text>
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
