import { useCallback } from "react";
import envConfig from "env-config";
import { useMutation } from "@tanstack/react-query";

const useDeleteUserAccount = ({ onSuccess }: { onSuccess: () => void }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deleteAccount = useCallback(async ({ queryParams }: any) => {
    let zappierURl = envConfig.REACT_APP_ZAPIER_HOOK_URL + envConfig.REACT_APP_ZAPIER_CATCH_ACCOUNT;
    if (queryParams) {
      (Object.entries(queryParams) as [string, string][]).forEach(
        ([queryName, queryValue], index) => {
          if (index === 0) {
            zappierURl = `${zappierURl}/?${queryName}=${encodeURIComponent(queryValue)}`;
          } else {
            zappierURl = `${zappierURl}&${queryName}=${encodeURIComponent(queryValue)}`;
          }
        },
      );
    }
    await fetch(zappierURl);
    onSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate, isLoading, error } = useMutation(deleteAccount);

  return {
    deleteUserAccount: mutate,
    deletingUserAccount: isLoading,
    error,
  };
};

export default useDeleteUserAccount;
