/* eslint-disable @typescript-eslint/no-explicit-any */
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { useCallback, useMemo, useState, useEffect } from "react";
import { ChildReference, OrganisationLevelSection, sectionStore } from "@eolas-medical/core";

import { useReorder, useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, NavButton, PageTitle, SubSectionTile } from "UIKit";

import { medusaIconsMapper } from "../IconMapper";
import { DropResult } from "react-beautiful-dnd";
import { VirtualSortableList } from "shared/components/Lists";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const MedusaSubSections: React.FC<RouteComponentProps> = observer(
  ({ match: { params }, history }: any) => {
    const { t } = useTranslation();
    const sectionId = useMemo(
      () => (params.id ? decodeURIComponent(params.id) : null),
      [params.id],
    );
    const [subSections, setSubSections] = useState<ChildReference[]>([]);
    useMainSectionVisit({
      mainSectionId: OrganisationLevelSection.medusaMedications,
    });

    const mainSection: any = useMemo(() => {
      if (sectionId) {
        return sectionStore.getSection(sectionId);
      }
      return (
        sectionStore
          .getMainSectionList("hospital")
          .find(({ icon }) => icon === "medusaMedications") || { id: "" }
      );
    }, [sectionId]);

    const pageTitle = useMemo(
      () => params.sectionName || "Medusa Medications",
      [params.sectionName],
    );

    const { onDragEnd } = useReorder(mainSection.id);

    useEffect(() => {
      const sections = sectionStore.getSection(mainSection.id).childrenOrder;
      if (sections && sections.length) {
        setSubSections(compact(sections));
      }
    }, [mainSection.id]);

    const childrenOrder = useMemo(() => {
      return subSections.filter((value) => value.disabled !== "true");
    }, [subSections]);

    const items = useMemo(() => {
      return childrenOrder.map(({ id, name, description, icon }) => ({
        id,
        name: name || "",
        description: description || "",
        icon: icon || "",
      }));
    }, [childrenOrder]);

    const handleDragEnd = useCallback(
      (drop: DropResult) => {
        onDragEnd(drop);
        const sections = sectionStore.getSection(mainSection.id).childrenOrder;
        if (sections && sections.length) {
          setSubSections(compact(sections));
        }
      },
      [onDragEnd, mainSection.id],
    );

    const renderItem = useCallback(
      (item: { id: string; name: string; description: string; icon: string }) => {
        const hash = encodeURIComponent(item.id);
        const path = `${OrganisationLevelSection.medusaMedications}/${item.name}/${hash}`;
        return (
          <Link key={item.id} to={path}>
            <SubSectionTile title={item.name!} image={medusaIconsMapper(item.icon!)} />
          </Link>
        );
      },
      [],
    );

    return (
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={mainSection.id}
          parentId={sectionId ?? ""}
        />
        <PageTitle titleClass="capitalize" title={t("medusa_main_title", { title: pageTitle })} />
        <NavButton onClick={history.goBack} />

        <VirtualSortableList
          listType="section"
          items={items}
          renderItem={renderItem}
          onDragEnd={handleDragEnd}
          droppableId="medusa-sections"
          isDragDisabled={!sectionStore.isAdmin}
        />
      </InnerPageWrapper>
    );
  },
);
