import { useQuery } from "@tanstack/react-query";
import { MonographType, bnfClient, sortInteractions } from "@eolas-medical/core";
import bnfKeys from "./bnf.queryKeys";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";

type HookArgs = { sid?: string; type: MonographType };

const fetchSingleDrugInteractions = async ({ sid, type }: HookArgs) => {
  if (!sid) {
    throw new Error("Sid not found in useGetSingleDrugInteractions. This should not happen.");
  }
  const { interactions, supplementaryInfo } = await bnfClient.getSingleDrugInteractions({
    sid,
    type,
  });
  return { interactions: sortInteractions({ interactions, currentSid: sid }), supplementaryInfo };
};

export const useGetSingleDrugInteractions = (args: HookArgs) => {
  const { sid, type } = args;

  const { data, error, isFetching, refetch } = useQuery({
    queryKey: bnfKeys.singleDrugInteractions(args.sid, args.type),
    queryFn: () => fetchSingleDrugInteractions({ sid, type }),
    enabled: !!sid, // Only run the query if `sid` is truthy
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return { data, error, isInitialLoading: isFetching, refetch };
};
