import { ContentItem, SpecialtyValue, userStore } from "@eolas-medical/core";
import useDeviceType from "Hooks/useDeviceType/useDeviceType";
import { Carousel, ImageCard, Loader, Modal, SectionIcon, Text, Title } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { observer } from "mobx-react-lite";
import useMedicationHighlightsItems from "modules/medications/data/useMedicationHighlightsItems";
import useMedicationsHighlightsSection from "modules/medications/data/useMedicationsHighlightsSection";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useShareFile } from "shared/hooks/useShareFile";
import { twMerge } from "tailwind-merge";

interface MedicationsHighlightsCarouselProps {
  isAdmin: boolean;
}

const MedicationsHighlightsCarousel = observer(
  ({ isAdmin }: MedicationsHighlightsCarouselProps) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const { push } = useHistory();
    const user = userStore.userData;
    const deviceType = useDeviceType();

    const medicationsHighlightsSection = useMedicationsHighlightsSection();
    const { medicationsHighlightsItems, medicationsHighlightsItemsLoading } =
      useMedicationHighlightsItems({
        medicationsHighlightsSectionId: medicationsHighlightsSection?.id || "",
        specialties: (user.specialty as SpecialtyValue[]) || [],
      });

    const shouldShowLoader = medicationsHighlightsItemsLoading;
    const shouldShowEmptyState = !shouldShowLoader && !medicationsHighlightsItems.length;
    const shouldShowCarousel = !shouldShowLoader && !!medicationsHighlightsItems.length;
    const carouselIsSwipeable = deviceType !== "desktop";

    const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
      useShareFile();

    const handleClickSection = () => {
      if (isAdmin) {
        push(`${url}/${medicationsHighlightsSection?.iconName}`);
      }
    };

    const handleClickFlashcard = (flashcard: ContentItem) => {
      push(
        `/${BaseAppRoute.viewer}/${encodeURIComponent(
          flashcard.mainSectionId,
        )}/flash-cards/${encodeURIComponent(flashcard.id)}`,
      );
    };

    const sectionClass = twMerge(
      "flex justify-between items-center p-5",
      isAdmin ? "cursor-pointer" : "",
    );

    return (
      <>
        <Modal open={isShareModalOpen} onClose={closeShareModal}>
          {shareModal}
        </Modal>
        <div className="flex flex-col rounded-lg shadow-sm bg-white">
          <div className={sectionClass} onClick={handleClickSection}>
            <div className="flex items-center space-x-4 ml-5">
              <SectionIcon
                iconClassname="w-10 h-10"
                iconURL={medicationsHighlightsSection?.iconUrl || ""}
              />
              <Title level={7}>{medicationsHighlightsSection?.name}</Title>
            </div>
            <Text level={2} className="text-grey-600">
              {`${medicationsHighlightsItems.length} highlights`}
            </Text>
          </div>
          <div className="bg-green-50 py-6 ">
            {shouldShowLoader && <Loader className="bg-transparent" />}

            {shouldShowEmptyState && (
              <Title level={4} className="flex items-center justify-center text-grey-600">
                {t("medications_highlights_empty")}
              </Title>
            )}

            {shouldShowCarousel && (
              <Carousel isSwipeable={carouselIsSwipeable} itemClass="mx-1 py-4">
                {medicationsHighlightsItems.map((flashcard) => (
                  <div key={flashcard.id} className="cursor-pointer">
                    <ImageCard
                      title={flashcard.name}
                      description={flashcard.description || undefined}
                      imageUrl={flashcard.url || ""}
                      onClick={() => handleClickFlashcard(flashcard)}
                      onShare={isShareFileEnabled ? () => handleOnShareFile(flashcard) : undefined}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </>
    );
  },
);

export default MedicationsHighlightsCarousel;
