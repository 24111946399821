import { ClinicalQuestionIcon } from "Assets";
import { NewQuestionProps } from "./NewQuestionView";
import { FormElement, IconButton, Input, Loader } from "UIKit";

type QuestionBarProps = Omit<NewQuestionProps, "setValue" | "isSubmitting"> & {
  placeholder: string;
  fieldName: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isFocused: boolean;
  isSubmitting?: boolean;
};

export const QuestionBar = ({
  fieldName,
  isSubmitting,
  control,
  onSubmit,
  errorMessage,
  placeholder,
  onFocus,
  onBlur,
  isFocused,
}: QuestionBarProps) => {
  return (
    <form className="flex flex-col mb-8" onSubmit={onSubmit}>
      <FormElement required id={fieldName} control={control} errorMessage={errorMessage}>
        {(fieldProps, { isValid }) => (
          <Input
            {...fieldProps}
            onFocus={onFocus}
            onBlur={onBlur}
            size="xl"
            className={`rounded-md p-4 border ${
              isFocused ? "border-blue-100" : "border-grey-200"
            } shadow-md`}
            inputClassName="text-sm sm:text-base"
            disabled={isSubmitting}
            placeholder={placeholder}
            iconRight={
              <IconComponent
                isSubmitting={Boolean(isSubmitting)}
                isValid={Boolean(isValid)}
                onSubmit={onSubmit}
              />
            }
          />
        )}
      </FormElement>
    </form>
  );
};

const IconComponent = ({
  onSubmit,
  isSubmitting,
}: {
  onSubmit: () => void;
  isValid: boolean;
  isSubmitting: boolean;
}) => {
  if (isSubmitting) {
    return <Loader className="mr-2" size={20} />;
  }
  return (
    <IconButton
      className="bg-blue-900"
      icon={<ClinicalQuestionIcon height={30} width={40} />}
      onClick={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    />
  );
};
