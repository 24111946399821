import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  userStore,
  EolasFile,
  CompletedContentStatus,
  MarkFileCompletedArgs,
  markFileCompletedFunction,
  CompletedContentResponseItem,
} from "@eolas-medical/core";

import { errorStore } from "Stores/ErrorStore";
import { CompletedFileProps, LDFlagNames } from "Utilities/types";
import { daysLeftForCompetency, hasExpirationLength, useRequestStatus } from "Utilities";
import { useLaunchDarkly } from "Contexts";
import { useNotifications } from "Components/Notifications";

const completionStatus = {
  seen: "completedStatus_seen",
  expired: "completedStatus_expired",
  notSeen: "completedStatus_notSeen",
  completed: "completedStatus_completed",
  completedWithExpiration: "completedStatus_completed_expiration",
};

type FileLabelParams = {
  file: EolasFile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translator: any;
  completedFileInfo?: CompletedContentResponseItem | null | undefined;
};

const getTileProperties = ({ file, translator: t, completedFileInfo }: FileLabelParams) => {
  const doesExpire = hasExpirationLength(file.moreInfo!);
  const hasCompletedDate = completedFileInfo?.completedDate;
  const markedComplete = completedFileInfo?.markedAsComplete;

  const defaultProperties = {
    completionLabel: t(completionStatus.notSeen),
    isButtonDisabled: true,
    isChecked: false,
    isCompleted: false,
  };

  if (hasCompletedDate && !markedComplete) {
    return {
      completionLabel: t(completionStatus.seen),
      isButtonDisabled: false,
      isChecked: false,
      isCompleted: true,
    };
  }
  if (markedComplete) {
    if (!doesExpire) {
      return {
        completionLabel: t(completionStatus.completed),
        isButtonDisabled: true,
        isChecked: true,
        isCompleted: true,
      };
    }

    const daysLeft = daysLeftForCompetency({
      completedDate: markedComplete,
      expirationInMonths: file.moreInfo!,
    });

    if (daysLeft > 0) {
      return {
        completionLabel: t(completionStatus.completedWithExpiration, { daysLeft }),
        isButtonDisabled: true,
        isChecked: true,
        isCompleted: true,
      };
    }

    if (daysLeft <= 0) {
      return {
        completionLabel: t(completionStatus.expired),
        isButtonDisabled: false,
        isChecked: false,
        isCompleted: false,
      };
    }
  }

  return defaultProperties;
};

export const useCompleteFile = () => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { showNotification } = useNotifications();

  const onCompleteFile = useCallback(
    (fileId: string, status: CompletedContentStatus) => {
      const args: MarkFileCompletedArgs = {
        status,
        fileID: fileId,
      };

      if (flags[LDFlagNames.READ_ONLY_ACTIVATED]) {
        // show notificatons banner
        showNotification({
          type: "error",
          description: t("read_only_notification_mark-file"),
        });
        return;
      }

      setRequestStatus({ status: "pending", error: "" });

      markFileCompletedFunction(args)
        .then(() => {
          setRequestStatus({ status: "success", error: "" });
        })
        .catch((error) => {
          setRequestStatus({ status: "error", error: "" });
          errorStore.captureError({
            error,
            source: "user",
          });
        });
    },
    [flags, setRequestStatus, showNotification, t],
  );

  const getCompletedFileProps = useCallback(
    (file: EolasFile): CompletedFileProps => {
      const completedFileInfo = userStore.getCompletedFile(file.id);

      return getTileProperties({
        file,
        translator: t,
        completedFileInfo,
      });
    },
    [t],
  );

  return {
    error,
    isLoading,
    onCompleteFile,
    getCompletedFileProps,
  };
};
