import { FileSection } from "modules/myFiles/types";
import { motion, Variants } from "framer-motion";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { IconButton, SectionIcon, Text } from "UIKit";
import { DeleteIcon, DragIcon, EditMonoIcon } from "Assets";
import { useCallback, useMemo } from "react";
import { useToolTip } from "Hooks/useToolTip";

export interface SectionTileProps {
  section: FileSection;
  fileCount?: number;
  className?: string;
  isDraggable?: boolean;
  isDragging?: boolean;
  disabledDeleteText?: string;
  "data-testid"?: string;
  onClick?: (section: FileSection) => void;
  onEdit?: (section: FileSection) => void;
  onDelete?: (section: FileSection) => void;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const SectionTile = ({
  section,
  fileCount = 0,
  className,
  isDraggable = false,
  isDragging = false,
  disabledDeleteText,
  onClick,
  onEdit,
  onDelete,
  "data-testid": datTestId,
  ...props
}: SectionTileProps) => {
  const { makeToolTipDataAttr } = useToolTip();
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick(section);
    },
    [section, onClick],
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit && onEdit(section);
    },
    [section, onEdit],
  );

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete && onDelete(section);
    },
    [section, onDelete],
  );

  const hasFiles = useMemo(() => fileCount > 0, [fileCount]);

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={tw(`
        grid grid-cols-12 transition-all bg-white h-32
        p-5 rounded-lg shadow-sm
      ${onClick ? "cursor-pointer" : ""}
      ${isDragging ? "tile-border__dragging" : "tile-border"}
      ${className}
    `)}
      onClick={handleClick}
      data-testid={datTestId}
      {...props}
    >
      <div className="row-start-1 col-start-1">
        {isDraggable ? (
          <DragIcon
            data-testid="drag-icon"
            className="col-span-1 text-grey-500 self-start h-4 w-4"
          />
        ) : null}
      </div>

      <div className="row-start-1 col-start-1 col-end-11 flex items-center ml-8">
        <SectionIcon iconClassname="w-10 h-10" iconURL={section.iconUrl} />
        <p className="text-sm font-bold md:text-xl ml-5 flex-1 line-clamp-2">{section.name}</p>
      </div>

      <div className="row-start-1 col-span-2 flex flex-col">
        <div className={"flex flex-1 justify-end h-full items-center"}>
          {onEdit ? (
            <div className="self-start ">
              <IconButton
                size="sm"
                onClick={handleEdit}
                icon={<EditMonoIcon />}
                data-testid={`edit-section-${section.name}`}
              />
            </div>
          ) : null}
          {onDelete ? (
            <div className="self-start ml-4">
              <IconButton
                disabled={hasFiles}
                size="sm"
                onClick={handleDelete}
                icon={<DeleteIcon />}
                data-testid={`delete-section-${section.name}`}
                {...(hasFiles && disabledDeleteText
                  ? makeToolTipDataAttr({ text: disabledDeleteText })
                  : {})}
              />
            </div>
          ) : null}
        </div>
        <div className="flex self-end">
          <Text level={3} data-testid="section-list-item-files" className="text-grey-600">
            {fileCount} FILES
          </Text>
        </div>
      </div>
    </motion.div>
  );
};

export default SectionTile;
