import { useMutation } from "@tanstack/react-query";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { profilesClient, userStore } from "@eolas-medical/core";

export const useUpdateUserPhoto = (userId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isLoading, mutate } = useMutation<unknown, Error, any>(
    async (fileDto) => {
      const file = await profilesClient.updateUserPhoto(
        fileDto.userId,
        fileDto.blob.type.split("/")[1], // split to send only the image extension
        fileDto.blob.name,
      );

      try {
        if (file.uploadURL) {
          await uploadFileToPresignedS3(file.uploadURL, fileDto.blob);
        }
      } catch (error) {
        return;
      }
    },
    {
      onSuccess: async () => {
        const userProfile = await profilesClient.getUserProfile(userId);
        userStore.updateData({ user: userProfile });
      },
    },
  );

  return {
    updatingUserPhoto: isLoading,
    updateUserPhoto: mutate,
  };
};

export default useUpdateUserPhoto;
