import {
  isConstituentDrugs,
  isDrugIndicationsAndDose,
  isDrugInfo,
  isInteractants,
  isMedicinalForms,
  isMonitoringRequirements,
  isNationalFunding,
  isSynonyms,
} from "@eolas-medical/core";
import { ListableDisplayItem } from "modules/bnf/types";
import { SearchSortList } from "shared/components/Lists";
import DrugInteractionAndDoseContent from "./DrugIndicationsAndDoseContent/DrugIndicationsAndDoseContent";
import DrugInfoContentComponent from "../BnfDrugInfo/DrugInfoContentComponent";
import InteractantsContent from "./InteractantsContent/InteractantsContent";
import MonitoringRequirementsContent from "./MonitoringRequirementsContent/MonitoringRequirementsContent";
import SynonymsContent from "./SynonymsContent/SynonymsContent";
import NationalFundingContent from "./NationalFundingContent/NationalFundingContent";
import ConstituentDrugsContent from "./ConstituentDrugsContent/ConstituentDrugsContent";
import MedicinalFormsContent from "./MedicinalFormsContent/MedicinalFormsContent";
import { errorStore } from "Stores/ErrorStore";
import { useCallback } from "react";
import { BnfCollapsible, InfoIdProps } from "../BnfCollapsible/BnfCollapsible";
import { useRunOnMountUnmount } from "Hooks";
import { bnfStore } from "../../stores/bnf.store";

interface BnfDrugViewerProps {
  drugItems: ListableDisplayItem[];
  drugItemsLoading: boolean;
  idOrSid: string;
}

const BnfDrugViewer = ({ drugItems, drugItemsLoading, idOrSid }: BnfDrugViewerProps) => {
  const renderItem = useCallback(
    (item: ListableDisplayItem) => {
      const info: InfoIdProps = { itemId: idOrSid, sectionId: item.id };
      if (isDrugIndicationsAndDose(item)) {
        return <DrugInteractionAndDoseContent section={item.section} {...info} />;
      }
      if (isDrugInfo(item)) {
        return <DrugInfoContentComponent section={item.section} {...info} />;
      }
      if (isInteractants(item)) {
        return (
          <BnfCollapsible title="Interactions" {...info}>
            <InteractantsContent section={item.section} />
          </BnfCollapsible>
        );
      }
      if (isMonitoringRequirements(item)) {
        return <MonitoringRequirementsContent section={item.section} {...info} />;
      }
      if (isMedicinalForms(item)) {
        return <MedicinalFormsContent section={item.section} {...info} />;
      }
      if (isSynonyms(item)) {
        return <SynonymsContent section={item.section} {...info} />;
      }
      if (isNationalFunding(item)) {
        return <NationalFundingContent section={item.section} {...info} />;
      }
      if (isConstituentDrugs(item)) {
        return <ConstituentDrugsContent section={item.section} {...info} />;
      }
      errorStore.captureError({
        error: new Error("Unrecognised item for render row item in BNF view"),
        source: "user",
        data: { item: JSON.stringify(item) },
      });
      return null;
    },
    [idOrSid],
  );

  useRunOnMountUnmount({
    onUnmount: () => {
      bnfStore.cleanupItem(idOrSid);
    },
  });

  return (
    <SearchSortList<ListableDisplayItem>
      items={drugItems}
      renderItem={renderItem}
      isLoading={drugItemsLoading}
    />
  );
};

export default BnfDrugViewer;
