import { useTranslation } from "react-i18next";
import { userStore } from "@eolas-medical/core";
import { ConfirmationModal, InnerPageWrapper, Modal } from "UIKit";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import {
  SupervisionStatus,
  SupervisionSummary,
  SupervisorRequestCreatedBy,
} from "modules/portfolio/types";
import SupervisionCard from "../../components/SupervisionCard/SupervisionCard";
import SupervisionList from "../../components/SupervisionList/SupervisionList";
import useSupervisionActions from "../../hooks/useSupervisionActions";
import SupervisionPageTitle from "../../components/SupervisionPageTitle/SupervisionPageTitle";
import useSuperviseesList from "modules/portfolio/data/useSuperviseesList";

export const SuperviseesList = () => {
  const { t } = useTranslation();
  const { userID = "", userData } = userStore;
  const { superviseesRequestList, superviseesList, superviseesListLoading } =
    useSuperviseesList(userID);

  const {
    modalState,
    onCloseModal,
    onRemoveSupervision,
    onClickRemoveSupervision,
    onDeclineRequest,
    decliningRequest,
    removingSupervision,
    onApproveRequest,
    approvingRequest,
    onClickSupervision,
    onClickApprove,
    onClickDecline,
  } = useSupervisionActions({
    createdBy: SupervisorRequestCreatedBy.SUPERVISOR,
    ownerId: userID,
    userEmail: userData.email,
  });

  const renderSupervisionRequest = (supervision: SupervisionSummary) => {
    if (supervision.status === SupervisionStatus.PENDING) {
      return (
        <SupervisionCard
          id={supervision.id}
          email={supervision.email}
          status={supervision.status}
          onApprove={onClickApprove}
          onDecline={onClickDecline}
        />
      );
    }

    return (
      <SupervisionCard
        id={supervision.id}
        email={supervision.email}
        status={supervision.status}
        onRemove={onClickRemoveSupervision}
        onClick={() =>
          supervision.userId &&
          supervision.name &&
          onClickSupervision(supervision.userId, supervision.name)
        }
      />
    );
  };

  return (
    <InnerPageWrapper>
      <Modal open={modalState !== null} onClose={onCloseModal}>
        {modalState === "approveRequest" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onApproveRequest}
            iconType="GreenTickIcon"
            title={t("portfolio_supervisee_approve_title")}
            description={t("portfolio_supervisee_approve_description")}
            acceptLabel={t("portfolio_supervisee_approve_button")}
            declineLabel={t("portfolio_supervision_cancel_decline")}
            declineButtonProps={{ disabled: approvingRequest }}
            acceptButtonProps={{ color: "blue", isLoading: approvingRequest }}
          />
        )}
        {modalState === "declineRequest" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onDeclineRequest}
            iconType="MajorIncidentIcon"
            title={t("portfolio_supervisee_decline_title")}
            description={t("portfolio_supervisee_decline_description")}
            acceptLabel={t("portfolio_supervisee_decline_button")}
            declineLabel={t("portfolio_supervision_cancel_decline")}
            declineButtonProps={{ disabled: decliningRequest }}
            acceptButtonProps={{ color: "red", isLoading: decliningRequest }}
          />
        )}
        {modalState === "removeSupervision" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onRemoveSupervision}
            iconType="MajorIncidentIcon"
            title={t("portfolio_supervisee_remove_title")}
            description={t("portfolio_supervisee_remove_description")}
            acceptLabel={t("portfolio_supervisee_remove_accept")}
            declineLabel={t("portfolio_supervisor_remove_decline")}
            declineButtonProps={{ disabled: removingSupervision }}
            acceptButtonProps={{ color: "red", isLoading: removingSupervision }}
          />
        )}
      </Modal>
      <SupervisionPageTitle
        title={t("portfolio_supervisees")}
        subtitle={t("portfolio_supervisees_subtitle")}
        toReplaceUrl={PortfolioConstants.SUPERVISEES_URL}
      />

      <SupervisionList
        items={[superviseesRequestList, superviseesList]}
        renderSupervisionRequest={renderSupervisionRequest}
        isLoading={superviseesListLoading}
        pendingRequestTitle={t("portoflio_supervisee_pending")}
        approvedRequestTitle={t("portfolio_supervisee_approved")}
        emptySupervisionText={t("portfolio_empty_supervisees")}
      />
    </InnerPageWrapper>
  );
};
