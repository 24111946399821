import { useTranslation } from "react-i18next";
import { DsmErrorStep } from "shared/pages/ContentRepository/components/DsmErrorStep";
import { Step } from "UIKit";
import { DsmError } from "../../../functions/parseDsmSheet/helpers";

export const useDsmErrorStep = ({ error }: { error: DsmError | null }): Step<"dsm-error"> => {
  const { t } = useTranslation();

  let errorMessage = "";

  if (error) {
    errorMessage = error.code === "other" ? t("repository_admin_error_generic") : error.message;
  }

  return {
    name: "dsm-error",
    title: t("repository_admin_error_title"),
    backLabel: t("fileViewer_goBack"),
    backDisabled: false,
    nextDisabled: true,
    component: errorMessage ? <DsmErrorStep errorMessage={errorMessage} /> : <></>,
  };
};
