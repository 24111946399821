import { SponsoredInfoSection, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sponsoredSlotQueryKeys } from "./sponsoredSlots.queryKeys";

export const useDeleteSponsoredSection = () => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isLoading, isError } = useMutation({
    mutationFn: (section: SponsoredInfoSection) => {
      return knowledgeContentClient.deleteSection(section.id);
    },
    onSuccess: (_, section) => {
      queryClient.invalidateQueries(
        sponsoredSlotQueryKeys.getAllSponsoredSlots(section.mainSectionId),
      );
    },
  });

  return { deleteSection: mutate, isSuccess, isLoading, isError };
};
