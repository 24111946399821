import {
  CommunityLevelSection,
  InfoItem,
  SupportedTags,
  hasProp,
  sectionStore,
} from "@eolas-medical/core";
import { LDFlagNames, LDFlags } from "Utilities/types";

export const wrapInTags = (text: string, tag: SupportedTags = "p") => `<${tag}>${text}</${tag}>`;

export const isTextXml = (text: string) => {
  const tags = text.match(/(<.[^(><.)]+>)/g);
  return Boolean(tags?.length);
};

export const removeTags = (xml: string) => xml.replace(/<[^>]*>/g, "");

type InfoItemSections = InfoItem["sections"];

export const parseInfoItemData = (
  data: unknown,
): { sections: InfoItemSections; infoType?: "guidance" | "treatmentSummaries" } | null => {
  /**
   * The API tells us that data with the following property items contains an InfoSection,
   * therefore we cast:
   */
  if (hasProp(data, "guidance") && hasProp(data.guidance, "sections")) {
    return { sections: data.guidance.sections as InfoItemSections, infoType: "guidance" };
  }

  if (hasProp(data, "treatmentSummary") && hasProp(data.treatmentSummary, "sections")) {
    return {
      sections: data.treatmentSummary.sections as InfoItemSections,
      infoType: "treatmentSummaries",
    };
  }
  if (hasProp(data, "about") && hasProp(data.about, "sections")) {
    return { sections: data.about.sections as InfoItemSections };
  }
  if (
    hasProp(data, "borderlineSubstancesIntro") &&
    hasProp(data.borderlineSubstancesIntro, "sections")
  ) {
    return { sections: data.borderlineSubstancesIntro.sections as InfoItemSections };
  }
  if (
    hasProp(data, "nursePrescribersFormularyIntro") &&
    hasProp(data.nursePrescribersFormularyIntro, "sections")
  ) {
    return { sections: data.nursePrescribersFormularyIntro.sections as InfoItemSections };
  }
  if (
    hasProp(data, "nursePrescribersFormulary") &&
    hasProp(data.nursePrescribersFormulary, "sections")
  ) {
    return { sections: data.nursePrescribersFormulary.sections as InfoItemSections };
  }
  if (hasProp(data, "dentalFormulary")) {
    /**
     * Dental formulary is a special case- each item is actually a section of InfoItem:
     */
    return { sections: [data.dentalFormulary] as InfoItemSections };
  }

  return null;
};

export const isBnfEnabled = (flags: LDFlags) => {
  const isCommunityTabEnabled = flags[LDFlagNames.COMMUNITY_TAB];
  const isBnfWebEnabled = flags[LDFlagNames.BNF_WEB];
  return Boolean(
    isCommunityTabEnabled &&
      isBnfWebEnabled &&
      sectionStore.getChildReferenceByMainSectionType(CommunityLevelSection.communityMedications)
        ?.id,
  );
};
