import { Dispatch, useState } from "react";
import Fuse from "fuse.js";
export interface UseLocalMultipleSearchProps<T> {
  data: T[][];
  keysToSearch: string[];
}

export interface UseLocalSearch<T> {
  searchInput: string;
  searchResult: T[][];
  onSetSearchInput: Dispatch<string>;
}
const useLocalMultipleSearch = <T>({
  data,
  keysToSearch,
}: UseLocalMultipleSearchProps<T>): UseLocalSearch<T> => {
  const [searchText, setSearchText] = useState<string>("");

  const fuseData = (list: T[]) => {
    const options = {
      keys: keysToSearch,
      threshold: 0.3, // the minimum score a result must have to be included
    };
    const fuse = new Fuse(list, options);
    return searchText.length > 0 ? fuse.search(searchText).map(({ item }) => item) : list;
  };

  const results = data.map(fuseData);

  return {
    searchInput: searchText,
    searchResult: results,
    onSetSearchInput: setSearchText,
  };
};
export default useLocalMultipleSearch;
