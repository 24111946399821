import * as XLSX from "xlsx";
import { DsmErrorCode, DsmSheetType } from "./types";
import { sheetIndices } from "./constants";
import { parseAwsJson } from "Utilities";
import { hasStringProp } from "@eolas-medical/core";

export const makeWorkbook = async (file: File): Promise<XLSX.WorkBook> => {
  const buffer = await file.arrayBuffer();
  return XLSX.read(buffer);
};

export const getSheet = ({
  workbook,
  sheetType,
}: {
  workbook: XLSX.WorkBook;
  sheetType: DsmSheetType;
}): XLSX.WorkSheet | null => {
  return workbook.Sheets[workbook.SheetNames[sheetIndices[sheetType]]] ?? null;
};

export const removeEmptyRows = (data: unknown) => {
  const cleaned: Array<Array<unknown>> = [];
  if (Array.isArray(data)) {
    for (const row of data) {
      if (Array.isArray(row) && row.length) {
        cleaned.push(row);
      }
    }
  }
  return cleaned;
};

export const getWorkSheetJson = ({ worksheet }: { worksheet: XLSX.WorkSheet }): Array<unknown> => {
  return removeEmptyRows(XLSX.utils.sheet_to_json(worksheet, { header: 1 }));
};

export const stripString = (str: string) => str.trim().replace(/[.,\s]/g, "");

export class DsmError extends Error {
  public code: DsmErrorCode;
  constructor(message: string, code: DsmErrorCode) {
    super(message);
    this.code = code;
  }
}

export const isMetadataDsmMetadata = (metadata: unknown) => {
  const parsed = parseAwsJson(metadata);
  return hasStringProp(parsed, "dsmData");
};
