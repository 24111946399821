import { BaseModalProps } from "Hooks/useModal";
import { InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";
import { Option } from "UIKit/Select/Select";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const options: Option[] = [
  { value: "mobile", label: "Mobile" },
  { value: "web", label: "Web" },
];

export const PreviewModal = ({
  bannerUrl,
  squareBannerUrl,
}: { bannerUrl: string; squareBannerUrl: string } & BaseModalProps) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<Option>({ ...options[0] });

  const { value: currentValue } = selection;

  const urlSrc = currentValue === "mobile" ? bannerUrl : squareBannerUrl;

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{t("knowledge_sponsored_slot_preview_title")}</Title>
      </ModalHeader>
      <ModalBody className="space-y-1 sm:space-y-4 w-full">
        <Text level={1}>{t("knowledge_sponsored_slot_preview_type_title")}</Text>
        <EolasSelect
          value={selection}
          mode="synchronous"
          onChange={(option) => {
            option && setSelection(option);
          }}
          options={options}
        />
        <div className="flex justify-center items-center">
          <div
            className={`${
              currentValue === "mobile" ? "h-24 w-80" : "h-52 w-52"
            } rounded-lg overflow-hidden border border-grey-300`}
          >
            <img
              src={urlSrc}
              srcSet={urlSrc}
              alt=""
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
