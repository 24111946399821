import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CommunityLevelSection,
  GentResults,
  gentamicinDoseCalc,
  gentamicinDoseCalcArgs,
  ClinicalCalculatorsSubSection,
} from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, Modal, NavButton, PageTitle, WarningModal } from "UIKit";
import { CalendarIcon, CreatinineClearanceIcon, HeightIcon, WeightIcon } from "Assets";

import { useCalculatorWarning } from "../hooks";
import { ClinicalCalculatorRouteProps } from "../types";
import { ResultsModal } from "../components/ResultsModal";
import { CalculatorForm } from "../components/CalculatorForm";
import { AttentionModal } from "../components";

interface GentamicinCalculatorState {
  result?: GentResults;
  values?: gentamicinDoseCalcArgs;
}

export const GentamicinCalculator: React.FC<ClinicalCalculatorRouteProps> = ({
  history,
  match: { params },
}: ClinicalCalculatorRouteProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<GentamicinCalculatorState>({});
  const { warning, setWarningMessage } = useCalculatorWarning(params.id);

  useMainSectionVisit({
    mainSectionId: CommunityLevelSection.clinicalCalculators,
    subSectionId: "gentamicin",
  });

  const handleSubmit = (values: gentamicinDoseCalcArgs) => {
    const result = gentamicinDoseCalc(values);
    setState({ result, values });
  };

  return (
    <InnerPageWrapper>
      <PageTitle title={t("clinicalCalculators_gentamicin_title")} />
      <NavButton onClick={history.goBack} />

      <CalculatorForm
        schemaName="gentamicinDose"
        onSubmit={handleSubmit}
        values={[
          {
            id: "age",
            label: t("clinicalCalculators_age_label"),
            inputProps: {
              type: "number",
              iconLeft: <CalendarIcon className="w-4" />,
              placeholder: t("clinicalCalculators_age_placeholder"),
            },
          },
          {
            id: "weight",
            label: t("clinicalCalculators_weight_label"),
            inputProps: {
              type: "number",
              iconLeft: <WeightIcon className="w-4" />,
              placeholder: t("clinicalCalculators_weight_placeholder"),
            },
          },
          {
            id: "height",
            label: t("clinicalCalculators_height_label"),
            inputProps: {
              type: "number",
              iconLeft: <HeightIcon className="w-4" />,
              placeholder: t("clinicalCalculators_height_placeholder"),
            },
          },
          {
            id: "creatinine",
            label: t("clinicalCalculators_creatinine_label"),
            inputProps: {
              type: "number",
              iconLeft: <CreatinineClearanceIcon className="w-4" />,
              placeholder: t("clinicalCalculators_creatinine_placeholder"),
            },
          },
        ]}
      />
      <AttentionModal calculatorType={ClinicalCalculatorsSubSection.gentamicinDose} />
      <Modal open={!!(state.result && state.values)} onClose={() => setState({})}>
        {state.result && state.values && (
          <ResultsModal
            handleClose={() => setState({})}
            result={state.result}
            values={state.values}
            type={ClinicalCalculatorsSubSection.gentamicinDose}
          />
        )}
      </Modal>

      <WarningModal
        isOpen={!!warning}
        title="Warning Message"
        text={warning}
        confrimTitle="Got it"
        onConfirm={() => setWarningMessage("")}
      />
    </InnerPageWrapper>
  );
};
