import { useState, useEffect } from "react";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import { AnalyticsEvents, Space, UserLocation, userStore } from "@eolas-medical/core";
import { Option } from "UIKit/Select/Select";
import { useBrowserCountry } from "Hooks/useBrowserCountry";
import { trackEvent } from "API/Analytics";
import { useSpaceDetailsStep } from "./components/SpaceDetails/useSpaceDetailsStep";
import { useSpaceImageStep } from "./components/SpaceImage/useSpaceImageStep";
import { useSpaceAccessTypeStep } from "Pages/Spaces/components/AccessTypeForm/useSpaceAccessTypeStep";
import useCreateSpace from "modules/spaces/data/useCreateSpace";
import { useSpaceTemplateSelectorStep } from "./components/TemplateSelector/useSpaceTemplateSelectorStep";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

type CreateSpaceSteps = "templateSelector" | "details" | "image" | "access";

interface WizardState {
  name: string;
  description: string;
  specialty?: Option[];
  location?: UserLocation;
  url: string;
  spaceTemplateId: string;
}
interface CreateSpaceModalProps {
  onCreate: (space: Space) => void;
}

const CreateSpaceModal = ({ onCreate }: CreateSpaceModalProps) => {
  const { createSpace, creatingSpace, errorCreatingSpace } = useCreateSpace();
  const { browserCountryCode } = useBrowserCountry();
  const { wizardControl, submitWizard } = useWizard<CreateSpaceSteps>();
  const [isPublic, setIsPublic] = useState(true);
  const [wizardState, setWizardState] = useState<WizardState>({
    name: "",
    description: "",
    location: { country: "", city: "" },
    specialty: [],
    url: "",
    spaceTemplateId: "5",
  });
  const { flags } = useLaunchDarkly();

  const { userID = "" } = userStore;

  const showTemplateSelectorStep = flags[LDFlagNames.SHOW_CREATE_SPACE_TEMPLATE_STEP] ?? false;

  useEffect(() => {
    // If the template selector step should not be shown, ensure the default template ID is set
    if (!showTemplateSelectorStep) {
      setWizardState((prevState) => ({
        ...prevState,
        spaceTemplateId: "5",
      }));
    }
  }, [showTemplateSelectorStep]);

  const handleCreateSpace = () => {
    const spaceSpecialties = wizardState?.specialty
      ? wizardState?.specialty.map(({ value }: Option) => value)
      : [];

    createSpace(
      {
        ...wizardState,
        accessType: isPublic ? "public" : "private",
        specialty: spaceSpecialties,
        countryCode: browserCountryCode,
        userId: userID,
      },
      {
        onSuccess: (createdSpace: Space) => {
          onCreate(createdSpace);
        },
      },
    );
  };

  const handleSubmitImageStep = ({ image, imageUrl }: { image?: File; imageUrl?: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      file: image,
      url: imageUrl || "",
    }));
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_IMAGE_COMPLETED);
  };

  const handleSubmitAccessTypeStep = () => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_ACCESS_TYPE_COMPLETED);
    handleCreateSpace();
  };

  const handleSubmitSpaceDetails = ({
    name,
    location,
    specialty,
    description,
  }: {
    name: string;
    location?: UserLocation;
    description: string;
    specialty?: Option[];
  }) => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
    setWizardState((prev: WizardState) => ({
      ...prev,
      name,
      location,
      specialty,
      description,
    }));
  };

  const handleChangeAccessType = ({ isPublic }: { isPublic: boolean }) => {
    setIsPublic(isPublic);
  };

  const handleChangeTemplate = ({ spaceTemplateId }: { spaceTemplateId: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      spaceTemplateId,
    }));
    submitWizard();
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_TEMPLATE_SELECTED);
  };

  const spaceTemplateSelectorStep = useSpaceTemplateSelectorStep({
    onSubmit: handleChangeTemplate,
    spaceTemplateId: wizardState.spaceTemplateId ? wizardState.spaceTemplateId : undefined,
    isLoading: creatingSpace,
  });

  const spaceDetailsStep = useSpaceDetailsStep({
    onSubmit: handleSubmitSpaceDetails,
    onEnter: submitWizard,
  });

  const spaceImageStep = useSpaceImageStep({
    onSubmit: handleSubmitImageStep,
  });

  const spaceAccessTypeStep = useSpaceAccessTypeStep({
    onSubmit: handleSubmitAccessTypeStep,
    spaceName: wizardState.name,
    onChange: handleChangeAccessType,
    isPublic,
    isLoading: creatingSpace,
    error: errorCreatingSpace,
  });

  const getStepClassName = (step: string) => {
    if (step === "templateSelector") {
      return "bg-white bg-blue-wave bg-cover";
    }
    return "";
  };

  const steps: Step<CreateSpaceSteps>[] = showTemplateSelectorStep
    ? [spaceTemplateSelectorStep, spaceDetailsStep, spaceImageStep, spaceAccessTypeStep]
    : [spaceDetailsStep, spaceImageStep, spaceAccessTypeStep]; // Exclude the template selector step if flag is off

  return (
    <Wizard
      wizardControl={wizardControl}
      steps={steps}
      showProgress
      showSteps
      getStepClassName={getStepClassName}
    />
  );
};

export default CreateSpaceModal;
