import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AssignUserModal from "UIKit/Modal/AssignUserModal/AssignUserModal";
import { Assessor } from "modules/portfolio/types";
import { Avatar } from "UIKit";
import useRecentlyAssingedAssessors from "modules/portfolio/data/useRecentlyAssignedAssessors";
import useSupervisorsList from "modules/portfolio/data/useSupervisorsList";

type AssignAssessorModalProps = {
  ownerId: string;
  onCancel: () => void;
  onSubmit: (email: string) => void;
  isLoading: boolean;
  userEmail: string;
};

const AssignSupervisorModal = ({
  ownerId,
  onCancel,
  onSubmit,
  isLoading,
  userEmail,
}: AssignAssessorModalProps) => {
  const { t } = useTranslation();
  const { recentlyAssingedAssessors = [], recentlyAssignedLoading } =
    useRecentlyAssingedAssessors(ownerId);

  const { supervisorsRequestList, supervisorsList } = useSupervisorsList(ownerId);

  const handleSubmitModal = useCallback(
    (email: string) => {
      onSubmit(email);
    },
    [onSubmit],
  );

  const renderRecentlyAssignedUser = useCallback(
    ({ email }: Assessor) => (
      <div className="flex flex-1 items-center space-x-4">
        <Avatar label={email} isLoading={recentlyAssignedLoading} />
      </div>
    ),
    [recentlyAssignedLoading],
  );

  const currentlyAssignedSupervisors = useMemo(() => {
    const uniqueSupervisorsEmails = [
      ...new Set([
        ...supervisorsRequestList.map(({ email }) => email),
        ...supervisorsList.map(({ email }) => email),
      ]),
    ];

    const supervisorsWithEmails = uniqueSupervisorsEmails.map((email) => ({ email }));

    return supervisorsWithEmails;
  }, [supervisorsRequestList, supervisorsList]);

  return (
    <AssignUserModal<Assessor>
      assignTitleText={t("portfolio_supervisor_add_title")}
      descriptionText={t("portfolio_supervisor_add_description")}
      placeholderText={t("portfolio_supervisor_add_placeholder")}
      submitAssignButtonText={t("portfolio_supervisor_add_submit")}
      cancelButtonText={t("portfolio_supervisor_add_cancel")}
      assignButtonText={t("portfolio_supervisor_add_assign")}
      removeButtonText={t("portfolio_supervisor_add_remove")}
      onSubmit={handleSubmitModal}
      onCancel={onCancel}
      isLoading={recentlyAssignedLoading}
      isSubmitting={isLoading}
      recentlyAssignedUsers={recentlyAssingedAssessors}
      assignerEmail={userEmail}
      renderUserAvatar={renderRecentlyAssignedUser}
      currentAssignedUsers={currentlyAssignedSupervisors}
      assignedUsersErrorText={t("portfolio_supervisor_assigned_users")}
    />
  );
};

export default AssignSupervisorModal;
