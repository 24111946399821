import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { AddEolasEditorItemDto } from "../types";
import { createResourcesManifestFromHtml, mapToAddEolasEditorDto } from "../helpers";

import { useState } from "react";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseAddEolasEditorItemProps<T, R> {
  mainSectionId: string;
  parentId: string;
  onSuccess: (params?: T) => Promise<R>;
}

const useAddEolasEditorItem = <T, R>({
  mainSectionId,
  parentId,
  onSuccess,
}: UseAddEolasEditorItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const addContentItem = async ({
    name,
    description,
    eolasEditorContent,
    createdBy,
    expiryDate,
    isDraft,
  }: AddEolasEditorItemDto) => {
    const resourcesManifest = await createResourcesManifestFromHtml(eolasEditorContent);

    const eolasEditorItemDto = mapToAddEolasEditorDto({
      createdBy,
      parentId,
      name,
      expiryDate,
      description,
      eolasEditorContent,
      isDraft,
      isKnowledge: false,
      resourcesManifest: resourcesManifest,
    });
    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const result: any = await contentClient.addContentItem(mainSectionId, eolasEditorItemDto);

    setUploadProgress(50);
    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentItemDto: AddEolasEditorItemDto) => addContentItem(contentItemDto),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  return {
    addEolasEditorItem: mutate,
    addingEolasEditorItem: isLoading,
    addEolasEditorItemSuccess: isSuccess,
    addEolasEditorItemError: error instanceof Error ? error.message : "",
    addEolasEditorItemProgress: uploadProgress,
  };
};

export default useAddEolasEditorItem;
