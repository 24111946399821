import { GreenTickIcon } from "Assets";
import { Button } from "UIKit/Button";
import { FormError, InnerModalWrapper, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import useMarkAllAsRead from "modules/contentRepository/data/useMarkAllAsRead";

interface MarkAllAsReadModalProps {
  onClose: () => void;
  mainSectionId: string;
}
const MarkAllAsReadModal = ({ onClose, mainSectionId }: MarkAllAsReadModalProps) => {
  const { t } = useTranslation();
  const { markAllAsRead, markAllAsReadError, markingAsReadInProgress } = useMarkAllAsRead({
    sectionID: mainSectionId,
  });

  const handleOnClickMarkAll = () => {
    markAllAsRead(undefined as void, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <InnerModalWrapper>
      <div className="p-10 flex flex-col items-center justify-center space-y-4">
        <GreenTickIcon className="w-20 h-20 mr-0 sm:mr-2" />
        <Title level={6} className="text-center">
          {t("newsFeed_complete_all")}?
        </Title>
        <Text level={1} className="text-grey-600">
          {t("newsFeed_complete_all_confirmation")}
        </Text>
        <Button
          color="blue"
          isLoading={markingAsReadInProgress}
          onClick={handleOnClickMarkAll}
          disabled={markingAsReadInProgress}
          className="self-center w-full sm:w-1/3 "
        >
          {t("newsFeed_complete_all")}
        </Button>
        <Button
          color="grey"
          variant="outline"
          disabled={markingAsReadInProgress}
          onClick={onClose}
          className="self-center w-full sm:w-1/3 "
        >
          {t("general_cancel")}
        </Button>
        {markAllAsReadError && <FormError error={markAllAsReadError} />}
      </div>
    </InnerModalWrapper>
  );
};

export default MarkAllAsReadModal;
