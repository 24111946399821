import { Button, InnerModalWrapper, Loader, ModalBody, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { CreateSpaceUserOutcome } from "../functions/createSpaceUser";
import { useMediaQuery } from "Hooks";
import { appConfig } from "AppTypeConfig";
import { apple, google } from "Assets";
import { useMemo } from "react";

export type ModalState = "loading" | "explanation" | "pleaseLogin" | CreateSpaceUserOutcome;

export const AutoAccessBaseModal = ({
  handleCloseModal,
  modalState,
  linkType,
}: {
  modalState: ModalState;
  handleCloseModal: () => void;
  linkType: "access" | "invite";
}) => {
  const { t } = useTranslation();
  const media = useMediaQuery();
  const isMobile = media === "xs";

  const stringsMapper: Record<ModalState, { title: string; desc: string }> = useMemo(
    () => ({
      loading: { title: "auto_access_title", desc: "auto_access_desc" },
      explanation: { title: "auto_access_granted_title", desc: "auto_access_granted_description" },
      error: { title: "auto_access_error_title", desc: "auto_access_error_desc" },
      alreadyExists: {
        title: "auto_access_existing_user_title",
        desc: "auto_access_existing_user_desc",
      },
      wrongAccount: {
        title: "auto_access_wrong_account_title",
        desc: "auto_access_wrong_account_desc",
      },
      pleaseLogin: {
        title: "auto_access_login_title",
        desc: linkType === "access" ? "auto_access_login_desc" : "auto_access_login_desc_invite",
      },
      deleted: {
        title: "auto_access_deleted_title",
        desc: "auto_access_deleted_desc",
      },
      success: {
        title: "auto_access_granted_title",
        desc: "auto_access_granted_description",
      },
      nil: { title: "auto_access_error_title", desc: "auto_access_error_desc" },
      inviteCompleted: { title: "auto_access_completed_title", desc: "auto_access_completed_desc" },
    }),
    [linkType],
  );

  return (
    <InnerModalWrapper>
      <ModalBody className="flex-col items-center">
        <Title level={5}>{t(stringsMapper[modalState].title)}</Title>
        {modalState === "pleaseLogin" && isMobile ? (
          <Text level={1} className="my-2">
            {t("auto_access_on_mobile")}
          </Text>
        ) : null}
        <Text level={1} className="my-4">
          {t(stringsMapper[modalState].desc)}
        </Text>
        {modalState === "pleaseLogin" && isMobile ? (
          <div className="flex row space-x-6 my-4">
            <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
              <img src={apple} className="h-12 rounded-md" alt="app-store" />
            </a>

            <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
              <img src={google} className="h-12 rounded-md" alt="play-store" />
            </a>
          </div>
        ) : null}
        {modalState === "loading" ? (
          <Loader size={40} />
        ) : (
          <div className="flex justify-center mt-4">
            <Button color="grey" type="submit" className="w-full" onClick={handleCloseModal}>
              {t(
                modalState === "pleaseLogin"
                  ? isMobile
                    ? "auto_access_alt_button_mobile"
                    : "auto_access_alt_button"
                  : "auto_access_button",
              )}
            </Button>
          </div>
        )}
      </ModalBody>
    </InnerModalWrapper>
  );
};
