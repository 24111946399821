import { Pack, makeLegalCategoryText } from "@eolas-medical/core";
import BnfPill from "../BnfPill/BnfPill";
import { PackCategoryTagsStrings } from "./PackCategoryTags.strings";

const PackCategoryTags = ({
  pack,
  controlledDrugLabel,
}: {
  pack: Pack;
  controlledDrugLabel: string | null;
}) => {
  const legalCategoryText = makeLegalCategoryText(pack.legalCategory);
  return (
    <div className="flex space-x-1">
      {legalCategoryText ? (
        <BnfPill value={legalCategoryText} className="py-0.25" valueClassname="text-2xs" />
      ) : null}
      {controlledDrugLabel ? (
        <BnfPill value={controlledDrugLabel} className="py-0.25" valueClassname="text-2xs" />
      ) : null}
      {pack.hospitalOnly ? (
        <BnfPill
          value={PackCategoryTagsStrings.hospOnly}
          className="py-0.25"
          valueClassname="text-2xs"
          type="child"
        />
      ) : null}
    </div>
  );
};

export default PackCategoryTags;
