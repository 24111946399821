import { Control, useController } from "react-hook-form";
import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { Editor as TinyMCEEditor } from "tinymce";

import { DeeplinkConfig } from "shared/components/EolasMCEEditor/hooks/useDeeplinkingSetup";
import { EolasEditorStep } from "UIKit/Wizard/templates/EolasEditorCreatorStep";
import { EditEditorValues } from "./hooks/useEditorContent";
import { WizardState } from "../../../types";

export const EditorComponent = ({
  state,
  control,
  sectionId,
  initialEditorContent,
  modalBodyRef,
}: {
  state: WizardState;
  control: Control<EditEditorValues>;
  sectionId: string;
  initialEditorContent: string;
  modalBodyRef?: RefObject<HTMLDivElement>;
}) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const {
    field: { onChange },
    formState: { errors },
  } = useController({ control, name: "content" });

  const [isLoading, setIsLoading] = useState(true);

  const editorConfigs: { deeplinkingConfig: DeeplinkConfig } = useMemo(
    () => ({
      deeplinkingConfig: {
        isEnabled: true,
        disabledIds: state.fileId ? [state.fileId] : undefined,
      },
    }),
    [state],
  );

  const onEditorInit = useCallback((_: unknown, editor: TinyMCEEditor) => {
    setIsLoading(false);
    editorRef.current = editor;
  }, []);

  return (
    <EolasEditorStep
      contentRepositoryId={sectionId}
      editorContent={initialEditorContent}
      isLoading={isLoading}
      error={errors["content"]?.message}
      onEditorInit={onEditorInit}
      onContentChange={onChange}
      modalBodyRef={modalBodyRef}
      editorConfigs={editorConfigs}
    />
  );
};
