import { useCallback, useState } from "react";
import { AxiosProgressEvent } from "axios";

import { useLaunchDarkly } from "Contexts";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { useSpacesContext } from "modules/spaces";
import { sectionStore } from "@eolas-medical/core";
import { generateS3FileKey } from "Utilities";
import { LDFlagNames } from "Utilities/types";

type UploadMediaProps = {
  mainSectionId: string;
  shouldAlwaysUploadAsPublic?: boolean;
  mediaName?: string;
  blob: File;
};

export const useUploadBlob = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { isPublic } = useSpacesContext();

  const { flags } = useLaunchDarkly();
  const shouldUseAppServicesEndpoints = Boolean(flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS]);

  const isLocationPublic = isPublic && Boolean(flags[LDFlagNames.PUBLIC_FILES]);

  const setProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.total) {
      return;
    }
    const percentCompleted = Math.round(progressEvent.loaded / progressEvent.total) * 100;
    setUploadProgress(percentCompleted);
  }, []);

  const uploadBlob = useCallback(
    async ({
      mainSectionId,
      shouldAlwaysUploadAsPublic,
      blob,
      mediaName,
    }: UploadMediaProps): Promise<
      | { type: "public"; url: string; isLegacy: false }
      | { type: "private"; s3Key: string; isLegacy: boolean }
    > => {
      if (shouldAlwaysUploadAsPublic || isLocationPublic) {
        const isOrganisationFile = sectionStore.hospitalSectionsOrder.some(
          ({ id }) => id === mainSectionId,
        );
        const { publicUrl } = await uploadFileV2({
          file: blob,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isOrganisationFile,
          isPublic: true,
        });

        if (!publicUrl) {
          throw new Error("Unable to get public url in mutateContentItem");
        }
        return { type: "public", url: publicUrl, isLegacy: false };
      } else {
        if (shouldUseAppServicesEndpoints) {
          const s3Params = {
            isPublic: false,
            fileName: mediaName || "",
            fileFormat: blob.type,
            mainSectionId,
            addExtension: true,
          };
          const s3Key = generateS3FileKey(s3Params);
          await uploadFile(s3Key, blob, setProgress);
          return { type: "private", s3Key, isLegacy: true };
        } else {
          const { mediaId } = await uploadFileV2({
            file: blob,
            mainSectionId,
            onUploadProgress: setUploadProgress,
          });
          return { type: "private", s3Key: mediaId, isLegacy: false };
        }
      }
    },
    [isLocationPublic, setProgress, shouldUseAppServicesEndpoints, uploadFile, uploadFileV2],
  );

  return { uploadBlob, setUploadProgress, uploadProgress, isLocationPublic };
};
