import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { InnerModalWrapper, Button, Text, IconButton, Checkbox } from "UIKit";
import { CheckIcon } from "Assets";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { ArrowLeftIcon, CaretRight } from "Assets/Icons/monocolored";
import { eolasLogger, sectionStore } from "@eolas-medical/core";
import { getIconUrl } from "modules/helpers";
import {
  SelectTileList,
  SelectTileListContext,
} from "Pages/Spaces/components/SelectTileList/SelectTileList";
import { ListItem } from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";
import { useManageLimitedAdminModal } from "./hooks/useManageLimitedAdminModal";
import { EolasCheckBox } from "UIKit/EolasCheckBox/EolasCheckBox";

type ManageLimitedAdminModalProps = {
  isLoading: boolean;
  onSubmit: () => void;
  onChange: (selectedIds: Record<string, ListItem>) => void;
  currentSelection: string[];
  isMutationError: boolean;
};

export const ManageLimitedAdminModal = observer(
  ({
    currentSelection,
    isLoading,
    isMutationError,
    onChange,
    onSubmit,
  }: ManageLimitedAdminModalProps) => {
    const { t } = useTranslation();

    const {
      initialSelection,
      isUpdateButtonDisabled,
      currentSelectedItems,
      shouldShowBackArrow,
      renderItems,
      handleNavigateDown,
      handleNavigateUp,
      shouldConfirm,
      onClickSubmit,
      setShouldConfirm,
    } = useManageLimitedAdminModal({ currentSelection, onSubmit });

    return (
      <InnerModalWrapper>
        <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-70vh w-full">
          <Text level={2} className="text-gray-500">
            {t(shouldConfirm ? "manageUsers_sections_check_desc" : "manageUsers_sections_desc")}
          </Text>
          {shouldShowBackArrow ? (
            <div className="w-full">
              <IconButton
                color="white"
                variant="rounded"
                onClick={handleNavigateUp}
                icon={<ArrowLeftIcon className="w-5 h-5" />}
              />
            </div>
          ) : null}
          <SelectTileList
            data={shouldConfirm ? currentSelectedItems : renderItems}
            selectionMode="multi"
            onSelectionChange={onChange}
            renderTile={LimitedAdminSectionItem}
            handleNavigateDown={handleNavigateDown}
            isSearchable={false}
            initialSelection={initialSelection ? initialSelection : undefined}
          />
        </div>
        <div className="w-full flex gap-2 items-center justify-center absolute bottom-6 sm:bottom-12">
          <Button
            size="lg"
            color="grey"
            variant="solid"
            disabled={!shouldConfirm}
            onClick={() => setShouldConfirm(false)}
            className={"w-1/4 xs:w-1/3"}
          >
            {t("manageUsers_sections_go_back")}
          </Button>
          <Button
            size="lg"
            color="blue"
            variant="solid"
            disabled={isUpdateButtonDisabled || isLoading}
            onClick={onClickSubmit}
            isLoading={isLoading}
            className={"w-1/4 xs:w-1/3"}
          >
            {t(shouldConfirm ? "manageUsers_sections_update" : "manageUsers_sections_check")}
          </Button>
        </div>
        {isMutationError ? (
          <Text level={2} className="text-red-500 flex flex-col items-center p-6 w-full">
            {t("manageUsers_sections_error")}
          </Text>
        ) : null}
      </InnerModalWrapper>
    );
  },
);

const LimitedAdminSectionItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ListItem, SelectTileListContext<ListItem>>) => {
  const { handleNavigateDown, selectedItems, handleAddToSelection, handleRemoveFromSelection } =
    context;
  const isSelected = Boolean(selectedItems && selectedItems[item.id]);

  const handleToggle = () => {
    if (isSelected) {
      handleRemoveFromSelection(item.id);
      return;
    }
    handleAddToSelection(item.id, item);
  };

  if (item.childrenType === "none" || item.childrenType === "mainSections") {
    eolasLogger.error(new Error("ManageLimitedAdminModal: children type should only be sections"), {
      item,
    });
    return null;
  }

  const sectionChildRef = item.item;

  const mainSectionId = sectionStore.getSection(item.id)?.mainSectionID;

  const mainSectionChildRef = mainSectionId
    ? sectionStore.getChildReferenceOfSection(mainSectionId)
    : null;

  const isGenericContentRepo = mainSectionChildRef?.identity === "genericContentRepository";

  return (
    <div className="pb-2">
      <EolasSectionTile
        isDisabled={item.isDisabled}
        variant="withChildDetails"
        title={sectionChildRef.name || ""}
        iconUrl={getIconUrl(sectionChildRef.icon || "")}
        childrenType={isGenericContentRepo ? item.childrenType : undefined}
        rightAction={
          <>
            {isGenericContentRepo && item.childrenType === "sections" ? (
              <Caret isDisabled={isSelected} onClick={() => handleNavigateDown(item)} />
            ) : null}
            {<EolasCheckBox isChecked={isSelected} onToggle={handleToggle} />}
          </>
        }
      />
    </div>
  );
};

const Caret = ({ onClick, isDisabled }: { onClick: () => void; isDisabled: boolean }) => {
  return (
    <div
      onClick={isDisabled ? undefined : onClick}
      className={`rounded-full border-2 ${
        isDisabled
          ? "border-blue-100"
          : "cursor-pointer select-none border-blue-300 hover:shadow-focus"
      } overflow-hidden`}
    >
      <CaretRight className={`h-5 w-5 text-white ${isDisabled ? "bg-blue-100" : "bg-blue-500"}`} />
    </div>
  );
};
