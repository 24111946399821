import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BlobContent, FileExtensionMediaType } from "@eolas-medical/core";
import { Button, NavButton } from "UIKit";
import { isVideoType } from "Utilities/fileHelpers";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useFileDownload } from "shared/hooks/useFileDownload";
import VideoViewer from "./VideoViewer/VideoViewer";
import DocumentViewer from "./DocumentViewer/DocumentViewer";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";

interface BlobViewerProps {
  blob: BlobContent;
  backBehaviour?: "goBack" | "goHome";
}

const BlobViewer = ({ blob, backBehaviour = "goBack" }: BlobViewerProps) => {
  const { t } = useTranslation();
  const { toggleDrawerHidden, drawerHidden } = useEolasNavigation();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  useEffect(() => {
    toggleDrawerHidden(true);

    return () => {
      toggleDrawerHidden(false);
    };
  }, [toggleDrawerHidden]);

  const getFile = async () => {
    return {
      fileName: blob.name,
      fileUrl: blob.url || "",
      fileExt: blob.media.type,
    };
  };

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const handleDownloadFile = () => {
    downloadFile(blob.id, {
      onSuccess: () => {
        return;
      },
    });
  };

  if (!drawerHidden) {
    return null;
  }

  return (
    <div className="h-100vh w-100vw" data-testid="blob-viewer">
      <div className="bg-grey-light py-1 px-2 flex flex-col justify-center gap-2">
        <div className="flex justify-center items-center space-x-3">
          <NavButton onClick={handleGoBack} />
          <Button size="sm" onClick={handleDownloadFile}>
            {t("general_download_original")}
          </Button>
        </div>
      </div>
      <div className="bg-grey-light max-h-90vh overflow-y-auto">
        {isVideoType(blob.media.type as FileExtensionMediaType) ? (
          <VideoViewer video={blob} />
        ) : (
          <DocumentViewer document={blob} />
        )}
      </div>
    </div>
  );
};

export default BlobViewer;
