import { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { AnalyticsEvents, AppLevelSection } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { useHighlightFile, useMainSectionVisit } from "Hooks";
import { Modal, NavButton, PageTitle, InnerPageWrapper, AddButton } from "UIKit";
import { SearchSortList, SortFn } from "shared/components/Lists";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { DeleteFileModal } from "Components";
import useNewsFeeds from "../../data/useNewsFeeds";
import AdminNewsFeedTile from "./components/AdminNewsFeedTite/AdminNewsFeedTile";
import { NewsFeed } from "../../types";
import {
  AddNewsItemModal,
  NewsItemStatsModal,
  NewsItemViewModal,
} from "Pages/Spaces/components/NewsItems";
import TotalOpenRates from "UIKit/TotalOpenRates/TotalOpenRates";
import { LDFlagNames } from "Utilities/types";
import ItemAudienceModal from "UIKit/Modal/ItemAudienceModal/ItemAudienceModal";
import BoostOpenRatesModal from "./components/BoostOpenRatesModal";
import { ContentItemAnalytics } from "modules/contentRepository/data/useGetContentItemAnalytics";
import { useMainSectionAnalytics } from "../../hooks/useMainSectionAnalytics";
import { formatDate } from "Utilities";
import { trackEvent } from "API/Analytics";

type ModalState =
  | "addNewsItem"
  | "viewChart"
  | "viewFile"
  | "deleteFile"
  | "itemAudience"
  | "boostOpenRates";

export const AdminNewsFeed = observer(() => {
  const [sortMethod, setSortMethod] = useState<SortFn>();
  const [selectedNewsFeed, setSelectedNewsFeed] = useState<NewsFeed>();
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const { url } = useRouteMatch();
  useMainSectionVisit({ mainSectionId: AppLevelSection.newsFeed });
  const { flags } = useLaunchDarkly();

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.newsFeed);
  const { toggleHighlight } = useHighlightFile();

  const { newsFeeds } = useNewsFeeds();

  const {
    mainSectionAnalytics,
    isLoadingMainSectionAnalytics,
    sendMainSectionReminder,
    sendingMainSectionReminder,
    sendMainSectionReminderSuccess,
    resetMainSectionReminder,
    sendContentItemReminder,
    sendingContentItemReminder,
    sendContentItemReminderSuccess,
    resetContentItemReminder,
    downloadItemRateReport,
    downloadingItemRateReport,
  } = useMainSectionAnalytics({ mainSectionId: spaceSection.id });

  const showAnalyticsNewsFeed = flags[LDFlagNames.ANALYTICS_NEWSFEED];
  const showOpenRateTrendNewsFeed = flags[LDFlagNames.NEWSFEED_OPEN_RATE_TREND];

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: newsFeeds,
    keysToSearch: ["name", "createdBy"],
  });

  const items = useMemo(() => searchResult.slice().sort(sortMethod), [sortMethod, searchResult]);

  const hasNewsFeed = newsFeeds.length > 0;

  const canSendReminder = mainSectionAnalytics?.readRatio !== "100";

  const handleCloseModal = useCallback(() => {
    setModalState(null);
  }, []);

  const handleCompleteMainSectionReminder = () => {
    resetMainSectionReminder();
    handleCloseModal();
  };

  const handleCompleteContentItemReminder = () => {
    resetContentItemReminder();
    handleCloseModal();
  };

  const handlePrintReport = ({
    contentItemAnalytics,
    contentItem,
  }: {
    contentItemAnalytics?: ContentItemAnalytics;
    contentItem: NewsFeed;
  }) => {
    const itemRate = {
      ...contentItemAnalytics,
      fileName: contentItem.name,
      author: contentItem.createdBy || "",
      createdAt: formatDate(contentItem.createdAt),
      totalUsers: contentItemAnalytics?.totalUsers?.toString(),
    };

    downloadItemRateReport({ mainSectionId: spaceSection.id, contentId: contentItem.id, itemRate });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_PRINT_REPORT, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handleAddNewsFeed = () => {
    setModalState("addNewsItem");
  };

  const handleViewAudience = (newsFeed: NewsFeed) => {
    setSelectedNewsFeed(newsFeed);
    setModalState("itemAudience");
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_VIEW_ITEM_READ_LIST, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handleClickFile = (newsItem: NewsFeed) => {
    setSelectedNewsFeed(newsItem);
    setModalState("viewFile");
  };

  const handleViewChart = (newsFeed: NewsFeed) => {
    setSelectedNewsFeed(newsFeed);
    setModalState("viewChart");
  };

  const handleSendMainSectionReminder = () => {
    sendMainSectionReminder({ mainSectionId: spaceSection.id });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_SEND_BOOST_OPEN_RATE, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handleSendContentItemReminder = (contentId: string) => {
    sendContentItemReminder({ mainSectionId: spaceSection.id, contentId });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_SEND_READ_ITEM_REMINDER, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handleDeleteFile = (newsFeed: NewsFeed) => {
    setSelectedNewsFeed(newsFeed);
    setModalState("deleteFile");
  };

  const handleBoostOpenRatesModal = () => {
    setModalState("boostOpenRates");
  };

  const renderAdminNewsFeedTile = (newsFeed: NewsFeed) => (
    <AdminNewsFeedTile
      newsFeed={newsFeed}
      onClick={handleClickFile}
      onDelete={handleDeleteFile}
      onHighlight={() => toggleHighlight(newsFeed)}
      onViewChart={handleViewChart}
      onViewAudience={handleViewAudience}
      showAnalyticsNewsFeed={showAnalyticsNewsFeed}
    />
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("newsFeed_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.newsFeed}`, "")} />
        {showAnalyticsNewsFeed && (
          <TotalOpenRates
            average={mainSectionAnalytics?.readRatio || "0"}
            onOpenRate={handleBoostOpenRatesModal}
            showOpenRateTrend={showOpenRateTrendNewsFeed}
            showBoostOpenRate={hasNewsFeed && canSendReminder}
            isLoading={isLoadingMainSectionAnalytics}
          />
        )}
        <AddButton onClick={handleAddNewsFeed} data-testid="add-new-file">
          {t(`${AppLevelSection.newsFeed}_add_new_file`)}
        </AddButton>

        <SearchSortList<NewsFeed>
          items={items}
          defaultSort="date"
          renderItem={renderAdminNewsFeedTile}
          isSearchable
          isSortable
          onSearchInputChange={onSetSearchInput}
          onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
        />
      </InnerPageWrapper>

      <Modal
        open={modalState !== null}
        onClose={handleCloseModal}
        shouldCloseOnOverlayClick={false}
      >
        {modalState === "itemAudience" && (
          <ItemAudienceModal
            item={selectedNewsFeed as NewsFeed}
            onSendReminder={handleSendContentItemReminder}
            isLoading={sendingContentItemReminder}
            isDownloadingReport={downloadingItemRateReport}
            onCompleteSuccess={handleCompleteContentItemReminder}
            onPrintReport={handlePrintReport}
            isSuccess={sendContentItemReminderSuccess}
            canSendReminder={canSendReminder}
          />
        )}
        {modalState === "boostOpenRates" && (
          <BoostOpenRatesModal
            onSendReminder={handleSendMainSectionReminder}
            isLoading={sendingMainSectionReminder}
            isSuccess={sendMainSectionReminderSuccess}
            onCompleteSuccess={handleCompleteMainSectionReminder}
            onClose={handleCloseModal}
          />
        )}
        {modalState === "addNewsItem" && (
          <AddNewsItemModal
            onCloseModal={handleCloseModal}
            mainSectionID={AppLevelSection.newsFeed}
          />
        )}
        {modalState === "viewChart" && (
          <NewsItemStatsModal
            newsItem={selectedNewsFeed as NewsFeed}
            sectionType={AppLevelSection.newsFeed}
            onSendReminder={handleSendContentItemReminder}
            canSendReminder={canSendReminder}
            onCompleteSuccess={handleCompleteContentItemReminder}
            isLoading={sendingContentItemReminder}
            isSuccess={sendContentItemReminderSuccess}
          />
        )}
        {modalState === "viewFile" && <NewsItemViewModal newsItem={selectedNewsFeed as NewsFeed} />}
        {modalState === "deleteFile" && (
          <DeleteFileModal file={selectedNewsFeed as NewsFeed} onCloseModal={handleCloseModal} />
        )}
      </Modal>
    </>
  );
});
