import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { sectionStore, userStore } from "@eolas-medical/core";

import { CheckIcon, AttentionIcon, EducationPortalIcon, EnvelopeIcon } from "Assets";
import { Text, Title, Button, ModalHeader, InnerModalWrapper, ModalBody } from "UIKit";

import { useTrackedFiles } from "../../hooks/useTrackedFiles";
import useSpaceCompletedContentItems from "../../data/useSpaceCompletedContentItems";
import { SpaceUser } from "clients/departments/types";

interface UserDetailsModalProps {
  appUser: SpaceUser;
  onCloseModal(): void;
}

export const UserDetailsModal: React.FC<UserDetailsModalProps> = observer(({ appUser }) => {
  const { t } = useTranslation();
  const isYou = userStore.appUserID === appUser.id;
  const userName = `${appUser.user?.given_name} ${appUser.user?.family_name} ${
    isYou ? "(you)" : ""
  }`;

  const { appID } = sectionStore;

  const { completedContentItems } = useSpaceCompletedContentItems({
    spaceId: appID,
    userId: appUser.userID,
  });

  const trackedFiles = useTrackedFiles(completedContentItems);

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{userName}</Title>
      </ModalHeader>

      <ModalBody>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 sm:mb-8">
          <div className="flex flex-col">
            <Text level={1} className="font-bold">
              {t("manageUsers_accessRequest_userDetails_email")}
            </Text>
            <Text level={2}>{appUser.user?.email}</Text>
          </div>

          <div className="flex flex-col">
            <Text level={1} className="font-bold">
              {t("manageUsers_accessRequest_userDetails_accessLevel")}
            </Text>
            <Text level={2}>{appUser.accessLevel}</Text>
          </div>
        </div>

        {trackedFiles.length > 0 && (
          <>
            <div className="flex items-center">
              <Text level={1} className="font-bold">
                {t("manageUsers_tracked_files")}
              </Text>
              <EducationPortalIcon className="h-6 w-6 ml-4" />
            </div>

            <div className="flex flex-col divide-y divide-grey-200 overflow-y-auto max-h-60 mb-4 sm:mb-8">
              {trackedFiles.map(({ id, name, isCompleted }) => {
                return (
                  <div
                    key={id}
                    style={{ minHeight: "2.5rem" }}
                    className="flex justify-between items-center h-10"
                  >
                    <Text level={2} className="text-black">
                      {name}
                    </Text>

                    <div className="flex items-center">
                      <Text level={2} className="text-grey-700">
                        {t(
                          isCompleted ? "manageUsers_complete_file" : "manageUsers_incomplete_file",
                        )}
                      </Text>

                      {isCompleted ? (
                        <CheckIcon className="ml-2 text-green" height={16} width={16} />
                      ) : (
                        <AttentionIcon className="ml-2 text-red" height={16} width={16} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <Button
          color="grey"
          onClick={() => {
            const newWin = window.open(`mailto:${appUser?.user?.email}`);
            if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
              alert(t("popip_blocked_message"));
            }
          }}
        >
          {t("manageUsers_email_user")}
          <EnvelopeIcon width={20} height={20} className="ml-2" />
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
});
