import { GreenTickIcon, WarningIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Loader, Text } from "UIKit";
import { useDownloadSectionMap } from "Pages/Spaces/components/DownloadSectionMapModal/hooks/useDownloadSectionMap";
import { useTranslation } from "react-i18next";
import { useRunOnMountUnmount } from "Hooks";
import { trackEvent } from "API/Analytics";
import { Analytics } from "aws-amplify";
import { AnalyticsEvents, MainSectionContentMapGeneratedPayload } from "@eolas-medical/core";

type Props = {
  mainSectionId: string;
};

export const DownloadSectionMapModal = ({ mainSectionId }: Props) => {
  const { t } = useTranslation();
  const { isError, isLoading, downloadSectionStructure, s3DownloadUrl } = useDownloadSectionMap({
    mainSectionId,
  });

  useRunOnMountUnmount({
    onMount: async () => {
      trackEvent<MainSectionContentMapGeneratedPayload>(
        AnalyticsEvents.MAIN_SECTION_CONTENT_MAP_GENERATED,
        { mainSectionId },
      );
      await downloadSectionStructure();
    },
  });

  return (
    <InnerModalWrapper>
      <div className="flex flex-1 flex-col items-center justify-center p-8">
        {isLoading ? (
          <div className="flex items-center flex-col space-y-4 justify-center w-full h-full">
            <Loader />
            <Text level={2} className="text-center">
              {t("download_section_map_generating")}
            </Text>
          </div>
        ) : null}
        {isError ? (
          <div className="flex flex-col items-center space-y-4">
            <WarningIcon className="w-16 h-16" />
            <Text level={3} className="text-center text-red-500">
              {t("download_section_map_error")}
            </Text>
          </div>
        ) : null}
        {!isError && s3DownloadUrl ? (
          <div className="flex flex-col items-center space-y-4">
            <GreenTickIcon className="w-16 h-16" />
            <Text level={2} className="text-center">
              {t("download_section_map_success")}
            </Text>
          </div>
        ) : null}
        <div className="flex flex-row items-center space-x-4">
          <Button
            onClick={() => modalStore.closeModal()}
            className="mt-4"
            variant="solid"
            size="lg"
            color="grey"
          >
            {t("general_close")}
          </Button>

          {isError ? (
            <Button
              onClick={() => downloadSectionStructure()}
              className="mt-4"
              variant="solid"
              size="lg"
              color="blue"
            >
              {t("general_retry")}
            </Button>
          ) : null}

          {!isError && s3DownloadUrl ? (
            <Button
              onClick={() => window.open(s3DownloadUrl, "_self")}
              className="mt-4"
              variant="solid"
              size="lg"
              color="blue"
            >
              {t("download_section_map_success_button")}
            </Button>
          ) : null}
        </div>
      </div>
    </InnerModalWrapper>
  );
};
