import { StepToComponentAndPropsMap } from "../types";
import { selectFileData } from "./firstStepData";
import { confirmAndUploadData } from "../components/ConfirmAndUpload/ConfirmAndUpload";
import { fileDetailsData } from "../components/FileDetails/FileDetails";
import { flashcardDetailsData } from "../components/FlashCardComponents/FlashCardDetails/FlashCardDetails";
import { editFlashcardContentData } from "../components/FlashCardComponents/EditFlashcardContent/EditFlashcardContent";
import { selectHeroImageData } from "../components/FlashCardComponents/SelectHeroImage/SelectHeroImage";
import { eolasEditorCreatorData } from "../components/EolasEditorComponents/EolasEditorCreator/EolasEditorCreator";
import { dsmErrorComponentData } from "../components/DsmComponents/DsmErrorComponent";
import { summaryOfChangesData } from "../components/SummaryOfChanges/SummaryOfChanges";
import { editorFileDetailsData } from "../components/EolasEditorComponents/EolasEditorFileDetails";

export const stepToComponentAndPropsMap: StepToComponentAndPropsMap = {
  selectFile: selectFileData,
  fileDetails: fileDetailsData,
  confirmAndUpload: confirmAndUploadData,
  flashcardDetails: flashcardDetailsData,
  editFlashcardContent: editFlashcardContentData,
  selectFlashcardHeroImage: selectHeroImageData,
  eolasEditorCreator: eolasEditorCreatorData,
  dsmError: dsmErrorComponentData,
  summaryOfChanges: summaryOfChangesData,
  editorFileDetails: editorFileDetailsData,
};
