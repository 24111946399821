import { create, insertMultiple, search, removeMultiple } from "@orama/orama";
import { stopwords as englishStopwords } from "./localsearch.data";

import {
  AppLevelSection,
  EolasFile,
  EolasMainSection,
  fileStore,
  isEolasShadowCopyFile,
  MasterSearchFile,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { LocalFilesSearchDb, LocalSearchFile, localSearchSchema } from "./localSearch.types";

import { makeChecklistMasterSearchFile } from "Components/MasterSearch/helpers";
import { eolasFileNormaliser } from "Utilities/helpers";
import { LDFlags } from "Utilities/types";
import { parseMedusaMetadata } from "Utilities";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { sectionToFlags } from "Hooks";

export const createLocalFilesDb = async () => {
  const db: LocalFilesSearchDb = await create({
    schema: localSearchSchema,
    components: {
      tokenizer: {
        stopWords: englishStopwords,
      },
    },
  });
  return db;
};

export const populateLocalFilesDb = async (
  args:
    | {
        searchDb: LocalFilesSearchDb;
        action: "addAll";
      }
    | {
        action: "update";
        searchDb: LocalFilesSearchDb;
        checklistIdsRemoved: string[];
        checklistIdsAdded: string[];
        fileIdsAdded: string[];
        fileIdsRemoved: string[];
      },
) => {
  if (
    args.action === "update" &&
    !args.fileIdsAdded.length &&
    !args.fileIdsRemoved.length &&
    !args.checklistIdsAdded.length &&
    !args.checklistIdsRemoved.length
  ) {
    return;
  }

  const { searchDb } = args;

  let allLocalSearchFiles: LocalSearchFile[] = [];

  if (args.action === "addAll") {
    const checklistMasterSearchFiles: LocalSearchFile[] = [];

    Object.values(sectionStore.checklistTemplatesMap).forEach((checklist) => {
      const checklistFile = makeChecklistMasterSearchFile({ checklist });
      if (checklistFile) {
        checklistMasterSearchFiles.push(mapToLocalSearchFile(checklistFile));
      }
    });

    const allFiles = [...Object.values(fileStore.fileMap)]
      .filter(isFileForDb)
      .map(mapToLocalSearchFile);

    allLocalSearchFiles = [...checklistMasterSearchFiles, ...allFiles];
  } else if (args.action === "update") {
    const { fileIdsAdded, fileIdsRemoved, checklistIdsAdded, checklistIdsRemoved } = args;
    const totalToRemoveBeforeAdd = [
      ...fileIdsRemoved,
      ...fileIdsAdded,
      ...checklistIdsAdded,
      ...checklistIdsRemoved,
    ];
    if (totalToRemoveBeforeAdd.length) {
      await removeMultiple(searchDb, totalToRemoveBeforeAdd);
    }

    for (const id of fileIdsAdded) {
      const file = fileStore.fileMap[id];
      if (file && isFileForDb(file)) {
        allLocalSearchFiles.push(mapToLocalSearchFile(file));
      }
    }

    for (const id of checklistIdsAdded) {
      const checklist = sectionStore.checklistTemplatesMap[id];
      if (checklist) {
        const checklistFile = makeChecklistMasterSearchFile({ checklist });
        if (checklistFile) {
          allLocalSearchFiles.push(mapToLocalSearchFile(checklistFile));
        }
      }
    }
  }

  if (allLocalSearchFiles.length) {
    insertMultiple(searchDb, allLocalSearchFiles);
  }
};

export const searchWithDb = async ({
  term,
  ldFlags,
  localFilesDb,
  isInAdminMode,
  shouldSearchSpace = true,
  shouldSearchOrganisation,
  idsToInclude,
}: {
  term: string;
  localFilesDb: LocalFilesSearchDb;
  ldFlags: LDFlags;
  isInAdminMode: boolean;
  shouldSearchSpace?: boolean;
  shouldSearchOrganisation: boolean;
  idsToInclude?: Record<string, string>;
}) => {
  if (!term.length) {
    return [];
  }

  const masterSearchFiles: MasterSearchFile[] = [];

  const result = await search<LocalFilesSearchDb, LocalSearchFile>(localFilesDb, {
    term,
    properties: "*",
    threshold: 0.4,
    boost: { name: 4, searchField: 1 },
    limit: idsToInclude ? 100 : 50,
  });

  for (const { document } of result.hits) {
    if (idsToInclude && !idsToInclude[document.id]) {
      continue;
    }

    const eolasFile = fileStore.getFile(document.id);

    let masterSearchFile: MasterSearchFile | null = eolasFile
      ? { ...eolasFile, parentName: getParentName(eolasFile) }
      : null;

    if (!masterSearchFile) {
      masterSearchFile = makeChecklistMasterSearchFile({ id: document.id });
    }

    if (!masterSearchFile) {
      continue;
    }

    const { file } = eolasFileNormaliser(masterSearchFile);

    if (file.isDraft && !isInAdminMode) {
      continue;
    }

    const isMainSectionEnabled =
      sectionStore.getChildReferenceOfSection(file.mainSectionID)?.disabled !== "true";

    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);

    const flagProperty = mainSectionType ? sectionToFlags[mainSectionType] : null;

    const isLdFlagEnabled = flagProperty !== null && ldFlags[flagProperty] === true;

    const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
      file.mainSectionID,
    );

    const isGenericContentRepositoryItem = mainSectionIdentity === "genericContentRepository";

    let shouldInclude = isMainSectionEnabled && (isLdFlagEnabled || isGenericContentRepositoryItem);

    if (!shouldInclude) {
      continue;
    }

    if (mainSectionType === OrganisationLevelSection.medusaMedications) {
      const medusaMainSection = sectionStore.getSection(file.mainSectionID);
      const medusa = parseMedusaMetadata(medusaMainSection?.metadata);
      if (medusa?.status !== "enabled") {
        continue;
      }
    }

    const { isSpaceFile, isOrgFile } = getFileOwnerTypes(mainSectionType, file.mainSectionID);
    shouldInclude = (isSpaceFile && shouldSearchSpace) || (isOrgFile && shouldSearchOrganisation);

    if (shouldInclude) {
      masterSearchFiles.push(masterSearchFile);
    }
  }

  const includedOriginalIdMap: Record<string, string> = {};

  return masterSearchFiles.reduce<MasterSearchFile[]>((acc, current) => {
    const { file } = eolasFileNormaliser(current);
    if (isEolasShadowCopyFile(file)) {
      const originalFile = getOriginalFromShadowCopy(file, false);
      if (!originalFile || includedOriginalIdMap[originalFile.id]) {
        return acc;
      }
      includedOriginalIdMap[originalFile.id] = originalFile.id;
    } else {
      if (includedOriginalIdMap[current.id ?? ""]) {
        return acc;
      }
    }
    return [...acc, current];
  }, []);
};

const getFileOwnerTypes = (
  mainSectionType: EolasMainSection | null,
  mainSectionId: string,
): { isSpaceFile: boolean; isOrgFile: boolean } => {
  if (!mainSectionType) {
    if (sectionStore.departmentSectionsOrder.find(({ id }) => id === mainSectionId)) {
      return { isSpaceFile: true, isOrgFile: false };
    }
    if (sectionStore.hospitalSectionsOrder.find(({ id }) => id === mainSectionId)) {
      return { isSpaceFile: false, isOrgFile: true };
    }
    return { isSpaceFile: false, isOrgFile: false };
  }
  if (Object.values<string>(AppLevelSection).includes(mainSectionType)) {
    return { isSpaceFile: true, isOrgFile: false };
  }
  if (Object.values<string>(OrganisationLevelSection).includes(mainSectionType)) {
    return { isSpaceFile: false, isOrgFile: true };
  }
  return { isSpaceFile: false, isOrgFile: false };
};

// We should never be searching for completed checklists (this should be applied pre DB population)
const isFileForDb = (file: EolasFile) => {
  const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);
  return mainSectionType !== AppLevelSection.checklists;
};

const getParentName = <T extends MasterSearchFile>(file: T): string => {
  if (!file.parentID || file.parentID === file.mainSectionID) {
    return "";
  }
  return sectionStore.getChildReferenceOfSection(file.parentID)?.name ?? "";
};

const mapToLocalSearchFile = <T extends MasterSearchFile>(file: T): LocalSearchFile => {
  let searchField = file.searchField;

  if (!searchField) {
    searchField = `${file.name} ${getParentName(file)} ${
      file.keywords?.length ? file.keywords.join(" ") : ""
    }`;
  }

  return { name: file.name ?? "", searchField, id: file.id ?? "" };
};
