import { GeneralCategory, NiceSummaryItem } from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";
import { FavouriteToggle, Text, TileContainer } from "UIKit";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { isNiceSummaryItem } from "modules/niceGuidelines/typeguards";
import { useAddFavourite } from "shared/hooks/useAddFavourite";

interface NiceGuidelineTileProps {
  item: GeneralCategory | NiceSummaryItem;
  onClick: () => void;
  onShare?: () => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
  id?: string;
}

export const NiceGuidelineTile = ({
  item,
  onClick,
  onShare,
  showRemoveFavouriteModal,
}: NiceGuidelineTileProps) => {
  const shouldShowShare = !!onShare;
  const { isMarkingAsFavourite, handleAddFavourite, isMarkFavouriteEnabled, favouriteId } =
    useAddFavourite(item.id);

  const shouldShowFavouriteToggle = isNiceSummaryItem(item) && isMarkFavouriteEnabled;
  const { title, id } = item;
  return (
    <TileContainer onClick={onClick} className="h-20 md:h-24 justify-between">
      <Text className="line-clamp-3" level={1}>
        {title}
      </Text>
      {shouldShowFavouriteToggle || shouldShowShare ? (
        <div>
          {shouldShowFavouriteToggle && (
            <FavouriteToggle
              entityId={id}
              isLoading={!!isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: item.id,
                      favouriteId,
                      title,
                      entityType: FavouriteEntityType.NICE,
                    })
                  : handleAddFavourite({
                      item,
                      entityType: FavouriteEntityType.NICE,
                    })
              }
            />
          )}
          {shouldShowShare && <ShareFileButton onShareFile={onShare} id={title} />}
        </div>
      ) : null}
    </TileContainer>
  );
};
