import { useMutation } from "@tanstack/react-query";
import { WizardState } from "../../../types";
import { useSelectFileBlobStep } from "../../SelectFile/hooks/useSelectFileBlobStep";
import {
  DsmError,
  makeWorkbook,
} from "shared/pages/ContentRepository/ContentItems/functions/parseDsmSheet/helpers";
import { parseWorkbook } from "shared/pages/ContentRepository/ContentItems/functions/parseDsmSheet/parseWorkbook";
import { makeMutableWizardState } from "../../../functions/makeMutableWizardState";
import { toAwsJSON } from "Utilities";
import { NewContentType } from "@eolas-medical/core";

export const useSelectDsmBlobFirstStep = (state: WizardState) => {
  const { control, handleSubmit, mediaName } = useSelectFileBlobStep(state);

  const { mutateAsync: parseDsmData, isLoading } = useMutation({
    mutationKey: ["createDsmData"],
    mutationFn: async (blob: File) => {
      const newState = makeMutableWizardState(state);
      if (state.isExistingFileWithNewType) {
        state.dto.newType = NewContentType.DSM;
      }
      let dsmName = "";
      try {
        const workBook = await makeWorkbook(blob);
        const parsedData = parseWorkbook(workBook);
        if (newState.isExistingFileWithNewType) {
          if (newState.dto.newType === NewContentType.DSM) {
            newState.dto.newProperties.metadata = toAwsJSON({ dsmData: parsedData });
          }
        } else {
          newState.dto.metadata = toAwsJSON({ dsmData: parsedData });
        }
        newState.error = undefined;
        dsmName = parsedData.name;
        newState.blob = blob;
      } catch (error) {
        let dsmError: DsmError;
        if (error instanceof DsmError) {
          dsmError = error;
        } else {
          console.error(error);
          dsmError = new DsmError("An unknown error ocurred", "other");
        }
        if (newState.isExistingFileWithNewType && newState.dto.newType === NewContentType.DSM) {
          newState.dto.newProperties.metadata = "";
        }
        newState.error = dsmError;
      }
      return { newState, dsmName };
    },
  });

  return { handleSubmit, control, parseDsmData, isLoading, mediaName };
};
