import React, { ReactNode } from "react";

interface SquareIconProps {
  icon: ReactNode;
  backgroundColor?: string;
  height?: string;
  width?: string;
  className?: string;
}

export const SquareIcon: React.FC<SquareIconProps> = ({
  icon,
  height = "h-16",
  width = "w-16",
  backgroundColor = "grey-100",
  className = "",
}: SquareIconProps) => {
  return (
    <div
      className={`flex items-center justify-center ${height} ${width} rounded-md bg-${backgroundColor} ${className}`}
      data-testid="circle-icon"
    >
      {icon}
    </div>
  );
};
