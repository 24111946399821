import { InputTypeButton, Text } from "UIKit";
import { ChooseFileTypeItem, SupportedPickerType } from "../types";

export const SelectTypeComponent = ({
  typesToRender,
  setSelectedType,
  selectedType,
}: {
  typesToRender: ChooseFileTypeItem[];
  setSelectedType: (newSelection: SupportedPickerType) => void;
  selectedType: SupportedPickerType;
}) => {
  return (
    <div
      className={`flex flex-wrap ${
        typesToRender.length > 3 ? "grid grid-cols-2 gap-4" : "flex-col items-center justify-center"
      } mb-4 gap-2 md:flex-row`}
    >
      {typesToRender.map(({ type, label, icon: Icon }) => (
        <InputTypeButton
          key={type}
          isSelected={selectedType === type}
          icon={<Icon width={32} height={32} />}
          onClick={() => setSelectedType(type)}
          className={`w-full ${selectedType === type ? "shadow-md" : ""}`}
        >
          <Text level={1}>{label}</Text>
        </InputTypeButton>
      ))}
    </div>
  );
};
