import React, { useMemo, useRef } from "react";
import { Virtuoso, ItemProps } from "react-virtuoso";
import { overrideTailwindClasses as tw } from "tailwind-override";

import {
  DraggableItemProps,
  EolasVersatileListContext,
  EolasVersatileListProps,
} from "./EolasVersatileList.types";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import * as Utils from "./functions/helpers";
import { useTranslation } from "react-i18next";
import { Variants, motion } from "framer-motion";
import { ListEmptyComponent as DefaultListEmptyComponent } from "./components/ListEmptyComponent";
import { useSortOrder } from "Hooks/useSortOrder";
import { isDev } from "Utilities/helpers";
import { DragIcon } from "Assets/Icons/monocolored";
import { ListActionMenuComponent } from "./components/ListActionMenuComponent";
import {
  hasDraggableListProps,
  hasSelectableListProps,
  hasSortableListProps,
  hasSearchableListProps,
} from "./functions/typeguards";
import { ListSearchComponent } from "./components/ListSearchComponent";

// Virtuoso's resize observer can throw this error,
// which is caught by DnD and aborts dragging.
window.addEventListener("error", (e) => {
  if (
    e.message === "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const MotionContainer = motion.div;

const defaultContext = { isInitialLoading: false };

/**
 * A versatile list component that supports drag and drop, sorting, searching and more.
 * One list to sort them all, one list to find them, one list to drag them all and in the darkness select them!
 */
export const EolasVersatileList = <T, C extends EolasVersatileListContext<T>>(
  props: EolasVersatileListProps<T, C>,
) => {
  const {
    items,
    keyExtractor,
    ListEmptyComponent,
    scrollParentId = "page-container",
    defaultSortMethod = "dragAndDrop",
    disabledSortMethods = [],
    sortDateBy,
    onSortMethodChange,
    className = "",
    isInModal = false,
    outerContainerClassName = "",
    innerContainerClassName = "",
    // FIXME: Having to cast this as C is a bit annoying, but it's the only way to get the default value to work correctly
    context = defaultContext as C,
  } = props;
  const { t } = useTranslation();

  const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
    initialOrder: defaultSortMethod,
    isDraggable: !disabledSortMethods.includes("dragAndDrop"),
    sortDateBy: Utils.sortDateByToSortDateByOption(sortDateBy),
    favourites: !disabledSortMethods.includes("favourites"),
    onSortMethodChange,
  });

  const customScrollParent = document.getElementById(scrollParentId);

  const scrollParent = isInModal ? undefined : customScrollParent;

  const shouldAllowDragAndDrop =
    !!props.isDraggable && items.length > 1 && orderBy === "dragAndDrop";

  const shouldAllowSorting = hasSortableListProps(props) && items.length > 1;
  const shouldAllowSelecting =
    hasSelectableListProps(props) && items.length > 1 && props.selectedItems;
  const shouldShowSearch = hasSearchableListProps(props);

  const handleDragEnd = (res: DropResult) => {
    if (hasDraggableListProps(props)) {
      props.onDragEnd(res);
    }
  };

  const sortedItems: T[] = useMemo(() => {
    if (!props.isSortable) {
      return items;
    }

    if (sortMethod) {
      return items.slice().sort(sortMethod);
    }

    if (orderBy === "dragAndDrop") {
      return items;
    }

    if (isDev()) {
      // This error should not happen, if it does something has gone very wrong!
      console.error(
        "EolasVersatileList - No sort method found. Please provide a sort method or onSortMethodChange function.",
      );
    }

    return items;
  }, [sortMethod, items, orderBy, props.isSortable]);

  const enrichedContext = useMemo(
    () => ({
      ...context,
      selectedItems: props.selectedItems,
      isListDraggable: shouldAllowDragAndDrop,
      isListSelectable: shouldAllowSelecting,
      availableMenuActions: props.menuActions,
    }),
    [context, props.selectedItems, props.menuActions, shouldAllowDragAndDrop, shouldAllowSelecting],
  );

  return (
    <div data-testid="eolas-list" className={tw(`flex flex-col pb-8 h-full ${className}`)}>
      <div className="flex flex-col gap-3">
        {shouldShowSearch ? (
          <ListSearchComponent
            searchInstanceId={props.searchInstanceId}
            searchMode={props.searchMode}
            onClickSearch={props.onClickSearch}
            onClearSearch={props.onClearSearch}
            isSearchLoading={props.isSearchLoading}
            searchPlaceholder={props.searchPlaceholder}
          />
        ) : null}

        {shouldAllowSorting ? SortComponent : null}
        {shouldAllowDragAndDrop ? (
          <div
            className="items-center justify-center space-x-2 hidden sm:flex"
            data-testid="drag-message"
          >
            <DragIcon width={16} height={16} className="text-grey-500" />
            <span className="text-center text-grey-mid inline">
              {t("component_eolasVersatileList_drag_message")}
            </span>
          </div>
        ) : null}
        {shouldAllowSelecting ? (
          <ListActionMenuComponent
            menuActions={props.menuActions ?? []}
            selectedItems={props.selectedItems}
            allItems={props.items}
            onMenuAction={props.onMenuAction ?? Utils.defaultMenuAction}
            keyExtractor={keyExtractor}
          />
        ) : null}
      </div>

      <div className={tw(`w-full h-full ${outerContainerClassName}`)}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={hasDraggableListProps(props) ? props.droppableId : "disabled"}
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
              <DraggableItem
                provided={provided}
                isDragging={snapshot.isDragging}
                item={props.items[rubric.source.index]}
                renderItem={props.renderItem}
                context={enrichedContext}
              />
            )}
          >
            {(provided) => {
              return (
                <MotionContainer
                  animate="in"
                  initial="out"
                  ref={provided.innerRef}
                  variants={containerVariants}
                  className={tw(`${innerContainerClassName} w-full h-full`)}
                >
                  <Virtuoso<T, C>
                    context={enrichedContext}
                    totalCount={sortedItems.length}
                    data={sortedItems}
                    components={{
                      Item: HeightPreservingItem,
                      // @ts-expect-error - FIXME: Difficult get virtuoso to work with this but it's still safe
                      EmptyPlaceholder: ListEmptyComponent
                        ? ListEmptyComponent
                        : DefaultListEmptyComponent,
                    }}
                    customScrollParent={scrollParent ?? undefined}
                    itemContent={(index, item, context) => {
                      const itemId = keyExtractor
                        ? keyExtractor(item)
                        : Utils.defaultKeyExtractor(item, index);
                      return (
                        <Draggable
                          index={index}
                          key={itemId}
                          draggableId={itemId}
                          isDragDisabled={!shouldAllowDragAndDrop}
                        >
                          {(provided) => (
                            <DraggableItem
                              provided={provided}
                              renderItem={props.renderItem}
                              item={item}
                              isDragging={false}
                              context={context}
                            />
                          )}
                        </Draggable>
                      );
                    }}
                  />
                </MotionContainer>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

/**
 * A wrapper around the Virtuoso Item component that preserves the height of the item.
 * This is necessary so that the list doesn't jump around / resize when dragging items.
 */
const HeightPreservingItem = ({ children, ...props }: ItemProps) => {
  const prevSize = useRef(0);
  const knownSize = props["data-known-size"];

  let size: number = prevSize.current;

  if (knownSize > 0) {
    size = knownSize;
    prevSize.current = size;
  }

  return (
    <div
      {...props}
      style={{
        minHeight: `${size}px`,
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  );
};

const DraggableItem = <T, C extends EolasVersatileListContext<T>>({
  provided,
  isDragging,
  item,
  renderItem,
  context,
}: DraggableItemProps<T, C> & { context: C }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={provided.draggableProps.style}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      {renderItem({ item, context, isDragging })}
    </div>
  );
};
