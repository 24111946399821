import {
  ChildReference,
  ClinicalCalculatorsSubSection,
  CommunityLevelSection,
  EolasFile,
  NationalResourceEntityType,
  hasProp,
  isAxiosError,
  sectionStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { isClinicalCalculatorsSubSectionType } from "Pages/Knowledge/pages/ClinicalCalculators/functions/typeguards";
import { useGetNationalResource } from "modules/nationalResources/data/useGetNationalResource";
import { useMemo } from "react";

type NationalEntityParams = {
  entityType: NationalResourceEntityType;
  fileId: string;
  clinicalCalculatorsSubSectionType?: string;
};

type Props = {
  nationalEntityParams: NationalEntityParams | null;
  isEnabled?: boolean;
};

type UnavailableReason = "notFound" | "notInRegion" | "error";

type Return = {
  resource: EolasFile | ChildReference | null;
  resourceUnavailableReason: UnavailableReason | null;
  isLoading: boolean;
  error: unknown | null;
};

export const useCheckClinicalResource = (props: Props): Return => {
  const isGetNationalResourceEnabled = isGetNationalResourceHookEnabled(props);
  const { supportedEolasRegion } = useSupportedRegion();
  let eolasCalculatorChildRef: ChildReference | null = null;

  if (isEolasCalculatorResource(props)) {
    const clinicalCalculatorsChildRef = sectionStore.getChildReferenceByMainSectionType(
      CommunityLevelSection.clinicalCalculators,
    );
    if (clinicalCalculatorsChildRef) {
      const clinicalCalcMainSectionId = clinicalCalculatorsChildRef.id;
      const clinicalCalcsChildRefs = sectionStore.getChildrenOrder(clinicalCalcMainSectionId);
      eolasCalculatorChildRef =
        clinicalCalcsChildRefs.find((childRef) => {
          return childRef.icon === props.nationalEntityParams.clinicalCalculatorsSubSectionType;
        }) ?? null;
    }
  }

  const isEolasCalculatorUnavailable = isEolasCalculatorResource(props) && !eolasCalculatorChildRef;

  const {
    data: nationalResource,
    isLoading: isLoadingNationalResource,
    isError: isFetchNationalResourceError,
    error: nationalResourceError,
  } = useGetNationalResource({
    id: isGetNationalResourceEnabled ? props.nationalEntityParams.fileId : "",
    isEnabled: isGetNationalResourceEnabled,
  });

  const isLoading = isGetNationalResourceEnabled && isLoadingNationalResource;
  const error = nationalResourceError;

  const resourceUnavailableReason: UnavailableReason | null = useMemo(() => {
    if (isEolasCalculatorUnavailable) {
      return "notFound";
    }

    if (isGetNationalResourceEnabled && !isLoadingNationalResource && nationalResource) {
      if (
        hasProp(nationalResource, "regions") &&
        !nationalResource.regions.includes(supportedEolasRegion)
      ) {
        return "notInRegion";
      }
    }

    if (isError404(nationalResourceError)) {
      return "notFound";
    }

    if (isFetchNationalResourceError) {
      return "error";
    }

    return null;
  }, [
    nationalResourceError,
    isFetchNationalResourceError,
    isGetNationalResourceEnabled,
    isLoadingNationalResource,
    supportedEolasRegion,
    nationalResource,
    isEolasCalculatorUnavailable,
  ]);

  return {
    resource: nationalResource || eolasCalculatorChildRef || null,
    resourceUnavailableReason,
    isLoading,
    error,
  };
};

export function isResourceUnavailableReason(string: unknown): string is UnavailableReason {
  if (typeof string !== "string") {
    return false;
  }

  return ["notFound", "notInRegion", "error"].includes(string);
}

function isError404(error: unknown) {
  return isAxiosError(error) && error.response?.status === 404;
}

function isGetNationalResourceHookEnabled(
  props: Props,
): props is { isEnabled: true; nationalEntityParams: NationalEntityParams } {
  return (
    hasProp(props, "nationalEntityParams") &&
    props.isEnabled === true &&
    !props.nationalEntityParams?.clinicalCalculatorsSubSectionType
  );
}

type EolasCalculatorResourceProps = Props & {
  isEnabled: true;
  nationalEntityParams: NationalEntityParams & {
    clinicalCalculatorsSubSectionType: ClinicalCalculatorsSubSection;
  };
};

function isEolasCalculatorResource(props: Props): props is EolasCalculatorResourceProps {
  if (
    hasProp(props, "nationalEntityParams") &&
    props.isEnabled &&
    props.nationalEntityParams?.clinicalCalculatorsSubSectionType &&
    isClinicalCalculatorsSubSectionType(
      props.nationalEntityParams.clinicalCalculatorsSubSectionType,
    )
  ) {
    return true;
  }
  return false;
}
