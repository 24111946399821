import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reassignAssessor } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { Assessor, EventStatus } from "../types";

const useReassignAssessor = (eventId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(reassignAssessor, {
    onSuccess: (updatedAssessor) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryClient.setQueryData(portfolioKeys.detail(eventId), (prevEvent: any) => ({
        ...prevEvent,
        status: EventStatus.PENDING_REVIEW,
      }));

      queryClient.setQueryData<Assessor[] | undefined>(
        portfolioKeys.assignedAssessors(eventId),
        (prevAssessors?: Assessor[]) => {
          if (!prevAssessors) {
            return undefined;
          }
          return [{ ...updatedAssessor, canRemind: true }];
        },
      );
    },
  });

  return {
    reassignAssessor: mutate,
    reassigningAssessor: isLoading,
    ...rest,
  };
};

export default useReassignAssessor;
