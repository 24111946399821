import { isObject } from "lodash";
import { useQuery } from "@tanstack/react-query";

import { MedicationItemDetails, MonographType, bnfClient } from "@eolas-medical/core";
import bnfKeys from "modules/bnf/data/bnf.queryKeys";
import { isBorderlineSubstanceData } from "../typeguards";
import { errorStore } from "Stores/ErrorStore";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "modules/bnf/constants";

export const useGetBorderlineSubstance = ({
  idOrSid,
  type,
}: {
  idOrSid: string | null;
  type: MonographType;
}) => {
  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.bnfItem(idOrSid, type),
    queryFn: async () => {
      if (!idOrSid) {
        throw new Error("Unable to get BNF item: idOrSid is null");
      }
      return bnfClient.getItemDetailsByIdOrSid({ itemIdOrSid: idOrSid, type });
    },
    select: (data: MedicationItemDetails) => {
      const maybeBorderlineSubstance = data.data;
      if (isBorderlineSubstanceData(maybeBorderlineSubstance)) {
        return {
          borderlineSubstance: maybeBorderlineSubstance.borderlineSubstances,
          title: data.name,
        };
      }
      const dataForDebugging = isObject(data.data) ? data.data : {};
      errorStore.captureError({
        error: new Error(
          `Unexpected item for BNF medication item. Contains: ${Object.keys(dataForDebugging)}`,
        ),
        source: "user",
      });
      return null;
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return {
    data,
    isLoading: isInitialLoading,
  };
};
