import { eolasLogger, MasterSearchFile } from "@eolas-medical/core";
import { createLocalFilesDb, populateLocalFilesDb, searchWithDb } from "./localSearch.functions";
import { LocalFilesSearchDb } from "./localSearch.types";
import { LDFlags } from "Utilities/types";

class LocalSearchStore {
  private db: LocalFilesSearchDb | null = null;

  private initialisingPromise: Promise<void> | null = null;
  private updatingPromise: Promise<void> | null = null;

  initialiseNewDb = async () => {
    if (this.initialisingPromise) {
      return this.initialisingPromise;
    }
    this.initialisingPromise = (async () => {
      let newDb: LocalFilesSearchDb | null = null;
      try {
        newDb = await createLocalFilesDb();
      } catch (error) {
        eolasLogger.error("LocalSearchStore: unable to create new DB", { error });
      }
      if (!newDb) {
        return;
      }
      try {
        await populateLocalFilesDb({ action: "addAll", searchDb: newDb });
        this.db = newDb;
      } catch (error) {
        eolasLogger.error("LocalSearchStore: unable to populate new DB", { error });
      }
    })();
    await this.initialisingPromise;
    this.initialisingPromise = null;
  };

  updateDb = async (args: {
    checklistIdsRemoved: string[];
    checklistIdsAdded: string[];
    fileIdsAdded: string[];
    fileIdsRemoved: string[];
  }) => {
    if (this.updatingPromise) {
      return this.updatingPromise;
    }
    this.updatingPromise = (async () => {
      if (this.initialisingPromise) {
        await this.initialisingPromise;
      }
      if (!this.db) {
        eolasLogger.error("LocalSearchStore: attempted to populate DB when DB was null");
        return;
      }
      try {
        await populateLocalFilesDb({ action: "update", searchDb: this.db, ...args });
      } catch (error) {
        eolasLogger.error("LocalSearchStore: attempted to update DB", { error });
      }
    })();
    await this.updatingPromise;
    this.updatingPromise = null;
  };

  search = async (args: {
    shouldSearchSpace?: boolean;
    shouldSearchOrganisation: boolean;
    term: string;
    ldFlags: LDFlags;
    isInAdminMode: boolean;
    idsToInclude?: Record<string, string>;
  }): Promise<MasterSearchFile[]> => {
    if (this.updatingPromise) {
      await this.updatingPromise;
    }
    if (!this.db) {
      eolasLogger.error("LocalSearchStore: attempted to search using DB when DB was null");
      return [];
    }
    let masterSearchFiles: MasterSearchFile[] = [];
    try {
      masterSearchFiles = await searchWithDb({ localFilesDb: this.db, ...args });
    } catch (error) {
      eolasLogger.error("LocalSearchStore: error performing search", { error });
    }
    return masterSearchFiles;
  };

  clearStore = () => {
    this.db = null;
  };
}

export const localSearchStore = new LocalSearchStore();
