import { CheckIcon } from "Assets";
import { useState } from "react";

export type ToggleListOption<T extends string> = {
  key: T;
  title: string;
  description?: string;
};

type ToggleListProps<T extends string> =
  | {
      options: ToggleListOption<T>[];
      defaultOptionKey?: T;
      onChange?: (selectedOptionKey: T) => void;
      mode?: "uncontrolled";
    }
  | {
      options: ToggleListOption<T>[];
      mode: "controlled";
      setValue: (selectedOptionKey: T) => void;
      value: T;
    };

export const ToggleList = <T extends string>(props: ToggleListProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<T | undefined>(
    !props.mode || props.mode === "uncontrolled" ? props.defaultOptionKey : undefined,
  );
  let handleOptionChange: (optionKey: T) => void = setSelectedOption;
  let selectedValue = selectedOption;
  if (!props.mode || props.mode === "uncontrolled") {
    handleOptionChange = (optionKey: T) => {
      props.onChange?.(optionKey);
      setSelectedOption(optionKey);
    };
  } else if (props.mode === "controlled") {
    handleOptionChange = props.setValue;
    selectedValue = props.value;
  }

  return (
    <div className="flex flex-col divide-y divide-grey-300">
      {props.options.map((option) => (
        <div
          key={option.key}
          className="flex items-center justify-between py-4"
          onClick={() => handleOptionChange(option.key)}
        >
          <label htmlFor={option.key} className="w-3/4 cursor-pointer">
            <strong>{option.title}</strong>
            {option.description ? <p>{option.description}</p> : null}
          </label>

          {selectedValue === option.key ? (
            <CheckIcon className="text-green w-6 h-6" />
          ) : (
            <span className="w-6 h-6 border border-grey-400 bg-grey-300 rounded-full" />
          )}
        </div>
      ))}
    </div>
  );
};
