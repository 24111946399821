import { get } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import { useFormContext, useFieldArray } from "react-hook-form";

import { ChecklistBuilderForm, ChecklistSectionItem } from "../types";

export const useBuilderSectionItem = (sectionIndex: number) => {
  const {
    control,
    formState: { errors },
    watch,
    trigger,
    register,
    setValue,
  } = useFormContext<ChecklistBuilderForm>();
  const expanded = watch(`checklistSections.${sectionIndex}.expanded`);

  const sectionItemError = get(
    errors,
    `checklistSections.${sectionIndex}.checklistSectionName.message`,
    "",
  );

  const missingSectionError = get(
    errors,
    `checklistSections.${sectionIndex}.sectionItems.message`,
    "",
  );

  const {
    fields: checklistItems,
    append,
    remove,
    update,
    move,
  } = useFieldArray({
    control,
    name: `checklistSections.${sectionIndex}.sectionItems`,
  });

  const toggleExpanded = () => {
    setValue(`checklistSections.${sectionIndex}.expanded`, !expanded);
  };

  const onAddItem = (sectionItem: ChecklistSectionItem) => {
    append(sectionItem);
    trigger("checklistSections");
  };

  const onEditItem = (index: number, sectionItem: ChecklistSectionItem) => {
    update(index, sectionItem);
  };

  const onDeleteItem = (index: number) => {
    remove(index);
  };

  const onSwap = (dropResult: DropResult) => {
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    move(dropResult.source.index, dropResult.destination.index);
  };

  return {
    control,
    expanded,
    checklistItems,
    sectionItemError,
    missingSectionError,
    onSwap,
    register,
    onAddItem,
    onEditItem,
    onDeleteItem,
    toggleExpanded,
  };
};
