import { Button, InnerModalWrapper, Modal, ModalBody, Text } from "UIKit";
import { Strings } from "./BnfAccessModal.strings";
import { observer } from "mobx-react-lite";
import { ProfileVerificationStatus, userStore } from "@eolas-medical/core";
import { useHistory } from "react-router-dom";
import { PersonalDetailsLocationState } from "Pages/MeTab";

export const BnfAccessModal = observer(({ closeModal }: { closeModal: () => void }) => {
  const isProfileVerificationPending =
    userStore.userData.profileVerification?.status === ProfileVerificationStatus.PENDING;
  const { push } = useHistory();

  return (
    <Modal open hasCloseIcon shouldCloseOnOverlayClick onClose={closeModal}>
      <InnerModalWrapper>
        <ModalBody className="flex-col items-center">
          <Text level={2} className="text-center mt-6">
            {isProfileVerificationPending ? Strings.titlePending : Strings.title}
          </Text>
          <Text level={3} className="my-4 text-center">
            {Strings.description}
          </Text>
          <div className="flex justify-center items-center mt-3 mx-auto space-x-2 w-full md:w-2/4">
            <Button
              size="sm"
              className="w-full"
              onClick={() => {
                const state: PersonalDetailsLocationState = { openEditEmail: true };
                push("/my-profile/personal-details", state);
              }}
            >
              {Strings.addEmailButton}
            </Button>
            <Text level={3}>{Strings.or}</Text>
            <Button
              disabled={isProfileVerificationPending}
              size="sm"
              className="w-full"
              onClick={() => {
                const state: PersonalDetailsLocationState = { openVerifyProfile: true };
                push("/my-profile/personal-details", state);
              }}
            >
              {isProfileVerificationPending
                ? Strings.pendingProfileVerification
                : Strings.verifyProfile}
            </Button>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    </Modal>
  );
});
