import { observer } from "mobx-react-lite";
import { sectionStore } from "@eolas-medical/core";

import ContentRepositoryContext, {
  defaultSpaceOrgContentRepositoryValues,
} from "shared/pages/ContentRepository/context/contentRepository.context";
import useSpaceContentRepositoryParams from "./hooks/useSpaceContentRepositoryParams";
import useAddContentSection from "modules/contentRepository/data/useAddContentSection";
import useEditContentSection from "modules/contentRepository/data/useEditContentSection";
import useDeleteContentSection from "modules/contentRepository/data/useDeleteContentSection";
import useAddContentItem from "modules/contentRepository/data/useAddContentItem";
import useEditContentItem from "modules/contentRepository/data/useEditContentItem";
import useDeleteContentItem from "modules/contentRepository/data/useDeleteContentItem";
import useContentItems from "modules/contentRepository/data/useContentItems";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import useContentSections from "modules/contentRepository/data/useContentSections";
import useContentSection from "modules/contentRepository/data/useContentSection";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useAddFlashcardItem from "modules/contentRepository/data/useAddFlashcardItem";
import useEditFlashcardItem from "modules/contentRepository/data/useEditFlashcardItem";
import { useSpacesContext } from "modules/spaces";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import useAddEolasEditorItem from "modules/contentRepository/data/useAddEolasEditorcardItem";
import useEditEolasEditorItem from "modules/contentRepository/data/useEditEolasEditorItem";
import { MediaUploadParams } from "modules/contentRepository/types";
import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useBulkDeleteItems } from "modules/contentRepository/data/useBulkDeleteItems";
import { useCallback, useMemo } from "react";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { makeShouldIncludeSectionHelpers } from "../ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "../ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useRefetchAppData } from "Hooks";

const SpaceContentRepositoryProvider = observer(({ children }: { children: React.ReactNode }) => {
  const { flags } = useLaunchDarkly();

  const { selectedSpaceId, isPublic } = useSpacesContext();
  const { contentRepositoryId, subSectionId, subSectionsNo } = useSpaceContentRepositoryParams();

  const useContentRepositoryHook = useContentRepository(contentRepositoryId);
  const useContentSectionsHook = useContentSections(subSectionId || contentRepositoryId);
  const useContentSectionHook = useContentSection(subSectionId || contentRepositoryId);
  const useContentItemsHook = useContentItems({ contentRepositoryId, subSectionId });

  const isPublicItem = !!flags[LDFlagNames.PUBLIC_FILES] && isPublic;

  const { activeTab } = useEolasNavigation();
  const limitedAccess = useGetLimitedAccess({ activeTab });
  const adminStatus = useGetAdminStatus({ activeTab });
  const { isAdmin: isOnAdminView } = sectionStore;
  const ownerType =
    activeTab === "organisation" ? EntityOwnerType.ORGANISATION : EntityOwnerType.SPACE;

  const mediaUploadParams: MediaUploadParams = {
    ownerType,
    isPublicItem,
    ownerId: selectedSpaceId ?? "",
    isOrganisationFile: activeTab === "organisation",
  };

  const { refetch } = useRefetchAppData();

  const onSuccessCallback = useCallback(async () => {
    refetch();
  }, [refetch]);

  const useAddContentSectionHook = useAddContentSection({
    mainSectionId: contentRepositoryId,
    onSuccess: onSuccessCallback,
    parentSectionId: subSectionId || contentRepositoryId,
  });
  const useEditContentSectionHook = useEditContentSection({
    onSuccess: onSuccessCallback,
  });
  const useDeleteContentSectionHook = useDeleteContentSection({
    onSuccess: onSuccessCallback,
  });
  const useAddContentItemHook = useAddContentItem({
    mainSectionId: contentRepositoryId,
    parentId: subSectionId || contentRepositoryId,
    onSuccess: onSuccessCallback,
    mediaUploadParams,
  });
  const useAddFlashcardItemHook = useAddFlashcardItem({
    mainSectionId: contentRepositoryId,
    parentId: subSectionId || contentRepositoryId,
    onSuccess: onSuccessCallback,
  });

  const useAddEolasEditorItemHook = useAddEolasEditorItem({
    mainSectionId: contentRepositoryId,
    parentId: subSectionId || contentRepositoryId,
    onSuccess: onSuccessCallback,
  });
  const useEditContentItemHook = useEditContentItem({
    mainSectionId: contentRepositoryId,
    mediaUploadParams,
    onSuccess: onSuccessCallback,
  });
  const useEditFlashcardItemHook = useEditFlashcardItem({
    mainSectionId: contentRepositoryId,
    onSuccess: onSuccessCallback,
  });
  const useEditEolasEditorItemHook = useEditEolasEditorItem({
    mainSectionId: contentRepositoryId,
    onSuccess: onSuccessCallback,
  });
  const useDeleteContentItemHook = useDeleteContentItem({
    mainSectionId: contentRepositoryId,
    onSuccess: onSuccessCallback,
  });
  const useBulkDeleteItemsHook = useBulkDeleteItems({
    mainSectionId: contentRepositoryId,
    onSettled: onSuccessCallback,
  });
  const useReorderContentHook = useReorderContent(subSectionId || contentRepositoryId);

  const limitedAccessHelpers = useMemo(() => {
    if (!isOnAdminView) {
      return {
        filterFnForChildren: () => true,
        shouldCurrentSectionAllowAdminRights: false,
      };
    }
    if (adminStatus === "limitedAdmin") {
      return makeShouldIncludeSectionHelpers(limitedAccess, subSectionId || contentRepositoryId);
    }
    return {
      filterFnForChildren: () => true,
      shouldCurrentSectionAllowAdminRights: adminStatus === "admin",
    };
  }, [limitedAccess, adminStatus, subSectionId, isOnAdminView, contentRepositoryId]);

  return (
    <ContentRepositoryContext.Provider
      value={{
        ...defaultSpaceOrgContentRepositoryValues,
        contextDomain: "spaceOrg",
        useContentRepositoryHook,
        useContentSectionsHook,
        useContentSectionHook,
        useContentItemsHook,
        useAddContentSectionHook,
        useEditContentSectionHook,
        useEditEolasEditorItemHook,
        useDeleteContentSectionHook,
        useAddContentItemHook,
        useAddFlashcardItemHook,
        useAddEolasEditorItemHook,
        useEditContentItemHook,
        useEditFlashcardItemHook,
        useDeleteContentItemHook,
        useReorderContentHook,
        useBulkDeleteItemsHook,
        isSubsection: !!subSectionId,
        canOnlyCreateFileSection: subSectionsNo === 10,
        limitedAccessHelpers,
        contentItemConfig: {
          shouldAllowFavourites: true,
          shouldAllowSharing: true,
          shouldShowBreadcrumbs: true,
        },
        contentSectionConfig: {
          canDownloadSectionMap: true,
          shouldShowBreadcrumbs: true,
          shouldAllowSharing: true,
        },
      }}
    >
      {children}
    </ContentRepositoryContext.Provider>
  );
});

export default SpaceContentRepositoryProvider;
