import { useMemo } from "react";
import {
  ChecklistIcon,
  DefaultFileIcon,
  EolasEditorIcon,
  FlashcardIcon,
  ImageIcon,
  OfficeIcon,
  PDFIcon,
  VideoIcon,
  WeblinkIcon,
} from "Assets";

interface FileTypeIconProps {
  type?: string;
  className?: string;
}

export const FavouriteFileTypeIcon = ({ type, className }: FileTypeIconProps) => {
  const Icon = useMemo(() => {
    const lowercaseType = type?.toLocaleLowerCase();

    if (type === "checklistTemplate") {
      return ChecklistIcon;
    }

    switch (lowercaseType) {
      case "flashcard":
        return FlashcardIcon;
      case "eolas-editor":
        return EolasEditorIcon;
      case "mp4":
      case "mov":
        return VideoIcon;
      case "pdf":
        return PDFIcon;
      case "jpeg":
      case "jpg":
      case "png":
        return ImageIcon;
      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
        return OfficeIcon;
      case "link":
        return WeblinkIcon;
      default:
        return DefaultFileIcon;
    }
  }, [type]);

  return <Icon className={className} />;
};
