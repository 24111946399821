import { clinicalQuestionStore } from "@eolas-medical/core";
import { ClinicalQuestionIcon } from "Assets";
import { Text } from "UIKit";
import { EolasCard } from "UIKit/EolasCard/EolasCard";

export const FollowUpQuestionCard = () => {
  // This store value is intentionally not being accessed from an observed component
  const text = clinicalQuestionStore.followUpQuestion;
  if (!text) {
    return null;
  }
  return (
    <EolasCard elevation={2}>
      <div className="flex gap-4 p-6">
        <ClinicalQuestionIcon height={25} width={25} />
        <Text level={1} className="font-normal">
          {text}
        </Text>
      </div>
    </EolasCard>
  );
};
