import { AppLevelSection } from "@eolas-medical/core";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { useTranslation } from "react-i18next";
import ComingSoon from "shared/pages/MiniApp/ComingSoon";

const Chat: React.FC = () => {
  const { t } = useTranslation();

  const { spaceSection } = useSpaceSection(AppLevelSection.channels);

  return (
    <ComingSoon
      titleText={t("channels")}
      subTitleText={t("coming_soon_subtitle", { miniApp: spaceSection.name })}
      informationText={t("coming_soon_information", { miniApp: spaceSection.name })}
      disclaimerText={t("coming_soon_disclaimer")}
    />
  );
};

export default Chat;
