import { useTranslation } from "react-i18next";

import { SearchSortList } from "shared/components/Lists";
import { AddButton, InnerPageWrapper, NavButton, PageTitle } from "UIKit";
import { removeLastUrlPath } from "Utilities";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { useGetAllSponsoredSlots } from "modules/sponsoredSlots";
import { SponsoredSlotTile } from "./components/SponsoredSlotTile";
import { useModal } from "Hooks/useModal";
import { ManageSlotWizardModal } from "./components/ManageSlotWizardModal";
import { SponsoredInfoSection } from "@eolas-medical/core";
import { useMemo } from "react";
import { useLocalClickSearch } from "shared/hooks/useLocalSearch/useLocalClickSearch";

type EnrichedSponsoredInfoSection = SponsoredInfoSection & {
  specialtiesString: string;
  jobTitlesString: string;
  senioritiesString: string;
};

const reducerFunc = (acc: string, current: string) => {
  return acc + " " + current.replace(/_/g, " ");
};

const renderItem = (section: SponsoredInfoSection) => {
  return <SponsoredSlotTile section={section} />;
};

const ManageSlotsPage = () => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { url } = useRouteMatch();
  const { mainSectionId } = useParamsDecoded<{
    mainSectionId: string;
  }>();
  const { sponsoredSlotSections, isLoading } = useGetAllSponsoredSlots({ mainSectionId });

  const { showModal: onClickAdd, modal: addSlotModal } = useModal({
    Modal: ManageSlotWizardModal,
    modalProps: { mainSectionId },
    modalContainerProps: { shouldCloseOnOverlayClick: false },
  });

  const enrichedSponsoredInfoSections: EnrichedSponsoredInfoSection[] = useMemo(() => {
    if (!sponsoredSlotSections.length) {
      return [];
    }
    return sponsoredSlotSections.map((section) => {
      const specialtiesString = section.sponsoredInfo.specialties?.reduce(reducerFunc, "") ?? "";
      const jobTitlesString = section.sponsoredInfo.jobTitles?.reduce(reducerFunc, "") ?? "";
      const senioritiesString = section.sponsoredInfo.seniorities?.reduce(reducerFunc, "") ?? "";

      return { ...section, specialtiesString, jobTitlesString, senioritiesString };
    });
  }, [sponsoredSlotSections]);

  const {
    searchInput,
    searchResult,
    isSearchClicked,
    onSetSearchInput,
    onClickSearch,
    onClearSearch,
  } = useLocalClickSearch<EnrichedSponsoredInfoSection>({
    data: enrichedSponsoredInfoSections,
    keysToSearch: [
      "name",
      "description",
      "specialtiesString",
      "jobTitlesString",
      "senioritiesString",
    ],
    onSearchAnalytics: () => {
      // Not needed as an internal admin tool
    },
  });

  const listToDisplay: SponsoredInfoSection[] = useMemo(() => {
    if (isSearchClicked) {
      return searchResult;
    }
    return sponsoredSlotSections;
  }, [searchResult, sponsoredSlotSections, isSearchClicked]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("knowledge_sponsored_slot_title")} />
        <NavButton onClick={() => replace(removeLastUrlPath(url, 2))} />
        <div className="flex justify-center items-center space-x-4">
          <AddButton onClick={onClickAdd}>{t("knowledge_sponsored_add_slot")}</AddButton>
        </div>
        <SearchSortList
          value={searchInput}
          onSearchInputChange={onSetSearchInput}
          onClickSearch={onClickSearch}
          onClearSearch={onClearSearch}
          placeholderSearchText={t("knowledge_sponsored_slot_search_placeholder")}
          items={listToDisplay}
          isSearchable
          isSortable={false}
          defaultSort="alphabetical"
          searchType="click"
          isLoading={isLoading}
          emptySearchPlaceholderText={t("knowledge_sponsored_slot_empty_search_placeholder")}
          hasEmptyListNavigation={false}
          renderItem={renderItem}
        />
      </InnerPageWrapper>
      {addSlotModal}
    </>
  );
};

export default ManageSlotsPage;
