import { FirstStepData } from "../types";
import { selectBlobStepData } from "../components/SelectBlobFirstStep/SelectBlobFirstStep";
import { selectDsmBlobData } from "../components/DsmComponents/SelectDsm";
import { selectEolasEditorData } from "../components/EolasEditorComponents/SelectEolasEditor";
import { selectFlashCardData } from "../components/FlashCardComponents/SelectFlashCard";
import { selectLinkData } from "../components/LinkComponents/SelectLink";

export const selectFileData: FirstStepData = {
  isFirstStep: true,
  typeRecords: {
    blob: selectBlobStepData,
    link: selectLinkData,
    flashcard: selectFlashCardData,
    dsm: selectDsmBlobData,
    "eolas-editor": selectEolasEditorData,
  },
};
