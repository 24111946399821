import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { AddFlashcardItemDto } from "../types";
import { createResourcesManifestFromHtml, mapToAddFlashcardDto } from "../helpers";
import { generateS3FileKey, generateS3PublicImageURL } from "Utilities";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { useState } from "react";
import { AxiosProgressEvent } from "axios";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseAddFlashcardItemProps<T, R> {
  mainSectionId: string;
  parentId: string;
  isSponsored?: boolean;
  onSuccess: (params?: T) => Promise<R>;
}

const useAddFlashcardItem = <T, R>({
  mainSectionId,
  parentId,
  isSponsored,
  onSuccess,
}: UseAddFlashcardItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!); // set completion progress to 25%
    setUploadProgress(percentCompleted);
  };

  const addContentItem = async ({
    name,
    description,
    flashcardContent,
    imageUrl,
    file,
    linkUrl,
    itemType,
    keywords,
    specialty,
    createdBy,
    isDraft,
  }: AddFlashcardItemDto) => {
    let key = imageUrl || "";

    if (!imageUrl && file) {
      if (useAppServicesEndpoints) {
        const s3key = generateS3FileKey({
          isPublic: true,
          fileName: name,
          fileFormat: file?.type,
          mainSectionId,
          addExtension: true,
        });
        key = generateS3PublicImageURL(s3key);
        await uploadFile(s3key, file, setProgress);
      } else {
        const { publicUrl } = await uploadFileV2({
          file,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isPublic: true,
        });
        if (!publicUrl) throw new Error("publicUrl missing when uploading flashcard cover image");
        key = publicUrl;
      }
    }

    const resourcesManifest = await createResourcesManifestFromHtml(flashcardContent, key);

    const flashcardItemDto = mapToAddFlashcardDto({
      parentId,
      name,
      description,
      flashcardContent,
      imageUrl,
      resourcesManifest: JSON.stringify(resourcesManifest),
      linkUrl,
      itemType,
      keywords,
      specialty,
      createdBy,
      isDraft,
      key,
      isSponsored,
      isKnowledge: false,
    });

    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const result: any = await contentClient.addFlashcard(mainSectionId, flashcardItemDto);

    setUploadProgress(50);
    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentItemDto: AddFlashcardItemDto) => addContentItem(contentItemDto),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  let addFlashcardItemError = "";

  if (error) {
    addFlashcardItemError = error instanceof Error ? error.message : JSON.stringify(error);
  }

  return {
    addFlashcardItem: mutate,
    addingFlashcardItem: isLoading,
    addFlashcardItemSuccess: isSuccess,
    addFlashcardItemError,
    addFlashcardItemProgress: uploadProgress,
  };
};

export default useAddFlashcardItem;
