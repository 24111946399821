/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchIcon } from "Assets";
import { useClickOutside } from "Hooks";
import React, { ComponentType, useCallback, useRef, useState } from "react";
import ReactSelect, {
  components,
  Props,
  ControlProps,
  SingleValue,
  MultiValue,
  StylesConfig,
  OptionsOrGroups,
  GroupBase,
} from "react-select";
import AsyncSelect from "react-select/async";

export type Option = {
  label: string;
  value: string;
};

interface CustomSelectStyles extends StylesConfig {
  customControl?: StylesConfig["control"];
  customDropdownIndicator?: StylesConfig["dropdownIndicator"];
  customMultiValue?: StylesConfig["multiValue"];
}

const { Option: SelectOption } = components;

interface CustomControlProps extends ControlProps<unknown, boolean> {
  isSearchable: boolean;
  controlIcon: React.ElementType | undefined;
}

const Control: React.FC<CustomControlProps> = ({
  children,
  isSearchable,
  controlIcon: ControlIcon,
  ...props
}: CustomControlProps) => {
  const showSearchIcon = isSearchable && !ControlIcon && !props.hasValue;
  const showControlIcon = ControlIcon && props.hasValue;
  const iconClass = "h-6 w-6 ml-2 hidden sm:inline cursor-pointer";

  return (
    <components.Control {...props}>
      {showSearchIcon && <SearchIcon className={iconClass} />}
      {showControlIcon && ControlIcon && <ControlIcon className={iconClass} />}
      {children}
    </components.Control>
  );
};

interface SelectProps extends Props {
  options?: Option[];
  isMulti?: boolean;
  isSearchable?: boolean;
  defaultValue?: SingleValue<Option> | MultiValue<Option>;
  controlIcon?: React.ElementType;
  isDisabled?: boolean;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<unknown, GroupBase<unknown>>) => void,
  ) => void;
}

/**
 * @deprecated use EolasSelect instead
 */
const Select: React.FC<SelectProps> = ({
  options,
  isMulti = false,
  isSearchable = false,
  isDisabled = false,
  defaultValue,
  controlIcon,
  loadOptions,
  ...rest
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleCloseMenuOptions = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickMenuOptions = useCallback(() => {
    if (!isMulti) {
      setIsOpen((state) => !state);
    } else {
      setIsOpen(true);
    }
    // Assuming dropdownRef is your ref to the outer div, you may need to find the input differently
    const inputElement = dropdownRef.current?.querySelector("input");
    if (inputElement) {
      inputElement.focus(); // This should trigger the keyboard on mobile devices
    }
  }, [isMulti]);

  useClickOutside(dropdownRef, handleCloseMenuOptions);

  const ControlWithIcon: ComponentType<ControlProps<unknown, boolean, GroupBase<unknown>>> = (
    props,
  ) => <Control {...props} isSearchable={isSearchable} controlIcon={controlIcon} />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function IconOption(props: any) {
    const {
      data: { label, optionIcon: Icon, extraInfo },
      isSelected,
    } = props;

    return (
      <SelectOption {...props} className="flex items-center">
        <div className="grid grid-cols-12 items-center">
          <div className="col-start-1 col-end-6 flex items-start justify-between">
            <div className="flex gap-x-2 items-center">
              {Icon && <span className="w-6 h-6">{<Icon />}</span>}
              {label}
            </div>
          </div>
          <div className="col-start-6 col-end-13 flex items-start">
            <div
              className={`text-${
                isSelected ? "white" : "grey-600"
              } overflow-hidden whitespace-nowrap overflow-ellipsis`}
            >
              {extraInfo}
            </div>
          </div>
        </div>
      </SelectOption>
    );
  }

  const customSelectStyles: CustomSelectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "12px",
      boxShadow: "none",
      borderColor: "#EBEFF1",
      "&:hover": {
        borderColor: "#C2D5FA",
      },
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
      transition: "250ms",
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "#f5f7f8",
      color: "#0D0C22",
      borderRadius: "44px",
      padding: "4px",
    }),
  };

  const commonProps = {
    menuIsOpen: isOpen,
    isDisabled,
    isMulti,
    ...rest,
    defaultValue,
    components: { Control: ControlWithIcon, Option: IconOption },
    isSearchable,
    options,
    maxMenuHeight: 130,
  };

  return (
    <div
      ref={dropdownRef}
      onClick={!isDisabled ? handleClickMenuOptions : undefined}
      onTouchStart={!isDisabled ? handleClickMenuOptions : undefined}
    >
      {!loadOptions ? (
        <ReactSelect styles={customSelectStyles} {...commonProps} />
      ) : (
        <AsyncSelect loadOptions={loadOptions} styles={customSelectStyles} {...commonProps} />
      )}
    </div>
  );
};

export default Select;
