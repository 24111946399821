import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { Input, FormElement, Textarea } from "UIKit";
import { NameAndUrlValues } from "./types";

interface NameAndUrlStepProps {
  control: Control<NameAndUrlValues>;
  errors: FieldErrors<NameAndUrlValues>;
  nameLabel: string;
  namePlaceholder: string;
  urlLabel: string;
  keyWordsLabel: string;
  urlPlaceholder: string;
  keyWordsPlaceholder: string;
}

const NameAndUrlStep: React.FC<NameAndUrlStepProps> = ({
  control,
  errors,
  nameLabel,
  namePlaceholder,
  urlLabel,
  keyWordsLabel,
  urlPlaceholder,
  keyWordsPlaceholder,
}: NameAndUrlStepProps) => {
  return (
    <form>
      <FormElement
        required
        id="name"
        control={control}
        label={nameLabel}
        errorMessage={errors.name?.message}
      >
        {(field) => <Input {...field} size="lg" placeholder={namePlaceholder} />}
      </FormElement>
      <FormElement
        errorMessage={errors.url?.message}
        required
        id="url"
        control={control}
        label={urlLabel}
      >
        {(field) => <Input {...field} size="lg" placeholder={urlPlaceholder} />}
      </FormElement>
      <FormElement
        id="keywords"
        control={control}
        label={keyWordsLabel}
        errorMessage={errors?.keywords?.message}
      >
        {(field) => <Textarea {...field} placeholder={keyWordsPlaceholder} />}
      </FormElement>
    </form>
  );
};

export default NameAndUrlStep;
