import {
  ChildReference,
  FeatureGuides,
  hasProp,
  hasStringProp,
  SectionNonAppsync,
} from "@eolas-medical/core";

export const isChildReference = (item: unknown): item is ChildReference => {
  if (
    hasStringProp(item, "icon") &&
    hasStringProp(item, "name") &&
    hasStringProp(item, "id") &&
    !hasProp(item, "mainSectionID")
  ) {
    return true;
  }
  return false;
};

export const isFeatureGuide = (sectionKey: string): sectionKey is FeatureGuides => {
  return Object.values<string>(FeatureGuides).includes(sectionKey);
};

export const isPromiseSettledResult = <D>(
  result: D | PromiseSettledResult<D>,
): result is PromiseSettledResult<D> => {
  if (
    hasProp(result, "status") &&
    (result.status === "fulfilled" || result.status === "rejected")
  ) {
    return true;
  }

  return false;
};

export const isPromiseSettledResultArray = <D>(
  results: (D | PromiseSettledResult<D>)[],
): results is PromiseSettledResult<D>[] => {
  return results.every(isPromiseSettledResult);
};

export const isPromiseRejectedResult = <D>(
  result: PromiseSettledResult<D>,
): result is PromiseRejectedResult => {
  return result.status === "rejected";
};

export const isPromiseFulfilledResult = <D>(
  result: PromiseSettledResult<D>,
): result is PromiseFulfilledResult<D> => {
  return result.status === "fulfilled";
};

export const isEolasSection = (section: unknown): section is SectionNonAppsync => {
  if (
    !hasStringProp(section, "mainSectionID") ||
    !hasStringProp(section, "mainSectionType") ||
    !hasStringProp(section, "ownerID") ||
    !hasStringProp(section, "sharedID") ||
    !hasStringProp(section, "type") ||
    !hasStringProp(section, "id")
  ) {
    return false;
  }

  if (hasStringProp(section, "__typename") && section.__typename === "Section") {
    return true;
  }

  if (hasStringProp(section, "identity")) {
    return true;
  }

  return false;
};
