/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useQueryClient } from "@tanstack/react-query";
import { makeGetLoginTokensQueryKey } from "./useLoginTokens";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useTranslation } from "react-i18next";

export const useLoginToken = (token: string) => {
  const { t } = useTranslation();
  const { isLoading, setRequestStatus } = useRequestStatus();
  const [tooltipText, setTooltipText] = React.useState<string>(t("manageUsers_copy_login_link"));
  const { flags } = useLaunchDarkly();
  const queryClient = useQueryClient();
  const { spaceId } = useGetRelevantSpaceDetails();

  const onCopyClicked = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/auto-login/${encodeURIComponent(token)}`,
    );
    setTooltipText(t("manageUsers_login_tokens_link_copied"));
  };

  const afterTooltipHide = () => {
    return setTimeout(() => {
      setTooltipText(t("manageUsers_copy_login_link"));
    }, 250);
  };

  const onDeleteToken = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      await loginTokenService.deleteToken({
        token,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });
      queryClient.invalidateQueries(makeGetLoginTokensQueryKey(spaceId));
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [token, queryClient, spaceId, setRequestStatus, flags]);

  return {
    isLoading,
    tooltipText,
    onDeleteToken,
    onCopyClicked,
    afterTooltipHide,
  };
};
