import { useState, useEffect } from "react";
import { Title } from "UIKit";

export const LoadingDots = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return <Title level={4}>{dots + "\u00A0"}</Title>;
};
