import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { EditEolasEditorItemDto } from "modules/contentRepository/types";
import { mapToUpdateEolasEditorDto } from "modules/contentRepository/helpers";

interface UseEditEolasEditorItemProps {
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useEditKnowledgeContentEolasEditorItem = ({
  parentId,
  domain,
}: UseEditEolasEditorItemProps) => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleEditEolasEditorItem = async ({
    id,
    name,
    description,
    eolasEditorContent,
    expiryDate,
    isDraft,
  }: EditEolasEditorItemDto) => {
    const eolasEditorItemDto = mapToUpdateEolasEditorDto({
      contentItem: {
        name,
        description,
        expiryDate,
        eolasEditorContent,
        isDraft,
      },
      isKnowledge: true,
    });

    await knowledgeContentClient.updateEolasEditorItem({
      contentId: id,
      updateEolasEditorItemDto: eolasEditorItemDto,
    });

    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (editItem: EditEolasEditorItemDto) => handleEditEolasEditorItem(editItem),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
        setUploadProgress(100);
      },
    },
  );

  return {
    editEolasEditorItem: mutate,
    editingEolasEditorItem: isLoading,
    editEolasEditorItemSuccess: isSuccess,
    editEolasEditorItemError: error instanceof Error ? error.message : "",
    editEolasEditorItemProgress: uploadProgress,
  };
};

export default useEditKnowledgeContentEolasEditorItem;
