import { contentClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";

export const useFetchContentItemById = ({
  contentItemId,
  isEnabled,
}: {
  contentItemId: string;
  isEnabled?: boolean;
}) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetchContentItemById", contentItemId],
    queryFn: () => contentClient.getContentItemById(contentItemId),
    enabled: isEnabled,
  });

  return {
    contentItem: data,
    isLoading: isLoading && isEnabled,
    isError: isError,
    error,
  };
};
