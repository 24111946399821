const contentKeys = {
  all: ["content"],
  list: () => [...contentKeys.all, "list"],
  copy: () => [...contentKeys.all, "copy"],
  detail: (contentId: string) => [...contentKeys.all, "detail", contentId],
  flashcard: (flashcardId: string) => [...contentKeys.all, "flashcard", flashcardId],
  bulkDelete: () => [...contentKeys.all, "bulkDelete"],
};

export default contentKeys;
