import { Route, Switch, useRouteMatch } from "react-router-dom";
import KnowledgeContentSections from "./pages/KnowledgeContentSections/KnowledgeContentSections";
import KnowledgeContentRepositoryProvider from "./KnowledgeContentRepositoryProvider";
import { ContentSection } from "modules/contentRepository/types";
import { ContentItem, KnowledgeContentDomain } from "@eolas-medical/core";
import KnowledgeContentViewerProvider from "./KnowledgeContentViewerProvider";
import ContentViewer from "shared/pages/ContentViewer/ContentViewer";
import KnowledgeContentItems from "./pages/KnowledgeContentItems/KnowledgeContentItems";

export type KnowledgeContentAnalyticsCallbacks = {
  onClickContentSection: (section: ContentSection) => void;
  onLoadContentItem: (item: ContentItem) => void;
  onSearchContentSection: (searchValue: string, noHits?: number) => void;
  onSearchContentItem: (searchValue: string, noHits?: number) => void;
};

export type KnowledgeContentRepositoryProps = {
  domain: KnowledgeContentDomain;
  shouldAllowBlankHeroImageSelection?: boolean;
  hasCopilotData: boolean;
} & KnowledgeContentAnalyticsCallbacks;

export const KnowledgeContentRepository = (props: KnowledgeContentRepositoryProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/:contentRepositoryId`}
        render={() => (
          <KnowledgeContentRepositoryProvider {...props}>
            <KnowledgeContentSections />
          </KnowledgeContentRepositoryProvider>
        )}
      />

      <Route
        exact
        path={`${path}/:contentRepositoryId/:subSectionId`}
        render={() => (
          <KnowledgeContentRepositoryProvider {...props}>
            <KnowledgeContentItems />
          </KnowledgeContentRepositoryProvider>
        )}
      />

      <Route
        exact
        path={`${path}/:contentRepositoryId/:subSectionId/:contentId`}
        render={() => (
          <KnowledgeContentRepositoryProvider {...props}>
            <KnowledgeContentViewerProvider domain={props.domain}>
              <ContentViewer />
            </KnowledgeContentViewerProvider>
          </KnowledgeContentRepositoryProvider>
        )}
      />
    </Switch>
  );
};
