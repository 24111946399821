import * as icons from "Assets/Icons";
import { useTranslation } from "react-i18next";

export const useGetTemplates = () => {
  const { t } = useTranslation();

  const templates = [
    {
      id: "1",
      title: t("template_healthcare_team_title"),
      description: t("template_healthcare_team_description"),
      iconName: "HealthcareTeamIcon" as icons.EolasIcons,
    },
    {
      id: "2",
      title: t("template_content_publisher_title"),
      description: t("template_content_publisher_description"),
      iconName: "ContentPublisherIcon" as icons.EolasIcons,
    },
    {
      id: "3",
      title: t("template_society_network_title"),
      description: t("template_society_network_description"),
      iconName: "NetworkIcon" as icons.EolasIcons,
    },
    {
      id: "4",
      title: t("template_conference_meeting_course_title"),
      description: t("template_conference_meeting_course_description"),
      iconName: "MeetingIcon2" as icons.EolasIcons,
    },
  ];

  return templates;
};
