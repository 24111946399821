import { Redirect, Route, Switch } from "react-router-dom";
import { MedicationsRoute } from "Pages/Knowledge/types";
import { BaseAppRoute } from "Utilities/types";
import { MedicationsHighlights, SmPcsContentRepository } from "./pages";
import { knowledgeRoutes } from "../../KnowledgeRoutes";
import { bnfRoutes } from "./pages/Bnf/routes/BnfRoutes";
import {
  BnfComingSoonAdult,
  BnfComingSoonChild,
} from "./pages/Bnf/pages/BnfComingSoon/BnfComingSoon";
import { DrugsListAdult, DrugsListChild } from "./pages/Bnf/pages/Drugs/DrugsList/DrugsList";
import {
  DrugsDetailAdult,
  DrugsDetailChild,
} from "./pages/Bnf/pages/Drugs/DrugsDetail/DrugsDetail";
import {
  GuidanceContentListAdult,
  GuidanceContentListChild,
  TreatmentSummaryContentListAdult,
  TreatmentSummaryContentListChild,
} from "./pages/Bnf/pages/InfoContent/InfoContentList/InfoContentList";
import {
  InfoContentDetailsAdult,
  InfoContentDetailsChild,
} from "./pages/Bnf/pages/InfoContent/InfoContentDetails.tsx/InfoContentDetails";
import {
  MultiInteractionsAdult,
  MultiInteractionsChild,
} from "./pages/Bnf/pages/Interactions/MultiInteractions/MultiInteractions";
import {
  SingleDrugInteractionsAdult,
  SingleDrugInteractionsChild,
} from "./pages/Bnf/pages/Interactions/SingleDrugInteractions/SingleDrugInteractions";
import {
  AdditionalContentHomeAdult,
  AdditionalContentHomeChild,
} from "./pages/Bnf/pages/AdditionalContent/AdditionalContentHome/AdditionalContentHome";
import {
  BorderlineSubstancesAdult,
  BorderlineSubstancesChild,
} from "./pages/Bnf/pages/AdditionalContent/BorderlineSubstances/BorderlineSubstances";
import {
  MedicalDevicesAdult,
  MedicalDevicesChild,
} from "./pages/Bnf/pages/AdditionalContent/MedicalDevices/MedicalDevices";
import { WoundCarePageAdult } from "./pages/Bnf/pages/AdditionalContent/WoundCare/WoundCarePage";

export const medicationRoutes: Record<MedicationsRoute, string> = {
  pharmaResources: `/${BaseAppRoute.knowledge}/communityMedications/pharmaResources`,
  communityPharmaFlashcards: `/${BaseAppRoute.knowledge}/communityMedications/communityPharmaFlashcards`,
};

const MedicationsRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={medicationRoutes.communityPharmaFlashcards}
          component={MedicationsHighlights}
        />
        <Route path={medicationRoutes.pharmaResources} component={SmPcsContentRepository} />
        <Route exact path={bnfRoutes.comingSoonAdult} component={BnfComingSoonAdult} />
        <Route exact path={bnfRoutes.comingSoonChild} component={BnfComingSoonChild} />
        <Route exact path={bnfRoutes.adultMedicationsBnf} component={DrugsListAdult} />
        <Route exact path={bnfRoutes.paediatricMedicationsBnfc} component={DrugsListChild} />
        <Route exact path={bnfRoutes.bnf} component={DrugsDetailAdult} />
        <Route exact path={bnfRoutes.bnfc} component={DrugsDetailChild} />
        <Route exact path={bnfRoutes.guidanceBnf} component={GuidanceContentListAdult} />
        <Route exact path={bnfRoutes.guidanceBnfc} component={GuidanceContentListChild} />
        <Route
          exact
          path={bnfRoutes.treatmentSummariesBnf}
          component={TreatmentSummaryContentListAdult}
        />
        <Route
          exact
          path={bnfRoutes.treatmentSummariesBnfc}
          component={TreatmentSummaryContentListChild}
        />
        <Route exact path={bnfRoutes.guidance_adult} component={InfoContentDetailsAdult} />
        <Route exact path={bnfRoutes.guidance_child} component={InfoContentDetailsChild} />
        <Route
          exact
          path={bnfRoutes.treatment_summaries_adult}
          component={InfoContentDetailsAdult}
        />
        <Route
          exact
          path={bnfRoutes.treatment_summaries_child}
          component={InfoContentDetailsChild}
        />
        <Route exact path={bnfRoutes.interactions_adult} component={MultiInteractionsAdult} />
        <Route exact path={bnfRoutes.interactions_child} component={MultiInteractionsChild} />
        <Route
          exact
          path={bnfRoutes.singleDrugInteractionsBnf}
          component={SingleDrugInteractionsAdult}
        />
        <Route
          exact
          path={bnfRoutes.singleDrugInteractionsBnfc}
          component={SingleDrugInteractionsChild}
        />
        <Route
          exact
          path={bnfRoutes.treatment_summaries_child}
          component={InfoContentDetailsChild}
        />
        <Route
          exact
          path={bnfRoutes.additionalContentAdult}
          component={AdditionalContentHomeAdult}
        />
        <Route
          exact
          path={bnfRoutes.additionalContentChild}
          component={AdditionalContentHomeChild}
        />
        <Route exact path={bnfRoutes.about_adult} component={InfoContentDetailsAdult} />
        <Route exact path={bnfRoutes.about_child} component={InfoContentDetailsChild} />
        <Route exact path={bnfRoutes.dental_formulary_adult} component={InfoContentDetailsAdult} />
        <Route exact path={bnfRoutes.dental_formulary_child} component={InfoContentDetailsChild} />
        <Route
          exact
          path={bnfRoutes.nurse_prescribers_formulary_adult}
          component={InfoContentDetailsAdult}
        />
        <Route
          exact
          path={bnfRoutes.nurse_prescribers_formulary_child}
          component={InfoContentDetailsChild}
        />
        <Route
          exact
          path={bnfRoutes.nurse_prescribers_formulary_intro_adult}
          component={InfoContentDetailsAdult}
        />
        <Route
          exact
          path={bnfRoutes.nurse_prescribers_formulary_intro_child}
          component={InfoContentDetailsChild}
        />
        <Route
          exact
          path={bnfRoutes.borderline_substances_intro_adult}
          component={InfoContentDetailsAdult}
        />
        <Route
          exact
          path={bnfRoutes.borderline_substances_intro_child}
          component={InfoContentDetailsChild}
        />
        <Route
          exact
          path={bnfRoutes.wound_management_intro_adult}
          component={InfoContentDetailsAdult}
        />
        <Route
          exact
          path={bnfRoutes.wound_management_intro_child}
          component={InfoContentDetailsChild}
        />
        <Route
          exact
          path={bnfRoutes.borderline_substances_adult}
          component={BorderlineSubstancesAdult}
        />
        <Route
          exact
          path={bnfRoutes.borderline_substances_child}
          component={BorderlineSubstancesChild}
        />
        <Route exact path={bnfRoutes.medical_devices_adult} component={MedicalDevicesAdult} />
        <Route exact path={bnfRoutes.medical_devices_child} component={MedicalDevicesChild} />
        <Route exact path={bnfRoutes.wound_management_adult} component={WoundCarePageAdult} />
        <Redirect to={knowledgeRoutes.communityMedications} />
      </Switch>
    </>
  );
};

export default MedicationsRoutes;
