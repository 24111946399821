import { niceGuidelinesClient } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { NICE_CACHE_TIME } from "../constants";
import { useQuery } from "@tanstack/react-query";

export const useGetNiceItemUrl = (id: string, isEnabled: boolean = true) => {
  const { flags } = useLaunchDarkly();

  const isQueryEnabled = flags[LDFlagNames.NICE_GUIDELINES_SECTION] && isEnabled;
  const { data, isLoading, error, isError } = useQuery({
    queryKey: [`nice-guideline-${id}`],
    queryFn: () => niceGuidelinesClient.getNiceSummaryById(id),
    cacheTime: NICE_CACHE_TIME,
    enabled: isQueryEnabled,
  });

  return {
    url: data?.url,
    title: data?.title ?? "",
    isLoading: isLoading && isEnabled,
    error,
    isError,
  };
};
