import { motion } from "framer-motion";

import { AnalyticsEvents, RecentQuestion } from "@eolas-medical/core";
import { useGetRecentQuestions } from "modules/clinicalQuestions/data/useGetRecentQuestions";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Text, Title } from "UIKit";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { ClinicalQuestion } from "../hooks/useClinicalQuestionInput";
import { UseFormSetValue } from "react-hook-form";
import { trackEvent } from "API/Analytics";

export const RecentQuestions = ({
  onSubmit,
  setValue,
}: {
  onSubmit: () => void;
  setValue: UseFormSetValue<ClinicalQuestion>;
}) => {
  const { list, error, isFetching } = useGetRecentQuestions();

  const shouldShowSpinner = isFetching && !error;
  const shouldShowRecentQuestions = !error && !isFetching;

  const { t } = useTranslation();
  const renderItem = useCallback(
    ({ item }: EolasVersatileListRenderItemProps<RecentQuestion>) => {
      return (
        <div className="mb-2">
          <EolasTile
            icon={null}
            variant="rectangular"
            primaryText={<Text level={1}>{item.title}</Text>}
            onClick={() => {
              setValue("question", item.title);
              trackEvent(AnalyticsEvents.CLINICAL_QUESTION_RECENT_SELECTED);
              onSubmit();
            }}
          />
        </div>
      );
    },
    [onSubmit, setValue],
  );
  return (
    <div className="flex flex-col w-full ">
      <Title level={6} className="text-center text-grey-700 mb-3">
        {t("latest_clinical_questions")}
      </Title>
      {shouldShowSpinner ? <Loader className="bg-transparent" size={40} /> : null}
      {error ? (
        <Text level={2} className="text-center italic text-grey-700 mt-6">
          {t("error_fetching_recent_questions")}
        </Text>
      ) : null}

      {shouldShowRecentQuestions ? (
        <motion.div
          initial={{ opacity: 0, y: "10%" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
          exit={{ opacity: 0, y: "10%" }}
        >
          <EolasVersatileList
            keyExtractor={(item) => item.title}
            items={list}
            renderItem={renderItem}
            ListEmptyComponent={EmptyComponent}
          />
        </motion.div>
      ) : null}
    </div>
  );
};

const EmptyComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <Text level={2} className="italic text-grey-700 mt-6">
        {t("no_recent_questions")}
      </Text>
    </div>
  );
};
