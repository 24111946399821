import { useMemo, useState } from "react";
import { ListItem } from "../../SelectTileList/hooks/useLocalChildrenList";
import { ContentItem, Maybe, SectionsMap, sectionStore, userStore } from "@eolas-medical/core";
import { useCopyItems } from "modules/contentRepository/data/useCopyItems";
import uniq from "lodash/uniq";
import { isInvalidItem } from "../functions/isInvalidItem";
import {
  isAncestorSectionIdInLimitedAccessArray,
  makeFilterFnForChildren,
  makeLimitedAdminSectionLookupMap,
} from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { ActiveTab } from "Utilities/types";

type Props = {
  initialItems: ContentItem[];
  shouldShowOrgItems: boolean;
  shouldShowSpaceItems: boolean;
};

export const useCopyItemsModal = ({
  initialItems,
  shouldShowOrgItems,
  shouldShowSpaceItems,
}: Props) => {
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);
  const [itemsToCopy, setItemsToCopy] = useState<ContentItem[]>(initialItems);
  const [didRetryFailedItems, setDidRetryFailedItems] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { copyItems, isError, isLoading, results } = useCopyItems();
  const spaceLimitedAccess = useGetLimitedAccess({ activeTab: "spaces" });
  const orgLimitedAccess = useGetLimitedAccess({ activeTab: "organisation" });
  const { sectionsMap } = sectionStore;

  const shouldShowSuccess = results?.wasCompleteSuccess && !isError;
  const shouldShowError = results?.wasCompleteFailure || isError;
  const shouldShowPartialSuccess =
    !!results?.hasFailedItems && !shouldShowError && !shouldShowSuccess;
  const hasRetryFailed = (results?.hasFailedItems || isError) && didRetryFailedItems;
  const hasInvalidItems = itemsToCopy.some((item) => isInvalidItem(item));

  const disabledSectionIds = useMemo(() => {
    const sectionIdsOfOriginals = itemsToCopy.map((item) => item.parentId);
    const spaceSectionIdsToDisable =
      shouldShowSpaceItems && spaceLimitedAccess.length
        ? generateSectionIdsToDisable(
            spaceLimitedAccess,
            getRelevantSectionMap(sectionsMap, "spaces"),
          )
        : [];
    const orgSectionIdsToDisable =
      shouldShowOrgItems && orgLimitedAccess.length
        ? generateSectionIdsToDisable(
            orgLimitedAccess,
            getRelevantSectionMap(sectionsMap, "organisation"),
          )
        : [];
    return uniq([...sectionIdsOfOriginals, ...spaceSectionIdsToDisable, ...orgSectionIdsToDisable]);
  }, [
    itemsToCopy,
    sectionsMap,
    shouldShowSpaceItems,
    shouldShowOrgItems,
    spaceLimitedAccess,
    orgLimitedAccess,
  ]);

  const handleSelectionChange = (selectedIds: Record<string, ListItem>) => {
    const selectedIdsArray = Object.keys(selectedIds);
    if (selectedIdsArray.length === 1) {
      setSelectedSectionId(selectedIdsArray[0]);
    } else {
      setSelectedSectionId(null);
    }
  };

  const handleCopyItems = async () => {
    if (selectedSectionId) {
      await copyItems({ contentItems: itemsToCopy, destinationSectionId: selectedSectionId });
    }
  };

  const retryFailedItems = async (failedItems: ContentItem[]) => {
    if (selectedSectionId) {
      await copyItems({ contentItems: failedItems, destinationSectionId: selectedSectionId });
      setDidRetryFailedItems(true);
    }
  };

  const onInvalidItemsContinue = (items: ContentItem[]) => setItemsToCopy(items);

  return {
    selectedSectionId,
    handleSelectionChange,
    isSearching,
    setIsSearching,
    handleCopyItems,
    isCopying: isLoading,
    shouldShowSuccess,
    shouldShowPartialSuccess,
    shouldShowError,
    retryFailedItems,
    hasRetryFailed,
    failedItems: results?.failedItems ?? [],
    hasInvalidItems,
    onInvalidItemsContinue,
    disabledSectionIds,
    itemsToCopy,
  };
};

const generateSectionIdsToDisable = (limitedAccessArray: string[], sectionsMap: SectionsMap) => {
  const idsToDisable: string[] = [];

  const shouldIncludeAsDisabledSectionFn = makeLimitedAccessDisabledIdsFilterFn(limitedAccessArray);

  for (const section of Object.values(sectionsMap)) {
    if (shouldIncludeAsDisabledSectionFn(section.id)) {
      idsToDisable.push(section.id);
    }
  }

  return idsToDisable;
};

const makeLimitedAccessDisabledIdsFilterFn = (
  limitedAccessArray: string[],
): ((sectionId: string) => boolean) => {
  const limitedAdminSectionLookupMap = makeLimitedAdminSectionLookupMap(limitedAccessArray);
  const limitedAdminFilterFn = makeFilterFnForChildren(limitedAdminSectionLookupMap, false);
  return (sectionId: string) => {
    const shouldIncludeBasedOnPath = limitedAdminFilterFn(sectionId);
    if (shouldIncludeBasedOnPath) {
      return false;
    }
    const isAncestorLimitedAdmin = isAncestorSectionIdInLimitedAccessArray(
      sectionId,
      limitedAdminSectionLookupMap,
    );
    if (isAncestorLimitedAdmin) {
      return false;
    }
    return true;
  };
};

const getRelevantSectionMap = (
  sectionMap: SectionsMap,
  activeTab: Extract<ActiveTab, "spaces" | "organisation">,
) => {
  const newSectionMap: SectionsMap = {};
  const sectionsOrder =
    activeTab === "spaces"
      ? sectionStore.departmentSectionsOrder
      : sectionStore.hospitalSectionsOrder;
  for (const section of Object.values(sectionMap)) {
    if (sectionsOrder.some(({ id }) => id === section.mainSectionID)) {
      newSectionMap[section.id] = section;
    }
  }
  return newSectionMap;
};
