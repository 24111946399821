import { sectionStore, userStore } from "@eolas-medical/core";
import { useEolasNavigation, useRoleSwitcher } from "Components/Navigation/hooks";
import { AdminDashboardIconType } from "Hooks";
import { getOrganisationRoute, getSpaceRoute } from "Pages/Spaces/helpers";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSpacesContext } from "../context";
import useSpace from "./useSpace";

type AdminDashboardSection = {
  title: string;
  route: string;
  description: string;
  id: AdminDashboardIconType;
  disabled?: boolean;
};

const limitedAdminSections: AdminDashboardSection[] = [
  {
    id: "contentManagement",
    title: "adminHome_cm_card_title",
    description: "adminHome_cm_card_description",
    route: "content-management",
  },
];

const adminSections: AdminDashboardSection[] = [
  ...limitedAdminSections,
  {
    id: "manageUsers",
    title: "adminHome_manage_users_card_title",
    description: "adminHome_manage_users_card_description",
    route: "manageUsers",
  },
];

const useOrganisationAdminSections = () => {
  const fullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const { switchToUser } = useRoleSwitcher();

  useEffect(() => {
    if (!isAccessLevelInAdminGroup(fullAccessLevel)) {
      switchToUser();
    }
  }, [fullAccessLevel, switchToUser]);

  if (fullAccessLevel === "admin") {
    return { activeAdminMainSections: adminSections };
  }

  if (fullAccessLevel === "limitedAdmin") {
    return { activeAdminMainSections: limitedAdminSections };
  }

  return { activeAdminMainSections: [] };
};

export default useOrganisationAdminSections;
