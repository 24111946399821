import {
  additionalContentSections,
  communitySubsectionNames,
  interactionsSections,
  monographSections,
} from "@eolas-medical/core";
import { BnfRouteKey } from "./types";
import { MedicationSubSectionWithId } from "./data/useBnfSections";

export const BNF_CACHE_TIME = 32 * 60 * 60 * 1000;
export const BNF_STALE_TIME = 24 * 60 * 60 * 1000;

type Ids = Extract<
  BnfRouteKey,
  | "comingSoonAdult"
  | "comingSoonChild"
  | "guidanceBnf"
  | "guidanceBnfc"
  | "treatmentSummariesBnf"
  | "treatmentSummariesBnfc"
>;

const routeIds: Record<Ids, Ids> = {
  comingSoonAdult: "comingSoonAdult",
  comingSoonChild: "comingSoonChild",
  guidanceBnf: "guidanceBnf",
  guidanceBnfc: "guidanceBnfc",
  treatmentSummariesBnf: "treatmentSummariesBnf",
  treatmentSummariesBnfc: "treatmentSummariesBnfc",
};

export const webMonographSubsections = monographSections;
export const webInteractionsSubsections = interactionsSections;
export const webAdditionalContentSubsections = additionalContentSections;

export const webComingSoonSubSections: MedicationSubSectionWithId[] = [
  {
    subsectionType: "adultMedicationsBnf",
    name: communitySubsectionNames.adultMedicationsBnf,
    id: routeIds.comingSoonAdult,
  },
  {
    subsectionType: "paediatricMedicationsBnfc",
    name: communitySubsectionNames.paediatricMedicationsBnfc,
    id: routeIds.comingSoonChild,
  },
];

export const webGuidanceAndTreatmentSummariesSubSections: MedicationSubSectionWithId[] = [
  {
    subsectionType: "guidanceBnf",
    name: communitySubsectionNames.guidance_adult,
    id: routeIds.guidanceBnf,
  },
  {
    subsectionType: "guidanceBnfc",
    name: communitySubsectionNames.guidance_child,
    id: routeIds.guidanceBnfc,
  },
  {
    subsectionType: "treatmentSummariesBnf",
    name: communitySubsectionNames.treatment_summaries_adult,
    id: routeIds.treatmentSummariesBnf,
  },
  {
    subsectionType: "treatmentSummariesBnfc",
    name: communitySubsectionNames.treatment_summaries_child,
    id: routeIds.treatmentSummariesBnfc,
  },
];
