import {
  ProcessingInstruction,
  makeProcessNode,
  makeShouldProcessNode,
} from "shared/functions/makeHtmlToReactNodeParser";
import { processAnchorTag } from "./CustomComponents/CustomAnchorTag";
import {
  shouldProcessCustomEvidenceTag,
  processCustomEvidenceTag,
  shouldRenderHiddenSpan,
  processHiddenSpan,
} from "./CustomComponents/CustomEvidenceLinkComponents";
import { processImgTag } from "./CustomComponents/CustomImgTag";

const bnfProcessingInstructions: ProcessingInstruction[] = [
  {
    shouldProcessNode: makeShouldProcessNode({
      className: "evidence-grading",
    }),
    processNode: makeProcessNode({ tagName: "span", classNameToAdd: "bg-grey-100" }),
  },
  {
    shouldProcessNode: shouldRenderHiddenSpan,
    processNode: processHiddenSpan,
  },
  { shouldProcessNode: shouldProcessCustomEvidenceTag, processNode: processCustomEvidenceTag },
  { shouldProcessNode: makeShouldProcessNode({ tagName: "a" }), processNode: processAnchorTag },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "img" }),
    processNode: processImgTag,
  },
];

export default bnfProcessingInstructions;
