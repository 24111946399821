import { CreateLinkItemDto, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

const useAddKnowledgeContentLink = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (linkDto: CreateLinkItemDto) => {
      await knowledgeContentClient.createLinkItem(linkDto);
    },
  );

  return {
    addLink: mutate,
    addingLink: isLoading,
    addingLinkSuccess: isSuccess,
    addingLinkError: error instanceof Error ? error.message : "",
  };
};

export default useAddKnowledgeContentLink;
