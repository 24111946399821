import { Text, IconButton } from "UIKit";
import { DeleteIcon, EditMonoIcon, TickChecklistIcon, TextChecklistIcon, DragIcon } from "Assets";

import { ChecklistSectionItem } from "../types";

interface ChecklistItemProps {
  onEdit(): void;
  onDelete(): void;
  item: ChecklistSectionItem;
}

export const ChecklistItem: React.FC<ChecklistItemProps> = ({
  item,
  onEdit,
  onDelete,
}: ChecklistItemProps) => {
  const Icon = item.itemType === "checkbox" ? TickChecklistIcon : TextChecklistIcon;

  return (
    <div className="flex px-2 sm:px-4 py-2 sm:py-3 border-b-2 border-grey-300">
      <DragIcon className="w-4 h-4 text-quartz-400 mr-4" />
      <div className="self-center mr-2 bg-grey-100 rounded-full h-12 w-12 ">
        <Icon className="h-12 w-12 mr-2 p-3" />
      </div>

      <div className="flex flex-1 flex-col mr-4">
        <Text level={1} className="line-clamp-1">
          {item.itemTitle}
        </Text>
        <Text level={2} className="line-clamp-1 text-grey-600">
          {item.itemInfo}
        </Text>
      </div>
      <div className="self-center flex">
        <IconButton
          size="sm"
          type="button"
          onClick={onEdit}
          className="mr-2 sm:mr-4"
          icon={<EditMonoIcon />}
        />
        <IconButton size="sm" onClick={onDelete} type="button" icon={<DeleteIcon />} />
      </div>
    </div>
  );
};
