import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { InnerPageWrapper, NavButton, PageTitle, Text, Title } from "UIKit";
import { removeLastUrlPath } from "Utilities";
import useExpiringContentItems, {
  ExpiringPeriod,
} from "modules/contentRepository/data/useExpiringContentItems";

import ExpiringPeriodFilters from "./components/ExpiringPeriodFilters/ExpiringPeriodFilters";
import ExpiringItemTile from "./components/ExpiringItemTile/ExpiringItemTile";
import { observer } from "mobx-react-lite";

interface ManageExpiredItemsProps {
  contentRepositoryId: string;
}
const ManageExpiredItems = observer(({ contentRepositoryId }: ManageExpiredItemsProps) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { replace } = useHistory();

  const backUrl = removeLastUrlPath(url, 1);
  const [expiringPeriod, setExpiringPeriod] = useState<ExpiringPeriod>(1);

  const { expiredItems, itemsExpiringSoon } = useExpiringContentItems({
    contentRepositoryId,
    expiringPeriod,
  });

  const handleChangePeriod = (newPeriod: ExpiringPeriod) => {
    setExpiringPeriod(newPeriod);
  };

  const renderExpiredItems = () => {
    if (!expiredItems.length) {
      return (
        <div className="h-32 bg-grey-200 flex justify-center items-center rounded-lg">
          <Text level={1} className="text-grey-600">
            {t("repository_no_expired")}
          </Text>
        </div>
      );
    }

    return (
      <div className="flex flex-col space-y-2">
        {expiredItems.map((contentItem) => {
          return (
            <div key={contentItem.id}>
              <ExpiringItemTile contentItem={contentItem} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderExpiringItems = () => {
    if (!itemsExpiringSoon.length) {
      return (
        <div className="h-32 bg-grey-200 flex justify-center items-center rounded-lg">
          <Text level={1} className="text-grey-600">
            {t(`repository_no_expired_items_in_${expiringPeriod}months`)}
          </Text>
        </div>
      );
    }

    return (
      <div className="flex flex-col space-y-2">
        {itemsExpiringSoon.map((contentItem) => {
          return (
            <div key={contentItem.id}>
              <ExpiringItemTile contentItem={contentItem} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("expiredPage_title", { section: t("Items") })} />
        <NavButton onClick={() => replace(backUrl)} />
      </div>

      <div className="space-y-5">
        <Title level={5}>{t("repository_expired_items")}</Title>
        {renderExpiredItems()}
      </div>

      <div className="space-y-5">
        <Title level={5}>{t("repository_expired_items_soon")}</Title>
        <ExpiringPeriodFilters selectedPeriod={expiringPeriod} onClickFilter={handleChangePeriod} />
        {renderExpiringItems()}
      </div>
    </InnerPageWrapper>
  );
});

export default ManageExpiredItems;
