import { ContentSection } from "modules/contentRepository/types";
import { MedicationSubSectionWithId } from "modules/bnf/data/useBnfSections";

import { BaseSection, ChildReference, isKnowledgeSection } from "@eolas-medical/core";
import { getIconUrl } from "modules/helpers";
import { MedicationSection } from "./types";

export const mapToMedicationSection = (
  childReference: ChildReference | BaseSection,
): MedicationSection => {
  const routeId = /#/.test(childReference.id) ? childReference.id.split("#")[1] : childReference.id;

  return {
    ...childReference,
    routeId,
    iconName: childReference.icon || "",
    iconUrl: getIconUrl(childReference.icon || ""),
    isContentRepository: isKnowledgeSection(childReference),
  };
};

export const mapFromCommunityMedicationSubsection = (
  section: MedicationSubSectionWithId,
): ContentSection => {
  return {
    id: section.id,
    name: section.name,
    iconName: section.subsectionType,
    routeId: section.subsectionType,
    iconUrl: section.subsectionType,
  };
};
