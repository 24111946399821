import { useCallback, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import { initializeVideoViewer } from "@pdftron/webviewer-video";
import { BlobContent } from "@eolas-medical/core";
import useBlobViewer from "../useBlobViewer";

interface UseVideoViewer {
  video: BlobContent;
}

const useVideoViewer = ({ video }: UseVideoViewer) => {
  const viewer = useRef<HTMLDivElement>(null);
  const { onFileError, onFileLoaded, onViewerLoadError } = useBlobViewer();

  const videoViewer = useCallback(
    () =>
      viewer.current && video && video.url
        ? WebViewer(
            {
              path: "/pdftron",
              initialDoc: video.url,
              disabledElements: ["ribbons", "toolsHeader", "toggleNotesButton", "selectToolButton"],
            },
            viewer.current,
          )
            .then(async (instance) => {
              const license = process.env.REACT_APP_PDF_TRON;
              instance.UI.addEventListener(instance.UI.Events.DOCUMENT_LOADED, onFileLoaded);
              instance.UI.addEventListener(instance.UI.Events.LOAD_ERROR, onViewerLoadError);
              instance.Core.disableEmbeddedJavaScript();
              // Extends WebViewer to allow loading HTML5 videos (.mp4, ogg, webm).
              const { loadVideo } = await initializeVideoViewer(instance, { license });
              if (video.url && video.media.type === "mp4") loadVideo(video.url);
            })
            .catch(onFileError)
        : null,
    [viewer, video, onFileError, onFileLoaded, onViewerLoadError],
  );

  useEffect(() => {
    if (!video) return;

    videoViewer();
  }, [video, videoViewer]);

  return { onFileLoaded, onFileError };
};

export default useVideoViewer;
