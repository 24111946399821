import { useMutation } from "@tanstack/react-query";
import { CreateMainSectionDto, contentClient } from "@eolas-medical/core";
import { useRefetchAppData } from "Hooks";

const useCreateMainSection = () => {
  const { refetch } = useRefetchAppData();
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (mainSection: CreateMainSectionDto) => {
      return contentClient.createMainSection(mainSection);
    },
    {
      onSuccess: refetch,
    },
  );

  return {
    createCreateMainSection: mutate,
    creatingMainSection: isLoading,
    errorCreatingMainSection: error,
    createContentMainSection: isSuccess,
  };
};

export default useCreateMainSection;
