import {
  AppLevelSection,
  CommunityLevelSection,
  CommunityMedicationsMiniApp,
  ContentItem,
  EolasFile,
  MedicationSummaryItem,
  NiceSummaryItem,
  OrganisationLevelSection,
  isMedicationSummaryItem,
  sectionStore,
  ChildReference,
  NiceGuidelinesMetadata,
  isNiceGuidelinesMetadata,
  MedicationResultMetadata,
  isMedicationMetadata,
  SectionNonAppsync,
  isEolasFile,
} from "@eolas-medical/core";
import { errorStore } from "Stores/ErrorStore";
import { ActiveTab } from "Utilities/types";
import {
  isSupportedBNFMedicationType,
  isSupportedOrganisationContentDeepLinkType,
  isSupportedSpaceContentDeepLinkType,
} from "deeplinking/functions/typeguards";
import { KnowledgeDeeplinkParams } from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";
import { OrganisationContentDeepLinkParams } from "deeplinking/types/contentDeeplinks/organisationContentDeeplinks.types";
import { SpaceContentDeepLinkParams } from "deeplinking/types/contentDeeplinks/spacesContentDeeplinks.types";
import envConfig from "env-config";
import { isNiceSummaryItem } from "modules/niceGuidelines/typeguards";
import { isChildReference, isEolasSection } from "./typeguards";
import { isChildRefEolasCalculator } from "Pages/Knowledge/pages/ClinicalCalculators/functions/typeguards";
import { ChecklistItem } from "Pages/Spaces/pages/Space/pages/MiniApp/Checklists";
import { isChecklistItem } from "Pages/Spaces/pages/Space/pages/MiniApp/Checklists/functions/typeguards";
import { isDev } from "Utilities/helpers";
import { isShareableMasterSearchFile } from "Components/MasterSearch/functions/typeguards";
import { ShareableMasterSearchFile } from "Components/MasterSearch/types";

type ShareableFileLinkRoots = "space" | "organisation" | "knowledge";

type SpaceContentDeepLinkParamsWithSpaceId = SpaceContentDeepLinkParams & { spaceId: string };
type OrganisationContentDeepLinkParamsWithOrgId = OrganisationContentDeepLinkParams & {
  organisationId: string;
};

type GenerateShareableFileLinkBaseParams<
  Root extends ShareableFileLinkRoots,
  P extends
    | SpaceContentDeepLinkParamsWithSpaceId
    | OrganisationContentDeepLinkParamsWithOrgId
    | KnowledgeDeeplinkParams,
> = {
  root: Root;
} & P;

type GenerateShareableFileLinkParams =
  | GenerateShareableFileLinkBaseParams<"space", SpaceContentDeepLinkParamsWithSpaceId>
  | GenerateShareableFileLinkBaseParams<"organisation", OrganisationContentDeepLinkParamsWithOrgId>
  | GenerateShareableFileLinkBaseParams<"knowledge", KnowledgeDeeplinkParams>;

export type CopilotResults = NiceGuidelinesMetadata | MedicationResultMetadata;

export type EntityItem =
  | ContentItem
  | MedicationSummaryItem
  | NiceSummaryItem
  | CopilotResults
  | EolasFile
  | ChildReference
  | ChecklistItem
  | ShareableMasterSearchFile
  | SectionNonAppsync;

export type NonCopilotEntityItem = Exclude<EntityItem, CopilotResults>;

const BASE_DOMAIN = `${envConfig.REACT_APP_PROTOCOL}://${envConfig.REACT_APP_WEB_DOMAIN}`;
const ORGANISATION_BASE_PATH = `${BASE_DOMAIN}/organisation/landing`;
const SPACE_BASE_PATH = `${BASE_DOMAIN}/spaces/landing`;
const KNOWLEDGE_BASE_PATH = `${BASE_DOMAIN}/knowledge/landing`;

const getSectionIds = (
  item: Exclude<
    EntityItem,
    ChecklistItem | NiceSummaryItem | CopilotResults | MedicationSummaryItem | ChildReference
  >,
): { mainSectionId: string; parentId: string; ownerId: string; id: string } => {
  if (isEolasFile(item) || isShareableMasterSearchFile(item)) {
    return {
      mainSectionId: item.mainSectionID,
      parentId: item.parentID,
      ownerId: item.ownerID,
      id: item.id,
    };
  }

  if (isEolasSection(item)) {
    return {
      mainSectionId: item.mainSectionID,
      parentId: item.parentID ?? "",
      ownerId: item.ownerID,
      id: item.id,
    };
  }

  return {
    mainSectionId: item.mainSectionId,
    parentId: item.parentId,
    ownerId: item.ownerId,
    id: item.id,
  };
};

const getParamsForContentItemLink = (
  item: EntityItem,
  itemOwnerType: ActiveTab,
  mainSectionIdParam?: string,
  linkOrigin: "section" | "document" = "section",
): GenerateShareableFileLinkParams | null => {
  // BNF MEDICATIONS AND BNFC MEDICATIONS
  if (isMedicationSummaryItem(item) || isMedicationMetadata(item)) {
    const subsectionType = isMedicationSummaryItem(item) ? item.type : item.bnfSubsectionType;
    if (isSupportedBNFMedicationType(subsectionType)) {
      return {
        root: "knowledge",
        type: CommunityLevelSection.communityMedications,
        bnfId: isMedicationSummaryItem(item) ? item.id : item.bnfId,
        subsectionType: subsectionType,
      };
    }

    errorStore.captureError({
      error: "Failed generate link to bnfItem as medication type was not supported",
      source: "user",
      data: { item },
    });
    return null;
  }

  // NICE SUMMARIES
  if (isNiceSummaryItem(item) || isNiceGuidelinesMetadata(item)) {
    const mainSectionChildRef = sectionStore.getChildReferenceByMainSectionType(
      CommunityLevelSection.niceGuidelines,
    );
    if (mainSectionChildRef) {
      return {
        root: "knowledge",
        type: CommunityLevelSection.niceGuidelines,
        niceSummaryId: isNiceSummaryItem(item) ? item.id : item.niceGuidanceId,
      };
    }

    errorStore.captureError({
      error:
        "Failed generate link to niceSummary as childReference could not be obtained via getChildReferenceByMainSectionType",
      source: "user",
      data: { item, mainSectionIdParam },
    });
    return null;
  }

  if (isChildReference(item)) {
    if (isChildRefEolasCalculator(item)) {
      return {
        root: "knowledge",
        type: CommunityLevelSection.clinicalCalculators,
        clinicalCalculatorsSubSectionType: item.icon,
        fileId: item.id,
      };
    }

    errorStore.captureError({
      error: "ChildReference was not an EolasCalculator, unable to generate link to item",
      source: "user",
      data: { item, mainSectionIdParam },
    });
    return null;
  }

  if (isChecklistItem(item)) {
    return {
      root: "space",
      type: AppLevelSection.checklists,
      checklistId: item.id,
      spaceId: item.ownerID,
      origin: linkOrigin,
    };
  }

  if (isEolasSection(item)) {
    if (itemOwnerType === "spaces") {
      return {
        root: "space",
        spaceId: item.ownerID,
        type: "null",
        sectionId: item.id,
        origin: linkOrigin,
      };
    }

    if (itemOwnerType === "organisation") {
      return {
        root: "organisation",
        organisationId: item.ownerID,
        type: "null",
        sectionId: item.id,
        origin: linkOrigin,
      };
    }

    errorStore.captureError({
      error: "Item owner type is not supported",
      source: "user",
      data: { itemOwnerType },
    });
    return null;
  }

  const { id, mainSectionId, parentId, ownerId } = getSectionIds(item);

  const sectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(mainSectionId);

  if (!id) {
    // content item didn't have the required properties
    errorStore.captureError({
      error: "Content item didn't have the required properties",
      source: "user",
      data: { item, missingProperties: ["id"] },
    });
    return null;
  }

  // GENERIC CONTENT REPOSITORY ITEMS

  if (sectionIdentity === "genericContentRepository") {
    if (!ownerId) {
      // content item didn't have the required properties
      errorStore.captureError({
        error: "Content item didn't have the required properties",
        source: "user",
        data: { item, missingProperties: ["ownerId"] },
      });
      return null;
    }

    if (itemOwnerType === "spaces") {
      return {
        root: "space",
        spaceId: ownerId,
        type: "null",
        fileId: id,
        origin: linkOrigin,
      };
    }

    if (itemOwnerType === "organisation") {
      return {
        root: "organisation",
        organisationId: ownerId,
        type: "null",
        fileId: id,
        origin: linkOrigin,
      };
    }

    // item owner type is not supported
    errorStore.captureError({
      error: "Item owner type is not supported",
      source: "user",
      data: { itemOwnerType },
    });
    return null;
  }

  // item is not a generic content repository item
  const maybeMainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(mainSectionId);

  if (!maybeMainSectionType) {
    // COMMUNITY MEDICATIONS HIGHLIGHTS
    if (itemOwnerType === "knowledge") {
      // Community medication highlights are a special case where the main section type is a subtype of community medications
      const maybeChildReference = sectionStore.getMainSectionChildReference(mainSectionId);
      if (
        maybeChildReference &&
        maybeChildReference.icon === CommunityMedicationsMiniApp.pharmaFlashcards
      ) {
        return {
          root: "knowledge",
          type: CommunityLevelSection.communityMedications,
          mainSectionId: mainSectionId,
          medicationHighlightId: id,
          parentId,
          ownerId,
        };
      }
    }

    // failed to determine main section type, can't proceed
    errorStore.captureError({ error: "Failed to determine main section type", source: "user" });
    return null;
  }

  // SMPCs
  if (maybeMainSectionType === CommunityLevelSection.communityMedications) {
    return {
      root: "knowledge",
      type: maybeMainSectionType,
      mainSectionId,
      contentId: id,
      parentId,
      ownerId,
    };
  }
  // National resources
  if (
    maybeMainSectionType === CommunityLevelSection.nationalGuidelines ||
    maybeMainSectionType === CommunityLevelSection.clinicalCalculators ||
    maybeMainSectionType === CommunityLevelSection.communityPatientLeaflets
  ) {
    return {
      root: "knowledge",
      type: maybeMainSectionType,
      fileId: id,
    };
  }

  // SPACE CONTENT ITEMS

  if (isSupportedSpaceContentDeepLinkType(maybeMainSectionType)) {
    if (maybeMainSectionType === AppLevelSection.newsFeed) {
      return {
        root: "space",
        spaceId: ownerId,
        type: maybeMainSectionType,
        fileId: id,
        origin: linkOrigin,
        mainSectionId,
        parentId,
      };
    }

    return {
      root: "space",
      spaceId: ownerId,
      // type is guaranteed to be a supported type here due to the type guard
      // but ts is struggling to allow a single variable to be used for all the variations of types
      // even though in practice its correct, so having to cast purely because ts is definately wrong in this scenario and to help ts
      // we would need to create a new return for each variation of type, with the same props
      type: maybeMainSectionType as AppLevelSection.admissionGuides,
      fileId: id,
      origin: linkOrigin,
    };
  }

  // ORGANISATION CONTENT ITEMS

  if (isSupportedOrganisationContentDeepLinkType(maybeMainSectionType)) {
    if (maybeMainSectionType === OrganisationLevelSection.medusaMedications) {
      return {
        root: "organisation",
        organisationId: ownerId,
        type: maybeMainSectionType,
        mainSectionId: mainSectionId,
        fileId: id,
        origin: linkOrigin,
      };
    }

    if (maybeMainSectionType === OrganisationLevelSection.communicationPortal) {
      return {
        root: "organisation",
        organisationId: ownerId,
        type: maybeMainSectionType,
        mainSectionId: mainSectionId,
        parentId,
        fileId: id,
        origin: linkOrigin,
      };
    }

    // content repositories
    return {
      root: "organisation",
      organisationId: ownerId,
      // same as above
      type: maybeMainSectionType as OrganisationLevelSection.genericOrganisationGuidelines,
      fileId: id,
      origin: linkOrigin,
    };
  }

  errorStore.captureError({
    error: "Main section type is not supported",
    source: "user",
    data: { maybeMainSectionType },
  });
  return null;
};

type GenerateLinkToItemReturn = {
  link: string;
  origin: GenerateShareableFileLinkParams["type"];
};

export const generateLinkToItem = (
  item: EntityItem,
  itemOwnerType: ActiveTab,
  mainSectionId?: string,
  pageNumber?: number,
  linkOrigin?: "section" | "document",
): GenerateLinkToItemReturn | null => {
  const params = getParamsForContentItemLink(item, itemOwnerType, mainSectionId, linkOrigin);

  if (!params) {
    // no need to log error here as it would have been logged in getParamsForContentItemLink
    return null;
  }

  const { root, type, ...rest } = params;

  let base = null;

  switch (root) {
    case "space":
      base = SPACE_BASE_PATH;
      break;
    case "organisation":
      base = ORGANISATION_BASE_PATH;
      break;
    case "knowledge":
      base = KNOWLEDGE_BASE_PATH;
      break;
    default:
      errorStore.captureError({
        error: "Root is not supported for generating link to item",
        source: "user",
        data: { root },
      });
      return null;
  }

  const path = `${base}/${type}`;

  const url = new URL(path);

  Object.entries(rest).forEach(([key, value]) => {
    if (typeof value !== "string") {
      if (isDev()) {
        console.warn(`Value for key ${key} is not a string, skipping...`);
      }
      return;
    }
    // Due to existing links incorrectly camel cased (news feed), we decided to use this casing for mainSectionId in all links for consistency
    // when a frontend handles a link it can convert it to camel case
    if (key === "mainSectionId") {
      url.searchParams.append("mainsectionId", value);
    } else {
      url.searchParams.append(key, value);
    }
  });

  if (pageNumber) {
    url.searchParams.append("pageNo", pageNumber.toString());
  }

  return {
    link: url.toString(),
    origin: type,
  };
};
