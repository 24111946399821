import { useController } from "react-hook-form";

import {
  compareWithNumbers,
  getObjectEntries,
  QuestionType,
  StringNumber,
} from "@eolas-medical/core";
import { Context, QuestionItem } from "../types";
import { IconButton, Text, ToggleList, ToggleListOption } from "UIKit";
import { InfoIconMonocolored } from "Assets";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { DsmTextModal } from "./DsmTextModal";
import { modalStore } from "Stores/ModalStore";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";

export const DsmQuestion = ({
  item: {
    item,
    context: { control },
  },
}: {
  item: EolasVersatileListRenderItemProps<QuestionItem, Context>;
}) => {
  const { qNumber, question, answers, info, qType } = item;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: qNumber });

  let selector: JSX.Element;

  if (qType === QuestionType.list) {
    const selectOptions = getObjectEntries(answers)
      .sort(([, a], [, b]) => compareWithNumbers(a, b))
      .map(([text, answerNumber]) => ({ label: text, value: answerNumber }));
    selector = (
      <EolasSelect
        mode="synchronous"
        options={selectOptions}
        onChange={(s) => {
          if (s) {
            onChange(s.value);
          }
        }}
        value={selectOptions.find((option) => option.value === value) || null}
      />
    );
  } else {
    const toggleOptions: ToggleListOption<StringNumber>[] = getObjectEntries(answers)
      .sort(([, a], [, b]) => compareWithNumbers(a, b))
      .map(([text, answerNumber]) => ({ title: text, key: answerNumber }));
    selector = (
      <div className="border border-grey-300 rounded-lg px-4 bg-white">
        <ToggleList mode="controlled" options={toggleOptions} setValue={onChange} value={value} />
      </div>
    );
  }

  return (
    <div className="flex flex-col my-5">
      <div className="flex flex-row items-center gap-2 px-4 mb-2">
        <Text level={1} className="font-bold text-lg" element="label">
          {question}
        </Text>
        {info ? (
          <IconButton
            size="sm"
            type="button"
            icon={<InfoIconMonocolored />}
            variant="rounded"
            onClick={() => {
              modalStore.openModal({
                variant: "component",
                Component: <DsmTextModal title={question} text={info} />,
                name: "dsmModal",
              });
            }}
          />
        ) : null}
      </div>
      {error ? (
        <Text level={2} className="text-red-500 px-4 mb-3" element="label">
          {error.message}
        </Text>
      ) : null}
      {selector}
    </div>
  );
};
