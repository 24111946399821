import { useCallback, useMemo } from "react";
import { PaginatedItemsList } from "shared/components/Lists";
import { ContentSection } from "modules/contentRepository/types";
import { ContentSectionItem } from "../ContentSectionItem/ContentSectionItem";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";

interface ContentSectionListProps {
  isAdmin: boolean;
  variant: ContentSectionVariants;
}

const PaginatedContentSectionList = ({ isAdmin, variant }: ContentSectionListProps) => {
  const { useContentSectionsHook, useSearchContentSectionsHook } = useContentRepositoryContext();
  const {
    contentSections,
    contentSectionsLoading,
    hasMoreSections,
    fetchMoreSections,
    isFetchingMoreSections,
  } = useContentSectionsHook;

  const {
    searchedSections,
    searchingSections,
    searchValue,
    searchInput,
    onClearSearch,
    onSearchInputChange,
    onSearch,
  } = useSearchContentSectionsHook;

  const items = useMemo(() => {
    if (searchValue.length === 0) {
      return contentSections;
    }

    return searchedSections;
  }, [contentSections, searchedSections, searchValue]);

  const renderItem = useCallback(
    (contentSection: ContentSection) => {
      return (
        <ContentSectionItem contentSection={contentSection} isAdmin={isAdmin} variant={variant} />
      );
    },
    // test
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdmin, searchInput, variant],
  );

  return (
    <div data-testid="content-item-list">
      <PaginatedItemsList<ContentSection>
        items={items}
        renderItem={renderItem}
        isLoading={contentSectionsLoading || searchingSections}
        isFetchingMoreItems={isFetchingMoreSections}
        hasMoreItems={Boolean(hasMoreSections && searchValue.length === 0)}
        searchValue={searchInput}
        onFetchMoreItems={fetchMoreSections}
        onClearSearch={onClearSearch}
        onSearchInputChange={onSearchInputChange}
        onClickSearch={onSearch}
      />
    </div>
  );
};

export default PaginatedContentSectionList;
