import { useEffect, useRef } from "react";

type Callback = (() => void) | (() => Promise<void>);

type Params = { onMount?: Callback; onUnmount?: Callback };

export const useRunOnMountUnmount = ({ onMount, onUnmount }: Params) => {
  const ref = useRef<Params>({ onMount, onUnmount });

  useEffect(() => {
    ref.current.onMount = onMount;
    ref.current.onUnmount = onUnmount;
  });

  useEffect(() => {
    ref.current.onMount?.();
    return () => {
      /**
       * Lint disabled as we are intentionally not
       * freezing this callback inside the useEffect closure.
       * We want this hook to be used without having to worry
       * about dependency arrays in useEffect or useCallback
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current.onUnmount?.();
    };
  }, []);
};
