import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "Assets/Icons";
import { IconButton, InnerModalWrapper, ModalHeader, Title } from "UIKit";

import { useCallback, useState } from "react";
import { useAdminEntry } from "./useAdminEntry";
import { ManageAdminAccessModal } from "./ManageAdminAccessModal";
import { SpaceUser } from "clients/departments/types";
import { ManageLimitedAdminModal } from "./LimitedAdmin/ManageLimitedAdminModal";
import { observer } from "mobx-react-lite";

interface ManageAdminsModalProps {
  appUsers: SpaceUser[];
}

export const ManageAdminsModal = observer(({ appUsers }: ManageAdminsModalProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    step: "manageAdmins" | "manageSections";
    selectedSections?: string[];
    userId?: string;
    userFullName?: string;
    action?: "set" | "update";
  }>({ step: "manageAdmins" });

  const { onUpdateAccessLevel, onUpdateLimitedAccess, isLoading, error } = useAdminEntry();

  const goBack = useCallback(() => setState({ step: "manageAdmins" }), []);

  const handleLimitedAccessChange = useCallback((selectedIds: Record<string, { id: string }>) => {
    setState((prevState) => ({
      ...prevState,
      selectedSections: Object.keys(selectedIds),
    }));
  }, []);

  const handleLimitedAccessSet = useCallback(async () => {
    const { userId, selectedSections, action } = state;
    if (userId && action === "set") {
      await onUpdateAccessLevel({
        userId,
        accessLevel: "admin",
        limitedAccess: selectedSections,
      });
    } else if (userId && selectedSections && action === "update") {
      await onUpdateLimitedAccess({ userId, limitedAccess: selectedSections });
    }
    goBack();
  }, [state, goBack, onUpdateAccessLevel, onUpdateLimitedAccess]);

  return (
    <InnerModalWrapper>
      {state.step === "manageSections" ? (
        <IconButton
          size="sm"
          type="button"
          icon={<ArrowLeftIcon />}
          variant="rounded"
          onClick={goBack}
          data-testid="modal-back-button"
          className="absolute top-4 left-4 z-10"
        />
      ) : null}
      <ModalHeader>
        <Title level={5}> {t("manageUsers_accessRequest_manageAdmin")}</Title>
        {state.userFullName ? (
          <Title className="text-grey-600" level={9}>
            {state.userFullName}
          </Title>
        ) : null}
      </ModalHeader>

      {state.step === "manageSections" && state.userId ? (
        <ManageLimitedAdminModal
          currentSelection={state.selectedSections ?? []}
          isLoading={isLoading}
          onSubmit={handleLimitedAccessSet}
          onChange={handleLimitedAccessChange}
          isMutationError={Boolean(error)}
        />
      ) : (
        <ManageAdminAccessModal
          departmentUsers={appUsers}
          setUserAccess={onUpdateAccessLevel}
          onManageSections={({ userId, selectedSections, action, userFullName }) => {
            setState({ userId, selectedSections, action, step: "manageSections", userFullName });
          }}
        />
      )}
    </InnerModalWrapper>
  );
});
