import { SpaceImage } from "@eolas-medical/core";
import { OrganisationIcon, SpacesIcon } from "Assets";
import { useSpacesContext } from "modules/spaces";

type Props = {
  root: "organisation" | "space";
};

export const RootIcon = ({ root }: Props) => {
  const { selectedSpace } = useSpacesContext();
  const spaceImage: SpaceImage | undefined = selectedSpace?.image;

  if (root === "organisation") {
    return (
      <div className="rounded-1 bg-orange-100 p-4 h-full w-full">
        <OrganisationIcon className="h-full w-full" />
      </div>
    );
  }

  if (root === "space") {
    if (spaceImage && spaceImage.url) {
      return (
        <div className="rounded-1 bg-grey-100 h-full w-full overflow-hidden">
          <img src={spaceImage.url} alt="space image" className="h-full w-full bg-cover" />
        </div>
      );
    }

    return (
      <div className="rounded-1 bg-grey-100 p-4 h-full w-full">
        <SpacesIcon className="h-full w-full" />
      </div>
    );
  }

  return (
    <div className="rounded-1 bg-grey-100 p-4 h-full w-full">
      <SpacesIcon className="h-full w-full" />
    </div>
  );
};
