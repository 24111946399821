import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import { error as errorAnimation } from "Assets/LottieAnimations";
import { BaseAppRoute } from "Utilities/types";
import { sectionStore } from "@eolas-medical/core";

export type GenericErrorProps = {
  errorTitle: string;
  errorDescription: string;
  errorButtonText: string;
  newRoute?: string;
  onClick?: () => void;
};

export const GenericError = ({
  errorTitle,
  errorDescription,
  newRoute,
  errorButtonText,
  onClick,
}: GenericErrorProps) => {
  return (
    <div className="flex flex-col justify-start items-center flex-1 pt-16">
      <Lottie style={{ width: "12rem", height: "12rem" }} animationData={errorAnimation} />
      <span className="font-semibold text-lg">{errorTitle}</span>
      <span className="mt-4 mb-8 text-grey-dark">{errorDescription}</span>
      <Link
        to={
          newRoute ||
          `/${sectionStore.activeDepartmentTab ? BaseAppRoute.spaces : BaseAppRoute.organisation}`
        }
        onClick={onClick}
        className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
      >
        {errorButtonText}
      </Link>
    </div>
  );
};
