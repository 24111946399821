import AccessTypeForm from "Pages/Spaces/components/AccessTypeForm/AccessTypeForm";
import useUpdateSpaceAccessType from "modules/spaces/data/useUpdateSpaceAccessType";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";

const AccessType = () => {
  const { relevantSpace, isPublic } = useGetRelevantSpaceDetails();
  const { updateSpaceAccessType } = useUpdateSpaceAccessType();

  const handleChangeAccessType = (isPublic: boolean) => {
    if (relevantSpace) {
      updateSpaceAccessType({
        space: relevantSpace,
        isPublic,
      });
    }
  };

  return <AccessTypeForm defaultIsPublic={isPublic} onChange={handleChangeAccessType} />;
};

export default AccessType;
