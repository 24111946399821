import { useTranslation } from "react-i18next";

import { FormElement, Input, Textarea } from "UIKit";

import { FirstStepComponentProps, FirstStepTypeData } from "../../types";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { useSelectLink } from "./hooks/useSelectLink";
import { FirstStepWrapper } from "../firstStepComponents/FirstStepWrapper";
import { convertToLink } from "Utilities";
import { EolasSupportedMiscellaneousType, NewContentType } from "@eolas-medical/core";
import { getFinalStepName } from "../../functions/getFinalStepName";
import {
  handleUpdateWizardDraftStatus,
  useMakePenultimateStepButtonProps,
} from "../../../../hooks/useMakePenultimateStepButtonProps";

const FileDetails = ({
  onClickForward,
  wizardState,
  selectTypeComponent,
}: FirstStepComponentProps) => {
  const { handleSubmit, control } = useSelectLink(wizardState);

  const { t } = useTranslation();

  const submitFunc = ({ isDraft }: { isDraft: boolean }) => {
    handleSubmit((values) => {
      let newState = makeMutableWizardState(wizardState);
      const keywords = values.keywords.split(",").map((str) => str.trim());
      if (!newState.isExistingFileWithNewType) {
        newState.dto.name = values.name;
        newState.dto.keywords = keywords;
        newState.dto.key = convertToLink(values.url);
        newState.type = EolasSupportedMiscellaneousType.LINK;
      } else {
        newState.dto.newType = NewContentType.LINK;
        if (newState.dto.newType === NewContentType.LINK) {
          newState.dto.newProperties.name = values.name;
          newState.dto.newProperties.keywords = keywords;
          newState.dto.newProperties.key = convertToLink(values.url);
        }
      }

      newState = handleUpdateWizardDraftStatus({ state: newState, isDraft });
      onClickForward(newState);
    })();
  };

  const buttonProps = useMakePenultimateStepButtonProps({
    currentWizardState: wizardState,
    handleClickForward: () => submitFunc({ isDraft: false }),
    handleAltClickForward: () => submitFunc({ isDraft: true }),
  });

  return (
    <FirstStepWrapper
      fileId={wizardState.fileId}
      selectTypeComponent={selectTypeComponent}
      buttonRowProps={buttonProps}
    >
      <FormElement required id="name" control={control} label={t("repository_file_name_label")}>
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            placeholder={t("repository_file_name_placeholder")}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="url"
        control={control}
        labelClassName="font-semibold"
        label={t("filePicker_url_label")}
      >
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            data-testid="form-element-name"
            placeholder={t("filePicker_url_placeholder")}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="keywords"
        control={control}
        label={t("repository_file_keywords_label")}
      >
        {(field) => <Textarea {...field} placeholder={t("repository_file_keywords_placeholder")} />}
      </FormElement>
    </FirstStepWrapper>
  );
};

export const selectLinkData: FirstStepTypeData = {
  StepComponent: FileDetails,
  props: { getNextStepName: (_, state) => getFinalStepName(state) },
};
