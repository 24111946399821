import { ArrowRightIcon } from "Assets";
import { EventFormTemplateSummary } from "modules/portfolio/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IconButton, InnerModalWrapper, Loader, ModalBody, Title, theme } from "UIKit";
import useEventIcons from "Pages/MeTab/pages/Portfolio/hooks/useEventIcons";

export interface AddNewEventModalProps {
  events: EventFormTemplateSummary[];
  eventsLoading: boolean;
  url: string;
}

const AddNewEventModal = ({ events, eventsLoading, url }: AddNewEventModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const icons = useEventIcons();

  const handleClickEventType = (name: string) => {
    const eventNameRoute = name.toLowerCase();
    history.push(`${url}/${eventNameRoute}`);
  };

  return (
    <InnerModalWrapper>
      <ModalBody>
        <Title className="flex justify-center mb-6" level={5}>
          {t("addNewSection_modal_title")}
        </Title>
        {eventsLoading ? <Loader /> : null}
        <div
          data-testid="add-new-event-modal"
          className="flex flex-col overflow-y-auto"
          style={{ maxHeight: "30rem" }}
        >
          {events?.map(({ id, eventType, eventTypeLabel }: EventFormTemplateSummary) => {
            const Icon = icons[eventType];
            return (
              <div
                data-testid={`event-type-${eventTypeLabel}`}
                key={id}
                className="flex items-center justify-start border-b border-blue-50 cursor-pointer py-2 hover:bg-grey-50 group relative"
                onClick={() => handleClickEventType(eventType)}
              >
                <div className="rounded-3xl bg-blue-50 p-3">
                  <Icon
                    color="bg-blue-50"
                    fill={theme.colors.blue[500]}
                    className="w-5 h-5 sm:w-6 sm:h-6"
                  />
                </div>
                <p className="text-base font-bold ml-2">{eventTypeLabel}</p>
                <IconButton
                  size="sm"
                  variant="rounded"
                  icon={<ArrowRightIcon />}
                  className={`
                    absolute right-14 group-hover:right-10
                    transition-all duration-200
                    opacity-0 group-hover:opacity-100
                    flex bg-darkblue-500 hover:bg-darkblue-400 text-white
              `}
                />
              </div>
            );
          })}
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
export default AddNewEventModal;
