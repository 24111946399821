import { useState } from "react";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { AddEolasEditorItemModalState } from "../types";
import { ConfigureFileValues } from "UIKit";
import { userStore } from "@eolas-medical/core";

export const useAddEolasEditorItemActions = () => {
  const [wizardState, setWizardState] = useState<AddEolasEditorItemModalState>();

  const user = userStore.userData;

  const { useAddEolasEditorItemHook } = useContentRepositoryContext();

  const {
    addEolasEditorItem,
    addingEolasEditorItem,
    addEolasEditorItemError,
    addEolasEditorItemSuccess,
    addEolasEditorItemProgress,
  } = useAddEolasEditorItemHook;

  const handleAddEolasEditorDetails = (data: ConfigureFileValues) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const handleAddEolasEditorContent = ({
    content,
    isDraft,
  }: {
    content: string;
    isDraft: boolean;
  }) => {
    setWizardState((prevState) => ({
      ...prevState,
      content,
      isDraft,
    }));

    addEolasEditorItem({
      name: wizardState?.name || "",
      description: wizardState?.keywords,
      expiryDate: wizardState?.expiryDate,
      eolasEditorContent: content,
      itemType: "eolas-editor",
      createdBy: user.id || "",
      isDraft,
    });
  };

  return {
    wizardState,
    addEolasEditorItemError,
    addingEolasEditorItem,
    addEolasEditorItemSuccess,
    addEolasEditorItemProgress,
    onAddEolasEditorDetails: handleAddEolasEditorDetails,
    onAddEolasEditorContent: handleAddEolasEditorContent,
  };
};
