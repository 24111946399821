import {
  isDefined,
  ChildReference,
  eolasLogger,
  sectionStore,
  Space,
  Organisation,
  fileStore,
  getFilesForLocalSearch,
  isEolasFile,
  EolasFile,
  hasProp,
  isEolasShadowCopyFile,
  LegacyContentRepositoryTypes,
} from "@eolas-medical/core";
import { isLegacyMyMedicalAccount } from "Pages/Spaces/helpers";
import { isDev, sortByName } from "Utilities/helpers";
import { mapToChildReference } from "modules/mainSections/helpers";
import { isMainSection } from "modules/mainSections/typeguards";
import { MainSection } from "modules/mainSections/types";
import { useSpacesContext } from "modules/spaces";
import useOrganisation from "modules/spaces/data/useOrganisation";
import { useSpaceOrgMainSections } from "modules/spaces/data/useSpaceOrgMainSections";
import { useCallback, useState, useMemo } from "react";
import { isChildReference } from "shared/functions/typeguards";

type ChildrenType = "files" | "sections" | "mainSections" | "none";
type ContentRoot = "organisation" | "space";
type LeafType = "files" | "sectionWithFiles";
type OtherSectionsToInclude = "legacyContentRepos" | "all" | "none";

// eslint-disable-next-line @typescript-eslint/ban-types
type BaseListItem<C extends ChildrenType, P extends {}> = {
  id: string;
  childrenType: C;
  hasParent: boolean;
  hasChildren: boolean;
  isDisabled: boolean;
} & P;

type SectionWithFilesItem = BaseListItem<
  "files",
  { item: ChildReference & { isGCR: boolean }; hasChildren: boolean }
>;
type SectionWithSections = BaseListItem<
  "sections",
  { item: ChildReference & { isGCR: boolean }; hasChildren: true }
>;
type ContentRootItem = BaseListItem<
  "mainSections",
  { rootType: ContentRoot; hasParent: false; hasChildren: true; item: { title: string } }
>;
type FileItem = BaseListItem<"none", { item: EolasFile; hasChildren: false }>;

export type ListItem = SectionWithFilesItem | SectionWithSections | ContentRootItem | FileItem;

type ListData = {
  handleNavigateUp: () => void;
  handleNavigateDown: (item: ListItem) => void;
  items: ListItem[];
  parentItem: ListItem | null;
  // TODO: It would be nice if we could infer the type of searchData based on the leafType
  searchData: (SectionWithFilesItem | FileItem)[];
};

type Props = {
  leafType: LeafType;
  shouldShowOrgItems: boolean;
  shouldShowSpaceItems: boolean;
  disabledIds?: string[];
  shouldFilterShadowCopies?: boolean;
  otherSectionsToInclude?: OtherSectionsToInclude;
  customFilterFn?: (item: ListItem) => boolean;
};

/**
 * This hook will return a list of items that can be used to navigate through the local sections and files.
 * It is commonly used with SelectTileList component.
 * You can specify the leafType to determine if the items should be selectable or not.
 * If a child of the chosen leafType will not be returned, i.e. if the leafType is "sectionWithFiles" this hook will not return any files, only sections.
 */
export const useLocalChildrenList = ({
  leafType,
  disabledIds = [],
  shouldShowOrgItems = false,
  shouldShowSpaceItems = false,
  shouldFilterShadowCopies = false,
  otherSectionsToInclude = "none",
}: Props): ListData => {
  const { selectedSpace } = useSpacesContext();
  const { organisation } = useOrganisation();
  const { activatedMainSections: spaceSections } = useSpaceOrgMainSections({ activeTab: "spaces" });
  const { activatedMainSections: orgSections } = useSpaceOrgMainSections({
    activeTab: "organisation",
  });

  const isOrgActive = sectionStore.organisationActiveHospitalTab;

  const [items, setItems] = useState<ListItem[] | null>(null);
  const [parentItem, setParentItem] = useState<ListItem | null>(null);

  const isDataReady = Boolean(selectedSpace && organisation);

  if (isDataReady && !items) {
    setItems(
      getInitialItems({
        selectedSpace,
        orgSections,
        spaceSections,
        isOrgActive,
        shouldShowOrgItems,
        shouldShowSpaceItems,
        organisation,
        disabledIds,
        leafType,
        otherSectionsToInclude,
      }),
    );
  }

  const searchData = useMemo(() => {
    const allData: (SectionWithFilesItem | FileItem)[] = [];

    const itemsMap =
      leafType === "files"
        ? getFilesForLocalSearch({ shouldReturnOriginalsOnly: shouldFilterShadowCopies })
        : sectionStore.sectionsMap;

    Object.values(itemsMap).forEach((item) => {
      const refOrFile = isEolasFile(item) ? item : sectionStore.getChildReferenceOfSection(item.id);

      if (shouldShowOrgItems && isOrgActive && organisation) {
        if (item.ownerID === organisation.id && refOrFile) {
          const node = createSectionNode({
            item: refOrFile,
            disabledIds,
            leafType,
            otherSectionsToInclude,
          });

          if (node?.hasChildren === false) {
            allData.push(node);
          }
        }
      }

      if (shouldShowSpaceItems && selectedSpace) {
        if (item.ownerID === selectedSpace.id && refOrFile) {
          const node = createSectionNode({
            item: refOrFile,
            disabledIds,
            leafType,
            otherSectionsToInclude,
          });
          if (node?.hasChildren === false) {
            allData.push(node);
          }
        }
      }
    });
    return allData;
  }, [
    isOrgActive,
    shouldShowOrgItems,
    shouldShowSpaceItems,
    organisation,
    selectedSpace,
    disabledIds,
    leafType,
    shouldFilterShadowCopies,
    otherSectionsToInclude,
  ]);

  const handleNavigateDown = useCallback(
    (item: ListItem) => {
      let children: ChildReference[] | MainSection[] | EolasFile[] = [];

      if (item.childrenType === "mainSections") {
        if (item.rootType === "organisation") {
          children = orgSections;
        } else if (item.rootType === "space") {
          children = spaceSections;
        }
      } else {
        const childrenOrder = sectionStore.getChildrenOrder(item.id);
        if (item.childrenType === "files") {
          children = fileStore
            .getFiles(childrenOrder)
            .filter((file) => (shouldFilterShadowCopies ? !isEolasShadowCopyFile(file) : true));
        } else {
          children = childrenOrder;
        }
      }

      const newItems = children
        .map((child) =>
          createSectionNode({ item: child, disabledIds, leafType, otherSectionsToInclude }),
        )
        .filter(isDefined);

      setParentItem(item);
      setItems(newItems);
    },
    [
      orgSections,
      spaceSections,
      disabledIds,
      leafType,
      shouldFilterShadowCopies,
      otherSectionsToInclude,
    ],
  );

  const handleNavigateUp = useCallback(() => {
    // If we are at the root level, do nothing
    if (parentItem === null) {
      return;
    }

    if (parentItem.id === organisation?.id || parentItem.id === selectedSpace?.id) {
      if (shouldShowOrgItems && shouldShowSpaceItems) {
        setParentItem(null);
        return setItems(
          getInitialItems({
            selectedSpace,
            orgSections,
            spaceSections,
            isOrgActive,
            shouldShowOrgItems,
            shouldShowSpaceItems,
            organisation,
            disabledIds,
            leafType,
            otherSectionsToInclude,
          }),
        );
      }

      // This should never happen
      return;
    }

    const parent = sectionStore.getSection(parentItem.id);

    if (parent.id === parent.mainSectionID) {
      if (isOrgActive && shouldShowOrgItems) {
        if (parent.ownerID === organisation?.id) {
          setParentItem(createContentRootNode("organisation", organisation));
          return setItems(
            orgSections
              .map((s) =>
                createSectionNode({ item: s, disabledIds, leafType, otherSectionsToInclude }),
              )
              .filter(isDefined),
          );
        }

        if (parent.ownerID === selectedSpace?.id) {
          setParentItem(createContentRootNode("space", selectedSpace));
          return setItems(
            spaceSections
              .map((s) =>
                createSectionNode({ item: s, disabledIds, leafType, otherSectionsToInclude }),
              )
              .filter(isDefined),
          );
        }
      }
      setParentItem(null);
      return setItems(
        getInitialItems({
          selectedSpace,
          orgSections,
          spaceSections,
          isOrgActive,
          shouldShowOrgItems,
          shouldShowSpaceItems,
          organisation,
          disabledIds,
          leafType,
          otherSectionsToInclude,
        }),
      );
    }

    const grandParent = parent.parentID ? sectionStore.getSection(parent.parentID) : null;
    const childRefOfGrandParent = grandParent
      ? sectionStore.getChildReferenceOfSection(grandParent.id)
      : null;
    if (!grandParent || !childRefOfGrandParent) {
      return;
    }

    const parents = sectionStore.getChildrenOrder(grandParent.id);
    const newItems = parents
      .map((p) => createSectionNode({ item: p, disabledIds, leafType, otherSectionsToInclude }))
      .filter(isDefined);
    setParentItem(
      createSectionNode({
        item: childRefOfGrandParent,
        disabledIds,
        leafType,
        otherSectionsToInclude,
      }),
    );
    setItems(newItems);
  }, [
    organisation,
    selectedSpace,
    orgSections,
    spaceSections,
    isOrgActive,
    shouldShowOrgItems,
    shouldShowSpaceItems,
    parentItem,
    disabledIds,
    leafType,
    otherSectionsToInclude,
  ]);

  const sortedItems = useMemo(
    () => (items ? items.sort((a, b) => sortByName(a.item, b.item)) : []),
    [items],
  );

  return {
    handleNavigateUp,
    handleNavigateDown,
    items: sortedItems,
    parentItem,
    searchData,
  };
};

const getInitialItems = ({
  selectedSpace,
  orgSections,
  spaceSections,
  isOrgActive,
  shouldShowOrgItems,
  shouldShowSpaceItems,
  organisation,
  leafType,
  disabledIds,
  otherSectionsToInclude,
}: {
  selectedSpace: Space | undefined;
  orgSections: MainSection[];
  spaceSections: MainSection[];
  isOrgActive: boolean;
  shouldShowOrgItems: boolean;
  shouldShowSpaceItems: boolean;
  organisation: Organisation | undefined;
  leafType: LeafType;
  otherSectionsToInclude: OtherSectionsToInclude;
  disabledIds: string[];
}): ListItem[] => {
  const isMyMed = isLegacyMyMedicalAccount();
  if (!selectedSpace) {
    return [];
  }

  if (isOrgActive && shouldShowOrgItems && shouldShowSpaceItems && organisation && !isMyMed) {
    const orgRootSection = createContentRootNode("organisation", organisation);
    const spaceRootSection = createContentRootNode("space", selectedSpace);

    return [orgRootSection, spaceRootSection];
  }

  const orgMainSectionList = orgSections
    .map((section) =>
      createSectionNode({
        item: section,
        hasParent: false,
        disabledIds,
        leafType,
        otherSectionsToInclude,
      }),
    )
    .filter(isDefined);

  const spaceMainSectionList = spaceSections
    .map((section) =>
      createSectionNode({
        item: section,
        hasParent: false,
        disabledIds,
        leafType,
        otherSectionsToInclude,
      }),
    )
    .filter(isDefined);

  if (isOrgActive && shouldShowOrgItems) {
    return orgMainSectionList;
  }

  return spaceMainSectionList;
};

export const createSectionNode = ({
  item,
  leafType,
  hasParent = true,
  disabledIds,
  otherSectionsToInclude,
}: {
  item: ChildReference | MainSection | EolasFile;
  leafType: LeafType;
  disabledIds: string[];
  otherSectionsToInclude: OtherSectionsToInclude;
  hasParent?: boolean;
}): ListItem | null => {
  const section = sectionStore.getSection(item.id);

  if (isMainSection(item)) {
    const sectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(item.id);
    const mainSectionChildRef = mapToChildReference(item);

    if (sectionIdentity !== "genericContentRepository" && otherSectionsToInclude === "none") {
      return null;
    }

    if (
      otherSectionsToInclude === "legacyContentRepos" &&
      mainSectionChildRef.icon &&
      !LegacyContentRepositoryTypes.includes(mainSectionChildRef.icon) &&
      sectionIdentity !== "genericContentRepository"
    ) {
      return null;
    }
  }

  if (!hasProp(section, "id") && !isEolasFile(item)) {
    if (isDev()) {
      eolasLogger.warn(
        new Error("Required data is missing for node creation, this node will be skipped"),
        { section: JSON.stringify(section), file: JSON.stringify(item) },
        false,
      );
    }
    return null;
  }

  const isDisabled = disabledIds?.includes(item.id) ?? false;

  if (!isChildReference(item) && isEolasFile(item)) {
    if (leafType === "files") {
      return {
        id: item.id,
        hasChildren: false,
        item: item,
        childrenType: "none",
        hasParent,
        isDisabled,
      };
    }

    // If the leafType is not "files" we should not return files
    if (leafType === "sectionWithFiles") {
      return null;
    }

    if (isDev()) {
      eolasLogger.error(
        new Error(
          "Tried to create EolasFile node with unexpected leafType - This should not happen!",
        ),
        { leafType: leafType },
      );
    }
    return null;
  }

  const sectionChildRef = isMainSection(item) ? mapToChildReference(item) : item;
  const sectionChildren = sectionStore.getChildrenOrder(item.id);
  const childType = sectionChildren.length > 0 ? sectionChildren[0].type : section.childrenType;

  if (!childType) {
    if (isDev()) {
      eolasLogger.warn(
        new Error(
          "Could not determine child type for section node creation, this section will be skipped",
        ),
        { sectionChildren, section: JSON.parse(JSON.stringify(section)) },
        false,
      );
    }
    return null;
  }

  const shouldSectionBeLeaf =
    leafType === "sectionWithFiles" && childType !== "section" && childType !== "subSection";

  const childrenType = childType !== "section" && childType !== "subSection" ? "files" : "sections";

  const sectionItem = {
    ...sectionChildRef,
    isGCR: isSectionGCR(sectionChildRef),
  };

  if (childrenType === "sections") {
    return {
      id: sectionChildRef.id,
      hasChildren: true,
      item: sectionItem,
      childrenType: childrenType,
      hasParent,
      isDisabled,
    };
  }

  return {
    id: sectionChildRef.id,
    hasChildren: shouldSectionBeLeaf,
    item: sectionItem,
    childrenType: childrenType,
    hasParent,
    isDisabled,
  };
};

const createContentRootNode = <C extends ContentRoot>(
  rootType: C,
  data: C extends "organisation" ? Organisation : Space,
): ContentRootItem => {
  if (rootType === "organisation") {
    return {
      id: data.id,
      hasChildren: true,
      item: { title: data.name },
      rootType: "organisation",
      childrenType: "mainSections",
      hasParent: false,
      isDisabled: false,
    };
  }

  return {
    id: data.id,
    hasChildren: true,
    item: { title: data.name },
    rootType: "space",
    childrenType: "mainSections",
    hasParent: false,
    isDisabled: false,
  };
};

const isSectionGCR = (childRef: ChildReference): boolean => {
  if (childRef.identity === "genericContentRepository") {
    return true;
  }

  const section = sectionStore.getSection(childRef.id);

  if (!section?.id) {
    return false;
  }

  if (section.identity === "genericContentRepository") {
    return true;
  }

  const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
    section.mainSectionID,
  );

  return mainSectionIdentity === "genericContentRepository";
};
