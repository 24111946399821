import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";

type MutationParams = {
  contentIds: string[];
  mainSectionId: string;
};

const mutationFn = async ({ mainSectionId, contentIds }: MutationParams) => {
  if (contentIds.length <= 100) {
    return contentClient.bulkDeleteContentItems({
      mainSectionId: mainSectionId,
      contentIds: contentIds,
    });
  }

  const chunks = [];
  const chunkSize = 100;

  for (let i = 0; i < contentIds.length; i += chunkSize) {
    chunks.push(contentIds.slice(i, i + chunkSize));
  }

  return Promise.all(
    chunks.map((chunk) =>
      contentClient.bulkDeleteContentItems({
        mainSectionId: mainSectionId,
        contentIds: chunk,
      }),
    ),
  );
};

export const useBulkDeleteItems = ({
  mainSectionId,
  onSettled,
}: {
  mainSectionId: string;
  onSettled?: () => void;
}) => {
  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: (ids: string[]) => mutationFn({ mainSectionId, contentIds: ids }),
    mutationKey: contentKeys.bulkDelete(),
    onSettled: onSettled,
  });

  return {
    bulkDeleteItems: mutateAsync,
    isLoading,
    isError,
    error,
  };
};
