import { useMutation } from "@tanstack/react-query";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { loginWithToken, logout } from "Pages/Authentication/auth.actions";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { LDFlagNames } from "Utilities/types";
import useUserDetails from "Hooks/useUserDetails";

export const useAutoLogin = () => {
  const { onSelectSpace } = useSelectSpaceActions();
  const { isSharedAccount: isCurrentAccountDataSharedAccount } = useUserDetails();

  const { flags } = useLaunchDarkly();

  const {
    mutate: autoLogin,
    isError,
    isLoading,
  } = useMutation({
    mutationKey: ["autoLogin"],
    mutationFn: async (token: string) => {
      const { data: loginData } = await loginTokenService.loginWithToken({
        token,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });

      await logout();
      await loginWithToken(loginData);
      await onSelectSpace(loginData.appID);
    },
  });

  return { autoLogin, isError, isLoading, isCurrentAccountDataSharedAccount };
};
