import { useQueryParams } from "Hooks/useQueryParams";
import { isAndroid, isIOS } from "Utilities/helpers";

const useRedirectToStore = () => {
  const queryParams = useQueryParams();

  const redirectToStore = queryParams.get("redirectToStore");
  if (redirectToStore === "true") {
    if (isAndroid()) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.eolasmedical.eolas&pcampaignid=web_share";
    } else if (isIOS()) {
      window.location.href = "https://apps.apple.com/app/id6447954935";
    }
  }
};

export default useRedirectToStore;
