import { getEventFormTemplates } from "../client/portfolio.client";
import { QueryEnvironment, useEolasQuery } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { useMemo } from "react";
import { EventFormTemplateSummary, EventType, eventTypeLabels } from "../types";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

interface UseEventFormTemplates {
  templatesSummary: EventFormTemplateSummary[];
  templatesSummaryLoading: boolean;
}

const useEventFormTemplates = (): UseEventFormTemplates => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getEventFormTemplates(),
    queryKey: portfolioKeys.templatesSummary(),
    environment: QueryEnvironment.loggedIn,
    options: {
      staleTime: Infinity,
    },
  });

  const { flags } = useLaunchDarkly();

  // TODO: remove this validation when all event types are handled
  const validEventTypes: EventType[] = useMemo(
    () => [
      EventType.TEACHING_DELIVERY,
      EventType.REFLECTION,
      EventType.MULTI_SOURCE_FEEDBACK,
      EventType.CLINICAL_EVENT,
      EventType.PROCEDURAL_SKILL,
      EventType.ULTRASOUND_LOG,
      EventType.AUDIT_QI_PROJECT,
      EventType.CASE_DISCUSSION,
      EventType.PDP,
      EventType.MEETING,
      EventType.ULTRASOUND_VASCULAR_ACCESS,
      EventType.ULTRASOUND_CARDIAC,
      EventType.ULTRASOUND_DVT,
      EventType.ULTRASOUND_THORACIC,
      EventType.ULTRASOUND_ABDOMINAL_RENAL,
      EventType.ULTRASOUND_AAA,
      EventType.ULTRASOUND_ECHO_IN_LIFE_SUPPORT,
      EventType.ULTRASOUND_FAST,
      EventType.ACUTE_CARE_ASSESSMENT_TOOL,
      EventType.DIRECT_OBSERVED_PROCEDURAL_SKILL,
      EventType.ADVANCED_PRACTICE_ANNUAL_APPRAISAL,
      EventType.EXTENDED_SUPERVISED_LEARNING_EVENT,
    ],
    [],
  );

  const belfastTrustEventTypes: EventType[] = useMemo(
    () => [
      EventType.ACUTE_CARE_ASSESSMENT_TOOL,
      EventType.DIRECT_OBSERVED_PROCEDURAL_SKILL,
      EventType.ADVANCED_PRACTICE_ANNUAL_APPRAISAL,
      EventType.EXTENDED_SUPERVISED_LEARNING_EVENT,
    ],
    [],
  );

  const templatesSummary = useMemo(() => {
    function isHiddenByFeatureFlag(eventType: EventType) {
      return (
        belfastTrustEventTypes.includes(eventType) &&
        flags[LDFlagNames.BELFAST_TRUST_PORTFOLIO_EVENTS] === false
      );
    }

    return data
      ?.filter(
        (ts: EventFormTemplateSummary) =>
          validEventTypes.indexOf(ts.eventType) > -1 && !isHiddenByFeatureFlag(ts.eventType),
      )
      .map((template: EventFormTemplateSummary) => {
        return {
          ...template,
          eventTypeLabel: eventTypeLabels[template.eventType],
        };
      })
      .sort((a: EventFormTemplateSummary, b: EventFormTemplateSummary) =>
        a.eventTypeLabel.localeCompare(b.eventTypeLabel),
      );
  }, [data, validEventTypes, belfastTrustEventTypes, flags]);

  return {
    templatesSummaryLoading: isLoading,
    templatesSummary,
  };
};

export default useEventFormTemplates;
