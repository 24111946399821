import { KnowledgeContentAnalyticsCallbacks } from "Pages/Knowledge/pages/KnowledgeContent";

export const analyticsCallbacks: KnowledgeContentAnalyticsCallbacks = {
  onLoadContentItem: () => {
    //
  },
  onClickContentSection: () => {
    //
  },
  onSearchContentItem: () => {
    //
  },
  onSearchContentSection() {
    //
  },
};
