import React from "react";
import { Loader, Text } from "UIKit";
import { twMerge } from "tailwind-merge";
import { UserIcon } from "Assets";
import { getInitials } from "./helpers";

interface AvatarProps {
  label?: string;
  name?: string;
  email?: string;
  isLoading?: boolean;
  showInitials?: boolean;
  className?: string;
  imageUrl?: string;
  rightComponent?: React.ReactNode;
}

function Avatar({
  label,
  isLoading,
  showInitials = false,
  className = "",
  imageUrl,
  name,
  email,
  rightComponent,
}: AvatarProps) {
  if (isLoading) {
    return <Loader size={24} className="bg-transparent" />;
  }

  if (imageUrl && !rightComponent) {
    return (
      <img
        src={imageUrl}
        srcSet={imageUrl}
        alt=""
        className="h-full w-full object-cover rounded-2xl"
      />
    );
  }

  if (!showInitials && label) {
    return (
      <div data-testid="avatar" className={`flex items-center ${label ? "space-x-4" : ""}`}>
        <UserIcon className={"h-8 w-8"} />
        {label ? <Text level={1}>{label}</Text> : null}
      </div>
    );
  }

  if (showInitials && !imageUrl) {
    return (
      <div
        data-testid="avatar"
        className={twMerge(
          "w-full h-full flex items-center justify-center bg-primary-focus text-2xl rounded-lg text-blue-400 font-bold",
          className,
        )}
      >
        {showInitials && getInitials(label)}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-12 items-start gap-4">
      <div className="col-span-2 flex items-center justify-center">
        {imageUrl ? (
          <img src={imageUrl} alt={name} className="h-10 w-10 sm:h-11 sm:w-11 rounded-md" />
        ) : (
          <div
            className={`w-10 h-10 flex items-center justify-center rounded-md text-blue-400 bg-primary-focus ${
              email ? "text-xl" : "text-2xl"
            }`}
          >
            {getInitials(name)}
          </div>
        )}
      </div>
      <div className="col-start-3 flex flex-col">
        <Text level={1}>{name}</Text>
        <div className="text-grey-700 text-2xs font-semibold">{email}</div>
      </div>
      {rightComponent ? (
        <div className="col-start-10 flex items-start gap-4">{rightComponent}</div>
      ) : null}
    </div>
  );
}

export default Avatar;
