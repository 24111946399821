import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { History } from "history";

import { GeneralCategory, NiceSummaryItem } from "@eolas-medical/core";
import { useGetNiceGuidelineData } from "modules/niceGuidelines/data/useGetNiceGuidelineData";
import { makeNiceGuidelinesRoute } from "../routes/makeNiceGuidelinesRoute";
import { makeAllGuidelinesCustomCategory } from "modules/niceGuidelines/helpers";
import { isGeneralCategory } from "modules/niceGuidelines/typeguards";
import { BaseAppRoute } from "Utilities/types";
import { CategoryReturn, SummaryReturn } from "./useNiceGuidelinesList";
import { fuseSearch } from "shared/hooks/useLocalSearch/fuseSearch";

type BaseReturn = {
  handleClickTile: (item: GeneralCategory | NiceSummaryItem) => void;
  onClickSearch: () => void;
  onClearSearch: () => void;
  onChangeText: (newText: string) => void;
  searchText: string;
  isLoading: boolean;
};

export const useGetNiceGuidelinesHome = ({
  history,
}: {
  history: History<unknown>;
}): BaseReturn & (CategoryReturn | SummaryReturn) => {
  const { data, isLoading } = useGetNiceGuidelineData();
  const [searchText, setSearchText] = useState("");
  const [selectedSearchText, setSelectedSearchText] = useState("");

  const { pathname: currentPath } = useLocation();

  const handleClickTile = useCallback(
    (item: GeneralCategory | NiceSummaryItem) => {
      if (isGeneralCategory(item)) {
        history.push({
          pathname: makeNiceGuidelinesRoute({ currentPath, item }),
          state: { item },
        });
      } else {
        history.push({ pathname: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${item.id}` });
      }
    },
    [history, currentPath],
  );

  const onClickSearch = useCallback(() => {
    setSelectedSearchText(searchText);
  }, [searchText]);

  const onClearSearch = useCallback(() => {
    setSelectedSearchText("");
    setSearchText("");
  }, []);

  const onChangeText = useCallback((newText: string) => {
    setSearchText(newText);
  }, []);

  const constructedData: CategoryReturn | SummaryReturn = useMemo(() => {
    if (selectedSearchText) {
      const { results } = fuseSearch(selectedSearchText, data?.summaries ?? [], [
        "title",
        "keywords",
      ]);
      return { type: "summaryItems", itemsToDisplay: results };
    }
    if (!data) {
      return { type: "categories", itemsToDisplay: [] };
    }
    const customCategory = makeAllGuidelinesCustomCategory(data);
    const customCategories = [customCategory, ...data.categories];
    return { type: "categories", itemsToDisplay: customCategories };
  }, [selectedSearchText, data]);

  return {
    isLoading,
    onChangeText,
    onClearSearch,
    onClickSearch,
    handleClickTile,
    searchText,
    ...constructedData,
  };
};
