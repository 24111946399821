import {
  CommunityLevelSection,
  KnowledgeContentDomains,
  SponsoredSlot,
  isUserInEmergencySpecialty,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import { MainSection } from "../types";
import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { mapToMainSection } from "../helpers";
import { useGetClinicalQuestionsSectionId } from "Pages/Knowledge/pages/ClinicalQuestions/hooks/useGetClinicalQuestionsSectionId";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useGetUserSponsoredSlot } from "modules/sponsoredSlots/data/useGetUserSponsoredSlot";

export type KnowledgeMainListItem = MainSection | SponsoredSlot;

interface UseKnowledgeSections {
  knowledgeSections: KnowledgeMainListItem[];
}

const useKnowledgeSections = (): UseKnowledgeSections => {
  const { isMainSectionEnabled } = useMainSectionFlags();
  const { flags } = useLaunchDarkly();
  const { getIconUrl } = useGetIconUrl();
  const { specialty } = userStore.userData;

  const { sectionId: clinicalQuestionsSectionId } = useGetClinicalQuestionsSectionId();
  const { userSponsoredSlot } = useGetUserSponsoredSlot();

  const isKnowledgeAdminEnabled = flags[LDFlagNames.KNOWLEDGE_ADMIN_ENABLED];

  const knowledgeSections: KnowledgeMainListItem[] = sectionStore
    .getMainSectionList("community")
    .map((item) => {
      const section = sectionStore.getSection(item.id);
      return { ...item, identity: section.identity };
    })
    .filter(({ id }) => {
      const section = sectionStore.getSection(id);

      if (!section) return false;

      if (section.identity === "genericContentRepository") {
        return true;
      }

      switch (section.type) {
        case CommunityLevelSection.clinicalQuestions:
          if (!clinicalQuestionsSectionId) {
            return false;
          }
          break;
        case CommunityLevelSection.sponsoredSlots:
          if (!isKnowledgeAdminEnabled) {
            return Boolean(userSponsoredSlot);
          }
          break;
        case CommunityLevelSection.wikiEm:
          if (!isUserInEmergencySpecialty(specialty)) {
            return false;
          }
          break;
      }

      return isMainSectionEnabled(section.type);
    })
    .map(mapToMainSection)
    .map((section) => {
      const mainSection = {
        ...section,
        iconUrl: section.iconName ? getIconUrl(section.iconName) : undefined,
      };

      switch (section.iconName) {
        case CommunityLevelSection.clinicalQuestions: {
          if (clinicalQuestionsSectionId) {
            mainSection.routeId = `${CommunityLevelSection.clinicalQuestions}/${encodeURIComponent(
              clinicalQuestionsSectionId,
            )}`;
          }
          return mainSection;
        }
        case KnowledgeContentDomains.sponsoredSlots: {
          if (userSponsoredSlot && !isKnowledgeAdminEnabled) {
            return userSponsoredSlot;
          }
        }
      }
      return mainSection;
    });

  return {
    knowledgeSections,
  };
};

export default useKnowledgeSections;
