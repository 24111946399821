import { useTranslation } from "react-i18next";

import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { NormalStepComponentProps, NormalStepData } from "../../types";
import { FormError, LottieWithHeader, ProgressBar, Text } from "UIKit";
import { InfoIconMonocolored, success, DraftIcon } from "Assets";
import { useConfirmAndUpload } from "./hooks/useConfirmAndUpload";
import { getLastMainWizardStepName } from "../../functions/getLastMainWizardStepName";

const ConfirmAndUpload = ({
  wizardState,
  onClickForward,
  onClickBack,
  sectionId,
}: NormalStepComponentProps) => {
  const { t } = useTranslation();

  const { isLoading, isError, isSuccess, mutate, uploadProgress } = useConfirmAndUpload(
    wizardState,
    sectionId,
  );

  const isInConfirmation = !isLoading && !isError && !isSuccess;

  let title = t("repository_confirm_upload_title");

  if (isError) {
    title = t("repository_error");
  } else if (isLoading) {
    title = t("repository_upload_title");
  } else if (isSuccess) {
    title = t("repository_upload_success");
  }

  let confirmationDesc = "";

  if (isInConfirmation) {
    if (!wizardState.nextDraftStatus) {
      confirmationDesc = t("content_item_publish_desc");
    } else {
      confirmationDesc = wizardState.fileId
        ? t("content_item_draft_desc")
        : t("content_item_unpublished_draft_desc");
    }
  }

  return (
    <EolasWizardModalWrapper
      header={{ title }}
      buttonRowProps={{
        previousButton: isInConfirmation ? { onClick: onClickBack } : undefined,
        altForwardButton:
          isInConfirmation && !isError && wizardState.nextDraftStatus
            ? {
                onClick: () => mutate(),
                text: wizardState.existingDraftStatus
                  ? t(t("content_item_update_draft"))
                  : t("content_item_new_draft"),
                color:
                  !wizardState.fileId || wizardState.existingDraftStatus === "unpublished"
                    ? "grey"
                    : undefined,
              }
            : undefined,
        forwardButton:
          isInConfirmation && !isError && !wizardState.nextDraftStatus
            ? {
                onClick: () => mutate(),
                color: "green",
                text: t("content_item_publish"),
              }
            : undefined,
      }}
    >
      {isInConfirmation ? (
        <div className="flex flex-col items-center justify-center py-5">
          {wizardState.nextDraftStatus === "parallel" ||
          wizardState.existingDraftStatus === "parallel" ? (
            <DraftIcon className="h-50 w-50 mb-10" />
          ) : (
            <InfoIconMonocolored className="h-20 w-20 mb-20" />
          )}
          <Text level={1} className="text-center mb-4 whitespace-pre-wrap">
            {confirmationDesc}
          </Text>
        </div>
      ) : (
        <div>
          {isLoading ? <ProgressBar progress={uploadProgress} /> : null}

          {isError && !isLoading ? <FormError error={t("repository_upload_error")} /> : null}

          {isSuccess ? (
            <LottieWithHeader
              text=""
              animation={success}
              onComplete={() => onClickForward(null)}
              data-testid="success-animation"
            />
          ) : null}
        </div>
      )}
    </EolasWizardModalWrapper>
  );
};

export const confirmAndUploadData: NormalStepData = {
  StepComponent: ConfirmAndUpload,
  props: {
    getNextStepName: (name) => name,
    getPreviousStepName: (_, state) => {
      if (state.isExistingFileWithNewType && state.dto.newProperties.summaryOfChanges) {
        return "summaryOfChanges";
      }
      if (state.fileId && !state.isExistingFileWithNewType && state.dto.summaryOfChanges) {
        return "summaryOfChanges";
      }

      return getLastMainWizardStepName(state);
    },
  },
};
