import {
  FormElement,
  PortfolioNativeComponent,
  PortfolioComplexComponent,
} from "modules/portfolio/types";
import PortfolioReadOnlyPrimitive from "./components/PortfolioReadOnlyPrimitive/PortfolioReadOnlyPrimitive";
import PortfolioReadOnlyAttachments from "./components/PortfolioReadOnlyAttachments/PortfolioReadOnlyAttachments";

const readOnlyComponents: {
  [type: string]: ({ element }: PortfolioReadOnlyElementProps) => JSX.Element;
} = {
  [PortfolioNativeComponent.DATE_PICKER]: PortfolioReadOnlyPrimitive,
  [PortfolioNativeComponent.NUMBER_FEEDBACK]: PortfolioReadOnlyPrimitive,
  [PortfolioNativeComponent.TEXT_INPUT]: PortfolioReadOnlyPrimitive,
  [PortfolioNativeComponent.BEST_ANSWER_SELECTOR]: PortfolioReadOnlyPrimitive,
  [PortfolioComplexComponent.ATTACH_BUTTON]: PortfolioReadOnlyAttachments,
};
interface PortfolioReadOnlyElementProps {
  element: FormElement;
}

const PortfolioReadOnlyElement = ({ element, ...rest }: PortfolioReadOnlyElementProps) => {
  if (!element.componentConfig.type) return null;

  const Component = readOnlyComponents[element.componentConfig.type];

  if (!Component) return null;

  return (
    <div className="justify-center justify-self-center mx-auto">
      <Component element={element} {...rest} />
    </div>
  );
};

export default PortfolioReadOnlyElement;
