import {
  Wizard,
  useWizard,
  Step,
  useUploadProgressAndSuccessStep,
  FormItem,
  useFormStep,
} from "UIKit";
import { useTranslation } from "react-i18next";
import { useImagePickerStep } from "UIKit/Wizard/templates/ImagePickerStep";
import { useFlashcardCreatorStep } from "UIKit/Wizard/templates/FlashcardCreatorStep";
import { useEditContentItemActions } from "./hooks/useEditFlashcardItemActions";
import useFormStepConfig, {
  FlashcardFormStep,
} from "../AddFlashcardItemWizard/hooks/useFormStepConfig";
import {
  FlashCardContent,
  FlashCardHeroImage,
  isCopilotEnrichedFlashcardMetadata,
  specialtyOptions,
} from "@eolas-medical/core";
import { useRef } from "react";
import { useSummaryOfChangesStep } from "UIKit/Wizard/templates/SummaryOfChangesStep/useSummaryOfChangesStep";

interface EditFlashcardModalProps {
  flashcard: FlashCardContent;
  hasSpecialties?: boolean;
  hasCopilotData?: boolean;
  onClose: () => void;
}

export type EditFlashcardItemWizardStep =
  | "flashcard-form"
  | "flashcard-creator"
  | "flashcard-hero-image"
  | "summary-of-changes"
  | "upload-progress";

const EditFlashcardModal = ({
  flashcard,
  hasSpecialties = false,
  hasCopilotData,
  onClose,
}: EditFlashcardModalProps) => {
  const { t } = useTranslation();
  const { wizardControl } = useWizard<EditFlashcardItemWizardStep>();
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const {
    wizardState,
    onEditFlashcardDetails,
    onEditFlashcardContent,
    onSubmitFlashcardImage,
    onUpdateFlashcardWithSummaryOfChanges,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
    shouldAllowBlankHeroImageSelection,
  } = useEditContentItemActions({
    flashcard,
    onClose,
  });

  const { canAddSummaryOfChanges, step: summaryOfChangesStep } = useSummaryOfChangesStep({
    onSubmit: onUpdateFlashcardWithSummaryOfChanges,
    title: t("repository_edit_item_summary_of_changes"),
    backLabel: t("general_back"),
    nextLabel: t("general_done"),
  });

  const formItems: FormItem<FlashcardFormStep>[] = useFormStepConfig({
    hasSpecialties,
    hasCopilotData,
  });

  let documentIdentifiers: string[] | null = null;
  let queriesForHit: string[] | null = null;

  if (flashcard.metadata && isCopilotEnrichedFlashcardMetadata(flashcard.metadata)) {
    ({ documentIdentifiers, queriesForHit } = flashcard.metadata);
  }

  const flashcardForm = useFormStep<"flashcard-form", FlashcardFormStep>({
    stepName: "flashcard-form",
    title: t("flashcards_edit_page_title"),
    formItems,
    backLabel: t("general_cancel"),
    defaultValues: {
      title: flashcard.name,
      description: flashcard.description || undefined,
      specialty: specialtyOptions.filter(
        (s) => flashcard.specialty?.split(",").some((sp) => s.value === sp),
      ),
      documentIdentifiers: documentIdentifiers?.map((item) => ({ value: item, label: item })),
      queriesForHit: queriesForHit?.map((item) => ({ value: item, label: item })),
    },
    onSubmit: onEditFlashcardDetails,
  });

  const flashcardCreator = useFlashcardCreatorStep<"flashcard-creator">({
    pageTitle: t("flashcards_page_title"),
    editorContent: wizardState.content ? wizardState.content : undefined,
    onSubmit: onEditFlashcardContent,
    contentRepositoryId: flashcard.mainSectionId,
    flashcardTitle: flashcard.name ?? wizardState?.title ?? "",
    flashcardDescription: flashcard.description ?? wizardState?.description ?? "",
    currentItemId: flashcard.id,
  });

  const flashcardHeroImage = useImagePickerStep<"flashcard-hero-image">({
    stepName: "flashcard-hero-image",
    title: t("flashcards_page_title_hero_image"),
    onSubmit: (values) => onSubmitFlashcardImage({ ...values, canAddSummaryOfChanges }),
    initialImage: flashcard.url === FlashCardHeroImage.blankImage ? undefined : flashcard.url,
    errorDraggedImageText: t("imagePicker_too_large_error"),
    shouldAllowBlankSelection: shouldAllowBlankHeroImageSelection,
    nextLabel: t(canAddSummaryOfChanges ? "addFile_wizard_next_button" : "general_done"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
    stepName: "upload-progress",
    fileUploading: editingFlashcardItem,
    uploadProgress: editFlashcardItemProgress,
    fileUploadError: editFlashcardItemError,
    fileUploadedSuccess: editFlashcardItemSuccess,
    onFinishUpload: onClose,
  });

  const steps: Step<EditFlashcardItemWizardStep>[] = [
    flashcardForm,
    flashcardCreator,
    flashcardHeroImage,
    ...(canAddSummaryOfChanges ? [summaryOfChangesStep] : []),
    uploadProgressAndSuccessStep,
  ];

  const getStepClassName = (step: string) => {
    if (step === "flashcard-creator") {
      return "max-w-6xl h-90vh";
    }
    return "";
  };

  return (
    <Wizard
      steps={steps}
      wizardControl={wizardControl}
      getStepClassName={getStepClassName}
      modalBodyRef={modalBodyRef}
    />
  );
};

export default EditFlashcardModal;
