import { useLaunchDarkly } from "Contexts";
import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { ActiveTab, LDFlagNames } from "Utilities/types";
import { useGetMainSectionAdminAccess } from "./useGetMainSectionAdminAccess";
import {
  ChildReference,
  eolasLogger,
  isDefined,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { useCallback, useMemo } from "react";
import { parseMedusaMetadata } from "Utilities";
import { mapToMainSection } from "modules/mainSections/helpers";
import { MainSection } from "modules/mainSections/types";

export type UseSpaceOrgMainSectionsReturn = {
  activatedMainSections: MainSection[];
  deactivatedMainSections: MainSection[];
  hiddenMainSections: MainSection[];
  hasDeactivatedSections: boolean;
};

export const useSpaceOrgMainSections = ({
  isOnAdminView = false,
  activeTab,
}: {
  isOnAdminView?: boolean;
  activeTab: Extract<ActiveTab, "spaces" | "organisation">;
}): UseSpaceOrgMainSectionsReturn => {
  const allMainSections: ChildReference[] =
    activeTab === "spaces"
      ? sectionStore.departmentSectionsOrder
      : sectionStore.hospitalSectionsOrder;

  const { flags } = useLaunchDarkly();
  const { isMainSectionEnabled } = useMainSectionFlags();
  const { getIconUrl } = useGetIconUrl();
  const { filterByAdminStatus } = useGetMainSectionAdminAccess({
    activeTab,
    isOnAdminView,
  });

  const mapSectionList = useCallback(
    (sections: ChildReference[]) => {
      return sections
        .map(mapToMainSection)
        .map((s) => ({ ...s, iconUrl: s.iconName ? getIconUrl(s.iconName) : undefined }));
    },
    [getIconUrl],
  );

  // Medusa is the one section which needs to be considered separately:
  const getMedusaSectionStatus = useCallback(
    (sectionId: string): "enabled" | "disabled" | "hidden" => {
      if (!flags[LDFlagNames.MEDUSA_SECTIONS]) return "hidden";
      let isMedusaEnabled = false;
      const { metadata } = sectionStore.getSection(sectionId);
      if (typeof metadata === "string" || isDefined(metadata?.medusa?.status)) {
        const data =
          typeof metadata === "string" ? parseMedusaMetadata(metadata) : metadata?.medusa;

        isMedusaEnabled = data?.status === "enabled";
      }
      if (isMedusaEnabled) {
        return "enabled";
      }
      return isOnAdminView && flags[LDFlagNames.DISABLE_MEDUSA_ACTIVATION] ? "hidden" : "disabled";
    },
    [flags, isOnAdminView],
  );

  return useMemo(() => {
    const filteredMainSectionsBasedOnAdmin = isOnAdminView
      ? allMainSections.filter(filterByAdminStatus)
      : allMainSections;

    const filteredBasedOnFlagStatus = filteredMainSectionsBasedOnAdmin.filter(
      ({ icon, identity }) => {
        if (identity === "genericContentRepository") {
          return true;
        }
        return isMainSectionEnabled(icon ?? "");
      },
    );

    const activatedMainSections = filteredBasedOnFlagStatus.filter(({ disabled, icon, id }) => {
      return icon === OrganisationLevelSection.medusaMedications
        ? disabled !== "true" && getMedusaSectionStatus(id) === "enabled"
        : disabled !== "true";
    });

    const deactivatedMainSections = filteredBasedOnFlagStatus.filter(({ disabled, icon, id }) => {
      if (icon === OrganisationLevelSection.medusaMedications) {
        if (getMedusaSectionStatus(id) === "hidden") {
          return false;
        }
        if (getMedusaSectionStatus(id) === "disabled") {
          return true;
        }
      }
      return disabled === "true" || (disabled as unknown as boolean) === true;
    });

    const hiddenMainSections = allMainSections.filter(
      ({ id }) =>
        !activatedMainSections.some((childRef) => childRef.id === id) &&
        !deactivatedMainSections.some((childRef) => childRef.id === id),
    );

    const hasDeactivatedSections = deactivatedMainSections.length > 0;

    if (
      activatedMainSections.length + deactivatedMainSections.length + hiddenMainSections.length !==
      allMainSections.length
    ) {
      eolasLogger.error(
        new Error(
          "useSpaceOrgMainSections: 3 main section arrays do not add up to total. This should not happen",
        ),
        { allMainSections, activatedMainSections, deactivatedMainSections, hiddenMainSections },
      );
    }

    return {
      activatedMainSections: mapSectionList(activatedMainSections),
      deactivatedMainSections: mapSectionList(deactivatedMainSections),
      hiddenMainSections: mapSectionList(hiddenMainSections),
      hasDeactivatedSections,
    };
  }, [
    allMainSections,
    filterByAdminStatus,
    getMedusaSectionStatus,
    mapSectionList,
    isMainSectionEnabled,
    isOnAdminView,
  ]);
};
