import { useCallback, useState } from "react";
import { EolasMainSection } from "@eolas-medical/core";
import { LocalResult } from "../types";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { getMainSectionIdentity } from "modules/mainSections/helpers";
import { useRoleSwitcher } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useDetermineVisibleOrganisationTab } from "modules/spaces/data/useDetermineVisibleOrganisationTab";

interface UseLocalSearch {
  localFiles: LocalResult[];
  localFilesLoading: boolean;
  onSearchLocalFiles: (text: string) => void;
}

export const useLocalMasterSearch = (): UseLocalSearch => {
  const [isLoading, setIsLoading] = useState(false);
  const [localFiles, setLocalFiles] = useState<LocalResult[]>([]);
  const { isOnAdminPanel } = useRoleSwitcher();
  const { flags: ldFlags } = useLaunchDarkly();
  const { shouldShowOrgTab } = useDetermineVisibleOrganisationTab();

  const { getIconUrl } = useGetIconUrl();

  const handleSearch = useCallback(
    async (text: string) => {
      if (!text) {
        setIsLoading(false);
        setLocalFiles([]);
        return;
      }
      setIsLoading(true);
      const results = await localSearchStore.search({
        shouldSearchOrganisation: shouldShowOrgTab,
        term: text,
        ldFlags,
        isInAdminMode: isOnAdminPanel,
      });
      const mappedResults: LocalResult[] = results.map((file) => {
        const { mainSectionType, mainSectionIdentity, childReference } = getMainSectionIdentity(
          file.mainSectionID ?? "",
        );

        return {
          id: file.id || "",
          file: file,
          title: file.name || "",
          subtitle: file.description || "",
          mainSectionName: childReference?.name || "",
          searchTypeSection: "local",
          searchSubType: mainSectionType ?? mainSectionIdentity ?? ("" as EolasMainSection),
          subSectionName: file.parentName,
          iconName: childReference?.icon ? getIconUrl(childReference?.icon) : undefined,
        };
      });
      setLocalFiles(mappedResults);
      setIsLoading(false);
    },
    [getIconUrl, ldFlags, isOnAdminPanel, shouldShowOrgTab],
  );

  return {
    localFiles,
    localFilesLoading: isLoading,
    onSearchLocalFiles: handleSearch,
  };
};
