import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useUpdateSpace from "modules/spaces/data/useUpdateSpace";
import { BaseAppRoute } from "Utilities/types";
import { webStore } from "Stores/WebStore";
import { useEffect, useState } from "react";
import { AnalyticsEvents, SpaceUpdateDto } from "@eolas-medical/core";
import { useHistory } from "react-router-dom";
import { trackEvent } from "API/Analytics";
import { isItemChanged } from "Utilities/helpers";
import { useGetRelevantSpaceDetails } from "../pages/ManageUsers/hooks/useGetRelevantSpaceDetails";
import { useGetAdminStatus } from "../../hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";

type SpaceDetailsModalState =
  | "editSpace"
  | "viewSpace"
  | "shareSpace"
  | "getStartedSpace"
  | "postSpaceCreation";

export const useSpaceDetailsModal = () => {
  const history = useHistory();

  const [modalState, setModalState] = useState<SpaceDetailsModalState | null>(null);

  const { isPublic, relevantSpace: selectedSpace } = useGetRelevantSpaceDetails();

  const { onSwitchToAdmin } = useSpacesContext();
  const { activeTab } = useEolasNavigation();
  const accessLevel = useGetAdminStatus({ activeTab });
  const handleCloseModal = () => {
    setModalState(null);
  };

  const isAdminUser = accessLevel === "admin";

  const currentImageUrl = selectedSpace?.image?.url;

  const { updateSpace, updatingSpace } = useUpdateSpace();

  const handleSpaceUpdated = (updatedSpace: SpaceUpdateDto) => {
    if (updatedSpace.url && updatedSpace.url !== currentImageUrl) {
      trackEvent(AnalyticsEvents.SPACE_GALLERY_IMAGE_UPDATED);
    }
    if (updatedSpace.file) {
      trackEvent(AnalyticsEvents.SPACE_CUSTOM_IMAGE_UPDATED);
    }

    if (selectedSpace) {
      const { file, ...rest } = updatedSpace;
      if (
        !isItemChanged({
          item: { ...selectedSpace, url: selectedSpace.image?.url },
          editItem: rest,
          blob: file,
        })
      ) {
        handleCloseModal();
        return;
      }

      updateSpace(
        { spaceId: selectedSpace.id, spaceDto: updatedSpace },
        {
          onSuccess: () => {
            handleCloseModal();
            trackEvent(AnalyticsEvents.SPACE_UPDATED);
          },
        },
      );
    }
  };

  const showPostSpaceCreationModalValue = webStore.showPostSpaceCreationModal;

  const canCloseModal = !(modalState === "getStartedSpace" || modalState === "postSpaceCreation");

  const handleShowGetStartedSpaceModal = () => {
    setModalState("getStartedSpace");
  };

  const handleInvitePeople = () => {
    webStore.setShowPostSpaceCreationModal(false);
    handleCloseModal();
    onSwitchToAdmin(true);
    history.push(`/${BaseAppRoute.spaces}/${selectedSpace?.name}/manageUsers`);
  };

  const handleClickExploreAdmin = () => {
    webStore.setShowPostSpaceCreationModal(false);
    handleCloseModal();
    onSwitchToAdmin(true);
  };

  useEffect(() => {
    if (showPostSpaceCreationModalValue) {
      setModalState("postSpaceCreation");
    }
  }, [showPostSpaceCreationModalValue, setModalState]);

  return {
    modalState,
    onSwitchToAdmin,
    isAdminUser,
    isPublic,
    updatingSpace,
    handleSpaceUpdated,
    setModalState,
    selectedSpace,
    handleCloseModal,
    canCloseModal,
    handleShowGetStartedSpaceModal,
    handleClickExploreAdmin,
    handleInvitePeople,
  };
};
