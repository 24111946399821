import { SponsoredInfoSection } from "@eolas-medical/core";
import { BaseModalProps } from "Hooks/useModal";
import { DeleteModal, SuccessModal } from "UIKit";
import { useDeleteSponsoredSection } from "modules/sponsoredSlots/data/useDeleteSponsoredSection";
import { useTranslation } from "react-i18next";

type DeleteSponsoredSlotModalProps = {
  section: SponsoredInfoSection;
} & BaseModalProps;

const DeleteSponsoredSlotModal = ({ section, closeModal }: DeleteSponsoredSlotModalProps) => {
  const { t } = useTranslation();

  const { deleteSection, isLoading, isSuccess, isError } = useDeleteSponsoredSection();

  const handleDeleteSection = () => {
    deleteSection(section);
  };

  if (isSuccess) {
    return <SuccessModal onComplete={closeModal} text={t("my_files_delete_section_successful")} />;
  }

  return (
    <div data-testid="delete-content-section-modal">
      <DeleteModal
        title={t("knowledge_sponsored_slot_delete")}
        onCloseModal={closeModal}
        onDelete={handleDeleteSection}
        isLoading={isLoading}
        error={isError ? t("general_error") : null}
        description={t("knowledge_sponsored_slot_confirmation")}
      />
    </div>
  );
};

export default DeleteSponsoredSlotModal;
