import { useEffect, useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";

import { userStore, sectionStore, eolasLogger, AnalyticsEvents } from "@eolas-medical/core";

import { NotificationsProvider } from "Components/Notifications";
import { LDProvider } from "./LaunchDarklyContext";
import { globalWaitForHydration } from "Utilities/appStartup";
import MasterSearchProvider from "Components/MasterSearch/context/MasterSearchProvider";
import { PushNotificationsProvider } from "services/pushNotifications";
import { SpacesProvider } from "modules/spaces/context/SpacesProvider";
import { SessionProvider } from "modules/session";
import { DocumentCacheProvider } from "services/documentCache";
import { Sentry, queryClient } from "config";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { trackEvent } from "API/Analytics";

export const EolasProviders = ({ children }: { children: React.ReactNode }) => {
  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useEffect(() => {
    globalWaitForHydration()
      .then(async () => {
        const userData = userStore.userData;
        if (userData.id && userData.email) {
          Sentry.setUser({
            id: userData.id,
            appUserID: userStore.appUserID,
            appID: sectionStore.appID,
          });
        }
        if (userStore.userSession.isInOrganisation) {
          await localSearchStore.initialiseNewDb();
        }
        trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
        setIsHydrated(true);
      })
      .catch((error) => {
        eolasLogger.error(error);
      });
  }, []);

  if (!isHydrated) {
    return <div />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DocumentCacheProvider>
        <LDProvider>
          <SessionProvider>
            <SpacesProvider>
              <PushNotificationsProvider>
                <NotificationsProvider>
                  <MasterSearchProvider>{children}</MasterSearchProvider>
                </NotificationsProvider>
              </PushNotificationsProvider>
            </SpacesProvider>
          </SessionProvider>
        </LDProvider>
      </DocumentCacheProvider>
    </QueryClientProvider>
  );
};
