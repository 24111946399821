import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { Modal } from "UIKit";
import MasterSearchResultsLocal from "./components/MasterSearchResultsLocal/MasterSearchResultsLocal";
import { LocalResult, MasterSearchResultsParams, SearchResult, SearchTypeSection } from "./types";
import useMasterSearch from "./hooks/useMasterSearch";
import useSelectResult from "./hooks/useSelectResult";
import { trackEvent } from "API/Analytics";
import { useLaunchDarkly } from "Contexts";
import useMasterSearchState from "./components/useMasterSearchState";
import { useEolasNavigation } from "Components/Navigation/hooks";
import MasterSearchResultsKnowledge from "./components/MasterSearchResultsKnowledge/MasterSearchResultsKnowledge";
import MasterSearchResultsKnowledgeCopilot from "./components/MasterSearchResultsKnowledgeCopilot/MasterSearchResultsKnowledgeCopilot";
import { LDFlagNames } from "Utilities/types";
import { searchTypeToAnalyticsFileIdentity, searchTypeToSearchEndpointUsed } from "./helpers";
import { useGoToFile } from "Hooks/useGoToFile";

const MasterSearch = observer(() => {
  const [isCopilotError, setIsCopilotError] = useState(false);
  const { activeTab } = useEolasNavigation();
  const { flags } = useLaunchDarkly();
  const { isInOrganisation } = userStore.userSession;

  const knowledgeSearchDisabled = !flags[LDFlagNames.COMMUNITY_TAB];

  const knowledgeCopilotSearchDisabled = !flags[LDFlagNames.COPILOT_COMMUNITY_SEARCH];

  const [searchType, setSearchType] = useState<SearchTypeSection>("local");

  useEffect(() => {
    if (activeTab === "spaces" || activeTab === "organisation") {
      setSearchType("local");
    }

    if (activeTab === "knowledge") {
      setSearchType(knowledgeCopilotSearchDisabled ? "knowledge" : "knowledgeCopilot");
    }
  }, [activeTab, knowledgeCopilotSearchDisabled]);

  const isLocalSearch = searchType === "local";

  const { searchInput, searchValue, onSearchInputChange, onSearch, onClearSearch } =
    useMasterSearch();

  const { state, onType, ...actions } = useMasterSearchState();

  const { onClickSearch, ...rest } = actions;

  const { modal, onSelectResult, onCloseModal } = useSelectResult();
  const { onGoToFile } = useGoToFile();

  // Function to handle changes in the search text input
  const handleChangeText = useCallback(
    (text: string) => {
      onSearchInputChange(text);
    },
    [onSearchInputChange],
  );

  // Function to handle click on the search button
  const handleClickSearch = useCallback(
    (searchText?: string) => {
      onSearch(searchText);
      trackEvent(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
        searchString: searchText || "",
        searchType: searchTypeToAnalyticsFileIdentity(searchType),
        searchEndpointUsed: searchTypeToSearchEndpointUsed(searchType),
      });
      setSearchType(searchType);
    },
    [onSearch, searchType],
  );

  // Function to handle clearing the search input
  const handleClearSearch = useCallback(() => {
    onClearSearch();
    setIsCopilotError(false);
  }, [onClearSearch]);

  // Function to handle click on a filter pill
  const handleClickFilter = useCallback(
    (searchType: SearchTypeSection) => {
      setIsCopilotError(false);

      setSearchType(searchType);
      trackEvent(AnalyticsEvents.SEARCH_FILTER_USED, {
        searchString: searchValue,
        searchType: searchTypeToAnalyticsFileIdentity(searchType),
      });
    },
    [searchValue],
  );

  // Function to handle click on a search result
  const handleClickResult = useCallback(
    (result: SearchResult) => {
      onSelectResult(result);
      trackEvent(AnalyticsEvents.SEARCH_RESULT_CLICKED, {
        searchString: searchValue,
        fileIdentity: searchTypeToAnalyticsFileIdentity(result.searchTypeSection),
        mainSection: result.searchSubType,
        itemName: result.title,
        itemId: result.id,
        searchType: searchTypeToAnalyticsFileIdentity(searchType),
        searchEndpointUsed: searchTypeToSearchEndpointUsed(searchType),
      });
    },
    [searchValue, onSelectResult, searchType],
  );

  const handleGoToFile = useCallback(
    (result: LocalResult) => {
      onGoToFile(result);
      trackEvent(AnalyticsEvents.SEARCH_RESULT_CLICKED, {
        searchString: searchValue,
        fileIdentity: searchTypeToAnalyticsFileIdentity(result.searchTypeSection),
        mainSection: result.searchSubType,
        itemName: result.title,
        itemId: result.id,
        searchType: searchTypeToAnalyticsFileIdentity(searchType),
        searchEndpointUsed: searchTypeToSearchEndpointUsed(searchType),
      });
      trackEvent(AnalyticsEvents.BREADCRUMB_CLICKED, { source: "search" });
    },
    [onGoToFile, searchType, searchValue],
  );

  const handleClickMessage = useCallback((searchType: SearchTypeSection) => {
    setSearchType(searchType);
  }, []);

  const handleCopilotError = (isError: boolean) => {
    setIsCopilotError(isError);
  };

  const Component =
    isCopilotError && !isLocalSearch
      ? masterSearchResultsMap["knowledge"]
      : masterSearchResultsMap[searchType];

  return (
    <>
      <Component
        searchInput={searchInput}
        searchValue={searchValue}
        searchType={searchType}
        onClickMessage={handleClickMessage}
        knowledgeSearchDisabled={knowledgeSearchDisabled}
        localSearchDisabled={!isInOrganisation}
        onClearSearch={handleClearSearch}
        onChangeText={handleChangeText}
        onClickFilter={handleClickFilter}
        onClickSearch={handleClickSearch}
        onClickResult={handleClickResult}
        onGoToFile={handleGoToFile}
        state={state}
        onType={onType}
        onClickSearchAction={onClickSearch}
        onSearchError={handleCopilotError}
        isCopilotError={isCopilotError}
        knowledgeCopilotSearchDisabled={knowledgeCopilotSearchDisabled}
        {...rest}
      />
      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});

const masterSearchResultsMap: Record<
  SearchTypeSection,
  (params: MasterSearchResultsParams) => React.ReactNode
> = {
  local: (params) => <MasterSearchResultsLocal {...params} />,
  knowledge: (params) => <MasterSearchResultsKnowledge {...params} />,
  knowledgeCopilot: (params) => <MasterSearchResultsKnowledgeCopilot {...params} />,
};

export default MasterSearch;
