import { hasStringProp } from "@eolas-medical/core";
import { InsertLinkModal } from "Pages/FileViewer/components/DocumentViewer/components/InsertLinkModal/InsertLinkModal";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { Editor as TinyMCEEditor } from "tinymce";

export type DeeplinkConfig = {
  isEnabled: boolean;
  disabledIds?: string[];
};

export const useDeeplinkingSetup = (config?: DeeplinkConfig) => {
  const { t } = useTranslation();
  const createDeepLinkSetup = (editor: TinyMCEEditor) => {
    const isAnchorElement = (
      node: HTMLElement | Element,
    ): node is (HTMLElement | Element) & { href: string } => {
      return node.nodeName.toLowerCase() === "a" && hasStringProp(node, "href");
    };

    const getAnchorElement = (): ((HTMLElement | Element) & { href: string }) | null => {
      const node = editor.selection.getNode();
      // Sometimes the node is a text node, so we need to get the parent and check that
      const parent = node.parentElement;

      if (isAnchorElement(node)) {
        return node;
      }

      if (parent && isAnchorElement(parent)) {
        return parent;
      }

      return null;
    };

    editor.ui.registry.addContextForm("link-form", {
      launch: {
        type: "contextformtogglebutton",
        icon: "link",
      },
      label: t("editor_linking_form_label"),
      predicate: isAnchorElement,
      initValue: () => {
        const elm = getAnchorElement();
        return elm ? elm.href : "";
      },
      commands: [
        {
          type: "contextformbutton",
          icon: "document-properties",
          tooltip: t("editor_linking_form_link_document_tooltip"),
          onAction: (formApi) => {
            const handleInsertLink = (link: string) => {
              editor.execCommand("CreateLink", false, link);
              formApi.hide();
              modalStore.closeModal("stacked");
            };
            modalStore.openModal({
              name: "selectLinkModal",
              variant: "component",
              layoutType: "stacked",
              Component: (
                <InsertLinkModal
                  onInsertLink={handleInsertLink}
                  linkType="local"
                  disabledIds={config?.disabledIds}
                />
              ),
            });
          },
        },
        {
          type: "contextformtogglebutton",
          icon: "link",
          primary: true,
          tooltip: t("editor_linking_form_link_url_tooltip"),
          onSetup: (buttonApi) => {
            buttonApi.setActive(!!getAnchorElement());
            const nodeChangeHandler = () => {
              buttonApi.setActive(!editor.readonly && !!getAnchorElement());
            };
            editor.on("nodechange", nodeChangeHandler);
            return () => editor.off("nodechange", nodeChangeHandler);
          },
          onAction: (formApi) => {
            const value = formApi.getValue();
            editor.execCommand("CreateLink", false, value);
            formApi.hide();
          },
        },
        {
          type: "contextformtogglebutton",
          icon: "unlink",
          tooltip: t("editor_linking_form_remove_link_tooltip"),
          active: false,
          onAction: (formApi) => {
            editor.execCommand("Unlink");
            formApi.hide();
          },
        },
      ],
    });

    editor.ui.registry.addContextToolbar("textselection", {
      predicate: () => !editor.selection.isCollapsed(),
      items: "bold italic link-form h2 h3 blockquote",
      position: "selection",
      scope: "node",
    });
  };

  return { createDeepLinkSetup };
};
