import { profilesClient, userStore } from "@eolas-medical/core";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useRequestStatus } from "Utilities";

export type NameProperty = "givenName" | "familyName";

export const usePersonalDetails = (nameProp: NameProperty) => {
  const { userData } = userStore;
  const value: string = userData?.[nameProp] ?? "givenName";

  const inputRef = useRef<HTMLInputElement>(null);
  const [editable, setEditable] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputValue, setInputValue] = useState(userData?.[nameProp]);
  const { error, setRequestStatus, isLoading: loading } = useRequestStatus();

  useEffect(() => {
    if (userStore.userData.isFederated) {
      setDisabled(true);
    }
  }, []);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const makeEditable = useCallback(() => {
    setTimeout(() => {
      setEditable(true);
      inputRef.current?.focus();
    }, 0);
  }, [setEditable]);

  const updateUserName = useCallback(
    async (value: string) => {
      setRequestStatus({ status: "pending", error: "" });
      if (userData.id) {
        const input = {
          [nameProp]: value,
        };
        try {
          const user = await profilesClient.updateUserProfile(userData.id, {
            ...userData,
            ...input,
          });
          userStore.updateData({ user });
          setRequestStatus({ status: "success", error: "" });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          setRequestStatus({ status: "error", error });
        }
      }
    },
    [nameProp, setRequestStatus, userData],
  );

  const onSave = useCallback(() => {
    if (inputValue && inputValue !== value) {
      updateUserName(inputValue).finally(() => {
        setEditable(false);
      });
    } else {
      setEditable(false);
    }
  }, [value, inputValue, setEditable, updateUserName]);

  return {
    error,
    onSave,
    disabled,
    inputRef,
    editable,
    loading,
    inputValue,
    makeEditable,
    onChangeInput,
    updateUserName,
    value: userData?.[nameProp],
  };
};
