import React from "react";
import { AxiosError, accessClient } from "@eolas-medical/core";
import { useMutation, MutateOptions } from "@tanstack/react-query";
import { ShouldAllowUserAccessResponse } from "@eolas-medical/core/lib/src/clients/access/access.types";

export const useShouldAllowAccess = () => {
  const { reset, mutate, error, data, isLoading } = useMutation<
    ShouldAllowUserAccessResponse,
    AxiosError,
    { appId: string }
  >(async ({ appId }) => {
    return accessClient.shouldAllowUserAccess(appId);
  });

  const shouldAllowUserAccess = React.useCallback(
    (
      appId: string,
      options?: MutateOptions<ShouldAllowUserAccessResponse, AxiosError, { appId: string }>,
    ) => {
      reset();
      mutate({ appId }, options);
    },
    [reset, mutate],
  );

  return {
    error: error?.message,
    userAccess: data,
    shouldAllowAccessLoading: isLoading,
    shouldAllowUserAccess,
  };
};
