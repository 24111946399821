import {
  CommunityLevelSection,
  MedicationSummaryItem,
  getEntityIdSuffix,
  sectionStore,
} from "@eolas-medical/core";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";
import { FavouriteToggle, Text, TileContainer } from "UIKit";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { isSupportedFavouriteBNFMedicationType } from "modules/myFavourites/typeguards";
import { useAddFavourite } from "shared/hooks/useAddFavourite";

interface BnfSectionTileProps {
  item: MedicationSummaryItem;
  onClick: () => void;
  onShare?: () => void;
  onClickFavourite?: () => void;
  isMarkingAsFavourite?: boolean;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
}

const BnfSectionTile = ({
  item,
  onClick,
  onShare,
  showRemoveFavouriteModal,
}: BnfSectionTileProps) => {
  const { id, name, type } = item;
  const shouldShowShare = !!onShare;
  const bnfEntityId =
    id + getEntityIdSuffix(type === "bnf" ? FavouriteEntityType.BNF : FavouriteEntityType.BNFC);
  const { isMarkingAsFavourite, isMarkFavouriteEnabled, handleAddFavourite, favouriteId } =
    useAddFavourite(bnfEntityId);
  const canFavourite = isSupportedFavouriteBNFMedicationType(type) && isMarkFavouriteEnabled;
  const mainSectionId = sectionStore.getChildReferenceByMainSectionType(
    CommunityLevelSection.communityMedications,
  )?.id;
  return (
    <TileContainer onClick={onClick} className="h-20 md:h-24 justify-between">
      <Text level={1}>{name}</Text>
      <div>
        {canFavourite && (
          <FavouriteToggle
            entityId={bnfEntityId}
            isLoading={!!isMarkingAsFavourite}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: id,
                    favouriteId,
                    title: name,
                    entityType: type === "bnf" ? FavouriteEntityType.BNF : FavouriteEntityType.BNFC,
                  })
                : handleAddFavourite({
                    item,
                    entityType: type === "bnf" ? FavouriteEntityType.BNF : FavouriteEntityType.BNFC,
                    mainSectionId,
                  })
            }
          />
        )}
        {shouldShowShare && <ShareFileButton onShareFile={onShare} id={name} />}
      </div>
    </TileContainer>
  );
};

export default BnfSectionTile;
