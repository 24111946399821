import { Text, IconButton } from "UIKit";
import { TrustedEmailIcon, CopyIcon, DeleteIcon } from "Assets";

import { useTrustedEmail } from "./useTrustedEmail";
import { useToolTip } from "Hooks/useToolTip";

interface TrustedEmailProps {
  email: string;
}

export const TrustedEmail: React.FC<TrustedEmailProps> = ({ email }: TrustedEmailProps) => {
  const { makeToolTipDataAttr } = useToolTip();
  const { isLoading, tooltipText, onDeleteEmail, onCopyClicked, afterTooltipHide } =
    useTrustedEmail(email);

  return (
    <div className="flex items-center p-4">
      <TrustedEmailIcon className="w-10 h-10" />

      <Text className="flex-1 text-black font-bold ml-4" level={1}>
        {email}
      </Text>

      <IconButton
        size="sm"
        data-tip
        className="mx-2"
        variant="rounded"
        icon={<CopyIcon />}
        onClick={onCopyClicked}
        onMouseLeave={afterTooltipHide}
        {...makeToolTipDataAttr({ text: tooltipText })}
      />

      <IconButton
        isLoading={isLoading}
        size="sm"
        variant="rounded"
        icon={<DeleteIcon />}
        onClick={onDeleteEmail}
      />
    </div>
  );
};
