import React from "react";
import { NewsFeed } from "../../../../types";
import format from "date-fns/format";
import { AttentionIcon, CheckIcon } from "Assets";
import { useTranslation } from "react-i18next";
import { DownloadFileButton } from "Components";
import { motion } from "framer-motion";
import { FavouriteToggle, FileTypeItemIcon } from "UIKit";
import { AppLevelSection, SupportedFileType } from "@eolas-medical/core";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

type NewsFeedTileProps = {
  newsFeed: NewsFeed;
  onClick: (newsFeed: NewsFeed) => void;
  onShare?: (newsFeed: NewsFeed) => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
};

const NewsFeedTile = ({
  newsFeed,
  onClick,
  onShare,
  showRemoveFavouriteModal,
}: NewsFeedTileProps) => {
  const { t } = useTranslation();

  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(newsFeed.id);

  return (
    <motion.div
      className="p-2 h-44 grid grid-cols-12 transition-all bg-white rounded-lg shadow-sm cursor-pointer"
      onClick={() => onClick(newsFeed)}
      data-testid={newsFeed.name}
    >
      <div className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 rounded-lg relative">
        <div className="flex items-center justify-center w-full h-full">
          <FileTypeItemIcon type={newsFeed.type as SupportedFileType} className="w-12 h-12" />
        </div>
      </div>
      <div className="row-start-1 col-start-4 lg:col-start-3 col-end-10 flex flex-col justify-between p-5">
        <span className="font-semibold text-lg line-clamp-1 leading-10">{newsFeed?.name}</span>
        <div className="flex flex-col">
          <span className="text-sm text-grey-dark line-clamp-2">{newsFeed?.createdBy}</span>
          {newsFeed.createdAt && (
            <span className="text-sm text-grey-dark">
              {format(new Date(newsFeed.createdAt as string), "do MMMM yyyy, HH:mm")}
            </span>
          )}
        </div>
        <div className="flex items-center mt-2">
          {newsFeed.isRead ? (
            <CheckIcon
              data-testid="completed-new-item"
              className="text-green"
              height={12}
              width={12}
            />
          ) : (
            <AttentionIcon
              data-testid="incomplete-new-item"
              className="text-red"
              height={12}
              width={12}
            />
          )}
          <span className="text-sm text-grey-dark ml-2">
            {t(newsFeed.isRead ? "newsFeed_item_viewed" : "newsFeed_item_not_viewed")}
          </span>
        </div>
      </div>
      <div className="col-start-12 xl:col-start-12 self-center">
        {isMarkFavouriteEnabled ? (
          <FavouriteToggle
            entityId={newsFeed.id}
            onClick={() =>
              favouriteId && showRemoveFavouriteModal
                ? showRemoveFavouriteModal({
                    entityId: newsFeed.id,
                    favouriteId,
                    title: newsFeed.name,
                    entityType: FavouriteEntityType.FILE,
                    originForAnalytics: AppLevelSection.newsFeed,
                  })
                : handleAddFavourite({
                    item: newsFeed,
                    entityType: FavouriteEntityType.FILE,
                  })
            }
            isLoading={!!isMarkingAsFavourite}
          />
        ) : null}
        <DownloadFileButton eolasFile={newsFeed} />
        {onShare && <ShareFileButton id={newsFeed.id} onShareFile={() => onShare(newsFeed)} />}
      </div>
    </motion.div>
  );
};

export default NewsFeedTile;
