import { useToolTip } from "Hooks/useToolTip";
import { Tag } from "UIKit";
import { LabelColor } from "UIKit/EolasTile/EolasTile.types";

const labelColorToTwClasses = (color: LabelColor): string => {
  switch (color) {
    case "grey":
      return "bg-grey-300 text-grey-700";
    case "green":
      return "bg-green-100 text-green-700";
    case "yellow":
      return "bg-yellow-100 text-yellow-700";
    case "transparent":
      return "bg-transparent text-grey-700";
  }
};

export const EolasTag = ({
  color,
  text,
  tooltipText,
}: {
  color: LabelColor;
  text: string;
  tooltipText?: string;
}) => {
  const { makeToolTipDataAttr } = useToolTip();
  return (
    <Tag
      className={`${labelColorToTwClasses(color)} self-start`}
      label={text}
      {...(tooltipText ? makeToolTipDataAttr({ text: tooltipText }) : {})}
    />
  );
};
