import { Modal as ModalWrapper, ModalProps } from "UIKit";
import { useCallback, useState } from "react";
import { useRunOnMountUnmount } from "./useRunOnMountUnmount";

export type BaseModalProps = { closeModal: () => void };

export const useModal = <U extends BaseModalProps>({
  Modal,
  modalProps,
  modalContainerProps,
  shouldShowModalOnMount,
}: {
  Modal: React.ComponentType<U>;
  modalProps: Omit<U, "closeModal">;
  modalContainerProps?: Omit<ModalProps, "onClose" | "children" | "open"> & {
    onClose?: () => void;
  };
  shouldShowModalOnMount?: boolean;
}) => {
  const [modalState, setModalState] = useState<JSX.Element | null>(null);

  const { onClose, ...rest } = modalContainerProps ?? {};

  const closeModal = useCallback(() => {
    onClose?.();
    setModalState(null);
  }, [onClose]);

  // Cast here as we know we are not creating a subtype of U, just recombining U:
  const combinedModalProps = { ...modalProps, closeModal } as U;

  const modal = <Modal {...combinedModalProps} />;

  const modalWrapped = (
    <ModalWrapper open={Boolean(modalState)} onClose={closeModal} {...rest}>
      {modal}
    </ModalWrapper>
  );

  const showModal = () => {
    setModalState(modal);
  };

  useRunOnMountUnmount({
    onMount: () => {
      if (shouldShowModalOnMount) {
        showModal();
      }
    },
  });

  return { modal: modalWrapped, showModal, closeModal };
};
