import { useForm } from "react-hook-form";
import { useMemo } from "react";

import { WizardState } from "../../../../types";
import { useFlashcardValidationSchema } from "UIKit/Wizard/templates/FlashcardCreatorStep/useFlashcardValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { EolasEditorMetadata, Metadata, NewContentType } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { createResourcesManifestFromHtml } from "modules/contentRepository/helpers";
import { toAwsJSON } from "Utilities";
import { extractEditorContent } from "../functions/extractEditorContent";

export type EditEditorValues = { content: string };

export const useEditorContent = (state: WizardState) => {
  const validationSchema = useFlashcardValidationSchema();

  const initialContent = useMemo(() => {
    let metadata: Metadata | string | undefined = undefined;
    if (!state.isExistingFileWithNewType) {
      metadata = state.dto.metadata;
    } else if (state.dto.newType === NewContentType.EOLAS_EDITOR) {
      metadata = state.dto.newProperties.metadata;
    }
    return extractEditorContent(metadata);
  }, [state]);

  const { handleSubmit, control } = useForm<EditEditorValues>({
    defaultValues: {
      content: initialContent,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    isLoading,
    mutateAsync: processEditorData,
    isError,
  } = useMutation({
    mutationKey: ["eolasEditorContent"],
    mutationFn: async ({ content }: { content: string }) => {
      const resourcesManifest = await createResourcesManifestFromHtml(content);
      const eolasEditorMetadata: EolasEditorMetadata = {
        eolasEditorContent: content,
        resourcesManifest,
      };
      return toAwsJSON(eolasEditorMetadata);
    },
  });

  return { control, handleSubmit, initialContent, isLoading, processEditorData, isError };
};
