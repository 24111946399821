import { useTranslation } from "react-i18next";
import { Control, FieldValues } from "react-hook-form";
import { Title } from "UIKit";
import { FeedbackForm, FeedbackStatus } from "modules/portfolio/types";
import PortfolioFormElement from "Pages/MeTab/pages/Portfolio/components/PortfolioFormElement/PortfolioFormElement";
import CompletedFeedback from "../CompletedFeedback/CompletedFeedback";

export interface FeedbackFormProps {
  feedbackForm?: FeedbackForm;
  control?: Control<FieldValues>;
}

const FeedbackFormBody = ({ feedbackForm, control }: FeedbackFormProps) => {
  const { t } = useTranslation();

  if (!feedbackForm || !control) return null;

  const { formElements } = feedbackForm;

  if (feedbackForm.status === FeedbackStatus.COMPLETE) {
    return <CompletedFeedback feedbackForm={feedbackForm} />;
  }

  return (
    <div className="rounded-xl bg-white p-8">
      <div className="flex flex-col items-center">
        <div className="mb-4">
          <Title level={5}>{t("portfolio_feedback_caption_title")}</Title>
        </div>
      </div>
      <form data-testid="feedback-form">
        <div className="mb-4">
          {formElements.map((el) => (
            <div key={el.fieldName}>
              <PortfolioFormElement item={el} control={control} />
            </div>
          ))}
        </div>
      </form>
    </div>
  );
};

export default FeedbackFormBody;
