import { useTranslation } from "react-i18next";
import { AppLevelSection } from "@eolas-medical/core";

import { noFilesFound } from "Assets";
import { EolasFileList } from "Components";
import { LottieWithHeader, Modal } from "UIKit";

import { useSubmittedChecklists } from "../hooks";

export interface SubmittedChecklistsProps {
  checklistID: string;
}

export const SubmittedChecklists: React.FC<SubmittedChecklistsProps> = ({
  checklistID,
}: SubmittedChecklistsProps) => {
  const { modal, onCloseModal, filterChecklists, onDelete, onSelect } = useSubmittedChecklists();

  return (
    <>
      <EolasFileList
        isAdmin={true}
        defaultSort="date"
        draggable={false}
        onDelete={onDelete}
        sortDateBy="createdAt"
        className="space-y-6"
        onSelectFile={onSelect}
        listClassName="space-y-4"
        adminNoFiles={<NoFiles />}
        subSectionId={checklistID}
        filterFn={filterChecklists}
        mainSectionId={AppLevelSection.checklists}
      />

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};

const NoFiles = () => {
  const { t } = useTranslation();
  return (
    <LottieWithHeader
      animationSize="25%"
      animation={noFilesFound}
      data-testid="no-files-found"
      lottieOptions={{ loop: true }}
      text={t("general_noFilesFound")}
    />
  );
};
