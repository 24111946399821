import { useMemo } from "react";
import { EolasFile, fileStore, searchClient, sectionStore } from "@eolas-medical/core";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import { KnowledgeResult } from "../types";
import { mapKnowledgeFiles } from "../helpers";

const useKnowledgeSearch = ({
  searchValue,
  knowledgeSearchDisabled,
}: {
  searchValue: string;
  knowledgeSearchDisabled?: boolean;
}) => {
  const { supportedEolasRegion } = useSupportedRegion();
  const { filesList } = fileStore;
  const { communitySectionsOrder } = sectionStore;

  const knowledgeLocalFiles = useMemo(() => {
    return filesList.filter((f) => communitySectionsOrder.some((s) => s.id === f.mainSectionID));
  }, [filesList, communitySectionsOrder]);

  const queryFn = (searchInput: string): Promise<EolasFile[]> => {
    return searchClient.searchNationalResources({
      query: searchInput,
      mode: "searchbyInput",
      region: supportedEolasRegion,
      organisationId: sectionStore.organisationID,
    });
  };

  const { data: files = [], isFetching } = useQuery(
    ["knowledgeSearch", { searchValue }],
    () => queryFn(searchValue),
    {
      staleTime: 60000,
      enabled: !knowledgeSearchDisabled && searchValue.length >= 2,
    },
  );

  const { searchResult: localSearchResult, onSetSearchInput } = useLocalSearch({
    data: knowledgeLocalFiles,
    keysToSearch: ["name", "description", "searchField"],
  });

  const knowledgeFiles: KnowledgeResult[] = useMemo(() => {
    const filesMapped = mapKnowledgeFiles(files);
    const localFilesMapped = mapKnowledgeFiles(localSearchResult);

    return [...filesMapped, ...localFilesMapped];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, localSearchResult]);

  return { knowledgeFiles, isFetching, onSetSearchInput };
};

export default useKnowledgeSearch;
