import { InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";

export const DsmTextModal = ({ title, text }: { title: string; text: string }) => {
  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{title}</Title>
      </ModalHeader>
      <ModalBody className="overflow-y-scroll">
        <Text className="mb-8 sm:mb-12 whitespace-pre-wrap" level={1}>
          {text.replace(/'''/g, "")}
        </Text>
      </ModalBody>
    </InnerModalWrapper>
  );
};
