import { Decision, NationalFundingDrugContent } from "@eolas-medical/core";
import { Divider, Text } from "UIKit";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import { COLUMN_CONTAINER_CLASS, LINK_CLASS } from "../../../styles/stylesClasses";
import { Strings } from "./NationalFundingContent.strings";

const NICE_DECISIONS = "eolas_nice_decisions";
const SMC_DECISIONS = "eolas_smc_decisions";
const AWMSG_DECISIONS = "eolas_awmsg_decisions";

export const NationalFundingComponent = ({ content }: { content: NationalFundingDrugContent }) => {
  return (
    <Divider>
      <BnfContentElement htmlContent={content.initialText} addTags="p" className="mb-2" />
      {content.niceDecisions.length ? (
        <Divider className={COLUMN_CONTAINER_CLASS}>
          <Text level={1} className="bold my-2">
            {content.niceDecisionsTitle || Strings.niceDecisions}
          </Text>
          {content.niceDecisions.map((decision, index) => {
            return <DecisionComponent decision={decision} key={NICE_DECISIONS + index} />;
          })}
        </Divider>
      ) : null}
      {content.smcDecisions.length ? (
        <Divider className={COLUMN_CONTAINER_CLASS}>
          <Text level={1} className="bold my-2">
            {content.smcDecisionsTitle || Strings.smcDecisions}
          </Text>
          {content.smcDecisions.map((decision, index) => {
            return <DecisionComponent decision={decision} key={SMC_DECISIONS + index} />;
          })}
        </Divider>
      ) : null}
      {content.awmsgDecisions.length ? (
        <Divider className={COLUMN_CONTAINER_CLASS}>
          <Text level={1} className="bold my-2">
            {content.awmsgDecisionsTitle || Strings.awmsgDecisions}
          </Text>
          {content.awmsgDecisions.map((decision, index) => {
            return <DecisionComponent decision={decision} key={AWMSG_DECISIONS + index} />;
          })}
        </Divider>
      ) : null}
    </Divider>
  );
};

const DecisionComponent = ({ decision }: { decision: Decision }) => {
  return (
    <div className="flex flex-col gap-1 py-1">
      {decision.fundingIdentifier ? (
        <Text level={3} className="italic">
          {decision.fundingIdentifier}
        </Text>
      ) : null}
      {decision.title ? <BnfContentElement htmlContent={decision.title} addTags="h5" /> : null}
      {decision.approvedForUse ? <Text level={3}>{decision.approvedForUse}</Text> : null}
      {decision.url ? (
        <Text level={3} className={LINK_CLASS} onClick={() => window.open(decision.url, "_blank")}>
          {Strings.link}
        </Text>
      ) : null}
    </div>
  );
};
