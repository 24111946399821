import { DrugIndicationsAndDose } from "@eolas-medical/core";
import { Divider } from "UIKit";
import IndicationsAndDoseGroup from "./IndicationAndDoseGroup";
import { isEmpty } from "lodash";
import AdditionalInfo from "./AdditionalInfo";
import BnfPill from "../../BnfPill/BnfPill";
import { IndicationAndDoseGroupStrings } from "./IndicationAndDoseGroup.strings";

const KEY_BASE = "eolas_dose_group";
const KEY_BASE_FRAGMENT = "eolas_group_fragment";
const KEY_BASE_NESTED = "eolas_dose_group_nested";

export const DrugIndicationsAndDoseBaseComponent = ({
  indsAndDose,
}: {
  indsAndDose: DrugIndicationsAndDose;
}) => {
  if (!indsAndDose.drugContent) {
    return null;
  }

  const {
    indicationAndDoseGroups,
    contentFor: unusedContentFor,
    ...additionalInfo
  } = indsAndDose.drugContent;
  const prepContentGroups = indsAndDose.prepContent;

  return (
    <Divider>
      <Divider className="flex flex-col -mt-2">
        {indicationAndDoseGroups?.map((group, index) => {
          return (
            <div key={`${KEY_BASE}-${index}`}>
              <IndicationsAndDoseGroup group={group} />
            </div>
          );
        })}
      </Divider>
      {!isEmpty(additionalInfo) && (
        <div className="pt-4">
          <AdditionalInfo {...additionalInfo} />
        </div>
      )}
      {prepContentGroups.length
        ? prepContentGroups.map((group, index) => {
            const {
              contentFor,
              indicationAndDoseGroups: nestedGroups,
              ...prepContentAdditionalInfo
            } = group;

            return (
              <div key={KEY_BASE_FRAGMENT + index} className="pt-4">
                <BnfPill value={IndicationAndDoseGroupStrings.for(contentFor)} />

                {nestedGroups.map((nestedGroup, nestedIndex) => {
                  return (
                    <div key={`${KEY_BASE}-${index}`}>
                      <IndicationsAndDoseGroup
                        key={KEY_BASE_NESTED + nestedIndex}
                        group={nestedGroup}
                      />
                    </div>
                  );
                })}
                {!isEmpty(prepContentAdditionalInfo) && (
                  <div className="py-4">
                    <AdditionalInfo {...prepContentAdditionalInfo} />
                  </div>
                )}
              </div>
            );
          })
        : null}
    </Divider>
  );
};
