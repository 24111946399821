// Assume webNotificationsKeys and removeObjectFromList are defined elsewhere and imported

import { trackEvent } from "API/Analytics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import webNotificationsKeys from "./webNotifications.queryKeys";
import { AnalyticsEvents, Notification, notificationsClient } from "@eolas-medical/core";

interface QueryData {
  notifications: Notification[];
}

interface MutationContext {
  previousData: QueryData | undefined;
}

const useDismissBulkNotifications = () => {
  const queryClient = useQueryClient();

  const dismissNotifications = async (notificationIds: string[]) => {
    await Promise.all(
      notificationIds.map((notificationId) =>
        notificationsClient.deleteNotification({ notificationId }),
      ),
    );
  };

  const { mutate, isLoading } = useMutation<void, Error, string[], MutationContext>(
    dismissNotifications,
    {
      onMutate: async (notificationIds) => {
        await queryClient.cancelQueries(webNotificationsKeys.list());

        const previousData = queryClient.getQueryData<QueryData>(webNotificationsKeys.list());

        queryClient.setQueryData<QueryData>(webNotificationsKeys.list(), (oldData) => {
          if (oldData) {
            const updatedNotifications = oldData.notifications.filter(
              (notification) => !notificationIds.includes(notification.id),
            );
            return { ...oldData, notifications: updatedNotifications };
          }
          return oldData;
        });

        return { previousData };
      },
      onError: (error, _, context) => {
        console.error("Error dismissing notifications:", error);
        // revert to previous state if necessary
        if (context?.previousData) {
          queryClient.setQueryData<QueryData>(webNotificationsKeys.list(), context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(webNotificationsKeys.list());
      },
      onSuccess: (_data, notificationIds, context) => {
        notificationIds.forEach((notificationId) => {
          const dismissedNotification = context?.previousData?.notifications.find(
            (n) => n.id === notificationId,
          );
          if (dismissedNotification) {
            trackEvent(AnalyticsEvents.NOTIFICATION_REMOVED, {
              notificationType: dismissedNotification.type,
            });
          }
        });
      },
    },
  );

  return {
    dismissBulkNotifications: mutate,
    dismissingNotifications: isLoading,
  };
};

export default useDismissBulkNotifications;
