import { InfoSection } from "@eolas-medical/core";
import BnfContentElement from "../../../components/BnfContentElement/BnfContentElement";
import { BnfCollapsible, InfoIdProps } from "../../../components/BnfCollapsible/BnfCollapsible";
import { removeTags } from "modules/bnf/helpers";

export const InfoSectionComponent = ({
  section,
  ...rest
}: {
  shouldMountExpanded: boolean;
  section: InfoSection;
} & InfoIdProps) => {
  return (
    <BnfCollapsible title={removeTags(section.title)} {...rest}>
      <BnfContentElement htmlContent={section.content} addTags="div" className="my-2" />
    </BnfCollapsible>
  );
};
