import { useQuery } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

const useKnowledgeContentRepository = ({
  domain,
  contentRepositoryId,
}: {
  domain: KnowledgeContentDomain;
  contentRepositoryId: string | null;
}) => {
  const { data, isInitialLoading } = useQuery({
    queryKey: knowledgeContentKeys.repository(domain),
    queryFn: () => {
      return knowledgeContentClient.getSectionById({ sectionId: contentRepositoryId ?? "" });
    },
    select: (data) => ({
      id: data?.id || "",
      name: data?.name || "",
      description: data?.description || "",
      childrenType: data?.childrenType || "",
    }),
    enabled: Boolean(contentRepositoryId),
  });

  return {
    contentRepository: data,
    contentRepositoryLoading: isInitialLoading,
  };
};

export default useKnowledgeContentRepository;
