import {
  Wizard,
  useWizard,
  Step,
  useUploadProgressAndSuccessStep,
  useConfigureFileStep,
} from "UIKit";
import { useTranslation } from "react-i18next";
import { EolasEditorContent } from "@eolas-medical/core";
import { useRef } from "react";
import { AddEolasEditorItemWizardStep } from "../AddEolasEditorItemWizard/types";
import { useEolasEditorStep } from "UIKit/Wizard/templates/EolasEditorCreatorStep";
import { useEditEolasEditorItemActions } from "./hooks/useEditEolasEditorItemActions";
import { useSummaryOfChangesStep } from "UIKit/Wizard/templates/SummaryOfChangesStep/useSummaryOfChangesStep";

interface EditEolasEditorItemModalProps {
  contentItem: EolasEditorContent;
  hasSpecialties?: boolean;
  onClose: () => void;
}

export type EditFlashcardItemWizardStep =
  | "flashcard-form"
  | "flashcard-creator"
  | "flashcard-hero-image"
  | "upload-progress";

const EditEolasEditorItemModal = ({ contentItem, onClose }: EditEolasEditorItemModalProps) => {
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<EditFlashcardItemWizardStep>();
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const {
    wizardState,
    editingEolasEditorItem,
    editEolasEditorItemSuccess,
    editEolasEditorItemError,
    editEolasEditorItemProgress,
    onEditEolasEditorDetails,
    onUpdateWithSummaryOfChanges,
    onSubmitEolasEditorCreator,
  } = useEditEolasEditorItemActions({
    eolasEditorItem: contentItem,
    onClose,
  });

  const { canAddSummaryOfChanges, step: summaryOfChangesStep } = useSummaryOfChangesStep({
    onSubmit: onUpdateWithSummaryOfChanges,
    title: t("repository_edit_item_summary_of_changes"),
    backLabel: t("general_back"),
    nextLabel: t("general_done"),
  });

  const eolasEditorForm = useConfigureFileStep<"eolas-editor-form">({
    stepName: "eolas-editor-form",
    title: t("repository_edit_item_title"),
    onEnter: submitWizard,
    onSubmit: onEditEolasEditorDetails,
    item: { ...wizardState },
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t("addSection_wizard_next_button"),
  });

  const eolasEditorCreator = useEolasEditorStep<"eolas-editor-creator">({
    pageTitle: t("eolasEditor_page_title"),
    nextLabel: t(canAddSummaryOfChanges ? "addFile_wizard_next_button" : "general_done"),
    editorContent: wizardState?.content,
    onSubmit: (values) => onSubmitEolasEditorCreator({ ...values, canAddSummaryOfChanges }),
    contentRepositoryId: contentItem.mainSectionId,
    modalBodyRef: modalBodyRef,
    currentItemId: contentItem.id,
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
    stepName: "upload-progress",
    fileUploading: editingEolasEditorItem,
    uploadProgress: editEolasEditorItemProgress,
    fileUploadError: editEolasEditorItemError,
    fileUploadedSuccess: editEolasEditorItemSuccess,
    onFinishUpload: onClose,
  });

  const steps: Step<AddEolasEditorItemWizardStep>[] = [
    eolasEditorForm,
    eolasEditorCreator,
    ...(canAddSummaryOfChanges ? [summaryOfChangesStep] : []),
    uploadProgressAndSuccessStep,
  ];

  const getStepClassName = (step: string) => {
    if (step === "eolas-editor-creator") {
      return "max-w-6xl h-90vh";
    }
    return "";
  };

  return (
    <Wizard
      getStepClassName={getStepClassName}
      steps={steps}
      wizardControl={wizardControl}
      modalBodyRef={modalBodyRef}
    />
  );
};

export default EditEolasEditorItemModal;
