import { InnerPageWrapper, GridContainer, Modal } from "UIKit";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import ViewSpaceDetailsModal from "./components/ViewSpaceDetailsModal/ViewSpaceDetailsModal";
import AccessQRCode from "./components/AccessQRCode/AccessQRCode";
import SpaceDetails from "./components/SpaceDetails/SpaceDetails";
import EditSpaceDetailsWizard from "./components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";
import PostSpaceCreationModal from "../SelectSpace/components/DiscoverSpaces/components/PostSpaceCreationModal/PostSpaceCreationModal";
import GetStartedSpaceModal from "./components/GetStartedSpaceModal/GetStartedSpaceModal";
import { observer } from "mobx-react-lite";
import { useSpaceDetailsModal } from "./hooks/useSpaceDetailsModal";
import { useSpaceOrgMainSections } from "modules/spaces/data/useSpaceOrgMainSections";

const Space = observer(() => {
  const {
    modalState,
    isAdminUser,
    isPublic,
    updatingSpace,
    handleSpaceUpdated,
    setModalState,
    selectedSpace,
    handleCloseModal,
    canCloseModal,
    handleShowGetStartedSpaceModal,
    handleClickExploreAdmin,
    handleInvitePeople,
  } = useSpaceDetailsModal();

  const { activatedMainSections } = useSpaceOrgMainSections({ activeTab: "spaces" });

  if (!selectedSpace) {
    return null;
  }

  return (
    <InnerPageWrapper>
      <div className="space-y-12 flex flex-col justify-center items-center -mt-2.5">
        <SpaceDetails
          onClickEditSpace={() => setModalState("editSpace")}
          onClickViewSpace={() => setModalState("viewSpace")}
          onClickShareSpace={() => setModalState("shareSpace")}
          isAdminUser={isAdminUser}
          isPublic={isPublic}
          selectedSpace={selectedSpace}
        />
        <MasterSearch />
      </div>

      <GridContainer>
        <MainSectionList mainSectionList={activatedMainSections} />
      </GridContainer>

      <Modal
        open={modalState !== null}
        onClose={handleCloseModal}
        hasCloseIcon={canCloseModal}
        shouldCloseOnOverlayClick={canCloseModal}
        shouldCloseOnEsc={canCloseModal}
      >
        {modalState === "editSpace" ? (
          <EditSpaceDetailsWizard
            onUpdate={handleSpaceUpdated}
            isLoading={updatingSpace}
            selectedSpace={selectedSpace}
          />
        ) : null}
        {modalState === "viewSpace" ? (
          <ViewSpaceDetailsModal
            onClose={() => handleCloseModal()}
            imageUrl={selectedSpace?.image?.url}
          />
        ) : null}
        {modalState === "shareSpace" ? <AccessQRCode onClose={handleCloseModal} /> : null}
        {modalState === "postSpaceCreation" ? (
          <PostSpaceCreationModal
            spaceName={selectedSpace.name}
            onComplete={handleShowGetStartedSpaceModal}
          />
        ) : null}
        {modalState === "getStartedSpace" ? (
          <GetStartedSpaceModal
            onClickExploreAdmin={handleClickExploreAdmin}
            onClickInvitePeople={handleInvitePeople}
          />
        ) : null}
      </Modal>
    </InnerPageWrapper>
  );
});

export default Space;
