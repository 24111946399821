import { useForm } from "react-hook-form";

import { useUpdateApp } from "Hooks";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

interface AddTrustedEmailForm {
  emailDomain: string;
}

const mapToDefaultValues = (): AddTrustedEmailForm => {
  return {
    emailDomain: "",
  };
};

export const useAddTrustedEmail = () => {
  const { spaceId, relevantSpace } = useGetRelevantSpaceDetails();
  const { updateApp } = useUpdateApp();
  const queryClient = useQueryClient();

  const { control, handleSubmit, formState } = useForm<AddTrustedEmailForm>({
    defaultValues: mapToDefaultValues(),
  });

  const mapToInput = (values: AddTrustedEmailForm): string => {
    const trustedEmail = relevantSpace?.trustedEmail ?? "";

    if (trustedEmail === "adminAuthentication" || trustedEmail === "publicAccess") {
      // if no values provided fallback to adminAuthentication
      return values.emailDomain.trim().toLowerCase().replace(/@/g, "") || "adminAuthentication";
    } else {
      const mails = trustedEmail.split(";");
      mails.push(values.emailDomain);

      const emailAccess = mails
        .map((e: string) => e.trim().toLowerCase().replace(/@/g, ""))
        .join(";");

      return emailAccess || "adminAuthentication";
    }
  };

  const { mutate, error, isSuccess, isLoading } = useMutation({
    mutationKey: ["addTrustedEmail"],
    mutationFn: async (values: { emailDomain: string }) => {
      if (!spaceId) {
        throw new Error("Add trusted email: spaceId is null");
      }
      // @TODO: replace this with REST call. Currently PATCH endpoint on spaces service does not accept trustedEmail
      return updateApp({
        id: spaceId,
        trustedEmail: mapToInput(values),
      });
    },
    onSuccess: () => {
      if (spaceId) {
        queryClient.refetchQueries(spacesKeys.detail(spaceId));
      }
    },
  });

  return {
    error: error instanceof Error ? error.message : "",
    control,
    isLoading,
    isSuccessful: isSuccess,
    isDirty: formState.isDirty,
    onSubmit: handleSubmit((values) => mutate(values)),
  };
};
