import { useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Modal, NavButton } from "UIKit";
import { FileViewerComponent, useEolasFileViewer, VideoPlayer } from "Components";
import useAttachmentDownload from "modules/portfolio/data/useAttachmentDownload";
import { isVideoType } from "Pages/MeTab/pages/MyFiles/helpers";
import { AttachmentItem } from "modules/portfolio/types";
import useMyFileDownload from "modules/myFiles/data/useMyFileDownload";

export type DocumentRouteParams = {
  attachmentId: string;
};

export const AttachmentFileViewer: React.FC<RouteComponentProps<DocumentRouteParams>> = ({
  history: { goBack, length },
  match: {
    params: { attachmentId },
  },
}: RouteComponentProps<DocumentRouteParams>) => {
  const { getAttachment } = useAttachmentDownload();
  const { download } = useMyFileDownload();

  const { viewer, file, onFileLoaded, onFileError, setFile } =
    useEolasFileViewer<Pick<AttachmentItem, "id" | "blob" | "name">>();

  const fetchFileGetUrl = useCallback(async () => {
    const file = await getAttachment({ attachmentId });
    if (file) setFile(file);
  }, [attachmentId, getAttachment, setFile]);

  const handleDownloadOriginal = useCallback(() => {
    if (file) {
      download(file.id);
    }
  }, [download, file]);

  useEffect(() => {
    fetchFileGetUrl();
  }, [fetchFileGetUrl]);

  return (
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={goBack}>
      <div className="h-100vh w-100vw">
        {length > 1 && (
          <div className="bg-grey-light py-1">
            <div className="flex justify-center space-x-3">
              <NavButton onClick={goBack} />
              <Button size="sm" onClick={handleDownloadOriginal}>
                Download Original
              </Button>
            </div>
          </div>
        )}
        <div className="bg-grey-light overflow-auto h-full">
          {isVideoType(file?.blob?.type) && file?.blob?.getURL ? (
            <div className="p-4">
              <VideoPlayer
                url={file.blob.getURL}
                title={file.name}
                onLoad={onFileLoaded}
                onError={onFileError}
              />
            </div>
          ) : (
            viewer && <FileViewerComponent viewer={viewer} />
          )}
        </div>
      </div>
    </Modal>
  );
};
