import { useParams } from "react-router-dom";
import { useMemo } from "react";

export function useParamsDecoded<Params extends { [K in keyof Params]?: string }>(): Params {
  const params: Params = useParams<Params>();

  return useMemo(
    () =>
      Object.keys(params).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (prev, curr) => ({ ...prev, [curr]: decodeURIComponent((params as any)[curr]) }),
        {},
      ),
    [params],
  ) as Params;
}
