import {
  AllowedChooseFileTypes,
  Step,
  Wizard,
  useChooseFileTypeStep,
  useFormStep,
  useUploadProgressAndSuccessStep,
  useWizard,
} from "UIKit";
import { useTranslation } from "react-i18next";
import { DetailsFormValues, ExternalUrlFormValues, ManageSlotWizardStep } from "../types/general";
import { useManageSlotActions } from "../hooks/useManageSlotActions";
import useGetFormStepConfigs from "../hooks/useGetFormStepConfigs";
import { allowedImageFileExtensions } from "../data/allowedImageFileExtensions";
import { FilesIcon } from "Assets";
import { SponsoredInfoSection } from "@eolas-medical/core";
import { BaseModalProps } from "Hooks/useModal";
import { useMemo } from "react";
import { useEditFileStep } from "UIKit/Wizard/templates/EditFileStep";
import { getFileExtensionFromString } from "Utilities/helpers";

export const ManageSlotWizardModal = ({
  closeModal: onCloseModal,
  mainSectionId,
  existingSection,
}: {
  mainSectionId: string;
  existingSection?: SponsoredInfoSection;
} & BaseModalProps) => {
  const { t } = useTranslation();
  const { wizardControl } = useWizard<ManageSlotWizardStep>();

  const {
    wizardState,
    isAddingSponsoredSlot,
    isAddingSponsoredSlotSuccess,
    addSponsoredSlotError,
    addSponsoredSlotProgress,
    isEditingSponsoredSlot,
    isEditingSponsoredSlotSuccess,
    editSponsoredSlotError,
    editSponsoredSlotProgress,
    onAddDetails,
    onAddUrl,
    onAddImage,
    onAddSquareImage,
    onEditImage,
    onEditSquareImage,
    getNextStep,
    getPrevStep,
  } = useManageSlotActions({ existingSection, mainSectionId });

  const { mainFormStep, externalUrlFormStep } = useGetFormStepConfigs(existingSection);

  const defaultFormDetails = useMemo(() => {
    if (!existingSection) {
      return undefined;
    }
    const details = {
      name: wizardState.name,
      description: wizardState.description,
      type: wizardState.type,
      jobTitles: wizardState.jobTitles,
      seniorities: wizardState.seniorities,
      specialties: wizardState.specialties,
      countries: wizardState.countries,
      isPaid: wizardState.isPaid,
    };
    return details;
  }, [existingSection, wizardState]);

  const defaultUrlValues = useMemo(() => {
    if (!existingSection) {
      return undefined;
    }
    return {
      externalUrl: wizardState.externalUrl,
    };
  }, [existingSection, wizardState]);

  const detailsStep = useFormStep<"form-details", DetailsFormValues>({
    stepName: "form-details",
    title: t("knowledge_sponsored_slot_add_details_title"),
    formItems: mainFormStep,
    backLabel: t("general_cancel"),
    onSubmit: onAddDetails,
    onPrev: onCloseModal,
    defaultValues: defaultFormDetails,
  });

  const urlStep = useFormStep<"input-url", ExternalUrlFormValues>({
    stepName: "input-url",
    title: t("knowledge_sponsored_slot_add_url_title"),
    formItems: externalUrlFormStep,
    backLabel: t("general_back"),
    onSubmit: onAddUrl,
    defaultValues: defaultUrlValues,
  });

  const allowedTypes: AllowedChooseFileTypes[] = [
    { type: "blob", disabled: false, icon: FilesIcon, label: t("filePicker_blob") },
  ];

  const chooseBannerImageStep = useChooseFileTypeStep<"banner-select">({
    stepName: "banner-select",
    title: t("knowledge_sponsored_slot_add_banner_image"),
    allowedTypes,
    onSubmit: onAddImage,
    fileFormLabel: t("knowledge_sponsored_slot_add_banner_image_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    cancelLabel: t("general_back"),
    submitButtonLabel: t("addSection_wizard_next_button"),
    allowedFileExtensionsOverride: allowedImageFileExtensions,
    shouldSubmitLinkAsDraft: false,
  });

  const chooseSquareBannerImageStep = useChooseFileTypeStep<"square-banner-select">({
    stepName: "square-banner-select",
    title: t("knowledge_sponsored_slot_add_square_banner_image"),
    allowedTypes,
    onSubmit: onAddSquareImage,
    fileFormLabel: t("knowledge_sponsored_slot_add_square_banner_image_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    cancelLabel: t("general_back"),
    submitButtonLabel: t("general_submit"),
    allowedFileExtensionsOverride: allowedImageFileExtensions,
    shouldSubmitLinkAsDraft: false,
  });

  const editBannerImageStep = useEditFileStep<"banner-select">({
    stepName: "banner-select",
    filePickerValue: `banner.${getFileExtensionFromString(
      existingSection?.sponsoredInfo.bannerUrl ?? ".png",
    )}`,
    title: t("knowledge_sponsored_slot_edit_banner_image"),
    onSubmit: onEditImage,
    allowedFileExtensionsOverride: allowedImageFileExtensions,
    fileFormLabel: t("knowledge_sponsored_slot_add_banner_image_label"),
    nextLabel: t("addSection_wizard_next_button"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    fileDescriptionText: t("filePicker_helper_text"),
  });

  const editSquareBannerImageStep = useEditFileStep<"square-banner-select">({
    stepName: "square-banner-select",
    filePickerValue: `square-banner.${getFileExtensionFromString(
      existingSection?.sponsoredInfo.squareBannerUrl ?? ".png",
    )}`,
    title: t("knowledge_sponsored_slot_edit_square_banner_image"),
    onSubmit: onEditSquareImage,
    allowedFileExtensionsOverride: allowedImageFileExtensions,
    fileFormLabel: t("knowledge_sponsored_slot_add_square_banner_image_label"),
    nextLabel: t("general_submit"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    fileDescriptionText: t("filePicker_helper_text"),
  });

  let fileUploadSuccessText = isAddingSponsoredSlotSuccess
    ? t("knowledge_sponsored_slot_add_banner_success")
    : "";

  if (existingSection) {
    fileUploadSuccessText = isEditingSponsoredSlotSuccess
      ? t("knowledge_sponsored_slot_edit_banner_success")
      : "";
  }

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress-end">({
    stepName: "upload-progress-end",
    fileUploading: isAddingSponsoredSlot || isEditingSponsoredSlot,
    uploadProgress: existingSection ? editSponsoredSlotProgress : addSponsoredSlotProgress,
    fileUploadedSuccess: isAddingSponsoredSlotSuccess || isEditingSponsoredSlotSuccess,
    onFinishUpload: onCloseModal,
    fileUploadError:
      addSponsoredSlotError || editSponsoredSlotError
        ? t("knowledge_sponsored_slot_add_banner_error")
        : "",
    fileUploadSuccessText,
  });

  const steps: Step<ManageSlotWizardStep>[] = [
    detailsStep,
    urlStep,
    existingSection ? editBannerImageStep : chooseBannerImageStep,
    existingSection ? editSquareBannerImageStep : chooseSquareBannerImageStep,
    uploadProgressAndSuccessStep,
  ];

  return (
    <Wizard
      steps={steps}
      wizardControl={wizardControl}
      getNextStep={getNextStep}
      getPrevStep={getPrevStep}
      showAltSubmit={(step) => step === "square-banner-select" && !existingSection}
      altSubmitLabel={t("knowledge_sponsored_slot_submit_draft")}
    />
  );
};
