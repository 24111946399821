import { KnowledgeContentRepository } from "Pages/Knowledge/pages/KnowledgeContent";
import { sponsoredSlotsAnalyticsCallbacks } from "./config/analyticsCallbacks";

export const SponsoredSlotsContentRepository = () => {
  return (
    <KnowledgeContentRepository
      {...sponsoredSlotsAnalyticsCallbacks}
      domain="sponsoredSlotsContent"
      hasCopilotData={false}
    />
  );
};
