import {
  KnowledgeContentDomains,
  getKnowledgeContentDomainMainSectionId,
  knowledgeContentClient,
} from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const useGetClinicalQuestionsSectionId = () => {
  const { flags } = useLaunchDarkly();

  const mainSectionId = getKnowledgeContentDomainMainSectionId("clinicalQuestions");

  const enabled =
    Boolean(mainSectionId) && (flags[LDFlagNames.SHOW_EOLAS_CLINICAL_QUESTION_GENERATOR] ?? false);

  const { data } = useQuery({
    queryKey: ["clinicalQuestionsTopLevelSections"],
    queryFn: () =>
      knowledgeContentClient.getSectionsByParentId({
        parentId: mainSectionId ?? "",
        domain: KnowledgeContentDomains.clinicalQuestions,
      }),
    select: (queryData): string | null => {
      return queryData.sections[0]?.id ?? null;
    },
    enabled,
  });

  return { sectionId: data ?? null };
};
