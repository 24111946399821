import { useTranslation } from "react-i18next";
import { useRef } from "react";
import * as DOMPurify from "dompurify";

import { NormalStepComponentProps, NormalStepData } from "../../../types";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { makeMutableWizardState } from "../../../functions/makeMutableWizardState";
import { useEditFlashcardContent } from "./hooks/useEditFlashcardContent";
import { FlashcardEditor } from "./components/FlashCardEditor";

import { FlashcardMetadata, NewContentType } from "@eolas-medical/core";
import { toAwsJSON } from "Utilities";

const EditFlashcardContent = ({
  wizardState,
  sectionId,
  onClickBack,
  onClickForward,
}: NormalStepComponentProps) => {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const { handleSubmit, control } = useEditFlashcardContent(wizardState);

  const { t } = useTranslation();

  return (
    <EolasWizardModalWrapper
      modalWrapperClassName="max-w-6xl h-90vh"
      ref={modalBodyRef}
      header={{
        title: wizardState.fileId ? t("flashcards_edit_page_title") : t("flashcards_page_title"),
      }}
      buttonRowProps={{
        previousButton: { onClick: onClickBack },
        forwardButton: {
          onClick: handleSubmit((values) => {
            const newState = makeMutableWizardState(wizardState);
            const sanitised = DOMPurify.sanitize(values.content);
            const metadataWithoutManifest: FlashcardMetadata = {
              flashcardContent: encodeURIComponent(sanitised),
            };
            if (!newState.isExistingFileWithNewType) {
              newState.dto.metadata = toAwsJSON(metadataWithoutManifest);
            } else if (newState.dto.newType === NewContentType.FLASHCARD) {
              newState.dto.newProperties.metadata = toAwsJSON(metadataWithoutManifest);
            }
            onClickForward(newState);
          }),
        },
      }}
    >
      <FlashcardEditor
        modalBodyRef={modalBodyRef}
        state={wizardState}
        sectionId={sectionId}
        control={control}
      />
    </EolasWizardModalWrapper>
  );
};

export const editFlashcardContentData: NormalStepData = {
  StepComponent: EditFlashcardContent,
  props: {
    getPreviousStepName: () => "flashcardDetails",
    getNextStepName: () => "selectFlashcardHeroImage",
  },
};
