import { DrugInfo } from "@eolas-medical/core";
import BnfDrugInfo from "./BnfDrugInfo";
import { BnfCollapsible, InfoIdProps } from "../BnfCollapsible/BnfCollapsible";

type InfoContentComponentProps = {
  section: DrugInfo;
} & InfoIdProps;

const DrugInfoContentComponent = ({ section, ...rest }: InfoContentComponentProps) => {
  return (
    <BnfCollapsible title={section.potName} {...rest}>
      <BnfDrugInfo info={section} />
    </BnfCollapsible>
  );
};

export default DrugInfoContentComponent;
