import { communitySubsectionBaseNames } from "@eolas-medical/core";

export const titleLookup = {
  bnf: {
    guidanceItems: communitySubsectionBaseNames.guidance_adult,
    treatmentSummaryItems: communitySubsectionBaseNames.treatment_summaries_adult,
  },
  bnfc: {
    guidanceItems: communitySubsectionBaseNames.guidance_child,
    treatmentSummaryItems: communitySubsectionBaseNames.treatment_summaries_child,
  },
} as const;
