import { KnowledgeContentDomain } from "@eolas-medical/core";
import { useContext, createContext } from "react";

type InfoContext = { domain: KnowledgeContentDomain; isUserAdmin: boolean };

const defaultContext: InfoContext = {
  domain: "smpcs",
  isUserAdmin: false,
};

export const KnowledgeContentInfoContext = createContext<InfoContext>(defaultContext);

export const useKnowledgeContentInfoContext = () => {
  return useContext(KnowledgeContentInfoContext);
};
