import { observer } from "mobx-react-lite";
import { validate as uuidValidate } from "uuid";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import {
  AnalyticsEvents,
  AppLevelSection,
  DeeplinkResolvedPayload,
  FileExtensionMediaType,
  OrganisationLevelSection,
  fileStore,
  isEolasShadowCopyFile,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import { useTranslation } from "react-i18next";
import { InnerPageWrapper, PageTitle, Text, Button } from "UIKit";
import useUserSpaces from "modules/spaces/data/useUserSpaces";
import FlashcardViewer from "./components/FlashcardViewer/FlashcardViewer";
import VideoViewer from "./components/VideoViewer/VideoViewer";
import { isVideoType } from "Utilities/fileHelpers";
import {
  getSearchParams,
  removeBracketsAndPercent,
  waitForFileStoreHydration,
} from "Utilities/helpers";
import { useEffect, useState } from "react";
import EolasEditorViewer from "./components/EolasEditorViewer/EolasEditorViewer";
import { convertToLink } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";
import { BaseAppRoute } from "Utilities/types";
import { LinkedDocumentsNoAccess } from "./components/LinkedDocumentsNoAccess/LinkedDocumentsNoAccess";
import { trackEvent } from "API/Analytics";
import { LinkedDocumentsLoader } from "./components/KnowledgeLinkedDocumentsNewTab/LinkedDocumentsLoader";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { useGetSpaceOrgOwnerIds } from "./hooks/useGetSpaceOrgOwnerIds";
import { NestedDsmViewer } from "shared/pages/ContentViewer/DsmViewer/DsmViewer";
import { getPathToItemOrSection } from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

export const LinkedDocumentsViewer = observer(() => {
  const [isHydrated, setIsHydrated] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { userSpaces, userSpacesLoading } = useUserSpaces(userStore.userID);
  const { isAdmin: isInAdminMode } = useSpacesContext();
  const { showNotification } = useNotifications();
  const { appID: currentSpaceId } = sectionStore;
  const {
    organisationId,
    spaceId,
    isLoading: isFetchingOwnerIds,
    didError: didFailGettingOwnerIds,
  } = useGetSpaceOrgOwnerIds();

  const { fileId, checklistId, pageNo, sectionId } = getSearchParams({
    searchString: location.search,
    paramNameList: ["fileId", "checklistId", "pageNo", "sectionId"],
  });

  const { onSelectSpace } = useSelectSpaceActions(
    undefined,
    `${location.pathname}${location.search}`,
  );

  useEffect(() => {
    const hydrateStore = async () => {
      await waitForFileStoreHydration();
      setIsHydrated(true); // Set hydration complete once the promise resolves
    };
    hydrateStore();
  }, [currentSpaceId]); // run only if current space changed

  // Loading state in case waiting for file store hydration
  if (!isHydrated || userSpacesLoading || isFetchingOwnerIds) {
    return <LinkedDocumentsLoader />;
  }
  if (didFailGettingOwnerIds) {
    return (
      <InnerPageWrapper>
        <div className="flex flex-col justify-start items-center flex-1 pt-16">
          <PageTitle title={t("linked_documents_viewer_error")} />
          <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
        </div>
      </InnerPageWrapper>
    );
  }

  const alternativeSpace = organisationId
    ? userStore.userDepartments.find((d) => d.organisationID === organisationId)
    : userSpaces.find((s) => s?.id === spaceId && s.id !== currentSpaceId);

  const spaceOrOrgId = spaceId || organisationId;

  if (spaceId !== currentSpaceId && organisationId !== sectionStore.organisationID) {
    if (alternativeSpace) {
      // Case 1 - document in another space user has access to
      return (
        <InnerPageWrapper>
          <div
            className="flex flex-col justify-start items-center flex-1 pt-24 text-center"
            data-testid="portfolio-error-fallback"
          >
            <Text level={1} className="mb-24 text-lg">
              {t("linked_documents_viewer_not_in_correct_space", {
                spaceName: alternativeSpace.name,
              })}
            </Text>
            <Button size="xl" onClick={() => onSelectSpace(alternativeSpace.id)}>
              {t("linked_documents_viewer_switch_to_space_button", {
                spaceName: alternativeSpace.name,
              })}
            </Button>
          </div>
        </InnerPageWrapper>
      );
    } else if (spaceOrOrgId) {
      // Case 2 - user has no access to space that hosts this document
      return (
        <LinkedDocumentsNoAccess
          ownerId={spaceOrOrgId}
          ownerType={spaceId ? "space" : "organisation"}
        />
      );
    }
  }

  if (spaceId === currentSpaceId || organisationId === sectionStore.organisationID) {
    // Case 3 - user is logged in to space with access to this document
    const maybeFile = fileId ? fileStore.getFile(fileId) : null;
    const file =
      maybeFile && isEolasShadowCopyFile(maybeFile)
        ? getOriginalFromShadowCopy(maybeFile)
        : maybeFile;
    const singleChecklist = checklistId
      ? sectionStore.getSingleChecklistTemplate(checklistId)
      : null;
    const section = sectionId ? sectionStore.getSection(sectionId) : null;
    const mainSectionId = file?.mainSectionID;
    const mainSectionType = mainSectionId
      ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionId)
      : null;
    const mainSectionIdentity = mainSectionId
      ? sectionStore.getMainSectionIdentityByMainSectionId(mainSectionId)
      : null;
    const currentSpaceName = userSpaces.find((s) => s.id === currentSpaceId)?.name;

    // This is shameful and im sorry but unless we refactor entirely how this component fetches files, its the best way (that i can think of)
    // to check that the file store has updated after changing spaces/organisations
    const firstFileInStore = fileStore.filesList.find((file) => !uuidValidate(file.ownerID));
    const firstFileOwnerId = firstFileInStore
      ? sectionStore.getSection(firstFileInStore.mainSectionID)?.ownerID
      : null;

    if (mainSectionType === AppLevelSection.patientLeaflets && currentSpaceName && fileId) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: fileId,
        type: "file",
        mainSectionType,
      });

      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.patientLeaflets}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }

    if (
      file &&
      file.parentID &&
      mainSectionType === OrganisationLevelSection.orgPatientLeaflets &&
      currentSpaceName &&
      fileId
    ) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: fileId,
        type: "file",
        mainSectionType,
      });

      const sectionId = file.parentID;
      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.organisation}/${currentSpaceName}/${OrganisationLevelSection.orgPatientLeaflets}/${sectionId}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }

    if (section && currentSpaceName) {
      const pathToLocation = getPathToItemOrSection({
        id: section.id,
        isInAdminMode,
        encodedSelectedSpaceName: encodeURIComponent(
          removeBracketsAndPercent(currentSpaceName) || "",
        ),
      });

      const canNavigateToSection = typeof pathToLocation === "string";

      if (canNavigateToSection) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: section.id,
          type: "section",
          mainSectionType: "genericContentRepository",
        });
        return (
          <Redirect
            to={{
              pathname: pathToLocation,
            }}
          />
        );
      }
    }

    if (singleChecklist && currentSpaceName && singleChecklist.parentID) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: singleChecklist.id,
        type: "file",
        mainSectionType: AppLevelSection.checklists,
      });
      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${
              AppLevelSection.checklists
            }/${encodeURIComponent(singleChecklist.parentID)}/details/${encodeURIComponent(
              singleChecklist.id,
            )}`,
          }}
        />
      );
    }
    if (file && file.parentID && mainSectionType === OrganisationLevelSection.hospitalContacts) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: file.id,
        type: "file",
        mainSectionType,
      });
      const sectionId = file.parentID;
      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.organisation}/${sectionStore.organisationName}/${OrganisationLevelSection.hospitalContacts}/${sectionId}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }
    if (file && file.parentID && mainSectionType === AppLevelSection.contacts) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: file.id,
        type: "file",
        mainSectionType,
      });
      const sectionId = file.parentID;
      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.contacts}/${sectionId}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }
    if (file && mainSectionType === OrganisationLevelSection.communicationPortal) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: file.id,
        type: "file",
        mainSectionType,
      });

      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.organisation}/${sectionStore.organisationName}/${OrganisationLevelSection.communicationPortal}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }

    if (file && mainSectionType === AppLevelSection.newsFeed) {
      trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
        entityId: file.id,
        type: "file",
        mainSectionType,
      });

      return (
        <Redirect
          to={{
            pathname: `/${BaseAppRoute.spaces}/${currentSpaceName}/${AppLevelSection.newsFeed}`,
            search: `?fileId=${fileId}`,
          }}
        />
      );
    }

    if (file?.type === "flashcard") {
      // should always be true
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return (
        <FlashcardViewer
          mainSectionId={file.mainSectionID}
          flashcardId={file.id}
          backBehaviour="goHome"
        />
      );
    }

    if (file?.type === "dsm") {
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return (
        <NestedDsmViewer
          mainSectionId={file.mainSectionID}
          contentItemId={file.id}
          backBehaviour="goHome"
          shadowCopyContentId={null}
          fileIdOfDraftFile={null}
        />
      );
    }

    if (file?.type === "eolas-editor") {
      // should always be true
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return (
        <EolasEditorViewer
          mainSectionId={file.mainSectionID}
          contentItemId={file.id}
          backBehaviour="goHome"
        />
      );
    }

    if (file?.type === "link") {
      // should always be true
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return (
        <InnerPageWrapper>
          <div
            className="flex flex-col justify-start items-center flex-1 pt-24 text-center"
            data-testid="portfolio-error-fallback"
          >
            <Text level={1} className="mb-16 text-lg">
              {t("linked_documents_viewer_external_link_text")}
            </Text>
            <Button
              size="xl"
              onClick={() => {
                if (file.key) {
                  const safeUrl = convertToLink(file.key);
                  window.open(safeUrl, "_blank");
                } else {
                  errorStore.captureError({
                    error: "File key is undefined or null",
                    source: "user",
                  });
                  showNotification({
                    type: "error",
                    description: t("linked_documents_viewer_external_link_error"),
                    autoHideTimeout: 10000,
                  });
                }
              }}
            >
              {t("linked_documents_viewer_external_link_button")}
            </Button>
            <Text className="mt-4" level={1}>
              {t("filePicker_or")}
            </Text>

            <Link
              to={
                organisationId
                  ? `/${BaseAppRoute.organisation}/${sectionStore.organisationName}`
                  : `/${BaseAppRoute.spaces}`
              }
              className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-4"
            >
              {t("take_me_home")}
            </Link>
          </div>
        </InnerPageWrapper>
      );
    }
    if (file && isVideoType(file?.type as FileExtensionMediaType)) {
      // should always be true
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return <VideoViewer video={file} backBehaviour="goHome" />;
    }

    if (file && isHydrated) {
      // should always be true
      if (mainSectionType && mainSectionIdentity) {
        trackEvent<DeeplinkResolvedPayload>(AnalyticsEvents.DEEPLINK_RESOLVED, {
          entityId: file.id,
          type: "file",
          mainSectionType: mainSectionType ?? mainSectionIdentity,
        });
      }
      return (
        <DocumentViewer
          document={file}
          backBehaviour="goHome"
          startingPage={pageNo ? parseInt(pageNo, 10) : undefined}
        />
      );
    }

    // This is related to the shameful code above - if the first file in the store is not owned by the current space/org
    // then its most likely that the file store has not updated after changing spaces/organisations as we are inside the condition above
    if (firstFileOwnerId !== spaceOrOrgId) {
      return <LinkedDocumentsLoader />;
    }

    return (
      <InnerPageWrapper>
        <div
          className="flex flex-col justify-start items-center flex-1 pt-16"
          data-testid="portfolio-error-fallback"
        >
          <PageTitle title={t("linked_documents_viewer_error_no_file")} />
          <Text level={1}>{t("linked_documents_viewer_error_no_file_desc")}</Text>
          <Link
            to={
              organisationId
                ? `/${BaseAppRoute.organisation}/${sectionStore.organisationName}`
                : `/${BaseAppRoute.spaces}`
            }
            className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
          >
            {t("take_me_home")}
          </Link>
        </div>
      </InnerPageWrapper>
    );
  }

  // Default case
  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-start items-center flex-1 pt-16">
        <PageTitle title={t("linked_documents_viewer_error")} />
        <Text level={1}>{t("linked_documents_viewer_unexpected_state")}</Text>
      </div>
    </InnerPageWrapper>
  );
});
