import { Text, Title } from "../../Typography";
import { ModalHeader, InnerModalWrapper } from "../components";
import { AttentionIcon, CheckIcon, NoResultsIcon, SearchIcon, SendEmailIcon } from "Assets";
import { Pill } from "UIKit/Pill";
import { Button } from "UIKit/Button";
import { useForm } from "react-hook-form";
import { FormElement, Input, Toggle } from "UIKit/FormElements";
import { format } from "date-fns";
import { NewsFeed } from "Pages/Spaces/pages/Space/pages/MiniApp/NewsFeed/types";
import AvatarList from "UIKit/AvatarList/AvatarList";
import { FileTypeItemIcon } from "UIKit/FileTypeIcon";
import { SupportedFileType } from "@eolas-medical/core";
import { useState } from "react";
import useContentItemAnalytics, {
  ContentItemAnalytics,
} from "modules/contentRepository/data/useGetContentItemAnalytics";
import useFilteredAudienceUsers from "./helpers";
import { SuccessModal } from "../modals";
import { Loader } from "UIKit/Loader";

interface ItemAudienceModal {
  // TODO:when implementing communication portal, use a generic type for both
  item: NewsFeed;
  canSendReminder: boolean;
  printText?: string;
  sendReminderText?: string;
  confirmReminderText?: string;
  cancelText?: string;
  sendText?: string;
  readText?: string;
  unreadText?: string;
  reminderSentText?: string;
  searchPlaceholderText?: string;
  showUnreadText?: string;
  informationText?: string;
  noResultsMessage?: string;
  onPrintReport: ({
    contentItemAnalytics,
    contentItem,
  }: {
    contentItem: NewsFeed;
    contentItemAnalytics?: ContentItemAnalytics;
  }) => void;
  onSendReminder: (id: string) => void;
  isLoading?: boolean;
  isSuccess: boolean;
  isDownloadingReport: boolean;
  onCompleteSuccess: () => void;
}
const ItemAudienceModal = ({
  item,
  printText = "Print Report",
  sendReminderText = "Send Read Reminder*",
  searchPlaceholderText = "Search by name or email...",
  showUnreadText = "Show Unread",
  informationText = "*This will send to only those who haven’t read this item",
  confirmReminderText = "This will send a push notification and email reminder to all users who haven’t opened this news item",
  reminderSentText = "Read Reminder has been successfully sent!",
  cancelText = "Cancel",
  sendText = "Send",
  readText = "Read",
  unreadText = "Unread",
  noResultsMessage = "Sorry, no results found",
  onPrintReport,
  onSendReminder,
  isLoading = false,
  isSuccess,
  onCompleteSuccess,
  isDownloadingReport,
  canSendReminder,
}: ItemAudienceModal) => {
  const [showConfirmReminder, setShowConfirmReminder] = useState(false);
  const { contentItemAnalytics, isLoadingContentItemAnalytics } = useContentItemAnalytics({
    mainSectionId: item.mainSectionID,
    contentId: item.id,
  });

  const { userCompletion, totalUnread, ...itemAnalytics } = contentItemAnalytics;

  const { name, createdBy, createdAt, type } = item;

  const { control, setValue, watch } = useForm({
    defaultValues: {
      searchText: "",
      showUnread: false,
    },
  });

  const handleShowUnreadToggle = (showUnread: boolean) => {
    setValue("showUnread", showUnread);
  };

  const searchText = watch("searchText");
  const showUnread = watch("showUnread");

  const filteredAudienceUsers = useFilteredAudienceUsers({
    userList: userCompletion,
    searchText,
    showUnread,
  });

  const renderRightComponent = ({ isRead }: { isRead: boolean }) => {
    const status = isRead ? readText : unreadText;
    return (
      <Pill
        value={status}
        className={`font-bold text-xs ${
          isRead ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500"
        }`}
        valueClassname="text-3xs sm:text-2xs"
        icon={
          isRead ? (
            <CheckIcon className="h-1.5 w-1.5  sm:h-2 sm:w-2 text-green-400" />
          ) : (
            <AttentionIcon className="h-1.5 w-1.5  sm:h-2 sm:w-2 text-red-500" />
          )
        }
      />
    );
  };

  const renderUsersList = () => {
    return (
      <div className="flex flex-col space-4 w-full min-h-20vh">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-y-4 ">
          <FormElement required id="searchText" control={control} className="w-full sm:w-4/6">
            {(fieldProps) => {
              const { value, ...rest } = fieldProps;
              return (
                <Input
                  {...rest}
                  className="input h-10 pl-4 box-content rounded-3xl"
                  placeholder={searchPlaceholderText}
                  iconLeft={<SearchIcon />}
                />
              );
            }}
          </FormElement>
          {canSendReminder && (
            <FormElement required id="showUnread" control={control}>
              {(fieldProps) => {
                const { value, ...rest } = fieldProps;
                return (
                  <Toggle
                    {...rest}
                    label={showUnreadText}
                    checked={value}
                    labelPosition="left"
                    onChange={handleShowUnreadToggle}
                  />
                );
              }}
            </FormElement>
          )}
        </div>
        {filteredAudienceUsers && filteredAudienceUsers?.length > 0 ? (
          <AvatarList
            usersList={filteredAudienceUsers}
            rightComponent={renderRightComponent}
            isLoading={isLoadingContentItemAnalytics}
          />
        ) : searchText.trim() !== "" ? (
          <div
            className="flex flex-col items-center justify-center space-y-2"
            data-testid="master-search-no-results"
          >
            <NoResultsIcon />
            <Title level={9} className="text-grey-700">
              {noResultsMessage}
            </Title>
          </div>
        ) : (
          <Loader />
        )}

        <div className="flex justify-center items-center space-x-2 mt-8">
          <Button
            size="sm"
            iconLeft="PrintIcon"
            variant="outline"
            data-testid="print-report-button"
            isLoading={isDownloadingReport}
            disabled={isLoadingContentItemAnalytics}
            onClick={() =>
              onPrintReport({ contentItemAnalytics: itemAnalytics, contentItem: item })
            }
          >
            <Text level={2} className="font-bold ml-2">
              {printText}
            </Text>
          </Button>
          <Button
            size="sm"
            data-testid="confirm-reminder-button"
            disabled={isLoadingContentItemAnalytics || !canSendReminder}
            iconLeft="ShareIcon"
            onClick={() => setShowConfirmReminder(true)}
          >
            <Text level={2} className="font-bold ml-2 text-center">
              {sendReminderText}
            </Text>
          </Button>
        </div>
        <div className="flex justify-center items-center mx-auto text-center">
          <Text level={3} className="text-blue-500">
            {informationText}
          </Text>
        </div>
      </div>
    );
  };

  if (isSuccess) {
    return <SuccessModal onComplete={onCompleteSuccess} text={reminderSentText} />;
  }

  const renderConfirmReminder = () => {
    return (
      <div className="space-y-4">
        <div className="flex flex-col w-2/3 mx-auto justify-center items-center">
          <SendEmailIcon className="h-24 w-24" />
          <Title level={9} className="mx-auto text-center">
            {confirmReminderText}.
          </Title>
        </div>
        <div className="flex justify-center items-center mx-auto space-x-2 w-full md:w-2/4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowConfirmReminder(false)}
            className="w-full"
          >
            {cancelText}
          </Button>
          <Button
            iconLeft="ShareIcon"
            data-testid="send-reminder-button"
            size="sm"
            isLoading={isLoading}
            onClick={() => onSendReminder(item.id)}
            className="w-full"
          >
            <Text level={2} className="font-bold ml-2 text-center">
              {sendText}
            </Text>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col justify-start items-start p-10 border-b border-b-grey-300">
        <div className="flex">
          <FileTypeItemIcon type={type as SupportedFileType} className="w-8 h-8 mr-1" />
          <Title level={5}>{name}</Title>
        </div>
        <Text level={2} className="text-grey-600">
          {createdBy}
        </Text>
        <Text level={2} className="text-grey-600 mb-8">
          {createdAt ? format(new Date(createdAt), "do MMMM yyyy") : null}
        </Text>
      </ModalHeader>

      <div
        className="flex flex-col
        p-6 sm:p-8 bg-grey-50 max-h-80vh overflow-y-auto"
      >
        {showConfirmReminder ? renderConfirmReminder() : renderUsersList()}
      </div>
    </InnerModalWrapper>
  );
};

export default ItemAudienceModal;
