import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useRequestStatus } from "Utilities";
import { EolasChecklistSection, LDFlagNames } from "Utilities/types";
import { contentClient, eolasLogger, hasStringProp } from "@eolas-medical/core";
import { UPDATE_SECTION_INFO } from "Pages/Spaces/components/SectionManagement";
import { useLaunchDarkly } from "Contexts";
import { useRefetchAppData } from "Hooks";

export const useDeactivateChecklist = (checklist: EolasChecklistSection, isDisabled: boolean) => {
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const [updateSectionInfo] = useMutation(UPDATE_SECTION_INFO);
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const onDeactivate = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    const updateSectionPromise = useAppServicesEndpoints
      ? updateSectionInfo({
          variables: {
            parentID: checklist.parentID,
            input: {
              id: checklist.id,
              disabled: isDisabled ? "false" : "true",
            },
          },
        })
      : contentClient.toggleMainSection({
          disabled: isDisabled ? false : true,
          id: checklist.id,
        });
    updateSectionPromise
      .then(async () => {
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        eolasLogger.error(error);
        setRequestStatus({
          status: "error",
          error: hasStringProp(error, "message") ? error.message : "unknown error",
        });
      });
  }, [
    checklist.id,
    checklist.parentID,
    isDisabled,
    setRequestStatus,
    updateSectionInfo,
    useAppServicesEndpoints,
    refetch,
  ]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDeactivate,
  };
};
