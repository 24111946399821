import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { ContentSection } from "modules/contentRepository/types";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

const useDeleteKnowledgeContentSection = ({ domain }: { domain: KnowledgeContentDomain }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, error, reset } = useMutation(
    (contentSection: ContentSection) => knowledgeContentClient.deleteSection(contentSection.id),
    {
      onSuccess: () => {
        reset();
        queryClient.invalidateQueries(knowledgeContentKeys.contentSections(domain));
      },
    },
  );

  return {
    deleteContentSection: mutate,
    deletingContentSection: isLoading,
    deleteContentSectionSuccess: isSuccess,
    deleteContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useDeleteKnowledgeContentSection;
