import { useTranslation } from "react-i18next";
import { ButtonRowProps, EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";

export const FirstStepWrapper = ({
  selectTypeComponent,
  children,
  fileId,
  buttonRowProps,
}: {
  selectTypeComponent: JSX.Element;
  children: React.ReactNode;
  fileId: string | null;
  buttonRowProps: Pick<ButtonRowProps, "altForwardButton" | "forwardButton">;
}) => {
  const { t } = useTranslation();
  return (
    <EolasWizardModalWrapper
      header={{ title: fileId ? t("repository_edit_item_title") : t("repository_add_item_title") }}
      buttonRowProps={buttonRowProps}
    >
      {selectTypeComponent}
      {children}
    </EolasWizardModalWrapper>
  );
};
