import { overrideTailwindClasses as tw } from "tailwind-override";

export interface InnerModalWrapperProps {
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
}

export const InnerModalWrapper: React.FC<InnerModalWrapperProps> = ({
  children,
  className,
  "data-testid": dataTestId,
}: InnerModalWrapperProps) => {
  return (
    <div
      className={tw(`
        flex flex-col bg-white shadow-xl
        rounded-b-none rounded-t-sm md:rounded-b-lg md:rounded-t-lg
        max-h-90vh w-90vw max-w-3xl h-auto relative overflow-hidden ${className}
      `)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
