import { useMutation } from "@tanstack/react-query";
import { UpdateMainSectionDto, contentClient } from "@eolas-medical/core";
import { useRefetchAppData } from "Hooks";

const useEditMainSection = () => {
  const { refetch } = useRefetchAppData();
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (mainSection: UpdateMainSectionDto) => {
      return contentClient.updateMainSection(mainSection);
    },
    {
      onSuccess: refetch,
    },
  );

  return {
    editMainSection: mutate,
    editingMainSection: isLoading,
    editMainSectionSuccess: isSuccess,
    editMainSectionError: error instanceof Error ? error.message : "",
  };
};

export default useEditMainSection;
