import { Space, SpaceAccessType, spacesClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";
import { useMemo } from "react";
import { mapSpecialtyNames } from "../helpers";

interface UseUserSpaces {
  userSpaces: Space[];
  userSpacesLoading: boolean;
  userSpacesError?: string;
}

const useUserSpaces = (userId: string): UseUserSpaces => {
  const { data, isLoading } = useQuery(
    spacesKeys.list(),
    async () => {
      const response = await spacesClient.listUserSpaces({ userId });
      return response.data;
    },
    {
      enabled: !!userId,
      retry: false,
    },
  );

  const userSpaces: Space[] = useMemo(() => {
    if (!data || !data.userSpaces) {
      return [];
    }

    return data.userSpaces
      .map((s) => {
        const specialties: string[] = (s?.specialty || [])[0]?.split(",") || [];

        return {
          id: s?.id,
          name: s?.name || "",
          description: s?.description || "",
          accessType: "private" as SpaceAccessType,
          userCount: s?.userCount || 1,
          specialty: s?.specialty ? mapSpecialtyNames(specialties) : [],
          image: {
            isCustomImage: !!s?.image?.url,
            url: s?.image?.url || s?.image?.logo || "",
          },
          accessTokens: [],
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  return {
    userSpaces,
    userSpacesLoading: isLoading,
    userSpacesError: undefined,
  };
};

export default useUserSpaces;
