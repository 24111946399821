import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { FormElement, Textarea } from "UIKit";
import { SummaryOfChangesValues } from "./types";
import { useTranslation } from "react-i18next";
import { MAX_SUMMARY_OF_CHANGES_LENGTH } from "shared/constants";

interface SummaryOfChangesStepProps {
  control: Control<SummaryOfChangesValues>;
  errors?: FieldErrors<SummaryOfChangesValues>;
}

export const SummaryOfChangesStep = ({ control, errors }: SummaryOfChangesStepProps) => {
  const { t } = useTranslation();

  return (
    <form>
      <FormElement
        id="summaryOfChanges"
        control={control}
        label={t("repository_edit_item_summary_of_changes")}
        errorMessage={errors?.summaryOfChanges?.message}
      >
        {(field) => <Textarea {...field} characterLimit={MAX_SUMMARY_OF_CHANGES_LENGTH} />}
      </FormElement>
    </form>
  );
};
