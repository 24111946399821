import { FileTypeItemIcon, IconButton } from "UIKit";
import React from "react";
import { NewsFeed } from "../../../../types";
import { format } from "date-fns";
import { AudienceIcon, DeleteIcon, PiechartIcon, StarIcon, StarOutlineIcon } from "Assets";
import { DownloadFileButton } from "Components";
import { motion } from "framer-motion";
import { SupportedFileType } from "@eolas-medical/core";

type AdminNewsFeedTileProps = {
  newsFeed: NewsFeed;
  onClick: (newsFeed: NewsFeed) => void;
  onDelete: (newsFeed: NewsFeed) => void;
  onHighlight: (newsFeed: NewsFeed) => void;
  onViewChart: (newsFeed: NewsFeed) => void;
  onViewAudience: (newsFeed: NewsFeed) => void;
  showAnalyticsNewsFeed?: boolean;
};

const AdminNewsFeedTile = ({
  newsFeed,
  onClick,
  onDelete,
  onHighlight,
  onViewChart,
  onViewAudience,
  showAnalyticsNewsFeed = false,
}: AdminNewsFeedTileProps) => {
  return (
    <motion.div
      className="p-2 grid grid-cols-12 transition-all bg-white rounded-lg shadow-sm cursor-pointer"
      onClick={() => onClick(newsFeed)}
    >
      <div
        className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 relative"
        style={{ borderRadius: "1rem" }}
      >
        <div className="flex items-center justify-center w-full h-full p-4">
          <FileTypeItemIcon type={newsFeed.type as SupportedFileType} className="w-12 h-12" />
        </div>
      </div>
      <div className="row-start-1 col-start-4 lg:col-start-3 col-end-10 flex flex-col justify-between p-5">
        <div>
          <span className="font-semibold text-lg line-clamp-1 leading-10">{newsFeed?.name}</span>
          <span className="text-sm font-bold text-grey-dark line-clamp-2">
            {newsFeed.createdBy}
          </span>
        </div>
        <div className="flex flex-col">
          {newsFeed.createdAt && (
            <span className="text-sm text-grey-dark">
              {format(new Date(newsFeed.createdAt as string), "do MMMM yyyy, HH:mm")}
            </span>
          )}
        </div>
      </div>
      <div className="ml-0 sm:ml-2 md:ml-6 col-start-12 xl:col-start-11 mt-3">
        <DownloadFileButton eolasFile={newsFeed} />
      </div>
      <div className="row-start-1 col-start-13 flex flex-col justify-between p-3">
        <div className="flex flex-col justify-center items-center space-y-1.5">
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onHighlight(newsFeed);
            }}
            icon={newsFeed.isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
            data-testid="star-icon"
          />
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(newsFeed);
            }}
            icon={<DeleteIcon />}
            data-testid="delete-icon"
          />
          {showAnalyticsNewsFeed ? (
            <IconButton
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onViewAudience(newsFeed);
              }}
              icon={<AudienceIcon />}
              data-testid="audience-icon"
            />
          ) : null}
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onViewChart(newsFeed);
            }}
            icon={<PiechartIcon />}
            data-testid="piechart-icon"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default AdminNewsFeedTile;
