import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QueryString from "qs";
import { useLaunchDarkly } from "Contexts";
import { BaseAppRoute, LANDING_PAGE_SLUG, LDFlagNames } from "Utilities/types";
import { AddButton, Modal, PageTitle, InnerPageWrapper } from "UIKit";

import YourSpaces from "./components/YourSpaces/YourSpaces";
import CreateSpaceModal from "./components/CreateSpaceWizard/CreateSpaceWizard";
import { Space, AnalyticsEvents } from "@eolas-medical/core";
import { useSpacesContext } from "modules/spaces";
import DiscoverSpaces from "./components/DiscoverSpaces/DiscoverSpaces";
import { SelectSpaceState } from "./types";
import SelectSpaceButtons from "./components/SelectSpaceButtons/SelectSpaceButtons";
import { trackEvent } from "API/Analytics";
import PostCognitoModal from "./components/DiscoverSpaces/components/PostCognitoModal/PostCognitoModal";
import GetStartedUserModal from "./components/DiscoverSpaces/components/GetStartedUserModal/GetStartedUserModal";
import { webStore } from "Stores/WebStore";
import { observer } from "mobx-react-lite";

type SelectSpaceModalState =
  | "createSpace"
  | "getStartedUser"
  | "getStartedSpace"
  | "updateProfile"
  | "postCognitoUser"
  | "postSpaceCreation";

export const SelectSpace = observer(() => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const [selectState, setSelectState] = useState<SelectSpaceState>("your-spaces");

  const [modalState, setModalState] = useState<SelectSpaceModalState | null>(null);

  const canCloseModal = !(
    modalState === "getStartedUser" ||
    modalState === "postCognitoUser" ||
    modalState === "updateProfile"
  );

  const history = useHistory();
  const { state, search } = useLocation<{ shouldCreateSpace?: boolean }>();
  const showPostCognitoCreationModalValue = webStore.showPostCognitoModal;

  const { onSelectSpace } = useSpacesContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickCreateSpace = () => {
    setModalState("createSpace");
  };

  const handleShowGetStartedUserModal = () => {
    setModalState("getStartedUser");
  };

  const handleCloseModal = () => {
    setModalState(null);
    webStore.setShowPostCognitoModal(false);
  };

  const handleGoToKnowledgeTab = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "explore_knowledge_hub",
    });
    history.push(`/${BaseAppRoute.knowledge}`);
  };

  const handleGoToSpacesTab = () => {
    webStore.setShowPostCognitoModal(false);
    setModalState(null);
    setSelectState("discover-spaces");
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "access_existing_space",
    });
  };

  const handleGoToCreateSpace = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "create_new_space",
    });
    handleClickCreateSpace();
  };

  const handleGoToMeTab = () => {
    webStore.setShowPostCognitoModal(false);
    trackEvent(AnalyticsEvents.POST_USER_CREATION_GUIDE_FINAL_SELECTION, {
      selection: "explore_me_tab",
    });
    history.push(`/${BaseAppRoute.myProfile}`);
  };

  const handleSpaceCreated = (createdSpace: Space) => {
    onSelectSpace(createdSpace);
    webStore.setShowPostSpaceCreationModal(true);
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_COMPLETED);
    history.push(`/${BaseAppRoute.spaces}/${createdSpace.name}`);
  };

  const handleChangeSpacesList = (state: SelectSpaceState) => {
    if (selectState === "your-spaces" && state === "discover-spaces") {
      setSelectState("discover-spaces");
    }

    if (selectState === "discover-spaces" && state === "your-spaces") {
      setSelectState("your-spaces");
    }
  };

  useEffect(() => {
    const query = QueryString.parse(search, { ignoreQueryPrefix: true });

    if (query.from === LANDING_PAGE_SLUG) {
      handleClickCreateSpace();
    }

    if (state?.shouldCreateSpace) {
      handleClickCreateSpace();
      history.replace({ ...history.location, state: undefined });
    }
  }, [state, history, search, handleClickCreateSpace]);

  useEffect(() => {
    if (showPostCognitoCreationModalValue && !state?.shouldCreateSpace) {
      setModalState("postCognitoUser");
    }
  }, [showPostCognitoCreationModalValue, state?.shouldCreateSpace]);

  return (
    <>
      <InnerPageWrapper className="mt-10">
        <div className="flex flex-col space-y-2">
          <PageTitle
            data-testid="find-organisation-title"
            title={t("selectSpace_selectSpace")}
            subTitle={t("pageSubTitle")}
            className="space-y-0"
            titleClass="text-white"
            subTitleClass="text-white text-base font-semibold"
          />

          {flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] && !flags[LDFlagNames.READ_ONLY_ACTIVATED] ? (
            <AddButton className="bg-blue-600 hover:bg-blue-500" onClick={handleClickCreateSpace}>
              {t("selectSpace_create_button")}
            </AddButton>
          ) : null}

          <SelectSpaceButtons selectedButton={selectState} onChange={handleChangeSpacesList} />
        </div>

        {selectState === "your-spaces" ? <YourSpaces /> : null}

        {selectState === "discover-spaces" ? <DiscoverSpaces /> : null}
        <Modal
          open={modalState !== null}
          onClose={handleCloseModal}
          hasCloseIcon={canCloseModal}
          shouldCloseOnOverlayClick={canCloseModal}
          shouldCloseOnEsc={canCloseModal}
        >
          {modalState === "createSpace" ? <CreateSpaceModal onCreate={handleSpaceCreated} /> : null}
          {modalState === "postCognitoUser" ? (
            <PostCognitoModal onComplete={handleShowGetStartedUserModal} />
          ) : null}
          {modalState === "getStartedUser" ? (
            <GetStartedUserModal
              onGoToKnowledgeTab={handleGoToKnowledgeTab}
              onGoToSpacesTab={handleGoToSpacesTab}
              onGoToCreateSpace={handleGoToCreateSpace}
              onGoToMeTab={handleGoToMeTab}
            />
          ) : null}
        </Modal>
      </InnerPageWrapper>
    </>
  );
});
