import { UpdateBlobItemDto, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { useState } from "react";

const useEditKnowledgeContentFile = () => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({
      contentId,
      updateBlobDto,
      file,
    }: {
      contentId: string;
      updateBlobDto: UpdateBlobItemDto;
      file?: File;
    }) => {
      const { uploadUrl } = await knowledgeContentClient.updateBlobItem({
        contentId,
        updateBlobItemDto: updateBlobDto,
      });

      if (uploadUrl && file) {
        await uploadFileToPresignedS3(uploadUrl, file, setUploadProgress);
      }

      setUploadProgress(100);
    },
  );

  return {
    editFile: mutate,
    editingFile: isLoading,
    editingFileSuccess: isSuccess,
    editingFileError: error instanceof Error ? error.message : "",
    editingFileProgress: uploadProgress,
  };
};

export default useEditKnowledgeContentFile;
