import { useTranslation } from "react-i18next";

import { MutableWizardState, NormalStepComponentProps, NormalStepData } from "../../../types";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { useHeroImageDetails } from "./hooks/useHeroImageDetails";
import ImagePicker from "UIKit/ImagePicker/ImagePicker";
import { FormError } from "UIKit";
import { getFinalStepName } from "../../../functions/getFinalStepName";
import {
  handleUpdateWizardDraftStatus,
  useMakePenultimateStepButtonProps,
} from "../../../../../hooks/useMakePenultimateStepButtonProps";

const SelectHeroImage = ({
  wizardState,
  onClickBack,
  onClickForward,
  sectionId,
}: NormalStepComponentProps) => {
  const {
    image,
    defaultImage,
    imageBank,
    isImageBankLoading,
    isImageUploading,
    isImageUploadError,
    uploadImage,
    canProceed,
    onChangeBlob,
    onChangeImageUrl,
  } = useHeroImageDetails(wizardState, sectionId);

  const { t } = useTranslation();

  const buttonProps = useMakePenultimateStepButtonProps({
    currentWizardState: wizardState,
    handleClickBack: onClickBack,
    handleClickForward: async () => {
      let newState: MutableWizardState | null = await uploadImage();
      newState = handleUpdateWizardDraftStatus({ state: newState, isDraft: false });
      onClickForward(newState);
    },
    handleAltClickForward: async () => {
      let newState: MutableWizardState | null = await uploadImage();
      newState = handleUpdateWizardDraftStatus({ state: newState, isDraft: true });
      onClickForward(newState);
    },
    isLoading: isImageUploading || isImageBankLoading,
    isDisabled: !canProceed,
  });

  return (
    <EolasWizardModalWrapper
      header={{ title: t("flashcards_page_title_hero_image") }}
      buttonRowProps={buttonProps}
    >
      <ImagePicker
        galleryImages={imageBank}
        errorDraggedImageText={t("imagePicker_too_large_error")}
        isLoading={isImageBankLoading}
        onChangeBlob={onChangeBlob}
        onChangeImageUrl={onChangeImageUrl}
        defaultImage={defaultImage}
        defaultFile={image || undefined}
        shouldAllowBlankSelection={false}
      />
      {isImageUploadError ? <FormError error={t("repository_upload_error")} /> : null}
    </EolasWizardModalWrapper>
  );
};

export const selectHeroImageData: NormalStepData = {
  StepComponent: SelectHeroImage,
  props: {
    getPreviousStepName: () => "editFlashcardContent",
    getNextStepName: (_, state) => getFinalStepName(state),
  },
};
