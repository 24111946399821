import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import allSettled from "promise.allsettled";

import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { LoadingOverlay } from "UIKit";
import { TermlyCookies } from "Components";
import { EolasProviders, Sentry } from "Contexts";
import { startup } from "Utilities/appStartup";

import "./i18n";
import "./index.css";

import App from "./App";
import { WhatsNewModal } from "Components/WhatsNewModal/WhatsNewModal";
import { SelfSetup } from "Pages/AppTour";
import CompleteProfileModal from "Components/CompleteProfileModal/CompleteProfileModal";
import { createRoot } from "react-dom/client";
import { AutoAccessAfterLogin } from "Pages/Authentication/pages/Invitations/pages/modals/AutoAccessAfterLogin";

startup();
allSettled.shim();

Sentry.addBreadcrumb({
  category: "Init",
  message: "imports added, calling amplify configure",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <EolasProviders>
    <Suspense fallback={<LoadingOverlay />}>
      <Router>
        <App />
        <TermlyCookies />
        <AutoAccessAfterLogin />
        <WhatsNewModal />
        <SelfSetup />
        <CompleteProfileModal />
      </Router>
    </Suspense>
  </EolasProviders>,
);
