import envConfig from "env-config";
import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { LDClient, LDUser, initialize } from "launchdarkly-js-client-sdk";

import {
  userStore,
  UserData,
  AppUserData,
  EolasRegions,
  eolasLogger,
  sectionStore,
} from "@eolas-medical/core";

import { LDFlags } from "Utilities/types";
import { appConfig } from "AppTypeConfig";

import { getSupportedRegion } from "Hooks/useSupportedRegion/useSupportedRegion";
import { useRunOnMountUnmount } from "Hooks";
import { isEqual } from "lodash";

interface LDState {
  flags: LDFlags;
  isLoading: boolean;
  client?: LDClient;
}

enum ActionTypes {
  CLIENT_INITIALISED = "CLIENT_INITIALISED",
  UPDATE_FLAGS = "UPDATE_FLAGS",
  RESET = "RESET",
}

type ReducerAction = {
  flags: LDFlags;
  client?: LDClient;
  type: ActionTypes;
};

export interface LDContextValue extends LDState {
  initClient: () => void;
}

const APP_ATTRIBUTES = {
  isWeb: true,
  appType: appConfig.appType,
  version: envConfig.REACT_APP_VERSION as string,
};

const ANONYMOUS_USER = { anonymous: true, custom: APP_ATTRIBUTES, key: "anonymous" };

function getLDUser({
  sessionUser,
  appUser,
  supportedEolasRegion,
}: {
  sessionUser?: UserData;
  appUser?: AppUserData;
  supportedEolasRegion?: EolasRegions;
} = {}): LDUser {
  if (!sessionUser?.id || !supportedEolasRegion) {
    return ANONYMOUS_USER;
  }

  const userAttributes = appUser
    ? {
        appUserID: appUser.id,
        appID: appUser.appID,
        accessLevel: appUser.accessLevel,
        orgID: sectionStore.organisationID,
      }
    : {};

  return {
    key: sessionUser.id,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    custom: {
      ...APP_ATTRIBUTES,
      ...userAttributes,
      eolasRegion: supportedEolasRegion,
    },
  };
}

const LDContext = React.createContext<LDContextValue>({} as LDContextValue);

const initialState: LDState = {
  // if LD is enabled, set to empty and fetch fresh if it's disabled we use the default flags
  flags: envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED ? {} : appConfig.defaultLDFlags,
  isLoading: envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED,
};

const ldReducer: React.Reducer<LDState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_INITIALISED: {
      const { client } = action;
      return { ...state, client };
    }
    case ActionTypes.UPDATE_FLAGS: {
      const { flags: newFlags } = action;
      const oldFlags = state.flags;
      const flags = isEqual(newFlags, oldFlags) ? oldFlags : newFlags;
      return { ...state, flags, isLoading: false };
    }
    case ActionTypes.RESET:
      return { ...initialState };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const LDProvider = observer(({ children }: { children: React.ReactNode }) => {
  const {
    userData: { id: idOfUser },
    appUserData: { id: appUserId },
  } = userStore;
  const isLoggedIn = userStore.userSession.isLoggedIn;
  const userId = isLoggedIn ? idOfUser : null;

  const [state, dispatch] = React.useReducer(ldReducer, { ...initialState });

  const { flags, client } = state;

  // user changes hook
  useEffect(() => {
    if (!client) {
      return;
    }

    const currentLdUser = client.getUser();

    if (!userId && currentLdUser) {
      eolasLogger.log("Launch darkly: identify anonymous user");
      client.identify(getLDUser());
      return;
    }

    if (currentLdUser?.key !== userId || currentLdUser?.custom?.appUserID !== appUserId) {
      eolasLogger.log("Launch darkly: user info changed");
      const ldUser = getLDUser({
        sessionUser: userStore.userData,
        appUser: userStore.appUserData,
        supportedEolasRegion: getSupportedRegion(userStore.userData),
      });
      client.identify(ldUser);
    }
  }, [client, appUserId, userId]);

  const initClient = useCallback(() => {
    if (!envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED) {
      return;
    }

    eolasLogger.log("Launch darkly: initialise");

    const ldUser: LDUser = getLDUser({
      sessionUser: userStore.userData,
      appUser: userStore.appUserData,
      supportedEolasRegion: getSupportedRegion(userStore.userData),
    });
    try {
      const newClient = initialize(envConfig.REACT_APP_LAUNCH_CLIENT_SIDE_ID, ldUser);

      newClient.on("ready", () => {
        eolasLogger.log("Launch darkly: LD flags ready");
        const flags = newClient.allFlags();
        dispatch({ type: ActionTypes.UPDATE_FLAGS, flags });
      });

      newClient.on("change", () => {
        eolasLogger.log("Launch darkly: LD flags changed");
        const flags = newClient.allFlags();
        dispatch({ type: ActionTypes.UPDATE_FLAGS, flags });
      });

      newClient.on("error", () => {
        eolasLogger.log("Launch darkly: Error");

        if (Object.keys(flags || {}).length === 0) {
          // if flags are empty, use the default flags
          dispatch({
            type: ActionTypes.UPDATE_FLAGS,
            flags: appConfig.defaultLDFlags,
          });
        }
      });

      dispatch({
        type: ActionTypes.CLIENT_INITIALISED,
        client: newClient,
        flags: { ...initialState.flags },
      });
    } catch (error) {
      eolasLogger.error(error);
    }
  }, [flags]);

  useRunOnMountUnmount({ onMount: initClient });

  const contextValue: LDContextValue = useMemo(
    () => ({ ...state, initClient }),
    [state, initClient],
  );

  return <LDContext.Provider value={contextValue}>{children}</LDContext.Provider>;
});

function useLaunchDarkly() {
  const context = React.useContext(LDContext);
  if (context === undefined) {
    throw new Error("useLaunchDarkly must be used within a LDProvider");
  }
  return context;
}

LDProvider.displayName = "LDProvider";

export { LDProvider, useLaunchDarkly };
