import { isTag } from "domhandler";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { useHistory } from "react-router-dom";

import { useMonographContext } from "../../../contexts/ MonographTypeContext";
import { processLink } from "./processLink";
import { ProcessingInstruction } from "shared/functions/makeHtmlToReactNodeParser";
import { Strings } from "./CustomEvidenceLinkComponents.strings";

type CustomEvidenceTagProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

// Trailing hyphen in intentional (multiple class names for the same thing):
const EVIDENCE_GRADING_LABEL_CLASS = "evidence-grading-";
const RECOMMENDATION_LEVEL_CLASS = "recommendation-level";

export const shouldProcessCustomEvidenceTag: ProcessingInstruction["shouldProcessNode"] = (
  node,
) => {
  if (!node) {
    return false;
  }
  const parentNode = node.parentNode;
  if (!parentNode || !isTag(parentNode)) {
    return false;
  }
  // Note: property is intentionally class not className
  const className = parentNode.attribs?.["class"];
  return (
    node.name === "a" &&
    typeof className === "string" &&
    className.includes(EVIDENCE_GRADING_LABEL_CLASS)
  );
};

const CustomEvidenceTag = ({ href, children, ...rest }: CustomEvidenceTagProps) => {
  const { type } = useMonographContext();
  const { push } = useHistory();

  if (!href) {
    return null;
  }
  const { internalLink } = processLink({ href, type });

  if (!internalLink) {
    return null;
  }

  let child = typeof children === "string" ? children : null;
  if (Array.isArray(children)) {
    child = children[0];
  }
  const text = typeof child === "string" ? child : null;

  return (
    <a
      {...rest}
      className="text-sm italic underline cursor-pointer"
      onClick={() => push(internalLink)}
    >
      {Strings.evidenceGrading(text)}
    </a>
  );
};

export const processCustomEvidenceTag: ProcessingInstruction["processNode"] = (
  node,
  children,
  index,
) => {
  return (
    <CustomEvidenceTag key={index} {...(node.attribs ?? {})}>
      {children}
    </CustomEvidenceTag>
  );
};

/* This custom should render function is to intentionally hide certain content related to evidence grading,
 * so that we are displaying it consistently
 */

export const shouldRenderHiddenSpan: ProcessingInstruction["shouldProcessNode"] = (node) => {
  if (!node) {
    return false;
  }
  // Note: property is intentionally class not className
  return node.name === "span" && node.attribs?.["class"] === RECOMMENDATION_LEVEL_CLASS;
};

export const processHiddenSpan: ProcessingInstruction["processNode"] = () => {
  return null;
};
