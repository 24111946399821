import { useTranslation } from "react-i18next";
import ComingSoon from "shared/pages/MiniApp/ComingSoon";
import { MonographType } from "@eolas-medical/core";

const BnfComingSoon = ({ type }: { type: MonographType }) => {
  const { t } = useTranslation();

  const label = type === "bnf" ? "adultMedications" : "paediatricMedications";

  return (
    <ComingSoon
      titleText={t(label)}
      subTitleText={t("coming_soon_subtitle", { miniApp: t(label) })}
      informationText={t("coming_soon_information", { miniApp: t(label) })}
      disclaimerText={t("coming_soon_disclaimer")}
    />
  );
};

export const BnfComingSoonAdult = () => <BnfComingSoon type="bnf" />;
export const BnfComingSoonChild = () => <BnfComingSoon type="bnfc" />;
