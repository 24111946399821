import { LinkedDocumentsError } from "Pages/FileViewer/hooks/useLinkedKnowledgeDocumentsViewer";
import { InnerPageWrapper, PageTitle, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  errorType: LinkedDocumentsError;
};

export const KnowledgeLinkedDocumentsError = ({ errorType }: Props) => {
  const { t } = useTranslation();
  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-16"
        data-testid="portfolio-error-fallback"
      >
        {errorType === "fileNotFound" ? (
          <>
            <PageTitle title={t("knowledge_linked_documents_file_not_found_title")} />
            <Text level={1}>{t("knowledge_linked_documents_file_not_found")}</Text>
          </>
        ) : null}

        {errorType === "wrongRegion" ? (
          <>
            <PageTitle title={t("knowledge_linked_documents_wrong_region_title")} />
            <Text level={1}>{t("knowledge_linked_documents_wrong_region")}</Text>
          </>
        ) : null}

        {errorType === "unSupportedType" || errorType === "invalidParams" ? (
          <>
            <PageTitle title={t("knowledge_linked_documents_viewer_invalid_link_title")} />
            <Text level={1}>{t("knowledge_linked_documents_viewer_invalid_link")}</Text>
          </>
        ) : null}

        {errorType === "unknownError" ? (
          <>
            <PageTitle title={t("knowledge_linked_documents_viewer_unknown_error_title")} />
            <Text level={1}>{t("knowledge_linked_documents_viewer_unknown_error")}</Text>
          </>
        ) : null}

        <Link
          to={`/${BaseAppRoute.knowledge}`}
          className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
        >
          {t("take_me_home")}
        </Link>
      </div>
    </InnerPageWrapper>
  );
};
