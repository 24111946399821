import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import {
  EolasVersatileListContext,
  EolasVersatileListRenderItemProps,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { useEolasVersatileListSelection } from "UIKit/EolasVersatileList/hooks/useEolasVersatileListSelection";
import React from "react";
import { UseLocalSearchProps } from "shared/hooks/useLocalSearch/types";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";

export type TileListNode = {
  id: string;
  hasChildren: boolean;
  children?: TileListNode[];
  getChildren?: () => TileListNode[];
};

export type SelectTileListProps<T extends TileListNode> = {
  data: T[];
  onSelectionChange: (selectedIds: Record<string, T>) => void;
  handleNavigateDown: (item: T) => void;
  selectionMode: "single" | "multi";
  renderTile: (
    args: EolasVersatileListRenderItemProps<T, SelectTileListContext<T>>,
  ) => React.ReactNode;
  searchKeys?: UseLocalSearchProps<T>["keysToSearch"];
  initialSelection?: Record<string, T>;
  onSearch?: (text: string) => void;
  onClearSearch?: () => void;
  isSearchable?: boolean;
  searchData?: T[];
  /**
   * If true, sections that have children will be selectable and navigable.
   */
  areNavigableSectionsSelectable?: boolean;
};

export type SelectTileListContext<T extends TileListNode> = EolasVersatileListContext<
  T,
  {
    handleNavigateDown: (item: T) => void;
    handleAddToSelection: (id: string, item: T) => void;
    handleRemoveFromSelection: (id: string) => void;
    handleClearSelection: () => void;
    areNavigableSectionsSelectable: boolean;
  }
>;

export const SelectTileList = <T extends TileListNode>({
  data,
  onSelectionChange,
  selectionMode,
  handleNavigateDown,
  renderTile,
  searchKeys,
  searchData,
  onClearSearch,
  onSearch,
  isSearchable = true,
  initialSelection,
  areNavigableSectionsSelectable = false,
}: SelectTileListProps<T>) => {
  const { handleAddToSelection, handleClearSelection, handleRemoveFromSelection, selection } =
    useEolasVersatileListSelection<T>(initialSelection, selectionMode, onSelectionChange);

  const { searchInstanceId, searchResults, handleClearSearch, handleSearchClick, isSearchActive } =
    useLocalMobxSearch<T>({ data: searchData ?? data, keysToSearch: searchKeys ?? [] });

  const onSearchClick = (text: string) => {
    if (onSearch) {
      onSearch(text);
    }
    handleSearchClick(text);
  };

  const onClearSearchTerm = () => {
    if (onClearSearch) {
      onClearSearch();
    }
    handleClearSearch();
  };

  const shouldShowSearchResults = isSearchActive;
  const items = shouldShowSearchResults ? searchResults : data;

  return (
    <div className="h-3/4 w-full">
      <EolasVersatileList<T, SelectTileListContext<T>>
        className="w-full"
        items={items}
        context={{
          isInitialLoading: false,
          handleAddToSelection,
          handleRemoveFromSelection,
          handleNavigateDown,
          handleClearSelection,
          areNavigableSectionsSelectable,
        }}
        renderItem={renderTile}
        isSelectable
        selectedItems={selection}
        isInModal
        isSearchable={isSearchable}
        searchInstanceId={searchInstanceId}
        searchMode="click"
        onClickSearch={onSearchClick}
        onClearSearch={onClearSearchTerm}
        outerContainerClassName="bg-grey-300 rounded-2xl py-2 mt-2"
        innerContainerClassName="px-4 first:pt-2 last:pb-2"
      />
    </div>
  );
};
