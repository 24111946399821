import { EditFileStep } from "./EditFileStep";
import { useForm } from "react-hook-form";
import { Step } from "../../types";
import { EditItemValues } from "shared/pages/ContentRepository/ContentItems/components/EditContentItemModal/types";
import { SupportedFileType } from "@eolas-medical/core";

interface UseEditFileDetailsProps<T> {
  stepName: T;
  title: string;
  fileFormLabel?: string;
  fileUploadLabel?: string;
  fileChangeLabel?: string;
  fileDescriptionText?: string;
  onSubmit: (data: EditItemValues) => void;
  isLoading?: boolean;
  nextLabel?: string;
  filePickerValue: string;
  backDisabled?: boolean;
  allowedFileExtensionsOverride?: string[];
  onBlobSelection?: (file: File, type: SupportedFileType) => void | Promise<void>;
  type?: "blob" | "dsm";
}

export const useEditFileStep = <T extends string>({
  stepName,
  title,
  fileFormLabel = "",
  fileUploadLabel = "",
  fileChangeLabel = "",
  fileDescriptionText = "",
  nextLabel,
  filePickerValue,
  isLoading,
  onSubmit,
  backDisabled = false,
  allowedFileExtensionsOverride,
  onBlobSelection,
  type = "blob",
}: UseEditFileDetailsProps<T>): Step<T> => {
  const { handleSubmit, formState, getValues, setValue, clearErrors } = useForm();

  const { errors } = formState;

  const canProceed = formState.isValid && !formState.isSubmitting && !isLoading;

  const handleSubmitDetails = () => {
    const { blob } = getValues();
    onSubmit({ blob });
  };

  const handleBlobAddition = (file: File) => {
    setValue("blob", file);
    clearErrors("blob");
    onBlobSelection?.(file, type);
  };

  return {
    name: stepName,
    title,
    submitError: !!errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(handleSubmitDetails),
    isLoading,
    nextLabel,
    component: (
      <EditFileStep
        blobName={getValues("blob")?.name || filePickerValue}
        onBlobChange={handleBlobAddition}
        errors={errors}
        fileFormLabel={fileFormLabel}
        fileUploadLabel={fileUploadLabel}
        fileChangeLabel={fileChangeLabel}
        fileDescriptionText={fileDescriptionText}
        allowedFileExtensionsOverride={allowedFileExtensionsOverride}
      />
    ),
    backDisabled,
  };
};
