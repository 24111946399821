/* eslint-disable camelcase */
import * as yup from "yup";

export interface PasswordRules {
  UPPERCASE: RegExp;
  LOWERCASE: RegExp;
  NUMBERS: RegExp;
  SPECIAL_CHAR: RegExp;
  MIN_LENGTH: number;
}

export const PASSWORD_RULES: PasswordRules = {
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  NUMBERS: /[0-9]/,
  SPECIAL_CHAR: /[!@#$%^&*(),.?":{}|<>]/,
  MIN_LENGTH: 8,
};

export const validatePassword = (value: string, rule: keyof PasswordRules) => {
  const { UPPERCASE, LOWERCASE, NUMBERS, SPECIAL_CHAR, MIN_LENGTH } = PASSWORD_RULES;

  switch (rule) {
    case "LOWERCASE":
      return LOWERCASE.test(value);
    case "NUMBERS":
      return NUMBERS.test(value);
    case "SPECIAL_CHAR":
      return SPECIAL_CHAR.test(value);
    case "UPPERCASE":
      return UPPERCASE.test(value);
    case "MIN_LENGTH":
      return value.length >= MIN_LENGTH;
    default:
      return true;
  }
};

export const createEolasPasswordValidation = (requiredLabel: string) => {
  return yup
    .string()
    .required(requiredLabel)
    .min(PASSWORD_RULES.MIN_LENGTH, "passwordValidation_length_error")
    .matches(PASSWORD_RULES.UPPERCASE, { message: "passwordValidation_uppercase_error" })
    .matches(PASSWORD_RULES.LOWERCASE, { message: "passwordValidation_lowercase_error" })
    .matches(PASSWORD_RULES.NUMBERS, { message: "passwordValidation_number_error" })
    .matches(PASSWORD_RULES.SPECIAL_CHAR, { message: "passwordValidation_special_error" });
};

export const signUpSchema = yup.object().shape({
  given_name: yup.string().required("createAccount_name_required"),
  family_name: yup.string().required("createAccount_surname_required"),
  email: yup.string().email().required("createAccount_email_required"),
  password: createEolasPasswordValidation("passwordValidation_password_required"),
  confirmPassword: createEolasPasswordValidation(
    "passwordValidation_confirm_password_required",
  ).oneOf([yup.ref("password"), null], "passwordValidation_match_required"),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: createEolasPasswordValidation("passwordValidation_old_password_required"),
  newPassword: createEolasPasswordValidation("passwordValidation_new_password_required"),
  confirmPassword: createEolasPasswordValidation(
    "passwordValidation_confirm_password_required",
  ).oneOf([yup.ref("newPassword"), null], "passwordValidation_match_required"),
});

export const acceptInvitationSchema = yup.object().shape({
  email: yup
    .string()
    .email("createAccount_forgotPassword_email_invalid")
    .required("createAccount_email_required"),
  given_name: yup.string().required("acceptInvitation_first_name_required"),
  family_name: yup.string().required("acceptInvitation_surname_required"),
  password: createEolasPasswordValidation("passwordValidation_password_required"),
  confirmPassword: createEolasPasswordValidation(
    "passwordValidation_confirm_password_required",
  ).oneOf([yup.ref("password"), null], "passwordValidation_match_required"),
});

export const resetPasswordSchema = yup.object().shape({
  verificationCode: yup.string().required("passwordReset_verification_required"),
  resetPassword: createEolasPasswordValidation("passwordValidation_password_required"),
  resetConfirmPassword: createEolasPasswordValidation(
    "passwordValidation_confirm_password_required",
  ).oneOf([yup.ref("resetPassword"), null], "passwordValidation_match_required"),
});
