import { useMutation } from "@tanstack/react-query";
import { profilesClient, syncCommunityData, UserData, userStore } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";

const useUpdateUserProfile = () => {
  const { getSupportedEolasRegion } = useSupportedRegion();

  const { mutate, isLoading, isSuccess, error } = useMutation({
    mutationFn: async ({ userId, userData }: { userId: string; userData: UserData }) => {
      return profilesClient.updateUserProfile(userId, userData);
    },
    onSuccess: async (data) => {
      userStore.updateData({ user: data });
      const region = getSupportedEolasRegion();
      syncCommunityData({ region });
    },
  });

  return {
    updateUser: mutate,
    updateUserError: error instanceof Error ? error.message : undefined,
    updatingUser: isLoading,
    updateUserSuccess: isSuccess,
  };
};

export default useUpdateUserProfile;
