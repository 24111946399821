import React, { useMemo } from "react";
import { Title, Text } from "UIKit";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import {
  UserLocation,
  jobTitleOptions,
  seniorityOptions,
  specialtyOptions,
} from "@eolas-medical/core";
import ProfileLocation from "../../ProfileLocation/ProfileLocation";
import { useTranslation } from "react-i18next";

interface ProfileValueProps {
  seniority?: string;
  specialty?: string[];
  jobTitle?: string;
  location?: UserLocation;
}

const ProfileValue = ({ seniority, specialty, jobTitle, location }: ProfileValueProps) => {
  const { t } = useTranslation();

  const preloadedJobTitle = useMemo(() => {
    return jobTitleOptions.find((option) => option.value === jobTitle);
  }, [jobTitle]);

  const preloadedSeniority = useMemo(() => {
    return seniorityOptions.find((option) => option.value === seniority);
  }, [seniority]);
  const notSpecified = <span className="text-grey-600">{t("general_not_specified")}</span>;

  const preloadedSpecialties = useMemo(() => {
    return specialtyOptions.filter((option) => {
      return specialty?.some((spec: string) => {
        return spec === option.value;
      });
    });
  }, [specialty]);

  return (
    <div className="space-y-2">
      {location ? (
        <ProfileLocation
          location={location}
          className="flex justify-start items-center mb-5 mt-0"
        />
      ) : null}
      <Title level={7}>{t("my-medical_personal_details_jobtitle")}</Title>
      <Text level={1}>{preloadedJobTitle?.label || notSpecified}</Text>

      <Title level={7}>{t("my-medical_personal_details_seniority")}</Title>
      <Text level={1}>{preloadedSeniority?.label || notSpecified}</Text>

      <div style={{ maxHeight: "10rem" }} className="overflow-y-auto sm:overflow-y-hidden">
        <Title level={7}>{t("my-medical_personal_details_specialty")}</Title>
        <Text level={1} className="flex flex-wrap">
          {preloadedSpecialties?.length
            ? preloadedSpecialties.map((special, index) => {
                return (
                  <Pill
                    key={index}
                    className="bg-grey-100 inline-flex mb-2 mr-3 items-center justify-center custom-pill-font"
                    size={Sizes.SMALL}
                    value={special.label}
                  />
                );
              })
            : notSpecified}
        </Text>
      </div>
    </div>
  );
};

export default ProfileValue;
