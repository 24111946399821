import { Strings } from "./DevicesSection.strings";
import { DevicePreparation } from "@eolas-medical/core";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import BnfPreparation from "../../../../components/BnfPreparation/BnfPreparation";
import { Divider } from "UIKit";

export const DevicesSection = ({ devices }: { devices?: DevicePreparation[] }) => {
  if (!devices || !devices.length) {
    return null;
  }
  return (
    <Collapsible title={Strings.devices} shouldMountExpanded>
      <Divider>
        {devices.map((devicePrep, index) => {
          return <BnfPreparation prep={devicePrep} key={devicePrep.name + index} />;
        })}
      </Divider>
    </Collapsible>
  );
};
