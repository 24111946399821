import { SupportedFileType } from "@eolas-medical/core";
import { isUrlValid } from "Utilities";
import * as yup from "yup";

export const useChooseFileTypeStepSchema = (selectedType: SupportedFileType) => {
  if (selectedType === "blob" || selectedType === "dsm") {
    return yup.object().shape({
      blob: yup
        .mixed()
        .required("A file is required")
        .test("fileSize", "Please ensure file size is less than 2 GB", function (value) {
          if (!value) {
            return false;
          }
          return value.size <= 2147483648; // Example size limit: 2GB. Adjust as needed.
        }),
    });
  }

  if (selectedType === "link") {
    return yup.object().shape({
      link: yup.object().shape({
        name: yup.string().required("Name is required").notOneOf([""], "Name cannot be empty"),
        url: yup
          .string()
          .required("A URL is required")
          .test("validUrl", "Please enter a valid URL", function (newValue) {
            const isValid = newValue ? isUrlValid(newValue) : false;
            return isValid;
          }),
      }),
    });
  }

  return yup.object().shape({});
};
