import { PackStrings } from "./Pack.string";
import { Text } from "UIKit";
import PackCategoryTags from "./PackCategoryTags";
import { Pack as DrugPack } from "@eolas-medical/core";

export const Pack = ({
  pack,
  controlledDrugLabel,
}: {
  pack: DrugPack;
  controlledDrugLabel: string | null;
}) => {
  const nhsPrice = PackStrings.nhsPrice(pack.nhsIndicativePrice);
  const drugTariff = PackStrings.drugTariff(pack.drugTariff, pack.drugTariffPrice);
  return (
    <div className="flex space-x-1 p-1">
      <div className="-mt-[3px]">&#x2022;</div>
      <div className="flex flex-col">
        <div className="flex space-x-1 items-center">
          <Text level={2} className="font-light">
            {PackStrings.sizeAndUnit(pack.size, pack.unit)}
          </Text>
          <PackCategoryTags pack={pack} controlledDrugLabel={controlledDrugLabel} />
        </div>
        {nhsPrice || drugTariff ? (
          <div className="flex flex-col">
            {nhsPrice ? (
              <Text level={2} className="font-light">
                {nhsPrice}
              </Text>
            ) : null}
            {drugTariff ? (
              <Text level={2} className="font-light">
                {drugTariff}
              </Text>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
