import { Button, InnerPageWrapper, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const KnowledgeLinkedDocumentsNewTab = ({
  onOpenDocument,
}: {
  onOpenDocument: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-16"
        data-testid="portfolio-error-fallback"
      >
        <Text className="mt-2" level={1}>
          {t("knowledge_linked_documents_new_tab")}
        </Text>

        <Button
          color="blue"
          iconLeft="LinkIcon"
          type="submit"
          className="self-center sm:w-1/3 mt-8 [&_svg]:mr-1 [&_svg]:text-white"
          onClick={onOpenDocument}
        >
          {t("knowledge_linked_documents_new_tab_button")}
        </Button>

        <Text className="mt-4" level={1}>
          {t("filePicker_or")}
        </Text>

        <Link
          to={`/${BaseAppRoute.knowledge}`}
          className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-4"
        >
          {t("take_me_home")}
        </Link>
      </div>
    </InnerPageWrapper>
  );
};
