import useSpaceUsers from "./useSpaceUsers";

export const useSpaceAdmins = (departmentId: string) => {
  const { spaceUsers, isLoadingSpaceUsers } = useSpaceUsers(departmentId);

  return {
    spaceAdmins: spaceUsers.filter(({ accessLevel }) => accessLevel === "admin"),
    isLoadingSpaceAdmins: isLoadingSpaceUsers,
  };
};
