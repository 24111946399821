import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { GenericError } from "UIKit/GenericError/GenericError";
import { removeLastUrlPath } from "Utilities";

export const FileViewerUnauthorizedError = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <GenericError
      errorTitle={t("fileViewer_unauthorized_error_title")}
      errorDescription={t("fileViewer_unauthorized_error_description")}
      errorButtonText={t("fileViewer_unauthorized_error_button_text")}
      newRoute={removeLastUrlPath(url, 2)}
    />
  );
};
