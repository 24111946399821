import { Control, FieldErrors, FieldValues, FieldPath } from "react-hook-form";
import { FormItem } from "./types";
import { FormElement } from "UIKit/FormElements";
import { renderFormItem } from "./helpers";

interface FormStepProps<FormStepValues extends FieldValues> {
  control: Control<FormStepValues>;
  errors: FieldErrors<FormStepValues>;
  formItems: FormItem<FormStepValues>[];
}

const FormStep = <FormStepValues extends FieldValues>({
  control,
  errors,
  formItems,
}: FormStepProps<FormStepValues>) => {
  return (
    <form>
      {formItems.map((formItem) => {
        const fieldName = formItem.fieldName as FieldPath<FormStepValues>;

        return (
          <FormElement
            control={control}
            key={fieldName}
            id={fieldName}
            required={formItem.required}
            label={formItem.fieldLabel}
            errorMessage={errors[fieldName]?.message}
          >
            {(field) => renderFormItem({ field, formItem })}
          </FormElement>
        );
      })}
    </form>
  );
};

export default FormStep;
