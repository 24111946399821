import { myFilesService } from "../client/myFiles.client";
import { QueryEnvironment, useEolasQuery } from "Hooks";
import myFilesKeys, { MyFilesKey } from "./myFiles.queryKeys";
import { FileSection } from "modules/myFiles/types";
import { useMemo } from "react";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";

interface UseSections {
  sectionsList: FileSection[];
  sectionsListLoading: boolean;
}

const useSections = (userId: string): UseSections => {
  const { getIconUrl } = useGetIconUrl();
  const { data, isFetching } = useEolasQuery<MyFilesKey[], unknown, unknown, FileSection[]>({
    queryKey: myFilesKeys.sectionLists(),
    queryFn: async () => myFilesService.getAllSections(userId),
    environment: QueryEnvironment.loggedIn,
    options: {
      staleTime: 30000,
      enabled: userId?.length > 0,
    },
  });

  const newData = useMemo(() => {
    return data?.map((s) => ({ ...s, iconUrl: getIconUrl(s.icon) }));
  }, [data, getIconUrl]);

  return {
    sectionsList: newData || [],
    sectionsListLoading: isFetching,
  };
};

export default useSections;
