import { GeneralCategory } from "@eolas-medical/core";
import { snakeCase } from "lodash";
enum NicePdfParams {
  pageNo = "pageNo",
}

type MakeRouteParams = { currentPath: string; item: GeneralCategory };
export const makeNiceGuidelinesRoute = ({ currentPath, item }: MakeRouteParams): string => {
  const snakeCaseSection = snakeCase(item.title);

  return `${currentPath}/${snakeCaseSection}`;
};

export const makeNicePdfParams = (pageNo: string | undefined) => {
  if (!pageNo) {
    return null;
  }
  const params = new URLSearchParams();
  params.set(NicePdfParams.pageNo, pageNo);
  return params.toString();
};
