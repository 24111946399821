import {
  sectionStore,
  hasProp,
  CommunityMedicationsMiniApp,
  isDefined,
  CommunityLevelSection,
  isLinkContentItem,
  DeeplinkResolvedPayload,
  ContentItem,
  EolasFile,
  ChildReference,
  fromAwsJSON,
} from "@eolas-medical/core";
import { BaseAppRoute } from "Utilities/types";
import { isAxiosError } from "axios";
import {
  isSMPCGuidelineDeeplinkParams,
  isMedHighlightsDeeplinkParams,
  isSupportedKnowledgeContentDeepLinkType,
  isBNFDeeplinkParams,
  isNiceGuidelinesDeeplinkParams,
  isClinicalResourceDeeplinkParams,
  isClinicalResourceMainSectionType,
} from "deeplinking/functions/typeguards";
import { KnowledgeDeeplinkParams } from "deeplinking/types/contentDeeplinks/knowledgeContentDeeplinks.types";
import useFlashcardItem from "modules/contentRepository/data/useFlashcardItem";
import useFetchKnowledgeContentItem from "modules/knowledgeContent/data/useFetchKnowledgeContentItem";
import { useLocation, useParams } from "react-router-dom";
import { useExtractAllParams } from "./useExtractAllParams";
import { makeBnfRoute } from "Pages/Knowledge/pages/Medications/pages/Bnf/routes/BnfRoutes";
import { LocationDescriptorObject } from "history";
import { useGetNiceItemUrl } from "modules/niceGuidelines/data/useGetNiceItemUrl";
import {
  isContentItem,
  isEolasFile,
  isMedicationHighlight,
} from "modules/contentRepository/typeguards";
import {
  isResourceUnavailableReason,
  useCheckClinicalResource,
} from "deeplinking/hooks/knowledge/useCheckClinicalResource";
import { mainSectionTypeToNatResourceEntityTypeMapper } from "deeplinking/functions/helpers";
import { snakeCase } from "lodash";
import { isChildReference } from "shared/functions/typeguards";
import { isChildRefEolasCalculator } from "Pages/Knowledge/pages/ClinicalCalculators/functions/typeguards";

export type LinkedDocumentsError =
  | "invalidParams"
  | "fileNotFound"
  | "unSupportedType"
  | "wrongRegion"
  | "unknownError";

export const useLinkedKnowledgeDocumentsViewer = (): {
  isLoading: boolean;
  hasError: boolean;
  error: LinkedDocumentsError | null;
  redirectLocation: string | LocationDescriptorObject<undefined> | null;
  newTabLocation: string | null;
  resolvedDeeplinkPayload: DeeplinkResolvedPayload | null;
  item: ContentItem | EolasFile | ChildReference | null;
} => {
  const location = useLocation();
  const { type } = useParams<{ type: string | undefined }>();
  const searchParams: Record<string, string> = useExtractAllParams(location.search);
  const deeplinkParams = { ...searchParams, type };

  /** START OF PARAM CHECKS */

  let typedDeeplinkParams: KnowledgeDeeplinkParams | null = null;

  if (isSMPCGuidelineDeeplinkParams(deeplinkParams)) {
    typedDeeplinkParams = deeplinkParams;
  }

  if (isMedHighlightsDeeplinkParams(deeplinkParams)) {
    typedDeeplinkParams = deeplinkParams;
  }

  if (isBNFDeeplinkParams(deeplinkParams)) {
    typedDeeplinkParams = deeplinkParams;
  }

  if (isNiceGuidelinesDeeplinkParams(deeplinkParams)) {
    typedDeeplinkParams = deeplinkParams;
  }

  if (isClinicalResourceDeeplinkParams(deeplinkParams)) {
    typedDeeplinkParams = deeplinkParams;
  }

  /** END OF PARAM CHECKS */

  /** START OF FETCHES */

  // SMPC Fetch
  const { contentItem, isContentItemLoading, isContentItemError, contentItemError } =
    useFetchKnowledgeContentItem({
      itemId: isSMPCGuidelineDeeplinkParams(typedDeeplinkParams)
        ? typedDeeplinkParams.contentId
        : "",
      enabled: isSMPCGuidelineDeeplinkParams(typedDeeplinkParams),
      domain: "smpcs",
    });

  // Med Highlight Fetch
  const {
    flashcard: medHighlight,
    flashcardLoading: isMedHighlightLoading,
    isError: isMedHighlightError,
    error: medHighlightError,
  } = useFlashcardItem({
    mainSectionId: isMedHighlightsDeeplinkParams(typedDeeplinkParams)
      ? typedDeeplinkParams.mainSectionId
      : "",
    flashcardId: isMedHighlightsDeeplinkParams(typedDeeplinkParams)
      ? typedDeeplinkParams.medicationHighlightId
      : "",
    enabled: isMedHighlightsDeeplinkParams(typedDeeplinkParams),
  });

  // Nice guideline fetch
  const {
    url: niceGuidelineUrl,
    isLoading: isNiceGuidelineLoading,
    isError: isNiceGuidelineError,
    error: niceGuidelineError,
  } = useGetNiceItemUrl(
    isNiceGuidelinesDeeplinkParams(typedDeeplinkParams) ? typedDeeplinkParams.niceSummaryId : "",
    isNiceGuidelinesDeeplinkParams(typedDeeplinkParams), // isEnabled
  );

  let checkClinicalResourceParam = null;

  if (
    isClinicalResourceDeeplinkParams(typedDeeplinkParams) &&
    isClinicalResourceMainSectionType(type)
  ) {
    checkClinicalResourceParam = {
      entityType: mainSectionTypeToNatResourceEntityTypeMapper(type),
      fileId: typedDeeplinkParams.fileId,
      clinicalCalculatorsSubSectionType:
        typedDeeplinkParams.type === CommunityLevelSection.clinicalCalculators
          ? typedDeeplinkParams.clinicalCalculatorsSubSectionType
          : undefined,
    };
  }

  const {
    resource: clinicalResource,
    isLoading: isClinicalResourceLoading,
    error: clinicalResourceError,
    resourceUnavailableReason: clinicalResourceUnavailableReason,
  } = useCheckClinicalResource({
    nationalEntityParams: checkClinicalResourceParam,
    isEnabled: isClinicalResourceDeeplinkParams(typedDeeplinkParams),
  });

  /** END OF FETCHES */

  const entityItem =
    contentItem ||
    medHighlight ||
    clinicalResource ||
    (niceGuidelineUrl ? { niceGuidelineUrl: niceGuidelineUrl } : null);

  const isEntityItemLoading =
    isContentItemLoading ||
    isMedHighlightLoading ||
    isNiceGuidelineLoading ||
    isClinicalResourceLoading;

  const isEntityItemError =
    isContentItemError ||
    isNiceGuidelineError ||
    isMedHighlightError ||
    isDefined(clinicalResourceError) ||
    isDefined(clinicalResourceUnavailableReason);

  const entityItemError =
    contentItemError ||
    niceGuidelineError ||
    medHighlightError ||
    clinicalResourceError ||
    clinicalResourceUnavailableReason;

  /** START OF RETURNS */

  if (entityItem) {
    if (isContentItem(entityItem)) {
      // SMPCs

      if (isSMPCGuidelineDeeplinkParams(typedDeeplinkParams)) {
        const maybeChildReference = sectionStore.getMainSectionChildReference(
          typedDeeplinkParams.mainSectionId,
        );
        if (maybeChildReference && hasProp(maybeChildReference, "id")) {
          const redirectUrl = `/${BaseAppRoute.knowledge}/communityMedications/${
            CommunityMedicationsMiniApp.pharmaResources
          }/${encodeURIComponent(maybeChildReference.id)}/${encodeURIComponent(
            entityItem.parentId,
          )}/${encodeURIComponent(entityItem.id)}`;

          return {
            isLoading: false,
            hasError: false,
            error: null,
            newTabLocation: isLinkContentItem(entityItem) ? entityItem.url : null,
            redirectLocation: !isLinkContentItem(entityItem) ? redirectUrl : null,
            resolvedDeeplinkPayload: {
              entityId: typedDeeplinkParams.contentId,
              type: "file",
              mainSectionType: typedDeeplinkParams.type,
            },
            item: entityItem,
          };
        }

        // failed to get mainSectionChildReference
        return {
          isLoading: false,
          hasError: true,
          error: "unknownError",
          redirectLocation: null,
          newTabLocation: null,
          resolvedDeeplinkPayload: null,
          item: null,
        };
      }
    }

    // Med Highlights
    if (isMedicationHighlight(entityItem) && isMedHighlightsDeeplinkParams(typedDeeplinkParams)) {
      return {
        isLoading: false,
        hasError: false,
        error: null,
        newTabLocation: null,
        redirectLocation: `/${BaseAppRoute.viewer}/${encodeURIComponent(
          typedDeeplinkParams.mainSectionId,
        )}/flash-cards/${encodeURIComponent(entityItem.id)}`,
        resolvedDeeplinkPayload: {
          entityId: typedDeeplinkParams.medicationHighlightId,
          type: "file",
          mainSectionType: typedDeeplinkParams.type,
        },
        item: entityItem,
      };
    }

    if (hasProp(entityItem, "niceGuidelineUrl")) {
      // Nice Guidelines
      if (isNiceGuidelinesDeeplinkParams(typedDeeplinkParams)) {
        return {
          isLoading: false,
          hasError: false,
          error: null,
          newTabLocation: null,
          redirectLocation: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${typedDeeplinkParams.niceSummaryId}`,
          resolvedDeeplinkPayload: {
            entityId: typedDeeplinkParams.niceSummaryId,
            type: "file",
            mainSectionType: typedDeeplinkParams.type,
          },
          item: null,
        };
      }
    }

    if (isChildReference(entityItem)) {
      if (isChildRefEolasCalculator(entityItem) && entityItem.name) {
        return {
          isLoading: false,
          hasError: false,
          error: null,
          newTabLocation: null,
          redirectLocation: `/${
            BaseAppRoute.knowledge
          }/clinicalCalculators/eolas_calculator/${snakeCase(entityItem.name)}/${entityItem.id}`,
          // this condition will always be true
          resolvedDeeplinkPayload: isClinicalResourceDeeplinkParams(typedDeeplinkParams)
            ? {
                entityId: typedDeeplinkParams.fileId,
                type: "file",
                mainSectionType: typedDeeplinkParams.type,
              }
            : null,
          item: entityItem,
        };
      }

      // Unexpected Child Reference
      return {
        isLoading: false,
        hasError: true,
        error: "unknownError",
        redirectLocation: null,
        newTabLocation: null,
        resolvedDeeplinkPayload: null,
        item: null,
      };
    }

    if (isEolasFile(entityItem)) {
      // Clinical Resources
      if (isClinicalResourceDeeplinkParams(typedDeeplinkParams) && entityItem.key) {
        const parsedMetadata = fromAwsJSON(entityItem.metadata);

        if (hasProp(parsedMetadata, "isEolasCalculator") && parsedMetadata.isEolasCalculator) {
          const redirectUrl = `/${
            BaseAppRoute.knowledge
          }/clinicalCalculators/eolas_calculator/${snakeCase(entityItem.name)}/${entityItem.id}`;
          return {
            isLoading: false,
            hasError: false,
            error: null,
            newTabLocation: null,
            redirectLocation: redirectUrl,
            resolvedDeeplinkPayload: {
              entityId: typedDeeplinkParams.fileId,
              type: "file",
              mainSectionType: typedDeeplinkParams.type,
            },
            item: entityItem,
          };
        }
        return {
          isLoading: false,
          hasError: false,
          error: null,
          redirectLocation: null,
          newTabLocation: entityItem.key,
          resolvedDeeplinkPayload: {
            entityId: typedDeeplinkParams.fileId,
            type: "file",
            mainSectionType: typedDeeplinkParams.type,
          },
          item: entityItem,
        };
      }
    }
  }

  if (isBNFDeeplinkParams(typedDeeplinkParams)) {
    return {
      isLoading: false,
      hasError: false,
      error: null,
      newTabLocation: null,
      redirectLocation: makeBnfRoute({
        routeKey: typedDeeplinkParams.subsectionType,
        idOrSid: typedDeeplinkParams.bnfId,
      }),
      resolvedDeeplinkPayload: {
        entityId: typedDeeplinkParams.bnfId,
        type: "file",
        mainSectionType: typedDeeplinkParams.type,
      },
      item: null,
    };
  }

  if (isEntityItemError && entityItemError) {
    if (isAxiosError(entityItemError) && entityItemError.response?.status === 404) {
      return {
        isLoading: false,
        hasError: true,
        error: "fileNotFound",
        redirectLocation: null,
        newTabLocation: null,
        resolvedDeeplinkPayload: null,
        item: null,
      };
    }

    if (isResourceUnavailableReason(entityItemError) && entityItemError !== "error") {
      return {
        isLoading: false,
        hasError: true,
        error: entityItemError === "notInRegion" ? "wrongRegion" : "fileNotFound",
        redirectLocation: null,
        newTabLocation: null,
        resolvedDeeplinkPayload: null,
        item: null,
      };
    }

    return {
      isLoading: false,
      hasError: true,
      error: "unknownError",
      redirectLocation: null,
      newTabLocation: null,
      resolvedDeeplinkPayload: null,
      item: null,
    };
  }

  if (isEntityItemLoading) {
    return {
      isLoading: true,
      hasError: false,
      error: null,
      redirectLocation: null,
      newTabLocation: null,
      resolvedDeeplinkPayload: null,
      item: null,
    };
  }

  if (!typedDeeplinkParams) {
    return {
      isLoading: false,
      hasError: true,
      error: "invalidParams",
      redirectLocation: null,
      newTabLocation: null,
      resolvedDeeplinkPayload: null,
      item: null,
    };
  }

  if (!type || !isSupportedKnowledgeContentDeepLinkType(type)) {
    return {
      isLoading: false,
      hasError: true,
      error: "unSupportedType",
      redirectLocation: null,
      newTabLocation: null,
      resolvedDeeplinkPayload: null,
      item: null,
    };
  }

  // bottomed out
  return {
    isLoading: false,
    hasError: true,
    error: "unknownError",
    redirectLocation: null,
    newTabLocation: null,
    resolvedDeeplinkPayload: null,
    item: null,
  };
};
