import { useTranslation } from "react-i18next";

import { AttentionIcon, success } from "Assets";
import * as coloredIcons from "Assets/Icons/colored";

import { LottieWithHeader } from "UIKit/LottieWithHeader";

import { Button } from "../../Button";
import { Title, Text } from "../../Typography";
import { InnerModalWrapper } from "../components";

export interface DeleteModalProps {
  title: string;
  error?: string;
  isLoading?: boolean;
  description?: string;
  successLabel?: string;
  isSuccessful?: boolean;
  iconType?: coloredIcons.EolasColoredIcons;
  onDelete(): void;
  onCloseModal(): void;
  confirmBtnTxt?: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  error,
  title,
  isLoading,
  isSuccessful,
  successLabel,
  iconType = "DeleteFileIcon",
  description,
  onDelete,
  onCloseModal,
  confirmBtnTxt,
}: DeleteModalProps) => {
  const { t } = useTranslation();

  if (isSuccessful) {
    return (
      <InnerModalWrapper>
        <LottieWithHeader
          animation={success}
          text={successLabel ? successLabel : t("general_success")}
          animationSize="150px"
          onComplete={onCloseModal}
          data-testid="success-animation"
        />
      </InnerModalWrapper>
    );
  }

  const Icon = coloredIcons[iconType];

  return (
    <InnerModalWrapper>
      <div
        className="flex flex-col items-center flex-1 space-y-4 sm:space-y-8 p-6 px-12 sm:p-12"
        data-testid="delete-modal"
      >
        <Icon className="w-12 h-12 sm:w-16 sm:h-16" />

        <Title level={5} className="line-clamp-2 text-center">
          {title}
        </Title>

        <Text level={2} className="text-center text-grey-700 font-semibold">
          {description ? description : t("deleteModal_delete_file_description")}
        </Text>
        <div className="flex flex-col flex-1 w-full">
          <Button
            size="lg"
            color="red"
            variant="outline"
            onClick={onDelete}
            isLoading={isLoading}
            className="w-1/2 self-center sm:w-full mt-4 sm:mt-8"
            data-testid="delete-modal-button"
          >
            {confirmBtnTxt ? confirmBtnTxt : t("general_delete")}
          </Button>

          {error && (
            <div className="flex justify-center items-center text-red mt-4 sm:mt-8">
              <AttentionIcon className="w-6 h-6 mr-2" />
              <Text level={2} className="font-semibold">
                {error}
              </Text>
            </div>
          )}
        </div>
      </div>
    </InnerModalWrapper>
  );
};
