import OneSignal from "react-onesignal";
import { isAxiosError } from "axios";

import { AnalyticsEvents, accessClient, inviteClient, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";

export type CreateSpaceUserOutcome =
  | "success"
  | "alreadyExists"
  | "inviteCompleted"
  | "wrongAccount"
  | "deleted"
  | "error"
  | "nil";

export type CreateSpaceUserReturn = {
  spaceId?: string | undefined;
  outcome: CreateSpaceUserOutcome;
};

export const checkInvite = async (
  inviteId: string,
  currentUserId?: string,
): Promise<
  { outcome: CreateSpaceUserOutcome } & { email: string | null; userID?: string; appID?: string }
> => {
  try {
    const { email, userID, status, appID } = await inviteClient.getDetails(inviteId);
    if (currentUserId && userID && userID !== currentUserId) {
      return { outcome: "wrongAccount", email };
    }
    if (["Accepted", "AlreadyAccepted", "AlreadyExists"].includes(status)) {
      return { outcome: "inviteCompleted", email, userID };
    }
    return { outcome: "nil", email, userID, appID };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 404) {
        return { outcome: "deleted", email: null };
      }
    }
    return { outcome: "error", email: null };
  }
};

// Should only fire after user is logged in:
export const createSpaceUserAfterInvite = async (
  inviteId: string | null,
): Promise<CreateSpaceUserReturn> => {
  if (!inviteId) {
    return { outcome: "nil" };
  }
  try {
    const userId = userStore.userID;
    const { email, outcome, appID } = await checkInvite(inviteId, userId);
    if (email && outcome === "nil") {
      await inviteClient.createAppUser(inviteId, userId, email);
    } else {
      return { outcome };
    }
    trackEvent(AnalyticsEvents.ACCEPT_INVITE_DURATION);
    trackEvent(AnalyticsEvents.ACCEPT_INVITE_COMPLETED);
    OneSignal.sendTag("startedQuickDepartmentAccessDate", "null");
    return { outcome: "success", spaceId: appID };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 404) {
        return { outcome: "deleted" };
      }
    }
    if (error instanceof Error) {
      errorStore.captureError({ error, source: "network" });
    }
    return { outcome: "error" };
  }
};

// Should only fire after user is logged in:
export const createSpaceUserWithAccessToken = async (
  accessToken: string | null,
): Promise<CreateSpaceUserReturn> => {
  if (!accessToken) {
    return { outcome: "nil" };
  }
  const userId = userStore.userID;
  if (!userId) {
    return { outcome: "error" };
  }
  try {
    const { departmentId } = await accessClient.createUserAccessFromToken(accessToken, userId);
    trackEvent(AnalyticsEvents.INVITE_VIA_ACCESS_LINK_SUCCESS, { userId });
    return { outcome: "success", spaceId: departmentId };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 409) {
        return { outcome: "alreadyExists" };
      }
      if (error.response?.status === 404) {
        return { outcome: "deleted" };
      }
    }
    if (error instanceof Error) {
      errorStore.captureError({ error, source: "network" });
    }
    return { outcome: "error" };
  }
};
