import React from "react";
import { LayoutGroup, motion } from "framer-motion";

import { LDNotificationBanner } from "Components/Notifications/LDNotificationBanner";

import { useNotificationsBanner } from "./hooks";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { Navigation } from "Components/Navigation";
import { twMerge } from "tailwind-merge";
import BackgroundCover from "./components/BackgroundCover/BackgroundCover";

interface PageContainerProps {
  children: React.ReactNode;
}

export const PageContainer: React.FC<PageContainerProps> = ({ children }: PageContainerProps) => {
  const { notificationText, bannerNotSeen, setBannerNotSeen } = useNotificationsBanner();
  const { drawerExpanded = false, drawerHidden = false } = useEolasNavigation();

  let backgroundMargin = "w-full";

  if (!drawerHidden) {
    backgroundMargin = drawerExpanded
      ? "w-full lg:w-[calc(100%-theme(spacing.56)+1rem)]"
      : "w-full lg:w-[calc(100%-theme(spacing.20)+1rem)]";
  }

  return (
    <div className="h-100vh flex flex-1">
      <LayoutGroup>
        {!drawerHidden && <Navigation />}

        <motion.main
          layout
          // we need this to set the virtual list scroll parent
          id="page-container"
          className={twMerge(
            "flex flex-col flex-1 h-100vh overflow-y-auto overflow-x-hidden z-5 relative",
            backgroundMargin,
          )}
          transition={{ type: "spring", stiffness: 98, damping: 13 }}
        >
          <div className={twMerge("absolute lg:right-0 flex-1", backgroundMargin)}>{children}</div>
          <BackgroundCover drawerExpanded={drawerExpanded} drawerHidden={drawerHidden} />
          {notificationText && bannerNotSeen && (
            <LDNotificationBanner
              drawerExpanded={drawerExpanded}
              onClose={() => setBannerNotSeen(false)}
              text={notificationText}
            />
          )}
        </motion.main>
      </LayoutGroup>
    </div>
  );
};
