import { FlashcardCreatorStep } from "./FlashcardCreatorStep";
import { Editor as TinyMCEEditor } from "tinymce";
import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Step } from "UIKit/Wizard";
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useForm } from "react-hook-form";
import { useFlashcardValidationSchema } from "./useFlashcardValidationSchema";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { DeeplinkConfig } from "shared/components/EolasMCEEditor/hooks/useDeeplinkingSetup";

interface UseFlashcardCreatorStepProps {
  pageTitle: string;
  editorContent?: string;
  onSubmit: (flashcardContent: string) => void;
  contentRepositoryId: ContentRepositoryId;
  modalBodyRef?: RefObject<HTMLDivElement>;
  flashcardTitle: string;
  flashcardDescription: string;
  currentItemId?: string;
}

export const useFlashcardCreatorStep = <T extends string>({
  pageTitle,
  editorContent,
  onSubmit,
  contentRepositoryId,
  modalBodyRef,
  flashcardTitle,
  flashcardDescription,
  currentItemId,
}: UseFlashcardCreatorStepProps): Step<T> => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const validationSchema = useFlashcardValidationSchema();
  const { activeTab } = useEolasNavigation();

  const isDeeplinkingInEditorAllowed = activeTab !== "knowledge";

  const editorConfigs: { deeplinkingConfig: DeeplinkConfig } = useMemo(
    () => ({
      deeplinkingConfig: {
        isEnabled: isDeeplinkingInEditorAllowed,
        disabledIds: currentItemId ? [currentItemId] : undefined,
      },
    }),
    [isDeeplinkingInEditorAllowed, currentItemId],
  );

  const { formState, setValue, handleSubmit, getValues } = useForm<{ content: string }>({
    defaultValues: { content: editorContent },
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitFlashcard = () => {
    const { content } = getValues();
    onSubmit(content);
  };

  const onEditorInit = useCallback((evt: unknown, editor: TinyMCEEditor) => {
    setIsLoading(false);
    editorRef.current = editor;
  }, []);

  const handleContentChange = (content: string) => {
    setValue("content", content);
  };

  const canProceed = formState.isSubmitSuccessful && !formState.isSubmitting && !isLoading;

  return {
    name: "flashcard-creator" as T,
    title: pageTitle,
    canProceed,
    onSubmit: handleSubmit(handleSubmitFlashcard),
    component: (
      <FlashcardCreatorStep
        contentRepositoryId={contentRepositoryId}
        editorContent={editorContent}
        isLoading={isLoading}
        error={formState.errors["content"]?.message}
        onEditorInit={onEditorInit}
        onContentChange={handleContentChange}
        modalBodyRef={modalBodyRef}
        flashcardTitle={flashcardTitle}
        flashcardDescription={flashcardDescription}
        handleSubmitFlashcard={handleSubmitFlashcard}
        editorConfigs={editorConfigs}
      />
    ),
  };
};
