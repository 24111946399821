import React from "react";
import IndividualInteraction from "./IndividualInteraction";
import { EolasInteractionsMessage, makeTitle } from "@eolas-medical/core";
import InteractionsCollapsingSection from "./InteractionsCollapsingSection";

interface DrugInteractionProps {
  currentSid?: string;
  interaction: EolasInteractionsMessage;
}

const DrugInteraction: React.FC<DrugInteractionProps> = ({ currentSid, interaction }) => {
  const title = makeTitle({ currentSid, interaction });
  const containsSevere = interaction.messages.some(({ severityOrder }) => severityOrder > 3);
  const allSevere = interaction.messages.every(({ severityOrder }) => severityOrder > 3);

  return (
    <InteractionsCollapsingSection
      title={title}
      shouldMountExpanded={true}
      headerContainerBackgroundColor={containsSevere ? "bg-red-50" : "bg-white"}
      childrenContainerBackgroundColor={allSevere ? "bg-red-100" : "bg-white"}
    >
      {interaction.messages.map((message, index, arr) => (
        <IndividualInteraction
          key={`eolas_interaction_message_${index}`}
          message={message}
          index={index}
          arr={arr}
        />
      ))}
    </InteractionsCollapsingSection>
  );
};

export default DrugInteraction;
