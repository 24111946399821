import * as yup from "yup";
import i18n from "i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthFunctionNames, AnalyticsEvents, authenticationClient } from "@eolas-medical/core";

import { logout } from "Pages/Authentication/auth.actions";
import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";

import { LoginCredentials } from "../types";

interface VerificationCodeForm {
  code: string;
}

const schema = yup.object().shape({
  code: yup.string().required(i18n.t("createAccount_verifyEmail_error")).label("Text"),
});

export const useVerificationCodeStep = (
  { email }: LoginCredentials,
  onVerificationSuccess: () => void,
  isFromInvitation?: boolean,
) => {
  const { showNotification } = useNotifications();
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { control, handleSubmit } = useForm<VerificationCodeForm>({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const onResendCode = () => {
    authenticationClient.resendSignUp({ email: email.toLowerCase() });
    trackEvent(AnalyticsEvents.RESEND_VERIFICATION_CODE);
    showNotification({
      type: "success",
      autoHideTimeout: 5000,
      description: "Sent another code. Check your email.",
    });
  };

  const onSubmit = handleSubmit(async ({ code }) => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await authenticationClient.confirmSignUp({ email: email.toLowerCase(), code });
      trackEvent(AnalyticsEvents.SIGN_UP_EMAIL_VERIFIED);

      if (isFromInvitation) {
        trackEvent(AnalyticsEvents.ACCEPT_INVITE_COMPLETED);
      } else {
        trackEvent(AnalyticsEvents.SIGN_UP_USER_CREATED);
      }
      onVerificationSuccess();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.confirmSignUp,
      });
      setRequestStatus({ status: "error", error: errorMessage });
      logout();
    }
  });

  return {
    error,
    control,
    isLoading,
    onSubmit,
    onResendCode,
  };
};
