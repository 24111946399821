import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites/types";
import { getParentName, getParentNameCustomColor } from "../functions/helpers";
import { AdultHead, BabyHead, OrganisationIcon, SpacesIcon } from "Assets";

export const ShowItemOwner = ({
  ownerName,
  entityType,
  entityOwnerType,
  parentSectionName,
}: {
  ownerName?: string;
  entityType: string;
  entityOwnerType: EntityOwnerType;
  parentSectionName?: string;
}) => {
  const parentName = getParentName({
    parentSectionName,
    entityOwnerType,
    entityType,
  });

  const Icon = ({
    entityOwnerType,
    parentSectionName,
  }: {
    entityOwnerType: EntityOwnerType;
    parentSectionName?: string;
  }) => {
    if (entityOwnerType === EntityOwnerType.SPACE) {
      return <SpacesIcon className="h-4 w-4 mr-2 text-blue-500" />;
    }
    if (entityOwnerType === EntityOwnerType.ORGANISATION) {
      return <OrganisationIcon className="h-4 w-4 mr-2 text-orange-500" />;
    }

    if (entityOwnerType === EntityOwnerType.KNOWLEDGE) {
      if (parentSectionName === "bnf") {
        return <AdultHead className="h-4 w-4 mr-1" />;
      }
      if (parentSectionName === "bnfc") {
        return <BabyHead className="h-4 w-4 mr-1" />;
      }
      return null;
    }

    return null;
  };

  const parentNameCustomColor = getParentNameCustomColor({
    parentSectionName,
    entityOwnerType,
  });

  return (
    <div className="flex mb-2 mt-6 items-center">
      {Icon({ entityOwnerType, parentSectionName })}
      <p className={`line-clamp-2 font-bold ${parentNameCustomColor}`}>{ownerName || parentName}</p>
    </div>
  );
};
