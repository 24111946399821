import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useConfigureFileStep,
  useUploadProgressAndSuccessStep,
  useWizard,
} from "UIKit";

import { useAddEolasEditorItemActions } from "./hooks/useAddEolasEditorItemActions";
import { AddEolasEditorItemWizardStep } from "./types";
import { useRef } from "react";
import { useEolasEditorStep } from "UIKit/Wizard/templates/EolasEditorCreatorStep";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { observer } from "mobx-react-lite";

export interface AddEolasEditorItemWizardProps {
  mainSectionId: string;
  cancelOnBack?: boolean;
  onClose: () => void;
  onChangeWizard: () => void;
}

const AddEolasEditorItemWizard = observer(
  ({ mainSectionId, onChangeWizard, onClose }: AddEolasEditorItemWizardProps) => {
    const { t } = useTranslation();
    const { wizardControl, submitWizard } = useWizard<AddEolasEditorItemWizardStep>();
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const { flags } = useLaunchDarkly();
    const allowDrafting = flags[LDFlagNames.ALLOW_DRAFTING_ITEMS] ?? false;

    const {
      wizardState,
      addEolasEditorItemError,
      addingEolasEditorItem,
      addEolasEditorItemSuccess,
      addEolasEditorItemProgress,
      onAddEolasEditorDetails,
      onAddEolasEditorContent,
    } = useAddEolasEditorItemActions();

    const getPrevStep = (currentStep: AddEolasEditorItemWizardStep) => {
      if (currentStep === "eolas-editor-form") {
        onChangeWizard();
      }

      if (currentStep === "eolas-editor-creator") {
        return "eolas-editor-form";
      }

      if (currentStep === "upload-progress") {
        return "eolas-editor-creator";
      }

      return currentStep;
    };

    const eolasEditorForm = useConfigureFileStep<"eolas-editor-form">({
      stepName: "eolas-editor-form",
      title: t("repository_add_item_title"),
      onEnter: submitWizard,
      onSubmit: onAddEolasEditorDetails,
      item: { ...wizardState },
      toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
      toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
      expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
      nameInputLabel: t("repository_file_name_label"),
      nameInputPlaceholder: t("repository_file_name_placeholder"),
      keyWordsLabel: t("repository_file_keywords_label"),
      keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
      nextLabel: t("addFile_wizard_next_button"),
    });

    const eolasEditorCreator = useEolasEditorStep<"eolas-editor-creator">({
      pageTitle: t("eolasEditor_page_title"),
      editorContent: wizardState?.content,
      onSubmit: onAddEolasEditorContent,
      nextLabel: t("content_item_publish"),
      contentRepositoryId: mainSectionId,
      modalBodyRef: modalBodyRef,
    });

    const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
      stepName: "upload-progress",
      fileUploading: addingEolasEditorItem,
      uploadProgress: addEolasEditorItemProgress,
      fileUploadError: addEolasEditorItemError,
      fileUploadedSuccess: addEolasEditorItemSuccess,
      onFinishUpload: onClose,
    });

    const steps: Step<AddEolasEditorItemWizardStep>[] = [
      eolasEditorForm,
      eolasEditorCreator,
      uploadProgressAndSuccessStep,
    ];

    const getStepClassName = (step: string) => {
      if (step === "eolas-editor-creator") {
        return "max-w-6xl h-90vh p-0";
      }
      return "";
    };

    const showAltSubmit = (step: string) => {
      if (!allowDrafting) return false;
      else {
        return step === "eolas-editor-creator";
      }
    };

    return (
      <Wizard
        getStepClassName={getStepClassName}
        steps={steps}
        getPrevStep={getPrevStep}
        wizardControl={wizardControl}
        modalBodyRef={modalBodyRef}
        showAltSubmit={showAltSubmit}
        altSubmitLabel={t("content_item_draft_label")}
      />
    );
  },
);

export default AddEolasEditorItemWizard;
