import { accessClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

export const useRequestAccess = (userId: string) => {
  const { mutate, isLoading, isSuccess } = useMutation(
    ({ requestText, spaceId }: { requestText?: string; spaceId: string }) =>
      accessClient.requestDepartmentAccess(userId, spaceId, requestText),
  );

  return {
    requestAccess: mutate,
    requestingAccess: isLoading,
    requestAccessSuccessful: isSuccess,
  };
};
