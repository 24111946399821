import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SummaryOfChangesStep } from "./SummaryOfChangesStep";
import { useSummaryOfChangesStepValidationSchema } from "./useSummaryOfChangesValidationSchema";
import { SummaryOfChangesValues } from "./types";
import { Step } from "../../types";
import { useEolasNavigation } from "Components/Navigation/hooks";

interface UseSummaryOfChangesStepProps {
  onSubmit: (data: SummaryOfChangesValues) => void;
  title: string;
  isLoading?: boolean;
  nextLabel?: string;
  backLabel?: string;
  submitError?: string;
}

export const useSummaryOfChangesStep = ({
  onSubmit,
  isLoading,
  title,
  nextLabel,
  backLabel,
  submitError,
}: UseSummaryOfChangesStepProps): {
  step: Step<"summary-of-changes">;
  canAddSummaryOfChanges: boolean;
} => {
  const { activeTab } = useEolasNavigation();

  const validationSchema = useSummaryOfChangesStepValidationSchema();

  const { handleSubmit, formState, control } = useForm<SummaryOfChangesValues>({
    defaultValues: { summaryOfChanges: "" },
    resolver: yupResolver(validationSchema),
  });

  const canProceed = formState.isValid && !formState.isSubmitting && !isLoading;

  return {
    step: {
      name: "summary-of-changes",
      title,
      submitError: !!submitError || !!formState.errors,
      canProceed: canProceed,
      onSubmit: handleSubmit(onSubmit),
      isLoading,
      nextLabel,
      backLabel,
      component: <SummaryOfChangesStep control={control} errors={formState.errors} />,
    },
    canAddSummaryOfChanges: activeTab === "spaces" || activeTab === "organisation",
  };
};
