import { KnowledgeContentDomain } from "@eolas-medical/core";
import { modalStore } from "Stores/ModalStore";
import { DeleteModal, SuccessModal } from "UIKit";
import { ContentSection } from "modules/contentRepository/types";
import useDeleteKnowledgeContentSection from "modules/knowledgeContent/data/useDeleteKnowledgeContentSection";
import { useTranslation } from "react-i18next";

type Props = {
  contentSection: ContentSection;
  domain: KnowledgeContentDomain;
};

export const DeleteKnowledgeSectionModal = ({ contentSection, domain }: Props) => {
  const { t } = useTranslation();

  const {
    deleteContentSection,
    deletingContentSection,
    deleteContentSectionSuccess,
    deleteContentSectionError,
  } = useDeleteKnowledgeContentSection({ domain });

  const handleDeleteSection = () => {
    deleteContentSection(contentSection);
  };

  if (deleteContentSectionSuccess) {
    return (
      <SuccessModal
        onComplete={modalStore.closeModal}
        text={t("my_files_delete_section_successful")}
      />
    );
  }

  return (
    <div data-testid="delete-content-section-modal">
      <DeleteModal
        title={t("my_files_delete_section")}
        onCloseModal={modalStore.closeModal}
        onDelete={handleDeleteSection}
        isLoading={deletingContentSection}
        error={deleteContentSectionError}
        description={t("deleteModal_delete_section_description")}
      />
    </div>
  );
};
