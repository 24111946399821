import React from "react";
import { Modal } from "UIKit";
import { observer } from "mobx-react-lite";
import useCompleteProfileModal from "./hooks/useCompleteProfileModal";
import useUpdateUserPhoto from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserPhoto";
import useUpdateUserProfile from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserProfile";
import { AnalyticsEvents, UserData, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import EditProfileModal from "Pages/MeTab/pages/MyProfile/components/EditProfileModal/EditProfileModal";
import { webStore } from "Stores/WebStore";

const CompleteProfileModal = observer(() => {
  const { modalIsOpen, onCloseModal, forceProfileUpdate } = useCompleteProfileModal();
  const { updateUserPhoto, updatingUserPhoto } = useUpdateUserPhoto("");
  const { updateUser, updatingUser } = useUpdateUserProfile();
  const { id: userId } = userStore.userData;

  const handleUpdateUserProfile = (userData: UserData, file: { blob: Blob | null }) => {
    if (file.blob) updateUserPhoto({ userId: userId, ...file });
    updateUser(
      { userId: userId as string, userData },
      {
        onSuccess: (user) => {
          if (userId) {
            userStore.updateData({ user });
            webStore.setShowPostCognitoModal(true);
            trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId: userId });
            onCloseModal();
          }
        },
      },
    );
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={onCloseModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      hasCloseIcon={!forceProfileUpdate}
    >
      <EditProfileModal
        onClose={!forceProfileUpdate ? () => onCloseModal() : undefined}
        onSubmit={handleUpdateUserProfile}
        isLoading={updatingUser || updatingUserPhoto}
        shouldAllowUpdateProfileImage={false}
      />
    </Modal>
  );
});

export default CompleteProfileModal;
