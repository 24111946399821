import { useTranslation } from "react-i18next";

import { DeleteFileIcon } from "Assets";
import { CompletedChecklist, EolasFile } from "@eolas-medical/core";
import { InnerModalWrapper, Title, Text, Button, FormError, SuccessModal } from "UIKit";

import { useDeleteSubmittedChecklist } from "../hooks";

export interface DeleteSubmittedChecklistModalProps {
  checklist: EolasFile;
  completedChecklist: CompletedChecklist;
  closeModal(): void;
}

export const DeleteSubmittedChecklistModal: React.FC<DeleteSubmittedChecklistModalProps> = ({
  checklist,
  completedChecklist,
  closeModal,
}: DeleteSubmittedChecklistModalProps) => {
  const { t } = useTranslation();
  const { isSuccessful, isLoading, error, onDelete } = useDeleteSubmittedChecklist(checklist);

  if (isSuccessful) {
    return (
      <SuccessModal text="submittedChecklists_delete_submission_success" onComplete={closeModal} />
    );
  }

  return (
    <InnerModalWrapper>
      <div className="p-6 px-12 sm:p-12">
        <div className="flex flex-col items-center flex-1 space-y-4 sm:space-y-8">
          <DeleteFileIcon className="w-12 h-12 sm:w-16 sm:h-16" />

          <Title level={5} className="line-clamp-2 text-center">
            {t("submittedChecklists_delete_submission_title")}
          </Title>

          <Text level={1} className="font-semibold text-center ">
            {completedChecklist.checklistName}
          </Text>
        </div>

        <Button
          size="lg"
          color="red"
          variant="outline"
          onClick={onDelete}
          isLoading={isLoading}
          className="w-1/2 self-center sm:w-full mt-4 sm:mt-8"
        >
          {t("general_delete")}
        </Button>

        <FormError error={error} />
      </div>
    </InnerModalWrapper>
  );
};
