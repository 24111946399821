import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import SpaceDetails from "./SpaceDetails";
import { trackEvent } from "API/Analytics";
import {
  AnalyticsEvents,
  Space,
  SpecialtyOptions,
  UserLocation,
  specialtyOptions,
} from "@eolas-medical/core";
import useValidateSpaceName from "modules/spaces/data/useValidateSpace";
import { formatLocation } from "Utilities/helpers";
import { useMemo } from "react";

interface useSpaceDetailsStepProps {
  onSubmit: ({
    name,
    specialty,
    description,
    location,
  }: {
    name: string;
    description: string;
    specialty?: SpecialtyOptions[];
    location?: UserLocation;
  }) => void;
  selectedSpace?: Space;
  onEnter: () => void;
  backDisabled?: boolean;
  isEditing?: boolean;
  isOrgAdminSpace?: boolean;
}

export const useSpaceDetailsStep = ({
  onSubmit,
  onEnter,
  selectedSpace,
  backDisabled = false,
  isEditing = false,
  isOrgAdminSpace = false,
}: useSpaceDetailsStepProps): Step<"details"> => {
  const { t } = useTranslation();

  const { validateSpace } = useValidateSpaceName();

  const schema = yup.object().shape({
    name: yup.string().required(t("validation_name_required")),
    specialty: yup.array().min(1, t("validation_specialty_required")).nullable(),
    description: yup.string().required(t("validation_description_required")),
    location: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .test(
        "check-object-properties",
        t("validation_location_required"),
        (value: { label: string | undefined; value: string | undefined } | undefined) => {
          return value?.label?.trim() !== "" || value?.value?.trim() !== "" || isOrgAdminSpace;
        },
      ),
  });

  const formatedLocation = selectedSpace?.location
    ? formatLocation({ location: selectedSpace?.location })
    : undefined;

  const defaultSpecialties = useMemo(() => {
    const selectedSpecialties = selectedSpace?.specialty?.flatMap((spec) => spec.split(","));

    return specialtyOptions.filter((option) => {
      return selectedSpecialties?.some((spec: string) => {
        return spec === option.value;
      });
    });
  }, [selectedSpace]);

  const { control, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: {
      name: selectedSpace?.name || "",
      specialty: defaultSpecialties || ([] as SpecialtyOptions[]),
      description: selectedSpace?.description || "",
      location: formatedLocation || { label: "", value: "" },
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitSpaceDetails = async () => {
    const { name, description, specialty, location } = getValues();
    const city = location?.value?.split(",")[0];
    const country = location?.value?.split(",")[1];
    const locationDetails = { city, country };

    const { name: spaceNameIsValid } = await validateSpace({
      spaceName: name,
      spaceId: selectedSpace?.id,
    });
    if (!spaceNameIsValid) {
      setError("name", { message: t("space_name_exists_error") });
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_ALREADY_EXISTS);
    } else {
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
      onSubmit({ name, description, specialty, location: locationDetails });
    }
  };

  return {
    name: "details",
    title: isOrgAdminSpace
      ? t("space_details_edit_organisation")
      : t(isEditing ? "space_details_edit_space" : "space_create_general_title"),
    component: (
      <SpaceDetails
        control={control}
        autoFocus
        onSubmit={onEnter}
        isNameValid={!formState.errors.name ? formState.dirtyFields.name : undefined}
        isOrgAdminSpace={isOrgAdminSpace}
      />
    ),
    onSubmit: handleSubmit(handleSubmitSpaceDetails),
    canProceed: formState.isSubmitSuccessful,
    isLoading: formState.isSubmitting,
    backDisabled,
  };
};
