import { sectionStore } from "@eolas-medical/core";
import {
  createSectionNode,
  ListItem,
} from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";

export const convertToSectionData = (sectionArray: string[]): Record<string, ListItem> | null => {
  if (!sectionArray?.length) {
    return null;
  }
  return sectionArray.reduce<Record<string, ListItem>>((acc, current) => {
    const childRef = sectionStore.getChildReferenceOfSection(current);
    if (!childRef) {
      return acc;
    }
    const item = createSectionNode({
      item: childRef,
      leafType: "sectionWithFiles",
      disabledIds: [],
      otherSectionsToInclude: "all",
    });
    if (!item) {
      return acc;
    }
    return { ...acc, [current]: item };
  }, {});
};
