import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { PageTitle, InnerPageWrapper, GridContainer } from "UIKit";
import { RouteComponentProps } from "react-router-dom";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import KnowledgeSectionList from "./components/KnowledgeSectionList/KnowledgeSectionList";
import useKnowledgeSections from "modules/mainSections/data/useKnowledgeSections";

export const Knowledge: React.FC<RouteComponentProps> = observer(() => {
  const { t } = useTranslation();
  const { knowledgeSections } = useKnowledgeSections();

  return (
    <InnerPageWrapper>
      <div className="mt-20 space-y-36">
        <PageTitle
          subTitle={t("pageSubTitle")}
          data-testid="knowledge-title"
          title={t("knowledge_title")}
          titleClass="text-white"
          subTitleClass="text-white text-xl font-semibold"
        />

        <MasterSearch />
      </div>

      <GridContainer>
        <KnowledgeSectionList knowledgeSectionList={knowledgeSections} />
      </GridContainer>
    </InnerPageWrapper>
  );
});
