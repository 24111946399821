import { useTranslation } from "react-i18next";
import ImagePicker from "UIKit/ImagePicker/ImagePicker";
import useImageBank from "Hooks/useImageBank";
import { DefaultImage } from "Pages/Spaces/pages/SelectSpace/types";

interface SpaceImageProps {
  onChangeBlob: (file: File | null) => void;
  onChangeImageUrl: (imageUrl: string) => void;
  defaultImage?: DefaultImage;
}
const SpaceImage = ({ onChangeBlob, onChangeImageUrl, defaultImage }: SpaceImageProps) => {
  const { t } = useTranslation();

  const { imageBank, imageBankLoading } = useImageBank();

  return (
    <ImagePicker
      galleryImages={imageBank}
      errorDraggedImageText={t("imagePicker_too_large_error")}
      isLoading={imageBankLoading}
      onChangeBlob={onChangeBlob}
      onChangeImageUrl={onChangeImageUrl}
      cropperAspectRatio={4}
      defaultImage={defaultImage}
      shouldAllowBlankSelection={false}
    />
  );
};

export default SpaceImage;
