export const removePlugins = (plugins: string[], pluginsToRemove: string[]) => {
  return plugins.filter((plugin) => !pluginsToRemove.includes(plugin));
};

export const combinePlugins = (plugins: string[], pluginsToAddArg: string[] | string) => {
  const pluginsToAdd = Array.isArray(pluginsToAddArg)
    ? pluginsToAddArg
    : pluginsToAddArg.split(" ");
  return [...new Set([...plugins, ...pluginsToAdd])];
};
