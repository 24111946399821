import { format } from "date-fns";
import {
  BlobContent,
  LinkContent,
  SupportedFileType,
  isLinkContentItem,
} from "@eolas-medical/core";
import { Dropdown, DropdownOption, FileTypeItemIcon, Modal, OperationsButton, Text } from "UIKit";
import { useCallback, useMemo, useState } from "react";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useHistory } from "react-router-dom";
import EditContentItemModal from "shared/pages/ContentRepository/ContentItems/components/EditContentItemModal/EditContentItemModal";
import DeleteContentItemModal from "shared/pages/ContentRepository/ContentItems/components/DeleteContentItemModal/DeleteContentItemModal";
import { DeleteIcon, EditMonoIcon } from "Assets";
import { observer } from "mobx-react-lite";
import { convertToLink } from "Utilities";

enum Action {
  Delete = "Delete",
  Edit = "Edit",
}

interface ExpiringItemTileProps {
  contentItem: LinkContent | BlobContent;
}

const ExpiringItemTile = observer(({ contentItem }: ExpiringItemTileProps) => {
  const { push } = useHistory();
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const { getBlobViewerUrl } = useContentRepositoryContext();

  const handleCloseModal = useCallback(() => setModal(null), []);

  const handleClickItem = useCallback(() => {
    if (isLinkContentItem(contentItem)) {
      window.open(convertToLink(contentItem.url), "_blank");
    } else {
      push(getBlobViewerUrl({ blob: contentItem }));
    }
  }, [push, contentItem, getBlobViewerUrl]);

  const handleModalChange = useCallback((modalComponent: JSX.Element | null) => {
    setModal(modalComponent);
  }, []);

  const menuOptions = useMemo(
    () => [
      {
        label: Action.Delete,
        value: Action.Delete,
        icon: <DeleteIcon width={16} height={16} />,
      },
      {
        label: Action.Edit,
        value: Action.Edit,
        icon: <EditMonoIcon width={16} height={16} />,
      },
    ],
    [],
  );

  const handleActionMenuSelect = useCallback(
    (option: DropdownOption) => {
      const action = option.value as Action;
      switch (action) {
        case Action.Delete:
          handleModalChange(
            <DeleteContentItemModal contentItem={contentItem} onClose={handleCloseModal} />,
          );
          break;
        case Action.Edit:
          handleModalChange(
            <EditContentItemModal contentItem={contentItem} onClose={handleCloseModal} />,
          );
          break;
        default:
          console.warn("Unhandled action:", action);
      }
    },
    [contentItem, handleCloseModal, handleModalChange],
  );

  return (
    <>
      <Modal open={!!modal} onClose={handleCloseModal}>
        {modal}
      </Modal>
      <div
        className="flex justify-between items-center bg-white rounded-lg border border-grey-300 p-5 cursor-pointer relative"
        onClick={handleClickItem}
      >
        <div className="flex items-center justify-center  w-1/12">
          <FileTypeItemIcon
            type={(contentItem.media?.type || "link") as SupportedFileType}
            className="w-10 h-10"
          />
        </div>
        <div className="flex items-center space-x-2 w-9/12 line-clamp-3">
          <Text level={1}>{contentItem.name}</Text>
        </div>

        <div className="w-2/12 flex flex-col items-center justify-center">
          {menuOptions.length > 0 && (
            <Dropdown
              dropdownToggle={<OperationsButton className="static" size="xs" />}
              options={menuOptions}
              onSelect={handleActionMenuSelect}
            />
          )}
          {contentItem.expiryDate && (
            <Text level={2} className="text-grey-600 mt-3">
              {format(new Date(contentItem.expiryDate), "do MMMM yyyy")}
            </Text>
          )}
        </div>
      </div>
    </>
  );
});

export default ExpiringItemTile;
