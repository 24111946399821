import useRemoteIcons from "Hooks/useRemoteIcons";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { ContentSectionVariants } from "shared/pages/ContentRepository/types";

interface AddContentSectionModalProps {
  variant: ContentSectionVariants;
  onClose: () => void;
}

const AddContentSectionModal = ({ variant, onClose }: AddContentSectionModalProps) => {
  const { remoteIcons } = useRemoteIcons();
  const { wizardControl, submitWizard } = useWizard();

  const { useAddContentSectionHook } = useContentRepositoryContext();
  const { addContentSection, addingContentSection, addContentSectionError } =
    useAddContentSectionHook;

  const handleSubmit = ({
    name,
    description,
    iconName,
  }: {
    name: string;
    description?: string;
    iconName: string;
  }) => {
    addContentSection(
      {
        name,
        iconName,
        description,
        childrenType: "file",
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const createSectionStep = useConfigureSectionStep<"section">({
    title: "Add new Section",
    stepName: "section",
    iconsList: remoteIcons,
    isLoading: addingContentSection,
    error: addContentSectionError,
    showDescription: variant === "titleAndDescription",
    disableIconPicker: variant === "titleAndDescription",
    onSubmit: handleSubmit,
    onEnter: submitWizard,
    onCancel: onClose,
  });

  const steps: Step<"section">[] = [createSectionStep];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};

export default AddContentSectionModal;
