import { useMemo } from "react";
import { ActionButton, ActionButtonType } from "modules/portfolio/types";

interface UseEventFormButtonsProps {
  actionButtons: ActionButton[];
  isDirty: boolean;
  isNew: boolean;
  formHasAttachments: boolean;
  exportingPdfEvent: boolean;
  onSave: () => void;
  onSubmit: () => void;
  onComplete: () => void;
  onDelete: () => void;
  onContinue: () => void;
  onAssignAssessor: () => void;
  onViewQRCode: () => void;
  onViewFeedback: () => void;
  onExportPdfEvent: () => void;
}

const useEventFormButtons = ({
  actionButtons,
  isDirty,
  isNew,
  formHasAttachments,
  exportingPdfEvent,
  onSave,
  onSubmit,
  onComplete,
  onDelete,
  onContinue,
  onAssignAssessor,
  onViewQRCode,
  onViewFeedback,
  onExportPdfEvent,
}: UseEventFormButtonsProps): ActionButton[] => {
  const newEventButtons: ActionButton[] = useMemo(() => {
    return actionButtons
      .filter((ac) => {
        switch (ac.type) {
          case ActionButtonType.SHARE_ATTACHMENTS_TOGGLE:
            return formHasAttachments;
          case ActionButtonType.SAVE_DRAFT:
            return isDirty;
          case ActionButtonType.DELETE_FORM:
            return isNew;

          default:
            return true;
        }
      })
      .map((ac) => {
        switch (ac.type) {
          case ActionButtonType.SAVE_DRAFT:
            return {
              ...ac,
              onClick: onSave,
            };
          case ActionButtonType.GENERATE_FEEDBACK:
            return {
              ...ac,
              onClick: onSubmit,
            };
          case ActionButtonType.DELETE_FORM:
            return {
              ...ac,
              onClick: onDelete,
            };
          case ActionButtonType.OPTIONAL_ASSIGN_ASSESSOR_AND_SUBMIT:
            return {
              ...ac,
              onClick: onContinue,
            };
          case ActionButtonType.MANDATORY_ASSIGN_ASSESSOR_AND_SUBMIT:
            return {
              ...ac,
              onClick: onAssignAssessor,
            };
          case ActionButtonType.COMPLETE_EVENT:
          case ActionButtonType.COMPLETE_EVENT_WITHOUT_FEEDBACK:
            return {
              ...ac,
              onClick: onComplete,
            };
          case ActionButtonType.VIEW_QR_CODE:
            return {
              ...ac,
              onClick: onViewQRCode,
            };
          case ActionButtonType.VIEW_FEEDBACK:
            return {
              ...ac,
              onClick: onViewFeedback,
            };
          case ActionButtonType.EXPORT_EVENT_PDF:
            return {
              ...ac,
              isLoading: exportingPdfEvent,
              onClick: onExportPdfEvent,
            };
          default:
            return ac;
        }
      });
  }, [
    actionButtons,
    isDirty,
    isNew,
    formHasAttachments,
    exportingPdfEvent,
    onSave,
    onSubmit,
    onComplete,
    onDelete,
    onContinue,
    onAssignAssessor,
    onViewFeedback,
    onViewQRCode,
    onExportPdfEvent,
  ]);

  return newEventButtons;
};

export default useEventFormButtons;
