import { useMemo } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  AnalyticsEvents,
  DsmData,
  DsmPayload,
  formatAnalyticsPayload,
  getAnswer,
  qMapToArray,
  StringNumber,
} from "@eolas-medical/core";
import { useEolasForm } from "Hooks/useEolasForm";
import { Context, FooterDataItem, FormAnswers, QuestionItem } from "../types";
import { modalStore } from "Stores/ModalStore";
import { DsmTextModal } from "../component/DsmTextModal";
import { trackEvent } from "API/Analytics";
import { useRunOnMountUnmount } from "Hooks";

export const useDsmViewer = ({ dsmData }: { dsmData: DsmData }) => {
  const { t } = useTranslation();

  const { validationSchema, questionsArray } = useMemo(() => {
    const map = dsmData.questionNumberToQuestionsDataMap;
    const qArray = qMapToArray(map);
    const schema = Yup.object().shape(
      qArray.reduce<Record<StringNumber, Yup.AnySchema>>((acc, { qNumber }) => {
        return { ...acc, [qNumber]: Yup.string().nullable().required(t("dsm_no_value_error")) };
      }, {}),
    );
    const qArrayWithFooter: (QuestionItem | FooterDataItem)[] = [
      ...qArray,
      { type: "dsmFooterItem" },
    ];

    return { questionsArray: qArrayWithFooter, validationSchema: schema };
  }, [dsmData, t]);

  const { handleSubmit, control, reset } = useEolasForm<FormAnswers>({
    validationSchema,
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((result) => {
        const newAnswer = getAnswer({ result, data: dsmData });
        if (newAnswer) {
          trackEvent<DsmPayload>(
            AnalyticsEvents.DSM_COMPLETED,
            formatAnalyticsPayload({ dsmData, userOptions: result, result: newAnswer }),
          );
        }
        modalStore.openModal({
          variant: "component",
          Component: (
            <DsmTextModal title={t("dsm_result")} text={newAnswer || t("dsm_calc_error")} />
          ),
          name: "dsmModal",
        });
      }),
    [handleSubmit, dsmData, t],
  );

  const context: Context = useMemo(
    () => ({ control, isInitialLoading: false, onSubmit, reset }),
    [control, onSubmit, reset],
  );

  useRunOnMountUnmount({
    onMount: () => {
      trackEvent<DsmPayload>(AnalyticsEvents.DSM_OPENED, formatAnalyticsPayload({ dsmData }));
    },
  });

  return {
    questionsArray,
    context,
  };
};
