import { useTranslation } from "react-i18next";
import { Text } from "UIKit";

export const DsmErrorStep = ({ errorMessage }: { errorMessage: string }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-col items-center justify-center">
      <div>
        <Text level={1}>{t("repository_admin_dsm_error")}</Text>
      </div>
      <div className="mt-6">
        <Text level={1} className="text-red-500">
          {errorMessage}
        </Text>
      </div>
    </div>
  );
};
