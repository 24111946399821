import { AnalyticsEvents } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";

const useDeleteSection = (sectionId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation<unknown, Error>(
    async () => myFilesService.deleteSection(sectionId),
    {
      onSuccess: () => {
        trackEvent(AnalyticsEvents.MYFILES_SECTION_DELETE, { subSectionId: sectionId });

        queryClient.invalidateQueries({
          queryKey: myFilesKeys.sectionLists(),
        });
        queryClient.invalidateQueries({
          queryKey: myFilesKeys.filesBySectionList(),
        });
      },
    },
  );

  return {
    deleteSection: mutate,
    deletingSection: isLoading,
    deleteSectionSuccessful: isSuccess,
    deleteSectionError: error?.message,
  };
};

export default useDeleteSection;
