import { useMemo } from "react";
import {
  DefaultFileIcon,
  DsmIcon,
  EolasEditorIcon,
  FlashcardIcon,
  ImageIcon,
  OfficeIcon,
  PDFIcon,
  VideoIcon,
  WeblinkIcon,
} from "Assets";
import { SupportedFileType } from "@eolas-medical/core";

interface FileTypeIconProps {
  type?: SupportedFileType;
  className?: string;
}

export const FileTypeItemIcon = ({ type, className }: FileTypeIconProps) => {
  const Icon = useMemo(() => {
    const lowercaseType = type?.toLocaleLowerCase();

    switch (lowercaseType) {
      case "mp4":
      case "mov":
        return VideoIcon;
      case "pdf":
        return PDFIcon;
      case "jpeg":
      case "jpg":
      case "png":
        return ImageIcon;
      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
      case "ms-office":
        return OfficeIcon;
      case "link":
        return WeblinkIcon;
      case "eolas-editor":
        return EolasEditorIcon;
      case "flashcard":
        return FlashcardIcon;
      case "dsm":
        return DsmIcon;
      default:
        return DefaultFileIcon;
    }
  }, [type]);

  return <Icon className={className} />;
};
