import { CheckedIcon } from "Assets";
import { Button, Text } from "UIKit";
import { useTranslation } from "react-i18next";
import { useButtonSize } from "Pages/MeTab/pages/Portfolio/hooks/useButtonSize";

interface SendReminderButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isReminderSent?: boolean;
}
const SendReminderButton = ({ onClick, isLoading, isReminderSent }: SendReminderButtonProps) => {
  const { t } = useTranslation();

  const { buttonSize } = useButtonSize();

  if (isReminderSent) {
    return (
      <div className="flex items-center space-x-2">
        <CheckedIcon className="text-blue-500" />
        <Text level={2} className="text-blue-500">
          {t("portfolio_reminder_sent")}
        </Text>
      </div>
    );
  }

  return (
    <Button
      iconLeft={"BellIcon"}
      onClick={onClick}
      isLoading={isLoading}
      variant="outline"
      size={buttonSize}
      color="white"
      className="border-grey-400"
      data-testid="send-reminder-button"
    >
      <Text level={1} className="ml-2">
        {t("portfolio_send_reminder")}
      </Text>
    </Button>
  );
};

export default SendReminderButton;
