import { snakeCase } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  hasStringProp,
  NationalResourceEntityType,
  NationalResourcesClickPayload,
} from "@eolas-medical/core";

import { removeLastUrlPath } from "Utilities";
import { useMainSectionVisit, useSelectFile } from "Hooks";
import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { RouteComponentProps, useHistory } from "react-router-dom";
import useNationalBodies, {
  NationalBodiesState as NationalBody,
} from "modules/nationalResources/data/useNationalBodies";
import { CommunitySectionType, EOLAS_CALCULATOR, EOLAS_NATIONAL_BODY } from "./types";
import { SearchSortList } from "shared/components/Lists";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { trackEvent } from "API/Analytics";
import { BaseAppRoute, SubSectionRouteParams } from "Utilities/types";
import { isEolasFile } from "modules/contentRepository/typeguards";

const getNationalBodyFromMetadata = (metadata?: string | null): string | null => {
  if (!metadata) {
    return null;
  }

  try {
    const parsedMetadata = JSON.parse(metadata);
    if (hasStringProp(parsedMetadata, "nationalBody")) {
      return parsedMetadata.nationalBody;
    }
    return null;
  } catch {
    return null;
  }
};

const ClinicalCalculators = observer(
  ({ match: { url } }: RouteComponentProps<SubSectionRouteParams>) => {
    const [searchInput, setSearchInput] = useState("");
    const [showSearchResult, setShowSearchResult] = useState(false);

    const { t } = useTranslation();
    const history = useHistory();

    const { onSelectFile } = useSelectFile();

    useMainSectionVisit({
      mainSectionId: CommunityLevelSection.clinicalCalculators,
    });

    const { nationalBodies, gettingNationalBodies } = useNationalBodies({
      entityType: NationalResourceEntityType.CALCULATOR,
      bodiesToInject: [{ name: EOLAS_CALCULATOR, id: "eolas" }],
    });

    const {
      searchNationalResources,
      searchingNationalResources,
      data: otherCalculatorSearchResults,
    } = useSearchNationalResources();
    const {
      searchNationalResources: searchEolasCalculators,
      searchingNationalResources: isSearchingEolasCalulators,
      data: eolasCalculatorSearchResults,
    } = useSearchNationalResources();

    const isSearching = searchingNationalResources || isSearchingEolasCalulators;

    const searchResults = useMemo(() => {
      const results = [];

      if (otherCalculatorSearchResults) {
        results.push(...otherCalculatorSearchResults);
      }

      if (eolasCalculatorSearchResults) {
        results.push(...eolasCalculatorSearchResults);
      }

      return results;
    }, [otherCalculatorSearchResults, eolasCalculatorSearchResults]);

    const handleClickBody = useCallback(
      ({ name }: EolasFile) => {
        const snakeCaseBody = snakeCase(name!);
        history.push({
          pathname: `/${BaseAppRoute.knowledge}/clinicalCalculators/${snakeCaseBody}`,
          state: { body: name },
        });
      },
      [history],
    );

    const handleClearSearch = useCallback(() => {
      setSearchInput("");
      setShowSearchResult(false);
    }, []);

    const handleClickCalculator = (calculator: EolasFile) => {
      trackEvent<NationalResourcesClickPayload>(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
        nationalResourceType: NationalResourceEntityType.CALCULATOR,
        resourceName: calculator.name,
      });

      const nationalBody = getNationalBodyFromMetadata(calculator.metadata);

      if (nationalBody === EOLAS_NATIONAL_BODY) {
        const calculatorName = snakeCase(calculator.name);
        history.push({
          pathname: `clinicalCalculators/eolas_calculator/${calculatorName}/${calculator.id}`,
        });
      } else {
        onSelectFile(calculator);
      }
    };

    const handleSearchCalculators = useCallback(() => {
      setShowSearchResult(true);
      searchNationalResources({
        entityType: NationalResourceEntityType.CALCULATOR,
        query: searchInput,
        mode: "searchbyInput",
      });
      searchEolasCalculators({
        entityType: NationalResourceEntityType.EOLAS_CALCULATOR,
        query: searchInput,
        mode: "searchbyInput",
      });
    }, [searchInput, searchNationalResources, searchEolasCalculators]);

    useEffect(() => {
      if (searchInput === "") {
        setShowSearchResult(false);
      }
    }, [searchInput]);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={t("clinicalCalculators_title")} />
          <NavButton to={removeLastUrlPath(url)} />
          <SearchSortList<EolasFile | NationalBody>
            value={searchInput}
            placeholderSearchText={t("findClinicalCalculator_searchPlaceholder")}
            items={showSearchResult ? searchResults : nationalBodies}
            isSearchable
            isLoading={isSearching || gettingNationalBodies}
            searchType="click"
            onSearchInputChange={setSearchInput}
            onClearSearch={handleClearSearch}
            onClickSearch={handleSearchCalculators}
            renderItem={(eolasFile) => (
              <CommunityListItem
                eolasFile={eolasFile as EolasFile} // FIXME: Horrible cast otherwise i will be here for hours...
                onSelectFile={isEolasFile(eolasFile) ? handleClickCalculator : handleClickBody}
                sectionType={
                  isEolasFile(eolasFile)
                    ? CommunitySectionType.NATIONAL_RESOURCE
                    : CommunitySectionType.BODY
                }
              />
            )}
          />
        </InnerPageWrapper>
      </>
    );
  },
);

export default ClinicalCalculators;
