import { useState } from "react";

import { WizardState } from "../../../../types";
import useImageBank from "Hooks/useImageBank";
import { compareUrls } from "Utilities/helpers";
import { useMutation } from "@tanstack/react-query";
import { useUploadBlob } from "../../../../hooks/useUploadBlob";
import {
  FlashcardMetadata,
  NewContentType,
  parseFlashcardFileMetadata,
  sectionStore,
} from "@eolas-medical/core";
import { makeMutableWizardState } from "../../../../functions/makeMutableWizardState";
import { createResourcesManifestFromHtml } from "modules/contentRepository/helpers";
import { toAwsJSON } from "Utilities";

export const useHeroImageDetails = (state: WizardState, sectionId: string) => {
  let key: string | undefined;
  if (!state.isExistingFileWithNewType) {
    key = state.dto.key;
  } else if (state.dto.newType === NewContentType.FLASHCARD) {
    key = state.dto.newProperties.key;
  }
  const [image, setImage] = useState<File | null>(state?.blob || null);
  const [url, setUrl] = useState<string | null>(key || null);

  const { uploadBlob } = useUploadBlob();

  const { imageBank, imageBankLoading: isImageBankLoading } = useImageBank();

  const isCustomUploadedImage = key
    ? !imageBank.some((item) => compareUrls(item.imageUrl, key ?? ""))
    : false;

  const defaultImage = key ? { url: key, isCustomImage: isCustomUploadedImage } : undefined;

  const canProceed = Boolean(image || url);

  const onChangeBlob = (blob: File | null) => {
    if (blob) {
      setUrl(null);
    } else if (url) {
      setUrl(null);
    }
    setImage(blob);
  };

  const onChangeImageUrl = (url: string | null) => {
    if (url) {
      setImage(null);
    }
    setUrl(url);
  };

  const {
    isError: isImageUploadError,
    isLoading: isImageUploading,
    mutateAsync: uploadImage,
  } = useMutation({
    mutationKey: ["uploadHeroImage", sectionId],
    mutationFn: async () => {
      const newState = makeMutableWizardState(state);
      let key = "";
      if (image) {
        const mainSectionId = sectionStore.getSection(sectionId)?.mainSectionID;
        if (!mainSectionId) {
          throw new Error("No mainSectionId in uploadHeroImage");
        }
        const result = await uploadBlob({
          mainSectionId,
          shouldAlwaysUploadAsPublic: true,
          blob: image,
        });
        if (result.type === "public") {
          key = result.url;
        }
      } else if (url) {
        key = url;
      }
      let metadata: unknown = undefined;
      if (!newState.isExistingFileWithNewType) {
        metadata = newState.dto.metadata;
      } else if (newState.dto.newType === NewContentType.FLASHCARD) {
        metadata = newState.dto.newProperties.metadata;
      }
      const flashcardContent = metadata
        ? parseFlashcardFileMetadata(metadata)?.flashcardContent ?? ""
        : "";
      const newManifest = await createResourcesManifestFromHtml(flashcardContent, key);
      const newMetadata: FlashcardMetadata = {
        flashcardContent: encodeURIComponent(flashcardContent),
        resourcesManifest: newManifest,
      };
      if (!newState.isExistingFileWithNewType) {
        newState.dto.metadata = toAwsJSON(newMetadata);
        newState.dto.key = key;
      } else if (newState.dto.newType === NewContentType.FLASHCARD) {
        newState.dto.newProperties.metadata = toAwsJSON(newMetadata);
        newState.dto.newProperties.key = key;
      }
      return newState;
    },
  });

  return {
    defaultImage,
    canProceed,
    onChangeBlob,
    onChangeImageUrl,
    isImageBankLoading,
    imageBank,
    image,
    isImageUploadError,
    isImageUploading,
    uploadImage,
  };
};
