import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NameAndUrlStep from "./NameAndUrlStep";
import { useNameAndUrlStepValidationSchema } from "./useNameAndUrlValidationSchema";
import { NameAndUrlValues } from "./types";
import { Step } from "../../types";

interface UseNameAndUrlStepProps<T> {
  stepName: T;
  onSubmit: (data: NameAndUrlValues) => void;
  title: string;
  nameLabel: string;
  namePlaceholder: string;
  urlLabel: string;
  keyWordsLabel: string;
  urlPlaceholder: string;
  initialValues?: NameAndUrlValues;
  isLoading?: boolean;
  nextLabel?: string;
  backLabel?: string;
  submitError?: string;
  keyWordsPlaceholder: string;
}
const useNameAndUrlStep = <T extends string>({
  stepName,
  onSubmit,
  isLoading,
  title,
  nameLabel,
  namePlaceholder,
  urlLabel,
  keyWordsLabel,
  keyWordsPlaceholder,
  urlPlaceholder,
  initialValues,
  nextLabel,
  backLabel,
  submitError,
}: UseNameAndUrlStepProps<T>): Step<T> => {
  const validationSchema = useNameAndUrlStepValidationSchema();

  const { handleSubmit, formState, control } = useForm<NameAndUrlValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const canProceed = formState.isValid && !formState.isSubmitting && !isLoading;

  return {
    name: stepName,
    title,
    submitError: !!submitError || !!formState.errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    nextLabel,
    backLabel,
    component: (
      <NameAndUrlStep
        control={control}
        errors={formState.errors}
        nameLabel={nameLabel}
        namePlaceholder={namePlaceholder}
        urlLabel={urlLabel}
        keyWordsLabel={keyWordsLabel}
        urlPlaceholder={urlPlaceholder}
        keyWordsPlaceholder={keyWordsPlaceholder}
      />
    ),
  };
};

export default useNameAndUrlStep;
