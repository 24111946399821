const analyticsKeys = {
  all: ["analytics"],
  detailContent: (contentId: string) => [...analyticsKeys.all, "detailContent", contentId],
  detailMainSection: (mainSectionId: string) => [
    ...analyticsKeys.all,
    "detailMainSection",
    mainSectionId,
  ],
};

export default analyticsKeys;
