import * as Yup from "yup";
import { FormItem } from "./types";
import { FieldValues } from "react-hook-form";
import { Assign, ObjectShape } from "yup/lib/object";

type ValidationSchema<FormStepValues> = Record<keyof FormStepValues, Yup.AnySchema>;

type UseFormStepValidationSchema<T> = Yup.ObjectSchema<Assign<ObjectShape, ValidationSchema<T>>>;

const useFormStepValidationSchema = <FormStepValues extends FieldValues>(
  formItems: FormItem<FormStepValues>[],
): UseFormStepValidationSchema<FormStepValues> => {
  const schema: ValidationSchema<FormStepValues> = {} as ValidationSchema<FormStepValues>;

  formItems.forEach((item) => {
    const field = item.fieldName as keyof FormStepValues;
    let fieldSchema: Yup.AnySchema = Yup.mixed();

    if (item.required) {
      fieldSchema = fieldSchema.required(`${item.fieldLabel} is required`);
    }

    schema[field] = fieldSchema;
  });

  return Yup.object().shape(schema);
};

export default useFormStepValidationSchema;
