import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { AppLevelSection, MajorIncidentSubSection } from "@eolas-medical/core";

import {
  Input,
  Button,
  Title,
  Textarea,
  FormError,
  ModalBody,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useIsScrollable } from "Hooks";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

export interface AddIncidentModalProps {
  onClose(): void;
}

export const AddIncidentModal: React.FC<AddIncidentModalProps> = ({
  onClose,
}: AddIncidentModalProps) => {
  const { t } = useTranslation();

  const { formErrors, control, onSubmit, error, isLoading, isSuccessful } = useAddEolasFile({
    mainSectionID: AppLevelSection.majorIncidents,
    subSectionId: MajorIncidentSubSection.incidents,
  });

  const { ref, ScrollHint } = useIsScrollable();

  if (isSuccessful) {
    return <SuccessModal onComplete={onClose} text="incidents_add_incident_success" />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("incidents_add_incident_modal_title")}</Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="space-y-2 add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              control={control}
              label={t("incidents_add_incident_title_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  data-testid="incidentTitleInput"
                  placeholder={t("incidents_add_incident_title_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              control={control}
              id="info.location"
              label={t("incidents_add_incident_location_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  data-testid="incidentLocationInput"
                  placeholder={t("incidents_add_incident_location_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              control={control}
              id="info.typeOfIncident"
              label={t("incidents_add_incident_type_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  data-testid="incidentTypeInput"
                  placeholder={t("incidents_add_incident_type_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              id="info.hazards"
              control={control}
              label={t("incidents_add_incident_hazards_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="incidentHazardsInput"
                  placeholder={t("incidents_add_incident_hazards_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              control={control}
              id="info.access"
              label={t("incidents_add_incident_access_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="incidentAccessInput"
                  placeholder={t("incidents_add_incident_access_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              control={control}
              id="info.numberOfCasualties"
              label={t("incidents_add_incident_casualties_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  data-testid="incidentCasualtiesInput"
                  placeholder={t("incidents_add_incident_casualties_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              control={control}
              id="info.emergencyServices"
              label={t("incidents_add_incident_emergency_services_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="incidentServicesInput"
                  placeholder={t("incidents_add_incident_emergency_services_placeholder")}
                />
              )}
            </FormElement>
            <Button
              size="lg"
              color="red"
              type="submit"
              variant="outline"
              disabled={isLoading}
              isLoading={isLoading}
              style={{ minHeight: "3rem" }}
              className="add-file__submit-button"
              data-testid="activateMajorIncidentButton"
            >
              {t("incidents_add_incident_add_button")}
            </Button>
          </div>

          <FormError
            error={!isEmpty(formErrors) ? "There are some errors with the form fields." : undefined}
          />
          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
