import React from "react";
import { Text } from "UIKit/Typography/Text";

type Props = {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
  description?: string;
};

export const LinkTypeOption = ({ onClick, icon, title, description }: Props) => {
  return (
    <div
      className="max-w-md mx-auto bg-white rounded-md overflow-hidden border-2 border-grey-300 flex flex-col items-center justify-center hover:bg-blue-50 p-6 flex-grow flex-shrink basis-0 cursor-pointer"
      onClick={onClick}
    >
      <div className="h-14 w-14">{icon}</div>
      <div className="flex flex-col p-4 items-center justify-center">
        <Text level={1} className="line-clamp-1">
          {title}
        </Text>

        <Text level={2} className="text-grey-600 line-clamp-3">
          {description}
        </Text>
      </div>
    </div>
  );
};
