import { useTranslation } from "react-i18next";

import { success } from "Assets";

import { LottieWithHeader } from "../../LottieWithHeader";

import { InnerModalWrapper } from "../components";

export interface SuccessModalProps {
  text?: string;
  onComplete(): void;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  onComplete,
  text = "fileWithSearch_addNew_uploadComplete",
}: SuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <LottieWithHeader
        text={t(text)}
        animation={success}
        onComplete={onComplete}
        data-testid="success-animation"
      />
    </InnerModalWrapper>
  );
};
