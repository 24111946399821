import { userStore } from "@eolas-medical/core";
import { LocationDescriptorObject } from "history";
import { useSpacesContext } from "modules/spaces";
import useUserSpaces from "modules/spaces/data/useUserSpaces";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { InnerPageWrapper, Text, PageTitle, Loader } from "UIKit";
import { useParamsDecoded } from "Utilities/useParamsDecoded";

export const CheckAccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { spaceId, sectionType, sectionId } = useParamsDecoded<{
    spaceId: string;
    sectionType: string;
    sectionId: string;
  }>();
  const { search } = useLocation();
  const { userSpaces } = useUserSpaces(userStore.userID);
  const { selectedSpace } = useSpacesContext();
  const currentSpaceId = selectedSpace?.id;

  useEffect(() => {
    // Only proceed if userSpaces has been loaded and is not empty
    if (!userSpaces || userSpaces.length === 0) {
      return;
    }

    const alternativeSpace = userSpaces.find((s) => s?.id === spaceId && s.id !== currentSpaceId);
    const baseRoute = "/check-space-access/switch-space";

    if (alternativeSpace) {
      const urlParts = [
        baseRoute,
        encodeURIComponent(alternativeSpace.id),
        encodeURIComponent(alternativeSpace.name),
      ];

      // Conditionally add sectionType and sectionId if they are not undefined
      if (sectionType && sectionType !== "undefined") {
        urlParts.push(sectionType);
        if (sectionId && sectionId !== "undefined") {
          urlParts.push(sectionId);
        }
      }

      const url = urlParts.join("/");

      const locationObj: LocationDescriptorObject<undefined> = {
        pathname: url,
        search,
      };

      history.push(locationObj);
    } else {
      // Redirect to no access only if userSpaces is not empty and user has no access to alternative space
      history.push("/check-space-access/no-access");
    }
  }, [userSpaces, spaceId, currentSpaceId, sectionType, sectionId, history, search]);

  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-16"
        data-testid="portfolio-error-fallback"
      >
        <PageTitle title={t("check_access_title")} />
        <Text level={1}>{t("check_access_subtitle")}</Text>
        <Loader />
      </div>
    </InnerPageWrapper>
  );
};
