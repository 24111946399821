/* eslint-disable @typescript-eslint/no-explicit-any */
import { accessClient } from "@eolas-medical/core";
import { useMutation, MutateOptions } from "@tanstack/react-query";

type Args = { userId: string; departmentId: string };

export const useDeleteAppUser = () => {
  const { reset, mutate, isLoading, error, isSuccess } = useMutation<any, any, Args>(
    ({ userId, departmentId }) => {
      return accessClient.removeDepartmentAccess(userId, departmentId);
    },
  );

  const onDeleteAppUser = (args: Args, options?: MutateOptions<any, any, Args>) => {
    reset();
    mutate(args, options);
  };

  return {
    error,
    isLoading,
    isSuccess,
    onDeleteAppUser,
  };
};
