import { communitySubsectionBaseNames } from "@eolas-medical/core";

export const TitleStrings = {
  bnf: communitySubsectionBaseNames.interactions_adult,
  bnfc: communitySubsectionBaseNames.interactions_child,
} as const;

export const Strings = {
  select: "Select Medications",
  selectDisc: "To view interactions, please select at least 2 medications",
  interactantsListError:
    "We encountered a problem loading interactants. Please try reloading this page.",
  moreInteractions: "Please select at least 2 medications",
  tooManyInteractions: "Please select no more than 40 medications",
  interactionsListError: "We encountered a problem loading interactions. Please try again.",
  noInteractions: "No interactions documents for this selection",
};
