import { overrideTailwindClasses as tw } from "tailwind-override";

export interface FormLabelProps {
  children: React.ReactNode;
  htmlFor: string;
  labelClassName?: string;
  required?: boolean;
  className?: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  htmlFor,
  required,
  children,
  className = "",
  labelClassName = "",
}: FormLabelProps) => {
  return (
    <div className={tw(`flex justify-start ${className}`)}>
      <label htmlFor={htmlFor} className={tw(`font-bold text-lg ${labelClassName}`)}>
        {children}
      </label>
      {required && <span className="text-red font-semibold ml-1">*</span>}
    </div>
  );
};
