import {
  AnalyticsEvents,
  DeeplinkGeneratedPayload,
  EnrichedFavourite,
  MeLevelSection,
  hasProp,
  hasStringProp,
  isMedicationSummaryItem,
} from "@eolas-medical/core";
import {
  isKnowledgeSearchResult,
  isLocalSearchResult,
  isShareableMasterSearchFile,
} from "Components/MasterSearch/functions/typeguards";
import { trackEvent } from "API/Analytics";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useNotifications } from "Components/Notifications";
import { useLaunchDarkly } from "Contexts";
import { isChecklistItem } from "Pages/Spaces/pages/Space/pages/MiniApp/Checklists/functions/typeguards";
import { GenericShareLinkModal } from "UIKit/Modal/GenericShareLinkModal/GenericShareLinkModal";
import { expectNever } from "Utilities/helpers";
import { LDFlagNames } from "Utilities/types";
import { isContentItem, isEolasFile } from "modules/contentRepository/typeguards";
import { isEnrichedFavourite } from "modules/myFavourites/typeguards";
import { isNiceSummaryItem } from "modules/niceGuidelines/typeguards";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NonCopilotEntityItem, generateLinkToItem } from "shared/functions/generateLinkToItem";
import { isChildReference, isEolasSection } from "shared/functions/typeguards";
import { useModalState } from "shared/hooks/useModalState/useModalState";
import { KnowledgeResult, LocalResult } from "Components/MasterSearch/types";
import { modalStore } from "Stores/ModalStore";

export type ModalProps = {
  onClose: () => void;
  link: string;
  exportedQRCodeName: string;
  pageNumber?: number;
  totalPages?: number;
  item?: NonCopilotEntityItem | EnrichedFavourite;
};

type HandleShareOptions = {
  mainSectionIdOverride?: string;
  pageNumber?: number;
  totalPages?: number;
};

type Props = {
  Modal?: (props: ModalProps) => React.ReactNode;
  shouldUseMobxModal?: boolean;
};

export const useShareFile = (props?: Props) => {
  const { flags } = useLaunchDarkly();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const { modal, openModal, closeModal, isOpen } = useModalState();
  const { activeTab } = useEolasNavigation();
  const isShareFileEnabled = !!flags[LDFlagNames.SHARE_FILE];

  const handleOnShareFile = useCallback(
    (item: NonCopilotEntityItem | EnrichedFavourite, options?: HandleShareOptions) => {
      if (!isShareFileEnabled) {
        return;
      }

      let link;
      if (hasStringProp(item, "deeplinkValue")) {
        link = item.deeplinkValue;
        trackEvent<DeeplinkGeneratedPayload>(AnalyticsEvents.DEEPLINK_GENERATED, {
          entityId: item.id,
          type: "file",
          pageNo: options?.pageNumber?.toString(),
          url: link,
          title: getFileName(item),
          origin: MeLevelSection.myFavourites,
        });
      }

      if (!link && !hasStringProp(item, "deeplinkValue")) {
        if (activeTab !== "spaces" && activeTab !== "organisation" && activeTab !== "knowledge") {
          return;
        }
        const result = generateLinkToItem(item, activeTab, options?.mainSectionIdOverride);

        if (result) {
          link = result.link;
          trackEvent<DeeplinkGeneratedPayload>(AnalyticsEvents.DEEPLINK_GENERATED, {
            entityId: item.id,
            type: isEolasSection(item) ? "section" : "file",
            pageNo: options?.pageNumber?.toString(),
            url: link,
            title: getFileName(item),
            origin: result.origin === "null" ? "genericContentRepository" : result.origin,
          });
        }
      }
      if (link) {
        if (props?.shouldUseMobxModal) {
          modalStore.openModal({
            variant: "component",
            name: "GenericShareLinkModal",
            Component: (
              <GenericShareLinkModal
                link={link}
                exportedQRCodeName={getFileName(item)}
                titleText={hasStringProp(item, "name") ? item.name : undefined}
              />
            ),
          });
          return;
        }
        if (hasProp(props, "Modal") && props.Modal) {
          const ModalComponent = props.Modal;
          openModal(
            <ModalComponent
              link={link}
              exportedQRCodeName={getFileName(item)}
              onClose={closeModal}
              pageNumber={options?.pageNumber}
              totalPages={options?.totalPages}
              item={item}
            />,
          );
        } else {
          openModal(
            <GenericShareLinkModal
              link={link}
              exportedQRCodeName={getFileName(item)}
              titleText={hasStringProp(item, "name") ? item.name : undefined}
            />,
          );
        }
      } else {
        showNotification({
          type: "error",
          autoHideTimeout: 3000,
          description: t("share_item_link_failure"),
        });
      }
    },
    [isShareFileEnabled, openModal, showNotification, t, activeTab, props, closeModal],
  );

  return {
    handleOnShareFile,
    shareModal: modal,
    closeShareModal: closeModal,
    isShareModalOpen: isOpen,
    isShareFileEnabled,
  };
};

export function getFileName(
  item: NonCopilotEntityItem | EnrichedFavourite | LocalResult | KnowledgeResult,
): string {
  const fallback = item;
  if (
    isEolasFile(item) ||
    isShareableMasterSearchFile(item) ||
    isContentItem(item) ||
    isMedicationSummaryItem(item) ||
    isEolasSection(item)
  ) {
    return item.name ?? "";
  }

  if (isLocalSearchResult(item)) {
    return item.file.name ?? "";
  }

  if (isKnowledgeSearchResult(item)) {
    return item.knowledgeFile.name;
  }

  if (isNiceSummaryItem(item) || isEnrichedFavourite(item)) {
    return item.title;
  }

  if (isChildReference(item) || isChecklistItem(item)) {
    return item.name ?? "";
  }

  // The below conditions are fallbacks in case the item is not caught in the above conditions at runtime
  if (hasStringProp(fallback, "name")) {
    return fallback.name;
  }

  if (hasStringProp(fallback, "title")) {
    return fallback.title;
  }

  expectNever(item);

  return "";
}
