import { AccessRequestStatus } from "@eolas-medical/core/lib/src/clients/access/access.types";

export const useUserAccessStatus = (userAccessStatus: string) => {
  const isPendingAccessRequest = userAccessStatus === AccessRequestStatus.ACCESS_REQUESTED;
  const hasAccess =
    userAccessStatus === AccessRequestStatus.EXISTING_APP_USER ||
    userAccessStatus === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED ||
    userAccessStatus === AccessRequestStatus.APP_USER_CREATED ||
    userAccessStatus === AccessRequestStatus.PUBLIC_ACCESS_GRANTED;
  const hasNoAccessYet = userAccessStatus === AccessRequestStatus.NO_APP_ACCESS;
  const doesRequireTrustedEmail = userAccessStatus === AccessRequestStatus.NO_TRUSTED_EMAIL;

  return { isPendingAccessRequest, hasAccess, hasNoAccessYet, doesRequireTrustedEmail };
};
