import z from "zod";
import {
  PermutationsDataBare,
  SummaryData,
  WorkBook,
  QaDataBare,
  InfoDataBare,
  ConditionDataBare,
} from "./types";
import { DsmError, getSheet, getWorkSheetJson, removeEmptyRows, stripString } from "./helpers";
import {
  ConditionDataProperties,
  InfoDataProperties,
  PermutationsProperties,
  QaProperties,
  SummaryProperties,
} from "./constants";
import { QuestionType } from "@eolas-medical/core";

const validateAndRemoveHeaderRow = <
  Header extends Array<Array<unknown>>,
  Bare extends Array<Array<unknown>>,
  BareSchema extends { parse: (data: unknown) => Bare },
  HeaderSchema extends { parse: (data: unknown) => Header },
>({
  name,
  headerSchema,
  bareSchema,
  data,
}: {
  name: string;
  headerSchema: HeaderSchema;
  bareSchema: BareSchema;
  data: unknown;
}): Bare => {
  let headerData: Header | null = null;
  let bareBata: Bare | null = null;

  try {
    headerData = headerSchema.parse(data);
  } catch (firstError) {
    try {
      bareBata = bareSchema.parse(data);
    } catch (finalError) {
      console.error({ firstError, finalError });
      throw new DsmError(`Validation error on sheet: ${name}`, "schemaError");
    }
  }
  if (bareBata) {
    return bareBata;
  }
  if (headerData) {
    const [, ...rest] = headerData;
    /**
     * FIXME: splitting up the generics with a specific type for the header row still errors.
     * We know the difference between header and bare is that the
     * Header has 1 extra row at the start of the array, so this is safe:
     */
    return rest as Bare;
  }
  // Shouldn't ever reach here, as the schema will throw an error, but to keep TS happy:
  return [] as unknown as Bare;
};

const forgivingLiteral = (str: string) =>
  z.string().trim().toLowerCase().includes(str.toLowerCase());

const forgivingEmptySpace = z.union([
  z.undefined(),
  z.string().transform((val, ctx) => {
    const stripped = stripString(val);
    if (stripped === "") {
      return undefined;
    }
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Expected empty cell",
    });
    return z.NEVER;
  }),
]);

export const SummarySchema = z.tuple([
  z.tuple([forgivingLiteral(SummaryProperties.name), z.string()]).rest(forgivingEmptySpace),
  z.tuple([forgivingLiteral(SummaryProperties.questions), z.number()]).rest(forgivingEmptySpace),
  z.tuple([forgivingLiteral(SummaryProperties.permutations), z.number()]).rest(forgivingEmptySpace),
  z.tuple([forgivingLiteral(SummaryProperties.regimens), z.number()]).rest(forgivingEmptySpace),
]);

export const validateAndGetSummary = (workbook: WorkBook): SummaryData => {
  const worksheet = getSheet({ workbook, sheetType: "summary" });
  if (!worksheet) {
    throw new DsmError("No sheet found for Summary sheet", "notFound");
  }
  const data = getWorkSheetJson({ worksheet });
  const cleaned = removeEmptyRows(data);
  try {
    return SummarySchema.parse(cleaned);
  } catch {
    throw new DsmError(
      "Unexpected data structure in Summary Sheet. Check against requirements",
      "schemaError",
    );
  }
};

const UnionStringNumber = z.union([z.string(), z.number()]);

const QaRowSchema = z
  .tuple([z.number(), z.union([z.undefined(), z.string()]), z.string()])
  .rest(UnionStringNumber);

export const QaBareSchema = z.array(QaRowSchema);

export const QaHeaderSchema = z
  .tuple([
    z
      .tuple([
        forgivingLiteral(QaProperties.questionNumber),
        forgivingLiteral(QaProperties.questionType),
        forgivingLiteral(QaProperties.question),
      ])
      .rest(UnionStringNumber),
  ])
  .rest(QaRowSchema);

export const validateAndGetQuestionsAndAnswers = (workbook: WorkBook): QaDataBare => {
  const worksheet = getSheet({ workbook, sheetType: "qAndA" });
  if (!worksheet) {
    throw new DsmError("No sheet found for Q & A sheet", "notFound");
  }
  const data = getWorkSheetJson({ worksheet });
  return validateAndRemoveHeaderRow({
    headerSchema: QaHeaderSchema,
    bareSchema: QaBareSchema,
    data,
    name: "Questions and Answers",
  });
};

const PermutationsRowSchema = z.tuple([z.number()]).rest(UnionStringNumber);

export const PermutationsSchemaHeader = z
  .tuple([
    z
      .tuple([forgivingLiteral(PermutationsProperties.regimen)])
      .rest(z.union([z.number(), z.string()])),
  ])
  .rest(PermutationsRowSchema);
export const PermutationsSchemaBare = z.array(PermutationsRowSchema);

export const validateAndGetPermutations = (workbook: WorkBook): PermutationsDataBare => {
  const worksheet = getSheet({ workbook, sheetType: "permutations" });
  if (!worksheet) {
    throw new DsmError("No sheet found for Permutations sheet", "notFound");
  }
  const data = getWorkSheetJson({ worksheet });
  return validateAndRemoveHeaderRow({
    headerSchema: PermutationsSchemaHeader,
    bareSchema: PermutationsSchemaBare,
    data,
    name: "Permutations",
  });
};

const InfoRowSchema = z.tuple([z.number()]).rest(z.union([z.string(), z.undefined()]));

const HeaderInfoRowSchema = z
  .tuple([
    forgivingLiteral(InfoDataProperties.questionNumber),
    forgivingLiteral(InfoDataProperties.associatedInformation),
  ])
  .rest(z.undefined());

const InfoDataWithHeaderSchema = z.tuple([HeaderInfoRowSchema]).rest(InfoRowSchema);

export const InfoDataBareSchema = z.array(InfoRowSchema);

export const validateAndGetInfoData = (workbook: WorkBook): InfoDataBare => {
  const worksheet = getSheet({ workbook, sheetType: "infoData" });
  if (!worksheet) {
    throw new DsmError("No sheet found for Info data sheet", "notFound");
  }
  const data = getWorkSheetJson({ worksheet });
  return validateAndRemoveHeaderRow({
    headerSchema: InfoDataWithHeaderSchema,
    bareSchema: InfoDataBareSchema,
    data,
    name: "Info",
  });
};

const ConditionHeader = z
  .tuple([
    forgivingLiteral(ConditionDataProperties.regimen),
    forgivingLiteral(ConditionDataProperties.text),
  ])
  .rest(forgivingEmptySpace);

const ConditionRow = z.tuple([z.number(), z.string()]).rest(forgivingEmptySpace);

const ConditionDataWithHeaderSchema = z.tuple([ConditionHeader]).rest(ConditionRow);

export const ConditionDataBareSchema = z.array(ConditionRow);

export const validateAndGetConditionData = (workbook: WorkBook): ConditionDataBare => {
  const worksheet = getSheet({ workbook, sheetType: "conditionData" });
  if (!worksheet) {
    throw new Error("No sheet found for Condition data sheet");
  }
  const data = getWorkSheetJson({ worksheet });
  return validateAndRemoveHeaderRow({
    headerSchema: ConditionDataWithHeaderSchema,
    bareSchema: ConditionDataBareSchema,
    data,
    name: "Condition Data",
  });
};

export const validateBasedOnSummaryData = ({
  summaryData,
  qaData,
  infoData,
  permutationsData,
}: {
  summaryData: SummaryData;
  qaData: QaDataBare;
  permutationsData: PermutationsDataBare;
  infoData: InfoDataBare;
}) => {
  const name = summaryData[0][1];
  const expectedQuestionsNo = summaryData[1][1];
  const expectedPermutations = summaryData[2][1];
  const expectedRegimens = summaryData[3][1];

  if (!name || typeof name !== "string") {
    throw new DsmError("Expected summary to contain a string name", "schemaError");
  }

  if (permutationsData.length !== expectedPermutations) {
    throw new DsmError(
      "Number of permutations should equal number declared in summary data",
      "unexpectedData",
    );
  }
  if (qaData.length !== expectedQuestionsNo) {
    throw new DsmError(
      "Number of questions should equal number declared in summary data",
      "unexpectedData",
    );
  }

  const questionType = validateAndGetQuestionType(qaData);

  let calculatedPermutations = 1;
  for (let i = 0; i < qaData.length; i += 1) {
    const [questionNumber, , , ...rest] = qaData[i];
    if (questionNumber !== i + 1) {
      throw new DsmError(
        `Q & A data: Expected question number ${i + 1} on row ${i + 1}, got ${questionNumber}`,
        "unexpectedData",
      );
    }
    const questions = rest.filter((item) =>
      typeof item === "string" ? stripString(item) : item !== undefined,
    );
    calculatedPermutations *= questions.length ? questions.length : 1;
  }
  if (!questionType && expectedPermutations !== calculatedPermutations) {
    throw new DsmError(
      `Calculated permutations from question options as ${calculatedPermutations}, declared to be ${expectedPermutations}`,
      "unexpectedData",
    );
  }
  if (infoData.length > expectedQuestionsNo) {
    throw new DsmError(
      "More info data rows than questions given in Summary sheet",
      "unexpectedData",
    );
  }

  return { expectedQuestionsNo, expectedPermutations, expectedRegimens, name, questionType };
};

export const validateAndGetQuestionType = (data: QaDataBare) => {
  const supportedTypes = data.map(([, qType]) => convertToSupportedQuestionType(qType));

  if (supportedTypes.every((type) => type === supportedTypes[0])) {
    return supportedTypes[0];
  }

  throw new DsmError(
    "Every question type must be identical in Questions & Answers Sheet",
    "questionTypeError",
  );
};

const convertToSupportedQuestionType = (type: string | undefined): QuestionType | null => {
  if (!type?.trim()) {
    return null;
  }
  if (type.trim().toLowerCase() === QuestionType.list.toLowerCase()) {
    return QuestionType.list;
  }
  throw new DsmError("Unexpected question type in Questions & Answers Sheet", "questionTypeError");
};
