import { useQuery } from "@tanstack/react-query";
import { Summaries, bnfClient } from "@eolas-medical/core";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";
import bnfKeys from "./bnf.queryKeys";
import { useLaunchDarkly } from "Contexts";
import { isBnfEnabled } from "../helpers";

const useGetBnfAllSummaryData = () => {
  const { flags } = useLaunchDarkly();
  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.all,
    queryFn: () => bnfClient.getSummaryData(),
    select: (summaries): Summaries => {
      return summaries;
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
    enabled: isBnfEnabled(flags),
  });

  return {
    data,
    isInitialLoading,
  };
};

export default useGetBnfAllSummaryData;
