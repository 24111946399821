import { useState } from "react";
import { Step, Wizard, useWizard } from "UIKit/Wizard";

import { AuthenticationWizard, SignUpStep, ValidateEmailError } from "Pages/Authentication/types";
import useValidateEmailStep from "../ValidateEmailModal/useValidateEmailStep";
import useCreateAccountStep from "../CreateAccountModal/useCreateAccountStep";
import useCreatePasswordStep from "../CreatePasswordModal/useCreatePasswordStep";
import useVerificationCodeStep from "../VerificationCodeModal/useVerificationCodeStep";
import useEmailNotVerifiedStep from "../EmailNotVerifiedModal/useEmailNotVerifiedStep";
import useAssistanceVerificationCode from "modules/authentication/data/useAssistanceVerificationCode";
import { timeEvent, trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

import useSignUpUser from "modules/authentication/data/useSignUpUser";
import useVerifyCode from "modules/authentication/data/useVerifyCode";
import useSignInUser from "modules/authentication/data/useSignInUser";
import useValidateUserEmail from "modules/authentication/data/useValidateUserEmail";
import useResendVerificationCode from "modules/authentication/data/useResendVerificationCode";

interface CreateAccountWizardProps {
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
  defaultStep?: SignUpStep;
  email?: string;
}

interface SignUpWizardState {
  exists: boolean;
  userConfirmed: boolean;
  email: string;
  firstName: string;
  surname: string;
  password: string;
}

const SignUpWizard = ({
  email,
  defaultStep = "validate-email",
  onChangeWizard,
}: CreateAccountWizardProps) => {
  const { signInUser, isSigningIn, signInError } = useSignInUser();
  const { signUpUser, isSigningUp, signUpSuccessful, signUpError } = useSignUpUser();
  const { verifyCode, isVerifyingCode, verifyCodeError, verifyCodeSuccessful } = useVerifyCode();
  const {
    validateUserEmail,
    validatingUserEmail,
    validateUserEmailError,
    validateUserEmailSuccess,
    resetValidateUserEmail,
  } = useValidateUserEmail();

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode();

  const {
    resendVerificationCode,
    resendingVerificationCode,
    resendVerificationCodeError,
    resendVerificationCodeSuccess,
  } = useResendVerificationCode();

  const [wizardState, setWizardState] = useState<SignUpWizardState>({
    exists: false,
    userConfirmed: false,
    email: email || "",
    firstName: "",
    surname: "",
    password: "",
  });

  const [accountExistsError, setAccountExistsError] = useState<ValidateEmailError | undefined>();

  const { wizardControl, submitWizard } = useWizard<SignUpStep>();

  const handleSubmitEmail = async (email: string) => {
    handleClearWizard();
    validateUserEmail(email, {
      onSuccess: ({ exists, userConfirmed }) => {
        if (!exists || !userConfirmed) {
          setWizardState((prev) => ({ ...prev, email, exists, userConfirmed }));
        } else {
          trackEvent(AnalyticsEvents.EMAIL_CHECK_ACCOUNT_EXISTS_LOGIN_FLOW);
          setAccountExistsError("account-already-exists");
        }
      },
    });
    trackEvent(AnalyticsEvents.CREATE_ACCOUNT_EMAIL_ENTERED);
  };

  const handleSubmitAccount = ({ firstName, surname }: { firstName: string; surname: string }) => {
    setWizardState((prev) => ({ ...prev, firstName, surname }));
  };

  const handleSubmitPassword = async (password: string) => {
    setWizardState((prev) => ({ ...prev, password }));
    trackEvent(AnalyticsEvents.CREATE_ACCOUNT_PASSWORD_ENTERED);
    signUpUser({
      email: wizardState.email,
      password,
      familyName: wizardState.surname,
      givenName: wizardState.firstName,
    });
  };

  const handleSubmitCode = (code: string) => {
    verifyCode(
      { email: wizardState.email, code },
      {
        onSuccess: () => {
          if (wizardState.password) {
            trackEvent(AnalyticsEvents.CREATE_ACCOUNT_CORRECT_CODE_ENTERED);
            timeEvent(AnalyticsEvents.END_CREATE_ACCOUNT_NEW_ACCOUNT);
            signInUser({ email: wizardState.email, password: wizardState.password });
          } else {
            onChangeWizard("sign-in-wizard", wizardState.email);
          }
        },
      },
    );
  };

  const handleRequestCode = () => {
    resendVerificationCode(wizardState.email);
  };

  const handleResendCode = () => {
    resendVerificationCode(wizardState.email);
    requestAssistanceVerificationCode({
      queryParams: {
        email: wizardState.email,
        firstName: wizardState.firstName,
        lastName: wizardState.surname,
        origin: "SIGN_UP",
      },
    });
    trackEvent(AnalyticsEvents.DID_NOT_RECEIVE_CODE_LOGIN_FLOW);
  };

  const handleClearWizard = () => {
    setAccountExistsError(undefined);
    resetValidateUserEmail();
  };

  const validateEmailStep = useValidateEmailStep({
    canProceed: validateUserEmailSuccess && !accountExistsError,
    isLoading: validatingUserEmail,
    error: validateUserEmailError,
    submitError: accountExistsError,
    onSubmit: handleSubmitEmail,
    onEnter: submitWizard,
    onChangeWizard,
    defaultEmail: email,
  });

  const createAccountStep = useCreateAccountStep({
    backDisabled: !!email,
    onPrev: handleClearWizard,
    onSubmit: handleSubmitAccount,
    onEnter: submitWizard,
  });

  const createPasswordStep = useCreatePasswordStep({
    canProceed: signUpSuccessful,
    isLoading: isSigningUp,
    error: signUpError,
    onSubmit: handleSubmitPassword,
    onEnter: submitWizard,
  });

  const verificationCodeStep = useVerificationCodeStep({
    email: wizardState.email,
    canProceed: verifyCodeSuccessful,
    isLoading: isVerifyingCode || isSigningIn,
    error: verifyCodeError || signInError || undefined,
    onSubmit: handleSubmitCode,
    onResendCode: handleResendCode,
    onEnter: submitWizard,
  });

  const emailNotVerifiedStep = useEmailNotVerifiedStep({
    email: wizardState.email,
    isLoading: resendingVerificationCode,
    error: resendVerificationCodeError || "",
    canProceed: resendVerificationCodeSuccess,
    onSubmit: handleRequestCode,
  });

  const steps: Step<SignUpStep>[] = [
    validateEmailStep,
    createAccountStep,
    createPasswordStep,
    verificationCodeStep,
    emailNotVerifiedStep,
  ];

  const getNextStep = (currentStep: SignUpStep) => {
    if (currentStep === "validate-email" && !wizardState.exists && wizardState.email.length) {
      return "create-account";
    }

    if (
      currentStep === "validate-email" &&
      wizardState.exists &&
      !wizardState.userConfirmed &&
      wizardState.email.length
    ) {
      return "email-not-verified";
    }

    if (
      currentStep === "create-account" &&
      wizardState.firstName.length &&
      wizardState.surname.length
    ) {
      return "create-password";
    }

    if (currentStep === "create-password" || currentStep === "email-not-verified") {
      return "verification-code";
    }

    return currentStep;
  };

  return (
    <div className="flex md:w-200">
      <Wizard
        initialStep={defaultStep}
        wizardControl={wizardControl}
        steps={steps}
        getNextStep={getNextStep}
      />
    </div>
  );
};

export default SignUpWizard;
