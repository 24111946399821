import * as Yup from "yup";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { useNotifications } from "Components/Notifications";
import { useSubmitFeedback } from "../hooks/useSubmitFeedback";
import { useGetUserRating } from "modules/clinicalQuestions/data/useGetUserRating";
import { useEolasForm } from "Hooks/useEolasForm";
import { IconButton } from "UIKit";
import { CopyIcon, ThumbsDownIcon, ThumbsUpIcon } from "Assets";
import { FeedbackBox } from "./FeedbackBox";
import { stripHtmlTags } from "../functions/stripHtmlTags";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

type FormState = {
  comment: string;
};

const validationSchema = (message: string) =>
  Yup.object({
    comment: Yup.string().trim().required(message),
  });

const buttonVariants = {
  hover: {
    scale: 1.2,
    rotate: -10,
    transition: {
      duration: 0.2,
    },
  },
  tap: {
    scale: 0.95,
    transition: {
      duration: 0.1,
    },
  },
};

export const ButtonRow = ({
  text,
  questionID,
  title,
}: {
  text: string;
  questionID: string | null;
  title?: string;
}) => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const { submitFeedback, isLoading } = useSubmitFeedback();
  const { userRating, comments } = useGetUserRating({ questionID });
  const selectedIcon = userRating;

  const { handleSubmit, control, formState, reset } = useEolasForm<FormState>({
    validationSchema: validationSchema(t("comment_required")),
    mode: "onChange",
    defaultValues: {
      comment: "",
    },
  });

  const handleIconClick = async (icon: "like" | "dislike") => {
    if (!questionID || isLoading) {
      return;
    }
    await submitFeedback({
      questionID,
      likeOrDislike: icon === selectedIcon ? "neutral" : icon,
      title,
    });
  };

  const strippedText = stripHtmlTags(text);
  const textWithTitle = title ? `${title}\n\n${strippedText}` : strippedText;

  const onCopyClicked = () => {
    navigator.clipboard.writeText(textWithTitle);
    showNotification({
      type: "success",
      autoHideTimeout: 3000,
      description: t("copied_to_clipboard"),
    });
    trackEvent(AnalyticsEvents.CLINICAL_QUESTION_COPIED);
  };

  if (!questionID) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-center mt-4 mb-2">
        <div className="flex">
          <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
            <IconButton
              className={`bg-transparent hover:bg-transparent active:bg-transparent ${
                selectedIcon === "like" ? "text-blue-500" : "text-grey-500"
              }`}
              icon={<ThumbsUpIcon />}
              onClick={() => handleIconClick("like")}
            />
          </motion.div>
          <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
            <IconButton
              className={`bg-transparent hover:bg-transparent active:bg-transparent ${
                selectedIcon === "dislike" ? "text-blue-500" : "text-grey-500"
              }`}
              icon={<ThumbsDownIcon />}
              onClick={() => handleIconClick("dislike")}
            />
          </motion.div>
        </div>
        {strippedText ? (
          <IconButton
            className="bg-transparent text-grey-600"
            icon={<CopyIcon />}
            onClick={onCopyClicked}
          />
        ) : null}
      </div>

      {selectedIcon && questionID ? (
        <FeedbackBox
          title={title}
          reset={reset}
          comments={comments ?? []}
          handleSubmit={handleSubmit}
          control={control}
          isSubmitSuccessful={formState.isSubmitSuccessful}
          questionID={questionID}
          likeOrDislike={selectedIcon}
          isValid={formState.isValid}
        />
      ) : null}
    </>
  );
};
