import { useForm } from "react-hook-form";

import { WizardState } from "../../../../types";
import { useFlashcardValidationSchema } from "UIKit/Wizard/templates/FlashcardCreatorStep/useFlashcardValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { NewContentType, parseFlashcardFileMetadata } from "@eolas-medical/core";
import { useMemo } from "react";

export type EditFlashcardValues = { content: string };

export const useEditFlashcardContent = (state: WizardState) => {
  const validationSchema = useFlashcardValidationSchema();

  const initialContent = useMemo(() => {
    if (!state.isExistingFileWithNewType) {
      return state.dto.metadata
        ? parseFlashcardFileMetadata(state.dto.metadata)?.flashcardContent ?? ""
        : "";
    } else if (state.dto.newType === NewContentType.FLASHCARD) {
      return state.dto.newProperties.metadata
        ? parseFlashcardFileMetadata(state.dto.newProperties.metadata)?.flashcardContent ?? ""
        : "";
    }
  }, [state]);

  const { handleSubmit, control } = useForm<EditFlashcardValues>({
    defaultValues: {
      content: initialContent,
    },
    resolver: yupResolver(validationSchema),
  });

  return { control, handleSubmit };
};
