import {
  EolasFile,
  EolasShadowCopyContent,
  EolasShadowCopyFile,
  eolasLogger,
  fileStore,
} from "@eolas-medical/core";
import { getUrlObject } from "Utilities/helpers";

export const getOriginalFromShadowCopy = (
  item: EolasShadowCopyContent | EolasShadowCopyFile,
  shouldLogError = true,
): EolasFile | null => {
  const deeplinkUrl = getUrlObject(item.deepLinkUrl);
  if (!deeplinkUrl) {
    if (shouldLogError) {
      eolasLogger.error(
        new Error("convertShadowCopyFileToOriginal: no deeplink url found on key"),
        { item },
      );
    }
    return null;
  }
  const originalFileId = deeplinkUrl.searchParams.get("fileId");
  if (!originalFileId) {
    if (shouldLogError) {
      eolasLogger.error(new Error("convertShadowCopyFileToOriginal. Expected fileId searchParam"), {
        item,
      });
    }
    return null;
  }
  const originalFile = fileStore.getFile(originalFileId);
  if (!originalFile) {
    if (shouldLogError) {
      eolasLogger.error(new Error("convertShadowCopyFileToOriginal. No file found in fileStore"), {
        item,
      });
    }
    return null;
  }

  return originalFile;
};
