import { useMemo } from "react";
import { useLaunchDarkly } from "Contexts";

import { FilePickerAllowedTypes } from "Utilities/types";

const defaultFileTypes: FilePickerAllowedTypes[] = ["link", "mp4", "pdf", "ms-office"];

export const useAllowedFileTypes = (
  allowedFileTypes: FilePickerAllowedTypes[] = defaultFileTypes,
) => {
  const {
    flags: { "ms-office-files": msOfficeFiles },
  } = useLaunchDarkly();

  const types = useMemo(() => {
    if (msOfficeFiles) return allowedFileTypes;

    return allowedFileTypes.filter((t) => t !== "ms-office");
  }, [allowedFileTypes, msOfficeFiles]);

  return types;
};
