import { sectionStore } from "@eolas-medical/core";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useMemo } from "react";

export const useDetermineVisibleOrganisationTab = (): {
  shouldShowOrgTab: boolean;
  isHiddenForNonAdmins: boolean;
  adminOf: "organisation" | "space" | "both" | "none";
} => {
  const orgFullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const fullAccessLevel = useGetAdminStatus({ activeTab: "spaces" });
  const { organisationActiveHospitalTab, spaceActiveHospitalTab } = sectionStore;

  const isUserOrgAdmin = isAccessLevelInAdminGroup(orgFullAccessLevel);
  const isUserSpaceAdmin = isAccessLevelInAdminGroup(fullAccessLevel);

  const results: { shouldShowOrgTab: boolean; isHiddenForNonAdmins: boolean } = useMemo(() => {
    // If both tabs are active, show the org tab always
    if (organisationActiveHospitalTab && spaceActiveHospitalTab) {
      return { shouldShowOrgTab: true, isHiddenForNonAdmins: false };
    }

    if (organisationActiveHospitalTab && !spaceActiveHospitalTab) {
      if (isUserOrgAdmin) {
        return { shouldShowOrgTab: true, isHiddenForNonAdmins: true };
      }
      if (isUserSpaceAdmin) {
        return { shouldShowOrgTab: true, isHiddenForNonAdmins: true };
      }
    }

    return { shouldShowOrgTab: false, isHiddenForNonAdmins: false };
  }, [organisationActiveHospitalTab, spaceActiveHospitalTab, isUserOrgAdmin, isUserSpaceAdmin]);

  const adminOf = useMemo(() => {
    if (isUserOrgAdmin && isUserSpaceAdmin) {
      return "both";
    }
    if (isUserOrgAdmin) {
      return "organisation";
    }
    if (isUserSpaceAdmin) {
      return "space";
    }
    return "none";
  }, [isUserOrgAdmin, isUserSpaceAdmin]);

  return {
    shouldShowOrgTab: results.shouldShowOrgTab,
    isHiddenForNonAdmins: results.isHiddenForNonAdmins,
    adminOf,
  };
};
