import { AnalyticsEvents } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { FileSection, FileSectionUpdateDto } from "modules/myFiles/types";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";

const useEditSection = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation<
    FileSection,
    Error,
    FileSectionUpdateDto
  >(async (fileSection: FileSectionUpdateDto) => myFilesService.updateSection(fileSection), {
    onSuccess: (newSection) => {
      trackEvent(AnalyticsEvents.MYFILES_SECTION_EDIT, { subSectionId: newSection.id });

      queryClient.setQueryData(myFilesKeys.sectionLists(), (actualList?: FileSection[]) => {
        return actualList?.map((item: FileSection) => {
          if (item.id === newSection.id) {
            return newSection;
          }
          return item;
        });
      });
    },
  });

  return {
    editSection: mutate,
    editingSection: isLoading,
    editSectionSuccessful: isSuccess,
    editSectionError: error?.message,
  };
};

export default useEditSection;
