import { Space, spacesClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { mapSpecialtyNames } from "../helpers";

const useSearchSpace = () => {
  const { mutate, isLoading, error } = useMutation(async (query: string) => {
    const searchResponse = await spacesClient.searchSpaces(query);
    const spaces: Space[] = searchResponse.items.map((s) => ({
      ...s,
      id: s.appID,
      description: s.description || "",
      accessType: s.accessType || "private",
      userCount: s.total_users || 1,
      specialty: mapSpecialtyNames(
        Array.isArray(s?.specialty) ? s.specialty : s.specialty ? s.specialty.split(",") : [],
      ),
    }));
    return spaces;
  });

  return {
    searchSpace: mutate,
    searchingSpace: isLoading,
    searchSpaceError: error instanceof Error ? error.message : "",
  };
};

export default useSearchSpace;
