import { eolasLogger, EolasSupportedMiscellaneousType, NewContentType } from "@eolas-medical/core";
import { StepNames, WizardStateWithoutStep } from "../types";
import { isSupportedBlobType } from "../typeguards";

export const getLastMainWizardStepName = (state: WizardStateWithoutStep): StepNames => {
  let type: string | undefined = undefined;
  if (state.isExistingFileWithNewType) {
    type = state.dto.newType;
  } else {
    type = state.type;
  }
  if (!type) {
    eolasLogger.error(new Error("ConfirmAndUpload: invalid type"), { state });
    return "confirmAndUpload";
  }
  switch (type) {
    case EolasSupportedMiscellaneousType.FLASHCARD:
      return "selectFlashcardHeroImage";
    case EolasSupportedMiscellaneousType.LINK:
      return "selectFile";
    case EolasSupportedMiscellaneousType.DSM:
      return "fileDetails";
    case EolasSupportedMiscellaneousType.EOLAS_EDITOR:
      return "eolasEditorCreator";
    default:
      if (type === NewContentType.FILE || isSupportedBlobType(type)) {
        return "fileDetails";
      }
      eolasLogger.error(new Error("ConfirmAndUpload: invalid type"), { state });
      return "confirmAndUpload";
  }
};
