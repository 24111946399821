import { useSubmitFeedback } from "../hooks/useSubmitFeedback";
import { Control, UseFormHandleSubmit, UseFormReset } from "react-hook-form";
import { useState } from "react";
import { Comment } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";
import { Button, FormElement, Text, Textarea } from "UIKit";

type Props = {
  questionID: string;
  likeOrDislike: "like" | "dislike";
  handleSubmit: UseFormHandleSubmit<FormState>;
  isSubmitSuccessful: boolean;
  control: Control<FormState>;
  isValid: boolean;
  reset: UseFormReset<FormState>;
  comments: Comment[];
  title?: string;
};

type FormState = {
  comment: string;
};

export const FeedbackBox = ({
  questionID,
  likeOrDislike,
  control,
  isSubmitSuccessful,
  handleSubmit,
  isValid,
  reset,
  comments,
  title,
}: Props) => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const onSuccess = () => {
    setWantsFurtherComment(false);
    showNotification({
      type: "success",
      autoHideTimeout: 3000,
      description: t("thanks_for_feedback"),
    });
  };
  const { isError, isLoading, submitFeedback } = useSubmitFeedback(onSuccess);
  const [wantsFurtherComment, setWantsFurtherComment] = useState(false);
  const onSubmit = async (formValues: FormState) => {
    // Prevent multiple submissions
    if (isLoading) {
      return;
    }
    if (formValues.comment.length > 0) {
      await submitFeedback({
        title,
        questionID,
        likeOrDislike,
        comment: formValues.comment,
      });
    }
  };

  if (isSubmitSuccessful && !wantsFurtherComment) {
    return (
      <Button
        variant="solid"
        color="blue"
        onClick={() => {
          reset();
          setWantsFurtherComment(true);
        }}
      >
        <Text level={2}>
          {comments.length > 0 || isSubmitSuccessful
            ? t("send_another_comment")
            : t("send_comment")}
        </Text>
      </Button>
    );
  }

  return (
    <div className="mb-4">
      <form className="flex flex-col mb-8" onSubmit={handleSubmit(onSubmit)}>
        <FormElement
          required
          id="comment"
          control={control}
          errorMessage={isError ? t("feedback_error") : undefined}
        >
          {(fieldProps) => (
            <Textarea
              {...fieldProps}
              rowSpan={4}
              className=""
              disabled={isLoading}
              placeholder={t("feedback_caption")}
            />
          )}
        </FormElement>
        <Button
          type="submit"
          color="blue"
          variant="solid"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          <Text level={1}>{t("general_submit")}</Text>
        </Button>
      </form>
    </div>
  );
};
