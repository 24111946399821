import { contentClient, eolasLogger, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { AddContentItemDto, MediaUploadParams } from "../types";
import { isFileExtensionMediaType, mapToAddContentItemDto } from "../helpers";
import { getBlobType, getFileName } from "Utilities/fileHelpers";
import { generateS3FileKey } from "Utilities";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { useState } from "react";
import { AxiosProgressEvent } from "axios";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseAddContentItemProps<T, R> {
  mainSectionId: string;
  parentId: string;
  onSuccess: (params?: T) => Promise<R>;
  mediaUploadParams: MediaUploadParams;
}

const useAddContentItem = <T, R>({
  mainSectionId,
  parentId,
  onSuccess,
  mediaUploadParams,
}: UseAddContentItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { ownerType, isPublicItem = false, isOrganisationFile, ownerId } = mediaUploadParams;

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!); // set completion progress to 25%
    setUploadProgress(percentCompleted);
  };

  const addContentItem = async ({
    name,
    description,
    expiryDate,
    flashcardContent,
    imageUrl,
    file,
    linkUrl,
    itemType,
    keywords,
    createdBy,
    isDraft,
    dsmData,
  }: AddContentItemDto) => {
    let fileType;
    let key = "";
    let fileMediaId: string | undefined;
    let fileMediaName: string | undefined;

    const isSpaceOrOrganisationItem = ownerType === "ORGANISATION" || ownerType === "SPACE";

    if ((itemType === "blob" || itemType === "dsm") && file) {
      fileType = getBlobType(file.type);
      fileMediaName = `${getFileName(file)}.${fileType}`;

      if (!isPublicItem) {
        if (useAppServicesEndpoints) {
          const s3Params = {
            isPublic: false,
            fileName: fileMediaName,
            fileFormat: file.type,
            mainSectionId,
            addExtension: true,
          };
          key = generateS3FileKey(s3Params);
          await uploadFile(key, file, setProgress);
        } else {
          const { mediaId } = await uploadFileV2({
            file,
            mainSectionId,
            onUploadProgress: setUploadProgress,
          });
          fileMediaId = mediaId;
          key = mediaId;
        }
      } else if (ownerType && isSpaceOrOrganisationItem && isFileExtensionMediaType(fileType)) {
        const { publicUrl } = await uploadFileV2({
          file,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isOrganisationFile,
          isPublic: isPublicItem,
        });

        if (!publicUrl) {
          eolasLogger.error(new Error("File upload failed"));
          return;
        }

        key = publicUrl;
      } else if (ownerType && ownerType === "COMMUNITY" && isFileExtensionMediaType(fileType)) {
        const { uploadUrl, url } = await knowledgeContentClient.createPublicMedia({
          name: fileMediaName,
          ownerId: ownerId || "",
          mediaType: fileType,
          ownerType,
        });

        if (uploadUrl) {
          await uploadFileToPresignedS3(uploadUrl, file, setUploadProgress);
          key = url;
        }
      }
    }

    if (itemType === "link" && linkUrl) {
      key = linkUrl;
    }

    const contentItemDto = await mapToAddContentItemDto({
      parentId,
      contentItem: {
        name,
        description,
        expiryDate,
        flashcardContent,
        imageUrl,
        file,
        linkUrl,
        itemType,
        keywords,
        createdBy,
        isDraft,
        dsmData,
      },
      fileType,
      key,
      mediaId: fileMediaId,
      mediaName: fileMediaName,
    });
    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const result: any = await contentClient.addContentItem(mainSectionId, contentItemDto);

    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentItemDto: AddContentItemDto) => addContentItem(contentItemDto),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  return {
    addContentItem: mutate,
    addingContentItem: isLoading,
    addContentItemSuccess: isSuccess,
    addContentItemError: error instanceof Error ? error.message : "",
    addContentItemProgress: uploadProgress,
  };
};

export default useAddContentItem;
