import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Space } from "@eolas-medical/core";
import { Loader, Text, Title } from "UIKit";
import SpaceSearch from "./components/SpaceSearch/SpaceSearch";
import usePublicSpaces from "modules/spaces/data/usePublicSpaces";
import { DiscoverSpaceItem } from "./components/DiscoverSpaceItem/DiscoverSpaceItem";
import LoadMore from "UIKit/LoadMore/LoadMore";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { motion } from "framer-motion";

const DiscoverSpaces = () => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const showSuggestedSpaces = !!flags[LDFlagNames.DISCOVER_SPACES_SUGGESTED];

  const { publicSpaces, publicSpacesLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    usePublicSpaces({ enabled: showSuggestedSpaces });

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState<Space[] | undefined>();

  const handleSearchSuccessful = (searchResults: Space[], input?: string) => {
    setSearchResults(searchResults);
    input && setSearchInput(input);
  };

  const handleClearResults = () => {
    setSearchResults(undefined);
  };

  const handleLoadMore = () => {
    if (hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  };

  const renderTitle = () => {
    if (!showSuggestedSpaces || (searchResults && searchResults.length === 0)) {
      return null;
    }

    const titleKey = searchResults ? "selectSpace_search_result" : "selectSpace_suggested_space";

    return (
      <Title level={6} className="text-grey-600">
        {t(titleKey)}
      </Title>
    );
  };

  const renderSpaceItem = useCallback((discoveredSpace: Space) => {
    return (
      <div key={discoveredSpace.id}>
        <DiscoverSpaceItem space={discoveredSpace} />
      </div>
    );
  }, []);

  const renderSpacesList = () => {
    if (searchResults) {
      if (searchResults.length === 0) {
        return (
          <div className="flex flex-col px-4 sm:px-40 py-28 text-center items-center space-y-4">
            <Title level={8} className="text-grey-700">
              {t("selectSpace_no_results_found", { term: searchInput })}
            </Title>

            <Text level={1} className="text-grey-700">
              {t("selectSpace_try_another_search")}
            </Text>
          </div>
        );
      }
      return searchResults.map(renderSpaceItem);
    }

    if (publicSpacesLoading) {
      return <Loader className="bg-transparent h-30vh" />;
    }
    if (showSuggestedSpaces) {
      return publicSpaces.map(renderSpaceItem);
    }
  };

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <motion.div
        className="mt-8 md:mt-4"
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.3 }}
      >
        <SpaceSearch
          onSearchSuccessful={handleSearchSuccessful}
          onClearSearch={handleClearResults}
        />
      </motion.div>
      <div className="flex flex-col space-y-4 md:space-y-0">
        <div className="flex items-center justify-center sm:justify-start space-x-2 my-0 md:my-2">
          {renderTitle()}
        </div>
        <div className="flex flex-col relative mt-4 space-y-4">
          {renderSpacesList()}
          {hasNextPage && !searchResults && showSuggestedSpaces && (
            <LoadMore
              loadMoreText={t("selectSpace_load_more_spaces")}
              isLoading={isFetchingNextPage || false}
              onLoadMore={handleLoadMore}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DiscoverSpaces;
