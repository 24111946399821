import { useCallback } from "react";

import { useFileOperations, useRefetchAppData } from "Hooks";
import { removeLastUrlPath, useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { EolasChecklistSection, LDFlagNames } from "Utilities/types";
import { contentClient } from "@eolas-medical/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useLaunchDarkly } from "Contexts";

export const useDeleteChecklist = (checklist: EolasChecklistSection) => {
  const { error, isSuccessful, isLoading, setRequestStatus } = useRequestStatus();
  const { removeFile } = useFileOperations();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const onDelete = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    const removeFilePromise = useAppServicesEndpoints
      ? removeFile({
          variables: {
            id: checklist.id,
            parentID: checklist.parentID,
          },
        })
      : contentClient.deleteSectionRest(checklist.id);

    removeFilePromise
      .then(async () => {
        push(removeLastUrlPath(url, 2));
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [
    setRequestStatus,
    removeFile,
    checklist.id,
    checklist.parentID,
    push,
    url,
    useAppServicesEndpoints,
    refetch,
  ]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDelete,
  };
};
