import { FC, useMemo } from "react";

import { GentResults } from "@eolas-medical/core";

import { GentamicinIcon } from "Assets";

import { ResultBox } from "./ResultBox";

interface IGentamicinDoseResultsProps {
  results: GentResults;
}

export const GentamicinDoseResults: FC<IGentamicinDoseResultsProps> = ({
  results,
}: IGentamicinDoseResultsProps) => {
  const { gentamicinDose, error, creatinineClearance } = results;

  const gentamicinDoseToDisplay = gentamicinDose ? `${gentamicinDose}mg` : "N/A";

  const details = useMemo(() => {
    if (gentamicinDose) {
      return [
        `Based on a calculated creatinine clearance of ${creatinineClearance} ml/min.`,
        "Always consider if the details are correct for the patient, and that the dose seems sensible.",
        "This is the initial dose only. Subsequent doses should be based on serum peak and trough gentamicin levels.",
      ];
    }
    return [];
  }, [gentamicinDose, creatinineClearance]);

  return (
    <ResultBox
      title="Dose"
      error={error}
      details={details}
      text={gentamicinDoseToDisplay}
      detailsTitleStyle="mt-4 font-bold"
      icon={<GentamicinIcon className="w-6 h-6 mr-4" />}
    />
  );
};
