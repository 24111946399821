import { useRunOnMountUnmount } from "Hooks";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { InnerPageWrapper, Loader, PageTitle, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  ownerType: "space" | "organisation";
  ownerId: string;
};

type RenderCondition = "loading" | "no-access" | "access-pending";

export const LinkedDocumentsNoAccess = ({ ownerId, ownerType }: Props) => {
  const [renderCondition, setRenderCondition] = useState<RenderCondition>("loading");
  const { t } = useTranslation();
  const { onSelectSpace } = useSelectSpaceActions(
    undefined,
    `${location.pathname}${location.search}`,
  );

  useRunOnMountUnmount({
    onMount: async () => {
      const res = await onSelectSpace(ownerId);
      if (res === "pending") {
        setRenderCondition("access-pending");
        return;
      }

      if (res === "denied") {
        setRenderCondition("no-access");
        return;
      }
    },
  });

  if (renderCondition === "loading") {
    return <Loader />;
  }

  if (renderCondition === "access-pending") {
    return (
      <InnerPageWrapper>
        <div
          className="flex flex-col justify-start items-center flex-1 pt-16"
          data-testid="portfolio-error-fallback"
        >
          <PageTitle title={t("linked_documents_viewer_access_pending")} />
          <Text level={1} className="my-2">
            {t("linked_documents_viewer_access_pending_message")}
          </Text>
          <Link
            to={ownerType === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.organisation}`}
            className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
          >
            {t("take_me_home")}
          </Link>
        </div>
      </InnerPageWrapper>
    );
  }

  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-16"
        data-testid="portfolio-error-fallback"
      >
        <PageTitle title={t("linked_documents_viewer_access_denied")} />
        <Text level={1} className="my-2">
          {t("linked_documents_viewer_no_access_message")}
        </Text>
        <Link
          to={ownerType === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.organisation}`}
          className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2 mt-8"
        >
          {t("take_me_home")}
        </Link>
      </div>
    </InnerPageWrapper>
  );
};
