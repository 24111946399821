import { FormConfig, useEolasForm } from "Hooks/useEolasForm";
import { FieldValues } from "react-hook-form";
import { FormElement } from "modules/portfolio/types";
import { makeValidationSchema } from "../../MyPortfolio/hooks/helpers";

interface UseFeedbackFormProps extends Omit<FormConfig, "validationSchema"> {
  formElements: FormElement[];
}

const useFeedbackForm = ({ formElements, defaultValues }: UseFeedbackFormProps) => {
  const validationSchema = makeValidationSchema({ formElements });
  const formData = useEolasForm<FieldValues>({ validationSchema, defaultValues });
  return formData;
};
export default useFeedbackForm;
