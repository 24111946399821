import { Maybe, clinicalQuestionsClient, userStore } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";

type Rating = "like" | "dislike";

const isPreviousRating = (rating: Maybe<"like" | "dislike" | "neutral">): rating is Rating => {
  return rating === "like" || rating === "dislike";
};

export const makeGetUserRatingQueryKey = (questionID: string) => ["getUserRating", questionID];

export const useGetUserRating = ({ questionID }: { questionID: string | null }) => {
  const { data, isFetching } = useQuery({
    queryKey: makeGetUserRatingQueryKey(questionID ?? ""),
    queryFn: async () => {
      if (!questionID) {
        return;
      }
      return await clinicalQuestionsClient.getClinicalQuestionRatingByUser({
        userID: userStore.userID,
        questionID,
      });
    },
    cacheTime: 0,
  });

  const userRating = isPreviousRating(data?.body.likeOrDislike) ? data?.body.likeOrDislike : null;
  return { userRating, isFetching, comments: data?.body.comments };
};
