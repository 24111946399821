import { EmptyFolderOpen, FullFolderOpen } from "Assets";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { useTranslation } from "react-i18next";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { SectionChildDetailsProps } from "../EolasSectionTile.types";
import { Text } from "UIKit/Typography";

export const SectionTileWithChildDetails = ({
  title,
  rightAction,
  leftAction,
  onClick,
  iconUrl,
  childrenType,
  isDisabled = false,
}: SectionChildDetailsProps) => {
  const { t } = useTranslation();
  const { getIconComponent } = useIconComponent("w-full h-full");
  let secondaryText: JSX.Element | undefined = undefined;
  if (childrenType) {
    secondaryText = (
      <div className="flex flex-row gap-1 items-center">
        {childrenType === "sections" ? (
          <EmptyFolderOpen className="h-4 w-4 text-grey-500" />
        ) : (
          <FullFolderOpen className="h-4 w-4 text-grey-500" />
        )}
        <Text level={2} className="text-grey-600">
          {childrenType === "files"
            ? t("select_tile_modal_with_files")
            : t("select_tile_modal_with_subsections")}
        </Text>
      </div>
    );
  }
  return (
    <EolasTile
      isDisabled={isDisabled}
      variant="rectangular"
      primaryText={title}
      rightAction={rightAction}
      leftAction={leftAction}
      icon={
        <div className="rounded-1 bg-grey-100 p-4 w-full h-full">{getIconComponent(iconUrl)}</div>
      }
      onClick={onClick}
      secondaryText={secondaryText}
    />
  );
};
