import { DrugIndicationsAndDose } from "@eolas-medical/core";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";
import { DrugIndicationsAndDoseBaseComponent } from "./DrugIndicationsAndDoseBaseComponent";

type DrugInteractionAndDoseProps = {
  section: DrugIndicationsAndDose;
} & InfoIdProps;

const DrugIndicationsAndDoseContent = ({ section, ...rest }: DrugInteractionAndDoseProps) => {
  if (!section.drugContent) {
    return null;
  }

  return (
    <BnfCollapsible title={section.potName} {...rest}>
      <DrugIndicationsAndDoseBaseComponent indsAndDose={section} />
    </BnfCollapsible>
  );
};

export default DrugIndicationsAndDoseContent;
