import { useTranslation } from "react-i18next";

import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { NormalStepComponentProps, NormalStepData } from "../../types";
import { SummaryOfChangesStep } from "UIKit/Wizard/templates/SummaryOfChangesStep/SummaryOfChangesStep";
import { useSummaryOfChanges } from "./hooks/useSummaryOfChanges";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { getLastMainWizardStepName } from "../../functions/getLastMainWizardStepName";
import {
  handleUpdateWizardDraftStatus,
  useMakePenultimateStepButtonProps,
} from "../../../../hooks/useMakePenultimateStepButtonProps";

const SummaryOfChanges = ({
  onClickBack,
  onClickForward,
  wizardState,
}: NormalStepComponentProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control } = useSummaryOfChanges(wizardState);

  const submitFn = ({ isDraft }: { isDraft: boolean }) => {
    handleSubmit(({ summaryOfChanges }) => {
      let newState = makeMutableWizardState(wizardState);
      if (newState.isExistingFileWithNewType) {
        newState.dto.newProperties.summaryOfChanges = summaryOfChanges
          ? summaryOfChanges
          : undefined;
      } else if (newState.fileId) {
        newState.dto.summaryOfChanges = summaryOfChanges ? summaryOfChanges : undefined;
      }
      newState = handleUpdateWizardDraftStatus({
        state: newState,
        isDraft,
        isSummaryOfChanges: true,
      });
      onClickForward(newState);
    })();
  };

  const buttonProps = useMakePenultimateStepButtonProps({
    isSummaryOfChanges: true,
    currentWizardState: wizardState,
    handleClickBack: onClickBack,
    handleClickForward: () => submitFn({ isDraft: false }),
    handleAltClickForward: () => submitFn({ isDraft: true }),
  });

  return (
    <EolasWizardModalWrapper
      header={{ title: t("repository_edit_item_summary_of_changes") }}
      buttonRowProps={buttonProps}
    >
      <SummaryOfChangesStep control={control} />
    </EolasWizardModalWrapper>
  );
};

export const summaryOfChangesData: NormalStepData = {
  StepComponent: SummaryOfChanges,
  props: {
    getPreviousStepName: (_, state) => {
      return getLastMainWizardStepName(state);
    },
    getNextStepName: () => "confirmAndUpload",
  },
};
