export const MedicinalFormsStrings = {
  medicinalForms: "Medicinal forms",
  excipients: "Excipients",
  electrolytes: "Electrolytes",
  labels: "Cautionary and advisory labels",
  labelDetail: (no: number, qualifier?: string) =>
    `Label: ${no}${qualifier ? ` (${qualifier})` : ""}`,
  nhsIndicativePrice: "NHS Indicative Price",
  noPreps: (form: string) => `No Preparation Information for: ${form}`,
};
