import { ProfileVerificationStatus } from "@eolas-medical/core";
import { ProfessionalBody } from "./pages/MyProfile/components/ProfileVerificationModal/ProfileVerificationModal";

export const ProfileStatusLabel = {
  [ProfileVerificationStatus.UNVERIFIED]: "Not Verified",
  [ProfileVerificationStatus.PENDING]: "Pending Verification",
  [ProfileVerificationStatus.VERIFIED]: "Verified",
};

export const ProfessionalBodyLabel: Record<ProfessionalBody, string> = {
  [ProfessionalBody.GENERAL_MEDICAL_COUNCIL]: "General Medical Council",
  [ProfessionalBody.NURSING_AND_MIDWIFERY_COUNCIL]: "Nursing and Midwifery Council",
  [ProfessionalBody.GENERAL_PHARMACEUTICAL_COUNCIL]: "General Pharmaceutical Council",
  [ProfessionalBody.IRISH_MEDICAL_COUNCIL]: "Irish Medical Council",
  [ProfessionalBody.NURSING_AND_MIDWIFERY_BOARD_IRELAND]: "Nursing and Midwifery Board Ireland",
  [ProfessionalBody.PHARMACEUTICAL_SOCIETY_OF_IRELAND]: "Pharmaceutical Society of Ireland",
};
