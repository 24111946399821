import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PostSpaceCreationStepItem from "./components/PostSpaceCreationStepItem/PostSpaceCreationStepItem";
import usePostSpaceCreationSteps from "./hooks/usePostSpaceCreationSteps";
import CarouselModal from "UIKit/CarouselModal/CarouselModal";
import { spaceBackground } from "Assets";

const PostSpaceCreationModal = ({
  onComplete,
  spaceName,
}: {
  onComplete: () => void;
  spaceName: string;
}) => {
  const { t } = useTranslation();

  const { postSpaceCreationSteps } = usePostSpaceCreationSteps();

  const baseStep = (
    <div className="flex flex-col h-full">
      <div className="flex-1 relative">
        <img
          src={spaceBackground}
          alt=""
          className="absolute inset-0 w-full h-full object-cover"
          style={{
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
          }}
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <h1 className="text-white font-bold text-xl">{t("post_cognito_welcome_title")}</h1>
          <h2 className="text-white font-extrabold text-5xl">{spaceName}</h2>
        </div>
        <div className="h-52 xl:h-80 2xl:h-96" />
      </div>
      <div className="flex-1 bg-white overflow-auto p-4 flex items-center justify-center">
        <div className="text-center text-grey-900 flex flex-col items-center justify-center">
          <p className="text-center font-bold text-lg lg:text-xl mb-3">
            {t("post_space_creation_modal_base_title")}
          </p>
          <div className="text-md lg:text-lg font-semibold">
            <Trans i18nKey="post_space_creation_modal_base_description">
              Eolas Spaces are made up of two types of Sections,
              <span className="text-blue-300">Content Repository Sections</span>
              and <span className="text-blue-300">Mini-Apps</span>.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CarouselModal onComplete={onComplete}>
      <div className="h-full">
        <div className="relative mb-4 h-full">{baseStep}</div>
      </div>
      {postSpaceCreationSteps.map((step, index) => (
        <div className="h-full" key={index}>
          <div className="relative mb-4 h-full">
            <PostSpaceCreationStepItem
              backgroundImg={step.backgroundImg}
              animation={step.animation}
              title={step.title}
              pill={step.pill}
              content={step.content.map((item, index) => (
                <div key={index} className="flex gap-3 items-center mb-12 h-full">
                  <div className="text-center">{item.text}</div>
                </div>
              ))}
            />
          </div>
        </div>
      ))}
    </CarouselModal>
  );
};

export default PostSpaceCreationModal;
