import { contentClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";

export const useFetchContentSectionById = ({
  sectionId,
  isEnabled,
}: {
  sectionId: string;
  isEnabled?: boolean;
}) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetchContentSectionById", sectionId],
    queryFn: () => contentClient.getSectionRest(sectionId),
    enabled: isEnabled,
  });

  return {
    contentSection: data,
    isLoading: isLoading && isEnabled,
    isError: isError,
    error,
  };
};
