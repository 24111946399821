import { useState, useCallback, useEffect } from "react";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

interface QueryResult {
  id: string;
}
export interface UseQuerySearchProps<T> {
  key: string[];
  queryFn: (query: string) => Promise<T[]>;
  minInputLength?: number;
  queryDisabled?: boolean;
  options?: Omit<UseQueryOptions<T[], Error, T[], QueryKey>, "queryKey" | "queryFn">;
  onSearchCallback?: (searchValue: string) => void;
}

export const useSearch = ({
  minInputLength,
  onSearchCallback,
}: {
  minInputLength: number;
  onSearchCallback?: (searchValue: string) => void;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (searchInput.length < minInputLength) setSearchValue("");
  }, [searchInput, setSearchValue, minInputLength]);

  const onSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      if (typeof e === "string") {
        setSearchInput(e);
        return;
      }
      setSearchInput(e.target.value.toLowerCase());
    },
    [setSearchInput],
  );

  const onSearch = useCallback(
    (searchText?: string) => {
      if (searchText && searchText.length >= minInputLength) {
        setSearchValue(searchText);
        if (onSearchCallback) {
          onSearchCallback(searchValue);
        }
        return;
      }
      if (searchInput.length >= minInputLength) setSearchValue(searchInput);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchValue, searchInput, minInputLength, onSearchCallback],
  );

  const onClearSearch = useCallback(() => {
    setSearchValue("");
    setSearchInput("");
  }, [setSearchInput]);

  return { searchValue, searchInput, onSearchInputChange, onSearch, onClearSearch };
};

export const useQuerySearch = <T extends QueryResult>({
  key,
  queryFn,
  minInputLength = 3,
  queryDisabled = false,
  options,
}: UseQuerySearchProps<T>) => {
  const { searchValue, searchInput, onSearchInputChange, onSearch, onClearSearch } = useSearch({
    minInputLength,
  });

  const { data = [], isFetching } = useQuery<T[], Error>(
    [...key, { searchValue }],
    () => queryFn(searchValue),
    {
      ...options,
      enabled: !queryDisabled && searchValue.length >= minInputLength,
    },
  );

  return {
    files: data || [],
    isLoading: isFetching,
    searchInput,
    searchValue,
    onSearchInputChange,
    onSearch,
    onClearSearch,
  };
};
