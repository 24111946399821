import { useState, useCallback } from "react";
import {
  BlobContent,
  hasProp,
  LinkContent,
  Metadata,
  SupportedFileType,
} from "@eolas-medical/core";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { EditItemValues } from "../types";
import { isItemChanged } from "Utilities/helpers";
import { DsmError, makeWorkbook } from "../../../functions/parseDsmSheet/helpers";
import { parseWorkbook } from "../../../functions/parseDsmSheet/parseWorkbook";

interface UseEditContentItemActionsProps {
  contentItem: LinkContent | BlobContent;
  onClose: () => void;
}

const getEditItem = ({
  values,
  isLink,
  summaryOfChanges,
}: {
  values: EditItemValues;
  isLink: boolean;
  summaryOfChanges?: string;
}) => {
  const editItem: Omit<EditItemValues, "dsmData"> & {
    summaryOfChanges?: string;
    metadata?: Metadata;
  } = { name: values.name, keywords: values.keywords };
  if (isLink) {
    editItem.url = values.url;
  } else {
    editItem.expiryDate = values.expiryDate;
  }

  if (hasProp(values.dsmData, "parsedData")) {
    editItem.metadata = { dsmData: values.dsmData.parsedData };
  }

  if (summaryOfChanges) {
    editItem.summaryOfChanges = summaryOfChanges;
  }

  return editItem;
};

export const useEditContentItemActions = ({
  contentItem,
  onClose,
}: UseEditContentItemActionsProps) => {
  const { useEditContentItemHook } = useContentRepositoryContext();
  const {
    editContentItem,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
  } = useEditContentItemHook;
  const [wizardState, setWizardState] = useState<EditItemValues>({});

  const handleUpdateWizardValues = useCallback((newValues: EditItemValues) => {
    setWizardState((prevState) => ({ ...prevState, ...newValues }));
  }, []);

  const handleChangeFileItemDetails = useCallback(
    ({ values, summaryOfChanges }: { values: EditItemValues; summaryOfChanges?: string }) => {
      const editItem = getEditItem({ values, summaryOfChanges, isLink: false });

      editContentItem({
        contentItem,
        editItem,
        blob: wizardState.blob,
      });
    },
    [editContentItem, wizardState.blob, contentItem],
  );

  const handleUpdateLinkItem = useCallback(
    ({ values, summaryOfChanges }: { values: EditItemValues; summaryOfChanges?: string }) => {
      const editItem = getEditItem({ values, summaryOfChanges, isLink: true });

      editContentItem(
        {
          contentItem,
          editItem,
        },
        {
          onSuccess: () => onClose(),
        },
      );
    },
    [editContentItem, contentItem, onClose],
  );

  const handleUpdateWithSummaryOfChanges = useCallback(
    ({ summaryOfChanges, isLinkItem }: { summaryOfChanges?: string; isLinkItem: boolean }) => {
      return isLinkItem
        ? handleUpdateLinkItem({
            values: wizardState,
            summaryOfChanges,
          })
        : handleChangeFileItemDetails({ values: wizardState, summaryOfChanges });
    },
    [handleUpdateLinkItem, handleChangeFileItemDetails, wizardState],
  );

  const handleOnSubmitFileDetails = useCallback(
    ({
      values,
      canAddSummaryOfChanges,
    }: {
      values: EditItemValues;
      canAddSummaryOfChanges: boolean;
    }) => {
      const editItem = getEditItem({ values, isLink: false });

      if (!isItemChanged({ item: contentItem, editItem, blob: wizardState.blob })) {
        onClose();
        return;
      }

      return canAddSummaryOfChanges
        ? handleUpdateWizardValues(values)
        : handleChangeFileItemDetails({ values });
    },
    [contentItem, handleChangeFileItemDetails, onClose, wizardState.blob, handleUpdateWizardValues],
  );

  const handleOnSubmitLinkDetails = useCallback(
    ({
      values,
      canAddSummaryOfChanges,
    }: {
      values: EditItemValues;
      canAddSummaryOfChanges: boolean;
    }) => {
      const editItem = getEditItem({ values, isLink: true });

      if (!isItemChanged({ item: contentItem, editItem })) {
        onClose();
        return;
      }

      return canAddSummaryOfChanges
        ? handleUpdateWizardValues(values)
        : handleUpdateLinkItem({ values });
    },
    [contentItem, handleUpdateLinkItem, handleUpdateWizardValues, onClose],
  );

  const onBlobSelection = useCallback(
    async (file: File, type: SupportedFileType) => {
      if (type !== "dsm") {
        if (wizardState.dsmData) {
          setWizardState((old) => {
            const newState = { ...old };
            delete newState.dsmData;
            return newState;
          });
        }
        return;
      }
      try {
        const workBook = await makeWorkbook(file);
        const parsedData = parseWorkbook(workBook);
        setWizardState((prevState) => {
          return {
            ...prevState,
            selectedType: "dsm",
            dsmData: { parsedData },
          };
        });
      } catch (error) {
        let dsmError: DsmError;
        if (error instanceof DsmError) {
          dsmError = error;
        } else {
          console.error(error);
          dsmError = new DsmError("An unknown error ocurred", "other");
        }
        setWizardState((prevState) => ({
          ...prevState,
          selectedType: "dsm",
          dsmData: {
            dsmError,
          },
        }));
      }
    },
    [wizardState],
  );

  return {
    wizardState,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
    handleUpdateWizardValues,
    handleUpdateWithSummaryOfChanges,
    handleOnSubmitFileDetails,
    handleOnSubmitLinkDetails,
    onBlobSelection,
  };
};
