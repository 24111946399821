import { observer } from "mobx-react-lite";
import { LoadingOverlay } from "UIKit";
import { useCheckInvitation } from "../hooks/useCheckInvitation";
import { AutoAccessBaseModal } from "../modals/AutoAccessBaseModal";

export const CheckInvitation = observer(() => {
  const { isLoggedIn, state } = useCheckInvitation();

  if (!state || isLoggedIn) {
    return <LoadingOverlay />;
  }

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        background: "linear-gradient(180deg, #6197FE 0%, #EBF1FD 72.92%)",
      }}
    >
      <AutoAccessBaseModal
        linkType="invite"
        modalState={state.outcome}
        handleCloseModal={state.handleCloseModal}
      />
    </div>
  );
});
