import { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { ContentItem } from "@eolas-medical/core";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { PaginatedItemsList } from "shared/components/Lists";
import ContentItemInstance from "../ContentItemInstance/ContentItemInstance";

interface ContentItemListProps {
  isAdmin: boolean;
  flashcardsHaveSpecialties?: boolean;
}

export type MenuActionType = "edit" | "delete" | "download";

const PaginatedContentItemList = observer(
  ({ isAdmin, flashcardsHaveSpecialties }: ContentItemListProps) => {
    const { useContentItemsHook, useSearchContentItemsHook } = useContentRepositoryContext();
    const { contentItems, contentItemsLoading, isFetchingMoreItems, fetchMoreItems, hasMoreItems } =
      useContentItemsHook;
    const {
      searchedItems,
      searchingItems,
      searchValue,
      searchInput,
      onClearSearch,
      onSearchInputChange,
      onSearch,
    } = useSearchContentItemsHook;

    const items = useMemo(() => {
      if (searchValue.length === 0) {
        return contentItems;
      }

      return searchedItems;
    }, [contentItems, searchedItems, searchValue]);

    const renderFile = useCallback(
      (contentItem: ContentItem) => {
        if (contentItem.isDraft && !isAdmin) return null;

        return (
          <ContentItemInstance
            contentItem={contentItem}
            data-testid={`content-item-${contentItem.id}`}
            isAdminMode={isAdmin}
            flashcardsHaveSpecialties={flashcardsHaveSpecialties}
          />
        );
      },
      [isAdmin, flashcardsHaveSpecialties],
    );

    return (
      <div data-testid="content-item-list">
        <PaginatedItemsList<ContentItem>
          items={items}
          renderItem={renderFile}
          isLoading={contentItemsLoading || searchingItems}
          isFetchingMoreItems={isFetchingMoreItems}
          hasMoreItems={hasMoreItems && searchValue.length === 0}
          searchValue={searchInput}
          onFetchMoreItems={fetchMoreItems}
          onClearSearch={onClearSearch}
          onSearchInputChange={onSearchInputChange}
          onClickSearch={onSearch}
        />
      </div>
    );
  },
);

export default PaginatedContentItemList;
