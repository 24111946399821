import ContentItems from "shared/pages/ContentRepository/ContentItems/ContentItems";
import { useKnowledgeContentInfoContext } from "../../context/knowledgeContentInfoContext";
import useKnowledgeContentParams from "../../hooks/useKnowledgeContentParams";
import { useGetSearchParams } from "Utilities/useGetSearchParams";

const KnowledgeContentItems = () => {
  const { domain, isUserAdmin } = useKnowledgeContentInfoContext();
  const { mainSectionId, subSectionId } = useKnowledgeContentParams({
    domain,
  });

  const { title } = useGetSearchParams({
    paramList: ["title"],
  });

  return (
    <ContentItems
      contentRepositoryId={mainSectionId ?? ""}
      subSectionId={subSectionId ?? ""}
      isAdmin={isUserAdmin}
      disableReorder
      isPaginated={true}
      title={title}
    />
  );
};

export default KnowledgeContentItems;
