import ContentViewerContext from "shared/pages/ContentViewer/context/contentViewer.context";
import useFetchKnowledgeContentItem from "modules/knowledgeContent/data/useFetchKnowledgeContentItem";
import useKnowledgeContentParams from "./hooks/useKnowledgeContentParams";
import { KnowledgeContentDomain } from "@eolas-medical/core";

interface KnowledgeContentViewerProviderProps {
  children: React.ReactNode;
  domain: KnowledgeContentDomain;
}

const KnowledgeContentViewerProvider = ({
  children,
  domain,
}: KnowledgeContentViewerProviderProps) => {
  const { contentId } = useKnowledgeContentParams({
    domain,
  });
  const useContentItemHook = useFetchKnowledgeContentItem({ itemId: contentId, domain });

  return (
    <ContentViewerContext.Provider
      value={{
        useContentItemHook,
      }}
    >
      {children}
    </ContentViewerContext.Provider>
  );
};

export default KnowledgeContentViewerProvider;
