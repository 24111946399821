import { useMemo } from "react";
import { ActionButton, ActionButtonType } from "modules/portfolio/types";

interface UseFeedbackFormButtonsProps {
  actionButtons: ActionButton[];
  onSubmit: () => void;
  onDelete: () => void;
  onDeleteFeedbackAndEvent: () => void;
  onDecline: () => void;
}

const useFeedbackFormButtons = ({
  actionButtons,
  onSubmit,
  onDelete,
  onDeleteFeedbackAndEvent,
  onDecline,
}: UseFeedbackFormButtonsProps): ActionButton[] => {
  const feedbackButtons: ActionButton[] = useMemo(() => {
    return actionButtons.map((ac) => {
      if (ac.type === ActionButtonType.DELETE_FORM) {
        return {
          ...ac,
          type: ActionButtonType.DECLINE_FEEDBACK,
          onClick: onDelete,
        };
      }
      if (ac.type === ActionButtonType.DECLINE_FEEDBACK) {
        return {
          ...ac,
          onClick: onDecline,
        };
      }
      if (ac.type === ActionButtonType.SUBMIT_FEEDBACK) {
        return {
          ...ac,
          onClick: onSubmit,
        };
      }
      if (ac.type === ActionButtonType.DELETE_FEEDBACK_AND_EVENT) {
        return {
          ...ac,
          onClick: onDeleteFeedbackAndEvent,
        };
      }
      return ac;
    });
  }, [actionButtons, onSubmit, onDelete, onDecline, onDeleteFeedbackAndEvent]);

  return feedbackButtons;
};

export default useFeedbackFormButtons;
