import {
  SupportedSection,
  SupportedSubSection,
  supportedSections,
  supportedSubSections,
} from "@eolas-medical/core";

export const isSupportedSection = (section: string): section is SupportedSection =>
  Object.values<string>(supportedSections).includes(section);

export const isSupportedSubSection = (subSection: string): subSection is SupportedSubSection =>
  Object.values<string>(supportedSubSections).includes(subSection);
