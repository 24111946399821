import { FieldValues, useForm, UnpackNestedValue, DeepPartial } from "react-hook-form";
import FormStep from "./FormStep";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormItem } from "./types";
import useFormStepValidationSchema from "./useFormValidationSchema";

interface UseFormStepProps<T, FormStepValues extends FieldValues> {
  stepName: T;
  formItems: FormItem<FormStepValues>[];
  defaultValues?: UnpackNestedValue<DeepPartial<FormStepValues>>;
  onSubmit: (data: FormStepValues) => void;
  title: string;
  isLoading?: boolean;
  nextLabel?: string;
  backLabel?: string;
  submitError?: string;
  onPrev?: () => void;
}

const useFormStep = <T extends string, FormStepValues extends FieldValues>({
  stepName,
  formItems,
  defaultValues,
  onSubmit,
  onPrev,
  title,
  isLoading,
  nextLabel,
  backLabel,
  submitError,
}: UseFormStepProps<T, FormStepValues>) => {
  const validationSchema = useFormStepValidationSchema<FormStepValues>(formItems);

  const { control, handleSubmit, formState } = useForm<FormStepValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitValues = (data: UnpackNestedValue<FormStepValues>) => {
    onSubmit(data as FormStepValues);
  };

  const canProceed = formState.isSubmitSuccessful && !isLoading;

  return {
    name: stepName,
    title,
    submitError: !!submitError || !!formState.errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(handleSubmitValues),
    isLoading,
    nextLabel,
    backLabel,
    onPrev,
    component: (
      <FormStep<FormStepValues> control={control} errors={formState.errors} formItems={formItems} />
    ),
  };
};

export default useFormStep;
