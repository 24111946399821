import { removeLastUrlPath } from "Utilities";
import { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

export const useProcessItems = <T extends object>({
  fetchedItems,
}: {
  fetchedItems: T[] | undefined;
}) => {
  const [items, setItems] = useState<T[]>([]);

  const activeItem: T | null = items[0] ?? null;

  const { goBack, replace, length } = useHistory();
  const { url } = useRouteMatch();

  const goBackOverride = useCallback(() => {
    if (!items.length) {
      if (length > 2) {
        goBack();
        return;
      }
      replace(removeLastUrlPath(url));
      return;
    }
    setItems((old) => {
      const newArr = [...old];
      newArr.shift();
      return newArr;
    });
  }, [replace, length, url, goBack, items]);

  const makeOnClick = useCallback((nextSection: T) => {
    return () => setItems((old) => [nextSection, ...old]);
  }, []);

  return {
    fetchedItems,
    historyItems: items,
    activeItem,
    setHistoryItems: setItems,
    goBackOverride,
    makeOnClick,
  };
};
