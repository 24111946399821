import { Option } from "UIKit/Select/Select";
import { IsPaidOption, SlotOption } from "../types/general";

export const slotOptions: SlotOption[] = [
  { value: "BANNER", label: "Banner only" },
  { value: "URL", label: "Banner with clickable link" },
  { value: "CONTENT_ITEMS", label: "Banner with section containing items" },
  { value: "CONTENT_SECTIONS", label: "Banner with section containing subsections" },
];

export const countriesOptions: Option[] = [
  { label: "Ireland", value: "Ireland" },
  { label: "UK", value: "UK" },
  { label: "USA", value: "USA" },
];

export const isPaidOptions: IsPaidOption[] = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
