import { contentClient, EolasFile } from "@eolas-medical/core";
import { useAppSync } from "Hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { intermediateUpdateForUi } from "../../../functions/intermediateUpdateForUi";

export const usePublishItem = (file: EolasFile | null) => {
  const { t } = useTranslation();
  const { refetch } = useAppSync();

  const publishItem = useCallback(() => {
    if (!file) {
      return;
    }

    modalStore.openModal({
      name: "publishItem",
      variant: "dialogue",
      layoutType: "stacked",
      title: t("content_item_publish_title"),
      message: t("content_item_publish_desc"),
      icon: "warning",
      isDismissible: true,
      onConfirmAsync: async () => {
        await contentClient.updateContentItem({
          contentId: file.id,
          mainSectionId: file.mainSectionID,
          contentDto: { isDraft: false },
        });
        const newFile: EolasFile = { ...file, isDraft: false };
        intermediateUpdateForUi({ file: newFile, action: "update", type: "file" });
        refetch();
      },
    });
  }, [refetch, file, t]);

  return { publishItem };
};
