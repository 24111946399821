import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticationClient } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";

import { changePasswordSchema } from "Utilities/authValidations";

export const useChangePassword = () => {
  const { error, isLoading, isSuccessful, setRequestStatus } = useRequestStatus();

  const formMethods = useForm({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(async ({ oldPassword, newPassword }) => {
    try {
      setRequestStatus({ error: "", status: "pending" });
      await authenticationClient.changeUserPassword({ oldPassword, newPassword });
      setRequestStatus({ error: "", status: "success" });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setRequestStatus({ error: error.message, status: "error" });
    }
  });

  return {
    error,
    isLoading,
    formMethods,
    isSuccessful,
    onSubmit,
  };
};
