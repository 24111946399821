import { SearchTypeSection } from "Components/MasterSearch/types";
import { Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";

const SearchDisclaimer = ({ searchType }: { searchType: SearchTypeSection }) => {
  const { t } = useTranslation();
  const textColor = "text-grey-600";

  const renderLocalDisclaimer = () => {
    return (
      <>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_space")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_organisation")}
          </Text>
        </li>
      </>
    );
  };

  const renderKwowledgeDisclaimer = () => {
    return (
      <>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_national_guidelines")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_clinical_calculators")}
          </Text>
        </li>
      </>
    );
  };
  return (
    <div className="p-3 -mt-8">
      <Title level={6} className={`${textColor} my-4`}>
        {t("master_search_desclaimer_title")}
      </Title>
      <ul className={`list-disc ${textColor} px-3 space-y-2`}>
        {searchType == "local" ? renderLocalDisclaimer() : renderKwowledgeDisclaimer()}
      </ul>
    </div>
  );
};

export default SearchDisclaimer;
