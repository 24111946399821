import { DeleteModal } from "UIKit";
import { EolasFile } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";

import { useFileOperations } from "Hooks";
import { errorStore } from "Stores/ErrorStore";
import { useTranslation } from "react-i18next";

export interface DeleteMajorIncidentModalProps {
  file: EolasFile;
  onCloseModal(): void;
}

export const DeleteMajorIncidentModal: React.FC<DeleteMajorIncidentModalProps> = ({
  file,
  onCloseModal,
}: DeleteMajorIncidentModalProps) => {
  const { removeFile } = useFileOperations();
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const { t } = useTranslation();

  const onDelete = async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await removeFile({
        variables: {
          id: file.id,
          parentID: file.parentID,
        },
      });
      setRequestStatus({ status: "success", error: "" });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: removeFile,
        retryParameters: {
          variables: {
            id: file.id,
            parentID: file.parentID,
          },
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      isSuccessful={isSuccessful}
      onCloseModal={onCloseModal}
      title={file.name}
      description={t("deleteModal_delete_majorIncident_description")}
    />
  );
};
