import { NationalResourceEntityType, searchClient } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import communityKeys from "./community.queryKeys";
import { useMemo } from "react";

export interface NationalBodiesState {
  name: string;
  id: string;
}

type Props = {
  entityType: NationalResourceEntityType;
  isEnabled?: boolean;
  bodiesToInject?: { id: string; name: string }[];
};

const useGetNationalBodies = ({ entityType, isEnabled = true, bodiesToInject }: Props) => {
  const { supportedEolasRegion } = useSupportedRegion();

  const { data, isLoading, isError, error } = useQuery(
    communityKeys.bodies(entityType),
    () => searchClient.searchNationalBodies(entityType, supportedEolasRegion),
    { enabled: isEnabled },
  );

  const filteredNationalBodies = useMemo(() => {
    if (!data) return [];

    return [...data, ...(bodiesToInject ?? [])]
      .map((name, index) => {
        if (typeof name === "string") {
          return { name, id: index.toString() };
        }
        return name;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data, bodiesToInject]);

  return {
    nationalBodies: filteredNationalBodies,
    gettingNationalBodies: isLoading,
    error,
    isError,
  };
};

export default useGetNationalBodies;
