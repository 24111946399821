import { Text, IconButton } from "UIKit";
import { LoginTokenIcon, CopyIcon, DeleteIcon } from "Assets";
import { useLoginToken } from "./useLoginToken";
import { useToolTip } from "Hooks/useToolTip";

interface LoginTokenProps {
  token: string;
}

export const LoginToken: React.FC<LoginTokenProps> = ({ token }: LoginTokenProps) => {
  const { makeToolTipDataAttr } = useToolTip();
  const { tooltipText, onCopyClicked, afterTooltipHide, onDeleteToken, isLoading } =
    useLoginToken(token);

  return (
    <div className="flex items-center p-6">
      <LoginTokenIcon className="w-10 h-10" />

      <div className="flex flex-col md:flex-row">
        <Text className="flex-1 text-grey-700 font-bold ml-4" level={2}>
          {`${window.location.origin}/auto-login/${encodeURIComponent(token)}`}
        </Text>
        <div className="flex items-center justify-end space-x-2">
          <IconButton
            size="sm"
            data-tip
            variant="rounded"
            icon={<CopyIcon />}
            onClick={onCopyClicked}
            onMouseLeave={afterTooltipHide}
            {...makeToolTipDataAttr({ text: tooltipText })}
          />

          <IconButton
            size="sm"
            variant="rounded"
            isLoading={isLoading}
            icon={<DeleteIcon />}
            onClick={onDeleteToken}
          />
        </div>
      </div>
    </div>
  );
};
