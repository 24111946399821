import { useTranslation } from "react-i18next";

import { AtIcon, TrustedEmailIcon } from "Assets";
import {
  Text,
  Title,
  Input,
  Button,
  ModalBody,
  FormElement,
  SuccessModal,
  InnerModalWrapper,
} from "UIKit";

import { useAddTrustedEmail } from "./useAddTrustedEmail";

interface AddTrustedEmailModalProps {
  onCloseModal(): void;
}

export const AddTrustedEmailModal: React.FC<AddTrustedEmailModalProps> = ({
  onCloseModal,
}: AddTrustedEmailModalProps) => {
  const { t } = useTranslation();
  const { control, isDirty, isLoading, isSuccessful, onSubmit } = useAddTrustedEmail();

  if (isSuccessful) {
    return (
      <SuccessModal onComplete={onCloseModal} text="manageUsers_update_trusted_email_success" />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalBody>
        <TrustedEmailIcon className="w-20 h-20 self-center" />

        <Title className="text-center my-6" level={5}>
          {t("manageUsers_trusted_email_authentication")}
        </Title>

        <form className="flex flex-col" onSubmit={onSubmit}>
          <Text className="font-semibold mt-6" level={2}>
            {t("manageUsers_trusted_email_desc")}
          </Text>

          <FormElement control={control} id="emailDomain" className="mt-4">
            {({ className, ...field }) => (
              <Input
                {...field}
                iconLeft={<AtIcon />}
                className={`${className} bg-grey-100`}
                placeholder={t("manageUsers_trusted_email_enterSuffix")}
              />
            )}
          </FormElement>

          <Button isLoading={isLoading} className="mt-4" type="submit" disabled={!isDirty}>
            {t("manageUsers_trusted_email_submit_btn")}
          </Button>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
