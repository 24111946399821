import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { AddFlashcardItemDto } from "modules/contentRepository/types";
import { getBlobType } from "Utilities/fileHelpers";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { createResourcesManifestFromHtml, mapToAddFlashcardDto } from "modules/contentRepository/helpers";

interface UseAddContentItemPublicProps {
  mainSectionId: string | null;
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useAddKnowledgeContentFlashcardItem = ({
  mainSectionId,
  parentId,
  domain,
}: UseAddContentItemPublicProps) => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleAddFlashcardItem = async ({
    name,
    description,
    flashcardContent,
    file,
    createdBy,
    specialty,
    imageUrl,
    isDraft,
    queriesForHit,
    documentIdentifiers,
  }: AddFlashcardItemDto) => {
    let fileType;

    if (file) {
      fileType = getBlobType(file.type);
    }

    if (!mainSectionId || !parentId) {
      throw new Error("MainSectionId or parentId missing");
    }

    // Note: At time of writing knowledge flashcards created via web don't have hero images
    const resourcesManifest = await createResourcesManifestFromHtml(flashcardContent);

    const flashcardItemDto = mapToAddFlashcardDto({
      mainSectionId,
      parentId,
      name,
      description,
      flashcardContent,
      specialty,
      createdBy,
      fileType,
      resourcesManifest: JSON.stringify(resourcesManifest),
      url: imageUrl,
      isSponsored: false,
      isDraft,
      isKnowledge: true,
      queriesForHit,
      documentIdentifiers,
    });

    const { uploadUrl } = await knowledgeContentClient.createFlashcardItem(flashcardItemDto);

    if (file && uploadUrl) {
      await uploadFileToPresignedS3(uploadUrl, file, setUploadProgress);

      setUploadProgress(50);
    }

    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentDto: AddFlashcardItemDto) => handleAddFlashcardItem(contentDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
        setUploadProgress(100);
      },
    },
  );

  let addFlashcardItemError = "";

  if (error) {
    addFlashcardItemError = error instanceof Error ? error.message : JSON.stringify(error);
  }

  return {
    addFlashcardItem: mutate,
    addingFlashcardItem: isLoading,
    addFlashcardItemSuccess: isSuccess,
    addFlashcardItemError,
    addFlashcardItemProgress: uploadProgress,
  };
};

export default useAddKnowledgeContentFlashcardItem;
