import { MutateOptions, useQueryClient } from "@tanstack/react-query";
import { mapToUpdateBlobItemDto, mapToUpdateLinkItemDto } from "modules/contentRepository/helpers";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { getBlobType } from "Utilities/fileHelpers";
import useEditKnowledgeContentLink from "./useEditKnowledgeContentLink";
import useEditKnowledgeContentFile from "./useEditKnowledgeContentFile";
import {
  BlobContent,
  ContentItem,
  KnowledgeContentDomain,
  LinkContent,
  isBlobContentItem,
  isLinkContentItem,
} from "@eolas-medical/core";

interface UseAddKnowledgeContentItemProps {
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useEditKnowledgeContentItem = ({ parentId, domain }: UseAddKnowledgeContentItemProps) => {
  const queryClient = useQueryClient();

  const { editLink, editingLink, editingLinkSuccess, editingLinkError } =
    useEditKnowledgeContentLink();

  const { editFile, editingFile, editingFileSuccess, editingFileError, editingFileProgress } =
    useEditKnowledgeContentFile();

  const handleEditContentItem = (
    {
      contentItem,
      editItem,
      blob,
    }: {
      contentItem: ContentItem;
      editItem: Partial<LinkContent | BlobContent>;
      blob?: File;
    },
    options:
      | MutateOptions<
          void,
          unknown,
          {
            contentItem: ContentItem;
            editItem: Partial<LinkContent | BlobContent>;
            blob?: File;
          },
          unknown
        >
      | undefined,
  ) => {
    if (isLinkContentItem(contentItem)) {
      return editLink(
        {
          contentId: contentItem.id,
          updateLinkItemDto: mapToUpdateLinkItemDto(editItem),
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
            if (options && options.onSuccess) {
              options.onSuccess(
                data,
                {
                  contentItem,
                  editItem,
                  blob,
                },
                undefined,
              );
            }
          },
        },
      );
    }

    if (isBlobContentItem(contentItem)) {
      let fileType;

      if (blob) {
        fileType = getBlobType(blob.type);
      }

      return editFile(
        {
          contentId: contentItem.id,
          updateBlobDto: mapToUpdateBlobItemDto(contentItem, editItem, fileType),
          file: blob,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
          },
        },
      );
    }
  };

  return {
    editContentItem: handleEditContentItem,
    editingContentItem: editingLink || editingFile,
    editContentItemSuccess: editingLinkSuccess || editingFileSuccess,
    editContentItemError: editingLinkError || editingFileError,
    editContentItemProgress: editingFileProgress,
  };
};

export default useEditKnowledgeContentItem;
