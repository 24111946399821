import React from "react";

interface FileViewerComponentProps {
  viewer: React.RefObject<HTMLDivElement>;
}

export const FileViewerComponent: React.FC<FileViewerComponentProps> = ({
  viewer,
}: FileViewerComponentProps) => {
  return <div ref={viewer} style={{ width: "100%", height: "100%" }} />;
};
