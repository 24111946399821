import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContentItem, KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

const useDeleteKnowledgeContentItem = ({
  parentId,
  domain,
}: {
  parentId: string | null;
  domain: KnowledgeContentDomain;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, error, reset } = useMutation(
    (contentItem: ContentItem) => knowledgeContentClient.deleteContentItem(contentItem.id),
    {
      onSuccess: () => {
        reset();
        queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
      },
    },
  );

  return {
    deleteContentItem: mutate,
    deletingContentItem: isLoading,
    deleteContentItemSuccess: isSuccess,
    deleteContentItemError: error instanceof Error ? error.message : "",
  };
};

export default useDeleteKnowledgeContentItem;
