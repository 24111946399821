import { eolasLogger, EolasSupportedMiscellaneousType } from "@eolas-medical/core";
import { SupportedPickerType } from "../types";
import { isSupportedBlobType } from "../typeguards";

export const getInitialType = (type?: string): SupportedPickerType => {
  if (!type || isSupportedBlobType(type)) {
    return "blob";
  }
  switch (type) {
    case EolasSupportedMiscellaneousType.FLASHCARD:
      return "flashcard";
    case EolasSupportedMiscellaneousType.EOLAS_EDITOR:
      return "eolas-editor";
    case EolasSupportedMiscellaneousType.LINK:
      return "link";
    case EolasSupportedMiscellaneousType.DSM:
      return "dsm";
    default:
      eolasLogger.error("Manage Content Item Wizard: unsupported file type given", { type });
      return "blob";
  }
};
