import { addMonths } from "date-fns";
import { useState, useMemo } from "react";

import { ChildReference, EolasMainSection, fileStore, sectionStore } from "@eolas-medical/core";

import { NO_EXPIRY_DATE } from "Utilities/types";

type ExpiringSoonSections = 1 | 3 | 6 | 12;

export const useExpiredFiles = (mainSection: EolasMainSection) => {
  const [fileExpiringIn, setFileExpiringIn] = useState<ExpiringSoonSections>(1);
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { id: sectionID } = sectionStore.getChildReferenceByMainSectionType(mainSection)!;
  const childReferences = sectionStore.getChildrenOrder(sectionID);

  const getSectionFiles = () => {
    const combinedFilesOrder: ChildReference[][] = [];
    childReferences.forEach((child: ChildReference) => {
      combinedFilesOrder.push(sectionStore.getChildrenOrder(child.id));
    });
    const flatenFilesOrder = combinedFilesOrder.flat();
    return fileStore.getFiles(flatenFilesOrder);
  };

  const sectionFiles = getSectionFiles();

  const onCloseModal = () => setModal(null);

  const changeExpiryPeriod = (value: ExpiringSoonSections) => () => {
    setFileExpiringIn(value);
  };

  const expiredFiles = useMemo(() => {
    const today = new Date();
    return sectionFiles.filter((file) => {
      if (!file.expiryDate) return false;
      return new Date(file.expiryDate).valueOf() < today.valueOf();
    });
  }, [sectionFiles]);

  const filesExpiringSoon = useMemo(() => {
    const today = new Date();
    const expiresIn = addMonths(new Date(), fileExpiringIn);

    return sectionFiles.filter((file) => {
      if (!file.expiryDate || file.expiryDate === NO_EXPIRY_DATE) return false;
      const fileExpiryDate = new Date(file.expiryDate);

      return (
        fileExpiryDate.valueOf() >= today.valueOf() &&
        fileExpiryDate.valueOf() < expiresIn.valueOf()
      );
    });
  }, [sectionFiles, fileExpiringIn]);

  const noExpiredFilesLabel = useMemo(() => {
    return `expiredPage_no_expired_items_in_${fileExpiringIn}months`;
  }, [fileExpiringIn]);

  return {
    modal,
    setModal,
    onCloseModal,
    expiredFiles,
    fileExpiringIn,
    filesExpiringSoon,
    changeExpiryPeriod,
    noExpiredFilesLabel,
  };
};
