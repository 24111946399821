import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ClinicalQuestionsTitleIcon } from "Assets";
import { useClinicalQuestionInput } from "./hooks/useClinicalQuestionInput";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { BrowseButton } from "./components/BrowseButton";
import { NewQuestionView } from "./components/NewQuestionView";
import { NavBackButton } from "shared/components/LogicComponents";
import { useState } from "react";
import { AnalyticsEvents, clinicalQuestionStore, CommunityLevelSection } from "@eolas-medical/core";
import { useRunOnMountUnmount } from "Hooks";
import { ResponseCards } from "./components/ResponseCards";
import { Button, InnerPageWrapper, Text } from "UIKit";
import { useFeatureGuideModal } from "Components/FeatureGuideModal/hooks/useFeatureGuideModal";
import { trackEvent } from "API/Analytics";

export const ClinicalQuestionsHome = observer(() => {
  const { control, onSubmit, isSubmitting, errors, progress, reset, isSubmitSuccessful, setValue } =
    useClinicalQuestionInput();

  const { flags } = useLaunchDarkly();

  const { shouldShowFeatureGuide, showFeatureGuide } = useFeatureGuideModal(
    CommunityLevelSection.clinicalQuestions,
  );

  const shouldShowBrowserButton = flags[LDFlagNames.SHOW_EOLAS_CLINICAL_QUESTIONS_BROWSER];

  const handleResetQuestion = () => {
    setShowNewQuestion(true);
    reset();
    trackEvent(AnalyticsEvents.CLINICAL_QUESTION_NEW_BUTTON_CLICKED);
    clinicalQuestionStore.resetAll();
  };

  useRunOnMountUnmount({
    onMount: () => {
      if (shouldShowFeatureGuide) {
        showFeatureGuide();
      }
    },
    onUnmount: () => {
      clinicalQuestionStore.resetAll();
    },
  });
  const [showNewQuestion, setShowNewQuestion] = useState(false);

  const shouldShowNewQuestionButton = isSubmitSuccessful && !showNewQuestion;
  const shouldShowResponseCards = isSubmitSuccessful && !showNewQuestion;

  const handleSubmitQuestion = async () => {
    setShowNewQuestion(false);
    onSubmit();
  };

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-start items-center flex-1 w-full pt-4 mb-12">
        <div className="flex-col mb-8 flex items-center gap-4">
          <div className="flex gap-4 mb-10">
            <NavBackButton modePreference="goBackHistory" />

            {shouldShowBrowserButton && !shouldShowNewQuestionButton ? (
              <BrowseButton />
            ) : (
              <NewQuestionButton onClick={handleResetQuestion} />
            )}
          </div>
          <ClinicalQuestionsTitleIcon className="mb-2 w-90vw sm:w-100vw" />
        </div>
        {showNewQuestion || !isSubmitSuccessful ? (
          <NewQuestionView
            setValue={setValue}
            onSubmit={handleSubmitQuestion}
            control={control}
            isSubmitting={isSubmitting}
            errorMessage={errors ? errors.question?.message : undefined}
          />
        ) : null}

        {shouldShowResponseCards ? (
          <>
            <motion.div
              initial={{ opacity: 0, scaleY: 0, originY: 0.5 }}
              animate={{ opacity: 1, scaleY: 1 }}
              transition={{ duration: 0.8 }}
              className="w-full"
            >
              <ResponseCards
                followUpInputError={errors.notes}
                onSubmit={onSubmit}
                progress={progress}
                control={control}
              />
            </motion.div>
          </>
        ) : null}
      </div>
    </InnerPageWrapper>
  );
});

export const NewQuestionButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button
      size="md"
      weight="bold"
      variant="solid"
      onClick={onClick}
      iconLeft="NewQuestionIcon"
      className="self-center border-darkblue-900 rounded-md bg-darkblue-900 hover:bg-white hover:text-black hover:border hover:border-blue-500"
    >
      <Text level={1} className="ml-2 font-bold">
        {t("new_question")}
      </Text>
    </Button>
  );
};
