import { ReactPlayerProps } from "react-player";
import { useCallback, useState, useRef } from "react";
import { CompletedContentStatus, EolasFile, sectionStore } from "@eolas-medical/core";

import { useSelectFile, useCompleteFile } from "Hooks";

const VIDEO_COMPLETION_THRESHOLD = 0.01;

export const useSelectSimpleFileWithVideoPlayer = () => {
  const isAdmin = sectionStore.isAdmin;
  const videoMarkedAsComplete = useRef(false);

  const { onCompleteFile } = useCompleteFile();
  const { onSelectFile: selectFile } = useSelectFile();

  const videoRef = useRef<HTMLHeadingElement>(null);

  const [video, setVideo] = useState({ name: "", url: "", fileId: "" });
  const [modal, setModal] = useState<React.ReactNode>(null);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onCloseVideo = () => setVideo({ name: "", url: "", fileId: "" });

  const onVideoProgress: ReactPlayerProps["onProgress"] = (playerState) => {
    if (playerState.played >= VIDEO_COMPLETION_THRESHOLD) {
      if (!videoMarkedAsComplete.current) {
        videoMarkedAsComplete.current = true;
        if (isAdmin) return;

        onCompleteFile(video.fileId, CompletedContentStatus.COMPLETE);
      }
    }
  };

  const onSelectFile = async (file: EolasFile) => {
    const url = await selectFile(file);

    if (file.type === "mp4") {
      setVideo({ name: file.moreInfoTwo as string, url, fileId: file.id });

      videoMarkedAsComplete.current = false;
      if (videoRef.current) {
        videoRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  };

  return {
    modal,
    video,
    videoRef,
    setVideo,
    setModal,
    onSelectFile,
    onCloseModal,
    onCloseVideo,
    onVideoProgress,
  };
};
