import { fileStore } from "@eolas-medical/core";

export const constructFileBreadcrumbProps = (
  fileId: string,
): { mainSectionId?: string; parentId?: string; fileName: string } => {
  const couldNotFindFile = { mainSectionId: "", parentId: "", fileName: "" };
  const file = fileStore.getFile(fileId);
  if (file) {
    return { mainSectionId: file.mainSectionID, parentId: file.parentID, fileName: file.name };
  }
  return couldNotFindFile;
};
