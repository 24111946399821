import { capitalCase } from "change-case";

import { ConstituentDrugs } from "@eolas-medical/core";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import { Text } from "UIKit";
import { useMonographContext } from "../../../contexts/ MonographTypeContext";
import { useHistory } from "react-router-dom";
import { makeBnfRoute } from "../../../routes/BnfRoutes";
import { COLUMN_CONTAINER_CLASS, LINK_CLASS } from "../../../styles/stylesClasses";
import { Strings } from "../SynonymsContent/SynonymsContent.strings";
import { BnfCollapsible, InfoIdProps } from "../../BnfCollapsible/BnfCollapsible";

type ConstituentDrugsProps = {
  section: ConstituentDrugs;
} & InfoIdProps;

const ConstituentDrugsContent = ({ section, ...rest }: ConstituentDrugsProps) => {
  const { type } = useMonographContext();
  const { push } = useHistory();
  return (
    <BnfCollapsible title={Strings.title} {...rest}>
      <BnfContentElement htmlContent={section.message} addTags="h5" />
      <div className={COLUMN_CONTAINER_CLASS}>
        {section.constituents.map(({ id: constituentId, title }) => {
          const formattedTitle = capitalCase(title);
          return (
            <div
              key={constituentId}
              onClick={() =>
                push(
                  makeBnfRoute({
                    routeKey: type,
                    idOrSid: constituentId,
                    title: formattedTitle,
                  }),
                )
              }
            >
              <Text level={2} className={LINK_CLASS}>
                {formattedTitle}
              </Text>
            </div>
          );
        })}
      </div>
    </BnfCollapsible>
  );
};

export default ConstituentDrugsContent;
