import { Strings } from "./Detail.strings";
import { WoundManagmentDetail } from "@eolas-medical/core";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { removeTags } from "modules/bnf/helpers";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";
import { Divider, Text } from "UIKit";
import { ArrayElement } from "Utilities/types";

export const Detail = ({ detail }: { detail: WoundManagmentDetail }) => {
  return (
    <Collapsible title={removeTags(detail.title)}>
      <Divider>
        {detail.description ? <BnfContentElement htmlContent={detail.description} /> : null}
        <Divider className={detail.description ? undefined : "pt-3"}>
          {detail.products.map((product) => {
            return <Product key={product.ampId} product={product} />;
          })}
        </Divider>
      </Divider>
    </Collapsible>
  );
};

export const Product = ({
  product,
}: {
  product: ArrayElement<WoundManagmentDetail["products"]>;
}) => {
  const priceString = Strings.parsePrice(product.packs);
  return (
    <div className="flex-row py-3">
      <div className="flex-col w-11/12 space-y-1 lg:space-y-3">
        <TextDetail isHeading>{Strings.name}</TextDetail>
        <TextDetail>{Strings.parseName(product.name, product.blackTriangle)}</TextDetail>
        {priceString ? (
          <>
            <TextDetail isHeading>{Strings.price}</TextDetail>
            <TextDetail>{Strings.parsePrice(product.packs)}</TextDetail>
          </>
        ) : null}
        <TextDetail isHeading>{Strings.manufacturer}</TextDetail>
        <TextDetail>{removeTags(product.manufacturer)}</TextDetail>
      </div>
    </div>
  );
};

const TextDetail = ({ children, isHeading }: { children: string; isHeading?: boolean }) => {
  return (
    <div>
      <Text level={isHeading ? 2 : 3}>{children}</Text>
    </div>
  );
};
