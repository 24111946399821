import { useSpaceOrgMainSections, UseSpaceOrgMainSectionsReturn } from "./useSpaceOrgMainSections";

interface UseContentManagementSectionsProps {
  isSpaceContent: boolean;
}

const useContentManagementSections = ({
  isSpaceContent,
}: UseContentManagementSectionsProps): UseSpaceOrgMainSectionsReturn => {
  return useSpaceOrgMainSections({
    isOnAdminView: true,
    activeTab: isSpaceContent ? "spaces" : "organisation",
  });
};

export default useContentManagementSections;
