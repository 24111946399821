import WebViewer, { Core } from "@pdftron/webviewer";
import { Notification, useNotifications } from "Components/Notifications";
import { errorStore } from "Stores/ErrorStore";
import { useGetNiceItemUrl } from "modules/niceGuidelines/data/useGetNiceItemUrl";
import { getChildBookmark, navigateToGuidancePage } from "modules/niceGuidelines/helpers";
import { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  WEBVIEWER_ADD_EVENT_LISTENER_ERROR,
  WEBVIEWER_DOUBLE_INSTANCE_ERROR,
} from "modules/niceGuidelines/constants";
import { handleLinkClick } from "../functions/handleLinkClick";
import { hasStringProp, isString } from "@eolas-medical/core";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { sanitiseUrl } from "Utilities/helpers";

type State = {
  bookmarkNames: { parent: string; child: string };
};

export const useNiceGuidelineViewer = (id: string) => {
  const { t } = useTranslation();
  const viewerContainer = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<Notification>();
  const beenInitialised = useRef(false);
  const externalLinkVisited = useRef(false);
  const pageToScrollToOnGoBack = useRef<number | null>(null);
  const navigatedFromBackButton = useRef(false);

  const history = useHistory();
  const { pageNo } = useGetSearchParams({ paramList: ["pageNo"] });
  const { state } = useLocation<State | undefined>();
  const bookmarkNames = state?.bookmarkNames;

  const { hideNotification, showNotification, updateNotification } = useNotifications();
  const { url: pdfUrl, isLoading, title } = useGetNiceItemUrl(id);

  const handleBackClick = () => {
    navigatedFromBackButton.current = true;
  };
  const onPDFError = useCallback(
    (e: Error) => {
      if (e.message.includes(WEBVIEWER_ADD_EVENT_LISTENER_ERROR)) {
        // FIXME: This is a bit of a hacky fix for an existing issues which causes the webviewer to pause when loading the same pdf on second mount.
        window.location.reload();
        return;
      }
      if (!isLoading && !e.message.includes(WEBVIEWER_DOUBLE_INSTANCE_ERROR)) {
        updateNotification({
          type: "error",
          id: notificationRef.current!.id,
          description: t("fileViewer_error"),
        });
        errorStore.captureError({ error: e.message, source: "user" });
      }
    },
    [t, updateNotification, isLoading],
  );

  const onPDFLoaded = useCallback(
    async (event: SyntheticEvent, documentViewer: Core.DocumentViewer) => {
      if (pageNo && isString(pageNo)) {
        documentViewer.setCurrentPage(Number(pageNo), true);
      } else if (pageToScrollToOnGoBack.current && navigatedFromBackButton.current) {
        documentViewer.setCurrentPage(pageToScrollToOnGoBack.current, true);
        navigatedFromBackButton.current = false;
        pageToScrollToOnGoBack.current = null;
      }
      hideNotification(notificationRef.current!.id);

      if (hasStringProp(bookmarkNames, "parent") || hasStringProp(bookmarkNames, "child")) {
        await scrollToBookmark(documentViewer, bookmarkNames);
      }
    },
    [hideNotification, pageToScrollToOnGoBack, navigatedFromBackButton, bookmarkNames, pageNo],
  );

  const onViewerLoadError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_load_error"),
    });
  }, [t, updateNotification]);

  useEffect(() => {
    if (pdfUrl && !beenInitialised.current) {
      notificationRef.current = showNotification({
        type: "loading",
        description: t("fileViewer_opening_pdf"),
      });
    }
    if (externalLinkVisited.current && notificationRef.current) {
      notificationRef.current = showNotification({
        type: "warning",
        description: t("niceLinkViewer_error"),
      });
      externalLinkVisited.current = false;
    }
  }, [pdfUrl, showNotification, updateNotification, t]);

  useEffect(() => {
    if (!pdfUrl) return;
    beenInitialised.current = true;
    WebViewer(
      {
        path: "/pdftron",
        licenseKey: process.env.REACT_APP_PDF_TRON,
        initialDoc: pdfUrl,
        disabledElements: [
          "ribbons",
          "toolsHeader",
          "toggleNotesButton",
          "selectToolButton",
          "textHighlightToolButton",
          "textUnderlineToolButton",
          "textSquigglyToolButton",
          "textStrikeoutToolButton",
          "linkButton",
        ],
      },
      viewerContainer.current as HTMLDivElement,
    )
      .then(({ UI, Core }) => {
        const { Annotations, Actions, documentViewer } = Core;
        UI.addEventListener(UI.Events.DOCUMENT_LOADED, (event) =>
          onPDFLoaded(event, documentViewer),
        );
        UI.addEventListener(UI.Events.LOAD_ERROR, onViewerLoadError);

        Actions.setCustomOnTriggeredHandler(
          // this property is found on an Action, yet it still gives a TS error so having to cast as any here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Actions.URI as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          async (target, event, documentViewer, options: any) => {
            if (target instanceof Annotations.Link) {
              const targetUri = options.action.uri;
              const sanitisedUrl = sanitiseUrl(targetUri);
              const { outcome, guidanceNumber, subtype, bookmarkNames } =
                handleLinkClick(sanitisedUrl);
              if (outcome === "failure") {
                externalLinkVisited.current = true;
                options.originalOnTriggered(target, event, documentViewer);
              }
              if (outcome === "success") {
                const currentPage = documentViewer.getCurrentPage();
                pageToScrollToOnGoBack.current = currentPage;
                // jump links within the current document of structure 'guidance/ng1234/chapter/section-name' are not handled automatically by webviewer, therefore we handle manually here
                if (guidanceNumber === id && bookmarkNames) {
                  await scrollToBookmark(documentViewer, bookmarkNames);
                }
                navigateToGuidancePage(history, guidanceNumber, subtype, bookmarkNames);
                beenInitialised.current = false;
              }
            } else {
              options.originalOnTriggered(target, event, documentViewer);
            }
          },
        );

        Core.disableEmbeddedJavaScript();
      })
      .catch(onPDFError);

    return () => {
      hideNotification(notificationRef.current!.id);
    };
  }, [pdfUrl, onPDFError, onPDFLoaded, onViewerLoadError, hideNotification, history, id]);

  return {
    viewer: viewerContainer,
    title,
    handleBackClick,
  };
};

export const scrollToBookmark = async (
  documentViewer: Core.DocumentViewer,
  bookmarkNames: { parent: string; child: string },
) => {
  const allBookmarks = await documentViewer.getDocument().getBookmarks();
  const bookmarkParents = allBookmarks[0].getChildren();

  if (bookmarkNames.parent) {
    const matchParent = bookmarkParents.find((bookmark) => {
      return bookmark.getName().toLowerCase().includes(bookmarkNames.parent);
    });
    if (matchParent) {
      if (!bookmarkNames.child) {
        documentViewer.displayBookmark(matchParent);
      } else {
        const bookmarkChildren = matchParent.getChildren();
        const matchChild = bookmarkChildren.length
          ? getChildBookmark(bookmarkChildren, bookmarkNames.child)
          : null;
        documentViewer.displayBookmark(matchChild ? matchChild : matchParent);
      }
    }
  } else if (bookmarkNames.child) {
    const matchChild = getChildBookmark(bookmarkParents, bookmarkNames.child);
    matchChild ? documentViewer.displayBookmark(matchChild) : null;
  }
};
