import { ContentItem, EolasShadowCopyContent } from "@eolas-medical/core";
import { WarningIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Text, Title } from "UIKit";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { partition } from "lodash";
import { useTranslation } from "react-i18next";
import { isInvalidItem } from "../functions/isInvalidItem";

type Props = {
  items: (ContentItem | EolasShadowCopyContent)[];
  onContinue: (items: ContentItem[]) => void;
};

export const InvalidItemsModal = ({ items, onContinue }: Props) => {
  const [invalidItems, validItems] = partition(items, isInvalidItem);
  const { t } = useTranslation();
  const areAllInvalid = invalidItems.length === items.length;
  return (
    <InnerModalWrapper>
      <div
        className={`flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 ${
          areAllInvalid ? "h-25vh" : "h-50vh"
        } `}
      >
        <div>
          <WarningIcon className="h-16 w-16" />
        </div>
        <Title level={8} className="mt-4">
          {t("copy_files_invalid_files_title")}
        </Title>
        <Text level={2} className="text-gray-500">
          {t(
            areAllInvalid
              ? "copy_files_all_invalid_files_description"
              : "copy_files_invalid_files_description",
          )}
        </Text>
        {areAllInvalid ? null : (
          <div className="flex flex-col h-full w-full">
            <Text level={2} className="text-black">
              {t("copy_files_invalid_files_shortcut_files") + ` (${invalidItems.length})`}
            </Text>
            <EolasVersatileList
              className="w-full"
              items={invalidItems}
              renderItem={InvalidItems}
              isInModal
              outerContainerClassName="bg-grey-300 rounded-xl py-2 mt-2"
              innerContainerClassName="px-4 first:pt-2 last:pb-2 h-full w-full"
            />
            <Button
              size="lg"
              color="blue"
              variant="solid"
              onClick={() => (areAllInvalid ? modalStore.closeModal() : onContinue(validItems))}
              className="w-full self-center sm:w-full mt-4 sm:mt-8"
            >
              {t("general_continue")}
            </Button>
          </div>
        )}
      </div>
    </InnerModalWrapper>
  );
};

const InvalidItems = ({
  item,
}: EolasVersatileListRenderItemProps<ContentItem | EolasShadowCopyContent>) => {
  const fileName = item.media?.type ? `${item.name}.${item.media.type}` : item.name;

  return (
    <div className="pb-2">
      <Text level={1} className="text-grey-700 line-clamp-1">
        {fileName}
      </Text>
    </div>
  );
};
