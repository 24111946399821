import { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { SearchSortList } from "shared/components/Lists";

import { FilterSection } from "../../../../components/FilterSection/FilterSection";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import { EventStatus, TimelineData } from "modules/portfolio/types";
import AddNewEventButton from "../../components/AddNewEventButton/AddNewEventButton";
import ExportPortfolioButton from "../../../../components/ExportPortfolioButton/ExportPortfolioButton";
import AddNewEventModal from "../../components/AddNewEventModal/AddNewEventModal";
import useEventFormTemplatesSummary from "modules/portfolio/data/useEventFormTemplates";
import NoEventsMessage from "Pages/MeTab/pages/Portfolio/components/NoEventsMessage/NoEventsMessage";
import useFilteredEventList from "Pages/MeTab/pages/Portfolio/hooks/useFilteredEventList";
import useEventList from "modules/portfolio/data/useEventList";
import TimelineCard from "Pages/MeTab/pages/Portfolio/components/TimelineItem/TimelineCard";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";

export const EventList = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { eventList, eventListLoading } = useEventList();
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const {
    userID = "",
    userData: { givenName = "", familyName = "" },
  } = userStore;

  const {
    filteredEventList,
    filterObject,
    areFiltersApplied,
    searchInput,
    onUpdateFilters,
    onSearchInputChange,
  } = useFilteredEventList(eventList);

  const { templatesSummary, templatesSummaryLoading } = useEventFormTemplatesSummary();

  const eventStatuses = Object.values(EventStatus);

  const handleShowEventModal = useCallback(() => {
    setShowAddEventModal(!showAddEventModal);
  }, [showAddEventModal]);

  return (
    <InnerPageWrapper>
      <Modal open={showAddEventModal} onClose={handleShowEventModal}>
        <AddNewEventModal
          events={templatesSummary}
          eventsLoading={templatesSummaryLoading}
          url={url}
        />
      </Modal>

      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("my_portfolio")} />
        <NavButton to={url.replace(`/${PortfolioConstants.MY_PORTFOLIO_URL}`, "")} />
      </div>

      <div className="flex flex-col space-y-4 justify-center sm:flex-row sm:space-y-0 sm:space-x-4">
        <AddNewEventButton onShowModal={handleShowEventModal} />
        <ExportPortfolioButton
          ownerId={userID}
          ownerName={`${givenName}_${familyName}`}
          onSuccess={() => trackEvent(AnalyticsEvents.PORTFOLIO_EXPORT_PDF)}
        />
      </div>

      <div>
        <FilterSection
          filterObject={filterObject}
          eventStatuses={eventStatuses}
          searchInput={searchInput}
          areFiltersApplied={areFiltersApplied}
          onUpdateFilters={onUpdateFilters}
          onSearchInputChange={onSearchInputChange}
        />

        <SearchSortList<TimelineData>
          items={filteredEventList}
          renderItem={(item: TimelineData) => {
            return (
              <TimelineCard
                date={item.date!}
                title={item.title}
                type={item.type}
                status={item.status!}
                url={`${url}/${item.type.toLowerCase()}/${item.id}`}
              />
            );
          }}
          isLoading={eventListLoading}
          emptyItemsComponent={
            <NoEventsMessage searchText={searchInput} areFiltersApplied={areFiltersApplied} />
          }
        />
      </div>
    </InnerPageWrapper>
  );
};
