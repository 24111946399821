interface ProgressChartProps {
  average: string;
  showAverage?: boolean;
  label?: string;
}

export const ProgressChart = ({
  average,
  showAverage = true,
  label = "*When compared to other sites",
}: ProgressChartProps) => {
  let progressColorClass = "";
  let textColorClass = "";

  const parsedAverage = parseInt(average);

  if (parsedAverage <= 20) {
    progressColorClass = "bg-red-500";
    textColorClass = "text-red-500";
  } else if (parsedAverage > 20 && parsedAverage < 70) {
    progressColorClass = "bg-yellow-500";
    textColorClass = "text-yellow-500";
  } else {
    progressColorClass = "bg-green-500";
    textColorClass = "text-green-500";
  }

  const containerColorClass = `bg-${progressColorClass.split("-")[1]}-50`;

  return (
    <div className="flex items-center w-full ">
      {showAverage ? (
        <div className="mr-4">
          <div className={`text-3xl font-extrabold md:text-5xl ${textColorClass}`}>{average}%</div>
        </div>
      ) : null}
      <div className={`${containerColorClass} w-full h-6 rounded-md`}>
        <div
          className={`${progressColorClass} h-full rounded-md`}
          style={{ width: `${average}%` }}
        />
        <div className="mt-1">
          <div className="text-3xs font-semibold md:text-2xs">{label}</div>
        </div>
      </div>
    </div>
  );
};
