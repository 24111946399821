import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  NationalResourceEntityType,
  EolasFile,
  NationalResourcesClickPayload,
  AnalyticsEvents,
} from "@eolas-medical/core";

import { useSelectFile } from "Hooks";
import { NavButton, PageTitle, InnerPageWrapper, Modal } from "UIKit";

import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { SearchSortList } from "shared/components/Lists";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { CommunitySectionType } from "../../ClinicalCalculators/types";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { trackEvent } from "API/Analytics";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

const NationalGuidelinesBody: React.FC<RouteComponentProps> = observer(() => {
  const { onSelectFile } = useSelectFile();
  const [searchItems, setSearchItems] = useState<EolasFile[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};

  const { t } = useTranslation();

  const { searchNationalResources, searchingNationalResources } = useSearchNationalResources();

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: searchItems,
    keysToSearch: ["name"],
  });

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();
  const {
    showRemoveFavouriteModal,
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  const handleLocalSearch = useCallback(() => {
    onSetSearchInput(searchInput);
    setShowSearchResult(true);
  }, [onSetSearchInput, searchInput]);

  const handleClickGuideline = React.useCallback(
    (guideline: EolasFile) => {
      trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
        nationalResourceType: NationalResourceEntityType.GUIDELINE,
        resourceName: guideline.name,
      } as NationalResourcesClickPayload);
      onSelectFile(guideline);
    },
    [onSelectFile],
  );

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
    setShowSearchResult(false);
  }, []);

  useEffect(() => {
    searchNationalResources(
      {
        entityType: NationalResourceEntityType.GUIDELINE,
        query: body,
        mode: "searchByNationalBody",
      },
      {
        onSuccess: (response) => {
          setSearchItems(response);
        },
      },
    );
  }, [body, searchNationalResources]);

  useEffect(() => {
    if (searchInput === "") {
      setShowSearchResult(false);
    }
  }, [searchInput]);

  const renderItem = React.useCallback(
    (eolasFile: EolasFile) => {
      return (
        <CommunityListItem
          eolasFile={eolasFile}
          onSelectFile={handleClickGuideline}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
          onShareFile={isShareFileEnabled ? () => handleOnShareFile(eolasFile) : undefined}
        />
      );
    },
    [handleClickGuideline, handleOnShareFile, isShareFileEnabled, showRemoveFavouriteModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle title={t("community_nationalGuidelines_title")} />
        <NavButton onClick={() => history.goBack()} />
        <SearchSortList<EolasFile>
          value={searchInput}
          placeholderSearchText={t("findNationalGuidelines_searchPlaceholder")}
          items={showSearchResult ? searchResult : searchItems}
          isSearchable
          searchType="click"
          isLoading={searchingNationalResources}
          onSearchInputChange={setSearchInput}
          onClearSearch={handleClearSearch}
          onClickSearch={handleLocalSearch}
          renderItem={renderItem}
        />
      </InnerPageWrapper>
    </>
  );
});

export default NationalGuidelinesBody;
