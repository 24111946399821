import { ImageBankItem } from "Hooks/useImageBank";
import ImageWithCheck from "UIKit/ImageWithCheck/ImageWithCheck";

type ImageGalleryProps = {
  onClickGalleryImage: (index: number) => void;
  selectedIndex: number | null;
  galleryImages: ImageBankItem[];
};

const ImageGallery = ({ onClickGalleryImage, selectedIndex, galleryImages }: ImageGalleryProps) => {
  return (
    <div className="grid grid-cols-2 gap-4 max-h-40vh overflow-y-scroll px-2">
      {[...galleryImages].map((image: ImageBankItem, index) => (
        <div key={image.name}>
          <ImageWithCheck
            key={image.name}
            image={image.smallImageUrl}
            showCheck={index === selectedIndex}
            onClick={() => onClickGalleryImage(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
