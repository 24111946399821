import { DropResult } from "react-beautiful-dnd";
import { gql, useMutation } from "@apollo/client";

import { sectionStore, ChildReference, contentClient, eolasLogger } from "@eolas-medical/core";

import { errorStore } from "Stores/ErrorStore";
import { reorderItems } from "shared/components/Lists";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

const REORDER = gql`
  mutation Reorder($input: ReorderInput!) {
    reorder(input: $input) {
      id
      childrenOrder {
        id
        type
        description
        icon
        name
        disabled
      }
    }
  }
`;

export const useReorder = (sectionID: string) => {
  const isAdmin = sectionStore.isAdmin;
  const childReferences = sectionStore.getChildrenOrder(sectionID);
  const [reorder] = useMutation(REORDER);
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const onDragEnd = async (dropResult: DropResult) => {
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    const oldOrder = [...childReferences];
    const newOrder: ChildReference[] = reorderItems(
      childReferences,
      dropResult.source.index,
      dropResult.destination?.index,
    );

    sectionStore.setChildrenOrder(sectionID, newOrder);

    if (useAppServicesEndpoints) {
      const { data, errors } = await reorder({
        variables: {
          input: {
            id: sectionID,
            listOrder: newOrder.map((item) => item.id),
          },
        },
      });

      if (errors && errors[0]) {
        const [error] = errors;
        errorStore.captureError({
          error,
          source: "user",
          retryCallback: reorder,
          retryParameters: {
            variables: {
              input: {
                id: sectionID,
                listOrder: newOrder.map((item) => item.id),
              },
            },
          },
        });
        sectionStore.setChildrenOrder(sectionID, oldOrder as ChildReference[]);
      }

      return { data, errors };
    } else {
      try {
        const data = await contentClient.reorderChildrenOrder({
          id: sectionID,
          entityType: "section",
          listOrder: newOrder.map((item) => item.id),
        });

        return { data };
      } catch (error) {
        eolasLogger.error(error);
        sectionStore.setChildrenOrder(sectionID, oldOrder as ChildReference[]);
      }
    }
  };

  return {
    childReferences,
    isDragDisabled: !isAdmin || childReferences.length < 2,
    onDragEnd,
  };
};
