import React from "react";

import { NationalGuidelineFileTile } from "UIKit/FileTile/NationalGuidelineFileTile";
import { EolasFile } from "@eolas-medical/core";
import { RegularFileTile } from "UIKit";
import { CommunitySectionType } from "../../pages/ClinicalCalculators/types";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

export interface KnowledgeSectionItemProps {
  eolasFile: EolasFile;
  onSelectFile?(file: EolasFile): void;
  sectionType: string;
  onShareFile?: () => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
}

const KnowledgeSectionItem: React.FC<KnowledgeSectionItemProps> = ({
  eolasFile,
  onSelectFile,
  sectionType,
  onShareFile,
  showRemoveFavouriteModal,
}: KnowledgeSectionItemProps) => {
  if (sectionType === CommunitySectionType.NATIONAL_RESOURCE) {
    return (
      <NationalGuidelineFileTile
        onSelectFile={onSelectFile}
        isAdmin={false}
        eolasFile={eolasFile}
        onShareFile={onShareFile}
        showRemoveFavouriteModal={showRemoveFavouriteModal}
      />
    );
  }
  return (
    <RegularFileTile
      className="h-32"
      onSelectFile={onSelectFile}
      isAdmin={false}
      eolasFile={eolasFile}
    />
  );
};

export default KnowledgeSectionItem;
