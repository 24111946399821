import { MonographType } from "@eolas-medical/core";
import { createContext, ReactNode, useContext, useMemo } from "react";

export type TypeContext = {
  type: MonographType;
};

const MonographContext = createContext<TypeContext>({
  type: "bnf",
});

export const MonographContextProvider = ({
  children,
  type,
}: {
  children: ReactNode;
  type: MonographType;
}) => {
  const context = useMemo<TypeContext>(() => {
    return { type };
  }, [type]);

  return <MonographContext.Provider value={context}>{children}</MonographContext.Provider>;
};

export const useMonographContext = () => {
  return useContext(MonographContext);
};
