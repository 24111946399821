import { sectionStore } from "@eolas-medical/core";
import { mapToMedicationSection } from "../helpers";

const useMedicationsHighlightsSection = () => {
  const medicationsHighlightsSection = sectionStore
    .getMainSectionList("community")
    .filter((s) => s.icon === "communityPharmaFlashcards")
    .map(mapToMedicationSection)[0];

  if (medicationsHighlightsSection) {
    return medicationsHighlightsSection;
  }

  return undefined;
};

export default useMedicationsHighlightsSection;
