import React, { useEffect } from "react";
import { documentCache } from "./DocumentCache.class";
import DocumentCacheContext from "./documentCache.context";
import { getPreSignUrl } from "Utilities/helpers";

const DocumentCacheEvents = {
  update: "update-pdf-cache",
  delete: "delete-pdf-cache",
} as const;

interface DocumentCacheProviderProps {
  children: React.ReactNode;
}

const DocumentCacheProvider = ({ children }: DocumentCacheProviderProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDeleteCacheItem = React.useCallback((event: any) => {
    documentCache.deleteEntry(event.detail.key);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateCacheItem = React.useCallback((event: any) => {
    documentCache.setItem(event.detail.key, event.detail.value);
  }, []);

  useEffect(() => {
    window.addEventListener(DocumentCacheEvents.delete, onDeleteCacheItem);
    window.addEventListener(DocumentCacheEvents.update, onUpdateCacheItem);

    return () => {
      window.removeEventListener(DocumentCacheEvents.delete, onDeleteCacheItem);
      window.removeEventListener(DocumentCacheEvents.update, onUpdateCacheItem);
    };
  }, [onDeleteCacheItem, onUpdateCacheItem]);

  const getDocumentUrl = ({ fileId, key }: { fileId: string; key?: string }): Promise<string> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!key) {
        return reject("Missing file key.");
      }

      // return value from the cache
      const cacheValue = documentCache.getItem(key);

      if (cacheValue) {
        return resolve(cacheValue);
      }

      try {
        const s3Url = await getPreSignUrl({ fileId });
        documentCache.setItem(key, s3Url);

        resolve(s3Url);
      } catch (err: unknown) {
        reject(err);
      }
    });
  };

  return (
    <DocumentCacheContext.Provider value={{ getDocumentUrl }}>
      {children}
    </DocumentCacheContext.Provider>
  );
};

export default DocumentCacheProvider;
