/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import i18n from "i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { AuthFunctionNames, AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { verifyEmail } from "../auth.actions";

export interface VerifyCodeForm {
  email: string;
  text: string;
}

const schema = yup.object().shape({
  text: yup.string().required(i18n.t("createAccount_verifyEmail_error")).label("Text"),
});

export const useVerifyCode = (email = "", onFormSubmit: any) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { control, handleSubmit } = useForm<VerifyCodeForm>({
    defaultValues: {
      email,
      text: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ email, text }) => {
    setRequestStatus({ status: "pending", error: "" });
    verifyEmail(email, text)
      .then(() => {
        trackEvent(AnalyticsEvents.SIGN_UP_EMAIL_VERIFIED);
        trackEvent(AnalyticsEvents.SIGN_UP_DURATION);
        onFormSubmit();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.confirmSignUp,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  });

  return {
    error,
    control,
    onSubmit,
    isLoading,
  };
};
