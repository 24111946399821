/* eslint-disable camelcase */
import { Loader } from "UIKit/Loader";
import { RefObject, useEffect, useMemo, useState } from "react";
import { Button, FormError } from "UIKit";
import { useTranslation } from "react-i18next";
import { ContentRepositoryId } from "modules/contentRepository/types";
import Flashcard from "UIKit/Flashcard/Flashcard";
import { Parser } from "html-to-react";
import {
  EolasMCEEditor,
  Props as EolasMCEProps,
} from "shared/components/EolasMCEEditor/EolasMCEEditor";
import { ITinyEvents } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { useHandleImageUpload } from "../hooks/useHandleImageUpload";
import { DeeplinkConfig } from "shared/components/EolasMCEEditor/hooks/useDeeplinkingSetup";

interface FlashcardCreatorStepProps {
  editorContent?: string;
  onEditorInit: EolasMCEProps["onEditorInit"];
  isLoading: boolean;
  contentRepositoryId: ContentRepositoryId;
  error?: string;
  onContentChange: (content: string) => void;
  handleSubmitFlashcard?: () => void;
  modalBodyRef?: RefObject<HTMLDivElement>;
  flashcardTitle: string;
  flashcardDescription: string;
  editorConfigs?: {
    deeplinkingConfig?: DeeplinkConfig;
  };
}

const getEditorConfig = (
  handleImageUpload: (blobInfo: unknown, progress: (percent: number) => void) => Promise<string>,
  editorHeight: number,
) => ({
  min_height: editorHeight,
  resize: false,
  menubar: false,
  toolbar: "link| image | blocks | bold italic | bullist numlist | code ", // Add the image and preview buttons
  content_style: `
    body { font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif,
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-size:14px }
    h1 { font-size: 1.75rem; font-weight: 700; margin: 0.5rem 0;}
    h2 { font-size: 1.5rem; font-weight: 700; margin: 0.5rem 0;  }
    h3 { font-size: 1.25rem; font-weight: 700; margin: 0.5rem 0;}
    p { font-size: 1rem;}
    ul { list-style-type: disc; padding-left: 1.25rem; }
    ol { list-style-type: decimal; padding-left: 1.25rem; }
    li { margin-bottom: 0.5rem; }
    a { color: #3B82F6; text-decoration: underline; }
    a:hover { color: #1E40AF; }
    img { display: block; margin-left: auto; margin-right: auto; }
    .w-full { width: 100%; height: auto; }
  `,
  paste_data_images: true,
  paste_as_text: false,
  paste_block_drop: true,
  paste_tab_spaces: 2,
  block_formats: "Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3",
  default_link_target: "_blank",
  link_assume_external_targets: true,
  images_upload_handler: handleImageUpload,
  image_class_list: [
    { title: "Full Width", value: "w-full" },
    { title: "None", value: "" },
  ],
  paste_postprocess: function () {
    alert(
      "When pasting edited content from other websites or text editors, some content might not display as expected. For best results, please use in-built options and always view your flashcard after saving it.",
    );
  },
});

const MAX_SIZE_IN_BYTES = 395 * 1024; // 395KB

export const FlashcardCreatorStep = ({
  isLoading,
  editorContent,
  contentRepositoryId,
  modalBodyRef,
  error,
  flashcardTitle,
  flashcardDescription,
  onEditorInit,
  handleSubmitFlashcard,
  onContentChange,
  editorConfigs,
}: FlashcardCreatorStepProps) => {
  const [initialEditorContent, setInitialEditorContent] = useState(editorContent ?? "");
  const [isSizeExceeded, setIsSizeExceeded] = useState(false);
  const [editorHeight, setEditorHeight] = useState(500);
  const [showPreview, setShowPreview] = useState(false);

  const { t } = useTranslation();
  const handleImageUpload = useHandleImageUpload(contentRepositoryId);
  const editorConfiguration = useMemo(
    () => getEditorConfig(handleImageUpload, editorHeight),
    [handleImageUpload, editorHeight],
  );

  useEffect(() => {
    // Measure the height of the editor div and update the state
    if (modalBodyRef?.current) {
      const height = modalBodyRef.current.offsetHeight;

      setEditorHeight(height - 300);
    }
  }, [modalBodyRef]);

  // Todo: we should not be using MCE editor as a controlled component due to performance considerations
  // https://www.tiny.cloud/docs/tinymce/latest/react-ref/#using-the-tinymce-react-component-as-a-uncontrolled-component
  const handleContentChange = (...args: Parameters<ITinyEvents["onChange"]>) => {
    const [_, editor] = args;
    const content = editor.getContent();
    const encodedContent = encodeURIComponent(content);
    const byteSize = new Blob([encodedContent]).size;
    const newIsSizeExceeded = byteSize > MAX_SIZE_IN_BYTES;
    if (newIsSizeExceeded !== isSizeExceeded) {
      setIsSizeExceeded(newIsSizeExceeded);
    }
    onContentChange(content);
  };

  const togglePreview = () => {
    if (!showPreview) {
      handleSubmitFlashcard?.();
    }
    if (showPreview) {
      setShowPreview(false);
      setInitialEditorContent(editorContent ?? "");
      return;
    }
    setShowPreview(true);
  };

  const getCurrentEditorContent = () => {
    const htmlToReactParser = Parser();
    return htmlToReactParser.parse(editorContent);
  };

  return (
    <div className="flex-grow">
      {isLoading ? <Loader /> : null}
      {!isLoading ? (
        <div className="w-full flex justify-center mb-4">
          <Button onClick={togglePreview} size="sm">
            {showPreview ? t("filePicker_show_editor") : t("filePicker_preview")}
          </Button>
        </div>
      ) : null}
      {showPreview ? (
        <div className="flex-grow h-full">
          <Flashcard
            imgSrc="https://static.eolas.click/Img/placeholder.jpg"
            name={flashcardTitle}
            description={flashcardDescription}
            createdAt={new Date().toISOString()}
            content={getCurrentEditorContent()}
          />
        </div>
      ) : (
        <EolasMCEEditor
          onEditorInit={onEditorInit}
          onKeyUp={handleContentChange}
          onChange={handleContentChange}
          initialValue={initialEditorContent}
          init={editorConfiguration}
          deeplinkConfig={editorConfigs?.deeplinkingConfig}
        />
      )}
      {isSizeExceeded ? <FormError error={t("flashcard_editor_size_exceeded")} /> : null}
      {error ? <FormError error={t(error)} /> : null}
    </div>
  );
};
