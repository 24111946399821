import { hasProp, hasStringProp } from "@eolas-medical/core";
import { KnowledgeResult, LocalResult, ShareableMasterSearchFile } from "../types";
import { isEolasFile } from "modules/contentRepository/typeguards";

export function isLocalSearchResult(result: unknown): result is LocalResult {
  return (
    hasStringProp(result, "searchTypeSection") &&
    result.searchTypeSection === "local" &&
    hasProp(result, "file")
  );
}

export function isKnowledgeSearchResult(result: unknown): result is KnowledgeResult {
  return (
    hasStringProp(result, "searchTypeSection") &&
    result.searchTypeSection === "knowledge" &&
    hasProp(result, "knowledgeFile")
  );
}

export function isShareableMasterSearchFile(obj: unknown): obj is ShareableMasterSearchFile {
  return (
    isEolasFile(obj) &&
    hasStringProp(obj, "mainSectionID") &&
    hasStringProp(obj, "ownerID") &&
    hasStringProp(obj, "parentID") &&
    hasStringProp(obj, "sharedID") &&
    hasStringProp(obj, "name")
  );
}
