import { useState } from "react";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import {
  EolasEditorContent,
  eolasLogger,
  fromAwsJSON,
  parseEolasEditorFileMetadata,
} from "@eolas-medical/core";
import { ConfigureFileValues } from "UIKit";
import { AddEolasEditorItemModalState } from "../../AddEolasEditorItemWizard/types";
import LZString from "lz-string";
import { isItemChanged } from "Utilities/helpers";

interface UseEditEolasEditorItemProps {
  onClose: () => void;
  eolasEditorItem: EolasEditorContent;
}

export interface EditItemModalState {
  title?: string;
  description?: string | null;
  content?: string;
}

export const useEditEolasEditorItemActions = ({
  eolasEditorItem,
  onClose,
}: UseEditEolasEditorItemProps) => {
  const metadata = eolasEditorItem?.metadata ? fromAwsJSON(eolasEditorItem.metadata) : null;

  const [wizardState, setWizardState] = useState<AddEolasEditorItemModalState>({
    name: eolasEditorItem?.name,
    keywords: eolasEditorItem?.description ?? "",
    content: LZString.decompressFromEncodedURIComponent(metadata?.eolasEditorContent as string),
    expiryDate: eolasEditorItem?.expiryDate ?? undefined,
  });

  const { useEditEolasEditorItemHook } = useContentRepositoryContext();
  const {
    editEolasEditorItem,
    editingEolasEditorItem,
    editEolasEditorItemSuccess,
    editEolasEditorItemError,
    editEolasEditorItemProgress,
  } = useEditEolasEditorItemHook;

  const handleEditEolasEditorDetails = (data: Partial<ConfigureFileValues>) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const getEolasEditorEditItem = ({
    content,
    summaryOfChanges,
  }: {
    content: string;
    summaryOfChanges?: string;
  }) => {
    const editItem: {
      id: string;
      eolasEditorContent: string;
      description?: string;
      expiryDate?: string;
      name: string;
      summaryOfChanges?: string;
    } = {
      id: eolasEditorItem.id,
      eolasEditorContent: content,
      description: wizardState?.keywords || undefined,
      expiryDate: wizardState.expiryDate,
      name: wizardState?.name ?? "",
    };

    if (summaryOfChanges) {
      editItem.summaryOfChanges = summaryOfChanges;
    }

    return editItem;
  };

  const handleSubmitEolasEditorItem = ({
    content,
    isDraft,
    summaryOfChanges,
  }: {
    content: string;
    isDraft: boolean;
    summaryOfChanges?: string;
  }) => {
    setWizardState((prevState) => ({
      ...prevState,
      content,
      isDraft,
    }));

    const editItem = getEolasEditorEditItem({ content, summaryOfChanges });

    editEolasEditorItem(editItem);
  };

  const handleUpdateWithSummaryOfChanges = ({
    summaryOfChanges,
  }: {
    summaryOfChanges?: string;
  }) => {
    if (wizardState.content === undefined || wizardState.isDraft === undefined) {
      eolasLogger.error(
        new Error("The wizard state of the eolas editor file should contain content and isDraft"),
      );
      onClose();
      return;
    }

    return handleSubmitEolasEditorItem({
      summaryOfChanges,
      content: wizardState.content,
      isDraft: wizardState.isDraft,
    });
  };

  const handleOnSubmitEolasEditorCreator = ({
    content,
    isDraft,
    canAddSummaryOfChanges,
  }: {
    content: string;
    isDraft: boolean;
    canAddSummaryOfChanges: boolean;
  }) => {
    const editItem = getEolasEditorEditItem({ content });

    if (
      !isItemChanged({
        item: {
          ...eolasEditorItem,
          metadata: parseEolasEditorFileMetadata(eolasEditorItem.metadata)?.eolasEditorContent,
        },
        editItem: {
          description: editItem.description,
          expiryDate: editItem.expiryDate,
          name: editItem.name,
          metadata: editItem.eolasEditorContent,
        },
      })
    ) {
      onClose();
      return;
    }

    return canAddSummaryOfChanges
      ? handleEditEolasEditorDetails({ isDraft, content })
      : handleSubmitEolasEditorItem({ isDraft, content });
  };

  return {
    wizardState,
    editingEolasEditorItem,
    editEolasEditorItemSuccess,
    editEolasEditorItemError,
    editEolasEditorItemProgress,
    onEditEolasEditorDetails: handleEditEolasEditorDetails,
    onUpdateWithSummaryOfChanges: handleUpdateWithSummaryOfChanges,
    onSubmitEolasEditorCreator: handleOnSubmitEolasEditorCreator,
  };
};
