import React from "react";
import { CheckIcon } from "Assets";

interface CardCheckProps {
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const CardCheck: React.FC<CardCheckProps> = ({
  isSelected,
  onClick,
  children,
}: CardCheckProps) => {
  return (
    <div
      onClick={onClick}
      className={
        "relative flex flex-col p-4 justify-center items-center space-y-4 border h-48 w-1/2 border-grey-300 cursor-pointer shadow-md rounded-xl hover:border-blue-100"
      }
    >
      <div className="absolute top-3 right-3">
        <div className="w-6 h-6 rounded-full border border-grey-500">
          {isSelected && <CheckIcon className="text-green-500" />}
        </div>
      </div>

      {children}
    </div>
  );
};
