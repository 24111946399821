import { contentClient, EolasFile } from "@eolas-medical/core";
import { useAppSync } from "Hooks";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { intermediateUpdateForUi } from "../../../functions/intermediateUpdateForUi";
import { useCallback } from "react";

export const useDeleteParallelDraft = (file: EolasFile | null) => {
  const { t } = useTranslation();
  const { refetch } = useAppSync();

  const deleteParallelDraft = useCallback(() => {
    if (!file) {
      return;
    }
    const newFile: EolasFile = { ...file, hasDrafts: false };
    modalStore.openModal({
      name: "deleteParallelDraft",
      variant: "dialogue",
      layoutType: "stacked",
      title: t("content_item_delete_draft_title"),
      message: t("content_item_delete_draft_description"),
      icon: "warning",
      onConfirmAsync: async () => {
        await contentClient.deleteDraftFile({
          contentId: file.id,
          mainSectionId: file.mainSectionID,
        });
        intermediateUpdateForUi({ file: newFile, action: "update", type: "file" });
        refetch();
      },
    });
  }, [refetch, file, t]);

  return { deleteParallelDraft };
};
