import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { EditContentSectionDto } from "../types";
import { mapToUpdateSectionDto } from "../helpers";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseEditContentSectionProps<T, R> {
  onSuccess: (params?: T) => Promise<R>;
}

const useEditContentSection = <T, R>({ onSuccess }: UseEditContentSectionProps<T, R>) => {
  const { mutate, isLoading, isSuccess, error, reset } = useMutation(
    async ({ id, name, iconName, description, defaultSortMethod }: EditContentSectionDto) => {
      const mappedSection = mapToUpdateSectionDto({
        id,
        name,
        iconName,
        description,
        defaultSortMethod,
      });

      /**
       * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await contentClient.updateSectionRest(mappedSection);
      return result;
    },
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "section", action: "update", section: result });
        await onSuccess();
        reset();
      },
    },
  );

  return {
    editContentSection: mutate,
    editingContentSection: isLoading,
    editContentSectionSuccess: isSuccess,
    editContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useEditContentSection;
