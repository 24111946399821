import { ChangeEvent, useCallback, useEffect } from "react";
import useMasterSearchContext from "../context/useMasterSearchContext";
import { useSearch } from "shared/hooks/useQuerySearch/useQuerySearch";

interface UseMasterSearch {
  searchInput: string;
  searchValue: string;
  onSearchInputChange: (input: string) => void;
  onSearch: (searchText?: string) => void;
  onClearSearch: () => void;
}

const useMasterSearch = (): UseMasterSearch => {
  const { onSaveSearch } = useMasterSearchContext();
  const { onClearSearch, searchInput, searchValue, onSearch, onSearchInputChange } = useSearch({
    minInputLength: 2,
  });

  useEffect(() => {
    onSaveSearch(searchValue);
  }, [searchValue, onSaveSearch]);

  const handleSearch = useCallback(
    (searchText?: string) => {
      onSearch(searchText);
    },
    [onSearch],
  );

  const handleSearchInputChange = useCallback(
    (text: string) => {
      const syntheticEvent = {
        target: { value: text },
      } as ChangeEvent<HTMLInputElement>;
      onSearchInputChange(syntheticEvent);
    },
    [onSearchInputChange],
  );

  const handleClearSearch = useCallback(() => {
    onClearSearch();
  }, [onClearSearch]);

  return {
    searchInput: searchInput,
    searchValue: searchValue,
    onSearchInputChange: handleSearchInputChange,
    onSearch: handleSearch,
    onClearSearch: handleClearSearch,
  };
};

export default useMasterSearch;
