import { useQuery } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { UseContentItemHook } from "shared/pages/ContentViewer/context/types";

type Props = {
  itemId: string | null;
  enabled?: boolean;
  domain: KnowledgeContentDomain;
};

const useFetchKnowledgeContentItem = ({
  itemId,
  enabled = true,
  domain,
}: Props): UseContentItemHook => {
  const queryEnabled = !!itemId && enabled;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: knowledgeContentKeys.item(itemId ?? "", domain),
    queryFn: () => knowledgeContentClient.getContentById({ contentId: itemId ?? "" }),
    enabled: queryEnabled,
  });

  return {
    contentItem: data,
    isContentItemLoading: isLoading && enabled,
    isContentItemError: isError,
    contentItemError: error,
  };
};

export default useFetchKnowledgeContentItem;
