import { useCallback } from "react";
import envConfig from "env-config";
import { useMutation } from "@tanstack/react-query";

const useAssistanceVerificationCode = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notReceivedVertificationCode = useCallback(async ({ queryParams }: any) => {
    let zappierURL =
      envConfig.REACT_APP_ZAPIER_HOOK_URL + envConfig.REACT_APP_ZAPIER_CATCH_VERIFICATION_CODE;
    if (queryParams) {
      (Object.entries(queryParams) as [string, string][]).forEach(
        ([queryName, queryValue], index) => {
          if (index === 0) {
            zappierURL = `${zappierURL}/?${queryName}=${encodeURIComponent(queryValue)}`;
          } else {
            zappierURL = `${zappierURL}&${queryName}=${encodeURIComponent(queryValue)}`;
          }
        },
      );
    }
    await fetch(zappierURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate, isLoading, error } = useMutation(notReceivedVertificationCode);

  return {
    requestAssistanceVerificationCode: mutate,
    isRequestingAssistance: isLoading,
    error,
  };
};

export default useAssistanceVerificationCode;
