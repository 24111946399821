import { Button, InnerModalWrapper, Loader, ModalBody, Text, Title } from "UIKit";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useExportToImage from "Hooks/useExportToImage";

type Props = {
  onCopyLink?: () => void;
  onExportToImage?: () => void;
  exportedQRCodeName: string;
  link: string;
  titleText?: string;
  additionalText?: string;
  isLoading?: boolean;
};

export const GenericShareLinkModal = ({
  onCopyLink,
  onExportToImage,
  titleText,
  additionalText,
  isLoading = false,
  link,
  exportedQRCodeName,
}: Props) => {
  const { t } = useTranslation();
  const [didClickCopy, setDidClickCopy] = useState(false);

  const { parentRef, exportToImage } = useExportToImage();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setDidClickCopy(true);
    if (onCopyLink) {
      onCopyLink();
    }
  };

  const handleExportToImage = () => {
    exportToImage(exportedQRCodeName);
    if (onExportToImage) {
      onExportToImage();
    }
  };

  const copyLinkButtonText = didClickCopy ? t("copy_to_clipboard_tooltip") : t("copy_to_clipboard");

  return (
    <InnerModalWrapper>
      <ModalBody>
        <Title level={5} className="text-center overflow-ellipsis line-clamp-2 width-full">
          {titleText || t("share_item_link_modal_title")}
        </Title>
        <Text level={1} className="text-center">
          {t("share_item_link_modal_description")}
        </Text>
        {additionalText ? (
          <Text level={1} className="text-center">
            {additionalText}
          </Text>
        ) : null}

        <div className="my-8 self-center">
          <div ref={parentRef}>
            {isLoading ? (
              <Loader className="bg-transparent h-20vh" />
            ) : (
              <QRCode size={220} value={link} />
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
          <Button
            data-testid="copy-clipboard-button"
            size="sm"
            iconLeft={didClickCopy ? undefined : "CopyIcon"}
            color="grey"
            variant="outline"
            className="order-1 sm:order-0"
            onClick={handleCopyLink}
          >
            <Text className="ml-2 text-sm sm:text-base" level={1}>
              {copyLinkButtonText}
            </Text>
          </Button>

          <Button
            data-testid="export-png-button"
            size="sm"
            iconLeft="ExportIcon"
            color="grey"
            variant="outline"
            className="order-0 sm:order-1"
            onClick={handleExportToImage}
          >
            <Text className="ml-2 text-sm sm:text-base" level={1}>
              {t("export_as_png")}
            </Text>
          </Button>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
