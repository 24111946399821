import { useEnterKeySubmit } from "Hooks/useEnterKeySubmit/useEnterKeySubmit";
import { Button, FormElement, OneTimePasswordInput } from "UIKit";
import React, { useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface VerificationCodeModalProps {
  control: Control<{ code: string }>;
  onEnter: () => void;
  onResendCode: () => void;
  error?: string;
}

const VerificationCodeModal: React.FC<VerificationCodeModalProps> = ({
  control,
  onEnter,
  onResendCode,
  error,
}: VerificationCodeModalProps) => {
  const { t } = useTranslation();

  const [resendClicked, setResendClicked] = useState(false);

  const handleResendCode = () => {
    onResendCode();
    setResendClicked(true);
  };

  useEnterKeySubmit(onEnter);

  return (
    <div data-testid="verification-code-modal">
      <form onSubmit={onEnter}>
        <FormElement id="code" control={control} errorMessage={error} errorClassName="self-center">
          {(fieldProps) => {
            const { value } = fieldProps;
            return (
              <div className="flex justify-center items-center space-x-2">
                <OneTimePasswordInput
                  otp={value}
                  onOTPChange={(newOTP: string) => fieldProps.onChange(newOTP)}
                  data-testid="verification-code-input"
                />
              </div>
            );
          }}
        </FormElement>
        <div className="flex flex-col justify-center p-4">
          <Button
            color="blue"
            type="button"
            variant="link"
            className="self-center text-center -ml-4"
            onClick={handleResendCode}
          >
            {resendClicked
              ? t("user_not_received_verification_code_resent")
              : t("user_not_received_verification_code")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default VerificationCodeModal;
