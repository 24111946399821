import { SearchableListProps } from "../EolasVersatileList.types";
import { SearchBoxMobx } from "UIKit/SearchBoxMobx/SearchBoxMobx";
import { observer } from "mobx-react-lite";

export const ListSearchComponent = observer((props: SearchableListProps) => {
  const {
    searchMode = "debounce",
    onClickSearch,
    onClearSearch,
    isSearchLoading,
    searchPlaceholder,
    searchInstanceId,
  } = props;

  if (searchMode === "debounce") {
    return (
      <SearchBoxMobx
        searchInstanceId={searchInstanceId}
        searchMode="debounced"
        placeholder={searchPlaceholder}
        isLoading={isSearchLoading}
      />
    );
  }

  if (!onClickSearch) {
    throw new Error("onClickSearch is required when searchMode is 'click'");
  }

  return (
    <SearchBoxMobx
      searchInstanceId={searchInstanceId}
      searchMode={"onClick"}
      onClickSearch={onClickSearch}
      onClearSearch={onClearSearch}
      isLoading={props.isSearchLoading}
      placeholder={props.searchPlaceholder}
    />
  );
});
