import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ManageSlotWizardState } from "Pages/Knowledge/pages/SponsoredSlots/pages/ManageSlotsPage/types/general";
import axios, { AxiosProgressEvent } from "axios";
import { Dispatch, SetStateAction, useState } from "react";
import { isSponsoredContentSection, knowledgeContentClient } from "@eolas-medical/core";
import { sponsoredSlotQueryKeys } from "./sponsoredSlots.queryKeys";
import { makeManageSponsoredSlotDto } from "../functions/makeManageSponsoredSlotDto";

const UPDATE_SECTION_PERCENTAGE = 25;
const HALF_REMAINDER = (100 - UPDATE_SECTION_PERCENTAGE) / 2;
const FULL_REMAINDER = 100 - UPDATE_SECTION_PERCENTAGE;

export const useEditSponsoredSlot = () => {
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [uploadProgress2, setUploadProgress2] = useState(0);
  const [hasUploadedContent, setHasUploadedContent] = useState(false);
  const queryClient = useQueryClient();

  let editSponsoredSlotProgress = hasUploadedContent ? UPDATE_SECTION_PERCENTAGE : 0;

  let remainder = 0;

  if (uploadProgress1 || uploadProgress2) {
    if (uploadProgress1 && uploadProgress2) {
      remainder = HALF_REMAINDER;
    } else {
      remainder = FULL_REMAINDER;
    }
  }

  if (uploadProgress1) {
    editSponsoredSlotProgress = editSponsoredSlotProgress + Math.floor(uploadProgress1 * remainder);
  }

  if (uploadProgress2) {
    editSponsoredSlotProgress = editSponsoredSlotProgress + Math.floor(uploadProgress2 * remainder);
  }

  const makeSetProgress = (progressType: "1" | "2") => {
    let setUploadProgress: Dispatch<SetStateAction<number>>;
    if (progressType === "1") {
      setUploadProgress = setUploadProgress1;
    } else {
      setUploadProgress = setUploadProgress2;
    }
    return (progressEvent: AxiosProgressEvent) => {
      setUploadProgress((old) => {
        if (!progressEvent.total) {
          return old;
        }
        const newCompleted = progressEvent.loaded / progressEvent.total;
        if (newCompleted !== old) {
          return newCompleted;
        }
        return old;
      });
    };
  };

  const { mutate, isLoading, isSuccess, error } = useMutation({
    mutationFn: async ({ state }: { state: ManageSlotWizardState }) => {
      if (!state.existingSection) {
        throw new Error("useEditSponsoredSlot: existing section expected");
      }
      const dto = makeManageSponsoredSlotDto({ state });
      const section = await knowledgeContentClient.updateSection({
        sectionId: state.existingSection.id,
        updateSectionDto: dto,
      });
      if (!state.newImage && !state.newSquareImage) {
        return;
      }
      setHasUploadedContent(true);
      if (!isSponsoredContentSection(section)) {
        throw new Error("useEditSponsoredSlot: incorrect sponsored slot section data");
      }
      const promiseArray: Promise<unknown>[] = [];
      if (state.newImage) {
        if (!section.sponsoredInfo.bannerUploadUrl) {
          throw new Error("useEditSponsoredSlot: no banner upload url");
        }
        const onUploadProgress = makeSetProgress("1");
        promiseArray.push(
          axios.put(section.sponsoredInfo.bannerUploadUrl, state.newImage, {
            onUploadProgress,
            headers: {
              "content-type": state.newImage.type,
            },
          }),
        );
      }
      if (state.newSquareImage) {
        if (!section.sponsoredInfo.squareBannerUploadUrl) {
          throw new Error("useEditSponsoredSlot: no square banner upload url");
        }
        const onUploadProgress = makeSetProgress("2");
        promiseArray.push(
          axios.put(section.sponsoredInfo.squareBannerUploadUrl, state.newSquareImage, {
            onUploadProgress,
            headers: {
              "content-type": state.newSquareImage.type,
            },
          }),
        );
      }
      if (promiseArray.length) {
        await Promise.all(promiseArray);
      }
    },
    onSuccess: async (_, { state }) => {
      if (!state.existingSection) {
        return;
      }
      queryClient.invalidateQueries(
        sponsoredSlotQueryKeys.getAllSponsoredSlots(state.existingSection.mainSectionId),
      );
    },
  });

  return {
    editSponsoredSlot: mutate,
    isEditingSponsoredSlot: isLoading,
    isEditingSponsoredSlotSuccess: isSuccess,
    editSponsoredSlotError: error,
    editSponsoredSlotProgress,
  };
};
