import {
  SpaceAccessType,
  SpaceUpdateDto,
  sectionStore,
  specialtyOptions,
  ChildReference,
} from "@eolas-medical/core";
import {
  isChildReferenceFile,
  isChildReferenceSection,
} from "shared/pages/ContentRepository/ContentItems/functions/typeguards";

export const changeAccessType = (isPublic: boolean): SpaceUpdateDto => {
  const newAccessType: SpaceAccessType = isPublic ? "public" : "private";

  return {
    accessType: newAccessType,
  };
};

export const mapSpecialtyNames = (specialties: string[]): string[] => {
  return specialties?.reduce<string[]>((prev, curr) => {
    const specialtyLabel = specialtyOptions.find((s) => s.value === curr)?.label;

    if (!specialtyLabel) {
      return [...prev];
    }

    return [...prev, specialtyLabel];
  }, []);
};

type ItemReference<T> = {
  [key: string]: T | ItemReference<T>[];
};

export const getDeepSectionStructure = <T>(
  sectionId: string,
  itemFormatter: (item: ChildReference) => T,
  keyFormatter: (key: string) => string,
  depth: number = 0,
  maxDepth: number = 25,
): ItemReference<T> | null => {
  if (depth > maxDepth) {
    throw new Error("Maximum recursion depth exceeded");
  }

  const childRef = sectionStore.getChildReferenceOfSection(sectionId);
  const children = sectionStore.getChildrenOrder(sectionId);

  if (!childRef || !children) {
    return null;
  }

  const itemKey = keyFormatter("item");
  const childrenKey = keyFormatter("children");

  const item: ItemReference<T> = {
    [itemKey]: itemFormatter(childRef),
    [childrenKey]: [],
  };

  children.forEach((child) => {
    const childrenKeyRef = item[childrenKey];
    if (isChildReferenceSection(child)) {
      const children = getDeepSectionStructure(
        child.id,
        itemFormatter,
        keyFormatter,
        depth + 1,
        maxDepth,
      );

      if (children && Array.isArray(item[childrenKey])) {
        if (Array.isArray(childrenKeyRef)) {
          childrenKeyRef.push(children);
        }
      }
    } else if (isChildReferenceFile(child)) {
      if (Array.isArray(childrenKeyRef)) {
        childrenKeyRef.push({
          [itemKey]: itemFormatter(child),
          [childrenKey]: [],
        });
      }
    }
  });

  return item;
};
