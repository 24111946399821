/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActionButton = {
  __typename?: "ActionButton";
  backgroundColor?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  iconColor?: Maybe<Scalars["String"]>;
  subType: Scalars["String"];
  text: Scalars["String"];
  textColor?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type ApproveSupervisionRequestInput = {
  approvingUserEmail: Scalars["String"];
  approvingUserId: Scalars["String"];
};

export type AssessorSummary = {
  __typename?: "AssessorSummary";
  email?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  reviewStatus?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type AssignedAssessor = {
  __typename?: "AssignedAssessor";
  canRemind?: Maybe<Scalars["Boolean"]>;
  email: Scalars["String"];
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  reminderSentAt?: Maybe<Scalars["String"]>;
  reviewStatus?: Maybe<Scalars["String"]>;
};

export type AttachmentItem = {
  __typename?: "AttachmentItem";
  blob?: Maybe<Blob>;
  id: Scalars["ID"];
  link?: Maybe<Link>;
  name: Scalars["String"];
  type: Scalars["String"];
};

export type Blob = {
  __typename?: "Blob";
  mediaId: Scalars["String"];
  size: Scalars["Int"];
  type: Scalars["String"];
};

export type ComponentConfig = {
  __typename?: "ComponentConfig";
  inputSize?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<Maybe<ComponentOption>>>;
  title: Scalars["String"];
  type: Scalars["String"];
  warningText?: Maybe<Scalars["String"]>;
};

export type ComponentOption = {
  __typename?: "ComponentOption";
  type: Scalars["String"];
  value: Scalars["String"];
};

export type CreateSupervisionRequestInput = {
  appType?: InputMaybe<Scalars["String"]>;
  createdBy: Scalars["String"];
  ownerId: Scalars["String"];
  supervisorEmail: Scalars["String"];
};

export type DeclineSupervisionRequestInput = {
  decliningUserEmail: Scalars["String"];
  decliningUserId: Scalars["String"];
};

export type DeleteResponse = {
  __typename?: "DeleteResponse";
  deleted: Scalars["Boolean"];
};

export type EventDescription = {
  __typename?: "EventDescription";
  componentConfig?: Maybe<ComponentConfig>;
  fieldName: Scalars["String"];
  type: Scalars["String"];
  value: Scalars["String"];
};

export type EventFeedback = {
  __typename?: "EventFeedback";
  assessor: AssessorSummary;
  feedbackId: Scalars["String"];
  formElements: Array<FormElement>;
};

export type EventForm = {
  __typename?: "EventForm";
  actionButtons: Array<Maybe<ActionButton>>;
  areAttachmentsShared: Scalars["Boolean"];
  assignAssessorsType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  createdBy?: Maybe<Scalars["String"]>;
  formElements: Array<FormElement>;
  id: Scalars["String"];
  info?: Maybe<Scalars["String"]>;
  isWizardCompatible?: Maybe<Scalars["Boolean"]>;
  needFeedback?: Maybe<Scalars["Boolean"]>;
  status: Scalars["String"];
  templateTitle: Scalars["String"];
  type: Scalars["String"];
  updatedAt: Scalars["String"];
  userTitle?: Maybe<Scalars["String"]>;
};

export type EventFormCompleteNewInput = {
  areAttachmentsShared?: InputMaybe<Scalars["Boolean"]>;
  date: Scalars["String"];
  eventValues: Array<EventFormValueInput>;
  ownerId: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type EventFormCreateInput = {
  areAttachmentsShared?: InputMaybe<Scalars["Boolean"]>;
  date: Scalars["String"];
  eventValues?: InputMaybe<Array<InputMaybe<EventFormValueInput>>>;
  ownerId: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type EventFormSubmitNewInput = {
  areAttachmentsShared?: InputMaybe<Scalars["Boolean"]>;
  date: Scalars["String"];
  eventRequest?: InputMaybe<EventRequestInput>;
  eventValues: Array<EventFormValueInput>;
  ownerId: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type EventFormUpdateInput = {
  areAttachmentsShared?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<Scalars["String"]>;
  eventRequest?: InputMaybe<EventRequestInput>;
  eventValues?: InputMaybe<Array<EventFormValueInput>>;
  title?: InputMaybe<Scalars["String"]>;
};

export type EventFormValue = {
  __typename?: "EventFormValue";
  attachments?: Maybe<Array<Maybe<AttachmentItem>>>;
  booleanValue?: Maybe<Scalars["Boolean"]>;
  componentId: Scalars["String"];
  dateValue?: Maybe<Scalars["String"]>;
  intValue?: Maybe<Scalars["Int"]>;
  stringValue?: Maybe<Scalars["String"]>;
};

export type EventFormValueInput = {
  attachmentsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  booleanValue?: InputMaybe<Scalars["Boolean"]>;
  componentId: Scalars["String"];
  dateValue?: InputMaybe<Scalars["String"]>;
  intValue?: InputMaybe<Scalars["Int"]>;
  stringValue?: InputMaybe<Scalars["String"]>;
};

export type EventOwner = {
  __typename?: "EventOwner";
  email: Scalars["String"];
  name: Scalars["String"];
};

export type EventRequestInput = {
  appType: Scalars["String"];
  userEmail: Scalars["String"];
};

export type EventSummary = {
  __typename?: "EventSummary";
  date?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type FeedbackAggregatedValue = {
  __typename?: "FeedbackAggregatedValue";
  componentConfig?: Maybe<ComponentConfig>;
  fieldName: Scalars["String"];
  type: Scalars["String"];
  value: Array<Maybe<Scalars["String"]>>;
};

export type FeedbackForm = {
  __typename?: "FeedbackForm";
  actionButtons: Array<Maybe<ActionButton>>;
  areAttachmentsShared: Scalars["Boolean"];
  assignAssessorsType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  eventDescription: Array<Maybe<EventDescription>>;
  eventId: Scalars["String"];
  eventOwner: EventOwner;
  formElements: Array<FormElement>;
  id: Scalars["String"];
  status: Scalars["String"];
  type: Scalars["String"];
};

export type FeedbackFormUpdateInput = {
  feedbackValues?: InputMaybe<Array<FeedbackFormValueInput>>;
};

export type FeedbackFormValueInput = {
  booleanValue?: InputMaybe<Scalars["Boolean"]>;
  componentId: Scalars["String"];
  dateValue?: InputMaybe<Scalars["String"]>;
  intValue?: InputMaybe<Scalars["Int"]>;
  stringValue?: InputMaybe<Scalars["String"]>;
};

export type FeedbackSummary = {
  __typename?: "FeedbackSummary";
  date?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type FormElement = {
  __typename?: "FormElement";
  componentConfig: ComponentConfig;
  fieldName: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  owner: Scalars["String"];
  required: Scalars["Boolean"];
  type: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type FormTemplate = {
  __typename?: "FormTemplate";
  actionButtons: Array<Maybe<ActionButton>>;
  assignAssessorsType?: Maybe<Scalars["String"]>;
  childForms?: Maybe<Array<Scalars["String"]>>;
  formElements: Array<FormElement>;
  id: Scalars["String"];
  info?: Maybe<Scalars["String"]>;
  isWizardCompatible?: Maybe<Scalars["Boolean"]>;
  parentFormId?: Maybe<Scalars["String"]>;
  templateTitle: Scalars["String"];
  type: Scalars["String"];
  userTitle?: Maybe<Scalars["String"]>;
};

export type Link = {
  __typename?: "Link";
  url: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  approveSupervisionRequest: SupervisionSummary;
  cancelSupervisionRequest: DeleteResponse;
  completeEventForm: EventForm;
  completeNewEventForm: EventForm;
  createEventForm: EventForm;
  createSupervisionRequest: SupervisionSummary;
  declineSupervisionRequest: SupervisionSummary;
  getOrCreateFeedbackForm: FeedbackForm;
  reassignAssessor: AssignedAssessor;
  removeSupervision: DeleteResponse;
  sendReminderAssessor: AssignedAssessor;
  submitEventForReview: EventForm;
  submitFeedback: FeedbackForm;
  submitNewEventForReview: EventForm;
  updateEventForm: EventForm;
};

export type MutationApproveSupervisionRequestArgs = {
  input: ApproveSupervisionRequestInput;
  supervisionRequestId: Scalars["String"];
};

export type MutationCancelSupervisionRequestArgs = {
  supervisionRequestId: Scalars["String"];
};

export type MutationCompleteEventFormArgs = {
  eventId: Scalars["String"];
  input?: InputMaybe<EventFormUpdateInput>;
};

export type MutationCompleteNewEventFormArgs = {
  input: EventFormCompleteNewInput;
};

export type MutationCreateEventFormArgs = {
  eventFormBody: EventFormCreateInput;
};

export type MutationCreateSupervisionRequestArgs = {
  input: CreateSupervisionRequestInput;
};

export type MutationDeclineSupervisionRequestArgs = {
  input: DeclineSupervisionRequestInput;
  supervisionRequestId: Scalars["String"];
};

export type MutationGetOrCreateFeedbackFormArgs = {
  eventId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationReassignAssessorArgs = {
  eventId: Scalars["String"];
  input: EventRequestInput;
};

export type MutationRemoveSupervisionArgs = {
  supervisionId: Scalars["String"];
};

export type MutationSendReminderAssessorArgs = {
  eventId: Scalars["String"];
  input: EventRequestInput;
};

export type MutationSubmitEventForReviewArgs = {
  eventId: Scalars["String"];
  input?: InputMaybe<EventFormUpdateInput>;
};

export type MutationSubmitFeedbackArgs = {
  eventId: Scalars["String"];
  feedbackId: Scalars["String"];
  input: FeedbackFormUpdateInput;
};

export type MutationSubmitNewEventForReviewArgs = {
  input: EventFormSubmitNewInput;
};

export type MutationUpdateEventFormArgs = {
  eventId: Scalars["String"];
  input: EventFormUpdateInput;
};

export type Query = {
  __typename?: "Query";
  eventFeedback: Array<Maybe<EventFeedback>>;
  eventFormTemplate: FormTemplate;
  eventFormTemplatesSummary: Array<Maybe<Template>>;
  eventListSummary: Array<Maybe<EventSummary>>;
  feedbackAggregatedSummary: Array<Maybe<FeedbackAggregatedValue>>;
  feedbackListSummary: Array<Maybe<FeedbackSummary>>;
  getAssessorsSummary: Array<Maybe<AssessorSummary>>;
  getAssignedAssessors: Array<Maybe<AssignedAssessor>>;
  getEventForm: EventForm;
  getRecentlyAssignedAssessors: Array<Maybe<AssignedAssessor>>;
  getSuperviseeList: SuperviseeList;
  getSupervisorsList: SupervisorsList;
};

export type QueryEventFeedbackArgs = {
  eventId: Scalars["String"];
};

export type QueryEventFormTemplateArgs = {
  eventType: Scalars["String"];
};

export type QueryEventListSummaryArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryFeedbackAggregatedSummaryArgs = {
  eventId: Scalars["String"];
};

export type QueryFeedbackListSummaryArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAssessorsSummaryArgs = {
  eventId: Scalars["String"];
};

export type QueryGetAssignedAssessorsArgs = {
  eventId: Scalars["String"];
};

export type QueryGetEventFormArgs = {
  eventId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetRecentlyAssignedAssessorsArgs = {
  ownerId: Scalars["String"];
};

export type QueryGetSuperviseeListArgs = {
  ownerId: Scalars["String"];
};

export type QueryGetSupervisorsListArgs = {
  ownerId: Scalars["String"];
};

export type SuperviseeList = {
  __typename?: "SuperviseeList";
  superviseeSummary: Array<Maybe<SupervisionSummary>>;
  supervisionRequestsSummary: Array<Maybe<SupervisionSummary>>;
};

export type SupervisionSummary = {
  __typename?: "SupervisionSummary";
  canRemind?: Maybe<Scalars["Boolean"]>;
  email: Scalars["String"];
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  userId?: Maybe<Scalars["String"]>;
};

export type SupervisorsList = {
  __typename?: "SupervisorsList";
  supervisionRequestsSummary: Array<Maybe<SupervisionSummary>>;
  supervisorsSummary: Array<Maybe<SupervisionSummary>>;
};

export type Template = {
  __typename?: "Template";
  description?: Maybe<Scalars["String"]>;
  eventType: Scalars["String"];
  id: Scalars["ID"];
};

export type EventFormTemplateQueryVariables = Exact<{
  eventType: Scalars["String"];
}>;

export type EventFormTemplateQuery = {
  __typename?: "Query";
  eventFormTemplate: {
    __typename?: "FormTemplate";
    id: string;
    type: string;
    userTitle?: string | null;
    templateTitle: string;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type EventFormTemplatesSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type EventFormTemplatesSummaryQuery = {
  __typename?: "Query";
  eventFormTemplatesSummary: Array<{
    __typename?: "Template";
    id: string;
    eventType: string;
    description?: string | null;
  } | null>;
};

export type EventFormByIdQueryVariables = Exact<{
  eventId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type EventFormByIdQuery = {
  __typename?: "Query";
  getEventForm: {
    __typename?: "EventForm";
    id: string;
    createdBy?: string | null;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    status: string;
    type: string;
    userTitle?: string | null;
    templateTitle: string;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type EventListSummaryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type EventListSummaryQuery = {
  __typename?: "Query";
  eventListSummary: Array<{
    __typename?: "EventSummary";
    id?: string | null;
    type?: string | null;
    title?: string | null;
    status?: string | null;
    date?: string | null;
  } | null>;
};

export type FeedbackListSummaryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type FeedbackListSummaryQuery = {
  __typename?: "Query";
  feedbackListSummary: Array<{
    __typename?: "FeedbackSummary";
    id?: string | null;
    eventId?: string | null;
    type?: string | null;
    title?: string | null;
    status?: string | null;
    date?: string | null;
  } | null>;
};

export type AssessorsSummaryQueryVariables = Exact<{
  eventId: Scalars["String"];
}>;

export type AssessorsSummaryQuery = {
  __typename?: "Query";
  getAssessorsSummary: Array<{
    __typename?: "AssessorSummary";
    userId?: string | null;
    givenName?: string | null;
    familyName?: string | null;
    email?: string | null;
    reviewStatus?: string | null;
  } | null>;
};

export type FeedbackAggregatedSummaryQueryVariables = Exact<{
  eventId: Scalars["String"];
}>;

export type FeedbackAggregatedSummaryQuery = {
  __typename?: "Query";
  feedbackAggregatedSummary: Array<{
    __typename?: "FeedbackAggregatedValue";
    type: string;
    fieldName: string;
    value: Array<string | null>;
    componentConfig?: {
      __typename?: "ComponentConfig";
      title: string;
      type: string;
      inputSize?: string | null;
      warningText?: string | null;
      options?: Array<{
        __typename?: "ComponentOption";
        value: string;
        type: string;
      } | null> | null;
    } | null;
  } | null>;
};

export type EventFeedbackQueryVariables = Exact<{
  eventId: Scalars["String"];
}>;

export type EventFeedbackQuery = {
  __typename?: "Query";
  eventFeedback: Array<{
    __typename?: "EventFeedback";
    feedbackId: string;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    assessor: {
      __typename?: "AssessorSummary";
      userId?: string | null;
      givenName?: string | null;
      familyName?: string | null;
      email?: string | null;
    };
  } | null>;
};

export type GetAssignedAssessorsQueryVariables = Exact<{
  eventId: Scalars["String"];
}>;

export type GetAssignedAssessorsQuery = {
  __typename?: "Query";
  getAssignedAssessors: Array<{
    __typename?: "AssignedAssessor";
    email: string;
    reviewStatus?: string | null;
    reminderSentAt?: string | null;
    canRemind?: boolean | null;
  } | null>;
};

export type GetRecentlyAssignedAssessorsQueryVariables = Exact<{
  ownerId: Scalars["String"];
}>;

export type GetRecentlyAssignedAssessorsQuery = {
  __typename?: "Query";
  getRecentlyAssignedAssessors: Array<{
    __typename?: "AssignedAssessor";
    email: string;
    reviewStatus?: string | null;
  } | null>;
};

export type GetSupervisorsListQueryVariables = Exact<{
  ownerId: Scalars["String"];
}>;

export type GetSupervisorsListQuery = {
  __typename?: "Query";
  getSupervisorsList: {
    __typename?: "SupervisorsList";
    supervisionRequestsSummary: Array<{
      __typename?: "SupervisionSummary";
      id: string;
      email: string;
      status: string;
      name?: string | null;
      canRemind?: boolean | null;
    } | null>;
    supervisorsSummary: Array<{
      __typename?: "SupervisionSummary";
      id: string;
      email: string;
      status: string;
      name?: string | null;
      userId?: string | null;
    } | null>;
  };
};

export type GetSuperviseeListQueryVariables = Exact<{
  ownerId: Scalars["String"];
}>;

export type GetSuperviseeListQuery = {
  __typename?: "Query";
  getSuperviseeList: {
    __typename?: "SuperviseeList";
    supervisionRequestsSummary: Array<{
      __typename?: "SupervisionSummary";
      id: string;
      email: string;
      status: string;
      name?: string | null;
      canRemind?: boolean | null;
    } | null>;
    superviseeSummary: Array<{
      __typename?: "SupervisionSummary";
      id: string;
      email: string;
      status: string;
      name?: string | null;
      userId?: string | null;
    } | null>;
  };
};

export type CreateEventFormMutationVariables = Exact<{
  eventFormBody: EventFormCreateInput;
}>;

export type CreateEventFormMutation = {
  __typename?: "Mutation";
  createEventForm: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type UpdateEventFormMutationVariables = Exact<{
  eventId: Scalars["String"];
  input: EventFormUpdateInput;
}>;

export type UpdateEventFormMutation = {
  __typename?: "Mutation";
  updateEventForm: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type SubmitEventForReviewMutationVariables = Exact<{
  eventId: Scalars["String"];
  input: EventFormUpdateInput;
}>;

export type SubmitEventForReviewMutation = {
  __typename?: "Mutation";
  submitEventForReview: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type SubmitNewEventForReviewMutationVariables = Exact<{
  input: EventFormSubmitNewInput;
}>;

export type SubmitNewEventForReviewMutation = {
  __typename?: "Mutation";
  submitNewEventForReview: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type CompleteEventFormMutationVariables = Exact<{
  eventId: Scalars["String"];
  input?: InputMaybe<EventFormUpdateInput>;
}>;

export type CompleteEventFormMutation = {
  __typename?: "Mutation";
  completeEventForm: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type CompleteNewEventFormMutationVariables = Exact<{
  input: EventFormCompleteNewInput;
}>;

export type CompleteNewEventFormMutation = {
  __typename?: "Mutation";
  completeNewEventForm: {
    __typename?: "EventForm";
    id: string;
    type: string;
    status: string;
    userTitle?: string | null;
    templateTitle: string;
    needFeedback?: boolean | null;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    isWizardCompatible?: boolean | null;
    info?: string | null;
    createdBy?: string | null;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type GetOrCreateFeedbackFormMutationVariables = Exact<{
  eventId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type GetOrCreateFeedbackFormMutation = {
  __typename?: "Mutation";
  getOrCreateFeedbackForm: {
    __typename?: "FeedbackForm";
    id: string;
    status: string;
    type: string;
    eventId: string;
    createdAt: string;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    eventOwner: { __typename?: "EventOwner"; name: string; email: string };
    eventDescription: Array<{
      __typename?: "EventDescription";
      fieldName: string;
      value: string;
      type: string;
      componentConfig?: {
        __typename?: "ComponentConfig";
        type: string;
        inputSize?: string | null;
        title: string;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      } | null;
    } | null>;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type SubmitFeedbackMutationVariables = Exact<{
  feedbackId: Scalars["String"];
  eventId: Scalars["String"];
  input: FeedbackFormUpdateInput;
}>;

export type SubmitFeedbackMutation = {
  __typename?: "Mutation";
  submitFeedback: {
    __typename?: "FeedbackForm";
    id: string;
    status: string;
    type: string;
    eventId: string;
    createdAt: string;
    areAttachmentsShared: boolean;
    assignAssessorsType?: string | null;
    eventOwner: { __typename?: "EventOwner"; name: string; email: string };
    eventDescription: Array<{
      __typename?: "EventDescription";
      fieldName: string;
      value: string;
      type: string;
      componentConfig?: {
        __typename?: "ComponentConfig";
        type: string;
        inputSize?: string | null;
        title: string;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      } | null;
    } | null>;
    formElements: Array<{
      __typename?: "FormElement";
      id?: string | null;
      type: string;
      fieldName: string;
      owner: string;
      required: boolean;
      value?: string | null;
      componentConfig: {
        __typename?: "ComponentConfig";
        title: string;
        type: string;
        inputSize?: string | null;
        warningText?: string | null;
        options?: Array<{
          __typename?: "ComponentOption";
          value: string;
          type: string;
        } | null> | null;
      };
    }>;
    actionButtons: Array<{
      __typename?: "ActionButton";
      type: string;
      text: string;
      textColor?: string | null;
      icon?: string | null;
      iconColor?: string | null;
      backgroundColor?: string | null;
    } | null>;
  };
};

export type SendReminderAssessorMutationVariables = Exact<{
  eventId: Scalars["String"];
  input: EventRequestInput;
}>;

export type SendReminderAssessorMutation = {
  __typename?: "Mutation";
  sendReminderAssessor: {
    __typename?: "AssignedAssessor";
    email: string;
    reviewStatus?: string | null;
    reminderSentAt?: string | null;
    canRemind?: boolean | null;
  };
};

export type ReassignAssessorMutationVariables = Exact<{
  eventId: Scalars["String"];
  input: EventRequestInput;
}>;

export type ReassignAssessorMutation = {
  __typename?: "Mutation";
  reassignAssessor: {
    __typename?: "AssignedAssessor";
    email: string;
    reviewStatus?: string | null;
    reminderSentAt?: string | null;
  };
};

export type CreateSupervisionRequestMutationVariables = Exact<{
  input: CreateSupervisionRequestInput;
}>;

export type CreateSupervisionRequestMutation = {
  __typename?: "Mutation";
  createSupervisionRequest: {
    __typename?: "SupervisionSummary";
    id: string;
    name?: string | null;
    email: string;
    status: string;
    canRemind?: boolean | null;
  };
};

export type CancelSupervisionRequestMutationVariables = Exact<{
  supervisionRequestId: Scalars["String"];
}>;

export type CancelSupervisionRequestMutation = {
  __typename?: "Mutation";
  cancelSupervisionRequest: { __typename?: "DeleteResponse"; deleted: boolean };
};

export type RemoveSupervisionMutationVariables = Exact<{
  supervisionId: Scalars["String"];
}>;

export type RemoveSupervisionMutation = {
  __typename?: "Mutation";
  removeSupervision: { __typename?: "DeleteResponse"; deleted: boolean };
};

export type ApproveSupervisionRequestMutationVariables = Exact<{
  supervisionRequestId: Scalars["String"];
  input: ApproveSupervisionRequestInput;
}>;

export type ApproveSupervisionRequestMutation = {
  __typename?: "Mutation";
  approveSupervisionRequest: {
    __typename?: "SupervisionSummary";
    id: string;
    name?: string | null;
    email: string;
    userId?: string | null;
    status: string;
  };
};

export type DeclineSupervisionRequestMutationVariables = Exact<{
  supervisionRequestId: Scalars["String"];
  input: DeclineSupervisionRequestInput;
}>;

export type DeclineSupervisionRequestMutation = {
  __typename?: "Mutation";
  declineSupervisionRequest: {
    __typename?: "SupervisionSummary";
    id: string;
    name?: string | null;
    email: string;
    status: string;
  };
};

export const EventFormTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EventFormTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventType" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "eventFormTemplate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventType" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFormTemplateQuery, EventFormTemplateQueryVariables>;
export const EventFormTemplatesSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EventFormTemplatesSummary" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "eventFormTemplatesSummary" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "eventType" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EventFormTemplatesSummaryQuery,
  EventFormTemplatesSummaryQueryVariables
>;
export const EventFormByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EventFormById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getEventForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFormByIdQuery, EventFormByIdQueryVariables>;
export const EventListSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EventListSummary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "eventListSummary" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "date" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventListSummaryQuery, EventListSummaryQueryVariables>;
export const FeedbackListSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FeedbackListSummary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "feedbackListSummary" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "eventId" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "date" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedbackListSummaryQuery, FeedbackListSummaryQueryVariables>;
export const AssessorsSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AssessorsSummary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAssessorsSummary" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "reviewStatus" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssessorsSummaryQuery, AssessorsSummaryQueryVariables>;
export const FeedbackAggregatedSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FeedbackAggregatedSummary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "feedbackAggregatedSummary" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "componentConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "value" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "warningText" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedbackAggregatedSummaryQuery,
  FeedbackAggregatedSummaryQueryVariables
>;
export const EventFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EventFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "eventFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "feedbackId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assessor" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "userId" } },
                      { kind: "Field", name: { kind: "Name", value: "givenName" } },
                      { kind: "Field", name: { kind: "Name", value: "familyName" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFeedbackQuery, EventFeedbackQueryVariables>;
export const GetAssignedAssessorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAssignedAssessors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAssignedAssessors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "reviewStatus" } },
                { kind: "Field", name: { kind: "Name", value: "reminderSentAt" } },
                { kind: "Field", name: { kind: "Name", value: "canRemind" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssignedAssessorsQuery, GetAssignedAssessorsQueryVariables>;
export const GetRecentlyAssignedAssessorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRecentlyAssignedAssessors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getRecentlyAssignedAssessors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ownerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "reviewStatus" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRecentlyAssignedAssessorsQuery,
  GetRecentlyAssignedAssessorsQueryVariables
>;
export const GetSupervisorsListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSupervisorsList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSupervisorsList" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ownerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supervisionRequestsSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "canRemind" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supervisorsSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "userId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSupervisorsListQuery, GetSupervisorsListQueryVariables>;
export const GetSuperviseeListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSuperviseeList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSuperviseeList" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ownerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "ownerId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supervisionRequestsSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "canRemind" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "superviseeSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "userId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSuperviseeListQuery, GetSuperviseeListQueryVariables>;
export const CreateEventFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateEventForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventFormBody" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventFormCreateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createEventForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventFormBody" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventFormBody" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEventFormMutation, CreateEventFormMutationVariables>;
export const UpdateEventFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateEventForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventFormUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateEventForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEventFormMutation, UpdateEventFormMutationVariables>;
export const SubmitEventForReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitEventForReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventFormUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitEventForReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitEventForReviewMutation, SubmitEventForReviewMutationVariables>;
export const SubmitNewEventForReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitNewEventForReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventFormSubmitNewInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitNewEventForReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitNewEventForReviewMutation,
  SubmitNewEventForReviewMutationVariables
>;
export const CompleteEventFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteEventForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "EventFormUpdateInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeEventForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteEventFormMutation, CompleteEventFormMutationVariables>;
export const CompleteNewEventFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteNewEventForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventFormCompleteNewInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeNewEventForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "userTitle" } },
                { kind: "Field", name: { kind: "Name", value: "templateTitle" } },
                { kind: "Field", name: { kind: "Name", value: "needFeedback" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                { kind: "Field", name: { kind: "Name", value: "isWizardCompatible" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteNewEventFormMutation, CompleteNewEventFormMutationVariables>;
export const GetOrCreateFeedbackFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GetOrCreateFeedbackForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOrCreateFeedbackForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "eventId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eventOwner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eventDescription" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateFeedbackFormMutation,
  GetOrCreateFeedbackFormMutationVariables
>;
export const SubmitFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "feedbackId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FeedbackFormUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "feedbackId" },
                value: { kind: "Variable", name: { kind: "Name", value: "feedbackId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "eventId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "areAttachmentsShared" } },
                { kind: "Field", name: { kind: "Name", value: "assignAssessorsType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eventOwner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eventDescription" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "formElements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "fieldName" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      { kind: "Field", name: { kind: "Name", value: "required" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "inputSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "warningText" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actionButtons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "textColor" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "iconColor" } },
                      { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;
export const SendReminderAssessorDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendReminderAssessor" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventRequestInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendReminderAssessor" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "reviewStatus" } },
                { kind: "Field", name: { kind: "Name", value: "reminderSentAt" } },
                { kind: "Field", name: { kind: "Name", value: "canRemind" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendReminderAssessorMutation, SendReminderAssessorMutationVariables>;
export const ReassignAssessorDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ReassignAssessor" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EventRequestInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "reassignAssessor" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "eventId" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "reviewStatus" } },
                { kind: "Field", name: { kind: "Name", value: "reminderSentAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReassignAssessorMutation, ReassignAssessorMutationVariables>;
export const CreateSupervisionRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupervisionRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSupervisionRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupervisionRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "canRemind" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSupervisionRequestMutation,
  CreateSupervisionRequestMutationVariables
>;
export const CancelSupervisionRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CancelSupervisionRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelSupervisionRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supervisionRequestId" },
                value: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "deleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelSupervisionRequestMutation,
  CancelSupervisionRequestMutationVariables
>;
export const RemoveSupervisionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveSupervision" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supervisionId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeSupervision" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supervisionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "supervisionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "deleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveSupervisionMutation, RemoveSupervisionMutationVariables>;
export const ApproveSupervisionRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApproveSupervisionRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApproveSupervisionRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "approveSupervisionRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supervisionRequestId" },
                value: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveSupervisionRequestMutation,
  ApproveSupervisionRequestMutationVariables
>;
export const DeclineSupervisionRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineSupervisionRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeclineSupervisionRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "declineSupervisionRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supervisionRequestId" },
                value: { kind: "Variable", name: { kind: "Name", value: "supervisionRequestId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclineSupervisionRequestMutation,
  DeclineSupervisionRequestMutationVariables
>;
