import { useHistory } from "react-router-dom";
import { useCallback } from "react";

import { MedicationSummaryItem } from "@eolas-medical/core";
import { SummaryGroup } from "./SummaryGroup";
import { makeBnfRoute } from "../../../../routes/BnfRoutes";
import { GroupedSectionWithId } from "../hooks/useGetAdditionalContentSummary";

export const AdditionalContentGroup = ({ group }: { group: GroupedSectionWithId }) => {
  const { push } = useHistory();

  const makeOnClick = useCallback(
    (item: MedicationSummaryItem) => {
      return () => {
        push(makeBnfRoute({ routeKey: item.type, idOrSid: item.id, title: item.name }));
      };
    },
    [push],
  );

  return (
    <SummaryGroup
      title={group.title}
      makeOnClick={makeOnClick}
      items={group.data}
      itemTitleProperty="name"
    />
  );
};
