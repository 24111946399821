import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useChooseFileTypeStep,
  useConfigureFileStep,
  useWizard,
  useUploadProgressAndSuccessStep,
  AllowedChooseFileTypes,
} from "UIKit/Wizard";
import { AddPatientLeafletWizardStep, PatientLeafletProps } from "./types";
import { useAddPatientLeafletActions } from "./hooks/useAddPatientLeafletActions";
import { DomainIcon, FilesIcon } from "Assets";
import { observer } from "mobx-react-lite";

export interface AddPatientLeafletWizardProps {
  onClose: () => void;
  patientLeafletProps: PatientLeafletProps;
}

const AddPatientLeafletWizard = observer(
  ({ onClose, patientLeafletProps }: AddPatientLeafletWizardProps) => {
    const { t } = useTranslation();
    const { wizardControl, submitWizard } = useWizard<AddPatientLeafletWizardStep>();

    const {
      wizardState,
      addContentItemError,
      addingContentItem,
      addContentItemSuccess,
      addContentItemProgress,
      getNextStep,
      getPrevStep,
      onSubmitFileType,
      onSelectType,
      onAddFileDetails,
    } = useAddPatientLeafletActions({ onClose, patientLeafletProps });

    const allowedTypes: AllowedChooseFileTypes[] = [
      { type: "blob", disabled: false, icon: FilesIcon, label: t("filePicker_blob") },
      {
        type: "link",
        disabled: false,
        icon: DomainIcon,
        label: t("filePicker_link"),
      },
    ];

    const chooseFileType = useChooseFileTypeStep<"upload-item">({
      stepName: "upload-item",
      title: t("repository_add_item_title"),
      allowedTypes,
      onSubmit: onSubmitFileType,
      onSelectType,
      onClose,
      fileFormLabel: t("filePicker_file_to_upload_label"),
      fileUploadLabel: t("filePicker_upload_file_button"),
      fileChangeLabel: t("filePicker_change_file"),
      keywordsLabel: t("filePicker_link_keywords_label"),
      keywordsPlaceholder: t("filePicker_link_keywords_placeholder"),
      fileDescriptionText: t("filePicker_helper_text"),
      linkUrlLabel: t("filePicker_url_label"),
      linkUrlPlaceholder: t("filePicker_url_placeholder"),
      cancelLabel: t("general_cancel"),
      isLoading: addingContentItem,
    });

    const addItemDetails = useConfigureFileStep<"add-item-details">({
      stepName: "add-item-details",
      title: t("repository_add_item_title"),
      onEnter: submitWizard,
      onSubmit: onAddFileDetails,
      isLoading: wizardState.selectedType === "link" ? addingContentItem : false,
      item: {
        ...wizardState.file,
      },
      nameInputLabel: t("repository_file_name_label"),
      nameInputPlaceholder: t("repository_file_name_placeholder"),
      keyWordsLabel: t("repository_file_keywords_label"),
      keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
      nextLabel: t("general_done"),
    });

    const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
      stepName: "upload-progress",
      fileUploading: addingContentItem,
      uploadProgress: addContentItemProgress,
      fileUploadError: addContentItemError,
      fileUploadedSuccess: addContentItemSuccess,
      onFinishUpload: onClose,
    });

    const steps: Step<AddPatientLeafletWizardStep>[] = [
      chooseFileType,
      addItemDetails,
      uploadProgressAndSuccessStep,
    ];

    return (
      <Wizard
        steps={steps}
        wizardControl={wizardControl}
        getNextStep={getNextStep}
        getPrevStep={getPrevStep}
        altSubmitLabel={t("content_item_draft_label")}
      />
    );
  },
);

export default AddPatientLeafletWizard;
