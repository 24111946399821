import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useNameAndUrlStep,
  useConfigureFileStep,
  useWizard,
  useUploadProgressAndSuccessStep,
} from "UIKit/Wizard";
import { useMemo } from "react";
import { useEditFileStep } from "UIKit/Wizard/templates/EditFileStep";
import { useEditContentItemActions } from "./hooks/useEditContentItemActions";
import {
  BlobContent,
  hasProp,
  isLinkContentItem,
  LinkContent,
  parseDsmMetadata,
} from "@eolas-medical/core";
import { useSummaryOfChangesStep } from "UIKit/Wizard/templates/SummaryOfChangesStep/useSummaryOfChangesStep";
import { isItemDsmItem } from "../ContentItemInstance/functions";
import { useDsmErrorStep } from "../AddContentItemWizard/hooks/useDsmErrorStep";

interface EditContentItemModalProps {
  contentItem: LinkContent | BlobContent;
  onClose: () => void;
}

const EditContentItemModal = ({ contentItem, onClose }: EditContentItemModalProps) => {
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<"edit-item">();

  const {
    wizardState,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
    handleOnSubmitFileDetails,
    handleOnSubmitLinkDetails,
    handleUpdateWizardValues,
    handleUpdateWithSummaryOfChanges,
    onBlobSelection,
  } = useEditContentItemActions({ contentItem, onClose });

  const isDsmItem = isItemDsmItem(contentItem);

  const { canAddSummaryOfChanges, step: summaryOfChangesStep } = useSummaryOfChangesStep({
    onSubmit: (params) =>
      handleUpdateWithSummaryOfChanges({ ...params, isLinkItem: isLinkContentItem(contentItem) }),
    title: t("repository_edit_item_summary_of_changes"),
    backLabel: t("general_back"),
    nextLabel: t("general_done"),
    isLoading: editingContentItem,
  });

  const editLinkDetailsStep = useNameAndUrlStep<"edit-item">({
    stepName: "edit-item",
    title: t("repository_edit_item_title"),
    onSubmit: (values) => handleOnSubmitLinkDetails({ values, canAddSummaryOfChanges }),
    isLoading: editingContentItem,
    nameLabel: t("repository_file_name_label"),
    namePlaceholder: t("repository_file_name_placeholder"),
    urlLabel: t("filePicker_url_label"),
    keyWordsLabel: t("filePicker_link_keywords_label"),
    keyWordsPlaceholder: t("filePicker_link_keywords_placeholder"),
    urlPlaceholder: t("filePicker_url_placeholder"),
    initialValues: {
      name: contentItem.name,
      url: contentItem.url || "",
      keywords: contentItem.keywords ? contentItem.keywords : "",
    },
    nextLabel: t(canAddSummaryOfChanges ? "addFile_wizard_next_button" : "general_done"),
    backLabel: t("general_cancel"),
  });

  let editFileName = wizardState?.blob?.name ?? `${contentItem.name}.${contentItem.media?.type}`;

  if (isDsmItem) {
    const metadata = parseDsmMetadata(contentItem.metadata);
    editFileName = `${metadata?.dsmData.name ?? "DSM"}.xlsx`;
  }

  const editFile = useEditFileStep<"edit-item">({
    stepName: "edit-item",
    filePickerValue: editFileName,
    title: isDsmItem ? t("repository_edit_dsm_title") : t("repository_edit_item_title"),
    onSubmit: handleUpdateWizardValues,
    isLoading: editingContentItem,
    fileFormLabel: t("filePicker_file_to_upload_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    fileDescriptionText: t("filePicker_helper_text"),
    onBlobSelection,
    type: isDsmItem ? "dsm" : "blob",
    backDisabled: true,
  });

  const editFileDetails = useConfigureFileStep<"edit-item-details">({
    stepName: "edit-item-details",
    title: isDsmItem ? t("repository_edit_dsm_title") : t("repository_edit_item_title"),
    onEnter: submitWizard,
    onSubmit: (values) => handleOnSubmitFileDetails({ values, canAddSummaryOfChanges }),
    isLoading: editingContentItem,
    item: {
      name: contentItem.name || undefined,
      expiryDate: contentItem.expiryDate || undefined,
      keywords: contentItem.keywords ? contentItem.keywords : undefined,
    },
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t(canAddSummaryOfChanges ? "addFile_wizard_next_button" : "general_done"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
    stepName: "upload-progress",
    fileUploading: editingContentItem,
    uploadProgress: editContentItemProgress,
    fileUploadError: editContentItemError,
    fileUploadedSuccess: editContentItemSuccess,
    onFinishUpload: onClose,
  });

  const dsmError = hasProp(wizardState.dsmData, "dsmError") ? wizardState.dsmData.dsmError : null;

  const dsmErrorStep = useDsmErrorStep({
    error: dsmError,
  });

  const steps: Step<
    "edit-item" | "edit-item-details" | "upload-progress" | "summary-of-changes" | "dsm-error"
  >[] = useMemo(() => {
    if (isLinkContentItem(contentItem)) {
      return [editLinkDetailsStep, ...(canAddSummaryOfChanges ? [summaryOfChangesStep] : [])];
    }

    if (dsmError) {
      return [editFile, dsmErrorStep];
    }

    return [
      editFile,
      editFileDetails,
      ...(canAddSummaryOfChanges ? [summaryOfChangesStep] : []),
      uploadProgressAndSuccessStep,
    ];
  }, [
    editFileDetails,
    editLinkDetailsStep,
    uploadProgressAndSuccessStep,
    contentItem,
    editFile,
    summaryOfChangesStep,
    canAddSummaryOfChanges,
    dsmError,
    dsmErrorStep,
  ]);

  return (
    <div data-testid="edit-content-item-modal">
      <Wizard steps={steps} wizardControl={wizardControl} />
    </div>
  );
};

export default EditContentItemModal;
