import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEnterKeySubmit = <T extends (...args: any[]) => void>(onSubmit: T) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onSubmit]);
};
