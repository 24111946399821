import { ChooseFileTypeStep } from "./ChooseFileTypeStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useChooseFileTypeStepSchema } from "./useChooseFileTypeStepSchema";
import { LinkFormValues, ChooseFileTypeValues, AllowedChooseFileTypes } from "./types";
import { Step } from "../../types";
import { useState } from "react";
import { SupportedFileType, eolasLogger } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";

export interface UseChooseFileTypeStepProps<T> {
  stepName: T;
  title: string;
  allowedTypes: AllowedChooseFileTypes[];
  allowedFileExtensionsOverride?: string[];
  defaultSelectedType?: SupportedFileType;
  fileFormLabel?: string;
  fileUploadLabel?: string;
  fileChangeLabel?: string;
  fileDescriptionText?: string;
  linkNameLabel?: string;
  linkNamePlaceholder?: string;
  linkUrlLabel?: string;
  linkUrlPlaceholder?: string;
  keywordsLabel?: string;
  keywordsPlaceholder?: string;
  flashcardTitle?: string;
  flashcardSubtitle?: string;
  flashcardDescription?: string;
  editorTitle?: string;
  editorSubtitle?: string;
  editorDescription?: string;
  cancelLabel?: string;
  submitButtonLabel?: string;
  blobName?: string;
  link?: LinkFormValues;
  isLoading?: boolean;
  shouldSubmitLinkAsDraft?: boolean;
  onBlobSelection?: (file: File, type: SupportedFileType) => void | Promise<void>;
  onSelectType?: (type: SupportedFileType) => void;
  onSubmit: (type: SupportedFileType, result?: File | LinkFormValues, isDraft?: boolean) => void;
  onClose?: () => void;
}

export const useChooseFileTypeStep = <T extends string>({
  stepName,
  title = "Select Item Type",
  allowedTypes,
  allowedFileExtensionsOverride,
  submitButtonLabel: overrideSubmitButtonLabel,
  defaultSelectedType = "blob",
  fileFormLabel = "File to Upload",
  fileUploadLabel = "Browse Files",
  fileChangeLabel = "Change File",
  fileDescriptionText = "Drag & Drop a file here or",
  linkNameLabel = "Name",
  linkNamePlaceholder = "Enter link name",
  linkUrlLabel = "URL",
  linkUrlPlaceholder = "Enter link URL",
  keywordsLabel = "Search keywords ( optional )",
  keywordsPlaceholder = "Enter optional search keywords",
  flashcardTitle = "",
  flashcardSubtitle = "",
  flashcardDescription = "",
  editorTitle = "",
  editorSubtitle = "",
  editorDescription = "",
  cancelLabel = "Cancel",
  link,
  blobName,
  isLoading,
  shouldSubmitLinkAsDraft = true,
  onSelectType,
  onSubmit,
  onClose,
  onBlobSelection,
}: UseChooseFileTypeStepProps<T>): Step<T> => {
  const [selectedType, setSelectedType] = useState<SupportedFileType>(defaultSelectedType);
  const chooseFileTypeStepSchema = useChooseFileTypeStepSchema(selectedType);
  const { t } = useTranslation();

  /**
   * Note: there is a flaw with this logic in that submit button label can be
   * controlled by the parent component, however this label needs to change dynamically depending on the
   * type chosen which is only visible within the scope of this hook.
   * For now, this will be considered an "override" so as not to break existing functionality
   */
  let submitButtonLabel =
    selectedType === "link" ? t("content_item_publish") : t("addFile_wizard_next_button");

  if (overrideSubmitButtonLabel) {
    submitButtonLabel = overrideSubmitButtonLabel;
  }

  const { control, handleSubmit, formState, setValue, clearErrors, reset, getValues } =
    useForm<ChooseFileTypeValues>({
      defaultValues: {
        link,
      },
      resolver: yupResolver(chooseFileTypeStepSchema),
    });

  const handleSelect = (type: SupportedFileType) => {
    setSelectedType(type);
    if (allowedTypes.length > 1 && !onSelectType) {
      eolasLogger.error(
        new Error(
          "onSelectType callback undefined when trying to change type in useChooseFileTypeStep",
        ),
      );
    }
    onSelectType?.(type);
    reset();
  };

  const handleBlobAddition = (file: File) => {
    setValue("blob", file);
    clearErrors("blob");
    onBlobSelection?.(file, selectedType);
  };

  const handleSubmitFileType = () => {
    const { blob, link } = getValues();
    onSubmit(selectedType, blob || link || undefined, false);
  };

  const handleSubmitDraft = () => {
    if (shouldSubmitLinkAsDraft) {
      const { link } = getValues();
      onSubmit(selectedType, link || undefined, true);
      return;
    }
    const { blob, link } = getValues();
    onSubmit(selectedType, blob || link || undefined, true);
    return;
  };

  return {
    name: stepName,
    title,
    submitError: !formState.isValid,
    canProceed: formState.isSubmitSuccessful,
    onSubmit: handleSubmit(handleSubmitFileType),
    onAltSubmit: handleSubmit(handleSubmitDraft),
    onPrev: onClose,
    backLabel: cancelLabel,
    nextLabel: submitButtonLabel,
    isLoading: isLoading,
    component: (
      <ChooseFileTypeStep
        control={control}
        onSelect={handleSelect}
        allowedTypes={allowedTypes}
        selectedType={selectedType}
        onAddBlob={handleBlobAddition}
        blobName={getValues("blob")?.name || blobName}
        errors={formState.errors}
        fileFormLabel={fileFormLabel}
        fileChangeLabel={fileChangeLabel}
        fileUploadLabel={fileUploadLabel}
        fileDescriptionText={fileDescriptionText}
        linkNameLabel={linkNameLabel}
        linkNamePlaceholder={linkNamePlaceholder}
        linkUrlLabel={linkUrlLabel}
        linkUrlPlaceholder={linkUrlPlaceholder}
        keywordsLabel={keywordsLabel}
        keywordsPlaceholder={keywordsPlaceholder}
        flashcardTitle={flashcardTitle}
        flashcardSubtitle={flashcardSubtitle}
        flashcardDescription={flashcardDescription}
        editorTitle={editorTitle}
        editorSubtitle={editorSubtitle}
        editorDescription={editorDescription}
        allowedFileExtensionsOverride={allowedFileExtensionsOverride}
      />
    ),
  };
};
