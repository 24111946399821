import { useState } from "react";

import { useSelectTypeComponent } from "./hooks/useSelectTypeComponent";
import { makeInitialWizardState } from "./functions/makeInitialWizardState";
import { stepToComponentAndPropsMap } from "./data/stepToComponentAndPropsMap";
import { makeMutableWizardState } from "./functions/makeMutableWizardState";
import { modalStore } from "Stores/ModalStore";
import { DraftFile, eolasLogger } from "@eolas-medical/core";
import { isDev } from "Utilities/helpers";
import { ErrorComponent } from "./components/ErrorComponent";

export type ManageContentItemChildModalProps =
  | {
      sectionId: string;
      fileId: null;
    }
  | {
      sectionId: string;
      fileId: string;
      draftFile: DraftFile | null;
    };

export const ManageContentItemChildModal = (props: ManageContentItemChildModalProps) => {
  const [wizardState, setWizardState] = useState(() => makeInitialWizardState(props));

  const { selectTypeComponent, selectedType } = useSelectTypeComponent({
    wizardState,
    setWizardState,
    ...props,
  });

  const data = wizardState ? stepToComponentAndPropsMap[wizardState.stepName] : null;

  if (!data || !wizardState || !selectTypeComponent) {
    eolasLogger.error(new Error("ManageContentItemWizard: Unable to render component"), {
      fileId: props.fileId,
      wizardState,
      isSelectedTypeComponentPresent: Boolean(selectTypeComponent),
    });
    return <ErrorComponent />;
  }

  if (data.isFirstStep) {
    const {
      StepComponent: FirstStepComponent,
      props: { getNextStepName },
    } = data.typeRecords[selectedType];

    return (
      <FirstStepComponent
        selectTypeComponent={selectTypeComponent}
        onClickForward={(state) => {
          if (state === null) {
            modalStore.closeModal();
            return;
          }
          const { isMutableWizardState: _, ...nextState } = state;
          let type: string | undefined;
          if (nextState.isExistingFileWithNewType) {
            type = nextState.dto.newType;
          } else {
            type = nextState.type;
          }
          if (!type) {
            if (isDev()) {
              throw new Error("Type must be set in first step of ManageContentItemWizardModal");
            } else {
              eolasLogger.error(
                new Error("ManageContentItemWizard: No type set. This should not happen"),
                {
                  wizardState,
                },
              );
            }
          }
          const nextStep = getNextStepName(wizardState.stepName, nextState);
          if (!nextStep) {
            modalStore.closeModal();
            return;
          }
          setWizardState({ ...nextState, stepName: nextStep });
        }}
        wizardState={wizardState}
        sectionId={props.sectionId}
      />
    );
  }

  const {
    StepComponent: NormalStepComponent,
    props: { getNextStepName, getPreviousStepName },
  } = data;

  return (
    <NormalStepComponent
      sectionId={props.sectionId}
      onClickBack={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isMutableWizardState: _, ...nextState } = makeMutableWizardState(wizardState);
        const previousStepName = getPreviousStepName(wizardState.stepName, nextState);
        if (!previousStepName) {
          modalStore.closeModal();
          return;
        }
        setWizardState((old) => {
          if (!old) {
            return null;
          }
          return { ...old, stepName: previousStepName };
        });
      }}
      onClickForward={(nextState) => {
        if (nextState === null) {
          modalStore.closeModal();
          return;
        }
        const nextStep = getNextStepName(wizardState.stepName, nextState);
        if (!nextStep) {
          modalStore.closeModal();
          return;
        }
        setWizardState({ ...nextState, stepName: nextStep });
      }}
      wizardState={wizardState}
    />
  );
};
