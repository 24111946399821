import { useEffect, useState } from "react";

import { searchBoxStore } from "Stores/SearchBoxStore";

/**
 * These hooks should be used in conjunction with SearchBoxMobx. Note: the parent component which consumes the hook
 * must be an observer
 * useInitSearchBox: use when you do not need to directly access an observable isLoading or debounced text (i.e. onClick mode)
 * useObservingSearchBox: for use when observables need to be listened to (i.e Debounced mode)
 */

export const useInitSearchBox = () => {
  const [searchInstanceId] = useState(searchBoxStore.makeSearchBoxInstance);

  useEffect(() => {
    return () => {
      searchBoxStore.removeSearchBoxInstance(searchInstanceId);
    };
  }, [searchInstanceId]);

  return { searchInstanceId };
};

export const useObservingSearchBox = () => {
  const { searchInstanceId } = useInitSearchBox();
  const searchText = searchBoxStore.getText(searchInstanceId);
  const isFocused = searchBoxStore.getIsFocused(searchInstanceId);
  return { searchInstanceId, searchText, isFocused };
};
