import {
  KnowledgeContentDomain,
  getKnowledgeContentDomainMainSectionId,
} from "@eolas-medical/core";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { ContentRepositoryId } from "modules/contentRepository/types";

type ContentPathParams = {
  contentRepositoryId?: ContentRepositoryId;
  subSectionId?: string;
  contentId?: string;
};

const useKnowledgeContentParams = ({ domain }: { domain: KnowledgeContentDomain }) => {
  const { contentRepositoryId, subSectionId, contentId } = useParamsDecoded<ContentPathParams>();

  return {
    contentRepositoryId: contentRepositoryId ?? null,
    subSectionId: subSectionId ?? null,
    mainSectionId: getKnowledgeContentDomainMainSectionId(domain),
    contentId: contentId ?? null,
  };
};

export default useKnowledgeContentParams;
