import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DatePicker, FormElement, Input, Textarea, Toggle } from "UIKit";
import { FileDetailsValues } from "./useFileDetails";
import { isValid, parseISO } from "date-fns";
import { eolasLogger } from "@eolas-medical/core";

export const FileDetailsFormComponent = ({ control }: { control: Control<FileDetailsValues> }) => {
  const { t } = useTranslation();

  const hasExpiryDate = useWatch({ name: "hasExpiryDate", control });

  return (
    <form>
      <FormElement
        control={control}
        id="hasExpiryDate"
        label={t("repository_item_expiry_checkbox_label")}
      >
        {({ value, onChange, ...field }) => {
          return (
            <Toggle
              {...field}
              checked={value}
              onChange={onChange}
              label={t("repository_item_expiry_checkbox_text")}
            />
          );
        }}
      </FormElement>
      {hasExpiryDate ? (
        <FormElement
          required
          id="expiryDate"
          control={control}
          label={t("repository_item_expiry_date_picker_label")}
        >
          {({ value, onChange }, { error }) => {
            let parsedDate: Date | null = null;
            try {
              parsedDate = parseISO(value);
            } catch (error) {
              eolasLogger.error(new Error("Unable to parse date in datepicker"), {
                error,
                valueToParse: value,
              });
            }
            return (
              <DatePicker
                error={error}
                onChange={(date) => {
                  if (date instanceof Date) {
                    onChange(date.toISOString());
                  }
                }}
                selected={isValid(parsedDate) ? parsedDate : undefined}
              />
            );
          }}
        </FormElement>
      ) : null}
      <FormElement required id="name" control={control} label={t("repository_file_name_label")}>
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            placeholder={t("repository_file_name_placeholder")}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="keywords"
        control={control}
        label={t("repository_file_keywords_label")}
      >
        {(field) => <Textarea {...field} placeholder={t("repository_file_keywords_placeholder")} />}
      </FormElement>
    </form>
  );
};
