import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { ContentItem, sectionStore } from "@eolas-medical/core";
import { ContentRepositoryId } from "modules/contentRepository/types";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import ContentItemInstance from "../ContentItemInstance/ContentItemInstance";
import {
  EolasVersatileListContext,
  EolasVersatileListOnDropResult,
  EolasVersatileListRenderItemProps,
  ListMenuAction,
  ListMenuActionEvent,
} from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { useEolasVersatileListSelection } from "UIKit/EolasVersatileList/hooks/useEolasVersatileListSelection";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { CopyItemsModal } from "Pages/Spaces/components/CopyItemsModal/CopyItemsModal";
import EmptyLocalList from "shared/components/Lists/EmptyLocalList/EmptyLocalList";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { IsCopyableStatus } from "../ContentItemInstance/types";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { eolasFileNormaliser } from "Utilities/helpers";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useSectionSortMethod } from "./hooks/useSectionSortMethod";

interface ContentItemListProps {
  contentRepositoryId: ContentRepositoryId;
  isInAdminMode: boolean;
  subSectionId?: string;
  doFlashcardsHaveSpecialties?: boolean;
  shouldDisableReorder?: boolean;
  allowedAdminActions?: ("shadowCopy" | "delete")[];
}

type ContentItemListContext = EolasVersatileListContext<
  ContentItem,
  {
    isInAdminMode: boolean;
    doFlashcardsHaveSpecialties: boolean;
    handleRemoveFromSelection: (id: string) => void;
    handleAddToSelection: (id: string, item: ContentItem) => void;
    isCopyableStatus?: IsCopyableStatus;
  }
>;

const ContentItemList = observer(
  ({
    contentRepositoryId: mainSectionId,
    subSectionId,
    isInAdminMode,
    doFlashcardsHaveSpecialties = false,
    shouldDisableReorder = false,
    allowedAdminActions = [],
  }: ContentItemListProps) => {
    const { t } = useTranslation();
    const { flags } = useLaunchDarkly();
    const {
      handleAddToSelection,
      handleClearSelection,
      handleRemoveFromSelection,
      selection,
      handleSelectAll,
    } = useEolasVersatileListSelection<ContentItem>();

    // If the subSectionId is passed use it, if it is not passed it means that the section is a main section
    const sectionId = subSectionId || mainSectionId;

    const { useContentItemsHook, useBulkDeleteItemsHook } = useContentRepositoryContext();
    const { contentItems, contentItemsLoading } = useContentItemsHook;
    const { bulkDeleteItems } = useBulkDeleteItemsHook;

    const spaceAdminLevel = useGetAdminStatus({ activeTab: "spaces" });
    const orgAdminLevel = useGetAdminStatus({ activeTab: "organisation" });
    const { activeTab } = useEolasNavigation();

    const isDeleteAllowed = isInAdminMode && allowedAdminActions.includes("delete");

    let isFileSelectionEnabled = isDeleteAllowed;
    let isCopyableStatus: IsCopyableStatus | undefined = undefined;

    if (allowedAdminActions.includes("shadowCopy") && flags[LDFlagNames.SHADOW_COPYING]) {
      if (isInAdminMode) {
        isFileSelectionEnabled = true;
        isCopyableStatus = "all";
      } else {
        const isSpaceAdminViewingOrg =
          activeTab === "organisation" && isAccessLevelInAdminGroup(spaceAdminLevel);
        const isLimitedAdmin =
          spaceAdminLevel === "limitedAdmin" || orgAdminLevel === "limitedAdmin";
        if (isSpaceAdminViewingOrg || isLimitedAdmin) {
          isCopyableStatus = "fromTileDropDown";
        }
      }
    }

    let customSearchCallback: ((text: string) => Promise<ContentItem[]>) | undefined = undefined;

    if (activeTab === "organisation" || activeTab === "spaces") {
      customSearchCallback = async (text: string) => {
        const idsToInclude = contentItems.reduce<Record<string, string>>((acc, { id }) => {
          return { ...acc, [id]: id };
        }, {});
        const results = await localSearchStore.search({
          term: text,
          ldFlags: flags,
          shouldSearchOrganisation: sectionStore.organisationActiveHospitalTab,
          isInAdminMode: sectionStore.isAdmin,
          idsToInclude,
        });
        return results.map((f) => {
          const { file } = eolasFileNormaliser(f);
          return mapToContentItem(file);
        });
      };
    }

    const {
      searchInstanceId,
      handleClearSearch,
      handleSearchClick,
      searchResults,
      isSearchActive,
    } = useLocalMobxSearch({
      data: contentItems,
      keysToSearch: ["name", "keywords", "description"],
      customSearchCallback,
    });

    const shouldShowSearchResults = isSearchActive && !contentItemsLoading;
    const isDraggable = isInAdminMode && !shouldDisableReorder;

    const itemsForDisplay = useMemo(() => {
      if (shouldShowSearchResults) {
        return searchResults;
      }

      if (!isInAdminMode) {
        return contentItems.filter((item) => item.isDraft === false);
      }

      return contentItems;
    }, [searchResults, contentItems, shouldShowSearchResults, isInAdminMode]);

    const { reorderContent } = useReorderContent(sectionId);

    const onDropItem = (dropResult: EolasVersatileListOnDropResult) => {
      reorderContent(dropResult);
    };

    const listContext: ContentItemListContext = useMemo(
      () => ({
        isInitialLoading: contentItemsLoading,
        isInAdminMode,
        doFlashcardsHaveSpecialties,
        handleAddToSelection,
        handleRemoveFromSelection,
        isCopyableStatus,
      }),
      [
        contentItemsLoading,
        isInAdminMode,
        doFlashcardsHaveSpecialties,
        handleAddToSelection,
        handleRemoveFromSelection,
        isCopyableStatus,
      ],
    );

    const handleOnClickCopyTo = (items: ContentItem[]) => {
      modalStore.openModal({
        name: "selectCopyTargetModal",
        variant: "component",
        Component: <CopyItemsModal initialItems={items} />,
      });
    };

    const handleOnBulkDelete = (items: ContentItem[]) => {
      modalStore.openModal({
        name: "bulkDeleteConfirmation",
        variant: "dialogue",
        icon: "warning",
        title: t("repository_items_bulk_delete_title"),
        message: t("repository_items_bulk_delete_description", { count: items.length }),
        onConfirmAsync: async () => await bulkDeleteItems(items.map((item) => item.id)),
      });
    };

    const handleMenuAction = (event: ListMenuActionEvent<ContentItem>) => {
      if (event.type === "selectAll") {
        handleSelectAll(event.selectedItems);
      }

      if (event.type === "deselectAll") {
        handleClearSelection();
      }

      if (event.type === "copyTo") {
        handleOnClickCopyTo(Object.values(selection));
      }

      if (event.type === "delete") {
        handleOnBulkDelete(Object.values(selection));
      }
    };

    const listMenuActions: ListMenuAction[] = useMemo(() => {
      const actions: ListMenuAction[] = [];

      if (!isFileSelectionEnabled) {
        return actions;
      }

      if (isCopyableStatus === "all") {
        actions.push("copyTo");
      }

      if (isDeleteAllowed) {
        actions.push("delete");
      }

      if (actions.length > 1) {
        actions.push("selectAll");
      }

      return actions;
    }, [isFileSelectionEnabled, isDeleteAllowed, isCopyableStatus]);

    const { defaultSortMethod, onSortMethodChange } = useSectionSortMethod({
      sectionId,
      isInAdminMode,
    });

    return (
      <div data-testid="content-item-list">
        <EolasVersatileList
          defaultSortMethod={defaultSortMethod}
          context={listContext}
          items={itemsForDisplay}
          isSearchable
          renderItem={ListItem}
          searchInstanceId={searchInstanceId}
          sortDateBy="updatedAt"
          isSortable={!isSearchActive}
          disabledSortMethods={["favourites"]}
          isDraggable={!isSearchActive ? isDraggable : false}
          onDragEnd={onDropItem}
          searchMode="click"
          onClickSearch={handleSearchClick}
          onClearSearch={handleClearSearch}
          droppableId="content-item-list"
          isSelectable={listMenuActions.length > 0}
          selectedItems={selection}
          onMenuAction={handleMenuAction}
          menuActions={listMenuActions}
          ListEmptyComponent={isSearchActive ? NoSearchResults : undefined}
          className="space-y-6"
          onSortMethodChange={onSortMethodChange}
        />
      </div>
    );
  },
);

const ListItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ContentItem, ContentItemListContext>) => {
  const {
    isInAdminMode,
    doFlashcardsHaveSpecialties,
    isListDraggable,
    isListSelectable,
    selectedItems,
    handleAddToSelection,
    handleRemoveFromSelection,
    isCopyableStatus,
  } = context;

  const isSelected = selectedItems && !!selectedItems[item.id];

  const handleCheckboxClick = () => {
    if (isSelected) {
      handleRemoveFromSelection(item.id);
    } else {
      handleAddToSelection(item.id, item);
    }
  };

  return (
    <div className="pb-4">
      <ContentItemInstance
        contentItem={item}
        data-testid={`content-item-${item.id}`}
        isAdminMode={isInAdminMode}
        flashcardsHaveSpecialties={doFlashcardsHaveSpecialties}
        isDraggable={isListDraggable}
        isSelected={isSelected}
        onCheckboxClick={isListSelectable ? handleCheckboxClick : undefined}
        isCopyableStatus={isCopyableStatus}
      />
    </div>
  );
};

const NoSearchResults = () => <EmptyLocalList isSearchResult />;

export default ContentItemList;
