import React from "react";
import { eolasLogger, hasStringProp } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useQueryClient } from "@tanstack/react-query";
import { makeGetLoginTokensQueryKey } from "./useLoginTokens";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

export const useCreateLoginToken = () => {
  const { isLoading, error, isSuccessful, setRequestStatus } = useRequestStatus();
  const { flags } = useLaunchDarkly();
  const { spaceId } = useGetRelevantSpaceDetails();
  const queryClient = useQueryClient();

  const createToken = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await loginTokenService.createToken({
        appID: spaceId ?? "",
        // eslint-disable-next-line camelcase
        given_name: "Demo",
        // eslint-disable-next-line camelcase
        family_name: "Account",
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });

      queryClient.invalidateQueries(makeGetLoginTokensQueryKey(spaceId));
      queryClient.invalidateQueries(spacesKeys.usersList(spaceId ?? ""));

      setRequestStatus({ status: "success", error: "" });
    } catch (err) {
      eolasLogger.error(err);
      setRequestStatus({
        status: "error",
        error: hasStringProp(err, "message") ? err.message : "unknown error",
      });
    }
  }, [spaceId, queryClient, setRequestStatus, flags]);

  return {
    error,
    isLoading,
    isSuccessful,
    createToken,
  };
};
