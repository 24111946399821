import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { userStore } from "@eolas-medical/core";

import { Modal } from "UIKit";
import { AutoAccessModalAfterLogin } from "./AutoAccessModalAfterLogin";

export const AutoAccessAfterLogin = observer(() => {
  const inviteId = userStore.userSession.inviteId;
  const accessLinkId = userStore.userSession.accessLinkId;
  const { userID } = userStore;

  const [modal, setModal] = useState<ReactNode | null>(null);

  const handleCloseModal = useCallback(() => {
    userStore.setInviteId(null);
    userStore.setAccessLinkId(null);
    setModal(null);
  }, []);

  useEffect(() => {
    if (userID && (inviteId || accessLinkId)) {
      setModal(<AutoAccessModalAfterLogin handleCloseModal={handleCloseModal} />);
    } else {
      setModal(null);
    }
  }, [userID, inviteId, accessLinkId, handleCloseModal]);

  return (
    <Modal
      open={!!modal}
      hasCloseIcon={false}
      onClose={handleCloseModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {modal}
    </Modal>
  );
});
