import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { bnfClient, hasProp } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import {
  BNF_CACHE_TIME,
  BNF_STALE_TIME,
  webAdditionalContentSubsections,
  webComingSoonSubSections,
  webGuidanceAndTreatmentSummariesSubSections,
  webInteractionsSubsections,
  webMonographSubsections,
} from "../constants";
import { BnfRouteKey, MedicationSubSection } from "../types";
import bnfKeys from "./bnf.queryKeys";

export type MedicationSubSectionWithId = MedicationSubSection & { id: BnfRouteKey };

interface UseBnfSections {
  bnfSections: MedicationSubSectionWithId[];
  bnfSectionsLoading: boolean;
}

const useBnfSections = ({ isFullBnfEnabled }: { isFullBnfEnabled: boolean }): UseBnfSections => {
  const { flags } = useLaunchDarkly();

  const { data, isInitialLoading } = useQuery({
    queryKey: bnfKeys.all,
    queryFn: () => bnfClient.getSummaryData(),
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
    enabled: isFullBnfEnabled,
  });

  const isInteractionsEnabled = Boolean(flags[LDFlagNames.BNF_INTERACTIONS_FEATURE]);

  const bnfSections: MedicationSubSectionWithId[] = useMemo(() => {
    let sections: (MedicationSubSection | MedicationSubSectionWithId)[] = [];
    if (!isFullBnfEnabled) {
      sections = webComingSoonSubSections;
    } else {
      if (!data) {
        return [];
      }
      sections = [...webMonographSubsections];
      if (isInteractionsEnabled) {
        sections = [...sections, ...webInteractionsSubsections];
      }
      /* @TODO: add these sections in ready  */
      sections = [
        ...sections,
        ...webGuidanceAndTreatmentSummariesSubSections,
        ...webAdditionalContentSubsections,
      ];
    }

    return sections.map((section) => {
      if (hasProp(section, "id")) {
        return section;
      }
      return { ...section, id: section.subsectionType };
    });
  }, [data, isInteractionsEnabled, isFullBnfEnabled]);

  return { bnfSections, bnfSectionsLoading: isInitialLoading };
};

export default useBnfSections;
