import { ClockIcon } from "Assets";
import { EnrichedFileVersionWithDayAndTime } from "../types";
import { EolasCard } from "UIKit/EolasCard/EolasCard";
import { FileTypeItemIcon, Title, Text } from "UIKit";
import { isSupportedFileTypeWebOnly } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { EolasTag } from "UIKit/EolasTag/EolasTag";
import { extractFileNameFromLegacyS3Key } from "Utilities";
import { LabelColor } from "UIKit/EolasTile/EolasTile.types";

export const VersionTile = ({
  version,
  onClickTile,
}: {
  version: EnrichedFileVersionWithDayAndTime;
  onClickTile: () => void;
}) => {
  const {
    lastUpdatedBy,
    day,
    time,
    name,
    type,
    versionNo,
    summaryOfChanges,
    mediaName,
    key,
    status,
    versionNoString,
  } = version;
  const { t } = useTranslation();

  let mediaDisplayName = name;

  if (mediaName) {
    mediaDisplayName = mediaName;
  } else if (key?.startsWith("public/")) {
    const legacyName = extractFileNameFromLegacyS3Key(key);
    if (legacyName) {
      mediaDisplayName = legacyName;
    }
  }

  let tagColor: LabelColor = "green";
  let tagText: string = t("content_item_is_published");

  if (status === "parallelDraft") {
    tagColor = "yellow";
    tagText = t("content_item_is_draft");
  } else if (status === "unpublished") {
    tagColor = "grey";
    tagText = t("content_item_is_unpublished");
  }

  return (
    <div className="flex flex-row gap-3 p-2">
      <div className="flex flex-col items-center gap-2">
        <ClockIcon width={24} height={24} className="text-grey-500" />
        <div className="border w-0 text-grey-500 flex-1" />
      </div>
      <EolasCard onClick={onClickTile}>
        <div className="p-4 gap-3 flex flex-col">
          <div className="flex flex-row w-full justify-between items-center">
            <Title level={8}> {t("version_history_version", { versionNo: versionNoString })}</Title>
            <div className="flex-1 pl-5">
              <Text level={1} className="text-grey-800">
                {day}
              </Text>
              <Text level={1} className="text-grey-500">
                {" / "}
              </Text>
              <Text level={1} className="text-grey-800">
                {time}
              </Text>
            </div>
            <EolasTag color={tagColor} text={tagText} />
          </div>
          <div className="flex flex-row items-center gap-2">
            {isSupportedFileTypeWebOnly(type) ? (
              <>
                <FileTypeItemIcon type={type} className="w-10 h-10" />
                <Text level={1}>{mediaDisplayName}</Text>
              </>
            ) : (
              <Text level={1}>{name}</Text>
            )}
          </div>
          {versionNo !== 1 ? (
            <div>
              <Title level={8} className="text-grey-800">
                {t("repository_edit_item_summary_of_changes")}
              </Title>
              <Text
                level={1}
                className="overflow-ellipsis overflow-hidden block max-w-xl w-60vw whitespace-nowrap"
              >
                {summaryOfChanges ?? t("repository_item_no_summary")}
              </Text>
            </div>
          ) : null}
          <Text level={2} className="text-grey-600">
            {t("version_history_updated_by", { userFullName: lastUpdatedBy.fullName })}
          </Text>
        </div>
      </EolasCard>
    </div>
  );
};
