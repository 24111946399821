import { InnerPageWrapper, Loader } from "UIKit";

export const LinkedDocumentsLoader = () => {
  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-start items-center align-center flex-1 text-center">
        <Loader className="pt-10vh bg-transparent" />
      </div>
    </InnerPageWrapper>
  );
};
