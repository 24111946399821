import { useState } from "react";
import { AuthenticationWizard, SignInStep, ValidateEmailError } from "Pages/Authentication/types";

import { Wizard, Step, useWizard } from "UIKit/Wizard";

import useValidateEmailStep from "../ValidateEmailModal/useValidateEmailStep";
import useValidatePasswordStep from "../ValidatePasswordModal/useValidatePasswordModal";
import useEmailNotVerifiedStep from "../EmailNotVerifiedModal/useEmailNotVerifiedStep";
import useVerificationCodeStep from "../VerificationCodeModal/useVerificationCodeStep";
import { AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import useAssistanceVerificationCode from "modules/authentication/data/useAssistanceVerificationCode";

import useResendVerificationCode from "modules/authentication/data/useResendVerificationCode";
import useValidateUserEmail from "modules/authentication/data/useValidateUserEmail";
import useVerifyCode from "modules/authentication/data/useVerifyCode";
import useSignInUser from "modules/authentication/data/useSignInUser";

interface SignInWizardProps {
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
  defaultStep?: SignInStep;
  email?: string;
}

interface SignInWizardState {
  exists: boolean;
  userConfirmed: boolean;
  email: string;
  password: string;
}

const SignInWizard = ({
  defaultStep = "validate-email",
  email = "",
  onChangeWizard,
}: SignInWizardProps) => {
  const {
    validateUserEmail,
    validatingUserEmail,
    validateUserEmailError,
    validateUserEmailSuccess,
    resetValidateUserEmail,
  } = useValidateUserEmail();

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode();

  const [accountNotExistsError, setAccountNotExistsError] = useState<
    ValidateEmailError | undefined
  >();

  const { verifyCode, isVerifyingCode, verifyCodeError, verifyCodeSuccessful } = useVerifyCode();

  const {
    resendVerificationCode,
    resendingVerificationCode,
    resendVerificationCodeError,
    resendVerificationCodeSuccess,
  } = useResendVerificationCode();

  const { signInUser, isSigningIn, signInError } = useSignInUser({
    onSuccess: () => {
      trackEvent(AnalyticsEvents.CORRECT_PASSWORD_ENTERED_LOGIN_FLOW);
    },
    onError: () => {
      trackEvent(AnalyticsEvents.INCORRECT_PASSWORD_ENTERED_LOGIN_FLOW);
    },
  });

  const [wizardState, setWizardState] = useState<SignInWizardState>({
    exists: false,
    userConfirmed: false,
    email,
    password: "",
  });

  const { wizardControl, submitWizard } = useWizard<SignInStep>();

  const handleSubmitEmail = async (email: string) => {
    handleClearWizard();
    validateUserEmail(email, {
      onSuccess: ({ exists, userConfirmed }) => {
        if (exists) {
          setWizardState((prev) => ({ ...prev, email, exists, userConfirmed }));
        } else {
          trackEvent(AnalyticsEvents.EMAIL_CHECK_ACCOUNT_DOES_NOT_EXIST_LOGIN_FLOW);
          setAccountNotExistsError("account-not-exists");
        }
      },
    });
  };

  const handleSubmitPassword = async (password: string) => {
    const email = wizardState.email;
    await signInUser({ email, password });
  };

  const handleRequestCode = () => {
    resendVerificationCode(wizardState.email);
  };

  const handleSubmitCode = (code: string) => {
    verifyCode(
      { email: wizardState.email, code },
      {
        onSuccess: () => {
          setWizardState((prev) => ({ ...prev, userConfirmed: true }));
        },
      },
    );
  };

  const handleResendCode = () => {
    resendVerificationCode(wizardState.email);
    requestAssistanceVerificationCode({
      queryParams: {
        email: wizardState.email,
        origin: "SIGN_UP",
      },
    });
    trackEvent(AnalyticsEvents.DID_NOT_RECEIVE_CODE_LOGIN_FLOW);
  };

  const handleClearWizard = () => {
    setAccountNotExistsError(undefined);
    resetValidateUserEmail();
    setWizardState({
      exists: false,
      userConfirmed: false,
      email,
      password: "",
    });
  };

  const validateEmailStep = useValidateEmailStep({
    isLoading: validatingUserEmail,
    canProceed: validateUserEmailSuccess && !accountNotExistsError,
    error: validateUserEmailError,
    submitError: accountNotExistsError,
    onSubmit: handleSubmitEmail,
    onEnter: submitWizard,
    onChangeWizard,
    defaultEmail: email,
  });

  const validatePassword = useValidatePasswordStep({
    email: wizardState.email,
    isLoading: isSigningIn,
    error: signInError,
    backDisabled: !!email,
    onSubmit: handleSubmitPassword,
    onEnter: submitWizard,
    onPrev: handleClearWizard,
    onChangeWizard,
  });

  const emailNotVerifiedStep = useEmailNotVerifiedStep({
    email: wizardState.email,
    isLoading: resendingVerificationCode,
    error: resendVerificationCodeError || "",
    canProceed: resendVerificationCodeSuccess,
    onSubmit: handleRequestCode,
  });

  const verificationCodeStep = useVerificationCodeStep({
    email: wizardState.email,
    canProceed: verifyCodeSuccessful,
    isLoading: isVerifyingCode,
    error: verifyCodeError || undefined,
    onSubmit: handleSubmitCode,
    onResendCode: handleResendCode,
    onEnter: submitWizard,
  });

  const steps: Step<SignInStep>[] = [
    validateEmailStep,
    validatePassword,
    emailNotVerifiedStep,
    verificationCodeStep,
  ];

  const getNextStep = (currentStep: SignInStep) => {
    if (currentStep === "validate-email" && wizardState.exists && wizardState.userConfirmed) {
      return "validate-password";
    }

    if (
      currentStep === "validate-email" &&
      wizardState.exists &&
      !wizardState.userConfirmed &&
      wizardState.email.length
    ) {
      return "email-not-verified";
    }

    if (currentStep === "email-not-verified") {
      return "verification-code";
    }

    if (currentStep === "verification-code" && wizardState.userConfirmed) {
      return "validate-password";
    }

    return currentStep;
  };

  return (
    <div className="flex md:w-200">
      <Wizard
        initialStep={defaultStep}
        wizardControl={wizardControl}
        steps={steps}
        showSteps={false}
        getNextStep={getNextStep}
      />
    </div>
  );
};

export default SignInWizard;
