import { motion, Variants } from "framer-motion";
import React, { useCallback } from "react";
import { DragIcon, EditMonoIcon } from "Assets";
import { IconButton } from "UIKit/Button";
import { LocationDescriptor } from "history";
import { Link } from "react-router-dom";
import { Tag } from "UIKit/Tag";

export interface AdminMainSectionTileProps {
  id: string;
  to: LocationDescriptor;
  title?: string;
  tagLabel?: string;
  description: string;
  isDragging?: boolean;
  icon?: React.ReactNode;
  renderTitle?: JSX.Element;
  disableEdit?: boolean;
  disabled?: boolean;
  onEdit: () => void;
}

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export const AdminMainSectionTile: React.FC<AdminMainSectionTileProps> = ({
  id,
  to,
  icon,
  title = "",
  tagLabel,
  isDragging,
  renderTitle,
  description,
  disableEdit,
  disabled,
  onEdit,
}: AdminMainSectionTileProps) => {
  const onEditSection = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onEdit();
    },
    [onEdit],
  );

  const renderTile = () => {
    return (
      <motion.div
        variants={childrenVariants}
        whileHover={{ scale: 1.01 }}
        data-testid={`mainSectionButton-${id}`}
        className={`
        flex items-center space-x-4 bg-white rounded-md p-4 h-32
        ${isDragging ? "tile-border__dragging" : "tile-border"}
      `}
      >
        <DragIcon width={16} height={16} className="text-grey-500 self-start" />
        <div className="flex justify-center items-center mx-0 md:mx-4">{icon}</div>
        <div className="flex flex-col flex-1">
          {renderTitle ?? (
            <h3 className="text-lg font-semibold line-clamp-2" title={title}>
              {title}
            </h3>
          )}
          <p className="text-base line-clamp-2" title={description}>
            {description}
          </p>
        </div>
        {tagLabel && <Tag label={tagLabel} className="self-start" />}

        {!disableEdit && (
          <IconButton icon={<EditMonoIcon />} className="self-start" onClick={onEditSection} />
        )}
      </motion.div>
    );
  };

  if (!disabled) {
    return <Link to={to}>{renderTile()}</Link>;
  }

  return <div>{renderTile()}</div>;
};
