import {
  ContentItem,
  EolasShadowCopyContent,
  FileExtensionMediaType,
  SupportedFileType,
  hasStringProp,
} from "@eolas-medical/core";
import { ChildReference } from "API";
import { isContentItem, isMappedContentItem } from "modules/contentRepository/typeguards";

export const isContentItemMediaSupportedFileType = (
  item: ContentItem,
): item is ContentItem & { media: { type: SupportedFileType } } => {
  if (item.media && typeof item.media.type === "string") {
    if (Object.values<string>(FileExtensionMediaType).includes(item.media.type)) {
      return true;
    }
  }

  return false;
};

export const isShadowCopyContentItem = (item: unknown): item is EolasShadowCopyContent => {
  return (
    (isContentItem(item) || isMappedContentItem(item)) &&
    hasStringProp(item, "deepLinkType") &&
    hasStringProp(item, "deepLinkUrl")
  );
};

export const isChildReferenceSection = (
  item: ChildReference,
): item is ChildReference &
  ({ type: "section" } | { type: "mainSection" } | { type: "subSection" }) => {
  return ["section", "mainSection", "subSection"].includes(item.type);
};

export const isChildReferenceFile = (
  item: ChildReference,
): item is ChildReference & ({ type: "file" } | { type: "deepLink" }) => {
  return item.type === "file" || item.type === "deepLink";
};
