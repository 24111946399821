import { UpdateLinkItemDto, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

const useEditKnowledgeContentLink = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({
      contentId,
      updateLinkItemDto,
    }: {
      contentId: string;
      updateLinkItemDto: UpdateLinkItemDto;
    }) => {
      await knowledgeContentClient.updateLinkItem({ contentId, updateLinkItemDto });
    },
  );

  return {
    editLink: mutate,
    editingLink: isLoading,
    editingLinkSuccess: isSuccess,
    editingLinkError: error instanceof Error ? error.message : "",
  };
};

export default useEditKnowledgeContentLink;
