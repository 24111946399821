import { CheckedIcon, UserIcon } from "Assets";
import useReviewersSummary from "modules/portfolio/data/useAssessorsSummary";
import { useTranslation } from "react-i18next";
import { Text } from "UIKit";
import { EventStatus } from "modules/portfolio/types";

interface AssessorListProps {
  eventId: string;
  eventStatus?: EventStatus;
}

const AssessorsList = ({ eventId, eventStatus }: AssessorListProps) => {
  const { t } = useTranslation();
  const { assessorsList, assessorsListLoading } = useReviewersSummary(eventId);

  const noAssessorText =
    eventStatus === EventStatus.COMPLETE
      ? t("portfolio_completed_no_feedback")
      : t("portfolio_pending_review_no_reviewers");

  if (assessorsList.length === 0 || assessorsListLoading) {
    return (
      <div
        data-testid="no-assessor-text"
        className="rounded-xl border border-grey-400 bg-grey-300 w-full flex justify-center items-center py-9"
      >
        <Text className="text-center text-grey-600" level={1}>
          {noAssessorText}
        </Text>
      </div>
    );
  }

  return (
    <div
      data-testid="assessor-list"
      className="rounded-xl bg-white border border-grey-400  w-full px-5 divide-y divide-grey-300"
    >
      {assessorsList.map(({ email, familyName, givenName }) => (
        <div key={email} className="flex flex-1 justify-between py-4.5">
          <div className="flex items-center">
            <UserIcon className="h-8 w-8 mr-4" />
            <Text level={1}>{`${givenName} ${familyName}`}</Text>
          </div>
          <div className="flex items-center">
            <Text level={1}>{email}</Text>
          </div>
          <div className="flex items-center">
            <CheckedIcon className="text-green-500" />
            <Text className="text-green-500 ml-2" level={1}>
              {t("portfolio_pending_review_submitted")}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssessorsList;
