import { sectionStore, sponsoredSlotClient, userStore } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const useGetUserSponsoredSlot = () => {
  const communityId = sectionStore.data.community?.id ?? "";
  const { flags } = useLaunchDarkly();
  const shouldShowSponsoredSlot = flags[LDFlagNames.SPONSORED_SLOTS];
  const { specialty, jobTitle, seniority, location } = userStore.userData;

  const jobInfoString =
    JSON.stringify(specialty) +
    JSON.stringify(jobTitle) +
    JSON.stringify(seniority) +
    JSON.stringify(location?.country);

  const { data } = useQuery({
    queryKey: ["getUserSponsoredSlot", communityId, jobInfoString],
    queryFn: () => sponsoredSlotClient.getUserSponsoredSlot({ communityId }),
    enabled: shouldShowSponsoredSlot && Boolean(communityId),
  });

  return {
    userSponsoredSlot: shouldShowSponsoredSlot ? data : null,
  };
};
