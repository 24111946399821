import { useEffect, useState } from "react";
import { Modal } from "UIKit";

import { useSessionContext } from "modules/session";

import { AuthenticationWizard, SignInStep, SignUpStep } from "./types";
import LandingPage from "./pages/Landing/LandingPage";
import SoftSignOutModal from "./components/SoftSignOutModal/SoftSignOutModal";
import SignInWizard from "./components/SignInWizard/SignInWizard";
import SignUpWizard from "./components/SignUpWizard/SignUpWizard";
import ForgotPasswordWizard from "./components/ForgotPasswordWizard/ForgotPasswordWizard";
import { timeEvent, trackEvent } from "API/Analytics";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { useLocation } from "react-router-dom";
import { InviteData } from "./pages/Invitations/pages/hooks/useCheckInvitation";
import { usePostLoginRedirect } from "shared/hooks/usePostLoginRedirect/usePostLoginRedirect";
import { RedirectInfoModal } from "./components/RedirectInfoModal/RedirectInfoModal";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { observer } from "mobx-react-lite";
import { webStore } from "Stores/WebStore";
import { modalStore } from "Stores/ModalStore";
import { WholeAppLoaderModal } from "UIKit/WholeAppLoaderModal/WholeAppLoaderModal";

interface SignInWizardState {
  defaultStep: SignInStep;
  email?: string;
}

interface SignUpWizardState {
  defaultStep: SignUpStep;
  email?: string;
}

interface ForgotPasswordWizardState {
  email: string;
}

const Authentication = observer(() => {
  const { clearSession } = useSessionContext();
  const { postLoginRedirectPath } = usePostLoginRedirect();
  const { flags } = useLaunchDarkly();
  const { shouldReloadWindow } = webStore;

  const isSoftLoggedOut = !userStore.userSession.isLoggedIn && Boolean(userStore.userID);

  const showDeeplinkInfoModal = flags[LDFlagNames.SHOW_DEEPLINK_INFO_MODAL];

  const { state } = useLocation<{
    inviteData?: InviteData;
  }>();

  useEffect(() => {
    if (!isSoftLoggedOut || !shouldReloadWindow) {
      return;
    }
    /**
     * If they have left our app open and the idle timeout has triggered,
     * shouldReloadWindow will be set true, allowing us to reload our app
     * when the window is next in focus
     */
    const listener = () => {
      if (!document.hidden) {
        modalStore.openModal({
          variant: "component",
          Component: WholeAppLoaderModal,
          name: "wholeAppLoading",
          isDismissible: false,
        });
        webStore.setShouldReloadWindow(false);
        window.location.reload();
      }
    };
    window.addEventListener("visibilitychange", listener);
    return () => window.removeEventListener("visibilitychange", listener);
  }, [isSoftLoggedOut, shouldReloadWindow]);

  const [openedWizard, setOpenedWizard] = useState<AuthenticationWizard | null>(null);

  const [signInWizardState, setSignInWizardState] = useState<SignInWizardState>({
    defaultStep: "validate-email",
  });

  const [signUpWizardState, setSignUpWizardState] = useState<SignUpWizardState>({
    defaultStep: "validate-email",
  });

  const [forgotPasswordWizardState, setForgotPasswordWizardState] =
    useState<ForgotPasswordWizardState>({
      email: "",
    });

  const [didCloseRedirectModal, setDidCloseRedirectModal] = useState(false);

  const handleOpenSignInWizard = () => {
    trackEvent(AnalyticsEvents.SIGN_IN_BTN_LOGIN_FLOW);
    setOpenedWizard("sign-in-wizard");
  };

  const handleOpenSignUpWizard = () => {
    trackEvent(AnalyticsEvents.CREATE_NEW_ACCOUNT_LOGIN_FLOW);
    timeEvent(AnalyticsEvents.START_CREATE_ACCOUNT_NEW_ACCOUNT);
    setOpenedWizard("sign-up-wizard");
  };

  const handleCloseWizard = () => {
    setOpenedWizard(null);
    setSignInWizardState({
      defaultStep: "validate-email",
    });
    setSignUpWizardState({
      defaultStep: "validate-email",
    });
  };

  const handleChangeWizard = (newWizard: AuthenticationWizard, email: string) => {
    if (newWizard === "sign-in-wizard") {
      trackEvent(AnalyticsEvents.FORGOT_PASSWORD_LOGIN_FLOW);
      setSignInWizardState({
        defaultStep: "validate-password",
        email,
      });
    }

    if (newWizard === "sign-up-wizard") {
      setSignUpWizardState({
        defaultStep: "create-account",
        email,
      });
    }

    if (newWizard === "forgot-password-wizard") {
      setForgotPasswordWizardState({ email });
    }

    setOpenedWizard(newWizard);
  };

  const handleCloseRedirectInfoModal = () => {
    setDidCloseRedirectModal(true);
  };

  const shouldShowRedirectModal =
    !!postLoginRedirectPath &&
    !!showDeeplinkInfoModal &&
    (postLoginRedirectPath.includes("linked-documents") ||
      postLoginRedirectPath.includes("/check-space-access/")) &&
    !openedWizard &&
    !didCloseRedirectModal &&
    !isSoftLoggedOut;

  const { inviteData } = state ?? {};

  useEffect(() => {
    if (!inviteData) {
      return;
    }
    const { userEmail, type } = inviteData;
    if (type === "sign-in") {
      if (userEmail) {
        setSignInWizardState((old) => ({ ...old, email: userEmail }));
      }
      handleOpenSignInWizard();
    }
    if (type === "sign-up") {
      if (userEmail) {
        setSignUpWizardState((old) => ({ ...old, email: userEmail }));
      }
      handleOpenSignUpWizard();
    }
  }, [inviteData]);

  return (
    <>
      <LandingPage onClickSignIn={handleOpenSignInWizard} onClickSignUp={handleOpenSignUpWizard} />

      <Modal open={!!openedWizard} onClose={handleCloseWizard}>
        {openedWizard === "sign-in-wizard" ? (
          <SignInWizard
            onChangeWizard={handleChangeWizard}
            defaultStep={signInWizardState.defaultStep}
            email={signInWizardState.email}
          />
        ) : null}
        {openedWizard === "sign-up-wizard" ? (
          <SignUpWizard
            onChangeWizard={handleChangeWizard}
            defaultStep={signUpWizardState.defaultStep}
            email={signUpWizardState.email}
          />
        ) : null}
        {openedWizard === "forgot-password-wizard" ? (
          <ForgotPasswordWizard email={forgotPasswordWizardState.email} />
        ) : null}
      </Modal>

      <Modal open={isSoftLoggedOut} onClose={clearSession}>
        <SoftSignOutModal />
      </Modal>
      <RedirectInfoModal
        isOpen={shouldShowRedirectModal}
        handleClose={handleCloseRedirectInfoModal}
      />
    </>
  );
});

export default Authentication;
