import {
  FileExtensionMediaType,
  EolasSupportedMiscellaneousType,
  Maybe,
} from "@eolas-medical/core";

export const isSupportedBlobType = (type?: Maybe<string>): type is `${FileExtensionMediaType}` => {
  return Object.values<string>(FileExtensionMediaType).includes(type ?? "");
};

export const isSupportedMiscType = (
  type?: Maybe<string>,
): type is `${EolasSupportedMiscellaneousType}` => {
  return Object.values<string>(EolasSupportedMiscellaneousType).includes(type ?? "");
};

export const isSupportedWizardType = (
  type?: Maybe<string>,
): type is `${FileExtensionMediaType}` | `${FileExtensionMediaType}` => {
  return isSupportedBlobType(type) || isSupportedMiscType(type);
};
