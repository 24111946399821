import { niceGuidelinesClient } from "@eolas-medical/core";
import { NICE_CACHE_TIME, NICE_STALE_TIME } from "../constants";
import { LDFlagNames } from "Utilities/types";
import { useQuery } from "@tanstack/react-query";
import { useLaunchDarkly } from "Contexts";

export const useGetNiceGuidelineData = () => {
  const { flags } = useLaunchDarkly();

  const isEnabled = flags[LDFlagNames.NICE_GUIDELINES_SECTION];
  const { data, isLoading } = useQuery({
    queryKey: ["nice-guidelines-data"],
    queryFn: () => niceGuidelinesClient.getSummaryData(null),
    staleTime: NICE_STALE_TIME,
    cacheTime: NICE_CACHE_TIME,
    enabled: isEnabled,
  });

  return { data, isLoading };
};
