import { ApolloError } from "@apollo/client";
import { contentClient, eolasLogger } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { EnrichedFileVersionWithDayAndTime } from "shared/pages/ContentRepository/ContentItems/components/ShowVersionsModal/types";
import { FileStatus, isFileStatus } from "UIKit/EolasFileTile/EolasFileTile.types";

export const useContentVersions = (contentId: string) => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryFn: async () => {
      try {
        const versions = await contentClient.getContentVersions(contentId);
        return versions;
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors[0].extensions.code !== 403) {
          throw error;
        }
      }
    },
    queryKey: ["contentVersions", contentId],
  });

  const fileVersionsWithDayAndTime: EnrichedFileVersionWithDayAndTime[] =
    data?.fileVersions.map((v) => {
      const status: FileStatus = isFileStatus(v.status) ? v.status : "published";
      if (!isFileStatus(v.status)) {
        eolasLogger.warn(
          new Error(
            `Status of fileVersion with id ${v.id} is not a FileStatus, setting status published as default`,
          ),
        );
      }
      const createdAtDate = new Date(v.createdAt);
      let versionNoString = v.versionNo.toString();
      if (status === "parallelDraft") {
        versionNoString = `${v.nextPublishedVersionNo} (${t("content_item_is_draft")})`;
      } else if (status === "unpublished") {
        versionNoString = `${v.nextPublishedVersionNo} (${t("content_item_is_unpublished")})`;
      }
      return {
        ...v,
        day: format(createdAtDate, "dd MMMM yyyy"),
        time: format(createdAtDate, "hh:mm a"),
        deleted: v.deleted === "true" ? "true" : "false",
        versionNoString,
        status,
      };
    }) || [];

  return {
    fileVersionsWithDayAndTime,
    isLoadingContentVersions: isLoading,
    isUserAllowed: !isError,
  };
};
