import useImageBank from "Hooks/useImageBank";
import ImagePicker from "UIKit/ImagePicker/ImagePicker";
import { compareUrls } from "Utilities/helpers";

interface ImagePickerStepProps {
  errorDraggedImageText: string;
  onChangeBlob: (file: File | null) => void;
  onChangeImageUrl: (imageUrl: string) => void;
  shouldAllowBlankSelection: boolean;
  defaultImage?: string;
}

export const ImagePickerStep = ({
  errorDraggedImageText,
  onChangeBlob,
  onChangeImageUrl,
  defaultImage,
  shouldAllowBlankSelection,
}: ImagePickerStepProps) => {
  const { imageBank, imageBankLoading } = useImageBank();

  if (imageBankLoading) return null;

  const isCustomImage = defaultImage
    ? !imageBank.some((item) => compareUrls(item.imageUrl, defaultImage))
    : false;

  return (
    <ImagePicker
      galleryImages={imageBank}
      errorDraggedImageText={errorDraggedImageText}
      isLoading={imageBankLoading}
      onChangeBlob={onChangeBlob}
      onChangeImageUrl={onChangeImageUrl}
      defaultImage={defaultImage ? { url: defaultImage, isCustomImage } : undefined}
      shouldAllowBlankSelection={shouldAllowBlankSelection}
    />
  );
};
