export const NICE_CACHE_TIME = 32 * 60 * 60 * 1000;
export const NICE_STALE_TIME = 24 * 60 * 60 * 1000;
export const NICE_BASE_URL = "nice.org.uk";

export const GUIDANCE_TYPE = "guidance";
export const CHAPTER_SUBTYPE = "chapter";

export const WEBVIEWER_DOUBLE_INSTANCE_ERROR = "Two instances";

export const WEBVIEWER_ADD_EVENT_LISTENER_ERROR =
  "Cannot read properties of null (reading 'addEventListener')";
