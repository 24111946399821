import { checkIsBNFAccessAllowed, syncUserData } from "@eolas-medical/core";
import { useRunOnMountUnmount } from "Hooks";
import { useState } from "react";
import { BnfAccessModal } from "../components/BnfAccessModal/BnfAccessModal";
import { useHistory } from "react-router-dom";
import { errorStore } from "Stores/ErrorStore";

const onSuccess = () => {
  // Not needed in this case
};

export const useCheckBnfAccessModal = () => {
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const { goBack } = useHistory();

  useRunOnMountUnmount({
    onMount: () => {
      const result = checkIsBNFAccessAllowed({
        onSuccess,
      });
      if (result === "accessDenied") {
        syncUserData()
          .then(() => {
            const result = checkIsBNFAccessAllowed({
              onSuccess,
            });
            if (result === "accessDenied") {
              setModal(() => (
                <BnfAccessModal
                  closeModal={() => {
                    setModal(null);
                    goBack();
                  }}
                />
              ));
            }
          })
          .catch((error: unknown) => {
            if (error instanceof Error) {
              errorStore.captureError({ error, source: "user" });
            }
          });
      }
    },
  });

  return { modal };
};
