import {
  Maybe,
  sectionStore,
  SAFETY_THRESHOLD_MAX_SECTION_NESTING,
  isDefined,
} from "@eolas-medical/core";
import { LimitedAdminSectionPathMap } from "../types";
import { isDev } from "Utilities/helpers";

const makeSectionPath = (sectionId: string): string | null => {
  const currentSection = sectionStore.getSection(sectionId);
  if (!currentSection?.parentID) {
    if (isDev()) {
      console.warn(
        "Unable to make section path item: no parent ID found for section id in limitedAccess array. This might be because the section was deleted",
      );
    }
    return null;
  }
  if (sectionId === currentSection.mainSectionID) {
    return sectionId;
  }
  let path = sectionId;
  let levelsAbove = 0;
  let parentId = currentSection.parentID;

  while (parentId !== currentSection.mainSectionID) {
    levelsAbove += 1;
    if (levelsAbove > SAFETY_THRESHOLD_MAX_SECTION_NESTING) {
      console.error(new Error("Error making section path item: likely infinite loop"), {
        currentSection,
      });
      return null;
    }
    const parentSection = sectionStore.getSection(parentId);
    if (!parentSection.parentID) {
      if (isDev()) {
        console.warn(
          "Unable to make section path item: no parent ID found for section id in limitedAccess array. This might be because the section was deleted",
        );
      }
      return null;
    }
    path = `${parentId}/${path}`;
    parentId = parentSection.parentID;
  }
  path = `${currentSection.mainSectionID}/${path}`;
  return path;
};

/**
 * This lookup map summarises the sectionId path to the
 * limited access section to make it simple to check if a section higher in the
 * hierarchy should be displayed.
 * eg: "Analgesia" is the section the limited admin should have access to, but the hierarchy
 * goes "Hospital Guidelines" -> "Paediatrics" -> "Analgesia"
 * When we are starting the navigation from the top of the tree, we need to know to display
 * "Hospital Guidelines" because the limited admin has access to "Analgesia"
 */
export const makeLimitedAdminSectionLookupMap = (
  limitedAccess: Maybe<string[]>,
): LimitedAdminSectionPathMap => {
  if (!limitedAccess) {
    return {};
  }
  const limitedAdminSectionLookupMap: LimitedAdminSectionPathMap = {};
  limitedAccess.forEach((currentSectionId) => {
    const path = makeSectionPath(currentSectionId);
    if (path) {
      limitedAdminSectionLookupMap[currentSectionId] = path;
    }
  }, {});
  return limitedAdminSectionLookupMap;
};

/**
 * For deciding if a given section has an allowed limited admin section somewhere
 * in its hierarchy tree above (including itself)
 * eg: "Analgesia" is the section the limited admin should have access to, but we are
 * currently in "Opiates" where the hierarchy is:
 * "Hospital Guidelines" -> "Paediatrics" -> "Analgesia" -> Medication -> Opiates
 */
export const isAncestorSectionIdInLimitedAccessArray = (
  sectionId: string,
  limitedAdminSectionLookupMap: LimitedAdminSectionPathMap,
) => {
  const currentSection = sectionStore.getSection(sectionId);
  if (!currentSection.parentID) {
    return false;
  }
  if (
    limitedAdminSectionLookupMap[sectionId] ||
    limitedAdminSectionLookupMap[currentSection.mainSectionID]
  ) {
    return true;
  }
  let parentId = currentSection.parentID;
  if (limitedAdminSectionLookupMap[parentId]) {
    return true;
  }
  let levelsAbove = 0;
  while (parentId !== currentSection.mainSectionID) {
    levelsAbove += 1;
    if (levelsAbove > SAFETY_THRESHOLD_MAX_SECTION_NESTING) {
      console.error(new Error("Error making section path item: likely infinite loop"), {
        currentSection,
      });
      return false;
    }
    if (limitedAdminSectionLookupMap[parentId]) {
      return true;
    }
    const nextLevelSection = sectionStore.getSection(parentId);
    if (!nextLevelSection.parentID) {
      return false;
    }
    parentId = nextLevelSection.parentID;
  }
  return false;
};

export const makeFilterFnForChildren =
  (
    limitedAdminSectionLookupMap: LimitedAdminSectionPathMap,
    shouldCurrentSectionAllowAdminRights: boolean | null,
  ) =>
  (sectionId: string) => {
    if (shouldCurrentSectionAllowAdminRights) {
      return true;
    }
    if (limitedAdminSectionLookupMap[sectionId]) {
      return true;
    }
    return Object.values(limitedAdminSectionLookupMap).some((value) => {
      return value?.includes(sectionId);
    });
  };

// Types include a few null / undefined:
export const sanitiseLimitedAdminArray = (limitedAccess: Maybe<Maybe<string>[]>): string[] =>
  limitedAccess?.filter(isDefined) ?? [];

export const makeShouldIncludeSectionHelpers = (
  limitedAccess: string[],
  currentSectionId?: string,
) => {
  const sectionIdArray = sanitiseLimitedAdminArray(limitedAccess);
  const limitedAdminSectionLookupMap = makeLimitedAdminSectionLookupMap(sectionIdArray);
  const shouldCurrentSectionAllowAdminRights = currentSectionId
    ? isAncestorSectionIdInLimitedAccessArray(currentSectionId, limitedAdminSectionLookupMap)
    : null;
  return {
    filterFnForChildren: makeFilterFnForChildren(
      limitedAdminSectionLookupMap,
      shouldCurrentSectionAllowAdminRights,
    ),
    shouldCurrentSectionAllowAdminRights,
  };
};
