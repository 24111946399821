import { useQuery } from "@tanstack/react-query";
import { contentClient } from "@eolas-medical/core";
import analyticsKeys from "./analytics.queryKeys";

const useGetMainSectionAnalytics = ({ mainSectionId }: { mainSectionId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: analyticsKeys.detailMainSection(mainSectionId),
    queryFn: () =>
      contentClient.getMainSectionAnalytics({
        mainSectionId,
      }),
    staleTime: 1000,
  });

  return {
    mainSectionAnalytics: data,
    isLoadingMainSectionAnalytics: isLoading,
  };
};

export default useGetMainSectionAnalytics;
