import React from "react";
import * as icons from "Assets/Icons";
import { CircleIcon, Text, Title } from "UIKit";

type InviteCardProps = {
  iconType: icons.EolasIcons;
  titleText: string;
  descriptionText: string;
  disabled?: boolean;
  onClick: () => void;
};

const InviteCard: React.FC<InviteCardProps> = ({
  iconType,
  titleText,
  descriptionText,
  disabled = false,
  onClick,
}: InviteCardProps) => {
  const Icon = iconType ? icons[iconType] : null;

  const enabledClass = "hover:border-darkblue-500";
  const disabledClass = "cursor-not-allowed opacity-25";

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    onClick();
  };

  return (
    <div
      className={`w-1/2 md:w-full cursor-pointer  text-center p-6 flex flex-col items-center justify-center border-2 border-grey-300 shadow-lg rounded-md 
      ${disabled ? disabledClass : enabledClass}
      `}
      onClick={handleClick}
      data-testid="invite-card"
    >
      {Icon && <CircleIcon icon={<Icon />} />}
      <Title level={7} className="mt-4">
        {titleText}
      </Title>
      <Text level={5} className="text-grey-600 font-semibold">
        {descriptionText}
      </Text>
    </div>
  );
};

export default InviteCard;
