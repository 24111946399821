import { rocket } from "Assets";
import { Button } from "UIKit/Button";
import { InnerModalWrapper, LottieWithHeader, ModalHeader, SuccessModal, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";

interface ItemAudienceModal {
  onClose: () => void;
  onSendReminder?: () => void;
  onCompleteSuccess: () => void;
  isLoading?: boolean;
  isSuccess?: boolean;
}
const BoostOpenRatesModal = ({
  onSendReminder,
  onCompleteSuccess,
  onClose,
  isLoading = false,
  isSuccess = false,
}: ItemAudienceModal) => {
  const { t } = useTranslation();
  const renderConfirmReminder = () => {
    return (
      <div className="space-y-4">
        <div className="flex flex-col w-2/3 mx-auto justify-center items-center">
          {renderRocketAnimation()}
          <Title level={9} className="mx-auto text-center ">
            {t("analytics_boost_rates_reminder_information")}.
          </Title>
        </div>
        <div className="flex justify-center items-center mx-auto space-x-2 w-full md:w-2/4">
          <Button size="sm" variant="outline" onClick={onClose} className="w-full">
            {t("general_cancel")}
          </Button>
          <Button
            size="sm"
            iconLeft="ShareIcon"
            className="w-full"
            onClick={onSendReminder}
            isLoading={isLoading}
            disabled={isLoading}
          >
            <Text level={2} className="font-bold ml-2">
              {t("general_send")}
            </Text>
          </Button>
        </div>
      </div>
    );
  };

  const renderRocketAnimation = () => {
    return (
      <LottieWithHeader
        animationSize="100%"
        animation={rocket}
        lottieOptions={{ loop: true }}
        text={""}
      />
    );
  };

  if (isSuccess) {
    return (
      <SuccessModal onComplete={onCompleteSuccess} text={t("analytics_stats_read_reminder_sent")} />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col justify-center items-center p-10  border-b border-b-grey-300">
        <Title level={5}>{t("analytics_boost_rates_title")}</Title>
        <Text level={1} className="text-grey-600">
          {t("analytics_boost_rates_subtitle")}
        </Text>
      </ModalHeader>

      <div
        className="flex flex-col
        p-6 sm:p-8 bg-grey-50 max-h-80vh overflow-y-auto"
      >
        {renderConfirmReminder()}
      </div>
    </InnerModalWrapper>
  );
};

export default BoostOpenRatesModal;
