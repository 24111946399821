import React from "react";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";

interface PillListProps {
  pillBgColor: string;
  pillTextColor: string;
  pillIcon: React.ReactNode;
  searchTypeSection: string;
  mainSectionName: string;
}

const PillList: React.FC<PillListProps> = ({
  pillBgColor,
  pillTextColor,
  pillIcon,
  searchTypeSection,
  mainSectionName,
}) => {
  const bgClass = `bg-${pillBgColor}`;
  const textClass = `text-${pillTextColor}`;

  return (
    <div className="mt-2 flex flex-wrap">
      <Pill
        value={searchTypeSection}
        className={`${bgClass} ${textClass} h-6 text-sm mr-1`}
        size={Sizes.MEDIUM}
        icon={pillIcon}
      />
      <Pill
        value={mainSectionName}
        className={`${bgClass} ${textClass} h-6 text-sm mr-1`}
        size={Sizes.MEDIUM}
      />
    </div>
  );
};

export default PillList;
