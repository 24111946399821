import useGetMainSectionAnalytics from "modules/contentRepository/data/useGetMainSectionAnalytics";
import useSendContentItemReminder from "modules/contentRepository/data/useSendContentItemReminder";
import useDownloadItemRateReport from "modules/contentRepository/data/useDownloadItemRateReport";
import useSendMainSectionReminder from "modules/contentRepository/data/useSendMainSectionReminder";

export const useMainSectionAnalytics = ({ mainSectionId }: { mainSectionId: string }) => {
  const { mainSectionAnalytics, isLoadingMainSectionAnalytics } = useGetMainSectionAnalytics({
    mainSectionId: mainSectionId,
  });
  const {
    sendContentItemReminder,
    sendingContentItemReminder,
    sendContentItemReminderSuccess,
    resetContentItemReminder,
  } = useSendContentItemReminder();

  const {
    sendMainSectionReminder,
    sendingMainSectionReminder,
    sendMainSectionReminderSuccess,
    resetMainSectionReminder,
  } = useSendMainSectionReminder();

  const { downloadItemRateReport, downloadingItemRateReport } = useDownloadItemRateReport();

  return {
    mainSectionAnalytics,
    isLoadingMainSectionAnalytics,
    sendMainSectionReminder,
    sendingMainSectionReminder,
    sendMainSectionReminderSuccess,
    resetMainSectionReminder,
    sendContentItemReminder,
    sendingContentItemReminder,
    sendContentItemReminderSuccess,
    resetContentItemReminder,
    downloadItemRateReport,
    downloadingItemRateReport,
  };
};
