export enum AttachmentFileType {
  newsFeedPDF = "pdf",
  newsFeedMp4 = "mp4",
  newsFeedLink = "link",
}

export interface INewsItem {
  fileName: string;
  fileDescription: string;
  fileKey: string;
  fileType: AttachmentFileType;
  tracked?: boolean;
  fileID?: string;
  title?: string;
  info?: string;
  createdAt?: string;
  id?: string;
}

export enum Sizes {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
}
