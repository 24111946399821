import React from "react";
import { Loader } from "UIKit/Loader";

export interface ProgressBarProps {
  progress: number;
  className?: string;
  loadingStatusLabel?: "spinner" | "percentage";
}

const _ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className = "",
  loadingStatusLabel = "spinner",
}: ProgressBarProps) => {
  return (
    <div className="flex gap-4 items-center justify-center">
      <div className={`bg-grey-light rounded-xl h-8 w-full ${className}`}>
        <div
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={progress}
          style={{ width: `${progress}%` }}
          className={`
          flex justify-center items-center
          h-full rounded-l-xl bg-green shadow-md transition-width transition-slowest ease w-full
          ${progress === 100 ? "rounded-r-xl" : ""}
        `}
        >
          {loadingStatusLabel === "percentage" ? (
            <p className="text-white text-lg font-bold">{progress}%</p>
          ) : null}
          {loadingStatusLabel === "spinner" ? (
            <Loader className="bg-transparent opacity-100 text-white z-10" size={20} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const ProgressBar = React.memo(_ProgressBar);
