import { InnerModalWrapper, Button, Title, Text, IconButton } from "UIKit";
import { SelectTileList, SelectTileListContext } from "../SelectTileList/SelectTileList";
import { useTranslation } from "react-i18next";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { ArrowLeftIcon, CaretRight } from "Assets/Icons/monocolored";
import { observer } from "mobx-react-lite";
import { EolasRadio } from "UIKit/EolasRadio/EolasRadio";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { RootIcon } from "../RootIcons";
import { useCopyItemsModal } from "./hooks/useCopyItemsModal";
import { ContentItem, hasProp, sectionStore } from "@eolas-medical/core";
import { CopyItemsSuccess } from "./components/CopyItemsSuccess";
import { CopyItemsPartialSuccess } from "./components/CopyItemsPartialSuccess";
import { InvalidItemsModal } from "./components/InvalidItemsModal";
import { useLocalChildrenList, ListItem } from "../SelectTileList/hooks/useLocalChildrenList";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { getIconUrl } from "modules/helpers";

type Props = {
  initialItems: ContentItem[];
};

export const CopyItemsModal = observer(({ initialItems }: Props) => {
  const { t } = useTranslation();

  const shouldHideOrgContent = initialItems[0]?.ownerId !== sectionStore.organisationID;

  const orgFullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const fullAccessLevel = useGetAdminStatus({ activeTab: "spaces" });

  const isUserOrgAdmin = isAccessLevelInAdminGroup(orgFullAccessLevel);
  const isUserSpaceAdmin = isAccessLevelInAdminGroup(fullAccessLevel);

  const shouldShowOrgItems = !shouldHideOrgContent && isUserOrgAdmin;
  const shouldShowSpaceItems = isUserSpaceAdmin;

  const {
    handleSelectionChange,
    selectedSectionId,
    isSearching,
    setIsSearching,
    handleCopyItems,
    shouldShowError,
    shouldShowPartialSuccess,
    shouldShowSuccess,
    isCopying,
    retryFailedItems,
    hasRetryFailed,
    failedItems,
    disabledSectionIds,
    itemsToCopy,
    hasInvalidItems,
    onInvalidItemsContinue,
  } = useCopyItemsModal({ initialItems, shouldShowOrgItems, shouldShowSpaceItems });

  const { items, handleNavigateDown, handleNavigateUp, parentItem, searchData } =
    useLocalChildrenList({
      disabledIds: disabledSectionIds,
      leafType: "sectionWithFiles",
      shouldShowOrgItems,
      shouldShowSpaceItems,
    });

  const isAtRoot = items.length > 0 && items[0].hasParent === false ? true : false;

  const onNavigateUp = () => {
    // For this modal, we want to clear the selection when navigating
    if (selectedSectionId) {
      handleSelectionChange({});
    }
    handleNavigateUp();
  };

  if (hasInvalidItems) {
    return <InvalidItemsModal items={itemsToCopy} onContinue={onInvalidItemsContinue} />;
  }

  if (shouldShowSuccess && selectedSectionId) {
    return <CopyItemsSuccess destinationSectionId={selectedSectionId} />;
  }

  if (shouldShowPartialSuccess) {
    return (
      <CopyItemsPartialSuccess
        originalItems={itemsToCopy}
        failedItems={failedItems}
        retryFailedItems={retryFailedItems}
        isRetrying={isCopying}
        didRetryFail={hasRetryFailed}
      />
    );
  }

  const shouldShowSearch =
    orgFullAccessLevel !== "limitedAdmin" && fullAccessLevel !== "limitedAdmin";

  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 h-70vh w-full">
        <div>
          {isAtRoot || isSearching ? null : (
            <IconButton
              className="absolute inset-6 sm:inset-12"
              color="white"
              variant="rounded"
              onClick={onNavigateUp}
              icon={<ArrowLeftIcon className="w-5 h-5" />}
            />
          )}

          <Title level={4} className="text-center px-9 line-clamp-2">
            {parentItem ? getNameFromListItem(parentItem) : t("copy_files_modal_title")}
          </Title>
        </div>

        <Text level={2} className="text-gray-500">
          {t("copy_files_modal_description")}
        </Text>

        <SelectTileList
          data={items}
          selectionMode="single"
          onSelectionChange={handleSelectionChange}
          renderTile={SectionItem}
          handleNavigateDown={handleNavigateDown}
          searchKeys={["item.name"]}
          searchData={searchData}
          onClearSearch={() => setIsSearching(false)}
          onSearch={() => setIsSearching(true)}
          isSearchable={shouldShowSearch}
        />
      </div>
      <Button
        size="lg"
        color="blue"
        variant="solid"
        disabled={!selectedSectionId}
        onClick={handleCopyItems}
        isLoading={isCopying}
        className={"w-1/2 self-center xs:w-full absolute bottom-6 sm:bottom-12"}
      >
        {t("copy_files_modal_copy_button")}
      </Button>
      {shouldShowError ? (
        <Text level={2} className="text-red-500 flex flex-col items-center p-6 w-full">
          {t("copy_files_modal_error")}
        </Text>
      ) : null}
    </InnerModalWrapper>
  );
});

const SectionItem = ({
  item,
  context,
}: EolasVersatileListRenderItemProps<ListItem, SelectTileListContext<ListItem>>) => {
  const {
    handleNavigateDown,
    selectedItems,
    handleAddToSelection,
    handleRemoveFromSelection,
    handleClearSelection,
  } = context;
  const isSelected = Boolean(selectedItems && selectedItems[item.id]);
  const hasSelection = Object.keys(selectedItems ?? {}).length > 0;

  const handleToggle = () => {
    if (isSelected) {
      handleRemoveFromSelection(item.id);
      return;
    }
    handleAddToSelection(item.id, item);
  };

  const handleClick = () => {
    if (item.childrenType === "sections" || item.childrenType === "mainSections") {
      // For this modal, we want to clear the selection when navigating
      if (hasSelection) {
        handleClearSelection();
      }
      handleNavigateDown(item);
      return;
    }

    handleToggle();
  };

  if (item.childrenType === "none") {
    // Files are not supported in this modal and will never be rendered
    return null;
  }

  if (item.childrenType === "mainSections") {
    const rootItem = item.item;
    return (
      <div className="pb-2">
        <EolasTile
          onClick={handleClick}
          variant="rectangular"
          primaryText={rootItem.title}
          icon={<RootIcon root={item.rootType} />}
          rightAction={<CaretRight className="h-6 w-6 text-grey-600" />}
        />
      </div>
    );
  }

  const sectionChildRef = item.item;

  return (
    <div className="pb-2">
      <EolasSectionTile
        isDisabled={item.isDisabled}
        onClick={handleClick}
        variant="withChildDetails"
        title={sectionChildRef.name || ""}
        iconUrl={getIconUrl(sectionChildRef.icon || "")}
        childrenType={item.childrenType}
        rightAction={
          item.childrenType === "sections" ? (
            <CaretRight className="h-6 w-6 text-grey-600" />
          ) : (
            <EolasRadio
              isDisabled={item.isDisabled}
              variant="checkCircle"
              size="xs"
              isChecked={isSelected}
              onToggle={handleToggle}
            />
          )
        }
      />
    </div>
  );
};

const getNameFromListItem = (listItem: ListItem): string => {
  const item = listItem.item;

  if (hasProp(item, "name")) {
    return item.name;
  }

  if (hasProp(item, "title")) {
    return item.title;
  }

  return "";
};
