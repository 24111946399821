import React from "react";

import { Title } from "../../Typography";
import { twMerge } from "tailwind-merge";

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  className?: string;
  titleClass?: string;
  subTitleClass?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subTitle,
  titleClass = "text-2xl lg:text-5xl",
  subTitleClass,
  className = "",
  ...props
}: PageTitleProps) => {
  return (
    <div className={twMerge("justify-center text-center space-y-3 w-full", className)} {...props}>
      {subTitle ? (
        <Title level={7} className={twMerge("text-black", subTitleClass)}>
          {subTitle}
        </Title>
      ) : null}

      <Title level={3} className={titleClass} data-testid="pageTitle">
        {title}
      </Title>
    </div>
  );
};
