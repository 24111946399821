import { useTranslation } from "react-i18next";

import { Text } from "UIKit";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";

export const ErrorComponent = () => {
  const { t } = useTranslation();

  return (
    <EolasWizardModalWrapper header={{ title: t("repository_error") }}>
      <div className="flex flex-col items-center justify-center width-full">
        <div className="mt-6">
          <Text level={1} className="text-red-500 text-center">
            {t("repository_component_error")}
          </Text>
        </div>
      </div>
    </EolasWizardModalWrapper>
  );
};
