import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { AdminFileTile as AdminFileTileProps } from "../EolasFileTile.types";
import { Dropdown } from "UIKit/FormElements/Dropdown/Dropdown";
import { OperationsIcon } from "Assets/Icons/monocolored";
import { IconButton } from "UIKit/Button/IconButton";
import { useTranslation } from "react-i18next";
import { formatDate } from "Utilities";
import { SelectFileToggle } from "UIKit/Button/SelectFileToggle";
import { LinkIcon } from "Assets";
import { Title } from "UIKit/Typography/Title";
import { useToolTip } from "Hooks/useToolTip";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { EolasTag } from "UIKit/EolasTag/EolasTag";

export const AdminFileTile = ({
  onClick,
  menuActions,
  title,
  expiryDate,
  iconComponent,
  isDisabled,
  isDraggable,
  status,
  rightLabelTagTooltipText,
  isSelected = false,
  onToggleSelect,
  onMenuActionSelect,
  isShadowCopy,
  isSearchResult = false,
  mainSectionId,
  parentSectionId,
}: AdminFileTileProps) => {
  const { t } = useTranslation();
  const shouldShowMenu = menuActions.length > 0;
  return (
    <EolasTile
      shouldShowDragHandle={isDraggable}
      isDisabled={isDisabled}
      variant="rectangular"
      primaryText={
        <>
          {isSearchResult && mainSectionId ? (
            <FileBreadcrumbs
              shouldShowSpaceName={false}
              mainSectionId={mainSectionId}
              parentId={parentSectionId}
              isFromSearch
            />
          ) : null}
          <PrimaryText title={title} isShadowCopy={isShadowCopy} />
        </>
      }
      secondaryText={
        expiryDate
          ? `${t("repository_item_expiry")}: ${formatDate(expiryDate, "do MMMM yyyy")}`
          : undefined
      }
      rightLabel={
        <RightLabelTags status={status} rightLabelTagTooltipText={rightLabelTagTooltipText} />
      }
      rightAction={
        shouldShowMenu ? (
          <Dropdown
            dropdownToggle={
              <IconButton
                type="button"
                variant="rounded"
                icon={<OperationsIcon />}
                className="bg-transparent"
              />
            }
            options={menuActions}
            onSelect={onMenuActionSelect}
          />
        ) : undefined
      }
      icon={iconComponent}
      onClick={onClick}
      leftAction={
        onToggleSelect ? (
          <SelectFileToggle isSelected={isSelected} onCheckboxClick={onToggleSelect} />
        ) : undefined
      }
    />
  );
};

const PrimaryText = ({
  title,
  isShadowCopy = false,
}: {
  title: string;
  isShadowCopy?: boolean;
}) => {
  const { t } = useTranslation();
  const { makeToolTipDataAttr } = useToolTip();

  const tooltipDataAttr = makeToolTipDataAttr({
    text: t("component_admin_file_tile_shadow_copy_tooltip"),
  });
  return (
    <div className="flex flex-row items-center gap-2">
      <Title level={8} className="line-clamp-2">
        {title}
      </Title>
      {isShadowCopy ? (
        <div>
          <LinkIcon className="h-5 w-5 text-grey-600" {...tooltipDataAttr} />
        </div>
      ) : null}
    </div>
  );
};

const RightLabelTags = ({
  status,
  rightLabelTagTooltipText = {},
}: {
  status: AdminFileTileProps["status"];
  rightLabelTagTooltipText: AdminFileTileProps["rightLabelTagTooltipText"];
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-2">
      {status === "unpublished" ? (
        <EolasTag
          color="grey"
          text={t("content_item_is_unpublished")}
          tooltipText={rightLabelTagTooltipText["unpublished"]}
        />
      ) : null}
      {status === "parallelDraft" ? (
        <EolasTag
          color="yellow"
          text={t("content_item_new_draft")}
          tooltipText={rightLabelTagTooltipText["parallelDraft"]}
        />
      ) : null}
      {status === "published" || status === "parallelDraft" ? (
        <EolasTag
          color="green"
          text={t("content_item_is_published")}
          tooltipText={rightLabelTagTooltipText["published"]}
        />
      ) : null}
    </div>
  );
};
