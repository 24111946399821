import {
  BlobContent,
  ContentItem,
  EolasEditorContent,
  EolasShadowCopyContent,
  FlashCardContent,
  KnowledgeContentDomain,
  LinkContent,
  QueryAllContentReturn,
  QuerySectionsReturn,
} from "@eolas-medical/core";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  UseMutateFunction,
} from "@tanstack/react-query";
import {
  AddContentItemDto,
  AddContentSectionDto,
  ContentRepository,
  ContentSection,
  EditContentSectionDto,
  EditFlashcardItemDto,
  AddFlashcardItemDto,
  AddEolasEditorItemDto,
  EditEolasEditorItemDto,
} from "modules/contentRepository/types";
import { DropResult } from "react-beautiful-dnd";

import { useBulkDeleteItems } from "modules/contentRepository/data/useBulkDeleteItems";
import { makeShouldIncludeSectionHelpers } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";

export interface UseContentRepositoryHook {
  contentRepository?: ContentRepository;
  contentRepositoryLoading: boolean;
}
export interface UseContentItemsHook {
  contentItems: ContentItem[];
  contentItemsLoading: boolean;
  hasMoreItems?: boolean;
  fetchMoreItems?: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<QueryAllContentReturn, unknown>>;
  isFetchingMoreItems?: boolean;
}

export interface UseContentSectionsHook {
  contentSections: ContentSection[];
  contentSectionsLoading: boolean;
  hasMoreSections?: boolean;
  fetchMoreSections?: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<QuerySectionsReturn, unknown>>;
  isFetchingMoreSections?: boolean;
}

export interface UseContentSectionHook {
  contentSection?: ContentSection;
  contentSectionLoading: boolean;
}

export interface UseAddContentSectionHook {
  addContentSection: UseMutateFunction<void, unknown, AddContentSectionDto, unknown>;
  addingContentSection: boolean;
  addContentSectionSuccess: boolean;
  addContentSectionError: string;
}

export interface UseEditContentSectionHook {
  editContentSection: UseMutateFunction<void, unknown, EditContentSectionDto, unknown>;
  editingContentSection: boolean;
  editContentSectionSuccess: boolean;
  editContentSectionError: string;
}

export interface UseDeleteContentSectionHook {
  deleteContentSection: UseMutateFunction<void, unknown, ContentSection, unknown>;
  deletingContentSection: boolean;
  deleteContentSectionSuccess: boolean;
  deleteContentSectionError: string;
}

export interface UseAddContentItemHook {
  addContentItem: UseMutateFunction<void, unknown, AddContentItemDto, unknown>;
  addingContentItem: boolean;
  addContentItemSuccess: boolean;
  addContentItemError: string;
  addContentItemProgress: number;
}

export interface UseAddFlashcardItemHook {
  addFlashcardItem: UseMutateFunction<void, unknown, AddFlashcardItemDto, unknown>;
  addingFlashcardItem: boolean;
  addFlashcardItemSuccess: boolean;
  addFlashcardItemError: string;
  addFlashcardItemProgress: number;
}

export type UseAddEolasEditorItemHook = {
  addEolasEditorItem: UseMutateFunction<void, unknown, AddEolasEditorItemDto, unknown>;
  addingEolasEditorItem: boolean;
  addEolasEditorItemSuccess: boolean;
  addEolasEditorItemError: string;
  addEolasEditorItemProgress: number;
};

export interface UseEditEolasEditorItemHook {
  editEolasEditorItem: UseMutateFunction<void, unknown, EditEolasEditorItemDto, unknown>;
  editingEolasEditorItem: boolean;
  editEolasEditorItemSuccess: boolean;
  editEolasEditorItemError: string;
  editEolasEditorItemProgress: number;
}

export interface UseEditFlashcardItemHook {
  editFlashcardItem: UseMutateFunction<void, unknown, EditFlashcardItemDto, unknown>;
  editingFlashcardItem: boolean;
  editFlashcardItemSuccess: boolean;
  editFlashcardItemError: string;
  editFlashcardItemProgress: number;
}

export interface UseEditContentItemHook {
  editContentItem: UseMutateFunction<
    void,
    unknown,
    {
      contentItem: ContentItem;
      editItem: Partial<BlobContent | LinkContent>;
      blob?: File;
    },
    unknown
  >;
  editingContentItem: boolean;
  editContentItemSuccess: boolean;
  editContentItemError: string;
  editContentItemProgress: number;
}

export interface UseDeleteContentItemHook {
  deleteContentItem: UseMutateFunction<void, unknown, ContentItem, unknown>;
  deletingContentItem: boolean;
  deleteContentItemSuccess: boolean;
  deleteContentItemError: string;
}

export type UseBulkDeleteContentItemHook = ReturnType<typeof useBulkDeleteItems>;

export interface UseReorderContentHook {
  reorderContent: (dropResult: DropResult) => unknown;
}

export interface UseSearchContentSectionsHook {
  searchedSections: ContentSection[];
  searchingSections: boolean;
  searchValue: string;
  searchInput: string;
  onClearSearch: () => void;
  onSearch: () => void;
  onSearchInputChange: (text: string) => void;
}

export interface UserSearchContentItemsHook {
  searchedItems: ContentItem[];
  searchingItems: boolean;
  searchValue: string;
  searchInput: string;
  onClearSearch: () => void;
  onSearch: () => void;
  onSearchInputChange: (text: string) => void;
}

type ContentItemConfig = {
  /**
   * @default false
   */
  shouldAllowSharing?: boolean;
  /**
   * @default false
   */
  shouldAllowFavourites?: boolean;
  /**
   * @default false
   */
  shouldHideExpiryDate?: boolean;
  /**
   * @default false
   */
  shouldShowBreadcrumbs?: boolean;
};

type ContentSectionConfig = {
  /**
   * @default false
   */
  canDownloadSectionMap?: boolean;
  /**
   * @default false
   */
  shouldShowBreadcrumbs?: boolean;
  /**
   * @default false
   */
  shouldAllowSharing?: boolean;
};

export enum ContentItemParams {
  shadowCopyContentId = "shadowCopyContentId",
  fileIdOfDraftFile = "fileIdOfDraftFile",
  shouldShowBreadcrumbs = "shouldShowBreadcrumbs",
}

export type BaseContentItemSearchParams = {
  [ContentItemParams.shadowCopyContentId]?: string;
  [ContentItemParams.fileIdOfDraftFile]?: string;
  [ContentItemParams.shouldShowBreadcrumbs]?: string;
};

type BaseGetUrlParams<T extends object> = {
  versionNo?: number;
} & BaseContentItemSearchParams &
  T;

type ContextDomain = "knowledge" | "spaceOrg";

export type CommonContentRepositoryContextValueProperties = {
  useContentRepositoryHook: UseContentRepositoryHook;
  useContentItemsHook: UseContentItemsHook;
  useContentSectionsHook: UseContentSectionsHook;
  useContentSectionHook: UseContentSectionHook;
  useAddContentSectionHook: UseAddContentSectionHook;
  useEditContentSectionHook: UseEditContentSectionHook;
  useDeleteContentSectionHook: UseDeleteContentSectionHook;
  useAddContentItemHook: UseAddContentItemHook;
  useAddFlashcardItemHook: UseAddFlashcardItemHook;
  useAddEolasEditorItemHook: UseAddEolasEditorItemHook;
  useEditContentItemHook: UseEditContentItemHook;
  useEditFlashcardItemHook: UseEditFlashcardItemHook;
  useEditEolasEditorItemHook: UseEditEolasEditorItemHook;
  useDeleteContentItemHook: UseDeleteContentItemHook;
  useReorderContentHook: UseReorderContentHook;
  useSearchContentSectionsHook: UseSearchContentSectionsHook;
  useSearchContentItemsHook: UserSearchContentItemsHook;
  useBulkDeleteItemsHook: UseBulkDeleteContentItemHook;
  getBlobViewerUrl: (params: BaseGetUrlParams<{ blob: BlobContent }>) => string;
  getFlashcardViewerUrl: (params: BaseGetUrlParams<{ flashcard: FlashCardContent }>) => string;
  onClickContentSection?: (section: ContentSection) => void;
  getEolasEditorViewerUrl: (
    params: BaseGetUrlParams<{ eolasEditor: EolasEditorContent }>,
  ) => string;
  /** Only used and setup within Knowledge content items at time of writing */
  onLoadContentItem?: (item: ContentItem) => void;
  limitedAccessHelpers?: ReturnType<typeof makeShouldIncludeSectionHelpers>;
  isSubsection?: boolean;
  canOnlyCreateFileSection?: boolean;
  shouldAllowBlankHeroImageSelection?: boolean;
  hasCopilotData?: boolean;
  contentItemConfig?: ContentItemConfig;
  contentSectionConfig?: ContentSectionConfig;
  shadowCopyContentItem?: EolasShadowCopyContent;
  setShadowCopyContentItem?: (item: EolasShadowCopyContent) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type BaseContentRepositoryContextValue<D extends ContextDomain, P extends {} = {}> = {
  contextDomain: D;
} & CommonContentRepositoryContextValueProperties &
  P;

export type KnowledgeContentRepositoryContextValue = BaseContentRepositoryContextValue<
  "knowledge",
  {
    knowledgeContentDomain: KnowledgeContentDomain;
  }
>;

export type SpaceOrgContentRepositoryContextValue = BaseContentRepositoryContextValue<"spaceOrg">;

export type ContentRepositoryContextValue =
  | KnowledgeContentRepositoryContextValue
  | SpaceOrgContentRepositoryContextValue;
