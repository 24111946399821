import { DsmError, stripString } from "./helpers";
import { PermutationsDataBare, QaDataBare, WorkBook } from "./types";
import {
  AnswersMap,
  compareWithNumbers,
  ConditionsMap,
  DsmData,
  InfoMap,
  PermutationLookupMap,
  QuestionsAndAnswersMap,
  QuestionType,
  StringNumber,
} from "@eolas-medical/core";
import {
  validateAndGetConditionData,
  validateAndGetInfoData,
  validateAndGetPermutations,
  validateAndGetQuestionsAndAnswers,
  validateAndGetSummary,
  validateBasedOnSummaryData,
} from "./validators";

type ValidatedData = {
  qaData: QaDataBare;
  permutationsData: PermutationsDataBare;
  conditionMap: ConditionsMap;
  infoMap: InfoMap;
  expectedQuestionsNo: number;
  expectedPermutations: number;
};

type PartialDsmData = Pick<
  DsmData,
  "questionNumberToQuestionsDataMap" | "questionSelectionToRegimenLookupMap"
>;

export const parseWorkbook = (workbook: WorkBook): DsmData => {
  const summaryData = validateAndGetSummary(workbook);
  const qaData = validateAndGetQuestionsAndAnswers(workbook);
  const permutationsData = validateAndGetPermutations(workbook);
  const infoData = validateAndGetInfoData(workbook);
  const conditionData = validateAndGetConditionData(workbook);

  const { name, expectedQuestionsNo, expectedPermutations, expectedRegimens, questionType } =
    validateBasedOnSummaryData({
      summaryData,
      qaData,
      infoData,
      permutationsData,
    });

  const conditionMap = conditionData.reduce<ConditionsMap>((acc, current) => {
    const [regimen, text] = current;
    return { ...acc, [regimen.toString()]: text };
  }, {});

  const infoMap = infoData.reduce<InfoMap>((acc, current) => {
    const [qNumber, info] = current;
    const formattedInfo = info ? stripString(info) : info;
    if (qNumber && info && formattedInfo) {
      return { ...acc, [qNumber.toString()]: info };
    }
    return acc;
  }, {});

  if (Object.keys(conditionMap).length !== expectedRegimens) {
    throw new DsmError(
      "Computed regimens number does not equal expected number from summary sheet",
      "unexpectedData",
    );
  }

  const validatedData: ValidatedData = {
    qaData,
    permutationsData,
    expectedQuestionsNo,
    conditionMap,
    infoMap,
    expectedPermutations,
  };

  const data =
    questionType === QuestionType.list
      ? parseListQuestionType(validatedData)
      : parseDefaultQuestionType(validatedData);

  if (Object.keys(data.questionSelectionToRegimenLookupMap).length !== expectedPermutations) {
    throw new DsmError(
      "Computed questionSelectionToRegimenLookupMap number does not equal expected number from summary sheet",
      "unexpectedData",
    );
  }

  return {
    name,
    ...data,
    regimenToResultStringMap: conditionMap,
  };
};

const parseDefaultQuestionType = ({
  infoMap,
  qaData,
  permutationsData,
  conditionMap,
  expectedQuestionsNo,
}: ValidatedData): PartialDsmData => {
  const questionNumberToQuestionsDataMap = qaData.reduce<QuestionsAndAnswersMap>((acc, current) => {
    const [qNo, , question, ...rest] = current;
    const answers = rest
      .filter((item) => {
        return (
          typeof item === "number" || Boolean(typeof item === "string" ? stripString(item) : item)
        );
      })
      .reduce<AnswersMap>((answersAcc, answersCurrent, index) => {
        const formattedAnswer = answersCurrent.toString().trim();
        const stringAnswer: StringNumber = `${index + 1}`;
        return { ...answersAcc, [formattedAnswer]: stringAnswer };
      }, {});
    const questionNumber: StringNumber = `${qNo}`;
    const info = infoMap[questionNumber];
    return {
      ...acc,
      [qNo.toString()]: { qType: "", question, answers, info: info ?? "" },
    };
  }, {});

  const questionSelectionToRegimenLookupMap: PermutationLookupMap = {};

  for (let i = 0; i < permutationsData.length; i += 1) {
    const [regimen, ...rest] = permutationsData[i].slice(0, expectedQuestionsNo + 1);
    const stringRegimen = `${regimen}` as StringNumber; // Cast here as TS is not recognising the tuple. Index 0 is always a number
    if (!conditionMap[stringRegimen]) {
      throw new DsmError(
        `Incorrect regimen code in row ${i + 1} in Permutations sheet`,
        "unexpectedData",
      );
    }
    const permutationLookupString = rest
      .map((item, index) => {
        const qNo: StringNumber = `${index + 1}`;
        const data = questionNumberToQuestionsDataMap[qNo];
        if (!data) {
          throw new DsmError(
            `Unable to find question ${qNo} in questions and answers sheet for row ${
              i + 1
            } in Permutations sheet`,
            "unexpectedData",
          );
        }
        const { answers } = data;
        const formattedItem = item.toString().trim();
        if (answers[formattedItem] === undefined) {
          throw new DsmError(
            `Unable to match answer in permutations row ${i + 1} with answers from question ${qNo}`,
            "unexpectedData",
          );
        }
        return answers[formattedItem];
      })
      .join();

    questionSelectionToRegimenLookupMap[permutationLookupString] = stringRegimen;
  }

  return {
    questionNumberToQuestionsDataMap,
    questionSelectionToRegimenLookupMap,
  };
};

const parseListQuestionType = ({
  infoMap,
  conditionMap,
  qaData,
  permutationsData,
  expectedQuestionsNo,
  expectedPermutations,
}: ValidatedData): PartialDsmData => {
  const questionNumberToQuestionsDataMap = qaData.reduce<QuestionsAndAnswersMap>((acc, current) => {
    const [qNo, , question] = current;
    const questionNumber: StringNumber = `${qNo}`;
    const info = infoMap[questionNumber];
    return {
      ...acc,
      [qNo.toString()]: { qType: QuestionType.list, question, answers: {}, info: info ?? "" },
    };
  }, {});

  const sharedAnswers: Record<string, string> = {};

  for (let i = 0; i < permutationsData.length; i += 1) {
    const [_, ...permutations] = permutationsData[i].slice(0, expectedQuestionsNo + 1);
    for (const permutation of permutations) {
      const stringified = permutation.toString().trim();
      sharedAnswers[stringified] = stringified;
    }
  }

  const sharedAnswersMap: AnswersMap = Object.keys(sharedAnswers)
    .sort(compareWithNumbers)
    .reduce<AnswersMap>((acc, current, index) => {
      return { ...acc, [current]: `${index + 1}` };
    }, {});

  // As answers list is identical for all questions, every answer list combination must exist:
  const calculatedPermutations = Math.pow(
    Object.values(sharedAnswersMap).length,
    expectedQuestionsNo,
  );

  if (calculatedPermutations !== expectedPermutations) {
    throw new DsmError(
      `Calculated permutations as ${calculatedPermutations}, declared to be ${expectedPermutations}`,
      "unexpectedData",
    );
  }

  const questionSelectionToRegimenLookupMap: PermutationLookupMap = {};

  for (let i = 0; i < permutationsData.length; i += 1) {
    const [regimen, ...permutations] = permutationsData[i].slice(0, expectedQuestionsNo + 1);
    const stringRegimen = `${regimen}` as StringNumber; // Cast here as TS is not recognising the tuple. Index 0 is always a number
    if (!conditionMap[stringRegimen]) {
      throw new DsmError(
        `Incorrect regimen code in row ${i + 1} in Permutations sheet`,
        "unexpectedData",
      );
    }
    const permutationLookupStringArray: string[] = [];

    for (let i = 0; i < permutations.length; i += 1) {
      const qNo: StringNumber = `${i + 1}`;
      const data = questionNumberToQuestionsDataMap[qNo];
      if (!data) {
        throw new DsmError(
          `Unable to find question ${qNo} in questions and answers sheet for row ${
            i + 1
          } in Permutations sheet`,
          "unexpectedData",
        );
      }

      // For list type, answers for every question are shared:
      data.answers = sharedAnswersMap;

      const item = permutations[i];
      const formattedItem = item.toString().trim();
      if (sharedAnswersMap[formattedItem] === undefined) {
        // This error shouldn't happen, as we have computed these:
        throw new DsmError(
          `Unable to match answer in permutations row ${i + 1} with answers from question ${qNo}`,
          "unexpectedData",
        );
      }
      permutationLookupStringArray.push(sharedAnswersMap[formattedItem]);
    }

    const permutationLookupString = permutationLookupStringArray.join();

    questionSelectionToRegimenLookupMap[permutationLookupString] = stringRegimen;
  }

  return {
    questionNumberToQuestionsDataMap,
    questionSelectionToRegimenLookupMap,
  };
};
