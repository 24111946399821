import { CommunityLevelSection } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { errorStore } from "Stores/ErrorStore";
import { LDFlagNames } from "Utilities/types";
import { extractWikEmParams } from "deeplinking/functions/helpers";
import { DeepLinkTypeHandlerResponse } from "deeplinking/types/general.types";
import React from "react";

export const useWikEmDeeplinks = () => {
  const media = useMediaQuery();
  const { flags } = useLaunchDarkly();
  const isMobile = media === "xs";
  const shouldDirectToMobileFlag =
    flags[LDFlagNames.SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE] ?? false;

  const handleWikemDeeplink = React.useCallback(
    (searchString: string, type: CommunityLevelSection.wikiEm): DeepLinkTypeHandlerResponse => {
      const wikemParams = extractWikEmParams(searchString, type);

      if (wikemParams) {
        if (isMobile && shouldDirectToMobileFlag) {
          return "DISPLAY_DIRECT_TO_MOBILE";
        } else {
          return "PROCEED_TO_DEEPLINK";
        }
      }

      errorStore.captureError({
        error: new Error("Knowledge deeplink is missing or has invalid params"),
        source: "user",
        data: { searchString },
      });
      return "INVALID_PARAMS";
    },
    [isMobile, shouldDirectToMobileFlag],
  );

  return { handleWikemDeeplink };
};
