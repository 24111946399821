export type FindSpaceStep = "select-space" | "request-access" | "request-sent" | "no-trusted-email";

export enum AccessRequestStatus {
  APP_USER_CREATED = "APP_USER_CREATED",
  EXISTING_APP_USER = "EXISTING_APP_USER",
  NO_APP_ACCESS = "NO_APP_ACCESS",
  ACCESS_REQUESTED = "ACCESS_REQUESTED",
  VERIFIED_EMAIL_APP_USER_CREATED = "VERIFIED_EMAIL_APP_USER_CREATED",
  NO_TRUSTED_EMAIL = "NO_TRUSTED_EMAIL",
}

export type SearchSpaceResult = {
  appID: string;
  orgID: string;
  name: string;
};

export type SelectSpaceState = "your-spaces" | "discover-spaces";

export interface DefaultImage {
  url: string;
  isCustomImage: boolean;
}
