import { SponsoredInfoSection, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sponsoredSlotQueryKeys } from "./sponsoredSlots.queryKeys";

export const usePublishDraftSponsoredSlot = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: unknown) => unknown;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isLoading, isError } = useMutation({
    mutationFn: (section: SponsoredInfoSection) => {
      return knowledgeContentClient.updateSection({
        sectionId: section.id,
        updateSectionDto: { isDraft: false },
      });
    },
    onSuccess: (_, section) => {
      queryClient.invalidateQueries(
        sponsoredSlotQueryKeys.getAllSponsoredSlots(section.mainSectionId),
      );
      onSuccess();
    },
    onError,
  });

  return { publish: mutate, isSuccess, isLoadingPublish: isLoading, isError };
};
