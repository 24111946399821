import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppLevelSection } from "@eolas-medical/core";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { Button, Modal, Title } from "UIKit";
import { EolasChecklistSection } from "Utilities/types";
import {
  ActivateIcon,
  TrashCanIcon,
  ChecklistIcon,
  ArrowRightIcon,
  DeactivateIcon,
  MajorIncidentIcon,
} from "Assets";

import { useCompletedChecklistsButtons } from "../hooks";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getSpaceRoute } from "Pages/Spaces/helpers";

export interface CompletedChecklistsButtonsProps {
  hasIssues: boolean;
  isDisabled: boolean;
  checklist: EolasChecklistSection;
}

const ButtonHoverIcon = () => {
  return (
    <div
      className={`
        flex items-center justify-center h-8 w-8 rounded-full bg-darkblue-500 text-white
        transition-all
        absolute right-8 opacity-0 group-hover:right-5 group-hover:opacity-100
      `}
    >
      <ArrowRightIcon className="h-4 w-6" />
    </div>
  );
};

const buttonClassname = "group relative hover:bg-white hover:border-darkblue-500";
const childrenClassname = "justify-start space-x-4 sm:space-x-8";

export const CompletedChecklistsButtons: React.FC<CompletedChecklistsButtonsProps> = ({
  hasIssues,
  checklist,
  isDisabled,
}: CompletedChecklistsButtonsProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { selectedSpace } = useSpacesContext();
  const { id: checklistID, parentID: sectionID = "" } = checklist;
  const { modal, onCloseModal, onDeleteChecklist, showUnresolvedIssues, onDeactivateChecklist } =
    useCompletedChecklistsButtons(checklist, isDisabled);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Button
          size="lg"
          color="grey"
          variant="outline"
          data-testid="view-editChecklist"
          className={buttonClassname}
          childrenContainerClassname={childrenClassname}
          onClick={() => {
            push(
              `${getSpaceRoute(selectedSpace)}/content-management/${
                AppLevelSection.checklists
              }/${encodeURIComponent(sectionID as string)}/builder/${encodeURIComponent(
                checklistID,
              )}`,
            );
          }}
        >
          <ChecklistIcon className="w-8 h-8" />
          <Title level={8}>{t("checklist_editButton_view")}</Title>
          <ButtonHoverIcon />
        </Button>

        <Button
          size="lg"
          variant={"outline"}
          disabled={!hasIssues}
          className={tw(`
            ${buttonClassname}
            ${!hasIssues && "filter grayscale bg-grey-200 hover:bg-grey-200"}
          `)}
          onClick={showUnresolvedIssues}
          data-testid="resolveIssuesButton"
          color={hasIssues ? "red" : "grey"}
          weight={hasIssues ? "semibold" : "regular"}
          childrenContainerClassname={childrenClassname}
        >
          <MajorIncidentIcon className="w-8 h-8" />
          <Title level={8}>
            {t(`${hasIssues ? "checklist_editButton_issues" : "checklist_editButton_noIssues"}`)}
          </Title>

          {hasIssues && <ButtonHoverIcon />}
        </Button>

        <Button
          size="lg"
          color="grey"
          variant="outline"
          className={buttonClassname}
          onClick={onDeactivateChecklist}
          data-testid="activateChecklistButton"
          childrenContainerClassname={childrenClassname}
        >
          {isDisabled ? (
            <ActivateIcon className="h-8 w-8" />
          ) : (
            <DeactivateIcon className="h-8 w-8" />
          )}
          <Title level={8}>
            {t(
              `${isDisabled ? "checklist_editButton_activate" : "checklist_editButton_deactivate"}`,
            )}
          </Title>
          <ButtonHoverIcon />
        </Button>

        <Button
          size="lg"
          color="grey"
          variant="outline"
          onClick={onDeleteChecklist}
          className={buttonClassname}
          data-testid="deleteChecklistButton"
          childrenContainerClassname={childrenClassname}
        >
          <TrashCanIcon className="h-8 w-8" />
          <Title level={8}>{t("checklist_editButton_delete")}</Title>
          <ButtonHoverIcon />
        </Button>
      </div>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
