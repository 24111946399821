import { EolasFile, EolasFileWithRegions, nationalResourcesClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import communityKeys from "./community.queryKeys";

type Props = {
  id: string;
  isEnabled?: boolean;
};

export const useGetNationalResource = ({ id, isEnabled = true }: Props) => {
  const { data, isLoading, isError, error } = useQuery<EolasFile | EolasFileWithRegions>(
    communityKeys.resource(id),
    () => nationalResourcesClient.getItem(id),
    { enabled: isEnabled },
  );

  return {
    data,
    isLoading,
    error,
    isError,
  };
};
