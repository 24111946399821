import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  KnowledgeContentDomains,
  KnowledgeContentSection,
  knowledgeContentClient,
} from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import useBnfSections, { MedicationSubSectionWithId } from "modules/bnf/data/useBnfSections";

interface UseMedicationSections {
  medicationSections: MixedMedicationSubsection[];
  medicationSectionsLoading: boolean;
}

export type MixedMedicationSubsection = MedicationSubSectionWithId | KnowledgeContentSection;

const useMedicationSections = (medicationsMainSectionId: string): UseMedicationSections => {
  const { flags } = useLaunchDarkly();

  const isFullBnfEnabled = Boolean(flags[LDFlagNames.BNF_WEB]);
  const isSmpcEnabled = Boolean(flags[LDFlagNames.PHARMA_RESOURCES]);

  const { bnfSections, bnfSectionsLoading } = useBnfSections({ isFullBnfEnabled });

  const { data: smpcData, isInitialLoading } = useInfiniteQuery({
    queryKey: ["medicationsSmpcSections"],
    queryFn: ({ pageParam }) =>
      knowledgeContentClient.getSectionsByParentId({
        parentId: medicationsMainSectionId,
        pageNumber: pageParam,
        domain: KnowledgeContentDomains.smpcs,
      }),
    getNextPageParam: (lastPage) => (lastPage?.nextPage ? lastPage.nextPage : undefined),
    staleTime: 20000,
    retry: false,
    enabled: Boolean(medicationsMainSectionId) && isSmpcEnabled,
  });

  const smpcSections: KnowledgeContentSection[] = useMemo(() => {
    if (!smpcData) {
      return [];
    }
    return smpcData.pages.flatMap((page) => page.sections) ?? [];
  }, [smpcData]);

  return {
    medicationSections: [...(isSmpcEnabled ? smpcSections : []), ...bnfSections],
    medicationSectionsLoading: isInitialLoading || bnfSectionsLoading,
  };
};

export default useMedicationSections;
