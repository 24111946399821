import { Presentation as BnfPresentation, Substance } from "@eolas-medical/core";
import { removeTags } from "modules/bnf/helpers";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import BnfPreparation from "../../../../components/BnfPreparation/BnfPreparation";
import { Divider, Text } from "UIKit";
import { Strings } from "./SubstanceComponent.strings";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";

type PresentationProps = { presentation: BnfPresentation };

export const SubstanceComponent = ({ substance }: { substance: Substance }) => {
  return (
    <Collapsible title={removeTags(substance.title)}>
      <div className="flex flex-col items-start py-2">
        {substance.introductionNote ? (
          <Text level={2} className="mb-2">
            {removeTags(substance.introductionNote)}
          </Text>
        ) : null}
        <Divider>
          {substance.presentations.length
            ? substance.presentations.map((pres, index) => {
                return <Presentation key={pres.formulation + index} presentation={pres} />;
              })
            : null}
        </Divider>
      </div>
    </Collapsible>
  );
};

const PREP_BASE_KEY = "eolas_prep";

const Presentation = ({ presentation }: PresentationProps) => {
  const specialCharacteristicsString = presentation.specialCharacteristics.join(". ");
  return (
    <div className="flex flex-col items-start">
      {presentation.formulation ? (
        <Detail title={Strings.formulation} content={presentation.formulation} />
      ) : null}
      <Energy presentation={presentation} />
      <DetailItem
        title={Strings.protein}
        amount={presentation.proteinGrams}
        units={Strings.g}
        constituents={presentation.proteinConstituents}
      />
      <DetailItem
        title={Strings.carbohydrate}
        amount={presentation.carbohydrateGrams}
        units={Strings.g}
        constituents={presentation.carbohydrateConstituents}
      />
      <DetailItem
        title={Strings.fat}
        amount={presentation.fatGrams}
        units={Strings.g}
        constituents={presentation.fatConstituents}
      />
      <DetailItem
        title={Strings.fibre}
        amount={presentation.fibreGrams}
        units={Strings.g}
        constituents={presentation.fibreConstituents}
      />
      {specialCharacteristicsString ? (
        <Detail title={Strings.specialCharact} content={specialCharacteristicsString} />
      ) : null}
      <div className="mb-2">
        <Indications presentation={presentation} />
      </div>
      {presentation.borderlineSubstancePreps.length ? (
        <Divider>
          <div className="mv-2">
            {presentation.borderlineSubstancePreps.map((prep, index) => {
              return <BnfPreparation key={PREP_BASE_KEY + index} prep={prep} />;
            })}
          </div>
        </Divider>
      ) : null}
    </div>
  );
};

const Energy = ({ presentation }: PresentationProps) => {
  const { energyKj, energyKCal } = presentation;
  if (!energyKCal && !energyKj) {
    return null;
  }
  let content = "";
  if (energyKj && !energyKCal) {
    content = `${energyKj} ${Strings.kj}`;
  } else if (!energyKj && energyKCal) {
    content = `${energyKCal} ${Strings.kcal}`;
  } else {
    content = `${energyKj} ${Strings.kj} / ${energyKCal} ${Strings.kcal}`;
  }

  return <Detail title={Strings.energy} content={content} />;
};

const DetailItem = ({
  title,
  amount,
  units,
  constituents,
}: {
  title: string;
  amount?: string;
  units: string;
  constituents: string[];
}) => {
  if (!amount) {
    return null;
  }
  const constituentString = " " + constituents.join(", ");
  const amountWithUnits = amount === Strings.nil ? Strings.nil : `${amount} ${units}`;
  const content = `${amountWithUnits}${constituentString}`;
  return <Detail title={title} content={content} />;
};

/* Frustratingly, some strings contain the title with bold tags, others don't. Nothing in the data
seems to indicate how to tell. Looks silly having a double title, so if detected by regex, aim is
to hide (a bit hacky I know) :
*/
const TITLE_STRING_MATCH = "indications:</b>";

const Indications = ({ presentation }: PresentationProps) => {
  if (!presentation.acbs.length) {
    return null;
  }
  const acbsString = presentation.acbs.join(". ");
  let title = presentation.isAcbs ? Strings.acbsInds : Strings.inds;
  if (acbsString.includes(TITLE_STRING_MATCH)) {
    title = "";
  }
  return (
    <div className="flex items-center rounded-md border-b-1 p-2 bg-grey-200">
      {title ? <Text level={2}>{title}</Text> : null}
      <BnfContentElement htmlContent={acbsString} addTags="div" className="ml-1" />
    </div>
  );
};

const Detail = ({ title, content }: { title?: string; content: string }) => {
  return (
    <div className="flex flex-col items-start">
      {title ? <Text level={3}>{title}</Text> : null}
      <BnfContentElement htmlContent={content} addTags="p" />
    </div>
  );
};
