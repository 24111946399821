import React from "react";
import { Button, Title, Text } from "UIKit";
import { AdminIcon, PiechartIcon, UserInviteIcon, ArrowRightIcon, GreenPadlockIcon } from "Assets";
import { useTranslation } from "react-i18next";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { ManageUsersModalState } from "../../ManageUsers";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";

const childrenClassname = "justify-start space-x-4";
const disabledClassName = "hidden border-none";

const ButtonHoverIcon: React.FC<{ className?: string; disabled?: boolean }> = ({
  className,
  disabled,
}: {
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={tw(`
        flex items-center justify-center h-8 w-8 rounded-full bg-darkblue-500 text-white
        transition-all
        absolute right-8 opacity-0 group-hover:right-5 group-hover:opacity-100
        ${disabled ? disabledClassName : ""}
        ${className}
      `)}
    >
      <ArrowRightIcon className="h-4 w-6" />
    </div>
  );
};

interface ManageUserButtonsProps {
  accessRequestsCount: number;
  setModalState: React.Dispatch<React.SetStateAction<ManageUsersModalState | null>>;
}
export const ManageUsersButtons: React.FC<ManageUserButtonsProps> = ({
  accessRequestsCount,
  setModalState,
}: ManageUserButtonsProps) => {
  const { t } = useTranslation();
  const { isPublic } = useGetRelevantSpaceDetails();
  const hasAccessRequests = accessRequestsCount > 0;
  const showAccessRequests = hasAccessRequests && !isPublic;

  const buttonClassname = `group relative rounded-md hover:bg-white ${
    isPublic ? "" : "hover:border-darkblue-500"
  }`;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
      <Button
        size="lg"
        color="grey"
        variant="outline"
        className={buttonClassname}
        childrenContainerClassname={childrenClassname}
        onClick={() => {
          setModalState("UserBreakdown");
        }}
      >
        <PiechartIcon className="w-8 h-8" />
        <Title level={8}>{t("manageUsers_accessRequest_showUserBreakdown")}</Title>
        <ButtonHoverIcon />
      </Button>

      <Button
        size="lg"
        color="grey"
        variant="outline"
        className={buttonClassname}
        childrenContainerClassname={childrenClassname}
        onClick={() => {
          setModalState("ManageAdmins");
        }}
      >
        <AdminIcon className="w-8 h-8" />
        <Title level={8}>{t("manageUsers_accessRequest_manageAdmin")}</Title>
        <ButtonHoverIcon />
      </Button>

      <Button
        size="lg"
        color="grey"
        variant="outline"
        className={buttonClassname}
        childrenContainerClassname={childrenClassname}
        onClick={() => {
          setModalState("InviteUsers");
        }}
      >
        <UserInviteIcon className="w-8 h-8" />
        <Title level={8}>{t("manageUsers_invite_users")}</Title>
        <ButtonHoverIcon />
      </Button>

      <Button
        size="lg"
        variant="outline"
        color={showAccessRequests ? "red" : "grey"}
        className={`
          ${buttonClassname}
          ${showAccessRequests ? "hover:bg-red-50 hover:border-red-500" : ""}
        `}
        childrenContainerClassname={childrenClassname}
        disabled={isPublic}
        onClick={() => {
          setModalState("AccessRequests");
        }}
      >
        <GreenPadlockIcon className="w-8 h-8" style={{ opacity: isPublic ? 0.1 : 1 }} />
        <Title level={8} className="text-left flex-1">
          {t("manageUsers_button_accessRequest")}
        </Title>

        {showAccessRequests && (
          <Text className="text-grey-600 font-semibold mr-2" level={1}>
            {accessRequestsCount}
          </Text>
        )}
        <ButtonHoverIcon className={showAccessRequests ? "bg-red-600" : ""} disabled={isPublic} />
      </Button>
    </div>
  );
};
