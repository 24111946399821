import { flashcardsAnimation } from "Assets";
import { Text } from "UIKit/Typography";
import React from "react";
import LottiePlayer from "react-lottie-player"; // Import from react-lottie-player

interface UploadFileStepProps {
  title: string;
  subtitle: string;
  descriptionText: string;
  bgColour?: string;
  animationsData?: object;
}

const TextWithLottie: React.FC<UploadFileStepProps> = ({
  title = "flashcard_lottie_intro",
  subtitle = "flashcard_lottie_subtitle",
  descriptionText = "flashcard_lottie_description",
  bgColour = "bg-blue-100",
  animationsData = flashcardsAnimation,
}: UploadFileStepProps) => {
  return (
    <div className={`flex flex-col lg:flex-row items-stretch rounded-md ${bgColour}`}>
      <div className="lg:order-2 lg:w-1/2 lg:ml-4 mb-4 lg:mb-0">
        <div className="100% flex justify-center align-middle py-4">
          <LottiePlayer
            loop
            play
            animationData={animationsData}
            style={{ width: "auto", height: "11rem" }}
          />
        </div>
      </div>
      <div className="lg:order-1 lg:w-1/2">
        <div className="p-4 lg:p-6 h-full flex flex-col justify-center space-y-2">
          <Text level={1} className="font-bold text-lg">
            {title}
          </Text>
          <Text level={2} className="text-grey-700 font-semibold text-xs">
            {subtitle}
          </Text>
          <Text level={2} className="text-xs">
            {descriptionText}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default TextWithLottie;
