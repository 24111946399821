import { isDefined } from "@eolas-medical/core";
import { twJoin } from "tailwind-merge";

type EolasCardProps = {
  children: React.ReactNode;
  elevation?: 1 | 2 | 3;
  borderStyle?: string;
  hasNoBorder?: boolean;
  hoverEffect?: "border" | "none";
  onClick?: (e: React.MouseEvent) => void;
  isDisabled?: boolean;
};

/**
 * EolasCard is a card component that can be used to wrap content.
 * Its designed to keep the styling consistent across the application.
 */
export const EolasCard = ({
  children,
  elevation = 1,
  borderStyle = "border-grey-300",
  hasNoBorder = false,
  hoverEffect: hoverEffectProp,
  onClick,
  isDisabled = false,
}: EolasCardProps) => {
  let shadow = "shadow-sm";
  let hoverEffectStyle = null;

  // If onClick is provided, hoverEffect will be "border" by default
  const hoverEffect = onClick && !isDefined(hoverEffectProp) ? "border" : hoverEffectProp;

  if (elevation === 2) {
    shadow = "shadow-md";
  } else if (elevation === 3) {
    shadow = "shadow-lg";
  }

  if (hoverEffect === "border") {
    hoverEffectStyle = "hover:shadow-focus";
  }

  if (isDisabled) {
    hoverEffectStyle = null;
  }

  const className = twJoin(
    "w-full",
    "bg-white",
    "rounded-lg",
    shadow,
    !hasNoBorder && `border ${borderStyle}`,
    !!hoverEffectStyle && hoverEffectStyle,
    !!onClick && !isDisabled && "cursor-pointer select-none",
  );

  return (
    <div className={className} onClick={isDisabled ? undefined : onClick}>
      {children}
    </div>
  );
};
