import { InteractionsSupplementaryInfo } from "@eolas-medical/core";
import { Divider, Text } from "UIKit";
import { removeTags } from "modules/bnf/helpers";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";

export const SupplementaryInfo = ({
  supplementaryInfo,
}: {
  supplementaryInfo?: InteractionsSupplementaryInfo[];
}) => {
  if (!supplementaryInfo || !supplementaryInfo.length) {
    return null;
  }

  return (
    <div className="container mx-auto border rounded-lg p-4 border-grey-500 w-full grey-100">
      <Divider>
        {supplementaryInfo.map((info, index) => (
          <div key={info.title + index}>
            <Text level={1} className="mb-3">
              {removeTags(info.title)}
            </Text>
            <BnfContentElement htmlContent={info.information} />
          </div>
        ))}
      </Divider>
    </div>
  );
};
