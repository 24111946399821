import { useCallback, useState } from "react";
import { EventType, AttachmentItem } from "modules/portfolio/types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { removeLastUrlPath } from "Utilities";
import useSubmitFeedback from "modules/portfolio/data/useSubmitFeedback";
import useDeclineFeedback from "modules/portfolio/data/useDeclineFeedback";
import { createErrorNotification } from "Pages/MeTab/pages/Portfolio/helpers";
import { useNotifications } from "Components/Notifications";
import { PortfolioErrorMessages } from "Pages/MeTab/pages/Portfolio/errors";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import useAttachmentDownload from "modules/portfolio/data/useAttachmentDownload";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import useDeleteFeedback from "modules/portfolio/data/useDeleteFeedback";
import { FeedbackFormUpdateInput } from "modules/portfolio/client/portfolio.graphql";

type FeedbackSubmissionModalState = "confirmSubmit" | "confirmDecline" | "confirmDelete" | null;

interface UseFeedbackActions {
  eventId: string;
  eventType: EventType;
  backUrl: string;
  feedbackId: string;
}

const useFeedbackActions = ({ eventId, eventType, backUrl, feedbackId }: UseFeedbackActions) => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { url } = useRouteMatch();
  const [modalState, setModalState] = useState<FeedbackSubmissionModalState>(null);
  const { showNotification, hideNotification } = useNotifications();
  const [deleteEvent, setDeleteEvent] = useState(false);

  const { submitFeedback, submittingFeedback } = useSubmitFeedback();
  const { declineFeedback, decliningFeedback } = useDeclineFeedback({
    onSuccess: () => {
      trackEvent(AnalyticsEvents.DECLINE_ASSESSOR, { eventType });
      replace(backUrl);
    },
    onError: () => {
      showNotification(createErrorNotification(PortfolioErrorMessages.DECLINE_FEEDBACK_ERROR));
      setModalState(null);
    },
  });
  const { deleteFeedback, deletingFeedback } = useDeleteFeedback({
    onSuccess: () => replace(backUrl),
    onError: () => {
      showNotification(createErrorNotification(PortfolioErrorMessages.DECLINE_FEEDBACK_ERROR));
      setModalState(null);
    },
  });

  const { download } = useAttachmentDownload();

  /**
   * Closes the modal that is opened
   */
  const handleCloseModal = useCallback(() => {
    setModalState(null);
  }, []);

  /**
   * Opens the ConfirmDecline modal
   */
  const handleClickDecline = useCallback(() => {
    setModalState("confirmDecline");
  }, []);

  /**
   * Opens the ConfirmSubmit modal
   */
  const handleClickSubmit = useCallback(() => {
    setModalState("confirmSubmit");
  }, []);

  /**
   * Opens the ConfirmDelete modal
   */
  const handleClickDelete = useCallback(() => {
    setModalState("confirmDelete");
    setDeleteEvent(false);
  }, []);

  const handleClickDeleteFeedbackAndEvent = useCallback(() => {
    handleClickDelete();
    setDeleteEvent(true);
  }, [handleClickDelete]);

  /**
   * Deletes the feedback
   */
  const handleDelete = useCallback(() => {
    deleteFeedback({ eventId, feedbackId, deleteEvent });
  }, [eventId, feedbackId, deleteFeedback, deleteEvent]);

  /**
   * Declines the feedback
   */
  const handleDecline = useCallback(() => {
    declineFeedback({ eventId, feedbackId });
  }, [eventId, feedbackId, declineFeedback]);

  /**
   * Submits the feedback
   */
  const handleConfirmSubmit = useCallback(
    (feedbackBody: FeedbackFormUpdateInput) => {
      submitFeedback(
        { eventId, feedbackId, feedbackBody },
        {
          onSuccess: () => {
            trackEvent(AnalyticsEvents.PORTFOLIO_FEEDBACK_SUBMITTED, { eventType });
            setModalState(null);
          },
          onError: () => {
            showNotification(createErrorNotification(PortfolioErrorMessages.SUBMIT_FEEDBACK_ERROR));
            setModalState(null);
          },
        },
      );
    },
    [eventId, eventType, feedbackId, submitFeedback, showNotification],
  );

  /**
   * Downloads an attachment from the event
   */
  const handleDownloadAttachment = useCallback(
    (attachment: AttachmentItem) => {
      if (attachment.blob?.attachmentId) {
        showNotification({
          type: "loading",
          title: t("portfolio_getting_attachment"),
          id: PortfolioConstants.ATTACHMENT_NOTIFICATION_ID,
        });
        download(attachment, {
          onSuccess: () => {
            trackEvent(AnalyticsEvents.ATTACHMENT_DOWNLOADED_FEEDBACK, {
              fileId: attachment.blob?.id,
            });
            hideNotification(PortfolioConstants.ATTACHMENT_NOTIFICATION_ID);
          },
          onError: () => {
            showNotification(
              createErrorNotification(PortfolioErrorMessages.DOWNLOAD_ATTACHMENT_ERROR),
            );
          },
        });
      }
    },
    [t, download, showNotification, hideNotification],
  );

  /**
   * Opens an attachment of the event.
   */
  const handleClickAttachment = useCallback(
    (attachment: AttachmentItem) => {
      if (attachment.link) {
        window.open(attachment.link.url, "_blank");
        return;
      }

      if (attachment.blob?.attachmentId) {
        window.open(
          `${removeLastUrlPath(url, 3)}/attachment/${attachment.blob?.attachmentId}`,
          "_blank",
        );
      }
      trackEvent(AnalyticsEvents.ATTACHMENT_OPENED_FEEDBACK, { fileId: attachment.blob?.id });
    },
    [url],
  );

  return {
    modalState,
    decliningFeedback,
    deletingFeedback,
    submittingFeedback,
    deleteEvent,
    onClickDecline: handleClickDecline,
    onClickDelete: handleClickDelete,
    onClickDeleteFeedbackAndEvent: handleClickDeleteFeedbackAndEvent,
    onDecline: handleDecline,
    onDelete: handleDelete,
    onClickSubmit: handleClickSubmit,
    onConfirmSubmit: handleConfirmSubmit,
    onCloseModal: handleCloseModal,
    onDownloadAttachment: handleDownloadAttachment,
    onClickAttachment: handleClickAttachment,
  };
};

export default useFeedbackActions;
