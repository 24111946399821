import { eolasLogger, hasProp } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { generateS3FileKey, generateS3PublicImageURL } from "Utilities"; // Removed unused import
import { LDFlagNames } from "Utilities/types";
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useCallback } from "react";

export const useHandleImageUpload = (contentRepositoryId: ContentRepositoryId) => {
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const handleImageUpload = useCallback(
    async (blobInfo: unknown): Promise<string> => {
      if (!isBlobInfo(blobInfo)) {
        throw new Error("Invalid blobInfo");
      }

      try {
        const file = blobInfo.blob();
        if (useAppServicesEndpoints) {
          const s3Params = {
            isPublic: true,
            fileName: hasProp(file, "name") ? file.name : blobInfo.name(),
            fileFormat: file.type,
            mainSectionId: contentRepositoryId,
            addExtension: false,
          };
          const key = generateS3FileKey(s3Params);
          await uploadFile(key, file);

          // If successful, return the image URL.
          return generateS3PublicImageURL(key);
        } else {
          const { publicUrl } = await uploadFileV2({
            file,
            mainSectionId: contentRepositoryId,
            isPublic: true,
          });

          if (!publicUrl) throw new Error("publicUrl missing when uploading image");

          return publicUrl;
        }
      } catch (error: unknown) {
        eolasLogger.error(error);
        throw new Error("Image upload failed. Please try again.");
      }
    },
    [contentRepositoryId, uploadFile, uploadFileV2, useAppServicesEndpoints],
  );

  return handleImageUpload;
};

const isBlobInfo = (
  blobInfo: unknown,
): blobInfo is { blob: () => Blob | File; name: () => string } => {
  if (hasProp(blobInfo, "blob") && hasProp(blobInfo, "name")) {
    return typeof blobInfo.blob === "function" && typeof blobInfo.name === "function";
  }

  return false;
};
