import {
  isMedicationMetadata,
  isNationalResourceMetadata,
  isNiceGuidelinesMetadata,
  fromAwsJSON,
} from "@eolas-medical/core";
import { KnowledgeResult } from "Components/MasterSearch/types";
import { ActiveTab } from "Utilities/types";
import { EntityItem } from "shared/functions/generateLinkToItem";

export function createEntityItemFromSearchResult(item: KnowledgeResult): EntityItem | null {
  const metadata = fromAwsJSON(item.knowledgeFile.metadata);

  if (isNationalResourceMetadata(metadata)) {
    return {
      ...item.knowledgeFile,
      id: metadata.nationalResourceFileId,
    };
  }

  if (isNiceGuidelinesMetadata(metadata)) {
    return metadata;
  }

  if (isMedicationMetadata(metadata)) {
    return metadata;
  }

  return null;
}

type MainSectionOwnerType = "space" | "community" | "organisation" | "department" | "hospital";

export function mainSectionOwnerTypeToActiveTab(ownerType: MainSectionOwnerType): ActiveTab {
  switch (ownerType) {
    case "space":
    case "department":
      return "spaces";
    case "community":
      return "knowledge";
    case "organisation":
    case "hospital":
      return "organisation";
  }
}
