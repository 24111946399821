import { Space } from "@eolas-medical/core";
import SpaceTile from "../../../SpaceTile/SpaceTile";
import { useSelectSpaceActions } from "../../../../hooks/useSelectSpaceActions";
export interface SpaceItemProps {
  space: Space;
}

export const SpaceItem: React.FC<SpaceItemProps> = ({ space }: SpaceItemProps) => {
  const { onSelectSpace, shouldAllowAccessLoading } = useSelectSpaceActions();

  const handleClickSpace = () => {
    onSelectSpace(space.id);
  };

  return (
    <SpaceTile
      name={space.name}
      description={space.description}
      membersCount={space.userCount}
      imageUrl={space.image?.url}
      specialties={space.specialty || []}
      onClick={handleClickSpace}
      isLoading={shouldAllowAccessLoading}
    />
  );
};
