import { isUrlValid } from "Utilities";
import * as yup from "yup";

export const useNameAndUrlStepValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().required("Name is required").notOneOf([""], "Name cannot be empty"),
    url: yup
      .string()
      .required("A URL is required")
      .test("validUrl", "Please enter a valid URL", function (newValue) {
        const isValid = newValue ? isUrlValid(newValue) : false;
        return isValid;
      }),
  });
};
