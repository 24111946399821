import { Text } from "UIKit";
import React from "react";

interface SpecialtiesListProps {
  specialties: string[];
}

const SpecialtiesList = ({ specialties }: SpecialtiesListProps) => {
  return (
    <div className="flex gap-0 md:gap-0.5 lg:gap-1 flex-wrap justify-start">
      {specialties?.map((s, i) => (
        <div key={`${s}-${i}`} className="flex space-x-2 items-center">
          <Text level={3} className="text-grey-700">
            {s}
          </Text>
          {i < specialties.length - 1 && <span className="text-blue-300"> • </span>}
        </div>
      ))}
    </div>
  );
};

export default SpecialtiesList;
