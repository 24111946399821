import { ChildReference, fileStore, sectionStore } from "@eolas-medical/core";

import { fromAwsJSON } from "Utilities";
import { EolasChecklist } from "Utilities/types";

export const useGetCompletedChecklists = (checklistID: string) => {
  const checklist = sectionStore.getSection(checklistID);
  const { metadata, childrenOrder } = checklist;
  const { disabled } = sectionStore.getChildReferenceOfSection(checklistID) ?? {};
  const { checklistTemplate }: { checklistTemplate: EolasChecklist } =
    typeof metadata === "string" ? fromAwsJSON(metadata ?? "{}") : metadata;

  const completedChecklists = childrenOrder
    ? fileStore.getFiles(childrenOrder as ChildReference[]).map((file) => ({
        ...file,
        metadata: fromAwsJSON(file.metadata || "{}")?.completedChecklist,
      }))
    : [];

  const hasIssues = completedChecklists.some(
    (checklistFile) => checklistFile.metadata.checklistIssues,
  );

  return {
    hasIssues,
    checklist,
    completedChecklists,
    isDisabled: disabled === "true",
    checklistName: checklistTemplate?.checklistName ?? "Checklist",
  };
};
