import { useState, useCallback } from "react";
import { SupportedFileType, userStore } from "@eolas-medical/core";
import { ConfigureFileValues, LinkFormValues } from "UIKit/Wizard";
import { AddItemModalState, AddPatientLeafletWizardStep, PatientLeafletProps } from "../types";
import { isFile, isLink } from "Pages/Spaces/helpers";
import useAddContentItem from "modules/contentRepository/data/useAddContentItem";
import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { MediaUploadParams } from "modules/contentRepository/types";
import { useRefetchAppData } from "Hooks";

interface AddPatientLeafletActionsProps {
  onClose: () => void;
  patientLeafletProps: PatientLeafletProps;
}

export const useAddPatientLeafletActions = ({
  onClose,
  patientLeafletProps,
}: AddPatientLeafletActionsProps) => {
  const [wizardState, setWizardState] = useState<AddItemModalState>({
    selectedType: "blob",
  });

  const user = userStore.userData;
  const { activeTab } = useEolasNavigation();
  const ownerType =
    activeTab === "organisation" ? EntityOwnerType.ORGANISATION : EntityOwnerType.SPACE;

  const mediaUploadParams: MediaUploadParams = {
    ownerType,
    isPublicItem: true,
    isOrganisationFile: activeTab === "organisation",
  };

  const { refetch } = useRefetchAppData();

  const {
    addContentItem,
    addingContentItem,
    addContentItemSuccess,
    addContentItemError,
    addContentItemProgress,
  } = useAddContentItem({
    mainSectionId: patientLeafletProps.mainSectionId,
    parentId: patientLeafletProps.parentId,
    mediaUploadParams,
    onSuccess: async () => refetch(),
  });

  const handleSelectFileType = (type: SupportedFileType) => {
    setWizardState((prevState) => ({
      ...prevState,
      selectedType: type,
    }));
  };

  const handleSubmitFileType = (
    type: SupportedFileType,
    result?: File | LinkFormValues,
    isDraft = false,
  ) => {
    if (type === "blob" && isFile(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        file: {
          ...prevState?.file,
          blob: result,
        },
      }));
    }

    if (type === "link" && isLink(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        link: {
          name: result.name,
          url: result.url,
          keywords: result?.keywords,
        },
      }));

      addContentItem(
        {
          name: result.name,
          itemType: "link",
          keywords: result.keywords ? result.keywords.split(",") : undefined,
          linkUrl: result.url,
          createdBy: user.id || "",
          isDraft,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  const handleAddFileDetails = useCallback(
    (data: ConfigureFileValues) => {
      setWizardState((prevState) => ({
        ...prevState,
        file: {
          ...prevState.file,
          name: data.name,
          expiryDate: data.expiryDate,
          keywords: data.keywords,
        },
      }));

      addContentItem({
        name: data.name,
        expiryDate: data.expiryDate,
        keywords: data.keywords.split(","),
        itemType: "blob",
        file: wizardState.file?.blob,
        createdBy: user.id || "",
        isDraft: data.isDraft,
      });
    },
    [addContentItem, wizardState, user.id],
  );

  const getNextStep = (currentStep: AddPatientLeafletWizardStep): AddPatientLeafletWizardStep => {
    if (currentStep === "upload-item" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-progress";
    }

    return "upload-item";
  };

  const getPrevStep = (currentStep: AddPatientLeafletWizardStep): AddPatientLeafletWizardStep => {
    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-item";
    }

    if (currentStep === "upload-progress" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    return "upload-item";
  };

  return {
    wizardState,
    addContentItemError,
    addingContentItem,
    addContentItemSuccess,
    addContentItemProgress,
    getNextStep,
    getPrevStep,
    onSelectType: handleSelectFileType,
    onSubmitFileType: handleSubmitFileType,
    onAddFileDetails: handleAddFileDetails,
  };
};
