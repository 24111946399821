import { useState } from "react";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { FlashcardFormStep } from "../../AddFlashcardItemWizard/hooks/useFormStepConfig";
import {
  FlashCardContent,
  FlashCardHeroImage,
  SpecialtyValue,
  isCopilotEnrichedFlashcardMetadata,
  parseFlashcardFileMetadata,
} from "@eolas-medical/core";
import { isItemChanged } from "Utilities/helpers";

interface UseAddContentItemProps {
  onClose: () => void;
  flashcard: FlashCardContent;
}

export interface EditItemModalState {
  title?: string;
  description?: string | null;
  specialty?: SpecialtyValue[];
  queriesForHit?: string[];
  documentIdentifiers?: string[];
  content?: string;
  heroImageUrl?: string;
  image?: File;
}

export const useEditContentItemActions = ({ flashcard, onClose }: UseAddContentItemProps) => {
  let documentIdentifiers: string[] | null = null;
  let queriesForHit: string[] | null = null;

  if (flashcard.metadata && isCopilotEnrichedFlashcardMetadata(flashcard.metadata)) {
    ({ documentIdentifiers, queriesForHit } = flashcard.metadata);
  }

  const [wizardState, setWizardState] = useState<EditItemModalState>({
    title: flashcard.name,
    description: flashcard.description,
    content: flashcard.metadata
      ? parseFlashcardFileMetadata(flashcard.metadata)?.flashcardContent
      : undefined,
    queriesForHit: queriesForHit || undefined,
    documentIdentifiers: documentIdentifiers || undefined,
  });

  const { useEditFlashcardItemHook, shouldAllowBlankHeroImageSelection } =
    useContentRepositoryContext();
  const {
    editFlashcardItem,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
  } = useEditFlashcardItemHook;

  const handleEditFlashcardDetails = (data: FlashcardFormStep) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        ...data,
        specialty: data.specialty ? data.specialty?.map((s) => s.value) : [],
        queriesForHit: data.queriesForHit
          ? data.queriesForHit.map((item) => item.value)
          : undefined,
        documentIdentifiers: data.documentIdentifiers
          ? data.documentIdentifiers.map((item) => item.value)
          : undefined,
      };
    });
  };

  const handleEditFlashcardContent = (content: string) => {
    setWizardState((prevState) => ({
      ...prevState,
      content,
    }));
  };

  const handleEditFlashcardImage = ({ image, imageUrl }: { image?: File; imageUrl?: string }) => {
    let heroImageUrl = imageUrl || "";
    if (shouldAllowBlankHeroImageSelection && !image && !imageUrl) {
      heroImageUrl = FlashCardHeroImage.blankImage;
    }
    setWizardState((prevState) => ({
      ...prevState,
      image,
      heroImageUrl,
    }));
    return heroImageUrl;
  };

  const getEditItem = ({
    heroImageUrl,
    summaryOfChanges,
  }: {
    heroImageUrl?: string;
    summaryOfChanges?: string;
  }) => {
    return {
      name: wizardState?.title,
      description: wizardState?.description || undefined,
      flashcardContent: wizardState?.content,
      imageUrl: heroImageUrl ? heroImageUrl : undefined,
      specialty: wizardState?.specialty,
      queriesForHit: wizardState?.queriesForHit,
      documentIdentifiers: wizardState?.documentIdentifiers,
      summaryOfChanges,
    };
  };

  const handleSubmitFlashcardImageStep = async ({
    image,
    imageUrl,
    summaryOfChanges,
  }: {
    image?: File;
    imageUrl?: string;
    summaryOfChanges?: string;
  }) => {
    const heroImageUrl = handleEditFlashcardImage({ image, imageUrl });

    const editItem = getEditItem({ heroImageUrl, summaryOfChanges });

    editFlashcardItem({
      flashcard,
      editItem: editItem,
      file: image,
    });
  };

  const handleUpdateWithSummaryOfChanges = ({
    summaryOfChanges,
  }: {
    summaryOfChanges?: string;
  }) => {
    return handleSubmitFlashcardImageStep({
      summaryOfChanges,
      image: wizardState.image,
      imageUrl: wizardState.heroImageUrl,
    });
  };

  const onSubmitImage = ({
    image,
    imageUrl,
    canAddSummaryOfChanges,
  }: {
    image?: File;
    imageUrl?: string;
    canAddSummaryOfChanges: boolean;
  }) => {
    const heroImageUrl = handleEditFlashcardImage({ image, imageUrl });

    const editItem = getEditItem({ heroImageUrl });

    if (
      !editItem.queriesForHit &&
      !editItem.documentIdentifiers &&
      !isItemChanged({
        item: {
          ...flashcard,
          metadata: parseFlashcardFileMetadata(flashcard.metadata)?.flashcardContent,
        },
        editItem: {
          name: editItem.name,
          description: editItem.description,
          metadata: wizardState?.content,
          url: heroImageUrl,
        },
        blob: image,
      })
    ) {
      onClose();
      return;
    }

    return canAddSummaryOfChanges
      ? handleEditFlashcardImage({ image, imageUrl })
      : handleSubmitFlashcardImageStep({ image, imageUrl });
  };

  return {
    wizardState,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
    onEditFlashcardDetails: handleEditFlashcardDetails,
    onEditFlashcardContent: handleEditFlashcardContent,
    onSubmitFlashcardImage: onSubmitImage,
    onUpdateFlashcardWithSummaryOfChanges: handleUpdateWithSummaryOfChanges,
    shouldAllowBlankHeroImageSelection,
  };
};
