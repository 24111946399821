import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  CommunityLevelSection,
  MedicationSummaryItem,
  MonographType,
  sectionStore,
} from "@eolas-medical/core";
import { InnerPageWrapper, Modal } from "UIKit";
import { LocalItemsList } from "shared/components/Lists";
import BnfSectionTile from "../../../components/BnfSectionTile/BnfSectionTile";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { makeBnfRoute } from "../../../routes/BnfRoutes";
import useGetInfoContentList from "modules/bnf/data/useGetInfoContentList";
import { titleLookup } from "./InfoContentList.strings";
import { isSupportedBNFMedicationType } from "deeplinking/functions/typeguards";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

const routeKeyLookup = {
  bnf: {
    guidanceItems: "guidance_adult",
    treatmentSummaryItems: "treatment_summaries_adult",
  },
  bnfc: {
    guidanceItems: "guidance_child",
    treatmentSummaryItems: "treatment_summaries_child",
  },
} as const;

const InfoContentList = ({
  infoType,
  type,
}: {
  infoType: "guidanceItems" | "treatmentSummaryItems";
  type: MonographType;
}) => {
  const { push } = useHistory();
  const { isLoading, summaryList } = useGetInfoContentList({ type, infoType });
  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const mainSectionId = sectionStore.getChildReferenceByMainSectionType(
    CommunityLevelSection.communityMedications,
  )?.id;

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  const renderInfoContentTile = useCallback(
    (item: MedicationSummaryItem) => {
      const canShare = isShareFileEnabled && isSupportedBNFMedicationType(item.type);

      return (
        <BnfSectionTile
          item={item}
          onClick={() => {
            push(
              makeBnfRoute({
                routeKey: routeKeyLookup[type][infoType],
                idOrSid: item.id,
                title: item.name,
              }),
            );
          }}
          onShare={
            canShare
              ? () => handleOnShareFile(item, { mainSectionIdOverride: mainSectionId })
              : undefined
          }
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [
      push,
      type,
      infoType,
      handleOnShareFile,
      mainSectionId,
      isShareFileEnabled,
      showRemoveFavouriteModal,
    ],
  );

  return (
    <InnerPageWrapper>
      <Modal open={isModalOpen} onClose={onCloseModal}>
        {shareModal || removeFavouriteModal}
      </Modal>
      <BnfHeader
        type={type}
        title={titleLookup[type][infoType]}
        goBackBehaviourPreference="removeFromPath"
      />
      <LocalItemsList<MedicationSummaryItem>
        items={summaryList}
        isLoading={isLoading}
        keysToSearch={["name", "keywords"]}
        renderItem={renderInfoContentTile}
        searchType="click"
      />
    </InnerPageWrapper>
  );
};

export const GuidanceContentListAdult = () => (
  <InfoContentList infoType="guidanceItems" type="bnf" />
);
export const TreatmentSummaryContentListAdult = () => (
  <InfoContentList infoType="treatmentSummaryItems" type="bnf" />
);
export const GuidanceContentListChild = () => (
  <InfoContentList infoType="guidanceItems" type="bnfc" />
);
export const TreatmentSummaryContentListChild = () => (
  <InfoContentList infoType="treatmentSummaryItems" type="bnfc" />
);
