import { hasProp } from "@eolas-medical/core";
import {
  DraggableListProps,
  EolasVersatileListContext,
  EolasVersatileListProps,
  SearchableListProps,
  SelectableListProps,
  SortableListProps,
} from "../EolasVersatileList.types";

export const hasDraggableListProps = <T, C extends EolasVersatileListContext<T>>(
  props: EolasVersatileListProps<T, C>,
): props is EolasVersatileListProps<T, C> & DraggableListProps => {
  return (
    hasProp(props, "onDragEnd") &&
    hasProp(props, "isDraggable") &&
    !!props.isDraggable &&
    hasProp(props, "droppableId")
  );
};

export const hasSearchableListProps = <T, C extends EolasVersatileListContext<T>>(
  props: EolasVersatileListProps<T, C>,
): props is EolasVersatileListProps<T, C> & SearchableListProps => {
  return (
    hasProp(props, "searchInstanceId") && hasProp(props, "isSearchable") && !!props.isSearchable
  );
};

export const hasSortableListProps = <T, C extends EolasVersatileListContext<T>>(
  props: EolasVersatileListProps<T, C>,
): props is EolasVersatileListProps<T, C> & SortableListProps<T> => {
  return hasProp(props, "isSortable") && !!props.isSortable;
};

export const hasSelectableListProps = <T, C extends EolasVersatileListContext<T>>(
  props: EolasVersatileListProps<T, C>,
): props is EolasVersatileListProps<T, C> & SelectableListProps<T> => {
  return hasProp(props, "isSelectable") && hasProp(props, "selectedItems") && !!props.isSelectable;
};
