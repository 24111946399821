import { getBlobType, getFileName } from "Utilities/fileHelpers";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { FirstStepComponentProps, FirstStepTypeData } from "../../types";
import { FirstStepWrapper } from "../firstStepComponents/FirstStepWrapper";
import { SelectFile } from "../SelectFile/SelectFile";
import { useSelectFileBlobStep } from "../SelectFile/hooks/useSelectFileBlobStep";
import { NewContentType } from "@eolas-medical/core";

const SelectFileBlobFirstStep = ({
  selectTypeComponent,
  wizardState,
  onClickForward,
}: FirstStepComponentProps) => {
  const { control, handleSubmit, mediaName } = useSelectFileBlobStep(wizardState);
  return (
    <FirstStepWrapper
      fileId={wizardState.fileId}
      buttonRowProps={{
        forwardButton: {
          onClick: handleSubmit(({ blob }) => {
            const newState = makeMutableWizardState(wizardState);
            if (!blob) {
              if (newState.fileId) {
                onClickForward(newState);
              }
              return;
            }
            newState.blob = blob;
            const fileType = getBlobType(newState.blob.type);
            const mediaName = `${getFileName(newState.blob)}.${fileType}`;
            if (newState.isExistingFileWithNewType) {
              newState.dto.newType = NewContentType.FILE;
              if (newState.dto.newType === NewContentType.FILE) {
                newState.dto.newProperties.mediaType = fileType;
                newState.dto.newProperties.mediaName = mediaName;
              }
            } else {
              newState.type = fileType;
              newState.dto.mediaName = mediaName;
            }
            onClickForward(newState);
          }),
        },
      }}
      selectTypeComponent={selectTypeComponent}
    >
      <SelectFile control={control} mediaName={mediaName} />
    </FirstStepWrapper>
  );
};

export const selectBlobStepData: FirstStepTypeData = {
  StepComponent: SelectFileBlobFirstStep,
  props: {
    getNextStepName: () => "fileDetails",
  },
};
