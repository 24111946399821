import { ChildReference, contentClient, sectionStore } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { ContentRepositoryId } from "../types";
import { DropResult } from "react-beautiful-dnd";
import { reorderItems } from "shared/components/Lists";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useRefetchAppData } from "Hooks";

const useReorderContent = (contentRepositoryId: ContentRepositoryId) => {
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const reorderContent = async (dropResult: DropResult) => {
    const childReferences = sectionStore.getChildrenOrder(contentRepositoryId);
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    const newOrder: ChildReference[] = reorderItems(
      childReferences,
      dropResult.source.index,
      dropResult.destination?.index,
    );

    sectionStore.setChildrenOrder(contentRepositoryId, newOrder);
    return useAppServicesEndpoints
      ? contentClient.reorder({
          input: {
            id: contentRepositoryId,
            listOrder: newOrder.map((item) => item.id),
          },
        })
      : contentClient.reorderChildrenOrder({
          id: contentRepositoryId,
          listOrder: newOrder.map((item) => item.id),
          entityType: "section",
        });
  };

  const { mutate } = useMutation(reorderContent, {
    onSuccess: async () => {
      refetch();
    },
    onMutate: (dropResult: DropResult) => {
      const childReferences = sectionStore.getChildrenOrder(contentRepositoryId);
      const oldOrder = [...childReferences];

      return { oldOrder, dropResult };
    },
    onError: (_, variables, context) => {
      sectionStore.setChildrenOrder(contentRepositoryId, context?.oldOrder as ChildReference[]);
    },
  });

  return {
    reorderContent: mutate,
  };
};

export default useReorderContent;
