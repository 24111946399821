import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

import { useMonographContext } from "../../../contexts/ MonographTypeContext";
import { ProcessingInstruction } from "shared/functions/makeHtmlToReactNodeParser";

const BASE_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

const CustomImgTag = ({
  src,
  ...rest
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const { type } = useMonographContext();
  const imgUrl = `${BASE_URL}/${type}/${src}`;
  return <img {...rest} src={imgUrl} />;
};

export const processImgTag: ProcessingInstruction["processNode"] = (node, _, index) => {
  return <CustomImgTag key={index} {...(node.attribs ?? {})} />;
};
