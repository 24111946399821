import { useTranslation } from "react-i18next";
import { useState } from "react";

import { ButtonRowProps } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { MutableWizardState, WizardState } from "../components/ManageContentItemWizard/types";

export const useMakePenultimateStepButtonProps = ({
  handleClickForward,
  handleAltClickForward,
  handleClickBack,
  currentWizardState,
  isLoading,
  isDisabled,
  isSummaryOfChanges,
}: {
  currentWizardState: WizardState;
  handleClickBack?: () => void;
  handleClickForward: () => void | Promise<void>;
  handleAltClickForward: () => void | Promise<void>;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSummaryOfChanges?: boolean;
}): ButtonRowProps => {
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState<"altForward" | "forward" | null>(null);

  if (currentWizardState.fileId && !isSummaryOfChanges) {
    return {
      previousButton: handleClickBack
        ? { onClick: handleClickBack, disabled: isLoading || isDisabled }
        : undefined,
      forwardButton: {
        onClick: () => {
          setButtonClicked("forward");
          handleClickForward();
        },
        isLoading: buttonClicked === "forward" && isLoading,
        disabled: isDisabled,
      },
    };
  }

  return {
    previousButton: handleClickBack
      ? { onClick: handleClickBack, disabled: isLoading || isDisabled }
      : undefined,
    altForwardButton: {
      onClick: () => {
        setButtonClicked("altForward");
        handleAltClickForward();
      },
      isLoading: buttonClicked === "altForward" && isLoading,
      color:
        !currentWizardState.fileId || currentWizardState.existingDraftStatus === "unpublished"
          ? "grey"
          : undefined,
      text: t("content_item_draft_next"),
      disabled: isDisabled,
    },
    forwardButton: {
      onClick: () => {
        setButtonClicked("forward");
        handleClickForward();
      },
      isLoading: buttonClicked === "forward" && isLoading,
      color: "green",
      text: t("content_item_publish_next"),
      disabled: isDisabled,
    },
  };
};

export const handleUpdateWizardDraftStatus = <T extends MutableWizardState | null>({
  state,
  isDraft,
  isSummaryOfChanges,
}: {
  state: T;
  isDraft: boolean;
  isSummaryOfChanges?: boolean;
}) => {
  if (!state) {
    return state;
  }

  if (state.fileId && !isSummaryOfChanges) {
    return state;
  }

  if (isDraft) {
    return updateDraftState(state);
  }

  return updatePublishState(state);
};

const updateDraftState = <T extends MutableWizardState | null>(state: T) => {
  if (!state) {
    return state;
  }

  if (state.existingDraftStatus) {
    state.nextDraftStatus = state.existingDraftStatus;
  } else {
    if (state.fileId) {
      state.nextDraftStatus = "parallel";
    } else {
      state.nextDraftStatus = "unpublished";
    }
  }
  return state;
};

const updatePublishState = <T extends MutableWizardState | null>(state: T) => {
  if (state) {
    state.nextDraftStatus = undefined;
  }
  return state;
};
