import { Redirect, Route, Switch } from "react-router-dom";

import { KnowledgeRoute } from "./types";
import { BaseAppRoute, CalculatorSections, LDFlagNames } from "Utilities/types";
import { Knowledge } from "./Knowledge";

import NationalGuidelines from "./pages/NationalGuidelines/NationalGuidelines";
import NationalGuidelinesBody from "./pages/NationalGuidelines/pages/NationalGuidelinesBody";
import ClinicalCalculatorsBody from "./pages/ClinicalCalculators/pages/ClinicalCalculatorsBody";
import ClinicalCalculators from "./pages/ClinicalCalculators/ClinicalCalculators";
import PatientLeaflets from "./pages/CommunityPatientLeaflets/PatientLeaflets";
import PatientLeafletsBody from "./pages/CommunityPatientLeaflets/pages/PatientLeafletsBody";
import Medications from "./pages/Medications/Medications";
import {
  BodyWeightCalculator,
  CreatinineClearanceCalculator,
  GentamicinCalculator,
} from "./pages/ClinicalCalculators/pages";
import MedicationsRoutes from "./pages/Medications/MedicationsRoutes";
import { NiceGuidelinesHome } from "./pages/NiceGuidelines/pages/NiceGuidelinesHome";
import { NiceGuidelineViewer } from "./pages/NiceGuidelines/components/NiceGuidelineViewer";
import { NiceGuidelinesList } from "./pages/NiceGuidelines/pages/NiceGuidelinesList";
import { ClinicalQuestionsContentRepository } from "./pages/ClinicalQuestions/ClinicalQuestionsContentRepository";
import { WikEm } from "./pages/WikEm/pages/WikEm";
import ManageSlotsPage from "./pages/SponsoredSlots/pages/ManageSlotsPage/ManageSlotsPage";
import { useLaunchDarkly } from "Contexts";
import { SponsoredSlotsContentRepository } from "./pages/SponsoredSlots/SponsoredSlotsContentRepository";
import { KnowledgeContentDomains } from "@eolas-medical/core";
import { ClinicalQuestionsHome } from "./pages/ClinicalQuestions/ClinicalQuestionsHome";

export const knowledgeRoutes: Record<KnowledgeRoute, string> = {
  nationalGuidelines: `/${BaseAppRoute.knowledge}/nationalGuidelines`,
  nationalGuidelinesBody: `/${BaseAppRoute.knowledge}/nationalGuidelines/:body`,
  clinicalCalculators: `/${BaseAppRoute.knowledge}/clinicalCalculators`,
  clinicalCalculatorsBody: `/${BaseAppRoute.knowledge}/clinicalCalculators/:body`,
  bodyWeightCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.bodyWeightCalculator}/:id`,
  creatinineCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.creatinineCalculator}/:id`,
  gentamicinCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.gentamicinCalculator}/:id`,
  communityPatientLeaflets: `/${BaseAppRoute.knowledge}/communityPatientLeaflets`,
  communityPatientLeafletsBody: `/${BaseAppRoute.knowledge}/communityPatientLeaflets/:body`,
  communityMedications: `/${BaseAppRoute.knowledge}/communityMedications`,
  niceGuidelines: `/${BaseAppRoute.knowledge}/niceGuidelines`,
  niceGuidelinesList: `/${BaseAppRoute.knowledge}/niceGuidelines/:sections`,
  niceGuidelinesItem: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/:id`,
  clinicalQuestions: `/${BaseAppRoute.knowledge}/${KnowledgeContentDomains.clinicalQuestions}/:routeId`,
  clinicalQuestionsContent: `/${BaseAppRoute.knowledge}/${KnowledgeContentDomains.clinicalQuestionsContent}`,
  wikEm: `/${BaseAppRoute.knowledge}/wikEm`,
  sponsoredSlots: `/${BaseAppRoute.knowledge}/${KnowledgeContentDomains.sponsoredSlots}/:mainSectionId`,
  sponsoredSlotsContent: `/${BaseAppRoute.knowledge}/${KnowledgeContentDomains.sponsoredSlotsContent}`,
};

export const KnowledgeRoutes = () => {
  const { flags } = useLaunchDarkly();
  const isKnowledgeAdminEnabled = flags[LDFlagNames.KNOWLEDGE_ADMIN_ENABLED];

  return (
    <>
      <Route exact path={`/${BaseAppRoute.knowledge}`} component={Knowledge} />

      {/* Nice Guidelines */}
      <Route exact path={knowledgeRoutes.niceGuidelines} component={NiceGuidelinesHome} />
      <Switch>
        <Route exact path={knowledgeRoutes.niceGuidelinesItem} component={NiceGuidelineViewer} />
        <Route path={knowledgeRoutes.niceGuidelinesList} component={NiceGuidelinesList} />
      </Switch>
      {/* National Guidelines */}
      <Route exact path={knowledgeRoutes.nationalGuidelines} component={NationalGuidelines} />

      <Route
        exact
        path={knowledgeRoutes.nationalGuidelinesBody}
        component={NationalGuidelinesBody}
      />

      {/* Clinical Calculators */}
      <Route exact path={knowledgeRoutes.clinicalCalculators} component={ClinicalCalculators} />

      <Route
        exact
        path={knowledgeRoutes.clinicalCalculatorsBody}
        component={ClinicalCalculatorsBody}
      />

      <Route
        exact
        component={CreatinineClearanceCalculator}
        path={knowledgeRoutes.creatinineCalculator}
      />
      <Route exact component={BodyWeightCalculator} path={knowledgeRoutes.bodyWeightCalculator} />
      <Route exact component={GentamicinCalculator} path={knowledgeRoutes.gentamicinCalculator} />

      {/* Patient Leaflets */}
      <Route exact path={knowledgeRoutes.communityPatientLeaflets} component={PatientLeaflets} />

      <Route
        exact
        path={knowledgeRoutes.communityPatientLeafletsBody}
        component={PatientLeafletsBody}
      />

      {/* Bnf and Medications */}
      <Route exact path={knowledgeRoutes.communityMedications} component={Medications} />

      <Route path={knowledgeRoutes.communityMedications} component={MedicationsRoutes} />

      {/* Clinical question generator */}
      <Route path={knowledgeRoutes.clinicalQuestions} component={ClinicalQuestionsHome} />

      {/* Clinical questions browser */}
      <Route
        path={knowledgeRoutes.clinicalQuestionsContent}
        component={ClinicalQuestionsContentRepository}
      />

      {isKnowledgeAdminEnabled ? (
        <>
          {/* Sponsored Slots admin */}
          <Route exact path={knowledgeRoutes.sponsoredSlots} component={ManageSlotsPage} />
        </>
      ) : null}

      {/** Sponsored Slots Content */}
      <Route
        path={knowledgeRoutes.sponsoredSlotsContent}
        component={SponsoredSlotsContentRepository}
      />

      {/* WikEm */}
      <Route path={[knowledgeRoutes.wikEm]} component={WikEm} />
      {/** WikEM redirect for a common typo */}
      <Route path={`/${BaseAppRoute.knowledge}/wikiEm`}>
        <Redirect to={knowledgeRoutes.wikEm} />
      </Route>
    </>
  );
};
