import React, { useState, useCallback } from "react";

import { CompletedChecklist, EolasFile } from "@eolas-medical/core";
import { formatDate, fromAwsJSON } from "Utilities";

import { DeleteSubmittedChecklistModal, SubmittedChecklistModal } from "../components";

export const useSubmittedChecklists = () => {
  const [modal, setModal] = useState<React.ReactNode | null>(null);
  const onCloseModal = useCallback(() => setModal(null), [setModal]);

  const onDelete = (checklist: EolasFile) => {
    const { completedChecklist }: { completedChecklist: CompletedChecklist } = fromAwsJSON(
      // eslint-disable-next-line quotes
      checklist.metadata ?? '"{}"',
    );

    setModal(
      <DeleteSubmittedChecklistModal
        checklist={checklist}
        closeModal={onCloseModal}
        completedChecklist={completedChecklist}
      />,
    );
  };

  const onSelect = (checklist: EolasFile) => {
    const { completedChecklist }: { completedChecklist: CompletedChecklist } = fromAwsJSON(
      // eslint-disable-next-line quotes
      checklist.metadata ?? '"{}"',
    );

    setModal(
      <SubmittedChecklistModal checklist={checklist} completedChecklist={completedChecklist} />,
    );
  };

  const filterChecklists = (searchInput: string) => (checklist: EolasFile) => {
    const author = checklist.info!;

    const searchThrough: string[] = [
      author!.toLowerCase(),
      formatDate(checklist.createdAt as string, "PPPPpppp").toLowerCase(),
    ];

    return searchThrough.some((term) => term.includes(searchInput));
  };

  return {
    modal,
    onSelect,
    onDelete,
    onCloseModal,
    filterChecklists,
  };
};
