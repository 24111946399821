import { UploadProgressAndSuccessStep } from "./UploadProgressAndSuccessStep";
import { Step } from "../../types";
import { useTranslation } from "react-i18next";

interface useUploadProgressAndSuccessStepProps<T> {
  stepName: T;
  fileUploadingTitle?: string;
  fileUploadSuccessText?: string;
  fileUploading: boolean;
  uploadProgress: number;
  fileUploadError: string | undefined;
  fileUploadedSuccess: boolean;
  onFinishUpload: () => void;
}

export const useUploadProgressAndSuccessStep = <T extends string>({
  stepName,
  fileUploading,
  fileUploadingTitle,
  fileUploadSuccessText,
  uploadProgress,
  fileUploadError,
  fileUploadedSuccess,
  onFinishUpload,
}: useUploadProgressAndSuccessStepProps<T>): Step<T> => {
  const { t } = useTranslation();

  let title = "";

  if (fileUploading) {
    title = fileUploadingTitle || t("repository_upload_title");
  } else if (fileUploadError) {
    title = t("repository_upload_error");
  } else {
    title = fileUploadSuccessText || t("repository_upload_success");
  }

  return {
    name: stepName,
    title,
    submitError: !!fileUploadError,
    backDisabled: true,
    nextDisabled: true,
    component: (
      <UploadProgressAndSuccessStep
        fileUploading={fileUploading}
        uploadProgress={uploadProgress}
        fileUploadError={fileUploadError}
        fileUploadedSuccess={fileUploadedSuccess}
        onFinishUpload={onFinishUpload}
      />
    ),
    hideButtons: true,
  };
};
