import { useQuery } from "@tanstack/react-query";
import contentKeys from "./content.queryKeys";
import { contentClient } from "@eolas-medical/core";
import { mapToContentItem } from "../helpers";

interface UseContentItem {
  mainSectionId: string;
  contentId: string;
  enabled?: boolean;
}
const useFetchContentItem = ({ mainSectionId, contentId, enabled = true }: UseContentItem) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: contentKeys.detail(contentId),
    queryFn: () => contentClient.getContentItemByMainSectionId({ mainSectionId, contentId }),
    enabled,
  });

  return {
    contentItem: data ? mapToContentItem(data) : undefined,
    isContentItemLoading: isLoading && enabled,
    isFetchContentItemError: isError,
    fetchContentItemError: error,
  };
};

export default useFetchContentItem;
