import { gql, useMutation } from "@apollo/client";
import { Space, contentClient, eolasLogger, sectionStore } from "@eolas-medical/core";
import { errorStore } from "Stores/ErrorStore";
import useContentManagementSections from "modules/spaces/data/useContentManagementSections";
import { MainSection } from "../types";
import { mapToChildReference } from "../helpers";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useRefetchAppData } from "Hooks";

const REORDER = gql`
  mutation Reorder($input: ReorderInput!) {
    reorder(input: $input) {
      id
      childrenOrder {
        id
        type
        description
        icon
        name
        disabled
      }
    }
  }
`;

interface UseReorderMainSections {
  space: Space;
  isSpaceMainSection: boolean;
}

const useReorderMainSections = ({ space, isSpaceMainSection }: UseReorderMainSections) => {
  const [reorder] = useMutation(REORDER);
  const { deactivatedMainSections, hiddenMainSections } = useContentManagementSections({
    isSpaceContent: isSpaceMainSection,
  });
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { refetch } = useRefetchAppData();

  const reorderId = isSpaceMainSection ? space.id : space.organisationID;

  const reorderSections = (reorderedSections: MainSection[]) => {
    const oldOrder = [
      ...(isSpaceMainSection
        ? sectionStore.departmentSectionsOrder
        : sectionStore.hospitalSectionsOrder),
    ];
    sectionStore.setMainSectionsList(
      [
        ...hiddenMainSections.map(mapToChildReference),
        ...reorderedSections.map(mapToChildReference),
        ...deactivatedMainSections.map(mapToChildReference),
      ],
      isSpaceMainSection ? "department" : "hospital",
    );

    const listOrder = [
      ...hiddenMainSections.map((item) => item.id),
      ...reorderedSections.map((item) => item.id),
      ...deactivatedMainSections.map((item) => item.id),
    ];

    if (useAppServicesEndpoints) {
      reorder({
        variables: {
          input: {
            id: reorderId,
            listOrder,
          },
        },
      }).catch((error) => {
        errorStore.captureError({
          error,
          source: "user",
          retryParameters: {
            variables: {
              input: {
                id: reorderId,
                listOrder,
              },
            },
          },
          retryCallback: reorder,
        });
      });
    } else {
      contentClient
        .reorderChildrenOrder({
          id: reorderId,
          listOrder,
          entityType: isSpaceMainSection ? "space" : "organisation",
        })
        .catch((error) => {
          eolasLogger.error(error);
          sectionStore.setMainSectionsList(
            oldOrder,
            isSpaceMainSection ? "department" : "hospital",
          );
        })
        .then(refetch);
    }
  };

  return {
    reorderSections,
  };
};

export default useReorderMainSections;
