import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile, OrganisationLevelSection } from "@eolas-medical/core";

import { InfoIconColoured } from "Assets";
import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import {
  Input,
  Title,
  Button,
  FormError,
  ModalBody,
  FilePicker,
  FormElement,
  ProgressBar,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";
import { useToolTip } from "Hooks/useToolTip";

interface AddPatientLeafletModalProps {
  parentId?: string;
  mainSectionType: OrganisationLevelSection.orgPatientLeaflets | AppLevelSection.patientLeaflets;
  leaflet?: EolasFile;
  onCloseModal(): void;
}

export const AddPatientLeafletModal = ({
  leaflet,
  parentId,
  mainSectionType,
  onCloseModal,
}: AddPatientLeafletModalProps) => {
  const { t } = useTranslation();
  const { ref, ScrollHint } = useIsScrollable();
  const allowedFileTypes = useAllowedFileTypes();
  const { makeToolTipDataAttr } = useToolTip();

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    eolasFile: leaflet,
    subSectionId: parentId,
    mainSectionID: mainSectionType,
    isPublic: true,
  });

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={
          leaflet ? "patientLeaflets_edit_file_success" : "patientLeaflets_add_new_file_success"
        }
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <div className="flex items-center">
          <Title level={5}>
            {t(leaflet ? "patientLeaflets_edit_modal_title" : "patientLeaflets_add_modal_title")}
          </Title>
          <InfoIconColoured
            className="ml-2 h-5 w-5"
            {...makeToolTipDataAttr({ text: t("patientLeaflets_info_tooltip"), place: "bottom" })}
          />
        </div>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              className="mb-4"
              control={control}
              label={t("patientLeaflets_file_name_label")}
            >
              {(fieldProps) => (
                <Input
                  {...fieldProps}
                  size="lg"
                  disabled={isLoading}
                  placeholder={t("patientLeaflets_file_name_placeholder")}
                />
              )}
            </FormElement>

            <FilePicker
              required
              isDisabled={isLoading}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              error={formErrors.key?.message}
              onSelectType={onSelectFileType}
              allowedFileTypes={allowedFileTypes}
            />

            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>

          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
          >
            {t("general_submit")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
