export const PackStrings = {
  sizeAndUnit: (size: string, unit: string) => `${size} ${unit}`,
  nhsPrice: (nhsIndicativePrice: string | undefined) =>
    nhsIndicativePrice
      ? `NHS Indicative Price = ${nhsIndicativePrice}`
      : "No NHS indicative price available",
  drugTariff: (drugTariff: string | undefined, drugTariffPrice: string | undefined) => {
    if (drugTariff && drugTariffPrice) {
      return `${drugTariff} = ${drugTariffPrice}`;
    }
    if (drugTariff) {
      return `Drug Tariff ${drugTariff}`;
    }
    return "";
  },
};
