import { MonographType } from "@eolas-medical/core";
import { useProcessBorderlineSubstance } from "./hooks/useProcessBorderlineSubstance";
import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import { InnerPageWrapper, Loader } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { MonographContextProvider } from "../../../contexts/ MonographTypeContext";
import { SummaryGroup } from "../AdditionalContentHome/components/SummaryGroup";
import { SubstanceComponent } from "./components/SubstanceComponent";

const BorderlineSubstances = ({ type }: { type: MonographType }) => {
  const { idOrSid } = useGetBnfSearchParams();
  const { title, isLoading, activeBorderlineSubstance, goBackSubstance, makeOnClick } =
    useProcessBorderlineSubstance({ idOrSid, type });

  return (
    <InnerPageWrapper className="space-y-3 lg:space-y-6">
      <BnfHeader
        title={title}
        type={type}
        goBackBehaviourPreference="goBackHistory"
        overrideGoBackCallback={goBackSubstance}
      />
      <MonographContextProvider type={type}>
        {isLoading ? <Loader className="bg-transparent h-30vh" /> : null}
        {activeBorderlineSubstance?.children?.length ? (
          <SummaryGroup
            title={title ?? ""}
            items={activeBorderlineSubstance.children}
            itemTitleProperty="title"
            makeOnClick={makeOnClick}
          />
        ) : null}
        {activeBorderlineSubstance?.substances.length
          ? activeBorderlineSubstance.substances.map((substance) => (
              <SubstanceComponent substance={substance} key={substance.id} />
            ))
          : null}
      </MonographContextProvider>
    </InnerPageWrapper>
  );
};

export const BorderlineSubstancesAdult = () => <BorderlineSubstances type="bnf" />;
export const BorderlineSubstancesChild = () => <BorderlineSubstances type="bnfc" />;
