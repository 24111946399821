import {
  makeProcessNode,
  makeShouldProcessNode,
  ProcessingInstruction,
} from "shared/functions/makeHtmlToReactNodeParser";

export const responseProcessingInstructions: ProcessingInstruction[] = [
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "ul" }),
    processNode: makeProcessNode({ tagName: "ul", classNameToAdd: "list-disc px-8 text-base" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "p" }),
    processNode: makeProcessNode({ tagName: "p", classNameToAdd: "text-base my-2 my-0.5" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "li" }),
    processNode: makeProcessNode({ tagName: "li", classNameToAdd: "text-base my-0.5" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "a" }),
    processNode: makeProcessNode({
      tagName: "a",
      classNameToAdd: "text-base text-blue-500 hover:text-blue-700 underline",
    }),
  },
];
