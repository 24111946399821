import { DropResult } from "react-beautiful-dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";

import { addNewSectionSchema } from "../validationSchemas";
import { ChecklistSection, ChecklistSectionItem } from "../types";

export const useAddNewSection = (onSave: (section: ChecklistSection) => void) => {
  const formMethods = useForm<ChecklistSection>({
    resolver: yupResolver(addNewSectionSchema),
    mode: "all",
    defaultValues: {
      checklistSectionName: "",
      sectionItems: [],
    },
  });

  const { control, handleSubmit } = formMethods;

  const {
    fields: sectionItems,
    append,
    update,
    remove,
    move,
  } = useFieldArray({
    control,
    name: "sectionItems",
  });

  const onAddItem = (sectionItem: ChecklistSectionItem) => {
    append(sectionItem);
  };

  const onEditItem = (index: number, sectionItem: ChecklistSectionItem) => {
    update(index, sectionItem);
  };

  const onDeleteItem = (index: number) => {
    remove(index);
  };

  const onSwap = (dropResult: DropResult) => {
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    move(dropResult.source.index, dropResult.destination.index);
  };

  const onSubmit = () => {
    handleSubmit((values) => {
      onSave(values);
    })();
  };

  return {
    control,
    formMethods,
    sectionItems,
    onSwap,
    onSubmit,
    onAddItem,
    onEditItem,
    onDeleteItem,
  };
};
