import { NavButton, NavButtonProps } from "UIKit";
import { removeLastUrlPath } from "Utilities";
import { useHistory, useRouteMatch } from "react-router-dom";

type BaseProps = Pick<NavButtonProps, "className" | "color">;

export type BackButtonMode = "removeFromPath" | "goBackHistory";

export type NavBackButtonProps = {
  modePreference: BackButtonMode;
  overrideUrl?: string;
  segmentsToRemove?: number;
  overrideGoBackCallback?: () => void;
} & BaseProps;

export const NavBackButton = ({
  overrideGoBackCallback,
  modePreference,
  segmentsToRemove,
  overrideUrl,
  ...rest
}: NavBackButtonProps) => {
  const { goBack, replace, length } = useHistory();
  const { url } = useRouteMatch();

  const onClick = () => {
    if (modePreference === "goBackHistory" && length > 2) {
      goBack();
      return;
    }
    replace(overrideUrl || removeLastUrlPath(url, segmentsToRemove));
  };

  return <NavButton onClick={overrideGoBackCallback ?? onClick} {...rest} />;
};
