import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { EditFlashcardItemDto } from "../types";
import { generateS3FileKey, generateS3PublicImageURL } from "Utilities";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { useState } from "react";
import { AxiosProgressEvent } from "axios";
import { createResourcesManifestFromHtml, mapToUpdateFlashcardDto } from "../helpers";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseEditContentItemProps<T, R> {
  mainSectionId: string;
  onSuccess: (params?: T) => Promise<R>;
}

const useEditFlashcardItem = <T, R>({
  mainSectionId,
  onSuccess,
}: UseEditContentItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!);
    setUploadProgress(percentCompleted);
  };

  const editFlashcardItem = async ({ flashcard, editItem, file }: EditFlashcardItemDto) => {
    if (file) {
      if (useAppServicesEndpoints) {
        const s3key = generateS3FileKey({
          isPublic: true,
          fileName: editItem.name || flashcard.name,
          fileFormat: file?.type,
          mainSectionId,
          addExtension: true,
        });
        editItem.imageUrl = generateS3PublicImageURL(s3key);
        await uploadFile(s3key, file, setProgress);
      } else {
        const { publicUrl } = await uploadFileV2({
          file,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isPublic: true,
        });
        editItem.imageUrl = publicUrl;
      }
    }

    const resourcesManifest = editItem.flashcardContent
      ? await createResourcesManifestFromHtml(editItem.flashcardContent, editItem.imageUrl)
      : undefined;

    const flashcardDto = mapToUpdateFlashcardDto({
      ...editItem,
      isKnowledge: false,
      resourcesManifest: resourcesManifest ? JSON.stringify(resourcesManifest) : undefined,
    });

    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    const result: any = await contentClient.updateFlashcard({
      mainSectionId,
      flashcardId: flashcard.id,
      contentDto: flashcardDto,
    });
    setUploadProgress(50);
    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({ flashcard, editItem, file }: EditFlashcardItemDto) =>
      editFlashcardItem({ flashcard, editItem, file }),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  return {
    editFlashcardItem: mutate,
    editingFlashcardItem: isLoading,
    editFlashcardItemSuccess: isSuccess,
    editFlashcardItemError: error instanceof Error ? error.message : "",
    editFlashcardItemProgress: uploadProgress,
  };
};

export default useEditFlashcardItem;
