import { Button, Title } from "UIKit";
import { SelectSpaceState } from "../../types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface SelectSpaceButtons {
  selectedButton: SelectSpaceState;
  onChange: (option: SelectSpaceState) => void;
}

const SelectSpaceButtons = ({ selectedButton, onChange }: SelectSpaceButtons) => {
  const { t } = useTranslation();

  const selectedButtonClass = "text-blue-500";
  const notSelectedButtonClass = "text-black";

  const getButtonClass = (button: SelectSpaceState) =>
    selectedButton === button ? selectedButtonClass : notSelectedButtonClass;

  return (
    <div className="flex justify-center items-center space-x-4 pt-6">
      <Button
        iconLeft={"SpacesIcon"}
        color="white"
        size="lg"
        className={twMerge("w-56 rounded-3xl", getButtonClass("your-spaces"))}
        onClick={() => onChange("your-spaces")}
      >
        <Title
          level={7}
          className={twMerge("ml-2 font-bold text-lg", getButtonClass("your-spaces"))}
        >
          {t("selectSpace_your_spaces")}
        </Title>
      </Button>
      <Button
        iconLeft={"SearchMonoIcon"}
        color="white"
        size="lg"
        className={twMerge("w-56 rounded-3xl", getButtonClass("discover-spaces"))}
        onClick={() => onChange("discover-spaces")}
      >
        <Title
          level={7}
          className={twMerge("ml-2 font-bold text-lg", getButtonClass("discover-spaces"))}
        >
          {t("selectSpace_discover_spaces")}
        </Title>
      </Button>
    </div>
  );
};

export default SelectSpaceButtons;
