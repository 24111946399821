import { UserInviteIcon } from "Assets/Icons";
import { useTranslation } from "react-i18next";
import { InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";
import InviteCard from "../InviteCard/InviteCard";

interface InviteUsersModalProps {
  isPublic?: boolean;
  onClickViaLink: () => void;
  onClickViaEmail: () => void;
}

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({
  isPublic = false,
  onClickViaLink,
  onClickViaEmail,
}: InviteUsersModalProps) => {
  const { t } = useTranslation();
  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <UserInviteIcon className="h-16 w-16 mb-4" />
        <Title level={5}>{t("manageUsers_invite_users_modal_title")}</Title>
        <Text level={1} className="text-center">
          {t("manageUsers_invite_users_modal_subtitle")}
        </Text>
      </ModalHeader>
      <ModalBody>
        <div className="flex w-full gap-x-4">
          <InviteCard
            iconType="InviteLinkIcon"
            titleText={t("manageUsers_invite_users_invite_via_link")}
            descriptionText={t("manageUsers_invite_users_invite_via_link_description")}
            onClick={onClickViaLink}
          />
          <InviteCard
            iconType="InviteEmailIcon"
            titleText={t("manageUsers_invite_users_invite_via_email")}
            descriptionText={t("manageUsers_invite_users_invite_via_email_description")}
            onClick={onClickViaEmail}
            disabled={isPublic}
          />
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default InviteUsersModal;
