import { useState, useCallback } from "react";
import { DsmData, hasProp, SupportedFileType, userStore } from "@eolas-medical/core";
import { ConfigureFileValues, LinkFormValues } from "UIKit/Wizard";
import { AddItemModalState, AddContentItemWizardStep } from "../types";
import { isFile, isLink } from "Pages/Spaces/helpers";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { parseWorkbook } from "../../../functions/parseDsmSheet/parseWorkbook";
import { DsmError, makeWorkbook } from "../../../functions/parseDsmSheet/helpers";

interface UseAddContentItemProps {
  onClose: () => void;
  onChangeWizard: (editorType: SupportedFileType) => void;
}

export const useAddContentItemActions = ({ onChangeWizard, onClose }: UseAddContentItemProps) => {
  const [wizardState, setWizardState] = useState<AddItemModalState>({
    selectedType: "blob",
  });

  const { useAddContentItemHook, contextDomain } = useContentRepositoryContext();
  const user = userStore.userData;

  const {
    addContentItem,
    addingContentItem,
    addContentItemSuccess,
    addContentItemError,
    addContentItemProgress,
  } = useAddContentItemHook;

  const handleSelectFileType = (type: SupportedFileType) => {
    setWizardState((prevState) => ({
      ...prevState,
      selectedType: type,
    }));
  };

  const handleSubmitFileType = (
    type: SupportedFileType,
    result?: File | LinkFormValues,
    isDraft = false,
  ) => {
    if ((type === "blob" || type === "dsm") && isFile(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        file: {
          ...prevState?.file,
          blob: result,
        },
      }));
    }

    if (type === "link" && isLink(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        link: {
          name: result.name,
          url: result.url,
          keywords: result?.keywords,
        },
      }));

      addContentItem(
        {
          name: result.name,
          itemType: "link",
          keywords: result.keywords ? result.keywords.split(",") : undefined,
          linkUrl: result.url,
          createdBy: user.id || "",
          isDraft,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }

    if (type === "flashcard") {
      onChangeWizard("flashcard");
    }

    if (type === "eolas-editor") {
      onChangeWizard("eolas-editor");
    }
  };

  const handleAddFileDetails = useCallback(
    (data: ConfigureFileValues) => {
      setWizardState((prevState) => ({
        ...prevState,
        file: {
          ...prevState.file,
          name: data.name,
          expiryDate: data.expiryDate,
          keywords: data.keywords,
        },
      }));

      let itemType: SupportedFileType = "blob";
      let dsmData: DsmData | undefined = undefined;

      if (hasProp(wizardState.dsmData, "parsedData")) {
        itemType = "dsm";
        dsmData = wizardState.dsmData.parsedData;
      }

      addContentItem({
        name: data.name,
        expiryDate: data.expiryDate,
        keywords: data.keywords.split(","),
        itemType,
        file: wizardState.file?.blob,
        createdBy: user.id || "",
        isDraft: data.isDraft,
        dsmData,
      });
    },
    [addContentItem, wizardState, user.id],
  );

  const getNextStep = (currentStep: AddContentItemWizardStep): AddContentItemWizardStep => {
    if (currentStep === "upload-item" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    if (currentStep === "upload-item" && wizardState.dsmData) {
      if (hasProp(wizardState.dsmData, "parsedData")) {
        return "add-dsm-details";
      }
      return "dsm-error";
    }

    if (currentStep === "add-dsm-details") {
      return "upload-progress";
    }

    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-progress";
    }

    return "upload-item";
  };

  const getPrevStep = (currentStep: AddContentItemWizardStep): AddContentItemWizardStep => {
    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-item";
    }

    if (currentStep === "add-dsm-details" || currentStep === "dsm-error") {
      return "upload-item";
    }

    if (currentStep === "upload-progress" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    return "upload-item";
  };

  const onBlobSelection = async (file: File, type: SupportedFileType) => {
    if (type !== "dsm") {
      if (wizardState.dsmData) {
        setWizardState((old) => {
          const newState = { ...old };
          delete newState.dsmData;
          return newState;
        });
      }
      return;
    }
    try {
      const workBook = await makeWorkbook(file);
      const parsedData = parseWorkbook(workBook);
      setWizardState((prevState) => {
        return {
          ...prevState,
          selectedType: "dsm",
          dsmData: { parsedData },
        };
      });
    } catch (error) {
      let dsmError: DsmError;
      if (error instanceof DsmError) {
        dsmError = error;
      } else {
        console.error(error);
        dsmError = new DsmError("An unknown error ocurred", "other");
      }
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: "dsm",
        dsmData: {
          dsmError,
        },
      }));
    }
  };

  return {
    contextDomain,
    wizardState,
    addContentItemError,
    addingContentItem,
    addContentItemSuccess,
    addContentItemProgress,
    getNextStep,
    getPrevStep,
    onSelectType: handleSelectFileType,
    onSubmitFileType: handleSubmitFileType,
    onAddFileDetails: handleAddFileDetails,
    onBlobSelection,
  };
};
