import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { SearchBox, SearchBoxProps } from "../SearchBox";
import { Button } from "UIKit/Button";
import { ClearIcon, SearchMonoIcon } from "Assets";
import { twMerge } from "tailwind-merge";
import { ButtonSize } from "UIKit/Button/types";
import { SearchBoxSize } from "../types";

interface ClickSearchBoxProps extends SearchBoxProps {
  onClickSearch: () => void;
  onClearSearch: () => void;
  isLoading?: boolean;
  searchButtonText?: string;
  searchButtonClass?: string;
  minInputLength?: number;
}

const rightButtonSizes: Record<SearchBoxSize, ButtonSize> = {
  md: "sm",
  lg: "md",
  xl: "lg",
};

export const ClickSearchBox = forwardRef<HTMLInputElement, ClickSearchBoxProps>(
  function ClickSearchBox(
    {
      onClickSearch,
      isLoading,
      searchButtonText = "Search",
      searchButtonClass,
      onClearSearch,
      className,
      minInputLength = 2,
      value,
      ...otherProps
    }: ClickSearchBoxProps,
    ref,
  ) {
    const [isTypingSearch, setIsTypingSearch] = useState<boolean>(false);
    const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

    const handlePressEnter = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && value && value?.length >= minInputLength) {
          e.preventDefault();
          onClickSearch();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClickSearch],
    );

    const handleClearSearch = useCallback(() => {
      onClearSearch();
    }, [onClearSearch]);

    useEffect(() => {
      setIsTypingSearch(!!value && value.length > 0);
      setIsSearchEnabled(!!value && value.length >= minInputLength);
    }, [value, minInputLength]);

    const rightSection = (
      <div className="flex flex-row items-center">
        {isTypingSearch && (
          <button
            onClick={handleClearSearch}
            data-testid="clear-search"
            className="sm:mx-2 flex items-center pl-4 text-sm"
          >
            <ClearIcon />
          </button>
        )}

        <Button
          autoFocus
          onClick={onClickSearch}
          isLoading={isLoading}
          data-testid="searchbar-button"
          disabled={!isSearchEnabled}
          size={rightButtonSizes[otherProps.searchBoxSize || "md"]}
          className={twMerge(
            "ml-1 px-3 sm:px-6 rounded-xl border-0 bg-blue-500",
            isSearchEnabled ? "bg-blue-500" : "bg-blue-200",
            searchButtonClass,
          )}
        >
          <span className="hidden sm:inline text-white">{searchButtonText}</span>
          <SearchMonoIcon className="h-6 w-6 inline sm:hidden" />
        </Button>
      </div>
    );

    return (
      <SearchBox
        {...otherProps}
        ref={ref}
        onKeyDown={handlePressEnter}
        rightSection={rightSection}
        className={`pr-1 ${className}`}
        value={value}
        debounceTimeout={0}
      />
    );
  },
);
