import { useQuery } from "@tanstack/react-query";
import { contentClient } from "@eolas-medical/core";

interface UseSpaceCompletedContentItemsProps {
  userId: string;
  spaceId?: string;
}

const useSpaceCompletedContentItems = ({ userId, spaceId }: UseSpaceCompletedContentItemsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [`spaceCompletedContentItems-${userId}-${spaceId}`],
    queryFn: () =>
      contentClient.getSpaceCompletedContentItems({
        userId,
        spaceId,
      }),
  });

  return {
    completedContentItems: data || [],
    loading: isLoading,
  };
};

export default useSpaceCompletedContentItems;
