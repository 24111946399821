import { Step } from "UIKit/Wizard";
import { useState } from "react";
import { ImagePickerStep } from "./ImagePickerStep";

interface UseImagePickerStepProps<T> {
  stepName: T;
  title: string;
  onSubmit: (image: { image?: File; imageUrl?: string; isDraft: boolean }) => void;
  errorDraggedImageText: string;
  initialImage?: string;
  shouldAllowBlankSelection?: boolean;
  nextLabel?: string;
}

export const useImagePickerStep = <T extends string>({
  stepName,
  title,
  nextLabel,
  onSubmit,
  initialImage,
  errorDraggedImageText,
  shouldAllowBlankSelection = false,
}: UseImagePickerStepProps<T>): Step<T> => {
  const [image, setImage] = useState<File | undefined>();
  const [imageUrl, setImageUrl] = useState<string | undefined>(initialImage);
  const [canProceed, setCanProceed] = useState(false);

  const handleSubmitStep = async ({ isDraft }: { isDraft: boolean }) => {
    setCanProceed(true);
    onSubmit({ image, imageUrl, isDraft });
  };

  const handleSubmitImage = (file: File | null) => {
    if (file) {
      setImage(file);
    }
  };

  const handleSelectImage = (imageUrl: string) => {
    setImageUrl(imageUrl);
  };

  const nextDisabled = shouldAllowBlankSelection ? false : !(image || imageUrl);

  return {
    name: stepName,
    title,
    nextLabel,
    canProceed,
    onSubmit: () => handleSubmitStep({ isDraft: false }),
    onAltSubmit: () => handleSubmitStep({ isDraft: true }),
    component: (
      <ImagePickerStep
        errorDraggedImageText={errorDraggedImageText}
        onChangeBlob={handleSubmitImage}
        onChangeImageUrl={handleSelectImage}
        defaultImage={initialImage || ""}
        shouldAllowBlankSelection={shouldAllowBlankSelection}
      />
    ),
    nextDisabled,
  };
};
