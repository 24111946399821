import { useState } from "react";
import {
  DetailsFormValues,
  ExternalUrlFormValues,
  ManageSlotWizardState,
  ManageSlotWizardStep,
} from "../types/general";
import { makeInitialWizardState } from "../functions/makeInitialWizardState";
import { SponsoredInfoSection } from "@eolas-medical/core";
import { UseChooseFileTypeStepProps } from "UIKit";
import { useAddSponsoredSlot } from "modules/sponsoredSlots";
import { useEditSponsoredSlot } from "modules/sponsoredSlots/data/useEditSponsoredSlot";

export const useManageSlotActions = ({
  existingSection,
  mainSectionId,
}: {
  mainSectionId: string;
  existingSection?: SponsoredInfoSection;
}) => {
  const [wizardState, setWizardState] = useState<ManageSlotWizardState>(() =>
    makeInitialWizardState(existingSection),
  );

  const {
    addSponsoredSlot,
    isAddingSponsoredSlot,
    isAddingSponsoredSlotSuccess,
    addSponsoredSlotError,
    addSponsoredSlotProgress,
  } = useAddSponsoredSlot({
    mainSectionId,
    parentId: mainSectionId,
  });

  const {
    editSponsoredSlot,
    isEditingSponsoredSlot,
    isEditingSponsoredSlotSuccess,
    editSponsoredSlotError,
    editSponsoredSlotProgress,
  } = useEditSponsoredSlot();

  const onAddDetails = (values: DetailsFormValues) => {
    setWizardState((prevState) => {
      return { ...prevState, ...values };
    });
  };

  const onAddUrl = (values: ExternalUrlFormValues) => {
    setWizardState((prevState) => {
      return { ...prevState, ...values };
    });
  };

  const onAddImage: UseChooseFileTypeStepProps<string>["onSubmit"] = (fileType, file) => {
    const newState = { ...wizardState };
    if (file instanceof File && fileType === "blob") {
      newState.newImage = file;
    }
    setWizardState(newState);
  };

  const onAddSquareImage: UseChooseFileTypeStepProps<string>["onSubmit"] = (
    fileType,
    file,
    isDraft,
  ) => {
    const newState = { ...wizardState };
    if (file instanceof File && fileType === "blob") {
      newState.newSquareImage = file;
    }
    setWizardState(newState);
    addSponsoredSlot({ state: newState, isDraft });
  };

  const onEditImage = ({ blob }: { blob?: File }) => {
    const newWizardState = { ...wizardState };
    if (blob) {
      newWizardState.newImage = blob;
    }
    setWizardState(newWizardState);
  };

  const onEditSquareImage = ({ blob }: { blob?: File }) => {
    const newWizardState = { ...wizardState };
    if (blob) {
      newWizardState.newSquareImage = blob;
    }
    setWizardState(newWizardState);
    editSponsoredSlot({ state: newWizardState });
  };

  const getNextStep = (currentStep: ManageSlotWizardStep): ManageSlotWizardStep => {
    switch (currentStep) {
      case "form-details":
        if (wizardState.type.value === "URL") {
          return "input-url";
        }
        return "banner-select";
      case "input-url": {
        return "banner-select";
      }
      case "banner-select":
        return "square-banner-select";
      case "square-banner-select":
        return "upload-progress-end";
      case "upload-progress-end":
        return "upload-progress-end";
    }
  };

  const getPrevStep = (currentStep: ManageSlotWizardStep): ManageSlotWizardStep => {
    switch (currentStep) {
      case "upload-progress-end":
        return "square-banner-select";
      case "square-banner-select":
        return "banner-select";
      case "banner-select": {
        if (wizardState.type.value === "URL") {
          return "input-url";
        }
        return "form-details";
      }
      case "input-url":
        return "form-details";
      case "form-details":
        return "form-details";
    }
  };

  return {
    wizardState,
    isAddingSponsoredSlot,
    isAddingSponsoredSlotSuccess,
    addSponsoredSlotError,
    addSponsoredSlotProgress,
    isEditingSponsoredSlot,
    isEditingSponsoredSlotSuccess,
    editSponsoredSlotError,
    editSponsoredSlotProgress,
    onAddDetails,
    onAddUrl,
    getNextStep,
    getPrevStep,
    onAddImage,
    onEditImage,
    onAddSquareImage,
    onEditSquareImage,
  };
};
