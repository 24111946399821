import { ConfigureFileStep } from "./ConfigureStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFileDetailsValidationSchema } from "./useFileDetailsValidationSchema";
import { ConfigureFileStepFormValues, ConfigureFileValues } from "./types";
import { Step } from "../../types";
import { useEffect } from "react";

interface UseAddFileDetailsProps<T> {
  stepName: T;
  title: string;
  toggleExpiryCheckText?: string;
  toggleExpiryLabel?: string;
  expiryDatePickerLabel?: string;
  nameInputLabel?: string;
  nameInputPlaceholder?: string;
  keyWordsLabel?: string;
  keyWordsPlaceholder?: string;
  onSubmit: (data: ConfigureFileValues) => void;
  onEnter: () => void;
  isLoading?: boolean;
  nextLabel?: string;
  item: Partial<ConfigureFileValues>;
  backDisabled?: boolean;
  // This name value will be injected into the form if given / changes:
  overrideNameValue?: string;
}

export const useConfigureFileStep = <T extends string>({
  stepName,
  title,
  toggleExpiryCheckText = "",
  toggleExpiryLabel = "",
  expiryDatePickerLabel = "",
  nameInputLabel = "",
  nameInputPlaceholder = "",
  keyWordsLabel = "",
  keyWordsPlaceholder = "",
  nextLabel,
  item,
  isLoading,
  onSubmit,
  onEnter,
  backDisabled = false,
  overrideNameValue,
}: UseAddFileDetailsProps<T>): Step<T> => {
  const validationSchema = useFileDetailsValidationSchema();

  const { handleSubmit, formState, control, getValues, watch, setValue } =
    useForm<ConfigureFileStepFormValues>({
      defaultValues: {
        name: item.name,
        expiryDate: item.expiryDate,
        keywords: item.keywords,
        hasExpiryDate: !!item.expiryDate,
      },
      resolver: yupResolver(validationSchema),
      mode: "onChange",
    });

  const { errors } = formState;

  const canProceed = formState.isValid && !formState.isSubmitting && !isLoading;

  const handleSubmitDetails = ({ isDraft }: { isDraft: boolean }) => {
    const { expiryDate, hasExpiryDate, ...rest } = getValues();
    const toSubmitExpiryDate = hasExpiryDate ? expiryDate : undefined;
    onSubmit({
      expiryDate: toSubmitExpiryDate,
      isDraft,
      ...rest,
    });
  };

  useEffect(() => {
    /**
     * Not ideal to have to update via a useEffect,
     * but issue is that every form in the wizard is mounted together,
     * not as the user progresses to the form step, therefore the form values
     * are already initialised
     */
    if (!overrideNameValue) {
      return;
    }
    const values = getValues();
    if (values.name !== overrideNameValue) {
      setValue("name", overrideNameValue);
    }
  }, [overrideNameValue, setValue, getValues]);

  return {
    name: stepName,
    title,
    submitError: !!errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(() => handleSubmitDetails({ isDraft: false })),
    onAltSubmit: handleSubmit(() => handleSubmitDetails({ isDraft: true })),
    isLoading,
    nextLabel,
    component: (
      <ConfigureFileStep
        control={control}
        toggleExpiryCheckText={toggleExpiryCheckText}
        allowAddingExpiryDate={!!toggleExpiryLabel}
        toggleExpiryLabel={toggleExpiryLabel}
        expiryDatePickerLabel={expiryDatePickerLabel}
        nameInputLabel={nameInputLabel}
        nameInputPlaceholder={nameInputPlaceholder}
        keyWordsLabel={keyWordsLabel}
        keyWordsPlaceholder={keyWordsPlaceholder}
        item={item}
        onEnter={onEnter}
        watch={watch}
        errors={errors}
      />
    ),
    backDisabled,
  };
};
