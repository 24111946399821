import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  eolasLogger,
  fileStore,
  getCompletedChecklistsAndIncidentReportFiles,
  Maybe,
  sectionStore,
  syncCommunityData,
  syncMyFavourites,
  syncSpaceOrgData,
  syncUserData,
  UserData,
  userStore,
} from "@eolas-medical/core";
import { getSupportedRegion } from "./useSupportedRegion";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { addRetries } from "Utilities/helpers";
import { webStore } from "Stores/WebStore";

export const makeUseAppSyncQueryKey = ({
  userData,
  spaceId,
  isLoggedIn,
  isInOrganisation,
}: {
  userData: Maybe<UserData>;
  spaceId: Maybe<string>;
  isLoggedIn: boolean;
  isInOrganisation: boolean;
}) => {
  return [
    "getFullAppData",
    userData && isLoggedIn ? getSupportedRegion(userData) : null,
    userData?.id && isLoggedIn ? userData.id : null,
    isInOrganisation ? spaceId : null,
  ];
};

export const syncWebAppData = async ({
  isFromSoftLogoutState,
}: { isFromSoftLogoutState?: boolean } = {}) => {
  const userData = userStore.userData;
  const spaceId = sectionStore.appID;
  const { isLoggedIn: savedIsLoggedIn, isInOrganisation } = userStore.userSession;
  if (!savedIsLoggedIn && !isFromSoftLogoutState) {
    webStore.setAppLastFetchTimestamp(null);
    return null;
  }
  const isLoggedIn = isFromSoftLogoutState || savedIsLoggedIn;
  const shouldFetchAll = isFromSoftLogoutState || false;
  const region = userData ? getSupportedRegion(userData) : null;
  const shouldFetchSpaceOrgData = Boolean(fileStore.timestamp && spaceId && isInOrganisation);
  const [spaceOrgData] = await Promise.all([
    shouldFetchSpaceOrgData ? syncSpaceOrgData(shouldFetchAll) : null,
    isLoggedIn ? syncUserData() : null,
    isLoggedIn && region ? syncCommunityData({ region }) : null,
  ]);
  if (isLoggedIn && region) {
    addRetries(() => syncMyFavourites({ region }))().catch(eolasLogger.error);
  }
  webStore.setAppLastFetchTimestamp(new Date().toISOString());
  if (spaceOrgData?.appUser.accessLevel === "admin") {
    getCompletedChecklistsAndIncidentReportFiles({
      updatedAt: !shouldFetchAll && fileStore.timestamp ? fileStore.timestamp : undefined,
    })
      .then((files) => {
        fileStore.upsertFiles(files);
      })
      .catch(eolasLogger.error);
  }
  return spaceOrgData;
};

/**
 * Hook for use in App.tsx
 */
export const useAppSync = () => {
  const userData = userStore.userData;
  const spaceId = sectionStore.appID;
  const { isLoggedIn, isInOrganisation } = userStore.userSession;

  const { refetch } = useQuery({
    queryKey: makeUseAppSyncQueryKey({ userData, spaceId, isLoggedIn, isInOrganisation }),
    queryFn: () => syncWebAppData(),
    onSuccess: (spaceOrgData) => {
      if (!spaceOrgData) {
        return;
      }
      localSearchStore.initialiseNewDb();
    },
    staleTime: 30000,
    cacheTime: 500000,
  });

  return { refetch };
};

/**
 * This is just a wrapper hook for now
 * to prevent modifying lots of other files
 */
export const useRefetchAppData = () => {
  const { refetch: mainRefetch } = useAppSync();
  const refetch = useCallback(async () => {
    await mainRefetch();
  }, [mainRefetch]);

  return { refetch };
};
