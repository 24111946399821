import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { Divider, Text } from "UIKit";
import { typedMemo } from "Utilities";

export const SummaryGroup = typedMemo(
  <T extends { id: string }>({
    items,
    title,
    itemTitleProperty,
    makeOnClick,
  }: {
    items: T[];
    itemTitleProperty: Extract<keyof T, string>;
    title: string;
    makeOnClick: (item: T) => () => void;
  }) => {
    return (
      <Collapsible title={title} shouldMountExpanded>
        <Divider>
          {items.map((item) => (
            <div key={item.id} className="flex flex-row py-3">
              <Text level={2} className="cursor-pointer" onClick={makeOnClick(item)}>
                {item[itemTitleProperty] || ""}
              </Text>
            </div>
          ))}
        </Divider>
      </Collapsible>
    );
  },
);
