import { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { AppLevelSection, CompletedContentStatus } from "@eolas-medical/core";

import { useCompleteFile, useMainSectionVisit } from "Hooks";
import { Modal, NavButton, PageTitle, InnerPageWrapper, Button } from "UIKit";

import { SearchSortList, SortFn } from "shared/components/Lists";

import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import useNewsFeeds from "../../data/useNewsFeeds";
import { NewsFeed as NewsFeedItem } from "../../types";
import { NewsItemViewModal } from "Pages/Spaces/components/NewsItems";
import NewsFeedTile from "./components/NewsFeedTile/NewsFeedTile";
import MarkAllAsReadModal from "./components/MarkAllAsReadModal/MarkAllAsReadModal";
import { useShareFile } from "shared/hooks/useShareFile";
import { useSelectNewsFeedItemOnMount } from "../../hooks/useSelectNewsFeedItemOnMount";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

export const NewsFeed = observer(() => {
  const [sortMethod, setSortMethod] = useState<SortFn>();
  const { url } = useRouteMatch();
  useMainSectionVisit({ mainSectionId: AppLevelSection.newsFeed });

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.newsFeed);
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { onCompleteFile } = useCompleteFile();

  const { newsFeeds } = useNewsFeeds();

  const handleClickNewsFeed = useCallback(
    (newsItem: NewsFeedItem) => {
      if (!newsItem.type) {
        onCompleteFile(newsItem.id, CompletedContentStatus.SEEN);
      }
      setModal(<NewsItemViewModal newsItem={newsItem} />);
    },
    [onCompleteFile],
  );

  useSelectNewsFeedItemOnMount({ onSelectFile: handleClickNewsFeed });

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: newsFeeds,
    keysToSearch: ["name", "createdBy"],
  });

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const items = useMemo(() => searchResult.slice().sort(sortMethod), [sortMethod, searchResult]);

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }

    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    setModal(null);
  };

  const renderNewsFeedTile = (newsFeed: NewsFeedItem) => (
    <NewsFeedTile
      newsFeed={newsFeed}
      onClick={handleClickNewsFeed}
      onShare={isShareFileEnabled ? () => handleOnShareFile(newsFeed) : undefined}
      showRemoveFavouriteModal={showRemoveFavouriteModal}
    />
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("newsFeed_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.newsFeed}`, "")} />

        <Button
          color="blue"
          variant="outline"
          iconLeft="TickIcon"
          onClick={() =>
            setModal(
              <MarkAllAsReadModal onClose={handleCloseModal} mainSectionId={spaceSection.id} />,
            )
          }
          className="self-center w-full sm:w-1/3 "
        >
          {t("newsFeed_complete_all")}
        </Button>

        <SearchSortList<NewsFeedItem>
          items={items}
          defaultSort="date"
          renderItem={renderNewsFeedTile}
          isSearchable
          isSortable
          onSearchInputChange={onSetSearchInput}
          onSortMethodChange={(sortMethod?: SortFn) => setSortMethod(() => sortMethod)}
        />
      </InnerPageWrapper>

      <Modal open={isModalOpen} onClose={handleCloseModal} shouldCloseOnOverlayClick={false}>
        {modal || shareModal || removeFavouriteModal}
      </Modal>
    </>
  );
});
