import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { isEmpty } from "lodash";
import { userStore, sectionStore } from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames, BaseAppRoute, ActiveTab } from "Utilities/types";

import NavigationContext, { NavigationContextValue } from "./navigation.context";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getOrganisationRoute, getSpaceRoute } from "Pages/Spaces/helpers";
import { useDetermineVisibleOrganisationTab } from "modules/spaces/data/useDetermineVisibleOrganisationTab";

interface NavigationProviderProps {
  children: React.ReactNode;
}

const NavigationProvider: React.FC<NavigationProviderProps> = observer(
  ({ children }: NavigationProviderProps) => {
    const { push } = useHistory();
    const { flags } = useLaunchDarkly();
    const { activeDepartmentTab } = sectionStore;
    const { appUserData } = userStore;
    const { selectedSpace } = useSpacesContext();
    const { shouldShowOrgTab } = useDetermineVisibleOrganisationTab();
    const [drawerExpanded, setDrawerExpanded] = useState(true);
    const [drawerHidden, setDrawerHidden] = useState(false);

    const hasSpacesTab = activeDepartmentTab;

    const hasSelectedSpace = !isEmpty(appUserData);

    const hasKnowledgeTab = !!flags[LDFlagNames.COMMUNITY_TAB];

    const hasOrganisationTab = shouldShowOrgTab;

    const selectSpaceMatch = useRouteMatch({ exact: true, path: `/${BaseAppRoute.selectSpace}` });
    const meMatch = useRouteMatch({ path: `/${BaseAppRoute.myProfile}` });
    const spaceMatch = useRouteMatch({ path: `/${BaseAppRoute.spaces}/:spaceName` });
    const organisationMatch = useRouteMatch({
      path: `/${BaseAppRoute.organisation}/:organisationName`,
    });
    const knowledgeMatch = useRouteMatch({ path: `/${BaseAppRoute.knowledge}` });

    const {
      isOnSpacesRoot,
      isOnSelectSpaceRoot,
      isOnOrganisationRoot,
      isOnMeRoot,
      isOnKnowledgeRoot,
    } = useMemo(() => {
      const isOnSpacesRoot = spaceMatch?.isExact || false;
      const isOnSelectSpaceRoot = selectSpaceMatch?.isExact || false;
      const isOnOrganisationRoot = organisationMatch?.isExact || false;
      const isOnMeRoot = meMatch?.isExact || false;
      const isOnKnowledgeRoot = knowledgeMatch?.isExact || false;

      return {
        isOnSpacesRoot,
        isOnSelectSpaceRoot,
        isOnOrganisationRoot,
        isOnMeRoot,
        isOnKnowledgeRoot,
      };
    }, [selectSpaceMatch, meMatch, spaceMatch, organisationMatch, knowledgeMatch]);

    const activeTab: ActiveTab = useMemo((): ActiveTab => {
      if (meMatch) {
        return "my-profile";
      }

      if (selectSpaceMatch) {
        return "select-space";
      }

      if (spaceMatch) {
        return "spaces";
      }

      if (organisationMatch) {
        return "organisation";
      }

      if (knowledgeMatch) {
        return "knowledge";
      }

      return hasSpacesTab ? "spaces" : "organisation";
    }, [meMatch, organisationMatch, selectSpaceMatch, spaceMatch, knowledgeMatch, hasSpacesTab]);

    const goMeTab = React.useCallback(() => {
      push(`/${BaseAppRoute.myProfile}`);
    }, [push]);

    const goToSelectSpace = React.useCallback(() => {
      push(`/${BaseAppRoute.selectSpace}`);
    }, [push]);

    const goToSpaceTab = React.useCallback(() => {
      push(getSpaceRoute(selectedSpace));
    }, [selectedSpace, push]);

    const goToOrganisationTab = React.useCallback(() => {
      push(getOrganisationRoute(selectedSpace));
    }, [selectedSpace, push]);

    const goToKnowledgeTab = React.useCallback(() => {
      push(`/${BaseAppRoute.knowledge}`);
    }, [push]);

    const toggleDrawerExpanded = useCallback(
      () => setDrawerExpanded((drawerExpanded) => !drawerExpanded),
      [],
    );

    const toggleDrawerHidden = useCallback((hide: boolean) => setDrawerHidden(hide), []);

    const navigationValue: NavigationContextValue = {
      activeTab,
      drawerExpanded,
      drawerHidden,
      hasSpacesTab,
      hasOrganisationTab,
      hasSelectedSpace,
      hasKnowledgeTab,
      isOnSpacesRoot,
      isOnSelectSpaceRoot,
      isOnOrganisationRoot,
      isOnMeRoot,
      isOnKnowledgeRoot,
      goMeTab,
      goToOrganisationTab,
      goToSpaceTab,
      goToSelectSpace,
      goToKnowledgeTab,
      toggleDrawerExpanded,
      toggleDrawerHidden,
    };

    return (
      <NavigationContext.Provider value={navigationValue}>{children}</NavigationContext.Provider>
    );
  },
);

export default NavigationProvider;
