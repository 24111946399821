import { useTranslation } from "react-i18next";
import { InnerPageWrapper, Text, PageTitle } from "UIKit";

export const NoAccessToSpace = () => {
  const { t } = useTranslation();

  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-16"
        data-testid="portfolio-error-fallback"
      >
        <PageTitle title={t("linked_documents_viewer_access_denied")} />
        <Text level={1}>{t("linked_documents_viewer_no_access_message")}</Text>
      </div>
    </InnerPageWrapper>
  );
};
