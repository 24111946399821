import { FormItem } from "UIKit";
import { useTranslation } from "react-i18next";
import { SpecialtyOptions, specialtyOptions } from "@eolas-medical/core";
import { Option } from "UIKit/Select/Select";

export type FlashcardFormStep = {
  title: string;
  description: string;
  specialty?: SpecialtyOptions[];
  queriesForHit?: Option[];
  documentIdentifiers?: Option[];
};

const useFormStepConfig = ({
  hasSpecialties,
  hasCopilotData,
}: {
  hasSpecialties?: boolean;
  hasCopilotData?: boolean;
}) => {
  const { t } = useTranslation();

  const titleField: FormItem<{ title: string }> = {
    fieldName: "title",
    fieldLabel: t("flashcards_title_label"),
    fieldPlaceholder: t("flashcards_title_placeholder"),
    itemType: "input",
    required: true,
  };

  const descriptionField: FormItem<{ description: string }> = {
    fieldName: "description",
    fieldLabel: t("flashcards_description_label"),
    fieldPlaceholder: t("flashcards_description_placeholder"),
    itemType: "text-area",
    required: true,
  };

  const specialtyField: FormItem<{ specialty?: SpecialtyOptions[] }> = {
    fieldName: "specialty",
    fieldLabel: t("flashcards_specialty_label"),
    fieldPlaceholder: t("flashcards_specialty_placeholder"),
    required: true,
    itemType: "select",
    isMultiSelect: true,
    selectOptions: specialtyOptions,
  };

  const queriesForHitField: FormItem<{ queriesForHit?: Option[] }> = {
    fieldName: "queriesForHit",
    fieldLabel: t("flashcards_queries_for_hit"),
    fieldPlaceholder: "",
    required: true,
    itemType: "eolas-select",
    isMultiSelect: true,
    selectOptions: [],
    isSearchable: true,
    shouldAllowCreateInSelect: true,
  };

  const documentIdentifiersField: FormItem<{ documentIdentifiers?: Option[] }> = {
    fieldName: "documentIdentifiers",
    fieldLabel: t("flashcards_document_identifiers"),
    fieldPlaceholder: "",
    required: true,
    itemType: "eolas-select",
    isMultiSelect: true,
    selectOptions: [],
    isSearchable: true,
    shouldAllowCreateInSelect: true,
  };

  let form: FormItem<FlashcardFormStep>[] = [titleField, descriptionField];

  if (hasSpecialties) {
    form = [...form, specialtyField];
  }

  if (hasCopilotData) {
    form = [...form, queriesForHitField, documentIdentifiersField];
  }

  return form;
};

export default useFormStepConfig;
