import { ToggleMainSectionDto, contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { useRefetchAppData } from "Hooks";

const useToggleMainSection = () => {
  const { refetch } = useRefetchAppData();
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ id, disabled }: ToggleMainSectionDto) => {
      await contentClient.toggleMainSection({ id, disabled });
    },
    {
      onSuccess: refetch,
    },
  );

  return {
    toggleMainSection: mutate,
    togglingMainSection: isLoading,
    toggleMainSectionSuccess: isSuccess,
    toggleMainSectionError: error instanceof Error ? error.message : "",
  };
};

export default useToggleMainSection;
