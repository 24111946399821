import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import {
  EolasFile,
  fileStore,
  sectionStore,
  AppLevelSection,
  MajorIncidentType,
  MajorIncidentSubSection,
} from "@eolas-medical/core";

import { Button, DeleteModal, Modal } from "UIKit";

import { useMajorIncident } from "../hooks";

const labelStyles = "font-semibold";
const propertyStyles = "mb-4";

interface IncidentDetailsProps {
  incident: EolasFile;
}
const IncidentDetails: React.FC<IncidentDetailsProps> = ({ incident }: IncidentDetailsProps) => {
  const [modal, setShowModal] = useState<React.ReactNode>(null);
  const { t } = useTranslation();
  const info = JSON.parse(incident.info ?? "");
  const { error, isLoading, onStandDown, isSuccessful } = useMajorIncident(incident);

  if (incident.moreInfoTwo === MajorIncidentType.deactivated) {
    return null;
  }

  return (
    <div
      key={incident.id}
      className="flex flex-col rounded-lg border-2 bg-white border-grey-300 p-4"
    >
      <Button
        weight="bold"
        variant="outline"
        isLoading={isLoading}
        className="mb-4 sm:self-center"
        onClick={() => setShowModal(true)}
        data-testid={`stand-down-for-${incident.name}`}
      >
        {t("incidents_stand_down_incident_button")}
      </Button>

      {error && <span className="text-center text-red">{error}</span>}

      <span className={labelStyles}>{t("incidents_add_incident_title_label")}</span>
      <span className={propertyStyles}>{incident.name}</span>

      {info.exactLocation && (
        <>
          <span className={labelStyles}>{t("incidents_add_incident_location_label")}</span>
          <span className={propertyStyles}>{info.exactLocation}</span>
        </>
      )}

      {info.typeOfIncident && (
        <>
          <span className={labelStyles}>{t("incidents_add_incident_type_label")}</span>
          <span className={propertyStyles}>{info.typeOfIncident}</span>
        </>
      )}

      {info.hazards && (
        <>
          <span className={labelStyles}>{t("incidents_add_incident_hazards_label")}</span>
          <span className={propertyStyles}>{info.hazards}</span>
        </>
      )}

      {info.access && (
        <>
          <span className={labelStyles}>{t("incidents_add_incident_access_label")}</span>
          <span className={propertyStyles}>{info.access}</span>
        </>
      )}

      {info.numberOfCasualties && (
        <>
          <span className={labelStyles}>{t("incidents_add_incident_casualties_label")}</span>
          <span className={propertyStyles}>{info.numberOfCasualties}</span>
        </>
      )}

      {info.emergencyServices && (
        <>
          <span className={labelStyles}>
            {t("incidents_add_incident_emergency_services_label")}
          </span>
          <span className={propertyStyles}>{info.emergencyServices}</span>
        </>
      )}

      <Modal open={!!modal} onClose={() => setShowModal(null)}>
        <DeleteModal
          error={error}
          onDelete={onStandDown}
          isLoading={isLoading}
          isSuccessful={isSuccessful}
          title={incident.name}
          onCloseModal={() => setShowModal(null)}
          description={t("deleteModal_delete_majorIncident_description")}
        />
      </Modal>
    </div>
  );
};

export const MajorIncidentsList = observer(() => {
  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.majorIncidents)!;

  const { id: incidentsSectionID } = sectionStore
    .getChildrenOrder(id)
    .find((child) => child?.icon === MajorIncidentSubSection.incidents)!;

  const allIncidents = fileStore.getFiles(sectionStore.getChildrenOrder(incidentsSectionID));

  return (
    <div className="flex flex-col space-y-4">
      {allIncidents.map(
        (incident) =>
          incident.moreInfoTwo === "active" && (
            <IncidentDetails key={incident.id} incident={incident} />
          ),
      )}
    </div>
  );
});
