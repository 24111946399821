import { ContentItem, fileStore, sectionStore } from "@eolas-medical/core";
import { ContentRepositoryId } from "../types";
import { mapToContentItem } from "../helpers";

interface UseContentItemsProps {
  contentRepositoryId: ContentRepositoryId;
  subSectionId?: string;
}

interface UseContentItems {
  contentItems: ContentItem[];
  contentItemsLoading: false;
}
const useContentItems = ({
  contentRepositoryId,
  subSectionId,
}: UseContentItemsProps): UseContentItems => {
  const childrenOrder = sectionStore.getChildrenOrder(subSectionId || contentRepositoryId);
  const items = fileStore.getFiles(childrenOrder ?? []).map(mapToContentItem);

  return {
    contentItems: items,
    contentItemsLoading: false,
  };
};

export default useContentItems;
