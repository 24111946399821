import { SponsoredSlot, hasStringProp } from "@eolas-medical/core";

export const isSupportedFileExtension = (
  ext: string | undefined,
): ext is "jpg" | "jpeg" | "png" => {
  if (!ext) {
    return false;
  }

  return ["jpg", "jpeg", "png"].includes(ext);
};

export const isUserSponsoredSlot = (item: unknown): item is SponsoredSlot => {
  return hasStringProp(item, "bannerUrl") && hasStringProp(item, "squareBannerUrl");
};
