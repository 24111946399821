import { KnowledgeContentRepository } from "Pages/Knowledge/pages/KnowledgeContent";
import { analyticsCallbacks } from "./config/analyticsCallbacks";

export const ClinicalQuestionsContentRepository = () => {
  return (
    <KnowledgeContentRepository
      {...analyticsCallbacks}
      domain="clinicalQuestions"
      hasCopilotData
      shouldAllowBlankHeroImageSelection
    />
  );
};
