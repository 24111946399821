import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormError, PasswordInput, PasswordInputValidator } from "UIKit";
import { useEnterKeySubmit } from "Hooks/useEnterKeySubmit/useEnterKeySubmit";

interface CreatePasswordModalProps {
  control: Control<{ password: string }>;
  error?: string;
  watch: (field: string) => string;
  onSubmit: () => void;
}

const CreatePasswordModal = ({ control, error, watch, onSubmit }: CreatePasswordModalProps) => {
  const { t } = useTranslation();

  const password = watch("password");

  useEnterKeySubmit(onSubmit);

  return (
    <div data-testid="create-password-modal">
      <form onSubmit={onSubmit} className="flex flex-col space-y-6">
        <PasswordInputValidator
          control={control}
          data-testid="sign-up-password"
          placeholder={t("login_enterPassword")}
          password={password}
        />
        <PasswordInput
          control={control}
          data-testid="sign-up-confirm-password"
          placeholder={t("login_verifyPassword")}
          isConfirmPassword
          id="confirmPassword"
        />
        {error && <FormError error={error} />}
      </form>
    </div>
  );
};

export default CreatePasswordModal;
