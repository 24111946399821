import { trackEvent } from "API/Analytics";
import { useNiceGuidelineViewer } from "../hooks/useNiceGuidelineViewer";
import { FileViewerComponent } from "Components";
import { Modal, NavButton } from "UIKit";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AnalyticsEvents } from "@eolas-medical/core";

export const NiceGuidelineViewer = () => {
  const { id } = useParamsDecoded<{ id: string }>();
  const { viewer, handleBackClick, title } = useNiceGuidelineViewer(id);
  const history = useHistory();

  useEffect(() => {
    if (!title) {
      return;
    }
    trackEvent(AnalyticsEvents.NICE_PAGE_VISITED, { id, title, type: "Page" });
  }, [id, title]);

  return (
    <>
      <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={history.goBack}>
        <div className="h-100vh w-100vw">
          <div className="bg-grey-light py-1">
            <div className="flex justify-center space-x-3">
              <NavButton
                onClick={() => {
                  handleBackClick();
                  history.goBack();
                }}
              />
            </div>
          </div>

          <div className="bg-grey-light" style={{ height: "94%" }}>
            <FileViewerComponent key={id} viewer={viewer} />
          </div>
        </div>
      </Modal>
    </>
  );
};
