import { accessClient } from "@eolas-medical/core";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useGetRelevantSpaceDetails } from "Pages/Spaces/pages/Space/pages/ManageUsers/hooks/useGetRelevantSpaceDetails";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";
import { makeGetAccessRequestsKey } from "./useGetAllAccessRequests";

export const useAccessRequests = () => {
  const queryClient = useQueryClient();

  const { spaceId } = useGetRelevantSpaceDetails();

  const acceptMutation = useMutation({
    mutationFn: async (userId: string) => {
      if (!spaceId) {
        throw new Error("useAccessRequests: spaceId is null");
      }
      return accessClient.approveDepartmentAccess(userId, spaceId);
    },
    onSuccess: () => {
      queryClient.refetchQueries(makeGetAccessRequestsKey(spaceId));
      queryClient.refetchQueries(spacesKeys.usersList(spaceId ?? ""));
    },
  });

  const declineMutation = useMutation({
    mutationFn: async (accessRequestId: string) => {
      return accessClient.rejectDepartmentAccess(accessRequestId);
    },
    onSuccess: () => {
      queryClient.refetchQueries(makeGetAccessRequestsKey(spaceId));
      queryClient.refetchQueries(spacesKeys.usersList(spaceId ?? ""));
    },
  });

  const acceptError = acceptMutation.error instanceof Error ? acceptMutation.error.message : "";
  const declineError = declineMutation.error instanceof Error ? declineMutation.error.message : "";

  return {
    acceptError,
    acceptLoading: acceptMutation.isLoading,
    onAcceptRequest: acceptMutation.mutate,
    declineError,
    declineLoading: declineMutation.isLoading,
    onDeclineRequest: declineMutation.mutate,
  };
};
