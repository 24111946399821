import { useState, useCallback } from "react";
import { InputTypeButton, Loader, Text } from "UIKit";
import { GalleryIcon, UploadImageIcon } from "Assets/Icons";
import ImageGallery from "UIKit/ImageGallery/ImageGallery";
import { ImageUploader } from "UIKit/ImageUploader/ImageUploader";
import { useTranslation } from "react-i18next";
import { ImageBankItem } from "Hooks/useImageBank";
import { compareUrls } from "Utilities/helpers";

type FileSource = "gallery" | "upload";

export type DefaultImage = {
  url: string;
  isCustomImage: boolean;
};

type ImagePickerWithGalleryProps = {
  galleryImages: ImageBankItem[];
  errorDraggedImageText: string;
  onChangeBlob: (file: File | null) => void;
  onChangeImageUrl: (url: string) => void;
  isLoading: boolean;
  error?: string;
  cropperAspectRatio?: number;
  defaultImage?: DefaultImage;
  shouldAllowBlankSelection: boolean;
  defaultFile?: File;
};

const ImagePicker = ({
  galleryImages,
  errorDraggedImageText,
  onChangeBlob,
  onChangeImageUrl,
  isLoading,
  error,
  cropperAspectRatio = NaN,
  defaultImage,
  shouldAllowBlankSelection,
  defaultFile,
}: ImagePickerWithGalleryProps) => {
  const { t } = useTranslation();
  const [fileSource, setFileSource] = useState<FileSource>(
    defaultImage?.isCustomImage || defaultFile ? "upload" : "gallery",
  );

  const [selectedIndex, setSelectedIndex] = useState<number | null>(() => {
    if (!defaultImage) {
      return null;
    }
    return (
      galleryImages.findIndex((image) => compareUrls(image.imageUrl, defaultImage.url)) ?? null
    );
  });

  const onClickGalleryImage = useCallback(
    (index: number) => {
      if (index === selectedIndex) {
        if (shouldAllowBlankSelection) {
          setSelectedIndex(null);
          onChangeImageUrl("");
        }
        return;
      }
      setSelectedIndex(index);
      onChangeImageUrl(galleryImages[index].imageUrl);
    },
    [selectedIndex, shouldAllowBlankSelection, galleryImages, onChangeImageUrl],
  );

  const isGallerySelected = fileSource === "gallery";

  if (isLoading) {
    return <Loader className="bg-transparent" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-1 justify-between items-center space-x-4">
        <InputTypeButton
          isSelected={fileSource === "gallery"}
          icon={<GalleryIcon width={32} height={32} />}
          onClick={() => setFileSource("gallery")}
          className={`w-full ${fileSource === "gallery" ? "shadow-md" : ""}`}
        >
          <Text level={1}>{t("general_gallery")}</Text>
        </InputTypeButton>
        <InputTypeButton
          isSelected={fileSource === "upload"}
          icon={<UploadImageIcon width={32} height={32} />}
          onClick={() => setFileSource("upload")}
          className={`w-full ${fileSource === "upload" ? "shadow-md" : ""}`}
        >
          <Text level={1}>{t("general_upload_image")}</Text>
        </InputTypeButton>
      </div>
      {isGallerySelected ? (
        <ImageGallery
          galleryImages={galleryImages}
          selectedIndex={selectedIndex}
          onClickGalleryImage={onClickGalleryImage}
        />
      ) : (
        <div className="col-span-2">
          <ImageUploader
            onChange={onChangeBlob}
            fileToLargeError={errorDraggedImageText}
            error={error}
            defaultValue={
              defaultFile || (defaultImage?.isCustomImage ? defaultImage?.url : undefined)
            }
            croppingEnabled
            shouldCompress={false}
            cropperAspectRatio={cropperAspectRatio}
          />
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
