import { useTranslation } from "react-i18next";
import { MAX_SUMMARY_OF_CHANGES_LENGTH } from "shared/constants";
import * as yup from "yup";

export const useSummaryOfChangesStepValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    summaryOfChanges: yup
      .string()
      .test(
        "summaryOfChangesLength",
        t("validation_max_length", { maxLength: MAX_SUMMARY_OF_CHANGES_LENGTH }),
        function (newValue) {
          return newValue ? newValue.length < MAX_SUMMARY_OF_CHANGES_LENGTH : true;
        },
      ),
  });
};
