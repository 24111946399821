/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { forwardRef, useCallback } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";
import ReactDatePicker, { ReactDatePickerProps, CalendarContainer } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.styles.css";

import { ArrowLeftIcon, ArrowRightIcon, CalendarMonoIcon, ClockIcon } from "Assets";

import { Select } from "../Select";
import { Input, InputSize } from "../Input";
import { Title } from "../../Typography";

export interface DatePrickerProps extends ReactDatePickerProps {
  size?: InputSize;
  error?: string;
  showTime?: boolean;
  isValid?(value?: string): boolean;
  customWrapper?: React.ReactNode;
}

const DateInput = forwardRef(function DateInput(
  { value = "", onClick, placeholder, error, size, className, ...props }: any,
  ref: any,
) {
  return (
    <Input
      ref={ref}
      size={size}
      value={value}
      onClick={onClick}
      onChange={() => {
        return;
      }}
      placeholder={placeholder}
      inputClassName="cursor-pointer"
      iconRight={<CalendarMonoIcon />}
      className={`
          ${!value ? "text-grey" : ""}
          ${error ? "border-red-focus focus-within:border-red-focus" : ""}
        `}
      {...props}
    />
  );
});

interface CustomContainerProps {
  children: React.ReactNode;
  className?: string;
}
const CustomContainer: React.FC<CustomContainerProps> = ({
  className,
  children,
}: CustomContainerProps) => {
  return (
    <div
      data-testid="date-picker-container"
      className="border border-grey-400 rounded-lg bg-white p-4 sm:py-4 sm:px-8"
    >
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  );
};

interface CustomHeader {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  monthDate: Date;
}
export const DatePicker: React.FC<DatePrickerProps> = ({
  customWrapper,
  size,
  value,
  error,
  onChange,
  showTime,
  isValid = () => true,
  wrapperClassName = "",
  showPopperArrow = false,
  dateFormat = "dd MMM yyyy",
  placeholderText = "incident_reporting_date_placeholder",
  ...props
}: DatePrickerProps) => {
  const { t } = useTranslation();

  const renderCustomHeader = useCallback(
    ({ decreaseMonth, increaseMonth, monthDate }: CustomHeader) => {
      return (
        <div className="flex mb-2">
          <ArrowLeftIcon
            width={32}
            onClick={decreaseMonth}
            data-testid="decrease-month"
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
          />

          <Title level={8} className="w-full">
            {format(monthDate, "MMMM yyyy")}
          </Title>

          <ArrowRightIcon
            width={32}
            onClick={increaseMonth}
            data-testid="increase-month"
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
          />
        </div>
      );
    },
    [],
  );

  return (
    <ReactDatePicker
      className="mt-0"
      onChange={onChange}
      dateFormat={dateFormat}
      showTimeInput={showTime}
      showTimeSelect={showTime}
      calendarClassName="font-nunito"
      showPopperArrow={showPopperArrow}
      calendarContainer={CustomContainer}
      placeholderText={t(placeholderText)}
      customTimeInput={<CustomTimeInput />}
      renderCustomHeader={renderCustomHeader}
      wrapperClassName={tw(`w-full mt-0 ${wrapperClassName}`)}
      selected={isValid(value) && value ? new Date(value) : null}
      customInput={customWrapper ?? <DateInput onBlur={props.onBlur} size={size} error={error} />}
      {...props}
    />
  );
};

const dateOptions = Array.from({ length: 24 }).flatMap((_, index) => {
  const fullHour = `${index.toString().padStart(2, "0")}:${"00"}`;
  const halfHour = `${index.toString().padStart(2, "0")}:${"30"}`;

  return [
    { label: fullHour, value: fullHour },
    { label: halfHour, value: halfHour },
  ];
});

const CustomTimeInput = ({ onChange }: any) => {
  return (
    <div className="flex items-center space-x-2">
      <ClockIcon width={28} height={28} />
      <Select
        placeholder="Select time"
        maxMenuHeight={250}
        options={dateOptions}
        onChange={(opt) => {
          onChange(opt?.value);
        }}
      />
    </div>
  );
};
