import { useTranslation } from "react-i18next";

import { NormalStepComponentProps, NormalStepData } from "../../types";
import { DsmError } from "shared/pages/ContentRepository/ContentItems/functions/parseDsmSheet/helpers";
import { DsmErrorStep } from "shared/pages/ContentRepository/components/DsmErrorStep";
import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";

const DsmErrorComponent = ({ wizardState, onClickBack }: NormalStepComponentProps) => {
  const { t } = useTranslation();

  let errorMessage = "";

  if (wizardState.error instanceof DsmError) {
    errorMessage =
      wizardState.error.code === "other"
        ? t("repository_admin_error_generic")
        : wizardState.error.message;
  }

  return (
    <EolasWizardModalWrapper
      header={{ title: t("repository_admin_error_title") }}
      buttonRowProps={{
        previousButton: { onClick: onClickBack },
        forwardButton: { onClick: () => undefined, disabled: true },
      }}
    >
      <DsmErrorStep errorMessage={errorMessage} />
    </EolasWizardModalWrapper>
  );
};

export const dsmErrorComponentData: NormalStepData = {
  StepComponent: DsmErrorComponent,
  props: {
    getPreviousStepName: () => "selectFile",
    getNextStepName: (name) => name,
  },
};
