import { SpaceUser } from "clients/departments/types";
import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { InnerModalWrapper, ModalHeader, Title, ModalBody } from "UIKit";

export interface UserBreakdownProps {
  users: Array<SpaceUser>;
}

export const UserBreakdownModal = ({ users }: UserBreakdownProps) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const admins = users.filter((user) => user.accessLevel === "admin").length;
    const readOnly = users.filter((user) => user.accessLevel === "User").length;
    return {
      labels: [t("manageUsers_users_standard_user"), t("manageUsers_users_admin_user")],
      datasets: [
        {
          data: [readOnly, admins],
          backgroundColor: ["#48db69", "#FFDF00"],
        },
      ],
    };
  }, [users, t]);

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}> {t("manageUsers_usersBreakdown_title")}</Title>
      </ModalHeader>
      <ModalBody className="align-center">
        <div className="lg:w-96 self-center">
          <Doughnut data={data} type="line" />
        </div>
        <div className="flex flex-col items-center mt-12">
          <span className="font-semibold text-lg">{users.length}</span>
          <span className="text-sm">{t("manageUsers_usersBreakdown_totalUsers")}</span>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
