import {
  AnalyticsEvents,
  Space,
  SpaceUpdateDto,
  SpecialtyOptions,
  UserLocation,
} from "@eolas-medical/core";
import { Option } from "UIKit/Select/Select";
import useWizard from "UIKit/Wizard/useWizard";
import { Step, Wizard } from "UIKit";
import { trackEvent } from "API/Analytics";
import { useSpaceDetailsStep } from "Pages/Spaces/pages/SelectSpace/components/CreateSpaceWizard/components/SpaceDetails/useSpaceDetailsStep";
import { useSpaceImageStep } from "Pages/Spaces/pages/SelectSpace/components/CreateSpaceWizard/components/SpaceImage/useSpaceImageStep";
import { useSpaceAccessTypeStep } from "Pages/Spaces/components/AccessTypeForm/useSpaceAccessTypeStep";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface SpaceUpdate {
  name: string;
  description: string;
  specialty: SpecialtyOptions[] | undefined;
  imageUrl?: string;
}

type UpdateSpaceSteps = "data" | "image" | "access";

interface EditSpaceDetailsWizardProps {
  onUpdate: (space: SpaceUpdateDto) => void;
  isLoading: boolean;
  isOrgAdminSpace?: boolean;
  selectedSpace: Space;
}

interface WizardState {
  name: string;
  description: string;
  specialty?: Option[];
  country: string;
  city: string;
  url: string;
}

type EditSpaceSteps = "details" | "image" | "access";

const EditSpaceDetailsWizard = ({
  onUpdate,
  isLoading,
  isOrgAdminSpace = false,
  selectedSpace,
}: EditSpaceDetailsWizardProps) => {
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<UpdateSpaceSteps>();
  const spaceImage = selectedSpace?.image;
  const defaultImageUrl = !spaceImage?.isCustomImage ? (spaceImage?.url as string) : undefined;

  const spaceIsPublic = selectedSpace?.accessType === "public";

  const [isPublic, setIsPublic] = useState<boolean>(spaceIsPublic);

  const [wizardState, setWizardState] = useState<WizardState>({
    name: "",
    description: "",
    country: "",
    city: "",
    specialty: [],
    url: "",
  });

  const handleEditSpace = ({ image, imageUrl }: { image?: File; imageUrl?: string } = {}) => {
    const spaceSpecialties = wizardState?.specialty
      ? wizardState?.specialty.map(({ value }: Option) => value)
      : [];
    const updateSpaceParams: SpaceUpdateDto = {
      ...wizardState,
      accessType: isPublic ? "public" : "private",
      specialty: spaceSpecialties,
    };

    if (imageUrl) {
      updateSpaceParams.url = imageUrl || "";
    }

    if (image) {
      updateSpaceParams.file = image;
    }

    onUpdate(updateSpaceParams);
  };

  const handleSubmitImageStep = ({ image, imageUrl }: { image?: File; imageUrl?: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      file: image,
      url: imageUrl || "",
    }));
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_IMAGE_COMPLETED);

    if (isOrgAdminSpace) handleEditSpace({ image, imageUrl });
  };

  const handleSubmitAccessTypeStep = () => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_ACCESS_TYPE_COMPLETED);
    handleEditSpace();
  };

  const handleEditSpaceDetails = ({
    name,
    location,
    specialty,
    description,
  }: {
    name: string;
    location?: UserLocation;
    description: string;
    specialty?: Option[];
  }) => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
    setWizardState((prev: WizardState) => ({
      ...prev,
      name,
      city: location?.city || "",
      country: location?.country || "",
      specialty,
      description,
    }));
  };

  const handleChangeAccessType = ({ isPublic }: { isPublic: boolean }) => {
    setIsPublic(isPublic);
  };

  const spaceDetailsStep = useSpaceDetailsStep({
    onSubmit: handleEditSpaceDetails,
    onEnter: submitWizard,
    selectedSpace,
    isEditing: true,
    isOrgAdminSpace,
  });

  const spaceImageStep = useSpaceImageStep({
    onSubmit: handleSubmitImageStep,
    defaultImage: spaceImage,
    defaultImageUrl: defaultImageUrl,
    isEditing: true,
    nextLabel: isOrgAdminSpace ? t("general_done") : undefined,
    isLoading,
  });

  const spaceAccessTypeStep = useSpaceAccessTypeStep({
    onSubmit: handleSubmitAccessTypeStep,
    spaceName: wizardState.name,
    onChange: handleChangeAccessType,
    isPublic,
    isLoading,
    // error: errorEditingSpace, // handle error
  });

  const steps: Step<EditSpaceSteps>[] = [
    spaceDetailsStep,
    spaceImageStep,
    ...(isOrgAdminSpace ? [] : [spaceAccessTypeStep]),
  ];

  return <Wizard wizardControl={wizardControl} steps={steps} showProgress showSteps />;
};

export default EditSpaceDetailsWizard;
