import { useTranslation } from "react-i18next";
import React, { useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { AddButton, Modal, FormFieldError } from "UIKit";

import { AddNewSection } from "./AddNewSection";
import { useBuilderSectionList } from "../hooks";
import { BuilderSectionItem } from "./BuilderSectionItem";
import { DeleteChecklistSectionModal } from "./DeleteChecklistSectionModal";
import { SortableList } from "shared/components/Lists";

export const BuilderSectionList = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const {
    sections,
    isAddingNewSection,
    checklistSectionsError,
    onSwap,
    onAddSection,
    onAddNewSection,
    onDeleteSection,
    setIsAddingNewSection,
  } = useBuilderSectionList();

  const onCloseModal = useCallback(() => setModal(null), [setModal]);

  const onDelete = useCallback(
    (sectionIndex: number, sectionName: string) => () => {
      setModal(
        <DeleteChecklistSectionModal
          title={sectionName}
          onDelete={() => {
            onDeleteSection(sectionIndex);
            onCloseModal();
          }}
        />,
      );
    },
    [onDeleteSection, onCloseModal],
  );

  return (
    <>
      <AnimatePresence initial={false} exitBeforeEnter>
        {isAddingNewSection ? (
          <AddNewSection
            onSave={onAddSection}
            onCancel={() => setIsAddingNewSection(false)}
            checklistSectionsError={checklistSectionsError}
          />
        ) : (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex flex-col items-center my-4 sm:my-6"
          >
            <AddButton
              color="grey"
              onClick={onAddNewSection}
              className={`w-full sm:w-1/3 ${checklistSectionsError ? "input-error" : ""}`}
            >
              {t("checklistBuilder_add_new_section_button")}
            </AddButton>
            {checklistSectionsError && (
              <FormFieldError className="mt-2">{checklistSectionsError}</FormFieldError>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <SortableList
        onDragEnd={onSwap}
        droppableClassname="space-y-4"
        droppableId="checklist-builder-sections"
      >
        {sections.map((checklistSection, index) => {
          return (
            <BuilderSectionItem
              index={index}
              key={checklistSection.id}
              section={checklistSection}
              onDelete={onDelete(index, checklistSection.checklistSectionName)}
            />
          );
        })}
      </SortableList>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
