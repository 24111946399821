import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Title, Button, Text, Modal, ConfirmationModal } from "UIKit";
import { AttentionIcon, CaretUpIcon, SkipIcon, CheckSlimIcon } from "Assets";

import { ChecklistSection } from "../../checklistBuilder/types";

import { useSection } from "../hooks";
import { SectionItem } from "./SectionItem";

export interface SectionProps {
  sectionIndex: number;
  section: ChecklistSection;
}

export const Section: React.FC<SectionProps> = ({ sectionIndex, section }: SectionProps) => {
  const { t } = useTranslation();
  const {
    isSkipped,
    isComplete,
    modalState,
    isExpanded,
    isIncomplete,
    onConfirm,
    onCloseModal,
    toggleSkipped,
    toggleExpanded,
    markAsComplete,
    onClearSection,
  } = useSection(sectionIndex);

  const sectionCardClass = useMemo(() => {
    if (isSkipped) return "border-2 border-grey-400 bg-grey-200 filter grayscale";
    if (isIncomplete) return "border-2 border-yellow-200 bg-white";
    return "border border-grey-300 bg-white";
  }, [isSkipped, isIncomplete]);

  return (
    <>
      <div
        className={`
        flex flex-col shadow-sm rounded-md py-4 px-0 sm:px-6 box-border
        ${isExpanded ? "pb-0" : ""}
        ${sectionCardClass}
      `}
      >
        <div onClick={toggleExpanded} className="flex items-center cursor-pointer px-4 sm:px-0">
          <CaretUpIcon
            className={`
            w-6 h-6 sm:w-8 sm:h-8 text-quartz-600 transform transition-transform duration-300
            -ml-2 mr-2
            ${isExpanded ? "rotate-0" : "rotate-180"}
          `}
          />

          <Title level={7} className={"flex-1 line-clamp-1"}>
            {section.checklistSectionName}
          </Title>

          {isIncomplete && (
            <div className="flex items-center space-x-2 text-yellow-600">
              <AttentionIcon className="h-4 w-4" />
              <Text level={3} className="hidden sm:inline">
                {t("checklistSubmission_incomplete_label")}
              </Text>
            </div>
          )}

          <Button
            color={isSkipped ? "black" : "grey"}
            variant={isSkipped ? "solid" : "outline"}
            onClick={isSkipped ? toggleSkipped : onConfirm("confirmSkip")}
            className="ml-2 rounded-full h-6 w-6 sm:h-8 sm:w-28 sm:rounded-lg px-1"
          >
            <SkipIcon className="w-4 h-4 mr-0 sm:mr-2" />
            <span className="hidden sm:inline text-sm font-semibold">
              {t(
                isSkipped ? "checklistSubmission_skipped_label" : "checklistSubmission_skip_label",
              )}
            </span>
          </Button>

          <Button
            disabled={isSkipped}
            color={isComplete ? "green" : "grey"}
            variant={isComplete ? "solid" : "outline"}
            onClick={isComplete ? onConfirm("confirmClear") : markAsComplete}
            className="ml-2 rounded-full h-6 w-6 sm:h-8 sm:w-28 sm:rounded-lg px-1"
          >
            <CheckSlimIcon className="w-4 h-4 mr-0 sm:mr-2" />
            <span className="hidden sm:inline text-sm font-semibold">
              {t(
                isComplete
                  ? "checklistSubmission_completed_label"
                  : "checklistSubmission_complete_label",
              )}
            </span>
          </Button>
        </div>

        {isExpanded && (
          <div className={"flex flex-col mt-4"}>
            {section.sectionItems.map((item, index) => {
              return (
                <SectionItem
                  itemIndex={index}
                  sectionItem={item}
                  sectionIndex={sectionIndex}
                  isSectionSkipped={isSkipped}
                  key={`${item.itemTitle}-${index}`}
                />
              );
            })}
          </div>
        )}
      </div>

      <Modal open={modalState !== null} onClose={onCloseModal}>
        {modalState === "confirmClear" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onClearSection}
            iconType="MajorIncidentIcon"
            title={t("checklistSubmission_clear_confirmation_title")}
            acceptLabel={t("checklistSubmission_clear_confirmation_accept_label")}
            description={t("checklistSubmission_clear_confirmation_descritiption")}
            declineLabel={t("checklistSubmission_clear_confirmation_decline_label")}
          />
        )}

        {modalState === "confirmSkip" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={toggleSkipped}
            iconType="MajorIncidentIcon"
            title={t("checklistSubmission_skip_confirmation_title")}
            acceptLabel={t("checklistSubmission_skip_confirmation_accept_label")}
            description={t("checklistSubmission_skip_confirmation_descritiption")}
            declineLabel={t("checklistSubmission_skip_confirmation_decline_label")}
          />
        )}
      </Modal>
    </>
  );
};
