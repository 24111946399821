import _ from "lodash";
import { Notification } from "Components/Notifications";
import { dateOnly } from "Utilities/helpers";
import { PortfolioErrorMessages } from "./errors";
import { format, isValid } from "date-fns";
import {
  EventStatus,
  FeedbackStatus,
  FormValues,
  TimelineEventStatus,
  FormElement,
  EventType,
  FormElementType,
  TimelineData,
  EventValue,
} from "modules/portfolio/types";

export const isEventStatus = (itemText: unknown): itemText is EventStatus => {
  if (Object.values(EventStatus).includes(itemText as EventStatus)) {
    return true;
  }
  return false;
};

export const isFeedbackStatus = (itemText: unknown): itemText is FeedbackStatus => {
  if (Object.values(FeedbackStatus).includes(itemText as FeedbackStatus)) {
    return true;
  }
  return false;
};

export const isArrayComponentType = (type: FormElementType) => {
  switch (type) {
    case FormElementType.ATTACHMENTS:
      return true;
    default:
      return false;
  }
};

export const applySelectedFilters = ({
  timelineEvents,
  dateFilters,
  statusFilters,
  typeFilters,
}: {
  timelineEvents: TimelineData[];
  dateFilters: { startDate: string; endDate: string };
  statusFilters: TimelineEventStatus[];
  typeFilters: EventType[];
}): TimelineData[] => {
  let filteredArray = timelineEvents;
  const startDate = new Date(dateFilters.startDate);
  const endDate = new Date(dateFilters.endDate);
  const areDateFiltersApplied =
    startDate.toISOString() !== dateOnly() || endDate.toISOString() !== dateOnly();
  const areStatusFiltersApplied = statusFilters.length > 0;
  const areTypeFiltersApplied = typeFilters.length > 0;

  if (areDateFiltersApplied) {
    filteredArray = filteredArray.filter(
      (eventItem) => new Date(eventItem.date) >= startDate && new Date(eventItem.date) <= endDate,
    );
  }
  if (areStatusFiltersApplied) {
    filteredArray = filteredArray.filter((eventItem) => statusFilters.includes(eventItem.status!));
  }
  if (areTypeFiltersApplied) {
    filteredArray = filteredArray.filter((eventItem) => typeFilters.includes(eventItem.type));
  }
  return filteredArray;
};

export const formHasChanges = (draftFormData: FormValues, formData: FormValues): boolean => {
  if (!draftFormData) {
    return false;
  }
  return !_.isEqual(draftFormData, formData);
};

export const createErrorNotification = (
  error: PortfolioErrorMessages,
): Omit<Notification, "id"> => {
  return {
    type: "error",
    description: error,
    autoHideTimeout: 3000,
  };
};

export const formatEventValue = (element: FormElement): string => {
  if (element.value && isValid(element.value)) {
    return format(new Date(element.value as Date), "do MMMM yyyy");
  }

  if (isNumberValue(element.value)) {
    return element.value.toString();
  }

  if (isBooleanValue(element.value)) {
    return element.value.toString();
  }

  if (typeof element.value !== "string") {
    return "Format invalid";
  }

  return element.value;
};

export const orderFeedbackList = (feedbackList: TimelineData[]) => {
  return feedbackList.sort((a, b) => {
    if (
      a.status === FeedbackStatus.REVIEW_IN_PROGRESS &&
      b.status === FeedbackStatus.REVIEW_IN_PROGRESS
    ) {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    } else if (a.status === FeedbackStatus.REVIEW_IN_PROGRESS) {
      return -1;
    } else if (b.status === FeedbackStatus.REVIEW_IN_PROGRESS) {
      return 1;
    }

    return 0;
  });
};

export const isDateValue = (value?: EventValue): value is Date => {
  return value instanceof Date;
};

export const isNumberValue = (value?: EventValue): value is number => {
  return typeof value === "number";
};

export const isBooleanValue = (value?: EventValue): value is boolean => {
  return typeof value === "boolean";
};
