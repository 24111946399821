import { BlobContent } from "@eolas-medical/core";
import useDocumentViewer from "./useDocumentViewer";

interface DocumentViewerProps {
  document: BlobContent;
}

const DocumentViewer = ({ document }: DocumentViewerProps) => {
  const { viewerContainer } = useDocumentViewer({ document });

  return (
    <div className="h-90vh" data-testid="document-viewer">
      <div ref={viewerContainer} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default DocumentViewer;
