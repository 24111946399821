export const getInitials = (name?: string) => {
  if (!name) return "";

  const nameParts = name.split(" ");
  const initials = nameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials;
};
