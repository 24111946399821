import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { WizardState } from "../../../../types";
import { NewContentType } from "@eolas-medical/core";

type FlashCardDetailsValues = { name: string; description: string };

export const useFlashCardDetails = (state: WizardState) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .required(t("general_required"))
        .test("notJustWhiteSpace", t("respository_name_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
      description: Yup.string()
        .required(t("validation_description_required"))
        .test("notJustWhiteSpace", t("validation_description_not_empty"), function (newValue) {
          const isValid = typeof newValue === "string" && Boolean(newValue.trim());
          return isValid;
        }),
    });
  }, [t]);

  let name: string | undefined = undefined;
  let description: string | undefined = undefined;

  if (!state.isExistingFileWithNewType) {
    name = state.dto.name;
    description = state.dto.description;
  } else if (state.dto.newType === NewContentType.FLASHCARD) {
    ({ name, description } = state.dto.newProperties);
  }

  const { handleSubmit, control } = useForm<FlashCardDetailsValues>({
    defaultValues: {
      name,
      description,
    },
    resolver: yupResolver(validationSchema),
  });

  return { handleSubmit, control };
};
