import { useQuery } from "@tanstack/react-query";
import { accessClient, userStore } from "@eolas-medical/core";

export const makeGetAccessRequestsKey = (spaceId: string | null) => ["getAccessRequests", spaceId];

export const useGetAllAccessRequests = (spaceId: string | null) => {
  const query = useQuery({
    queryFn: () => {
      if (!userStore.userID) {
        throw new Error("useGetAllAccessRequests: userId is undefined");
      }
      if (!spaceId) {
        throw new Error("useGetAllAccessRequests: spaceId is null");
      }
      return accessClient.listAppAccessRequests(spaceId);
    },
    queryKey: makeGetAccessRequestsKey(spaceId),
    enabled: Boolean(spaceId),
  });

  return {
    count: query.data?.requests?.length ?? 0,
    accessRequests: query.data?.requests ?? [],
    allAccessRequestLoading: query.isLoading,
    refetch: query.refetch,
  };
};
