import { useTranslation } from "react-i18next";
import AssignUserModal from "UIKit/Modal/AssignUserModal/AssignUserModal";
import { Assessor, EventStatus, FeedbackStatus } from "modules/portfolio/types";
import useRecentlyAssingedAssessors from "modules/portfolio/data/useRecentlyAssignedAssessors";
import { useCallback, useMemo } from "react";
import { Avatar } from "UIKit";
import StatusBadge from "Pages/MeTab/pages/Portfolio/components/StatusBadge/StatusBadge";

type AssignAssessorModalProps = {
  ownerId: string;
  onCancel: () => void;
  onSubmit: (email: string) => void;
  isLoading: boolean;
  eventStatus: EventStatus;
  eventOwnerEmail: string;
  currentAssessor?: Assessor;
  isReassigning?: boolean;
};

const AssignAssessorModal = ({
  ownerId,
  onCancel,
  onSubmit,
  isLoading,
  currentAssessor,
  isReassigning,
  eventOwnerEmail,
  eventStatus,
}: AssignAssessorModalProps) => {
  const { t } = useTranslation();
  const { recentlyAssingedAssessors = [], recentlyAssignedLoading } =
    useRecentlyAssingedAssessors(ownerId);

  const handleSubmitModal = useCallback(
    (email: string) => {
      onSubmit(email);
    },
    [onSubmit],
  );

  const renderRecentlyAssignedUser = useCallback(
    ({ email, reviewStatus }: Assessor) => (
      <div className="flex flex-1 items-center space-x-4">
        <Avatar label={email} isLoading={recentlyAssignedLoading} />
        {reviewStatus === FeedbackStatus.DECLINED ? <StatusBadge status={reviewStatus} /> : null}
      </div>
    ),
    [recentlyAssignedLoading],
  );

  const currentAssignedAssessor = useMemo(() => {
    if (currentAssessor) {
      return [currentAssessor];
    }
    return [];
  }, [currentAssessor]);

  return (
    <AssignUserModal<Assessor>
      assignTitleText={t("portfolio_assign_user_title")}
      reassignTitleText={t("portfolio_reassign_user_title")}
      descriptionText={t("portfolio_assign_user_description")}
      placeholderText={t("portfolio_assign_user_placeholder")}
      submitAssignButtonText={t("portfolio_assign_user_submit_button")}
      submitReassignButtonText={t("portfolio_reassign_user_submit_button")}
      cancelButtonText={t("portfolio_assign_user_cancel_button")}
      assignButtonText={t("portfolio_assign_user_assign_button")}
      removeButtonText={t("portfolio_assign_user_remove_button")}
      onSubmit={handleSubmitModal}
      onCancel={onCancel}
      isLoading={recentlyAssignedLoading}
      isSubmitting={isLoading}
      recentlyAssignedUsers={recentlyAssingedAssessors}
      currentAssignedUsers={currentAssignedAssessor}
      assignerEmail={eventOwnerEmail}
      renderUserAvatar={renderRecentlyAssignedUser}
      isReassigning={isReassigning}
      canReassignCurrent={eventStatus === EventStatus.DECLINED}
    />
  );
};

export default AssignAssessorModal;
