import { Button } from "UIKit/Button";
import React from "react";

type LoadMoreProps = {
  onLoadMore: () => void;
  isLoading: boolean;
  loadMoreText?: string;
};

function LoadMore({ onLoadMore, isLoading, loadMoreText = "Load More" }: LoadMoreProps) {
  return (
    <>
      <div className="flex justify-center">
        <Button
          onClick={onLoadMore}
          data-testid="load-more-button"
          isLoading={isLoading}
          size="sm"
          className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 focus:outline-none"
        >
          {loadMoreText}
        </Button>
      </div>
    </>
  );
}

export default LoadMore;
