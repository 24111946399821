import { hasStringProp } from "@eolas-medical/core";

export const useExtractAllParams = (searchString: string): Record<string, string> => {
  const urlParams = new URLSearchParams(searchString);
  const originalParams = Object.fromEntries(urlParams.entries());
  let correctlyCasedParams = originalParams;

  if (hasStringProp(originalParams, "mainsectionId")) {
    const { mainsectionId, ...rest } = originalParams;
    correctlyCasedParams = {
      ...rest,
      mainSectionId: mainsectionId,
    };
  }

  return correctlyCasedParams;
};
