import { contentClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { mapToCreateSectionDto } from "../helpers";
import { AddContentSectionDto } from "../types";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseAddContentSectionProps<T, R> {
  mainSectionId: string;
  parentSectionId?: string;
  onSuccess: (params?: T) => Promise<R>;
}

const useAddContentSection = <T, R>({
  mainSectionId,
  parentSectionId,
  onSuccess,
}: UseAddContentSectionProps<T, R>) => {
  const { mutate, isLoading, isSuccess, error, reset } = useMutation(
    async ({ name, description, iconName, childrenType }: AddContentSectionDto) => {
      const mappedSection = mapToCreateSectionDto(mainSectionId, parentSectionId || mainSectionId, {
        name,
        description,
        iconName,
        childrenType,
      });
      /**
       * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
       */
      const result: any = await contentClient.createSectionRest(mappedSection);
      return result;
    },
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "section", action: "update", section: result });
        await onSuccess();
        reset();
      },
    },
  );

  return {
    addContentSection: mutate,
    addingContentSection: isLoading,
    addContentSectionSuccess: isSuccess,
    addContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useAddContentSection;
