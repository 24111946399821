import { useState } from "react";
import { useWhatsNew } from "./hooks";
import { useLaunchDarkly } from "Contexts";
import { Loader, Modal } from "UIKit";
import { userStore } from "@eolas-medical/core";
import { webStore } from "Stores/WebStore";
import { LDFlagNames } from "Utilities/types";
import { observer } from "mobx-react-lite";

export const WhatsNewModal = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const { whatsNewURL, onCloseModal } = useWhatsNew();
  const { flags } = useLaunchDarkly();

  const hasAccessToken = Boolean(
    userStore.userSession.inviteId || userStore.userSession.accessLinkId,
  );

  const showWhatsNew =
    userStore.userSession.isLoggedIn &&
    flags[LDFlagNames.WHATS_NEW] &&
    !webStore.hasSeenCurrentWhatsNew(whatsNewURL) &&
    !hasAccessToken;

  if (!showWhatsNew) return null;

  return (
    <Modal
      className="w-full md:max-w-3xl h-full md:max-h-220 bg-white flex flex-col"
      overlayClassName="pt-12"
      open={!!whatsNewURL}
      onClose={onCloseModal}
    >
      {isLoading ? <Loader className="absolute inset-2/4" /> : null}

      <iframe
        className="rounded-t-md h-full w-full"
        title="whats new modal"
        onLoad={() => setIsLoading(false)}
        src={whatsNewURL}
      />
    </Modal>
  );
});
