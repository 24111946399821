import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useChooseFileTypeStep,
  useConfigureFileStep,
  useWizard,
  useUploadProgressAndSuccessStep,
  AllowedChooseFileTypes,
} from "UIKit/Wizard";
import { AddContentItemWizardStep } from "./types";
import { useAddContentItemActions } from "./hooks/useAddContentItemActions";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { DomainIcon, DsmIcon, EolasEditorIcon, FilesIcon, FlashcardIcon } from "Assets";
import { hasProp, SupportedFileType } from "@eolas-medical/core";
import { observer } from "mobx-react-lite";
import { useDsmErrorStep } from "./hooks/useDsmErrorStep";

export interface AddContentItemWizardProps {
  onClose: () => void;
  onChangeWizard: (editorType: SupportedFileType) => void;
}

const AddContentItemWizard = observer(({ onClose, onChangeWizard }: AddContentItemWizardProps) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<AddContentItemWizardStep>();
  const allowDrafting = flags[LDFlagNames.ALLOW_DRAFTING_ITEMS] ?? false;

  const {
    contextDomain,
    wizardState,
    addContentItemError,
    addingContentItem,
    addContentItemSuccess,
    addContentItemProgress,
    getNextStep,
    getPrevStep,
    onSubmitFileType,
    onSelectType,
    onAddFileDetails,
    onBlobSelection,
  } = useAddContentItemActions({ onClose, onChangeWizard });

  const isFlashcardsActive = !!flags[LDFlagNames.FLASHCARDS];
  const isEditorActive = !!flags[LDFlagNames.EOLAS_EDITOR];
  const isDsmEditingAllowed =
    contextDomain === "spaceOrg" && Boolean(flags[LDFlagNames.DSM_EDITING]);

  const allowedTypes: AllowedChooseFileTypes[] = [
    { type: "blob", disabled: false, icon: FilesIcon, label: t("filePicker_blob") },
    {
      type: "link",
      disabled: false,
      icon: DomainIcon,
      label: t("filePicker_link"),
    },
    {
      type: "flashcard",
      disabled: !isFlashcardsActive,
      icon: FlashcardIcon,
      label: t("filePicker_flashcard"),
    },
    {
      type: "eolas-editor",
      disabled: !isEditorActive,
      icon: EolasEditorIcon,
      label: t("filePicker_editor"),
    },
    {
      type: "dsm",
      disabled: !isDsmEditingAllowed,
      icon: DsmIcon,
      label: t("filePicker_dsm"),
    },
  ];

  const chooseFileType = useChooseFileTypeStep<"upload-item">({
    stepName: "upload-item",
    title: t("repository_add_item_title"),
    allowedTypes,
    onSubmit: onSubmitFileType,
    onSelectType,
    onClose,
    onBlobSelection,
    flashcardSubtitle: t("flashcard_lottie_subtitle"),
    flashcardDescription: t("flashcard_lottie_description"),
    flashcardTitle: t("flashcard_lottie_intro"),
    editorSubtitle: t("editor_lottie_subtitle"),
    editorDescription: t("editor_lottie_description"),
    editorTitle: t("editor_lottie_intro"),
    fileFormLabel: t("filePicker_file_to_upload_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    keywordsLabel: t("filePicker_link_keywords_label"),
    keywordsPlaceholder: t("filePicker_link_keywords_placeholder"),
    fileDescriptionText: t("filePicker_helper_text"),
    linkUrlLabel: t("filePicker_url_label"),
    linkUrlPlaceholder: t("filePicker_url_placeholder"),
    cancelLabel: t("general_cancel"),
    isLoading: addingContentItem,
  });

  const addItemDetails = useConfigureFileStep<"add-item-details">({
    stepName: "add-item-details",
    title: t("repository_add_item_title"),
    onEnter: submitWizard,
    onSubmit: onAddFileDetails,
    isLoading: wizardState.selectedType === "link" ? addingContentItem : false,
    item: {
      ...wizardState.file,
    },
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel:
      wizardState.selectedType === "eolas-editor"
        ? t("addFile_wizard_next_button")
        : t("content_item_publish"),
  });

  const dsmError = useDsmErrorStep({
    error:
      wizardState.dsmData && hasProp(wizardState.dsmData, "dsmError")
        ? wizardState.dsmData.dsmError
        : null,
  });

  const addDsmDetails = useConfigureFileStep<"add-dsm-details">({
    stepName: "add-dsm-details",
    title: t("repository_add_dsm_title"),
    onEnter: submitWizard,
    onSubmit: onAddFileDetails,
    item: {
      ...wizardState.file,
    },
    overrideNameValue:
      wizardState.dsmData && hasProp(wizardState.dsmData, "parsedData")
        ? wizardState.dsmData.parsedData.name
        : "",
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t("content_item_publish"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
    stepName: "upload-progress",
    fileUploading: addingContentItem,
    uploadProgress: wizardState.selectedType === "dsm" ? 50 : addContentItemProgress,
    fileUploadError: addContentItemError,
    fileUploadedSuccess: addContentItemSuccess,
    onFinishUpload: onClose,
  });

  const steps: Step<AddContentItemWizardStep>[] = [
    chooseFileType,
    addItemDetails,
    dsmError,
    addDsmDetails,
    uploadProgressAndSuccessStep,
  ];

  const showAltSubmit = (step: string) => {
    if (!allowDrafting) return false;
    else {
      return (
        step === "add-item-details" ||
        step === "add-dsm-details" ||
        (step === "upload-item" && wizardState.selectedType === "link")
      );
    }
  };

  return (
    <Wizard
      steps={steps}
      wizardControl={wizardControl}
      getNextStep={getNextStep}
      getPrevStep={getPrevStep}
      showAltSubmit={showAltSubmit}
      altSubmitLabel={t("content_item_draft_label")}
    />
  );
});

export default AddContentItemWizard;
