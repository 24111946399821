import { useMediaQuery } from "Hooks";
import { KnowledgeResult, NationalBodyTextColor } from "Components/MasterSearch/types";
import { twMerge } from "tailwind-merge";
import { FavouriteToggle, Text } from "UIKit";
import { AdultHead, BabyHead } from "Assets";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { snakeCase } from "lodash";
import { useRemoveSearchFavourite } from "Components/MasterSearch/hooks/useRemoveSearchFavourite";
import {
  getEntityIdFromKnowledgeSearchResult,
  knowledgeSearchResultToEntityType,
} from "Pages/MeTab/pages/MyFavourites/functions/helpers";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { observer } from "mobx-react-lite";
import { getEntityIdSuffix } from "@eolas-medical/core";

interface LexicalResultProps {
  item: KnowledgeResult;
  icon: React.ReactNode;
  title: string;
  additionalText?: string;
  mainSectionName: string;
  nationalBody?: string;
  subSectionId?: string;
  nationalBodyTextColor: NationalBodyTextColor;
  onShareResult?: () => void;
}

export const LexicalResult = observer(
  ({
    item,
    icon,
    title,
    nationalBody,
    additionalText,
    mainSectionName,
    nationalBodyTextColor,
    onShareResult,
  }: LexicalResultProps) => {
    const media = useMediaQuery();
    const isMobile = media === "xs";

    const containerClass = isMobile ? "grid grid-cols-8" : "flex space-x-2";
    const favouriteEntityType = knowledgeSearchResultToEntityType(item);
    const favouriteEntityId = getEntityIdFromKnowledgeSearchResult(item);

    const {
      isFavourite,
      isMarkingAsFavourite,
      canFavouriteSearchResult,
      favouriteId,
      handleAddFavourite,
    } = useAddFavourite(favouriteEntityId + getEntityIdSuffix(favouriteEntityType ?? ""), item);

    const { removeFavourite, isRemovingFavourite } = useRemoveSearchFavourite({
      entityId: favouriteEntityId,
      entityType: favouriteEntityType,
    });

    return (
      <div
        className={`bg-white w-full rounded-lg p-4 cursor-pointer border border-1 hover:bg-blue-50 border-grey-300 justify-between ${containerClass}`}
        data-testid="master-search-results-item"
      >
        <div className="col-start-1 col-end-7 flex flex-col items-start">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-4 h-4" data-testid="circle-icon">
              {icon}
            </div>

            <span className="text-grey-600 font-semibold ml-2">{mainSectionName} </span>
          </div>

          <Text level={1} className="line-clamp-2 mt-4">
            {title}
          </Text>

          {additionalText ? (
            <Text className="flex-grow mx-2 my-4 line-clamp-5" level={2}>
              {additionalText}
            </Text>
          ) : null}

          <div className="flex items-center justify-center mt-2">
            {nationalBodyTextColor === "blue-500" && <AdultHead className="h-4 w-4 mr-1" />}
            {nationalBodyTextColor === "red-500" && <BabyHead className="h-4 w-4 mr-1" />}

            <span
              className={twMerge(
                `text-grey-600 font-semibold text-xs text-${nationalBodyTextColor}`,
              )}
            >
              {nationalBody ?? ""}
            </span>
          </div>
        </div>
        <div>
          {canFavouriteSearchResult && favouriteEntityType ? (
            <FavouriteToggle
              entityId={favouriteEntityId + getEntityIdSuffix(favouriteEntityType)}
              onClick={() =>
                isFavourite && favouriteId
                  ? removeFavourite(favouriteId)
                  : handleAddFavourite({
                      item: item,
                      entityType: favouriteEntityType,
                    })
              }
              isLoading={isMarkingAsFavourite || isRemovingFavourite}
            />
          ) : null}
          {onShareResult && <ShareFileButton id={snakeCase(title)} onShareFile={onShareResult} />}
        </div>
      </div>
    );
  },
);
