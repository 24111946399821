import { KnowledgeContentDomain } from "@eolas-medical/core";

const knowledgeContentKeys = {
  all: (domain: KnowledgeContentDomain) => ["knowledgeContent", domain],
  repository: (domain: KnowledgeContentDomain) => [
    ...knowledgeContentKeys.all(domain),
    "repository",
  ],
  contentSections: (domain: KnowledgeContentDomain) => [
    ...knowledgeContentKeys.all(domain),
    "contentSections",
  ],
  contentSection: (sectionId: string, domain: KnowledgeContentDomain) => [
    ...knowledgeContentKeys.all(domain),
    "contentSection",
    sectionId,
  ],
  items: (sectionId: string, domain: KnowledgeContentDomain) => [
    ...knowledgeContentKeys.all(domain),
    "items",
    sectionId,
  ],
  item: (itemId: string, domain: KnowledgeContentDomain) => [
    ...knowledgeContentKeys.all(domain),
    "item",
    itemId,
  ],
  searchedItems: ({
    sectionId,
    query,
    domain,
  }: {
    sectionId: string;
    query: string;
    domain: KnowledgeContentDomain;
  }) => [...knowledgeContentKeys.items(sectionId, domain), query],
};

export default knowledgeContentKeys;
