import { ReactComponent as AcuteCareAssessmentToolIcon } from "./acuteCareAssessmentTool.svg";
import { ReactComponent as AddAssessorIcon } from "./addAssessor.svg";
import { ReactComponent as AdvancedPracticeAnnualAppraisalIcon } from "./advancedPracticeAnnualAppraisal.svg";
import { ReactComponent as AssessorIcon } from "./assessor.svg";
import { ReactComponent as AuditIcon } from "./audit.svg";
import { ReactComponent as CaseBasedDiscussionIcon } from "./caseBasedDiscussion.svg";
import { ReactComponent as ClinicalEventIcon } from "./clinicalEvent.svg";
import { ReactComponent as CustomFormIcon } from "./customForm.svg";
import { ReactComponent as DirectlyObservedProceduralSkillsIcon } from "./directlyObservedProceduralSkills.svg";
import { ReactComponent as EventClosedIcon } from "./eventClosed.svg";
import { ReactComponent as ExtendedSupervisedLearningEventIcon } from "./extendedSupervisedLearningEvent.svg";
import { ReactComponent as FeedbackIcon } from "./feedback.svg";
import { ReactComponent as MeetingIcon } from "./meeting.svg";
import { ReactComponent as MultiSourceFeedbackIcon } from "./multiSourceFeedback.svg";
import { ReactComponent as MyPortfolioIcon } from "./myPortfolio.svg";
import { ReactComponent as PdpIcon } from "./pdp.svg";
import { ReactComponent as ProceduralSkillIcon } from "./proceduralSkill.svg";
import { ReactComponent as ReflectionIcon } from "./reflection.svg";
import { ReactComponent as SupervisionIcon } from "./supervision.svg";
import { ReactComponent as SupervisorsIcon } from "./supervisors.svg";
import { ReactComponent as SuperviseesIcon } from "./supervisees.svg";
import { ReactComponent as TeachingDeliveryIcon } from "./teachingDelivery.svg";
import { ReactComponent as UltraSoundIcon } from "./ultrasoundLog.svg";
import { ReactComponent as UltraSoundVascularAccessIcon } from "./ultrasoundVascularAccess.svg";
import { ReactComponent as UltrasoundAbdominalRenalIcon } from "./ultrasoundAbdominalRenal.svg";
import { ReactComponent as UltraSoundCardiacIcon } from "./ultrasoundCardiac.svg";
import { ReactComponent as UltraSoundDVTIcon } from "./ultrasoundDVT.svg";
import { ReactComponent as UltraSoundThoracicIcon } from "./ultrasoundThoracic.svg";
import { ReactComponent as UltraSoundAAAIcon } from "./ultrasoundAAA.svg";
import { ReactComponent as UltraSoundFASTIcon } from "./ultrasoundFAST.svg";
import { ReactComponent as UltraSoundEchoInLifeSupportIcon } from "./ultrasoundEchoInLifeSupport.svg";

export {
  AcuteCareAssessmentToolIcon,
  AddAssessorIcon,
  AdvancedPracticeAnnualAppraisalIcon,
  AssessorIcon,
  AuditIcon,
  CaseBasedDiscussionIcon,
  ClinicalEventIcon,
  CustomFormIcon,
  DirectlyObservedProceduralSkillsIcon,
  EventClosedIcon,
  ExtendedSupervisedLearningEventIcon,
  FeedbackIcon,
  MeetingIcon,
  MultiSourceFeedbackIcon,
  MyPortfolioIcon,
  PdpIcon,
  ProceduralSkillIcon,
  ReflectionIcon,
  TeachingDeliveryIcon,
  UltraSoundIcon,
  UltraSoundVascularAccessIcon,
  UltrasoundAbdominalRenalIcon,
  UltraSoundCardiacIcon,
  UltraSoundDVTIcon,
  UltraSoundThoracicIcon,
  UltraSoundAAAIcon,
  UltraSoundFASTIcon,
  UltraSoundEchoInLifeSupportIcon,
  SupervisionIcon,
  SupervisorsIcon,
  SuperviseesIcon,
};

export const eventIcons = {
  AcuteCareAssessmentToolIcon,
  AdvancedPracticeAnnualAppraisalIcon,
  AuditIcon,
  CaseBasedDiscussionIcon,
  ClinicalEventIcon,
  CustomFormIcon,
  DirectlyObservedProceduralSkillsIcon,
  ExtendedSupervisedLearningEventIcon,
  MeetingIcon,
  MultiSourceFeedbackIcon,
  PdpIcon,
  ProceduralSkillIcon,
  ReflectionIcon,
  TeachingDeliveryIcon,
  UltraSoundIcon,
  UltraSoundVascularAccessIcon,
  UltraSoundCardiacIcon,
  UltraSoundDVTIcon,
  UltraSoundThoracicIcon,
  UltrasoundAbdominalRenalIcon,
  UltraSoundAAAIcon,
  UltraSoundFASTIcon,
  UltraSoundEchoInLifeSupportIcon,
};

export type EventIconsType = keyof typeof eventIcons;
