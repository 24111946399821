import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { OrganisationLevelSection, sectionStore } from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { useMedusaFile } from "../useMedusaFile";
import { useShareFile } from "shared/hooks/useShareFile";
import { organisationRoutes } from "Pages/Spaces/pages/Organisation/OrganisationRoutes";
import { ExtractRouteParams } from "Utilities/types";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

type Props = RouteComponentProps<ExtractRouteParams<typeof organisationRoutes.medusaFiles>>;

export const MedusaFilesPage = observer(({ match: { params }, history }: Props) => {
  const { t } = useTranslation();
  const { onSelectMedusaFile } = useMedusaFile();
  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();
  useMainSectionVisit({
    mainSectionId: OrganisationLevelSection.medusaMedications,
    subSectionId: decodeURIComponent(params.id),
  });
  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;

  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  return (
    <InnerPageWrapper>
      <Modal open={isModalOpen} onClose={onCloseModal}>
        {shareModal || removeFavouriteModal}
      </Modal>
      <FileBreadcrumbs
        shouldShowSpaceName={true}
        mainSectionId={
          sectionStore.getChildReferenceByMainSectionType(
            OrganisationLevelSection.medusaMedications,
          )?.id
        }
        parentId={decodeURIComponent(params.id) ?? ""}
      />
      <PageTitle
        title={t("medusa_files_title", {
          monoType: params.sectionName,
        })}
        titleClass="capitalize"
      />
      <NavButton onClick={history.goBack} />
      <EolasFileList
        isAdmin={false}
        draggable={false}
        defaultSort="alphabetical"
        onSelectFile={onSelectMedusaFile}
        mainSectionId={OrganisationLevelSection.medusaMedications}
        subSectionId={decodeURIComponent(params.id)}
        onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
        showRemoveFavouriteModal={showRemoveFavouriteModal}
      />
    </InnerPageWrapper>
  );
});
