import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mapToNewCreateSectionDto } from "modules/contentRepository/helpers";
import { AddContentSectionDto } from "modules/contentRepository/types";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

interface UseAddContentSectionProps {
  mainSectionId: string | null;
  parentSectionId: string | null;
  domain: KnowledgeContentDomain;
}

const useAddKnowledgeContentSection = ({
  mainSectionId,
  parentSectionId,
  domain,
}: UseAddContentSectionProps) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ name, description = "", iconName }: AddContentSectionDto) => {
      if (!mainSectionId || !parentSectionId) {
        throw new Error("MainSectionId or parentSectionId missing");
      }
      if (domain === "sponsoredSlots") {
        throw new Error("Cannot create sponsored slot from this hook");
      }
      const mappedSection = mapToNewCreateSectionDto({
        name,
        description,
        iconName,
        mainSectionId,
        parentId: parentSectionId,
        domain,
      });
      await knowledgeContentClient.createSection(mappedSection);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(knowledgeContentKeys.contentSections(domain));
      },
    },
  );

  return {
    addContentSection: mutate,
    addingContentSection: isLoading,
    addContentSectionSuccess: isSuccess,
    addContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useAddKnowledgeContentSection;
