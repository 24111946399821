import { useQuery } from "@tanstack/react-query";
import { contentClient } from "@eolas-medical/core";
import analyticsKeys from "./analytics.queryKeys";

type AnalyticsUser = {
  givenName: string;
  familyName: string;
  email: string;
  photoUrl?: string;
  isRead: boolean;
};

export type ContentItemAnalyticsResponse = {
  readRatio?: string;
  totalRead?: string;
  totalUnread?: string;
  userCompletion?: AnalyticsUser[];
};

export interface ContentItemAnalytics extends ContentItemAnalyticsResponse {
  readUsers?: string[];
  unreadUsers?: string[];
  totalUsers?: number;
}

const useGetContentItemAnalytics = ({
  mainSectionId,
  contentId,
}: {
  mainSectionId: string;
  contentId: string;
}) => {
  const { data, isLoading, error } = useQuery<ContentItemAnalyticsResponse>({
    queryKey: analyticsKeys.detailContent(contentId),
    queryFn: () =>
      contentClient.getContentItemAnalytics({
        mainSectionId,
        contentId,
      }),
    staleTime: 1000,
  });

  const readUsers =
    data?.userCompletion
      ?.filter((user) => user.isRead)
      .map((user) => `${user.givenName} ${user.familyName}`) ?? [];

  const unreadUsers =
    data?.userCompletion
      ?.filter((user) => !user.isRead)
      .map((user) => `${user.givenName} ${user.familyName}`) ?? [];

  return {
    contentItemAnalytics: {
      ...data,
      readUsers,
      unreadUsers,
      totalUsers: data?.userCompletion?.length,
    },
    isLoadingContentItemAnalytics: isLoading,
    contentItemAnalyticsError: error as Error,
  };
};

export default useGetContentItemAnalytics;
