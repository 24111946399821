import ContentSections from "shared/pages/ContentRepository/ContentSections/ContentSections";
import { useKnowledgeContentInfoContext } from "../../context/knowledgeContentInfoContext";
import useKnowledgeContentParams from "../../hooks/useKnowledgeContentParams";
import { useGetSearchParams } from "Utilities/useGetSearchParams";

const KnowledgeContentSections = () => {
  const { domain, isUserAdmin } = useKnowledgeContentInfoContext();
  const { contentRepositoryId } = useKnowledgeContentParams({
    domain,
  });

  const { title } = useGetSearchParams({
    paramList: ["title"],
  });

  return (
    <ContentSections
      contentRepositoryId={contentRepositoryId ?? ""}
      isAdmin={isUserAdmin}
      variant="titleAndDescription"
      disableReorder
      isPaginated={true}
      title={title}
    />
  );
};

export default KnowledgeContentSections;
