import { useTranslation } from "react-i18next";
import { InnerPageWrapper, Text, Button } from "UIKit";
import { useSelectSpaceActions } from "../SelectSpace/hooks/useSelectSpaceActions";

import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSpacesContext } from "modules/spaces";
import { LocationDescriptorObject } from "history";

export const SwitchToAnotherSpace = () => {
  const { t } = useTranslation();
  const { spaceId, spaceName, sectionType, sectionId } = useParamsDecoded<{
    spaceId: string;
    spaceName: string;
    sectionType: string;
    sectionId: string;
  }>();
  const { selectedSpace } = useSpacesContext();
  const { search } = useLocation();
  const history = useHistory();
  const [spaceLoading, setSpaceLoading] = useState(false);

  const { onSelectSpace } = useSelectSpaceActions(
    undefined,
    `${location.pathname}${location.search}`,
  );

  useEffect(() => {
    const baseRoute = `/spaces/${encodeURIComponent(spaceName)}`;

    if (spaceId === selectedSpace?.id) {
      const urlParts = [baseRoute];

      if (sectionType && sectionType !== "undefined") {
        urlParts.push(sectionType);
        if (sectionId && sectionId !== "undefined") {
          urlParts.push(sectionId);
        }
      }
      const url = urlParts.join("/");

      const locationObj: LocationDescriptorObject<undefined> = {
        pathname: url,
        search,
      };

      history.push(locationObj);
      setSpaceLoading(false);
    }
  }, [selectedSpace, spaceId, spaceName, sectionType, sectionId, history, search]);

  return (
    <InnerPageWrapper>
      <div
        className="flex flex-col justify-start items-center flex-1 pt-24 text-center"
        data-testid="portfolio-error-fallback"
      >
        <Text level={1} className="mb-24 text-lg">
          {t("linked_documents_viewer_not_in_correct_space", {
            spaceName: spaceName,
          })}
        </Text>
        <Button
          isLoading={spaceLoading}
          size="xl"
          onClick={() => {
            setSpaceLoading(true);
            onSelectSpace(spaceId);
          }}
        >
          {t("linked_documents_viewer_switch_to_space_button", {
            spaceName: spaceName,
          })}
        </Button>
      </div>
    </InnerPageWrapper>
  );
};
