import { hasStringProp } from "@eolas-medical/core";
import { DsmItem, DsmRenderItem, FooterItem } from "./types";

export const isDsmFooterItem = (item: DsmRenderItem): item is FooterItem => {
  const { item: renderItem } = item;
  return hasStringProp(renderItem, "type") && renderItem.type === "dsmFooterItem";
};

export const isDsmQuestionItem = (item: DsmRenderItem): item is DsmItem => {
  return !isDsmFooterItem(item);
};
