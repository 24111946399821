import React from "react";
import { get } from "lodash";
import { gql, useQuery } from "@apollo/client";
import { inviteClient, AnalyticsEvents, eolasLogger } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { UserInvitation } from "Utilities/types";
import { useNotifications } from "Components/Notifications";
import { useGetRelevantSpaceDetails } from "./useGetRelevantSpaceDetails";

// #region Graphql Operations
const LIST_INVITATIONS = gql`
  query InvitationsByApp($appID: ID!) {
    invitationsByApp(appID: $appID) {
      items {
        id
        appID
        userID
        appUserID
        email
        status
        info
      }
      nextToken
    }
  }
`;
// #endregion

const emailRegex = /.+@.+\..+/;

export const useInviteUsers = () => {
  const { spaceId } = useGetRelevantSpaceDetails();
  const { showNotification } = useNotifications();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [validationError, setValidationError] = React.useState("");

  const {
    error: refreshError,
    isLoading: isRefreshLoading,
    setRequestStatus: setRefreshRequestStatus,
  } = useRequestStatus();

  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const {
    data,
    loading: invitationsLoading,
    refetch: refetchInvitations,
    // @TODO: replace with REST API call. This is currently using the old app services endpoint
  } = useQuery(LIST_INVITATIONS, {
    variables: { appID: spaceId },
  });

  const invitations: UserInvitation[] = get(data, "invitationsByApp.items", []);

  const onSendInvites = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      if (!spaceId) {
        throw new Error("useInviteUsers: spaceId is null");
      }
      const { items } = await inviteClient.inviteUsers(
        spaceId,
        emails.map((e) => e.toLowerCase().trim()),
      );

      const pendingUsers = items
        .filter((invitation) => invitation.status === "Pending")
        .map((invitation) => invitation.email);

      const existingUsers = items
        .filter((invitation) => invitation.status === "AlreadyExists")
        .map((invitation) => invitation.email);

      const alreadyAcceptedUsers = items
        .filter((invitation) => invitation.status === "AlreadyAccepted")
        .map((invitation) => invitation.email);

      trackEvent(AnalyticsEvents.SEND_INVITATIONS);
      setEmails([]);

      if (pendingUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: pendingUsers.join(", "),
          title: `Invitation${pendingUsers.length > 1 ? "s" : ""} sent.`,
        });
      }

      if (existingUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: existingUsers.join(", "),
          title: `User${existingUsers.length > 1 ? "s" : ""} already part of the organisation.`,
        });
      }

      if (alreadyAcceptedUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: alreadyAcceptedUsers.join(", "),
          title: `User${
            alreadyAcceptedUsers.length > 1 ? "s" : ""
          } already accepted the invitation.`,
        });
      }

      await refetchInvitations();
      setRequestStatus({ status: "success", error: "" });
    } catch (err) {
      const { relevantMessage } = eolasLogger.error(err, { info: "Send invitation error" });
      setRequestStatus({ status: "error", error: relevantMessage });
    }
  }, [emails, spaceId, showNotification, refetchInvitations, setRequestStatus]);

  const emailValidator = React.useCallback(
    (value: string) => {
      const isValid = emailRegex.test(value);

      setValidationError(isValid ? "" : "manageUsers_invite_email_validation_error");

      return isValid;
    },
    [setValidationError],
  );

  const onRefreshInvitations = React.useCallback(async () => {
    setRefreshRequestStatus({ status: "pending", error: "" });
    try {
      await refetchInvitations();
      setRefreshRequestStatus({ status: "success", error: "" });
    } catch (err) {
      const { relevantMessage } = eolasLogger.error(err, { info: "Refresh invitations error" });
      setRequestStatus({ status: "error", error: relevantMessage });
    }
  }, [refetchInvitations, setRefreshRequestStatus, setRequestStatus]);

  React.useEffect(() => {
    const tagInputElement = document.getElementsByClassName("react-tag-input__input")[0];
    if (!tagInputElement) return;

    if (emails.length === 0) {
      tagInputElement.classList.add("ml-12");
    } else {
      tagInputElement.classList.remove("ml-12");
    }
  }, [emails]);

  return {
    error,
    emails,
    isLoading,
    invitations,
    refreshError,
    validationError,
    isRefreshLoading,
    invitationsLoading,
    isDisabled: emails.length === 0,
    invitationsError: error || refreshError,
    setEmails,
    onSendInvites,
    emailValidator,
    refetchInvitations,
    onRefreshInvitations,
  };
};
