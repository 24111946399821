/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthFunctionNames } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { resetPasswordSendCode } from "../auth.actions";

export interface SendResetLinkFormValues {
  emailForgotFlow: string;
}

const defaultValues: SendResetLinkFormValues = {
  emailForgotFlow: "",
};

const schema = yup.object().shape({
  emailForgotFlow: yup
    .string()
    .required("createAccount_forgotPassword_email_required")
    .email("createAccount_forgotPassword_email_invalid"),
});

export const useSendResetPasswordLink = (setStage: any, setUserEmail: any) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { control, handleSubmit } = useForm<SendResetLinkFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ emailForgotFlow }) => {
    resetPasswordSendCode(emailForgotFlow)
      .then(() => {
        setUserEmail(emailForgotFlow);
        setRequestStatus({ status: "success", error: "" });
        setStage();
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.forgotPassword,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  });

  return {
    error,
    control,
    isLoading,
    onSubmit,
  };
};
