import { Route, RouteProps } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";

import { Highlights } from "./pages/MiniApp/Highlights";
import { MeetTheTeam } from "./pages/MiniApp/MeetTheTeam";
import { TheDepartment } from "./pages/MiniApp/TheDepartment";
import { SimpleSection } from "./pages/SimpleSections";
import { ClientHospitalsList } from "./pages/MiniApp/Hospitals";
import { Wellbeing, WellbeingSection } from "./pages/Wellbeing";
import { GuidelinesSection, Guidelines } from "./pages/Guidelines";
import { ClientIncidentReportingForm } from "./pages/MiniApp/IncidentReporting";
import { DynamicPage, clientDynamicPageData } from "./pages/DynamicPages";
import { ClientContactsPage, ClientContactsSection } from "./pages/MiniApp/Contacts";
import { ClinicalPathways, ClinicalPathwaySection } from "./pages/ClinicalPathways";
import { ResearchAndAudit, ResearchAndAuditSection } from "./pages/ResearchAndAudit";
import { EducationalPortal, EducationPortalSection } from "./pages/MiniApp/EducationalPortal";
import {
  MajorIncidents,
  AddMajorIncident,
  MajorIncidentsDocuments,
} from "./pages/MiniApp/MajorIncidents";

import { SpaceRoute } from "../../types";
import { ClinicalDocuments, ClinicalDocumentSections } from "./pages/ClinicalDocuments";
import { PolicyDocuments, PolicyDocumentsSection } from "./pages/PolicyDocuments";
import Space from "Pages/Spaces/pages/Space/Space";
import { ChecklistsRoutes } from "./pages/MiniApp/Checklists/ChecklistsRoutes";
import Chat from "./pages/MiniApp/Chat/Chat";
import SpaceContentRepository from "./pages/SpaceContentRepository/SpaceContentRepository";
import { useLaunchDarkly } from "Contexts";
import { NewsFeed } from "./pages/MiniApp/NewsFeed";
import ActivityFeed from "./pages/MiniApp/ActivityFeed/ActivityFeed";
import { PatientLeaflets } from "./pages/MiniApp/PatientLeaflets";

export const spaceRoutes: Record<SpaceRoute, string> = {
  theDepartment: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.theDepartment}`,
  highlights: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.highlights}`,
  patientLeaflets: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.patientLeaflets}`,
  wellbeing: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.wellbeing}`,
  wellbeingSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.wellbeing}/:sectionID`,
  expiredPathways: `/${BaseAppRoute.spaces}/:spaceName/expired-pathways`,
  clinicalPathways: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.clinicalPathways}`,
  clinicalPathwaysSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.clinicalPathways}/:sectionID`,
  howTo: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.howTo}`,
  equipmentLocations: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.equipmentLocations}`,
  contacts: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.contacts}`,
  contactsSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.contacts}/:sectionID`,
  hospitalList: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.hospitalList}`,
  addHospital: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.hospitalList}/add`,
  editHospital: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.hospitalList}/edit/:hospitalId`,
  newsFeed: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.newsFeed}`,
  majorIncidents: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.majorIncidents}`,
  allMajorIncidents: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.majorIncidents}/all`,
  majorIncidentDocuments: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.majorIncidents}/documents`,
  incidentReporting: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.incidentReporting}`,
  meetTheTeam: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.meetTheTeam}`,
  educationPortal: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.educationPortal}`,
  educationPortalSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.educationPortal}/:sectionID`,
  guidelines: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.guidelines}`,
  clinicalDocuments: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.clinicalDocuments}`,
  clinicalDocumentsSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.clinicalDocuments}/:sectionID`,
  policyDocuments: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.policyDocuments}`,
  policyDocumentSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.policyDocuments}/:sectionID`,
  guidelinesSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.guidelines}/:sectionID`,
  guidelinesManageExpired: `/${BaseAppRoute.spaces}/:spaceName/guidelines-expired`,
  policyDocumentsManageExpired: `/${BaseAppRoute.spaces}/:spaceName/policy-documents-expired`,
  clinicalDocumentsManageExpired: `/${BaseAppRoute.spaces}/:spaceName/clinical-documents-expired`,
  rotas: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.rotas}`,
  emergencyActionCards: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.emergencyActionCards}`,
  admissionGuides: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.admissionGuides}`,
  importantLinks: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.importantLinks}`,
  surveys: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.surveys}`,
  researchAndAudit: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.researchAndAudit}`,
  researchAndAuditSection: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.researchAndAudit}/:sectionID`,
  pushNotificationCenter: `/${BaseAppRoute.spaces}/:spaceName/push-notifications`,
  addMajorIncident: `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.majorIncidents}/add`,
  contentManagement: "",
  completedChecklist: "",
  checklists: "",
  channels: `/${BaseAppRoute.spaces}/:spaceName/channels`,
  contentRepository: `/${BaseAppRoute.spaces}/:spaceName/content-repository/:contentRepositoryId`,
  activityFeed: `/${BaseAppRoute.spaces}/:spaceName/activityFeed`,
};

export const SpaceRoutes = () => {
  const { flags } = useLaunchDarkly();
  const showContentRepository = !!flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];

  return (
    <>
      <Route exact path={"/"} component={Space} />
      <Route exact path={`/${BaseAppRoute.spaces}/:spaceName`} component={Space} />

      <ChecklistsRoutes />

      {showContentRepository ? (
        <Route path={spaceRoutes.contentRepository} component={SpaceContentRepository} />
      ) : null}

      <Route
        exact
        path={spaceRoutes.rotas}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={clientDynamicPageData.rotas} />
        )}
      />

      <Route
        exact
        path={spaceRoutes.emergencyActionCards}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={clientDynamicPageData.emergencyActionCards} />
        )}
      />

      <Route
        exact
        path={spaceRoutes.admissionGuides}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={clientDynamicPageData.admissionGuides} />
        )}
      />

      <Route
        exact
        path={spaceRoutes.importantLinks}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={clientDynamicPageData.importantLinks} />
        )}
      />

      <Route
        exact
        path={spaceRoutes.surveys}
        render={(routeProps: RouteProps) => (
          <DynamicPage {...routeProps} data={clientDynamicPageData.surveys} />
        )}
      />

      <Route exact path={spaceRoutes.contacts} component={ClientContactsPage} />
      <Route exact component={ClientContactsSection} path={spaceRoutes.contactsSection} />

      <Route exact path={spaceRoutes.hospitalList} component={ClientHospitalsList} />
      <Route exact path={spaceRoutes.newsFeed} component={NewsFeed} />

      <Route exact path={spaceRoutes.majorIncidents} component={MajorIncidents} />
      <Route exact path={spaceRoutes.addMajorIncident} component={AddMajorIncident} />

      <Route exact path={spaceRoutes.guidelines} component={Guidelines} />

      <Route exact path={spaceRoutes.clinicalDocuments} component={ClinicalDocuments} />
      <Route
        exact
        path={spaceRoutes.clinicalDocumentsSection}
        component={ClinicalDocumentSections}
      />

      <Route exact path={spaceRoutes.policyDocuments} component={PolicyDocuments} />
      <Route exact path={spaceRoutes.policyDocumentSection} component={PolicyDocumentsSection} />

      <Route exact path={spaceRoutes.guidelinesSection} component={GuidelinesSection} />

      <Route exact component={MajorIncidentsDocuments} path={spaceRoutes.majorIncidentDocuments} />

      <Route exact component={ClientIncidentReportingForm} path={spaceRoutes.incidentReporting} />

      <Route exact path={spaceRoutes.meetTheTeam} component={MeetTheTeam} />

      <Route exact component={EducationalPortal} path={spaceRoutes.educationPortal} />
      <Route exact component={EducationPortalSection} path={spaceRoutes.educationPortalSection} />

      <Route
        exact
        path={spaceRoutes.howTo}
        render={(routeProps: RouteProps) => {
          return <SimpleSection {...routeProps} mainSectionId={AppLevelSection.howTo} />;
        }}
      />

      <Route
        exact
        path={spaceRoutes.equipmentLocations}
        render={(routeProps: RouteProps) => (
          <SimpleSection {...routeProps} mainSectionId={AppLevelSection.equipmentLocations} />
        )}
      />

      <Route
        exact
        path={spaceRoutes.highlights}
        render={(routeProps: RouteProps) => (
          <Highlights {...routeProps} mainSectionID={AppLevelSection.highlights} />
        )}
      />

      <Route exact component={ClinicalPathways} path={spaceRoutes.clinicalPathways} />

      <Route exact component={ClinicalPathwaySection} path={spaceRoutes.clinicalPathwaysSection} />

      <Route exact path={spaceRoutes.wellbeing} component={Wellbeing} />

      <Route exact component={WellbeingSection} path={spaceRoutes.wellbeingSection} />

      <Route exact path={spaceRoutes.researchAndAudit} component={ResearchAndAudit} />

      <Route exact component={ResearchAndAuditSection} path={spaceRoutes.researchAndAuditSection} />

      <Route exact component={PatientLeaflets} path={spaceRoutes.patientLeaflets} />

      <Route exact path={spaceRoutes.theDepartment} component={TheDepartment} />

      <Route exact path={spaceRoutes.channels} component={Chat} />

      <Route exact path={spaceRoutes.activityFeed} component={ActivityFeed} />
    </>
  );
};
