import { NICE_BASE_URL } from "modules/niceGuidelines/constants";
import {
  captureGuidanceError,
  extractGuidanceNumber,
  getBookmarkNames,
  getSubtype,
  isGuidanceUrl,
} from "modules/niceGuidelines/helpers";

export const handleLinkClick = (
  sanitisedUrl: string | null,
): {
  outcome: "success" | "failure";
  guidanceNumber: string | null;
  subtype: string | null;
  bookmarkNames: { parent: string; child: string } | null;
} => {
  if (!sanitisedUrl) {
    return { outcome: "failure", guidanceNumber: null, subtype: null, bookmarkNames: null };
  }

  const url = new URL(sanitisedUrl);
  const isNiceUrl = url.hostname.toLowerCase().includes(NICE_BASE_URL);

  if (isNiceUrl && isGuidanceUrl(url)) {
    const guidanceNumber = extractGuidanceNumber(url);
    const subtype = getSubtype(url);
    if (!guidanceNumber) {
      captureGuidanceError(url, guidanceNumber);
      return { outcome: "failure", guidanceNumber: null, subtype, bookmarkNames: null };
    }
    const bookmarkNames: { parent: string; child: string } | null = getBookmarkNames(url);
    return { outcome: "success", guidanceNumber, subtype, bookmarkNames };
  }
  return { outcome: "failure", guidanceNumber: null, subtype: null, bookmarkNames: null };
};
