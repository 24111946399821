import { isEqual } from "lodash";

import { useCallback, useMemo, useState } from "react";
import { sectionStore } from "@eolas-medical/core";
import { useLocalChildrenList } from "Pages/Spaces/components/SelectTileList/hooks/useLocalChildrenList";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { convertToSectionData } from "../functions/convertToSectionData";

export const useManageLimitedAdminModal = ({
  currentSelection,
  onSubmit,
}: {
  currentSelection: string[];
  onSubmit: () => void;
}) => {
  const [allInitialSelectedSections] = useState(() => [...currentSelection]);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const { activeTab } = useEolasNavigation();

  const filterOnActiveTab = useCallback(
    (id: string) => {
      const mainSectionId = sectionStore.getSection(id)?.mainSectionID;
      if (!mainSectionId) {
        return false;
      }
      return sectionStore
        .getMainSectionList(activeTab === "spaces" ? "department" : "hospital")
        .some((ref) => ref.id === mainSectionId);
    },
    [activeTab],
  );

  const { items, handleNavigateDown, handleNavigateUp, parentItem } = useLocalChildrenList({
    leafType: "sectionWithFiles",
    shouldShowOrgItems: activeTab === "organisation",
    shouldShowSpaceItems: activeTab === "spaces",
    otherSectionsToInclude: "all",
  });

  const shouldShowBackArrow = Boolean(parentItem) && !shouldConfirm;

  const memoData = useMemo(() => {
    const initialSelectedSections = allInitialSelectedSections.filter(filterOnActiveTab);
    const initialSelection = convertToSectionData(initialSelectedSections);
    const currentSelectedItems = Object.values(convertToSectionData(currentSelection) ?? {});
    const relevantSelection = [...currentSelection].sort().filter(filterOnActiveTab);
    const isUpdateButtonDisabled =
      shouldConfirm &&
      (!relevantSelection.length ||
        isEqual(relevantSelection, [...initialSelectedSections].sort().filter(filterOnActiveTab)));
    return { initialSelection, isUpdateButtonDisabled, currentSelectedItems };
  }, [currentSelection, allInitialSelectedSections, filterOnActiveTab, shouldConfirm]);

  const { initialSelection, isUpdateButtonDisabled, currentSelectedItems } = memoData;

  const onClickSubmit = () => {
    if (!shouldConfirm) {
      setShouldConfirm(true);
      return;
    }
    onSubmit();
  };

  const renderItems = shouldConfirm ? currentSelectedItems : items;

  return {
    initialSelection,
    isUpdateButtonDisabled,
    currentSelectedItems,
    shouldShowBackArrow,
    handleNavigateDown,
    handleNavigateUp,
    shouldConfirm,
    setShouldConfirm,
    onClickSubmit,
    renderItems,
  };
};
