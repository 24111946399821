import { EolasFile, fileStore } from "@eolas-medical/core";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useRunOnMountUnmount } from "Hooks";

type Props = {
  onSelectFile: (file: EolasFile) => void;
};

export const useSelectContactItemOnMount = ({ onSelectFile }: Props) => {
  const { fileId } = useGetSearchParams({ paramList: ["fileId"] });

  useRunOnMountUnmount({
    onMount: () => {
      if (fileId) {
        const file = fileStore.getFile(fileId);
        if (file) {
          onSelectFile(file);
        }
      }
    },
  });
};
