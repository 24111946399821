import { useCallback } from "react";
import { useFileDownload, OnDownloadActions } from "shared/hooks/useFileDownload";
import { ItemRateReportResponse, contentClient } from "@eolas-medical/core";

interface UseDownloadItemRateReport {
  downloadItemRateReport: (
    params: DownloadItemRateReportParams,
    actions?: OnDownloadActions,
  ) => void;
  downloadingItemRateReport: boolean;
}
interface ItemRateDto {
  fileName: string;
  author: string;
  createdAt: string;
  readUsers?: string[];
  unreadUsers?: string[];
  readRatio?: string;
  totalRead?: string;
  totalUsers?: string;
}

interface DownloadItemRateReportParams {
  mainSectionId: string;
  contentId: string;
  itemRate: ItemRateDto;
}

const useDownloadItemRateReport = (): UseDownloadItemRateReport => {
  const getFileUrl = useCallback(async (paramsString: string) => {
    const { mainSectionId, contentId, itemRate } = JSON.parse(paramsString);
    const response: ItemRateReportResponse = await contentClient.createItemRateReport({
      mainSectionId,
      contentId,
      itemRate,
    });

    return { fileName: itemRate.fileName, fileUrl: response.s3DownloadUrl, fileExt: "pdf" };
  }, []);

  const { downloadFile, preparingDownload } = useFileDownload({ getFileAsync: getFileUrl });

  const downloadItemRateReport = useCallback(
    (params: DownloadItemRateReportParams, actions?: OnDownloadActions) => {
      downloadFile(JSON.stringify(params), actions);
    },
    [downloadFile],
  );

  return {
    downloadItemRateReport,
    downloadingItemRateReport: preparingDownload,
  };
};

export default useDownloadItemRateReport;
