import { BlobContent } from "@eolas-medical/core";
import { VideoPlayer } from "Components";
import useVideoViewer from "./useVideoViewer";

interface VideoViewerProps {
  video: BlobContent;
}

const VideoViewer = ({ video }: VideoViewerProps) => {
  const { onFileLoaded, onFileError } = useVideoViewer({ video });

  return (
    <div className="p-2 h-90vh" data-testid="video-viewer">
      <VideoPlayer
        url={video.url || ""}
        height="82%"
        width="82%"
        onLoad={onFileLoaded}
        onError={onFileError}
      />
    </div>
  );
};

export default VideoViewer;
