import mime from "mime-types";

import {
  BannerMetadataDto,
  ChildrenType,
  CreateSponsoredSectionDto,
  KnowledgeContentDomains,
  SponsoredType,
  UpdateSponsoredSectionDto,
} from "@eolas-medical/core";
import { ManageSlotWizardState } from "Pages/Knowledge/pages/SponsoredSlots/pages/ManageSlotsPage/types/general";
import { isSupportedFileExtension } from "./typeguards";
import { convertToLink } from "Utilities";

export function makeManageSponsoredSlotDto(props: {
  state: ManageSlotWizardState;
}): UpdateSponsoredSectionDto;

export function makeManageSponsoredSlotDto(props: {
  state: ManageSlotWizardState;
  mainSectionId: string;
  parentId: string;
  isDraft?: boolean;
}): CreateSponsoredSectionDto;

export function makeManageSponsoredSlotDto({
  state,
  mainSectionId,
  parentId,
  isDraft,
}: {
  state: ManageSlotWizardState;
  mainSectionId?: string;
  parentId?: string;
  isDraft?: boolean;
}): CreateSponsoredSectionDto | UpdateSponsoredSectionDto {
  const type = mainSectionId && parentId ? "create" : "update";

  let banner: BannerMetadataDto | undefined = undefined;
  let squareBanner: BannerMetadataDto | undefined = undefined;

  if (state.newImage) {
    const mimeType = state.newImage.type;

    const fileExt = mime.extension(mimeType) || "";

    if (!isSupportedFileExtension(fileExt)) {
      throw new Error("makeManageSponsoredSlotDto: Invalid file extension for banner image");
    }

    banner = { size: state.newImage.size, type: fileExt };
  }

  if (state.newSquareImage) {
    const mimeType = state.newSquareImage.type;

    const fileExt = mime.extension(mimeType) || "";

    if (!isSupportedFileExtension(fileExt)) {
      throw new Error("makeManageSponsoredSlotDto: Invalid file extension");
    }

    squareBanner = { size: state.newSquareImage.size, type: fileExt };
  }

  let url: string | undefined = undefined;

  if (state.externalUrl) {
    url = convertToLink(state.externalUrl);
  }

  let isPaid = true;

  if (state.isPaid?.value === "false") {
    isPaid = false;
  }

  const jobInfo = {
    specialties: state.specialties?.length ? state.specialties.map(({ value }) => value) : [],
    seniorities: state.seniorities?.length ? state.seniorities.map(({ value }) => value) : [],
    jobTitles: state.jobTitles?.length ? state.jobTitles.map(({ value }) => value) : [],
    countries: state.countries?.length ? state.countries.map(({ value }) => value) : [],
  };

  switch (type) {
    case "create": {
      let childrenType: ChildrenType = "CONTENT";
      let sponsoredType: SponsoredType;

      switch (state.type.value) {
        case "BANNER": {
          sponsoredType = "BANNER";
          break;
        }
        case "URL": {
          sponsoredType = "URL";
          break;
        }
        case "CONTENT_SECTIONS": {
          sponsoredType = "CONTENT";
          childrenType = "SECTION";
          break;
        }
        case "CONTENT_ITEMS": {
          sponsoredType = "CONTENT";
          childrenType = "CONTENT";
          break;
        }
      }

      if (!banner || !squareBanner) {
        throw new Error("makeManageSponsoredSlotDto: expected new images");
      }
      if (sponsoredType === "URL" && !url) {
        throw new Error("makeManageSponsoredSlotDto: expected url for URL type");
      }
      const sponsoredInfo: CreateSponsoredSectionDto["sponsoredInfo"] = {
        sponsoredType,
        ...jobInfo,
        banner,
        squareBanner,
        url,
        isPaid,
      };

      return {
        name: state.name,
        description: state.description,
        childrenType,
        mainSectionId,
        parentId,
        isDraft,
        domain: KnowledgeContentDomains.sponsoredSlots,
        icon: "012-medical-history-1.svg",
        sponsoredInfo,
      };
    }
    case "update": {
      const sponsoredInfo: UpdateSponsoredSectionDto["sponsoredInfo"] = {
        ...jobInfo,
        banner,
        squareBanner,
        url,
        isPaid,
      };
      return { name: state.name, description: state.description, sponsoredInfo };
    }
  }
}
