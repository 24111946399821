import { InnerModalWrapper } from "UIKit/Modal/components/InnerModalWrapper";
import { useState } from "react";
import { ExternalLinkForm } from "./components/ExternalLinkForm";
import { LocalDocumentsList } from "./components/LocalDocumentsList";
import { FileLinkIcon, WindowLinkIcon } from "Assets/Icons/colored";
import { useTranslation } from "react-i18next";
import { Title } from "UIKit/Typography/Title";
import { ModalBody } from "UIKit/Modal/components/ModalBody";
import { LinkTypeOption } from "./components/LinkTypeOption";

type Props = {
  onInsertLink: (link: string) => void;
  disabledIds?: string[];
  linkType?: "external" | "local";
};

export const InsertLinkModal = ({ onInsertLink, disabledIds, linkType: linkTypeProp }: Props) => {
  const { t } = useTranslation();
  const [linkType, setLinkType] = useState<"external" | "local" | null>(linkTypeProp || null);

  if (linkType === "external") {
    return <ExternalLinkForm onSubmit={onInsertLink} onGoBack={() => setLinkType(null)} />;
  }

  if (linkType === "local") {
    return <LocalDocumentsList onInsertLink={onInsertLink} disabledIds={disabledIds} />;
  }

  return (
    <InnerModalWrapper>
      <div className="relative max-h-80vh overflow-y-auto">
        <div className="p-8 flex items-stretch w-full">
          <Title level={4} className="flex-1 text-center">
            {t("mode_selector_title")}
          </Title>
        </div>
        <ModalBody className="overflow-y-auto">
          <div className="flex items-center justify-center space-x-4">
            <LinkTypeOption
              title={t("in_app_link_text")}
              description={t("in_app_link_sub_text")}
              icon={<FileLinkIcon />}
              onClick={() => setLinkType("local")}
            />
            <LinkTypeOption
              title={t("external_link_text")}
              description={t("external_link_sub_text")}
              icon={<WindowLinkIcon />}
              onClick={() => setLinkType("external")}
            />
          </div>
        </ModalBody>
      </div>
    </InnerModalWrapper>
  );
};
