import { useTranslation } from "react-i18next";

import TextWithLottie from "UIKit/Wizard/templates/ChooseFileTypeStep/components/TextWithLottie";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { FirstStepComponentProps, FirstStepTypeData } from "../../types";
import { FirstStepWrapper } from "../firstStepComponents/FirstStepWrapper";
import { eolasEditor } from "Assets";
import { EolasSupportedMiscellaneousType, NewContentType } from "@eolas-medical/core";

const SelectEolasEditor = ({
  selectTypeComponent,
  wizardState,
  onClickForward,
}: FirstStepComponentProps) => {
  const { t } = useTranslation();
  return (
    <FirstStepWrapper
      fileId={wizardState.fileId}
      buttonRowProps={{
        forwardButton: {
          onClick: () => {
            const newState = makeMutableWizardState(wizardState);
            if (newState.isExistingFileWithNewType) {
              newState.dto.newType = NewContentType.EOLAS_EDITOR;
            } else {
              newState.type = EolasSupportedMiscellaneousType.EOLAS_EDITOR;
            }
            onClickForward(newState);
          },
        },
      }}
      selectTypeComponent={selectTypeComponent}
    >
      <TextWithLottie
        title={t("editor_lottie_intro")}
        subtitle={t("editor_lottie_subtitle")}
        descriptionText={t("editor_lottie_description")}
        animationsData={eolasEditor}
      />
    </FirstStepWrapper>
  );
};

export const selectEolasEditorData: FirstStepTypeData = {
  StepComponent: SelectEolasEditor,
  props: {
    getNextStepName: () => "editorFileDetails",
  },
};
