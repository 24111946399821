import { FormElement, Input, Textarea } from "UIKit";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "Assets";
import { Control } from "react-hook-form";
import Select from "UIKit/Select/Select";
import { SpecialtyOptions, specialtyOptions } from "@eolas-medical/core";
import useGetLocations from "Hooks/useGetLocations";
import { GroupBase, OptionsOrGroups } from "react-select";
import { formatLocation } from "Utilities/helpers";

interface SpaceDetailsProps {
  control: Control<{
    name: string;
    specialty: SpecialtyOptions[];
    description: string;
    location: {
      label: string;
      value: string;
    };
  }>;
  autoFocus?: boolean;
  isNameValid?: boolean;
  onSubmit: () => void;
  isOrgAdminSpace?: boolean;
}

const SpaceDetails = ({
  control,
  onSubmit,
  autoFocus = false,
  isNameValid,
  isOrgAdminSpace = false,
}: SpaceDetailsProps) => {
  const { t } = useTranslation();

  const { getLocations } = useGetLocations();

  const handleLoadOptions = async (
    inputValue: string,
    callback: (options: OptionsOrGroups<unknown, GroupBase<unknown>>) => void,
  ) => {
    const locations = await getLocations(inputValue);
    const options = locations.map((location) => {
      return formatLocation({ location });
    });
    callback(options);
  };

  return (
    <form onSubmit={onSubmit} className="max-h-50vh overflow-auto">
      <div className="col-span-2 p-2">
        <FormElement
          required
          id="name"
          control={control}
          label={t("space_create_name")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                autoFocus={autoFocus}
                size="md"
                iconRight={isNameValid ? <CheckIcon className="text-green" /> : undefined}
                placeholder={t("edit_space_details_name_placeholder")}
                className="text-base resize bg-white rounded-md"
                value={value}
              />
            );
          }}
        </FormElement>
        <FormElement
          required
          id="specialty"
          control={control}
          label={t("space_create_specialty")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Select
                {...rest}
                isMulti
                options={specialtyOptions}
                isSearchable
                defaultValue={value}
              />
            );
          }}
        </FormElement>
        <FormElement
          required={!isOrgAdminSpace}
          id="location"
          control={control}
          label={t("my-medical_personal_details_edit_profile_location")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;

            return (
              <Select
                {...rest}
                placeholder={t("my-medical_personal_details_edit_profile_location_placeholder")}
                isSearchable
                loadOptions={handleLoadOptions}
                defaultValue={value}
              />
            );
          }}
        </FormElement>
        <FormElement
          required
          id="description"
          control={control}
          label={t("space_create_description")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Textarea
                {...rest}
                placeholder={t("edit_space_details_description_placeholder")}
                value={value}
                className="resize-none"
                characterLimit={350}
                spellCheck
              />
            );
          }}
        </FormElement>
      </div>
    </form>
  );
};

export default SpaceDetails;
