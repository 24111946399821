import { useTranslation } from "react-i18next";
import { InnerModalWrapper } from "../components";
import { WarningIcon } from "Assets";
import { Text } from "UIKit/Typography";
import { Button } from "UIKit/Button";

type Props = {
  text?: string;
  onClose?: () => void;
};

export const ErrorModal = ({ onClose, text = "fileWithSearch_addNew_uploadComplete" }: Props) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center space-y-4 p-4">
        <WarningIcon className="h-16 w-16" />
        <Text level={1} className="text-center text-red-500">
          {t(text)}
        </Text>
        <Button
          size="lg"
          color="grey"
          variant="outline"
          onClick={onClose}
          className="order-1 sm:order-0"
        >
          {t("general_close")}
        </Button>
      </div>
    </InnerModalWrapper>
  );
};
