import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { Button, ButtonProps } from "UIKit/Button";
import { WizardProgress } from "UIKit/FormElements";
import { InnerModalWrapper, ModalBody, ModalHeader } from "UIKit/Modal";
import { Text, Title } from "UIKit/Typography";

export type WizardModalButtonProps = { text?: string; onClick: () => void } & Partial<
  Pick<ButtonProps, "size" | "color" | "variant" | "isLoading" | "disabled" | "type">
>;

type ProgressData = { currentStepNo: number; totalNumberOfSteps: number };

export type ButtonRowProps = {
  containerClassName?: string;
  previousButton?: WizardModalButtonProps;
  altForwardButton?: WizardModalButtonProps;
  forwardButton?: WizardModalButtonProps;
};

export type EolasWizardModalWrapperProps = {
  children: React.ReactNode;
  modalWrapperClassName?: string;
  progressData?: ProgressData;
  header?: { title: string; TopIcon?: JSX.Element; subTitle?: string; subtitleClassName?: string };
  buttonRowProps?: ButtonRowProps;
};

export const EolasWizardModalWrapper = forwardRef<HTMLDivElement, EolasWizardModalWrapperProps>(
  ({ modalWrapperClassName, progressData, header, children, buttonRowProps }, ref) => {
    return (
      <InnerModalWrapper className={modalWrapperClassName}>
        {progressData ? (
          <WizardProgress
            currentStep={progressData.currentStepNo}
            maxSteps={progressData.totalNumberOfSteps}
          />
        ) : null}
        {header ? (
          <ModalHeader className="flex flex-col">
            {header.TopIcon ? (
              <div className="w-10 h-10 md:w-12 md:h-12">{header.TopIcon}</div>
            ) : null}
            <Title level={6}>{header.title}</Title>
            {header.subTitle ? (
              <Text level={1} className={twMerge(["text-grey-700", header.subtitleClassName])}>
                {header.subTitle}
              </Text>
            ) : null}
          </ModalHeader>
        ) : null}
        <div ref={ref} className="flex flex-col h-full">
          <ModalBody className="overflow-y-auto overflow-x-hidden flex-grow max-h-80vh">
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 p-2">
              <div className="col-span-2">
                {children}
                <WizardButtonRow buttonRowProps={buttonRowProps} progressData={progressData} />
              </div>
            </div>
          </ModalBody>
        </div>
      </InnerModalWrapper>
    );
  },
);

const WizardButtonRow = ({
  buttonRowProps,
  progressData,
}: {
  buttonRowProps?: ButtonRowProps;
  progressData?: ProgressData;
}) => {
  const { t } = useTranslation();

  if (
    !buttonRowProps ||
    (!buttonRowProps.previousButton &&
      !buttonRowProps.altForwardButton &&
      !buttonRowProps.forwardButton)
  ) {
    return null;
  }

  const { containerClassName, previousButton, altForwardButton, forwardButton } = buttonRowProps;

  return (
    <div
      className={twMerge([
        "flex flex-col sm:flex-row sm:items-center justify-end mt-6",
        containerClassName,
      ])}
    >
      {progressData ? (
        <Text
          level={1}
          className="font-bold text-grey-700 text-center sm:text-left flex-1 order-1 sm:order-0 mt-4 sm:mt-0"
        >
          {t("wizard_step", {
            currentStep: progressData.currentStepNo + 1,
            maxSteps: progressData.totalNumberOfSteps,
          })}
        </Text>
      ) : null}
      <div className="flex items-center space-x-4 sm:space-x-6 order-0 sm:order-1 flex-1 justify-between">
        <Button
          size={previousButton?.size || "lg"}
          color={previousButton?.color || "grey"}
          type={previousButton?.type || "button"}
          onClick={previousButton?.onClick}
          variant={previousButton?.variant || "outline"}
          className="sm:w-32"
          disabled={!previousButton || previousButton.disabled || previousButton.isLoading}
        >
          {previousButton?.text || t("general_back")}
        </Button>
        <div className="flex items-center justify-center space-x-4 sm:space-x-6">
          {altForwardButton ? (
            <Button
              size={altForwardButton.size || "lg"}
              color={altForwardButton.color || "yellow"}
              onClick={altForwardButton.onClick}
              isLoading={altForwardButton.isLoading}
              disabled={altForwardButton.disabled || altForwardButton.isLoading}
              className="sm:w-32"
              type={altForwardButton.type || "button"}
            >
              {altForwardButton.text || t("content_item_draft_mode")}
            </Button>
          ) : null}
          {forwardButton ? (
            <Button
              size={forwardButton.size || "lg"}
              color={forwardButton.color || "blue"}
              onClick={forwardButton.onClick}
              isLoading={forwardButton.isLoading}
              disabled={forwardButton.disabled || forwardButton.isLoading}
              className="sm:w-32"
              type={forwardButton.type || "button"}
            >
              {forwardButton.text || t("general_next")}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
