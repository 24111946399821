import { KnowledgeContentRepository } from "Pages/Knowledge/pages/KnowledgeContent";
import { analyticsCallbacks } from "./config/analyticsCallbacks";

const SmPcsContentRepository = () => {
  return (
    <KnowledgeContentRepository {...analyticsCallbacks} domain="smpcs" hasCopilotData={false} />
  );
};

export default SmPcsContentRepository;
