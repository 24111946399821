import { useInfiniteQuery } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";
import { Space, spacesClient } from "@eolas-medical/core";
import { useMemo } from "react";
import { mapSpecialtyNames } from "../helpers";

interface UsePublicSpaces {
  publicSpaces: Space[];
  publicSpacesLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
}

const usePublicSpaces = ({ enabled }: { enabled: boolean }): UsePublicSpaces => {
  const { data, isInitialLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: spacesKeys.publicSpaces(),
      queryFn: ({ pageParam }) =>
        spacesClient.listSuggestedSpaces({
          lastKey: pageParam,
          extended: "true",
          limit: 25,
        }),
      getNextPageParam: (lastPage) => (lastPage?.lastKey ? lastPage.lastKey : undefined),
      staleTime: 20000,
      retry: false,
      enabled,
    });

  const publicSpaces: Space[] = useMemo(() => {
    return (
      data?.pages
        ?.flatMap((page) => page.records)
        ?.map((record) => ({
          ...record,
          name: record.name,
          description: record.description,
          accessType: record.accessType,
          specialty: mapSpecialtyNames(
            Array.isArray(record?.specialty) ? record.specialty : record.specialty?.split(","),
          ),
          userCount: record.appUsersCount,
        })) ?? []
    );
  }, [data?.pages]);

  return {
    publicSpaces,
    publicSpacesLoading: isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default usePublicSpaces;
