import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { AddFileIcon, AddFileCheckIcon } from "Assets";

import { theme } from "../theme";
import { Button } from "../Button";
import { FormLabel, FormFieldError } from "../FormElements";
import { FilePickerAllowedTypes } from "Utilities/types";

export type FileWithExt = { file: File; ext?: string; url?: string };

export interface EolasFilePickerProps {
  error?: string;
  value?: string;
  required?: boolean;
  isDisabled?: boolean;
  fileType?: FilePickerAllowedTypes;
  allowedFileExtensionsOverride?: string[];
  formLabel?: string;
  changeLabel?: string;
  uploadLabel?: string;
  descriptionText?: string;
  subDescriptionText?: string;
  onChange(value: File): void;
}

export const EolasFilePicker: React.FC<EolasFilePickerProps> = ({
  error,
  value,
  onChange,
  required,
  isDisabled,
  fileType = "blob",
  formLabel = "",
  changeLabel = "",
  uploadLabel = "",
  descriptionText = "",
  subDescriptionText = "",
  allowedFileExtensionsOverride,
}: EolasFilePickerProps) => {
  const handleChange = useCallback(
    async (file: File) => {
      onChange(file);
    },
    [onChange],
  );

  const accept = useMemo(() => {
    switch (fileType) {
      case "ms-office":
        return [".xls", ".xlsx", ".doc", ".docx", ".ppt", ".pptx"];
      case "image":
        return [".png", ".jpg", ".jpeg"];
      case "mp4":
        return [".mp4"];
      case "pdf":
        return [".pdf"];
      case "blob":
        if (allowedFileExtensionsOverride) {
          return allowedFileExtensionsOverride;
        }
        return [
          ".xls",
          ".xlsx",
          ".doc",
          ".docx",
          ".ppt",
          ".pptx",
          ".mp4",
          ".pdf",
          ".png",
          ".jpg",
          ".jpeg",
          ".mov",
          "video/quicktime",
        ];
    }
  }, [fileType, allowedFileExtensionsOverride]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept,
    onDrop: (files) => {
      handleChange(files[0]);
    },
  });

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? { backgroundColor: get(theme, "colors.grey.hover") } : {};
  }, [isDragActive]);

  const renderFileContent = () => {
    if (value) {
      return <span className="text-center font-semibold">{value ?? ""}</span>;
    }

    return (
      <div className="flex flex-col items-center space-y-1">
        <span className="text-grey-600 font-semibold">{descriptionText}</span>
        <span className="text-grey-600 font-semibold">{subDescriptionText}</span>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-col space-y-2">
        <FormLabel htmlFor="" required={required}>
          {formLabel}
        </FormLabel>

        <div
          className={`
            flex flex-col justify-center items-center rounded-md h-56 space-y-4 px-4
            ${isDisabled && "bg-grey"}
            ${error ? "border-red-focus" : "border-grey-400"}
            ${value ? "bg-white border-solid border-2" : "bg-grey-50 border-2 border-dashed"}
          `}
          {...getRootProps({ style: dropzoneStyle })}
        >
          {value ? (
            <AddFileCheckIcon className="fill-current" width={40} height={40} />
          ) : (
            <AddFileIcon className="fill-current" width={40} height={40} />
          )}

          {renderFileContent()}

          <Button
            color="grey"
            variant="outline"
            className="self-center w-full sm:w-min whitespace-nowrap"
          >
            {value ? changeLabel : uploadLabel}
          </Button>

          <input {...getInputProps()} data-testid="file-picker-input" />
        </div>
      </div>

      <FormFieldError>{error}</FormFieldError>
    </div>
  );
};
