import { useTranslation } from "react-i18next";
import {
  FormItem,
  Step,
  Wizard,
  useFlashcardCreatorStep,
  useFormStep,
  useImagePickerStep,
  useUploadProgressAndSuccessStep,
  useWizard,
} from "UIKit";

import { useAddFlashcardItemActions } from "./hooks/useAddFlashcardItemActions";
import { AddFlashcardItemWizardStep } from "./types";
import useFormStepConfig, { FlashcardFormStep } from "./hooks/useFormStepConfig";
import { useRef } from "react";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { observer } from "mobx-react-lite";

export interface AddFlashcardItemWizardProps {
  mainSectionId: string;
  hasSpecialties?: boolean;
  hasCopilotData?: boolean;
  cancelOnBack?: boolean;
  onClose: () => void;
  onChangeWizard: () => void;
}

const AddFlashcardItemWizard = observer(
  ({
    mainSectionId,
    hasSpecialties = false,
    hasCopilotData = false,
    cancelOnBack = false,
    onChangeWizard,
    onClose,
  }: AddFlashcardItemWizardProps) => {
    const { t } = useTranslation();
    const { wizardControl } = useWizard<AddFlashcardItemWizardStep>();
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const { flags } = useLaunchDarkly();
    const allowDrafting = flags[LDFlagNames.ALLOW_DRAFTING_ITEMS] ?? false;

    const {
      wizardState,
      addingFlashcardItem,
      addFlashcardItemSuccess,
      addFlashcardItemError,
      addFlashcardItemProgress,
      onAddFlashcardContent,
      onAddFlashcardDetails,
      onSubmitFlashcardImage,
      shouldAllowBlankHeroImageSelection,
    } = useAddFlashcardItemActions();

    const getPrevStep = (currentStep: AddFlashcardItemWizardStep) => {
      if (currentStep === "flashcard-form") {
        onChangeWizard();
      }

      if (currentStep === "flashcard-creator") {
        return "flashcard-form";
      }

      if (currentStep === "flashcard-hero-image") {
        return "flashcard-creator";
      }

      if (currentStep === "upload-progress") {
        return "flashcard-hero-image";
      }

      return currentStep;
    };

    const formItems: FormItem<FlashcardFormStep>[] = useFormStepConfig({
      hasSpecialties,
      hasCopilotData,
    });

    const flashcardForm = useFormStep<"flashcard-form", FlashcardFormStep>({
      stepName: "flashcard-form",
      title: t("flashcards_page_title"),
      formItems,
      backLabel: cancelOnBack ? t("general_cancel") : t("general_back"),
      onSubmit: onAddFlashcardDetails,
    });

    const flashcardCreator = useFlashcardCreatorStep<"flashcard-creator">({
      pageTitle: t("flashcards_page_title"),
      editorContent: wizardState?.content,
      onSubmit: onAddFlashcardContent,
      contentRepositoryId: mainSectionId,
      modalBodyRef: modalBodyRef,
      flashcardTitle: wizardState?.title ?? "",
      flashcardDescription: wizardState?.description ?? "",
    });

    const flashcardHeroImage = useImagePickerStep({
      stepName: "flashcard-hero-image",
      title: t("flashcards_page_title_hero_image"),
      onSubmit: onSubmitFlashcardImage,
      errorDraggedImageText: t("imagePicker_too_large_error"),
      shouldAllowBlankSelection: shouldAllowBlankHeroImageSelection,
      nextLabel: t("content_item_publish"),
    });

    const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<"upload-progress">({
      stepName: "upload-progress",
      fileUploading: addingFlashcardItem,
      uploadProgress: addFlashcardItemProgress,
      fileUploadError: addFlashcardItemError,
      fileUploadedSuccess: addFlashcardItemSuccess,
      onFinishUpload: onClose,
    });

    const steps: Step<AddFlashcardItemWizardStep>[] = [
      flashcardForm,
      flashcardCreator,
      flashcardHeroImage,
      uploadProgressAndSuccessStep,
    ];

    const getStepClassName = (step: string) => {
      if (step === "flashcard-creator") {
        return "max-w-6xl h-90vh";
      }
      return "";
    };

    const showAltSubmit = (step: string) => {
      if (!allowDrafting) return false;
      else {
        return step === "flashcard-hero-image";
      }
    };

    return (
      <Wizard
        getStepClassName={getStepClassName}
        steps={steps}
        getPrevStep={getPrevStep}
        wizardControl={wizardControl}
        modalBodyRef={modalBodyRef}
        showAltSubmit={showAltSubmit}
        altSubmitLabel={t("content_item_draft_label")}
      />
    );
  },
);

export default AddFlashcardItemWizard;
