import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { SectionBasicTileProps } from "../EolasSectionTile.types";
import { useTranslation } from "react-i18next";
import { LabelColor } from "UIKit/EolasTile/EolasTile.types";

export const SectionBasicTile = ({
  title,
  onClick,
  iconUrl,
  isDisabled = false,
  rightAction,
  description,
  numOfFiles,
}: SectionBasicTileProps) => {
  const { t } = useTranslation();
  const { getIconComponent } = useIconComponent("w-full h-full");

  const rightLabelProps: { text: string; color: LabelColor } | undefined = numOfFiles
    ? {
        text: t("general_files", { count: numOfFiles }),
        color: "transparent",
      }
    : undefined;

  return (
    <EolasTile
      isDisabled={isDisabled}
      variant="rectangular"
      primaryText={title}
      secondaryText={description}
      rightLabel={rightLabelProps}
      rightAction={rightAction}
      icon={
        <div className="rounded-1 bg-grey-100 p-4 w-full h-full">{getIconComponent(iconUrl)}</div>
      }
      onClick={onClick}
    />
  );
};
