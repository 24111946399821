import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ManageSlotWizardState } from "Pages/Knowledge/pages/SponsoredSlots/pages/ManageSlotsPage/types/general";
import axios, { AxiosProgressEvent } from "axios";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { makeManageSponsoredSlotDto } from "../functions/makeManageSponsoredSlotDto";
import {
  eolasLogger,
  isSponsoredContentSection,
  knowledgeContentClient,
} from "@eolas-medical/core";
import { sponsoredSlotQueryKeys } from "./sponsoredSlots.queryKeys";

const CREATE_SECTION_PERCENTAGE = 25;
const HALF_REMAINDER = (100 - CREATE_SECTION_PERCENTAGE) / 2;

export const useAddSponsoredSlot = ({
  mainSectionId,
  parentId,
}: {
  mainSectionId: string;
  parentId: string;
}) => {
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [uploadProgress2, setUploadProgress2] = useState(0);
  const [hasUploadedContent, setHasUploadedContent] = useState(false);
  const tempSectionIdRef = useRef("");
  const queryClient = useQueryClient();

  let addSponsoredSlotProgress = hasUploadedContent ? CREATE_SECTION_PERCENTAGE : 0;

  if (uploadProgress1) {
    addSponsoredSlotProgress =
      addSponsoredSlotProgress + Math.floor(uploadProgress1 * HALF_REMAINDER);
  }

  if (uploadProgress2) {
    addSponsoredSlotProgress =
      addSponsoredSlotProgress + Math.floor(uploadProgress2 * HALF_REMAINDER);
  }

  const makeSetProgress = (progressType: "1" | "2") => {
    let setUploadProgress: Dispatch<SetStateAction<number>>;
    if (progressType === "1") {
      setUploadProgress = setUploadProgress1;
    } else {
      setUploadProgress = setUploadProgress2;
    }
    return (progressEvent: AxiosProgressEvent) => {
      setUploadProgress((old) => {
        if (!progressEvent.total) {
          return old;
        }
        const newCompleted = progressEvent.loaded / progressEvent.total;
        if (newCompleted !== old) {
          return newCompleted;
        }
        return old;
      });
    };
  };

  const { mutate, isLoading, isSuccess, error } = useMutation({
    mutationFn: async ({ state, isDraft }: { state: ManageSlotWizardState; isDraft?: boolean }) => {
      const dto = makeManageSponsoredSlotDto({ state, mainSectionId, parentId, isDraft });
      const section = await knowledgeContentClient.createSection(dto);
      tempSectionIdRef.current = section.id;
      setHasUploadedContent(true);
      if (
        !isSponsoredContentSection(section) ||
        !section.sponsoredInfo.bannerUploadUrl ||
        !section.sponsoredInfo.squareBannerUploadUrl ||
        !state.newImage ||
        !state.newSquareImage
      ) {
        throw new Error("useAddSponsoredSlot: incorrect sponsored slot section data");
      }
      await Promise.all([
        axios.put(section.sponsoredInfo.bannerUploadUrl, state.newImage, {
          onUploadProgress: makeSetProgress("1"),
          headers: {
            "content-type": state.newImage.type,
          },
        }),
        axios.put(section.sponsoredInfo.squareBannerUploadUrl, state.newSquareImage, {
          onUploadProgress: makeSetProgress("2"),
          headers: {
            "content-type": state.newSquareImage.type,
          },
        }),
      ]);
    },
    onError: (newError) => {
      if (!tempSectionIdRef.current) {
        return;
      }
      try {
        knowledgeContentClient.deleteSection(tempSectionIdRef.current);
      } catch (error) {
        eolasLogger.error(error, {
          info: "useAddSponsoredSlot: autodeletion error after creation upload failure",
        });
      }
      return newError;
    },
    onSuccess: async () => {
      tempSectionIdRef.current = "";
      queryClient.invalidateQueries(sponsoredSlotQueryKeys.getAllSponsoredSlots(mainSectionId));
    },
  });

  return {
    addSponsoredSlot: mutate,
    isAddingSponsoredSlot: isLoading,
    isAddingSponsoredSlotSuccess: isSuccess,
    addSponsoredSlotError: error,
    addSponsoredSlotProgress,
  };
};
