import { getBlobType, getFileName } from "Utilities/fileHelpers";
import { FirstStepComponentProps, FirstStepTypeData } from "../../types";
import { useSelectDsmBlobFirstStep } from "./hooks/useSelectDsmBlobFirstStep";
import { FirstStepWrapper } from "../firstStepComponents/FirstStepWrapper";
import { SelectFile } from "../SelectFile/SelectFile";
import {
  EolasSupportedMiscellaneousType,
  NewContentType,
  parseDsmMetadata,
} from "@eolas-medical/core";
import { makeMutableWizardState } from "../../functions/makeMutableWizardState";
import { isDev } from "Utilities/helpers";
import { toAwsJSON } from "Utilities";

const SelectDsmBlobFirstStep = ({
  selectTypeComponent,
  wizardState,
  onClickForward,
}: FirstStepComponentProps) => {
  const { handleSubmit, control, parseDsmData, isLoading, mediaName } =
    useSelectDsmBlobFirstStep(wizardState);
  return (
    <FirstStepWrapper
      fileId={wizardState.fileId}
      buttonRowProps={{
        forwardButton: {
          onClick: handleSubmit(async ({ blob }) => {
            if (!blob) {
              const newState = makeMutableWizardState(wizardState);
              if (!newState.fileId || newState.isExistingFileWithNewType) {
                if (isDev()) {
                  throw new Error("Select DSM blob: no blob given. This should not happen");
                }
                return;
              }
              const metadata = parseDsmMetadata(newState.dto.metadata ?? "");
              if (!metadata) {
                if (isDev()) {
                  throw new Error("Select DSM blob: DSM metadata is null. This should not happen");
                }
              }
              newState.dto.metadata = toAwsJSON(metadata);
              onClickForward(newState);

              return;
            }
            const { newState, dsmName } = await parseDsmData(blob);
            const fileType = getBlobType(blob.type);
            const mediaName = `${getFileName(blob)}.${fileType}`;
            if (newState.isExistingFileWithNewType) {
              if (newState.dto.newType === NewContentType.DSM) {
                newState.dto.newProperties.name = dsmName;
                newState.dto.newProperties.mediaName = mediaName;
              }
            } else {
              newState.type = EolasSupportedMiscellaneousType.DSM;
              newState.dto.mediaName = mediaName;
              newState.dto.name = dsmName;
            }
            onClickForward(newState);
          }),
          isLoading,
        },
      }}
      selectTypeComponent={selectTypeComponent}
    >
      <SelectFile
        control={control}
        allowedFileExtensionsOverride={[".xlsx"]}
        mediaName={mediaName}
      />
    </FirstStepWrapper>
  );
};

export const selectDsmBlobData: FirstStepTypeData = {
  StepComponent: SelectDsmBlobFirstStep,
  props: {
    getNextStepName: (_, state) => {
      if (state.error) {
        return "dsmError";
      }
      return "fileDetails";
    },
  },
};
