import { PatientGroup } from "@eolas-medical/core";
import React from "react";
import { twMerge } from "tailwind-merge";

interface BnfColouredContainerProps {
  type: PatientGroup;
  children: React.ReactNode;
}

const BnfColouredContainer = ({ children, type }: BnfColouredContainerProps) => {
  let containerClass = "px-4 py-4 rounded-md bg-grey-50";

  if (type === "adult") {
    containerClass = twMerge(containerClass, "bg-blue-50");
  }

  if (type === "child" || type == "neonate") {
    containerClass = twMerge(containerClass, "bg-red-50");
  }

  return <div className={containerClass}>{children}</div>;
};

export default BnfColouredContainer;
