import { useTranslation } from "react-i18next";

import { ActivateIcon, DeactivateIcon } from "Assets";
import { EolasChecklistSection } from "Utilities/types";

import {
  Text,
  Title,
  Button,
  ModalBody,
  FormError,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useDeactivateChecklist } from "../hooks";

export interface DeactivateChecklistModalProps {
  isDisabled: boolean;
  checklist: EolasChecklistSection;
  onCloseModal(): void;
}

export const DeactivateChecklistModal: React.FC<DeactivateChecklistModalProps> = ({
  checklist,
  isDisabled,
  onCloseModal,
}: DeactivateChecklistModalProps) => {
  const { t } = useTranslation();
  const { isLoading, isSuccessful, error, onDeactivate } = useDeactivateChecklist(
    checklist,
    isDisabled,
  );

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={t(
          isDisabled
            ? "submittedChecklists_activated_success"
            : "submittedChecklists_deactivated_success",
        )}
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col space-y-6">
        {isDisabled ? (
          <ActivateIcon height={72} width={72} />
        ) : (
          <DeactivateIcon height={72} width={72} />
        )}
        <Title level={5}>
          {t(
            isDisabled
              ? "submittedChecklists_activated_modal_title"
              : "submittedChecklists_deactivated_modal_title",
          )}
        </Title>
      </ModalHeader>

      <ModalBody className="pt-0 sm:pt-0">
        <Text
          level={2}
          data-testid="deactivateChecklistModal"
          className="text-center text-grey-700"
        >
          {t(isDisabled ? "checklist_activate_text" : "checklist_deactivate_text")}
        </Text>

        <Button
          type="button"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={onDeactivate}
          className="mt-16 mb-4"
          color={isDisabled ? "blue" : "red"}
          data-testid="activateChecklistConfirmation"
        >
          {t(isDisabled ? "checklist_activate_button" : "checklist_deactivate_button")}
        </Button>

        <Button color="grey" variant="outline" onClick={onCloseModal}>
          Cancel
        </Button>

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
