import { KnowledgeContentDomains, KnowledgeContentSection } from "@eolas-medical/core";
import { BaseAppRoute } from "Utilities/types";
import { LocationDescriptorObject } from "history";

export const makeSponsoredContentRoute = (
  section: KnowledgeContentSection,
): LocationDescriptorObject<undefined> => {
  let route = `/${BaseAppRoute.knowledge}/${KnowledgeContentDomains.sponsoredSlotsContent}`;

  if (section.childrenType === "SECTION") {
    route = route + `/${encodeURIComponent(section.id)}`;
  } else if (section.childrenType === "CONTENT") {
    route = route + `/${encodeURIComponent(section.id)}/${encodeURIComponent(section.id)}`;
  }

  const params = new URLSearchParams();
  params.set("title", section.name);

  return { pathname: route, search: params.toString() };
};
