import { UserList } from "UIKit/AvatarList/AvatarList";
import { useMemo } from "react";

const useFilteredAudienceUsers = ({
  userList,
  searchText,
  showUnread,
}: {
  userList?: UserList[];
  searchText: string;
  showUnread: boolean;
}) => {
  return useMemo(() => {
    return userList?.filter((user) => {
      const matchesSearchText =
        searchText === "" ||
        user.givenName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchText.toLowerCase());
      const matchesUnreadStatus = !showUnread || !user.isRead;
      return matchesSearchText && matchesUnreadStatus;
    });
  }, [userList, searchText, showUnread]);
};

export default useFilteredAudienceUsers;
