import { MonographType, SupportedSection } from "@eolas-medical/core";
import { BnfRouteKey } from "modules/bnf/types";
import { makeBnfRoute } from "../../../routes/BnfRoutes";
import { isSupportedSection } from "./typeguards";
import { isUrlValid } from "Utilities";

const routeKeyLookup: Record<SupportedSection, Record<MonographType, BnfRouteKey>> = {
  drug: { bnf: "bnf", bnfc: "bnfc" },
  guidance: { bnf: "guidance_adult", bnfc: "guidance_child" },
  treatmentSummaries: { bnf: "treatment_summaries_adult", bnfc: "treatment_summaries_child" },
  about: { bnf: "about_adult", bnfc: "about_child" },
  dentalPractitionersFormulary: { bnf: "dental_formulary_adult", bnfc: "dental_formulary_child" },
  nursePrescribersFormulary: {
    bnf: "nurse_prescribers_formulary_adult",
    bnfc: "nurse_prescribers_formulary_child",
  },
  medicalDevice: { bnf: "medical_devices_adult", bnfc: "medical_devices_child" },
  woundManagement: { bnf: "wound_management_adult", bnfc: "wound_management_child" },
};

export const processLink = ({
  href,
  type,
}: {
  href: string;
  type: MonographType;
}): { internalLink: ReturnType<typeof makeBnfRoute> | null; isExternalLink: boolean } => {
  if (isUrlValid(href)) {
    // Valid url, therefore this was not an internal BNF link:
    return { internalLink: null, isExternalLink: true };
  }

  const parts = href.split("/");
  let idOrSid = "";
  let maybeSection = "";
  if (parts.length === 3) {
    [, maybeSection, idOrSid] = parts;
  } else if (parts.length === 4) {
    [, maybeSection, , idOrSid] = parts;
  }
  const details = isSupportedSection(maybeSection) ? routeKeyLookup[maybeSection] : null;
  if (!details || !idOrSid) {
    return { internalLink: null, isExternalLink: false };
  }
  return {
    internalLink: makeBnfRoute({ routeKey: details[type], idOrSid }),
    isExternalLink: false,
  };
};
