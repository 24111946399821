import { FormItem } from "UIKit";
import { useTranslation } from "react-i18next";
import {
  JobTitleOptions,
  SeniorityOptions,
  SpecialtyOptions,
  SponsoredInfoSection,
  jobTitleOptions,
  seniorityOptions,
  specialtyOptions,
} from "@eolas-medical/core";
import {
  DetailsFormValues,
  ExternalUrlFormValues,
  IsPaidOption,
  SlotOption,
} from "../types/general";
import { countriesOptions, isPaidOptions, slotOptions } from "../data/slotOptions";
import { Option } from "UIKit/Select/Select";

const useGetFormStepConfigs = (existingSection?: SponsoredInfoSection) => {
  const { t } = useTranslation();

  const slotTypeField: FormItem<{ type: SlotOption }> = {
    fieldName: "type",
    fieldLabel: t("knowledge_sponsored_slot_add_type"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_type_placeholder"),
    required: true,
    itemType: "eolas-select",
    selectOptions: slotOptions,
  };

  const titleField: FormItem<{ name: string }> = {
    fieldName: "name",
    fieldLabel: t("knowledge_sponsored_slot_add_title"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_title_placeholder"),
    itemType: "input",
    required: true,
  };

  const descriptionField: FormItem<{ description: string }> = {
    fieldName: "description",
    fieldLabel: t("knowledge_sponsored_slot_add_description"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_description_placeholder"),
    itemType: "text-area",
    required: true,
  };

  const specialtiesField: FormItem<{ specialties?: SpecialtyOptions[] }> = {
    fieldName: "specialties",
    fieldLabel: t("knowledge_sponsored_slot_add_specialties"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_specialties_placeholder"),
    required: false,
    itemType: "eolas-select",
    isSearchable: true,
    isMultiSelect: true,
    selectOptions: specialtyOptions,
  };

  const jobTitlesField: FormItem<{ jobTitles?: JobTitleOptions[] }> = {
    fieldName: "jobTitles",
    fieldLabel: t("knowledge_sponsored_slot_add_job_titles"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_job_titles_placeholder"),
    required: false,
    itemType: "eolas-select",
    isSearchable: true,
    isMultiSelect: true,
    selectOptions: jobTitleOptions,
  };

  const senioritiesField: FormItem<{ seniorities?: SeniorityOptions[] }> = {
    fieldName: "seniorities",
    fieldLabel: t("knowledge_sponsored_slot_add_seniorities"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_seniorities_placeholder"),
    required: false,
    itemType: "eolas-select",
    isSearchable: true,
    isMultiSelect: true,
    selectOptions: seniorityOptions,
  };

  const countriesField: FormItem<{ countries?: Option[] }> = {
    fieldName: "countries",
    fieldLabel: t("knowledge_sponsored_slot_add_countries"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_countries_placeholder"),
    required: false,
    itemType: "eolas-select",
    isSearchable: true,
    isMultiSelect: true,
    selectOptions: countriesOptions,
    shouldAllowCreateInSelect: true,
  };

  const isPaidField: FormItem<{ isPaid?: IsPaidOption }> = {
    fieldName: "isPaid",
    fieldLabel: t("knowledge_sponsored_slot_isPaid"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_isPaid_placeholder"),
    required: false,
    itemType: "eolas-select",
    isSearchable: false,
    isMultiSelect: false,
    selectOptions: isPaidOptions,
  };

  const externalUrl: FormItem<{ externalUrl: string }> = {
    fieldName: "externalUrl",
    fieldLabel: t("knowledge_sponsored_slot_add_url"),
    fieldPlaceholder: t("knowledge_sponsored_slot_add_url_placeholder"),
    itemType: "input",
    required: true,
  };

  const mainFormStep: FormItem<DetailsFormValues>[] = [
    titleField,
    descriptionField,
    isPaidField,
    specialtiesField,
    jobTitlesField,
    senioritiesField,
    countriesField,
  ];

  if (!existingSection) {
    mainFormStep.unshift(slotTypeField);
  }

  const externalUrlFormStep: FormItem<ExternalUrlFormValues>[] = [externalUrl];

  return { mainFormStep, externalUrlFormStep };
};

export default useGetFormStepConfigs;
