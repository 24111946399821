import {
  BlobContent,
  ContentItem,
  LinkContent,
  contentClient,
  eolasLogger,
  isBlobContentItem,
  knowledgeContentClient,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { isFileExtensionMediaType, mapToUpdateContentItemDto } from "../helpers";
import { getBlobType, getFileName } from "Utilities/fileHelpers";
import { useState } from "react";
import { useS3FileUpload, useS3FileUploadV2 } from "Hooks";
import { AxiosProgressEvent } from "axios";
import { generateS3FileKey } from "Utilities";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { MediaUploadParams } from "../types";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";

interface UseEditContentItemProps<T, R> {
  mainSectionId: string;
  onSuccess: (params?: T) => Promise<R>;
  mediaUploadParams: MediaUploadParams;
}

const useEditContentItem = <T, R>({
  mainSectionId,
  onSuccess,
  mediaUploadParams,
}: UseEditContentItemProps<T, R>) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();
  const uploadFileV2 = useS3FileUploadV2();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;
  const { ownerType, isPublicItem = false, isOrganisationFile, ownerId } = mediaUploadParams;

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!); // set completion progress to 25%
    setUploadProgress(percentCompleted);
  };
  const editContentItem = async (
    contentItem: ContentItem,
    editItem: Partial<LinkContent | BlobContent>,
    blob?: File,
  ) => {
    let fileType;
    let key = "";
    let fileMediaId: string | undefined;
    let fileMediaName: string | undefined;
    let updatedItem: Partial<BlobContent | LinkContent> = { ...editItem };

    if (blob && isBlobContentItem(contentItem)) {
      fileType = getBlobType(blob.type);

      if (!isPublicItem) {
        if (useAppServicesEndpoints) {
          const s3Params = {
            isPublic: false,
            fileName: getFileName(blob),
            fileFormat: blob.type,
            mainSectionId,
            addExtension: true,
          };
          key = generateS3FileKey(s3Params);
          await uploadFile(key, blob, setProgress);
        } else {
          const { mediaId } = await uploadFileV2({
            file: blob,
            mainSectionId,
            onUploadProgress: setUploadProgress,
          });
          fileMediaId = mediaId;
          fileMediaName = `${getFileName(blob)}.${getBlobType(blob.type)}`;
          key = mediaId;
        }
      } else if (ownerType && ownerType === "ORGANISATION" && isFileExtensionMediaType(fileType)) {
        const { publicUrl } = await uploadFileV2({
          file: blob,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isPublic: isPublicItem,
          isOrganisationFile,
        });

        if (!publicUrl) {
          eolasLogger.error(new Error("File upload failed"));
          return;
        }

        key = publicUrl;
      } else if (ownerType && ownerType === "COMMUNITY" && isFileExtensionMediaType(fileType)) {
        const { uploadUrl, url } = await knowledgeContentClient.createPublicMedia({
          name: getFileName(blob),
          ownerId: ownerId || "",
          mediaType: fileType,
          ownerType,
        });

        if (uploadUrl) {
          await uploadFileToPresignedS3(uploadUrl, blob, setUploadProgress);
          key = url;
        }
      }

      updatedItem = {
        ...editItem,
        url: key,
        media: {
          id: "",
          type: fileType,
          access: "PUBLIC", // This will have no effect here is just for typescript
        },
        type: "BLOB",
      };
    }

    const fileMapped = await mapToUpdateContentItemDto(contentItem, updatedItem);
    /**
     * "any" needed as types incompatible, this is only for onSuccess and there is a type guard on this any
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await contentClient.updateContentItem({
      mainSectionId,
      contentId: contentItem.id,
      contentDto: { ...fileMapped, mediaId: fileMediaId, mediaName: fileMediaName },
    });

    setUploadProgress(100);
    return result;
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({
      contentItem,
      editItem,
      blob,
    }: {
      contentItem: ContentItem;
      editItem: Partial<LinkContent | BlobContent>;
      blob?: File;
    }) => editContentItem(contentItem, editItem, blob),
    {
      onSuccess: async (result) => {
        intermediateUpdateForUi({ type: "file", action: "update", file: result });
        await onSuccess();
        setUploadProgress(100);
      },
    },
  );

  return {
    editContentItem: mutate,
    editingContentItem: isLoading,
    editContentItemSuccess: isSuccess,
    editContentItemError: error instanceof Error ? error.message : "",
    editContentItemProgress: uploadProgress,
  };
};

export default useEditContentItem;
