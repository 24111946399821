import { expectNever } from "Utilities/helpers";
import { FileTileProps } from "./EolasFileTile.types";
import { AdminFileTile } from "./components/AdminFileTile";
import { BasicFileTile } from "./components/BasicFileTile";

export const EolasFileTile = (props: FileTileProps) => {
  const { variant } = props;

  if (variant === "admin") {
    return <AdminFileTile {...props} />;
  }

  if (variant === "basic") {
    return <BasicFileTile {...props} />;
  }

  expectNever(variant);
};
