import { removeLastUrlPath } from "Utilities";
import { useHistory, useRouteMatch } from "react-router-dom";

export const useCalculatorBackBehaviour = () => {
  const { length, goBack, replace } = useHistory();
  const { url } = useRouteMatch();

  const handleGoBack = () => {
    if (length > 2) {
      goBack();
      return;
    }

    replace(removeLastUrlPath(url, 2));
  };

  return {
    handleGoBack,
  };
};
