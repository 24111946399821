import { useCallback } from "react";
import {
  AnalyticsEvents,
  EolasFile,
  ShadowCopyOpenedPayload,
  isEolasFile,
  isEolasShadowCopyFile,
} from "@eolas-medical/core";
import { useSelectFile, useSelectFileModal } from "Hooks";
import { SearchResult } from "../types";
import { isKnowledgeSearchResult, isLocalSearchResult } from "../functions/typeguards";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { trackEvent } from "API/Analytics";

interface UseSelectResult {
  modal: React.ReactNode;
  onSelectResult: (result: SearchResult) => void;
  onCloseModal: () => void;
}

const useSelectResult = (): UseSelectResult => {
  const { modal, onSelectFile, onCloseModal } = useSelectFileModal({
    from: "masterSearch",
  });
  const { onSelectFile: selectFile } = useSelectFile();

  const onSelectResult = useCallback(
    (result: SearchResult) => {
      if (isLocalSearchResult(result)) {
        if (isEolasFile(result.file) && isEolasShadowCopyFile(result.file)) {
          const original = getOriginalFromShadowCopy(result.file);
          if (original) {
            trackEvent<ShadowCopyOpenedPayload>(AnalyticsEvents.SHADOW_COPY_OPENED, {
              origin: "masterSearch",
              fileId: result.file.id,
            });
            onSelectFile(original, result.file.id);
            return;
          }
        }
        onSelectFile(result.file as EolasFile);
      }

      if (isKnowledgeSearchResult(result)) {
        if (result.searchSubType === "clinicalCalculators") {
          // TODO: when merging new calculators use onSelectFile
          selectFile(result.knowledgeFile);
          return;
        }
        onSelectFile(result.knowledgeFile);
      }
    },
    [onSelectFile, selectFile],
  );

  return {
    modal,
    onSelectResult,
    onCloseModal,
  };
};

export default useSelectResult;
