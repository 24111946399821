import { WoundDetailPrice } from "@eolas-medical/core";
import { removeTags } from "modules/bnf/helpers";

export const Strings = {
  parseName: (name: string, isBlackTriangle: boolean) => {
    return `${removeTags(name)}${isBlackTriangle ? "▼" : ""}`;
  },
  name: "Name:",
  price: "Price:",
  parsePrice: (price: WoundDetailPrice[]) => {
    if (price.length === 1) {
      return price[0].nhsIndicativePrice;
    }
    return price.map(({ nhsIndicativePrice }) => nhsIndicativePrice).join(", ");
  },
  manufacturer: "Manufacturer:",
};
