import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { EditContentSectionDto } from "modules/contentRepository/types";
import { mapToNewUpdateSectionDto } from "modules/contentRepository/helpers";

const useEditKnowledgeContentSection = ({ domain }: { domain: KnowledgeContentDomain }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ id, name, iconName, description }: EditContentSectionDto) => {
      const mappedSection = mapToNewUpdateSectionDto({
        name,
        iconName,
        description,
      });
      await knowledgeContentClient.updateSection({
        sectionId: id,
        updateSectionDto: mappedSection,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(knowledgeContentKeys.contentSections(domain));
      },
    },
  );

  return {
    editContentSection: mutate,
    editingContentSection: isLoading,
    editContentSectionSuccess: isSuccess,
    editContentSectionError: error instanceof Error ? error.message : "",
  };
};

export default useEditKnowledgeContentSection;
