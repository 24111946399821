import { CopyrightAndVersion } from "Components/CopyrightAndVersion/CopyrightAndVersion";
import { overrideTailwindClasses as tw } from "tailwind-override";

interface InnerPageWrapperProps {
  children: React.ReactNode;
  className?: string;
}
export const InnerPageWrapper: React.FC<InnerPageWrapperProps> = ({
  children,
  className = "",
}: InnerPageWrapperProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div
        className={tw(`
          w-full lg:w-4/5 flex-1 max-w-6xl
          px-4 sm:px-12 md:px-20 lg:px-0 pt-12 pb-28 lg:pb-8
          flex flex-col mx-auto space-y-6 lg:space-y-12 ${className}
        `)}
      >
        {children}
      </div>
      <div className="w-full flex justify-center">
        <CopyrightAndVersion className="mt-auto pb-4" />
      </div>
    </div>
  );
};
