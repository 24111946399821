import { useState, useCallback } from "react";
import { EolasFile } from "@eolas-medical/core";
import { NameAndUrlValues } from "UIKit/Wizard";
import { EditItemValues } from "shared/pages/ContentRepository/ContentItems/components/EditContentItemModal/types";
import useEditContentItem from "modules/contentRepository/data/useEditContentItem";
import { EntityOwnerType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { MediaUploadParams } from "modules/contentRepository/types";
import { useRefetchAppData } from "Hooks";

interface EditPatientLeafletActionsProps {
  onClose(): void;
  contentItem: EolasFile;
}

export const useEditPatientLeafletActions = ({
  onClose,
  contentItem,
}: EditPatientLeafletActionsProps) => {
  const { activeTab } = useEolasNavigation();
  const ownerType =
    activeTab === "organisation" ? EntityOwnerType.ORGANISATION : EntityOwnerType.SPACE;

  const mediaUploadParams: MediaUploadParams = {
    ownerType,
    isPublicItem: true,
    isOrganisationFile: activeTab === "organisation",
  };

  const { refetch } = useRefetchAppData();

  const {
    editContentItem,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
  } = useEditContentItem({
    mainSectionId: contentItem.mainSectionID,
    onSuccess: async () => refetch(),
    mediaUploadParams,
  });
  const [wizardState, setWizardState] = useState<{ blob?: File }>({});

  const handleUpdateFileItem = useCallback(({ blob }: { blob?: File }) => {
    if (blob) {
      setWizardState((prevState) => ({
        ...prevState,
        blob,
      }));
    }
  }, []);

  const handleChangeFileItemDetails = useCallback(
    (values: EditItemValues) => {
      const mappedContentItem = mapToContentItem(contentItem);
      editContentItem(
        {
          contentItem: mappedContentItem,
          editItem: {
            name: values.name,
            keywords: values?.keywords,
            expiryDate: values.expiryDate,
          },
          blob: wizardState.blob,
        },
        {
          onSuccess: () => onClose(),
        },
      );
    },
    [contentItem, editContentItem, wizardState.blob, onClose],
  );

  const handleUpdateLinkItem = useCallback(
    (values: NameAndUrlValues) => {
      const mappedContentItem = mapToContentItem(contentItem);
      editContentItem(
        {
          contentItem: mappedContentItem,
          editItem: {
            name: values.name,
            url: values.url,
          },
        },
        {
          onSuccess: () => onClose(),
        },
      );
    },
    [editContentItem, contentItem, onClose],
  );

  return {
    wizardState,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
    editContentItemProgress,
    handleChangeFileItemDetails,
    handleUpdateFileItem,
    handleUpdateLinkItem,
  };
};
