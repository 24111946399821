import { AnchorHTMLAttributes, DetailedHTMLProps, useState } from "react";
import { useHistory } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { InnerModalWrapper, Modal, ModalBody, Text, Title } from "UIKit";
import { LINK_CLASS } from "../../../styles/stylesClasses";
import { ProcessingInstruction } from "shared/functions/makeHtmlToReactNodeParser";
import { processLink } from "./processLink";
import { useMonographContext } from "../../../contexts/ MonographTypeContext";
import { Strings } from "./CustomAnchorTag.strings";
import { bnfStore } from "../../../stores/bnf.store";
import { errorStore } from "Stores/ErrorStore";

type CustomAnchorTagProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const CustomAnchorTag = ({ children, href, target, onClick, ...rest }: CustomAnchorTagProps) => {
  const { push } = useHistory();
  const { type } = useMonographContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!href) {
    return null;
  }

  const { internalLink, isExternalLink } = processLink({ href, type });

  let handleClick = internalLink
    ? () => {
        bnfStore.setShouldIgnoreUpdate();
        push(internalLink);
      }
    : onClick;

  if (!internalLink && !isExternalLink) {
    // this means there was a problem parsing inter-BNF link:
    handleClick = () => {
      errorStore.captureError({
        error: new Error("Unrecognised internal BNF link"),
        source: "user",
        data: { href },
      });
      setIsModalOpen(true);
    };
  }
  return (
    <>
      <a
        className={twMerge(LINK_CLASS, "text-sm")}
        target={internalLink ? undefined : target}
        href={internalLink ? undefined : href}
        rel={internalLink ? undefined : "noopener noreferrer"}
        onClick={handleClick}
        {...rest}
      >
        {children}
      </a>
      <Modal
        open={isModalOpen}
        hasCloseIcon
        shouldCloseOnOverlayClick
        onClose={() => setIsModalOpen(false)}
      >
        <InnerModalWrapper>
          <ModalBody className="flex-col items-center">
            <Title level={6}>{Strings.errorTitle}</Title>
            <Text level={1} className="my-4 text-center">
              {Strings.errorDesc}
            </Text>
          </ModalBody>
        </InnerModalWrapper>
      </Modal>
    </>
  );
};

export const processAnchorTag: ProcessingInstruction["processNode"] = (node, children, index) => {
  return (
    <CustomAnchorTag key={index} {...(node.attribs ?? {})}>
      {children}
    </CustomAnchorTag>
  );
};
