import { useTranslation } from "react-i18next";

import { formatDate } from "Utilities";
import { InnerModalWrapper } from "UIKit";

const labelstyle = "font-semibold mb-1";
const textStyle = "mb-3";

export interface IncidentInfoModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incident: any;
}

export const IncidentInfoModal: React.FC<IncidentInfoModalProps> = ({
  incident,
}: IncidentInfoModalProps) => {
  const { t } = useTranslation();
  const info = JSON.parse(incident.info);

  return (
    <InnerModalWrapper>
      <div className="border-b border-grey flex flex-col p-4 lg:p-8">
        <span className="font-semibold text-lg mb-1">{incident.name}</span>

        <span className="text-grey-dark text-sm">
          {t("incidents_activated_by", { user: incident.moreInfo })}
        </span>

        <span className="text-grey-dark text-sm">
          {t("incidents_activated_on", {
            date: formatDate(new Date(incident.createdAt)),
          })}
        </span>

        {incident.subSection === "deactivated" && (
          <span className="text-grey-dark text-sm">
            {t("incidents_deactivated_on", {
              date: formatDate(new Date(incident.updatedAt)),
            })}
          </span>
        )}
      </div>

      <div className="flex flex-col items-stretch p-4 lg:px-8">
        <span className={labelstyle}>{t("incidents_add_incident_title_label")}</span>
        <span className={textStyle}>{incident.name}</span>

        {info.exactLocation && (
          <>
            <span className={labelstyle}>{t("incidents_add_incident_location_label")}</span>
            <span className={textStyle}>{t(info.exactLocation)}</span>
          </>
        )}

        {info.typeOfIncident && (
          <>
            <span className={labelstyle}>{t("incidents_add_incident_type_label")}</span>
            <span className={textStyle}>{t(info.typeOfIncident)}</span>
          </>
        )}

        {info.hazards && (
          <>
            <span className={labelstyle}>{t("incidents_add_incident_hazards_label")}</span>
            <span className={textStyle}>{t(info.hazards)}</span>
          </>
        )}

        {info.access && (
          <>
            <span className={labelstyle}>{t("incidents_add_incident_access_label")}</span>
            <span className={textStyle}>{t(info.access)}</span>
          </>
        )}

        {info.numberOfCasualties && (
          <>
            <span className={labelstyle}>{t("incidents_add_incident_casualties_label")}</span>
            <span className={textStyle}>{t(info.numberOfCasualties)}</span>
          </>
        )}

        {info.emergencyServices && (
          <>
            <span className={labelstyle}>
              {t("incidents_add_incident_emergency_services_label")}
            </span>
            <span className={textStyle}>{t(info.emergencyServices)}</span>
          </>
        )}
      </div>
    </InnerModalWrapper>
  );
};
