import React from "react";
import { FieldErrors } from "react-hook-form";
import { EolasFilePicker } from "UIKit";
import { EditFileItemValues } from "./types";

interface EditFileProps {
  fileFormLabel: string;
  fileUploadLabel: string;
  fileChangeLabel: string;
  fileDescriptionText: string;
  onBlobChange: (blob: File) => void;
  errors?: FieldErrors<EditFileItemValues>;
  blobName?: string;
  allowedFileExtensionsOverride?: string[];
}

export const EditFileStep: React.FC<EditFileProps> = ({
  onBlobChange,
  errors,
  blobName,
  fileFormLabel,
  fileUploadLabel,
  fileChangeLabel,
  fileDescriptionText,
  allowedFileExtensionsOverride,
}: EditFileProps) => {
  return (
    <>
      <EolasFilePicker
        error={errors?.blob?.message}
        onChange={onBlobChange}
        value={blobName}
        formLabel={fileFormLabel}
        uploadLabel={fileUploadLabel}
        changeLabel={fileChangeLabel}
        descriptionText={fileDescriptionText}
        allowedFileExtensionsOverride={allowedFileExtensionsOverride}
      />
    </>
  );
};

export default EditFileStep;
