import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { ContentRepositoryId } from "modules/contentRepository/types";

const useSpaceContentRepositoryParams = () => {
  const params = useParamsDecoded<{
    contentRepositoryId: ContentRepositoryId;
    subSectionIds?: string;
  }>();
  const { contentRepositoryId, subSectionIds } = params;

  const subSectionIdsArr = subSectionIds ? subSectionIds.split("/") : [];
  const subSectionsNo = subSectionIdsArr.length;
  const subSectionId = subSectionsNo > 0 ? subSectionIdsArr[subSectionsNo - 1] : "";

  return {
    contentRepositoryId: contentRepositoryId ? `SEC#${contentRepositoryId}` : contentRepositoryId,
    subSectionId: subSectionId ? `SEC#${subSectionId}` : subSectionId,
    subSectionsNo,
  };
};

export default useSpaceContentRepositoryParams;
