import { useCallback, useState, useMemo } from "react";
import {
  InnerModalWrapper,
  ModalHeader,
  Title,
  ModalBody,
  FormElement,
  Input,
  ModalWizardButtons,
  IconButton,
  IconPicker,
  FormError,
} from "UIKit";
import { ArrowLeftIcon, CheckIcon } from "Assets/Icons";
import { useForm, SubmitHandler } from "react-hook-form";
import { Icon } from "UIKit/IconPicker/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSectionValidationSchema } from "./useSectionValidationSchema";

interface Section {
  name: string;
  icon: string;
  iconUrl?: string;
}

interface ConfigureSectionModalProps {
  section?: Section;
  title?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  iconPickerTitle?: string;
  cancelLabel?: string;
  nextLabel?: string;
  doneLabel?: string;
  iconsList?: Icon[];
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit?: SubmitHandler<Section>;
  error?: string;
  validationItems?: string[];
  requiredError?: string;
  minLengthError?: string;
  existsError?: string;
}

const ConfigureSectionModal = ({
  section,
  iconsList = [],
  title,
  inputLabel = "Section Name",
  inputPlaceholder = "Please type...",
  iconPickerTitle = "Select icon",
  cancelLabel = "Cancel",
  nextLabel = "Next",
  doneLabel = "Done",
  isLoading = false,
  onCancel = () => {
    return;
  },
  onSubmit = () => {
    return;
  },
  error,
  validationItems = [],
  requiredError = "Section name is required",
  minLengthError = "Section Name must be at least 3 characters",
  existsError = "Section with this name already exists",
}: ConfigureSectionModalProps) => {
  const sectionValidationSchema = useSectionValidationSchema(
    validationItems,
    requiredError,
    minLengthError,
    existsError,
  );

  const [isAddingIcon, setIsAddingIcon] = useState<boolean>(false);
  const [selectedIconUrl, setSelectedIconUrl] = useState<string | undefined>(section?.iconUrl);
  const [selectedIconFileName, setSelectedIconFileName] = useState<string | undefined>(
    section?.icon,
  );
  const { control, formState, handleSubmit, getValues, register } = useForm({
    defaultValues: {
      name: section?.name,
    },
    resolver: yupResolver(sectionValidationSchema),
  });

  const { isDirty } = formState;

  const isSubmittable = useMemo(() => {
    if (section && (isDirty || section.icon !== selectedIconFileName)) {
      return true;
    }
    return isDirty && !!selectedIconFileName;
  }, [section, isDirty, selectedIconFileName]);

  const nextButtonLabel = useMemo(() => {
    if (section) return doneLabel;

    return isSubmittable ? doneLabel : nextLabel;
  }, [section, isSubmittable, doneLabel, nextLabel]);

  const handleClickNext = useCallback(() => {
    if (!selectedIconFileName) {
      setIsAddingIcon(true);
      return;
    }

    const values = getValues();
    if (values.name && selectedIconFileName) {
      handleSubmit(onSubmit({ name: values.name, icon: selectedIconFileName }));
    }
  }, [getValues, selectedIconFileName, onSubmit, handleSubmit]);

  const handleClickEnter = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" && isDirty) {
        handleClickNext();
      }
    },
    [handleClickNext, isDirty],
  );

  if (isAddingIcon) {
    return (
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{iconPickerTitle}</Title>
          <IconButton
            className="mt-4"
            icon={<ArrowLeftIcon />}
            onClick={() => setIsAddingIcon(false)}
          />
        </ModalHeader>

        <ModalBody>
          <IconPicker
            iconsList={iconsList}
            defaultShowIcons
            iconURL={selectedIconUrl}
            iconContainerClassname={`
            h-70vh sm:h-60vh p-2
          `}
            onClick={(icon) => {
              setSelectedIconUrl(icon.url);
              setSelectedIconFileName(icon.fileName);
              setIsAddingIcon(false);
            }}
          />
        </ModalBody>
      </InnerModalWrapper>
    );
  }

  return (
    <div data-testid="configure-section-modal">
      <InnerModalWrapper>
        {title && (
          <ModalHeader>
            <Title level={5} className="font-bold">
              {title}
            </Title>
          </ModalHeader>
        )}

        <ModalBody>
          <FormElement required id="name" control={control} label={inputLabel}>
            {(fieldProps, { isValid }) => {
              const { value, ...rest } = fieldProps;
              return (
                <Input
                  {...rest}
                  value={value || ""}
                  size="lg"
                  disabled={isLoading}
                  data-testid="configure-section-modal-input"
                  placeholder={inputPlaceholder}
                  iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
                  onKeyPress={handleClickEnter}
                  {...register("name")}
                />
              );
            }}
          </FormElement>
          {selectedIconUrl && (
            <IconPicker
              iconURL={selectedIconUrl}
              disabled={isLoading}
              onClick={() => {
                return;
              }}
              iconContainerClassname="h-50vh"
              onOpen={() => setIsAddingIcon(true)}
              iconListClassname="pr-4 sm:pr-12 pb-4"
              label={iconPickerTitle}
            />
          )}
          <ModalWizardButtons
            nextDisabled={!isSubmittable && !isDirty}
            onBack={onCancel}
            onNext={handleSubmit(handleClickNext)}
            isLoading={isLoading}
            backLabel={cancelLabel}
            nextLabel={nextButtonLabel}
          />
          <FormError error={error} />
        </ModalBody>
      </InnerModalWrapper>
    </div>
  );
};

export default ConfigureSectionModal;
