import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { AddEolasEditorItemDto } from "modules/contentRepository/types";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { mapToAddEolasEditorDto } from "modules/contentRepository/helpers";

interface UseAddContentItemPublicProps {
  mainSectionId: string | null;
  parentId: string | null;
  domain: KnowledgeContentDomain;
}

const useAddKnowledgeContentEolasEditorItem = ({
  mainSectionId,
  parentId,
  domain,
}: UseAddContentItemPublicProps) => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleAddEolasEditorItem = async ({
    name,
    description,
    eolasEditorContent,
    createdBy,
    expiryDate,
    isDraft,
  }: AddEolasEditorItemDto) => {
    if (!mainSectionId || !parentId) {
      throw new Error("MainSectionId or parentId missing");
    }
    const eolasEditorItemDto = mapToAddEolasEditorDto({
      createdBy,
      name,
      expiryDate,
      description,
      eolasEditorContent,
      isDraft,
      parentId,
      mainSectionId,
      isKnowledge: true,
    });

    await knowledgeContentClient.createEolasEditorItem(eolasEditorItemDto);

    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (contentDto: AddEolasEditorItemDto) => handleAddEolasEditorItem(contentDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(knowledgeContentKeys.items(parentId ?? "", domain));
        setUploadProgress(100);
      },
    },
  );

  return {
    addEolasEditorItem: mutate,
    addingEolasEditorItem: isLoading,
    addEolasEditorItemSuccess: isSuccess,
    addEolasEditorItemError: error instanceof Error ? error.message : "",
    addEolasEditorItemProgress: uploadProgress,
  };
};

export default useAddKnowledgeContentEolasEditorItem;
