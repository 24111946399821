import { Text } from "UIKit";
import { SearchMonoIcon } from "Assets";

interface SuggestedSearchesProps {
  suggestedSearch: string[];
  onClick: (str: string) => void;
  title: string;
}

const SuggestedSearches = ({ suggestedSearch, onClick, title }: SuggestedSearchesProps) => {
  return (
    <div
      data-testid="master-search-recent-search-list"
      className="flex flex-col justify-center items-center"
    >
      {suggestedSearch?.length > 0 ? (
        <>
          <Text level={1} className="text-grey-600">
            {title}
          </Text>
          <div className="w-full">
            {suggestedSearch?.map((suggestion: string) => (
              <div
                key={suggestion}
                className="self-start w-full rounded-md shadow-md p-4 cursor-pointer border-2 border-transparent hover:border-blue-100 border-grey-300"
                onClick={() => onClick(suggestion)}
              >
                <div className="flex space-x-2">
                  <SearchMonoIcon className="w-5 h-5 text-grey-600 self-center my-auto" />
                  <span className="text-sm lg:text-base">{suggestion}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SuggestedSearches;
