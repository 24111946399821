import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DraftFile, EolasFile } from "@eolas-medical/core";
import { VideoPlayer } from "Components";
import { Button, Modal, NavButton } from "UIKit";
import { BlobTypes } from "modules/generic.types";
import { useFileDownload } from "shared/hooks/useFileDownload";
import useFileViewer from "../../hooks/useFileViewer";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { getPreSignUrl } from "Utilities/helpers";
import { isEolasDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/typeguards";

interface VideoViewerProps {
  video: EolasFile | DraftFile;
  backBehaviour?: "goBack" | "goHome";
  versionNo?: number;
}
const VideoViewer = ({ video, backBehaviour = "goBack", versionNo }: VideoViewerProps) => {
  const { t } = useTranslation();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  const { file, setFile, onFileLoaded, onFileError } = useFileViewer();

  const getFile = useCallback(
    async (key: string) => {
      let videoUrl = key;
      if (!key.startsWith("https:")) {
        // Public videos don't need presigned url
        let fileId: string;
        let draftId: string | undefined = undefined;
        if (isEolasDraftFile(video)) {
          fileId = video.fileId;
          draftId = video.id;
        } else {
          fileId = video.id;
        }
        videoUrl = await getPreSignUrl({ fileId, versionNo, draftId });
      }
      return {
        fileName: video.name || "",
        fileUrl: videoUrl,
        fileExt: video.type || "mp4",
      };
    },
    [video, versionNo],
  );

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const handleDownloadFile = () => {
    downloadFile(video.id, {
      onSuccess: () => {
        return;
      },
    });
  };

  const fetchFileUrl = useCallback(async () => {
    const { fileUrl } = await getFile(video.key || "");
    setFile({
      id: video.id,
      name: video.name || "",
      type: video.type as BlobTypes,
      url: fileUrl,
    });
  }, [setFile, video, getFile]);

  useEffect(() => {
    fetchFileUrl();
  }, [fetchFileUrl]);

  return (
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={handleGoBack}>
      <div className="h-100vh w-100vw">
        <div className="bg-grey-light py-1">
          <div className="flex justify-center space-x-3">
            <NavButton onClick={handleGoBack} />
            <Button size="sm" onClick={handleDownloadFile}>
              {t("general_download_original")}
            </Button>
          </div>
        </div>
        <div className="bg-grey-light overflow-auto h-full">
          <div className="p-4">
            <VideoPlayer
              url={file?.url || ""}
              title={video.name || ""}
              onLoad={onFileLoaded}
              onError={onFileError}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoViewer;
