import { IconButton, Title, Text, FileTypeItemIcon } from "UIKit";
import { EnrichedFileVersionWithDayAndTime } from "../types";
import { ArrowLeftIcon, EyeIcon } from "Assets";
import { format } from "date-fns";
import {
  ContentItem,
  isFlashCardContentItem,
  isLinkContentItem,
  isSupportedFileTypeWebOnly,
} from "@eolas-medical/core";
import { EolasCard } from "UIKit/EolasCard/EolasCard";
import { useTranslation } from "react-i18next";
import { EolasTag } from "UIKit/EolasTag/EolasTag";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { isValidDate } from "Utilities/helpers";

export const VersionDetails = ({
  version,
  onClickBack,
  handleClickVersionPreview,
}: {
  version: EnrichedFileVersionWithDayAndTime;
  onClickBack: () => void;
  handleClickVersionPreview: (item: ContentItem, versionNo: number) => void;
}) => {
  const {
    day,
    time,
    name,
    type,
    versionNo,
    expiryDate,
    lastUpdatedBy,
    key,
    description,
    keywords,
    summaryOfChanges,
    versionNoString,
  } = version;
  const contentItem = mapToContentItem({ ...version, id: version.fileId });

  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-full px-1">
      <div className="flex mb-4">
        <IconButton
          icon={<ArrowLeftIcon />}
          color="white"
          onClick={onClickBack}
          className="border absolute"
          variant="square"
        />
        <Title level={5} className="mx-auto">
          {t("version_history_version", { versionNo: versionNoString })}
        </Title>
      </div>
      <div className="flex justify-center">
        <Text level={1} className="text-grey-800 font-bold">
          {t("version_history_updated_on", { day })}
        </Text>
        <Text level={1} className="text-grey-500 font-bold">
          {" / "}
        </Text>
        <Text level={1} className="text-grey-800 font-bold">
          {time}
        </Text>
      </div>
      <Text level={1} className="text-grey-800 font-bold mb-8 flex justify-center">
        {t("version_history_by", { userFullName: lastUpdatedBy.fullName })}
      </Text>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <Title level={8}>
            {isFlashCardContentItem(contentItem)
              ? t("flashcards_title_label")
              : t("version_history_file_name")}
          </Title>
          <Text level={1}>{name}</Text>
        </div>
        {isFlashCardContentItem(contentItem) ? (
          <div className="flex flex-col">
            <Title level={8}>{t("flashcards_description_label")}</Title>
            <Text level={1}>{description}</Text>
          </div>
        ) : null}
        {expiryDate && isValidDate(expiryDate) ? (
          <div className="flex flex-col">
            <Title level={8}>{t("version_history_expiry_date")}</Title>
            <Text level={1}>{format(new Date(expiryDate), "dd/MM/yyyy")}</Text>
          </div>
        ) : null}
        <div className="flex flex-col">
          <Title level={8}>
            {isLinkContentItem(contentItem)
              ? t("filePicker_url_label")
              : t("version_history_preview")}
          </Title>
          <EolasCard
            borderStyle="rounded-sm border-grey-300"
            onClick={() => handleClickVersionPreview(contentItem, versionNo)}
          >
            <div className="p-3 flex items-center gap-2">
              <FileTypeItemIcon
                type={isSupportedFileTypeWebOnly(type) ? type : undefined}
                className="w-8 h-8"
              />
              <Text level={1} className="flex-1">
                {isLinkContentItem(contentItem) ? key : name}
              </Text>
              <EyeIcon width={20} height={20} className="text-grey-500" />
            </div>
          </EolasCard>
        </div>
        {summaryOfChanges ? (
          <div className="flex flex-col">
            <Title level={8}>{t("repository_edit_item_summary_of_changes")}</Title>
            <Text level={1} className="break-words">
              {summaryOfChanges}
            </Text>
          </div>
        ) : null}
        {keywords.length ? (
          <div className="flex flex-col gap-2">
            <Title level={8}>{t("guidelines_addNew_keywords")}</Title>
            <div className="flex gap-2 flex-wrap">
              {keywords.map((k, index) => (
                <EolasTag color="grey" text={k} key={index} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
