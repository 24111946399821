import React from "react";

export type MediaQueryBreakpoints = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

const mediaQueryLookup: { size: MediaQueryBreakpoints; query: string }[] = [
  { size: "xs", query: "(max-width: 639.98px)" },
  { size: "sm", query: "(min-width: 640px)" },
  { size: "md", query: "(min-width: 768px)" },
  { size: "lg", query: "(min-width: 1024px)" },
  { size: "xl", query: "(min-width: 1280px)" },
  { size: "2xl", query: "(min-width: 1536px)" },
];

export const useMediaQuery = () => {
  const [mediaBreakpoint, setMediaBreakpoint] = React.useState<MediaQueryBreakpoints | null>(null);

  React.useLayoutEffect(() => {
    const mediaArr: {
      mediaQueryList: MediaQueryList;
      handler: (e: MediaQueryListEvent) => void;
    }[] = [];

    mediaQueryLookup.forEach(({ size, query }, index, arr) => {
      const handler = ({ matches }: MediaQueryListEvent) => {
        if (matches) {
          setMediaBreakpoint(size);
        } else {
          setMediaBreakpoint(index === 0 ? null : arr[index - 1].size);
        }
      };
      const mediaQueryList = window.matchMedia(query);
      if (mediaQueryList.matches) {
        setMediaBreakpoint(size);
      }
      mediaQueryList.addEventListener("change", handler);
      mediaArr.push({ mediaQueryList, handler });
    });

    return () => {
      mediaArr.forEach(({ mediaQueryList, handler }) => {
        mediaQueryList.removeEventListener("change", handler);
      });
    };
  }, []);

  return mediaBreakpoint;
};
