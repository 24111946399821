import { useTranslation } from "react-i18next";

import { errorStore } from "Stores/ErrorStore";
import { SuccessModal, DeleteModal } from "UIKit";
import { useDeleteAppUser } from "Hooks/usersService";
import { SpaceUser } from "clients/departments/types";

interface DeactivateUserModalProps {
  appUser: SpaceUser;
  onCloseModal(): void;
}

export const DeactivateUserModal: React.FC<DeactivateUserModalProps> = ({
  appUser,
  onCloseModal,
}: DeactivateUserModalProps) => {
  const { t } = useTranslation();

  const { error, isLoading, isSuccess, onDeleteAppUser } = useDeleteAppUser();

  const onDelete = () => {
    onDeleteAppUser(
      { departmentId: appUser.appID, userId: appUser.userID },
      {
        onError: (error: Error) => {
          errorStore.captureError({ error: error.message, source: "user" });
        },
      },
    );
  };

  if (isSuccess) {
    return <SuccessModal text="manageUsers_deactivate_success" onComplete={onCloseModal} />;
  }

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      onCloseModal={() => {
        return;
      }}
      iconType="RemoveUserIcon"
      title={t("manageUsers_deactivate_user_title")}
      description={t("manageUsers_deactivate_user_description")}
    />
  );
};
