import { useMutation } from "@tanstack/react-query";
import { contentClient } from "@eolas-medical/core";

const useSendMainSectionReminder = () => {
  const { mutate, isLoading, isSuccess, reset, error } = useMutation(
    async ({ mainSectionId }: { mainSectionId: string }) => {
      return contentClient.sendMainSectionReminder({ mainSectionId });
    },
  );

  return {
    sendMainSectionReminder: mutate,
    sendingMainSectionReminder: isLoading,
    sendMainSectionReminderSuccess: isSuccess,
    resetMainSectionReminder: reset,
    errorSendingMainSectionReminder: error,
  };
};

export default useSendMainSectionReminder;
