import { useQuery } from "@tanstack/react-query";
import { KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import { mapToContentSection } from "modules/contentRepository/helpers";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

const useKnowledgeContentSection = ({
  domain,
  subSectionId,
}: {
  domain: KnowledgeContentDomain;
  subSectionId: string | null;
}) => {
  const { data, isInitialLoading } = useQuery({
    queryKey: knowledgeContentKeys.contentSection(subSectionId ?? "", domain),
    queryFn: () => knowledgeContentClient.getSectionById({ sectionId: subSectionId ?? "" }),
    enabled: Boolean(subSectionId),
  });

  return {
    contentSection: data ? mapToContentSection(data) : undefined,
    contentSectionLoading: isInitialLoading,
  };
};

export default useKnowledgeContentSection;
