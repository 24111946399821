import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "Assets";
import { Button, IconButton } from "UIKit/Button";
import CarouselComponent, {
  ArrowProps,
  ButtonGroupProps,
  CarouselInternalState,
} from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel.styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface CarouselProps {
  children: React.ReactNode[];
  isSwipeable?: boolean;
  partialVisible?: boolean;
  showArrowButtons?: boolean;
  showNextButtons?: boolean;
  centerMode?: boolean;
  itemClass?: string;
  showDots?: boolean;
  nextButtonText?: string;
  backButtonText?: string;
  onComplete?: () => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  isSwipeable,
  partialVisible = false,
  showNextButtons = false,
  showArrowButtons = true,
  centerMode = true,
  itemClass,
  showDots = false,
  nextButtonText = "Next",
  backButtonText = "Previous",
  onComplete,
}: CarouselProps) => {
  const RightArrowButton = ({ onClick }: ArrowProps) => (
    <IconButton
      icon={<ArrowRightIcon className="w-8 h-8" />}
      variant="rounded"
      size="lg"
      color="blue"
      className="absolute right-8"
      onClick={() => {
        onClick && onClick();
      }}
    />
  );

  const LeftArrowArrow = ({ onClick }: ArrowProps) => (
    <IconButton
      icon={<ArrowLeftIcon className="w-8 h-8" />}
      variant="rounded"
      size="lg"
      color="blue"
      className="absolute left-8"
      onClick={() => {
        onClick && onClick();
      }}
    />
  );

  const handleNextButtonClick = (
    next: () => void,
    carouselState: CarouselInternalState | undefined,
  ) => {
    if (!carouselState) return;

    const { currentSlide, totalItems } = carouselState;

    const totalSteps = Math.floor(totalItems / 2);

    if (currentSlide === totalSteps + 1) {
      onComplete ? onComplete() : next();
    } else {
      next();
    }
  };

  const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
    const currentSlide = carouselState?.currentSlide ?? 0;

    const isFirstSlide = currentSlide / 2 === 1;

    return showNextButtons ? (
      <div className="mb-4">
        <div className="flex justify-center items-center space-x-3 my-4">
          <Button
            variant="outline"
            size="sm"
            disabled={isFirstSlide}
            className={`${isFirstSlide ? "text-grey" : "text-black"} w-1/3 md:w-1/6`}
            color="grey"
            iconLeft={isFirstSlide ? null : "CaretLeftIcon"}
            onClick={() => previous && previous()}
          >
            {backButtonText}
          </Button>
          <Button
            className="w-1/3 md:w-1/6"
            onClick={() => next && handleNextButtonClick(next, carouselState)}
            iconRight={"CaretRight"}
            size="sm"
          >
            {nextButtonText}
          </Button>
        </div>
      </div>
    ) : null;
  };

  return (
    <CarouselComponent
      arrows={showArrowButtons}
      showDots={showDots}
      responsive={responsive}
      swipeable={isSwipeable}
      draggable={false}
      infinite={true}
      customTransition="all .1"
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      centerMode={centerMode}
      itemClass={itemClass}
      partialVisible={partialVisible}
      renderButtonGroupOutside
      dotListClass="carousel-dot-list-style"
      customRightArrow={<RightArrowButton />}
      customLeftArrow={<LeftArrowArrow />}
      customButtonGroup={<ButtonGroup />}
    >
      {children}
    </CarouselComponent>
  );
};
