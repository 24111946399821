/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";

import { AdminIcon } from "Assets";
import { Button, ButtonProps, Text } from "UIKit";

import { User } from "API";
import { UpdateAccessLevelParams } from "./useAdminEntry";

interface AdminEntryProps {
  user: User;
  onUpdateUser: (params: UpdateAccessLevelParams) => void;
  onManageSections: () => void;
  isAdmin?: boolean;
  isLoading?: boolean;
  isLimitedAccess?: boolean;
  showButton?: boolean;
  buttonText: React.ReactNode;
  buttonColor?: ButtonProps["color"];
}

export const AdminEntry: React.FC<AdminEntryProps> = ({
  user,
  isAdmin,
  isLoading,
  isLimitedAccess,
  buttonText,
  onUpdateUser,
  onManageSections,
  showButton = true,
}: AdminEntryProps) => {
  const { t } = useTranslation();
  const { family_name = "", given_name = "" } = user!;

  return (
    <div className="flex items-center p-2 border border-grey-300 rounded-2xl">
      {isAdmin && <AdminIcon className="w-6 h-6 mr-4" />}

      <div className="flex items-center justify-center gap-4 flex-1">
        <div>
          <Text level={1} className="line-clamp-1">{`${given_name} ${family_name}`}</Text>
        </div>
        <div className="flex flex-1 justify-end">
          {isLimitedAccess && (
            <Button
              size="sm"
              variant="outline"
              disabled={isLoading}
              color={"blue"}
              className={"rounded-full mr-2"}
              iconLeft={"AppAddIcon"}
              onClick={onManageSections}
            >
              <span className="hidden md:block">
                {t("manageUsers_accessRequest_manage_sections")}
              </span>
            </Button>
          )}
          <Button
            size="sm"
            variant="ghost"
            color={showButton ? "red" : "grey"}
            isLoading={isLoading}
            disabled={!showButton}
            className={"rounded-full"}
            iconLeft={"DeleteBinIcon"}
            onClick={() => {
              onUpdateUser({
                userId: user.id,
                accessLevel: isAdmin ? "User" : "admin",
              });
            }}
          >
            <span className="hidden md:block">
              {showButton ? buttonText : t("manageUsers_accessRequest_removeSelf")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
