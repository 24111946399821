import { useTranslation } from "react-i18next";
import React, { useState, useCallback } from "react";

import { DragIcon, DeleteIcon, ChevronIcon } from "Assets";
import { Modal, Input, Text, FormLabel, Title, IconButton, FormFieldError } from "UIKit";

import { ChecklistSection } from "../types";
import { useBuilderSectionItem } from "../hooks";
import { BuilderSectionItemList } from "./BuilderSectionItemList";

export interface BuilderSectionItemProps {
  index: number;
  section: ChecklistSection & { id: string };
  onDelete(): void;
}

export const BuilderSectionItem: React.FC<BuilderSectionItemProps> = ({
  index,
  section,
  onDelete,
}: BuilderSectionItemProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const {
    expanded,
    checklistItems,
    sectionItemError,
    missingSectionError,
    onSwap,
    register,
    onAddItem,
    onEditItem,
    onDeleteItem,
    toggleExpanded,
  } = useBuilderSectionItem(index);

  const onCloseModal = useCallback(() => setModal(null), [setModal]);

  return (
    <>
      <div
        className={`
          flex flex-col rounded-md bg-white relative
          ${expanded ? "p-4 sm:p-10" : "pl-2 sm:pl-6 pr-8 sm:pr-12 py-2 sm:py-4"}
          ${sectionItemError || missingSectionError ? "input-error" : "tile-border"}
        `}
      >
        <ChevronIcon
          onClick={toggleExpanded}
          className={`
            absolute top-3 sm:top-6 right-2 sm:right-4 w-6 h-6 text-quartz-200 transform transition-all duration-300
            ${expanded ? "rotate-180" : "rotate-0"}
          `}
        />

        {!expanded && (
          <div
            onClick={toggleExpanded}
            className={`
              flex items-center 
            `}
          >
            <DragIcon className="h-4 w-4 sm:w-6 sm:h-6 text-quartz-400 mr-2 sm:mr-6" />
            <Title level={7} className="flex-1 line-clamp-1">
              {section.checklistSectionName}
            </Title>

            <Text level={3} className="text-grey-600 mr-2 sm:mr-6 uppercase">
              {t("checklistBuilder_number_of_items", { amount: section.sectionItems.length })}
            </Text>

            <IconButton
              type="button"
              className="mr-2 sm:mr-4"
              icon={<DeleteIcon />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
            />
          </div>
        )}

        {expanded && (
          <>
            <div
              onClick={toggleExpanded}
              className="h-12 bg-transparent absolute top-0 right-0 left-0"
            />

            <div className="flex flex-col space-y-6">
              <div className="flex flex-col space-y-2">
                <FormLabel required htmlFor={`${section.id}-input`}>
                  {t("checklistBuilder_section_name_label")}
                </FormLabel>
                <Input
                  size="lg"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  id={`${section.id}-input`}
                  defaultValue={section.checklistSectionName}
                  className={`
                    ${sectionItemError ? "input-error" : "input"}
                    bg-grey-50 hover:bg-white
                  `}
                  placeholder={t("checklistBuilder_section_name_placeholder")}
                  {...register(`checklistSections.${index}.checklistSectionName` as const)}
                />
                <FormFieldError className="mt-2">{sectionItemError}</FormFieldError>
              </div>

              <BuilderSectionItemList
                onSwap={onSwap}
                onAddItem={onAddItem}
                onEditItem={onEditItem}
                onDeleteItem={onDeleteItem}
                sectionItems={checklistItems}
                missingSectionError={missingSectionError}
              />
            </div>
          </>
        )}
      </div>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
