import { useHistory } from "react-router-dom";
import { KnowledgeLinkedDocumentsError } from "./components/KnowledgeLinkedDocumentsError/KnowledgeLinkedDocumentsError";
import { useEffect, useRef } from "react";
import { useLinkedKnowledgeDocumentsViewer } from "./hooks/useLinkedKnowledgeDocumentsViewer";
import { KnowledgeLinkedDocumentsNewTab } from "./components/KnowledgeLinkedDocumentsNewTab/KnowledgeLinkedDocumentsNewTab";
import {
  DeeplinkResolvedPayload,
  AnalyticsEvents,
  NationalResourcesClickPayload,
  CommunityLevelSection,
  DrugResourcesContentSelectedPayload,
  isLinkContentItem,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { isContentItem, isEolasFile } from "modules/contentRepository/typeguards";
import { isClinicalResourceMainSectionType } from "deeplinking/functions/typeguards";
import { mainSectionTypeToNatResourceEntityTypeMapper } from "deeplinking/functions/helpers";
import { isDev } from "Utilities/helpers";
import { LinkedDocumentsLoader } from "./components/KnowledgeLinkedDocumentsNewTab/LinkedDocumentsLoader";

export const LinkedKnowledgeDocumentsViewer = (): JSX.Element | null => {
  const {
    isLoading,
    hasError,
    error,
    redirectLocation,
    newTabLocation,
    resolvedDeeplinkPayload,
    item,
  } = useLinkedKnowledgeDocumentsViewer();
  const history = useHistory();
  const didFireAnalyticsEvent = useRef(false);

  useEffect(() => {
    if (didFireAnalyticsEvent.current === false && resolvedDeeplinkPayload) {
      didFireAnalyticsEvent.current = true;
      trackEvent<DeeplinkResolvedPayload>(
        AnalyticsEvents.DEEPLINK_RESOLVED,
        resolvedDeeplinkPayload,
      );
    }

    if (redirectLocation) {
      history.replace(redirectLocation);
      return;
    }
  }, [redirectLocation, history, newTabLocation, resolvedDeeplinkPayload]);

  const handleOpenNewTab = (location: string) => {
    const mainSectionType = resolvedDeeplinkPayload?.mainSectionType;

    if (mainSectionType) {
      if (
        isEolasFile(item) &&
        isClinicalResourceMainSectionType(resolvedDeeplinkPayload.mainSectionType)
      ) {
        trackEvent<NationalResourcesClickPayload>(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
          nationalResourceType: mainSectionTypeToNatResourceEntityTypeMapper(
            resolvedDeeplinkPayload.mainSectionType,
          ),
          resourceName: item.name,
        });
      }

      if (isContentItem(item)) {
        // it will always be a link content item in this function
        if (
          mainSectionType === CommunityLevelSection.communityMedications &&
          isLinkContentItem(item)
        ) {
          trackEvent<DrugResourcesContentSelectedPayload>(
            AnalyticsEvents.DRUG_RESOURCES_CONTENT_VIEWED,
            {
              name: item.name,
              id: item.id,
              type: item.type,
              urlTarget: "BROWSER",
            },
          );
        } else {
          if (isDev()) {
            // For future content items that are not within Medications
            console.warn("No analytics event was fired for this content item link.");
          }
        }
      }
    }

    window.open(location, "_blank");
  };

  if (isLoading) {
    return <LinkedDocumentsLoader />;
  }

  if (hasError && error) {
    return <KnowledgeLinkedDocumentsError errorType={error} />;
  }

  if (newTabLocation) {
    return (
      <KnowledgeLinkedDocumentsNewTab onOpenDocument={() => handleOpenNewTab(newTabLocation)} />
    );
  }

  return null;
};
