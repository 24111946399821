import { useSummaryOfChangesStepValidationSchema } from "UIKit/Wizard/templates/SummaryOfChangesStep/useSummaryOfChangesValidationSchema";
import { WizardState } from "../../../types";
import { useForm } from "react-hook-form";
import { SummaryOfChangesValues } from "UIKit/Wizard/templates/SummaryOfChangesStep/types";
import { yupResolver } from "@hookform/resolvers/yup";

export const useSummaryOfChanges = (state: WizardState) => {
  const validationSchema = useSummaryOfChangesStepValidationSchema();

  let existingSummaryOfChanges: string | undefined = undefined;

  if (state.isExistingFileWithNewType) {
    existingSummaryOfChanges = state.dto.newProperties.summaryOfChanges;
  } else if (state.fileId) {
    existingSummaryOfChanges = state.dto.summaryOfChanges;
  }

  const { handleSubmit, control } = useForm<SummaryOfChangesValues>({
    defaultValues: {
      summaryOfChanges: existingSummaryOfChanges ?? "",
    },
    resolver: yupResolver(validationSchema),
  });

  return { handleSubmit, control };
};
