import { useState, useCallback } from "react";

export const useEolasVersatileListSelection = <T extends object>(
  initialSelection: Record<string, T> = {},
  selectionMode: "single" | "multi" = "multi",
  onSelectionChange?: (selection: Record<string, T>) => void,
) => {
  const [selection, setSelection] = useState(initialSelection);

  const handleAddToSelection = useCallback(
    (id: string, item: T) => {
      if (selectionMode === "single") {
        setSelection({ [id]: item });
        onSelectionChange?.({ [id]: item });
        return;
      }

      onSelectionChange?.({ ...selection, [id]: item });
      setSelection((prevSelection) => ({
        ...prevSelection,
        [id]: item,
      }));
    },
    [selectionMode, onSelectionChange, selection],
  );

  const handleSelectAll = (items: Record<string, T>) => {
    setSelection(items);
  };

  const handleRemoveFromSelection = useCallback(
    (id: string) => {
      if (selectionMode === "single") {
        setSelection({});
        onSelectionChange?.({});
        return;
      }

      const { [id]: _, ...rest } = selection;
      onSelectionChange?.(rest);
      setSelection((prevSelection) => {
        const { [id]: _, ...rest } = prevSelection;
        return rest;
      });
    },
    [selectionMode, onSelectionChange, selection],
  );

  const handleClearSelection = () => {
    onSelectionChange?.({});
    setSelection({});
  };

  return {
    selection,
    handleAddToSelection,
    handleRemoveFromSelection,
    handleClearSelection,
    handleSelectAll,
  };
};
