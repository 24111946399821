import { useCallback, useEffect, useRef } from "react";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { trackEvent } from "API/Analytics";
import { Notification, useNotifications } from "Components/Notifications";

const useBlobViewer = () => {
  const { t } = useTranslation();
  const notificationRef = useRef<Notification>();

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onFileError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_error"),
    });
  }, [t, updateNotification]);

  const onFileLoaded = useCallback(() => {
    hideNotification(notificationRef.current!.id);
  }, [hideNotification]);

  const onViewerLoadError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_load_error"),
    });
  }, [t, updateNotification]);

  useEffect(() => {
    notificationRef.current = showNotification({
      type: "loading",
      description: t("fileViewer_opening_pdf"),
    });
    trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);

    return () => {
      hideNotification(notificationRef.current!.id);
    };
  }, [t, showNotification, hideNotification]);

  return { onFileError, onFileLoaded, onViewerLoadError };
};

export default useBlobViewer;
