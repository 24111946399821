import { useTranslation } from "react-i18next";

interface PostSpaceCreationStepItemProps {
  backgroundImg: string;
  animation: React.ReactNode;
  title?: {
    text: string;
  };
  content: React.ReactNode;
  pill: React.ReactNode;
}

const PostSpaceCreationStepItem = ({
  backgroundImg,
  title,
  content,
  animation,
  pill,
}: PostSpaceCreationStepItemProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 relative">
        <img
          src={backgroundImg}
          alt=""
          className="absolute inset-0 w-full h-full object-cover"
          style={{
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
          }}
        />
        {animation}
      </div>
      <div className="flex-1 flex flex-col justify-center items-center p-2 overflow-auto mb-4">
        {pill}
        <div className="text-center font-bold text-lg lg:text-xl mt-3">{t(title?.text)}</div>
        <div className="text-md lg:text-lg font-semibold">{content}</div>
      </div>
    </div>
  );
};

export default PostSpaceCreationStepItem;
