import { useMemo } from "react";
import { addMonths } from "date-fns";
import { BlobContent, LinkContent, isFlashCardContentItem } from "@eolas-medical/core";
import { ContentRepositoryId } from "../types";
import { NO_EXPIRY_DATE } from "Utilities/types";
import { ContentType, useGetAllContentRepository } from "shared/hooks/useGetAllContentRepository";

export type ExpiringPeriod = 1 | 3 | 6 | 12;

interface UseExpiringContentItemsProps {
  contentRepositoryId: ContentRepositoryId;
  expiringPeriod: ExpiringPeriod;
}

interface UseExpiringContentItems {
  expiredItems: (LinkContent | BlobContent)[];
  itemsExpiringSoon: (LinkContent | BlobContent)[];
}

const useExpiringContentItems = ({
  contentRepositoryId,
  expiringPeriod,
}: UseExpiringContentItemsProps): UseExpiringContentItems => {
  const { finalItemList } =
    useGetAllContentRepository({
      contentRepositoryId,
      contentType: ContentType.Item,
    }) ?? [];

  const expiredItems: (LinkContent | BlobContent)[] = useMemo(() => {
    const today = new Date();
    return finalItemList.filter((file) => {
      if (isFlashCardContentItem(file)) return false;
      if (!file.expiryDate) return false;
      return new Date(file.expiryDate).valueOf() < today.valueOf();
    }) as (LinkContent | BlobContent)[];
  }, [finalItemList]);

  const itemsExpiringSoon: (LinkContent | BlobContent)[] = useMemo(() => {
    const today = new Date();
    const expiresIn = addMonths(new Date(), expiringPeriod);

    return finalItemList.filter((file) => {
      if (isFlashCardContentItem(file)) return false;
      if (!file.expiryDate || file.expiryDate === NO_EXPIRY_DATE) return false;
      const fileExpiryDate = new Date(file.expiryDate);

      return (
        fileExpiryDate.valueOf() >= today.valueOf() &&
        fileExpiryDate.valueOf() < expiresIn.valueOf()
      );
    }) as (LinkContent | BlobContent)[];
  }, [finalItemList, expiringPeriod]);

  return {
    expiredItems,
    itemsExpiringSoon,
  };
};

export default useExpiringContentItems;
