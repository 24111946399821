import { objectToArray } from "Utilities/helpers";
import { MixedMedicationSubsection } from "./data/useMedicationSections";
import { MedicationSubSectionWithId } from "modules/bnf/data/useBnfSections";
import {
  CommunityMedicationsSubsection,
  communityMedicationsSubsections,
  hasProp,
} from "@eolas-medical/core";

export const isMedicationSubsection = (
  section: MixedMedicationSubsection,
): section is MedicationSubSectionWithId => {
  return objectToArray(section).length === 3 && !hasProp(section, "childrenType");
};

export const isCommunityMedicationsSubsectionName = (
  name: string,
): name is CommunityMedicationsSubsection => {
  return Object.values<string>(communityMedicationsSubsections).includes(name);
};
