import { useEolasNavigation } from "Components/Navigation/hooks";
import { errorStore } from "Stores/ErrorStore";
import { useHistory } from "react-router-dom";

type Props = {
  backBehaviour: "goBack" | "goHome";
};

/**
 * Hook to handle the go back behaviour for deeplinks
 */
export const useDeeplinkingGoBackBehaviour = ({ backBehaviour }: Props) => {
  const { activeTab, goToKnowledgeTab, goToOrganisationTab, goToSpaceTab } = useEolasNavigation();
  const history = useHistory();

  const handleGoBack = () => {
    if (backBehaviour === "goBack") {
      history.goBack();
    }

    if (backBehaviour === "goHome") {
      switch (activeTab) {
        case "knowledge":
          goToKnowledgeTab();
          break;
        case "organisation":
          goToOrganisationTab();
          break;
        case "spaces":
          goToSpaceTab();
          break;
        default:
          // This should never happen but just in case, we go back in history and log the error
          history.goBack();
          errorStore.captureError({
            error: new Error(
              "Tried to go home after viewing a deeplink but active tab value was unexpected",
            ),
            source: "user",
            data: { activeTab },
          });
          break;
      }
    }
  };

  return { handleGoBack };
};
