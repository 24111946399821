import { Text, SquareIcon } from "UIKit";
import { Pill } from "UIKit/Pill";
import { QuotationMarks, StarYellow } from "Assets";
import { Sizes } from "UIKit/types";
import { useTranslation } from "react-i18next";
import { NationalBodyTextColor } from "Components/MasterSearch/types";

export const TopAnswer = ({
  text,
  icon,
  title,
  pills,
  nationalBody,
  nationalBodyTextColor,
}: {
  text: string;
  pills: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  nationalBody: string;
  nationalBodyTextColor: NationalBodyTextColor;
}) => {
  const { t } = useTranslation();
  if (!text) {
    return null;
  }
  return (
    <div className="flex flex-col bg-white w-full rounded-xl shadow-md p-4 cursor-pointer border-2 border-transparent hover:border-blue-100 border-grey-300 space-y-4">
      <Pill
        value={t("copilot_top_rated")}
        className="bg-grey-100 text-grey-600 h-6 text-sm mr-1 self-start"
        size={Sizes.MEDIUM}
        icon={<StarYellow className="h-4 w-4" />}
      />
      <div className="flex flex-row">
        <QuotationMarks className="h-4 w-4 shrink-0" />
        <Text className="flex-grow mx-2 line-clamp-5 font-bold" level={2}>
          {text}
        </Text>
        <QuotationMarks className="h-4 w-4 shrink-0 rotate-180 self-end" />
      </div>
      <div className="flex flex-row">
        <SquareIcon icon={icon} />
        <div className="flex flex-col justify-center ml-3">
          <Text level={2} className="line-clamp-2 font-bold">
            {title}
          </Text>
          <Text level={2} className={`line-clamp-2 text-${nationalBodyTextColor}`}>
            {nationalBody}
          </Text>
        </div>
      </div>
      {pills}
    </div>
  );
};
