import {
  ContentItem,
  KnowledgeContentDomain,
  knowledgeContentClient,
  sectionStore,
} from "@eolas-medical/core";
import { useQuerySearch } from "shared/hooks/useQuerySearch/useQuerySearch";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";

const useSearchKnowledgeContentItems = ({
  parentId,
  domain,
  onSearchContentItem,
}: {
  parentId: string | null;
  domain: KnowledgeContentDomain;
  onSearchContentItem: (searchValue: string, noHits?: number) => void;
}) => {
  const communityId = sectionStore.data.community?.id;

  const {
    files,
    isLoading,
    searchInput,
    searchValue,
    onClearSearch,
    onSearch,
    onSearchInputChange,
  } = useQuerySearch<ContentItem>({
    key: knowledgeContentKeys.items(parentId ?? "", domain),
    queryFn: async () => {
      if (!parentId) {
        throw new Error("ParentId is null");
      }
      const searchResponse = await knowledgeContentClient.search({
        query: searchValue,
        ownerId: communityId || "",
        type: "Content",
        parentId,
      });
      return searchResponse.content;
    },
    options: {
      staleTime: 100000,
    },
    onSearchCallback: onSearchContentItem,
  });

  return {
    searchedItems: files,
    searchingItems: isLoading,
    searchValue,
    searchInput,
    onClearSearch,
    onSearch,
    onSearchInputChange,
  };
};

export default useSearchKnowledgeContentItems;
