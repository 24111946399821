import useRemoteIcons from "Hooks/useRemoteIcons";
import { ConfigureSectionModal } from "UIKit/SectionManagement";
import { FileSection, FileSectionUpdateDto } from "modules/myFiles/types";
import { useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import useEditSection from "modules/myFiles/data/useEditSection";
import { SuccessModal } from "UIKit";
import { useTranslation } from "react-i18next";
import useSections from "modules/myFiles/data/useSections";
import { observer } from "mobx-react-lite";
import { userStore } from "@eolas-medical/core";

interface EditFileSectionProps {
  section: FileSection;
  onClose?: () => void;
}
const EditFileSectionModal = observer(
  ({
    section,
    onClose = () => {
      return;
    },
  }: EditFileSectionProps) => {
    const { t } = useTranslation();
    const { remoteIcons } = useRemoteIcons();
    const user = userStore.userData;

    const { editSection, editingSection, editSectionSuccessful, editSectionError } =
      useEditSection();

    const { sectionsList } = useSections(user.id || "");

    const handleEditSection = useCallback(
      (values: FieldValues) => {
        const editedFile: FileSectionUpdateDto = {
          id: section.id,
          name: values.name,
          icon: values.icon,
        };
        editSection(editedFile);
      },
      [section, editSection],
    );

    const sectionListItems = useMemo(() => {
      const filteredSections = sectionsList
        .filter(({ name }) => name !== section.name)
        .map(({ name }) => name);

      return filteredSections;
    }, [sectionsList, section]);

    if (editSectionSuccessful) {
      return <SuccessModal onComplete={onClose} text={t("my_files_edit_section_successful")} />;
    }

    return (
      <div data-testid="edit-file-section-modal">
        <ConfigureSectionModal
          title={t("my_files_edit_section")}
          inputLabel={t("my_files_add_section_label")}
          doneLabel={t("my_files_add_section_done")}
          cancelLabel={t("my_files_add_section_cancel")}
          section={section}
          iconsList={remoteIcons}
          onCancel={onClose}
          onSubmit={handleEditSection}
          isLoading={editingSection}
          error={editSectionError}
          validationItems={sectionListItems}
          requiredError={t("my_files_error_section_name_required")}
          minLengthError={t("my_files_error_section_name_min")}
          existsError={t("my_files_error_section_name_exists")}
        />
      </div>
    );
  },
);

export default EditFileSectionModal;
