import { OperationsIcon } from "Assets";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { SectionAdminTileProps } from "../EolasSectionTile.types";
import { Dropdown } from "UIKit/FormElements/Dropdown/Dropdown";
import { IconButton } from "UIKit/Button/IconButton";
import { LabelColor } from "UIKit/EolasTile/EolasTile.types";
import { useTranslation } from "react-i18next";

export const SectionAdminTile = ({
  title,
  onClick,
  iconUrl,
  isDisabled = false,
  menuActions,
  onMenuActionSelect,
  numOfFiles,
  isDraggable,
}: SectionAdminTileProps) => {
  const { t } = useTranslation();
  const { getIconComponent } = useIconComponent("w-full h-full");
  const shouldShowMenu = menuActions.length > 0;
  const rightLabelProps: { text: string; color: LabelColor } | undefined = numOfFiles
    ? {
        text: t("general_files", { count: numOfFiles }),
        color: "transparent",
      }
    : undefined;

  return (
    <EolasTile
      shouldShowDragHandle={isDraggable}
      isDisabled={isDisabled}
      variant="rectangular"
      primaryText={title}
      rightLabel={rightLabelProps}
      rightAction={
        shouldShowMenu ? (
          <Dropdown
            dropdownToggle={
              <IconButton
                type="button"
                variant="rounded"
                icon={<OperationsIcon />}
                className="bg-transparent"
              />
            }
            options={menuActions}
            onSelect={onMenuActionSelect}
          />
        ) : undefined
      }
      icon={
        iconUrl ? (
          <div className="rounded-1 bg-grey-100 p-4 w-full h-full">{getIconComponent(iconUrl)}</div>
        ) : null
      }
      onClick={onClick}
    />
  );
};
