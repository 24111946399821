import React from "react";
import { Button, ButtonProps, Text } from "UIKit";
import { useMediaQuery } from "Hooks";

export interface FilterButtonProps extends ButtonProps {
  areFiltersApplied?: boolean;
  buttonText?: string;
  onClick: () => void;
}
export const FilterButton: React.FC<FilterButtonProps> = ({
  onClick,
  areFiltersApplied,
  buttonText = "Filter",
  ...otherProps
}: FilterButtonProps) => {
  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";
  const buttonColor = areFiltersApplied ? "blue" : "white";
  const textClass = otherProps.size === "xl" ? "text-lg" : "text-base";

  return (
    <Button {...otherProps} onClick={onClick} color={buttonColor} iconLeft="FilterIcon">
      {isDesktop && (
        <Text level={1} color="inherit" className={textClass}>
          {buttonText}
        </Text>
      )}
    </Button>
  );
};
