import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { appConfig } from "AppTypeConfig";
import { GridContainer, InnerPageWrapper, Modal, PageTitle, Text } from "UIKit";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import useOrganisation from "modules/spaces/data/useOrganisation";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";
import { useDetermineVisibleOrganisationTab } from "modules/spaces/data/useDetermineVisibleOrganisationTab";
import { useSpaceOrgMainSections } from "modules/spaces/data/useSpaceOrgMainSections";
import SpaceDetails from "../Space/components/SpaceDetails/SpaceDetails";
import EditSpaceDetailsWizard from "../Space/components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useSpaceDetailsModal } from "../Space/hooks/useSpaceDetailsModal";
import { isAccessLevelInAdminGroup, useGetAdminStatus } from "../hooks/useGetAdminStatus";

const Organisation = observer(() => {
  const { flags } = useLaunchDarkly();

  const { t } = useTranslation();
  const orgFullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const spaceFullAccessLevel = useGetAdminStatus({ activeTab: "spaces" });

  const shouldShowOrgAdminDetails =
    orgFullAccessLevel === "admin" &&
    isAccessLevelInAdminGroup(spaceFullAccessLevel) &&
    flags[LDFlagNames.SHOULD_SHOW_ORGANISATION_ADMIN_DETAILS];

  const { organisation } = useOrganisation();
  const { activatedMainSections } = useSpaceOrgMainSections({
    activeTab: "organisation",
    isOnAdminView: false,
  });
  const { isHiddenForNonAdmins, adminOf } = useDetermineVisibleOrganisationTab();

  const {
    modalState,
    handleCloseModal,
    isAdminUser,
    isPublic,
    updatingSpace,
    handleSpaceUpdated,
    setModalState,
    selectedSpace,
  } = useSpaceDetailsModal();

  return (
    <InnerPageWrapper>
      {shouldShowOrgAdminDetails && selectedSpace ? (
        <div className="space-y-12 flex flex-col justify-center items-center -mt-2.5">
          <SpaceDetails
            onClickEditSpace={() => setModalState("editSpace")}
            isAdminUser={isAdminUser}
            isPublic={isPublic}
            selectedSpace={selectedSpace}
            isOrgAdminSpace
          />
          <MasterSearch />
        </div>
      ) : (
        <div className={`mt-20 ${isHiddenForNonAdmins ? "space-y-20" : "space-y-36"}`}>
          <PageTitle
            subTitle={t("pageSubTitle")}
            title={organisation?.name ?? appConfig.appName}
            data-testid="organisation-title"
            titleClass="text-white"
            subTitleClass="text-white text-xl font-semibold"
          />
          {isHiddenForNonAdmins ? (
            <div className="flex just-center">
              <Text level={1} className="m-auto text-center text-white">
                {t("navigation_organisation_hidden_non_admins", {
                  spaceOrOrg:
                    adminOf === "both" || adminOf === "organisation" ? "organisation" : "space",
                })}
              </Text>
            </div>
          ) : null}
          <MasterSearch />
        </div>
      )}

      <GridContainer>
        <MainSectionList mainSectionList={activatedMainSections} />
      </GridContainer>

      {shouldShowOrgAdminDetails && selectedSpace ? (
        <Modal
          open={modalState !== null}
          onClose={handleCloseModal}
          hasCloseIcon
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          {modalState === "editSpace" ? (
            <EditSpaceDetailsWizard
              onUpdate={handleSpaceUpdated}
              isLoading={updatingSpace}
              isOrgAdminSpace
              selectedSpace={selectedSpace}
            />
          ) : null}
        </Modal>
      ) : null}
    </InnerPageWrapper>
  );
});

export default Organisation;
