import { useMutation } from "@tanstack/react-query";
import {
  Space,
  SpaceCreateDto,
  accessClient,
  eolasLogger,
  fileStore,
  getFilesByMainSectionsAndUpdatedAt,
  hasProp,
  profilesClient,
  sectionStore,
  spacesClient,
  userStore,
} from "@eolas-medical/core";
import { AccessRequestStatus } from "@eolas-medical/core/lib/src/clients/access/access.types";

interface CustomError {
  message: string;
}

// A function to determine if the error conforms to CustomError
function isCustomError(error: unknown): error is CustomError {
  return !!error && hasProp(error, "message") && typeof error.message === "string";
}

const useCreateSpace = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (spaceDto: SpaceCreateDto) => {
      return spacesClient.createSpace(spaceDto);
    },
    {
      onSuccess: async (newSpace: Space) => {
        const { data, status } = await accessClient.shouldAllowUserAccess(newSpace.id);
        if (
          status === AccessRequestStatus.APP_USER_CREATED ||
          status === AccessRequestStatus.EXISTING_APP_USER ||
          status === AccessRequestStatus.PUBLIC_ACCESS_GRANTED ||
          status === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
        ) {
          const { appUser, sections, app, organisation } = data;
          sectionStore.setStoreFromApi({ app, organisation, sections }, true);
          const [refreshedProfile, files] = await Promise.all([
            profilesClient.getUserProfile(userStore.userID!),
            getFilesByMainSectionsAndUpdatedAt({ updatedAt: undefined }),
          ]);

          userStore.signInOrganisation({ appUser, user: refreshedProfile });

          fileStore.upsertFiles(files, true);
        } else {
          eolasLogger.error(
            new Error("User was denied access to space after creation -- this should not happen"),
            { status, spaceId: newSpace.id },
          );
        }
      },
    },
  );

  const errorCreatingSpace = isCustomError(error) ? error : undefined;

  return {
    createSpace: mutate,
    creatingSpace: isLoading,
    errorCreatingSpace: errorCreatingSpace?.message ?? "",
    createSpaceSuccess: isSuccess,
  };
};

export default useCreateSpace;
