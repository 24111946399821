import { WoundManagementChild, isWoundManagementParentChild } from "@eolas-medical/core";
import { Collapsible } from "UIKit/Collapsible/Collapsible";
import { removeTags } from "modules/bnf/helpers";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";
import { Strings } from "./WoundCareHistoryItem.strings";
import { SummaryGroup } from "../../AdditionalContentHome/components/SummaryGroup";
import { Detail } from "./Detail";

export const WoundCareHistoryItem = ({
  historyItem,
  makeOnClick,
}: {
  historyItem: WoundManagementChild;
  makeOnClick: (nextSection: WoundManagementChild) => () => void;
}) => {
  const title = removeTags(historyItem.title);
  const subChildren = isWoundManagementParentChild(historyItem) ? historyItem.children : undefined;
  const introText = isWoundManagementParentChild(historyItem) ? historyItem.text : undefined;

  return (
    <>
      {introText ? (
        <Collapsible title={Strings.introTitle(title)}>
          <BnfContentElement htmlContent={introText} />
        </Collapsible>
      ) : null}
      {subChildren?.length ? (
        <SummaryGroup
          makeOnClick={makeOnClick}
          items={subChildren}
          itemTitleProperty="title"
          title={title}
        />
      ) : null}
      {historyItem.productGroups.map((detail, index) => {
        return <Detail detail={detail} key={detail.title + index} />;
      })}
    </>
  );
};
