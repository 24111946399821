import { useEolasForm } from "Hooks/useEolasForm";
import { useTranslation } from "react-i18next";
import { ModalWizardButtons, FormElement, FormError, Input } from "UIKit";
import { convertToLink } from "Utilities";
import { linkValidationSchema } from "../validations/schema";

export interface LinkFormProps {
  existingFileNames: string[];
  isLoading: boolean;
  errorMessage?: string;
  url?: string;
  name?: string;
  onSubmit: (args: { name: string; url: string }) => void;
  onBack: () => void;
}

export const LinkForm = ({
  existingFileNames,
  isLoading,
  errorMessage = "",
  url,
  name,
  onSubmit,
  onBack,
}: LinkFormProps) => {
  const { t } = useTranslation();
  const { control, getFieldState, handleSubmit } = useEolasForm({
    defaultValues: { name, url },
    validationSchema: linkValidationSchema(existingFileNames, name),
  });

  const handleSave = handleSubmit(({ name, url }) => {
    onSubmit({ name, url: convertToLink(url) });
  });

  return (
    <form onSubmit={handleSave} className="add-file-form">
      <FormElement
        required
        id="url"
        control={control}
        labelClassName="font-semibold"
        label={t("filePicker_url_label")}
        errorMessage={getFieldState("url").error?.message}
      >
        {(field) => (
          <Input
            {...field}
            size="lg"
            disabled={false}
            data-testid="form-element-name"
            placeholder={t("filePicker_url_placeholder")}
            className={getFieldState("url").error ? "border-red-focus input-error" : "input"}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="name"
        control={control}
        labelClassName="font-semibold"
        label={t("file_name_label")}
        errorMessage={getFieldState("name").error?.message}
      >
        {(field) => (
          <Input
            {...field}
            size="lg"
            disabled={false}
            data-testid="form-element-link"
            placeholder={t("file_name_placeholder")}
            className={getFieldState("name").error ? "border-red-focus input-error" : "input"}
          />
        )}
      </FormElement>

      <FormError error={t(errorMessage)} />

      <ModalWizardButtons
        onBack={onBack}
        isLoading={isLoading}
        backLabel={"Cancel"}
        nextLabel={"Save"}
      />
    </form>
  );
};
