import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { trackEvent } from "API/Analytics";
import { removeLastUrlPath } from "Utilities";

import {
  AssignAssessorsType,
  AttachmentItem,
  EventForm,
  EventType,
  FormElement,
  FormValues,
  PortfolioFieldNames,
} from "modules/portfolio/types";
import { createErrorNotification, formHasChanges } from "Pages/MeTab/pages/Portfolio/helpers";
import { PortfolioErrorMessages } from "Pages/MeTab/pages/Portfolio/errors";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";

import useSaveEvent from "modules/portfolio/data/useSaveEvent";
import useSubmitEvent from "modules/portfolio/data/useSubmitEvent";
import useDeleteEvent from "modules/portfolio/data/useDeleteEvent";
import useCompleteEvent from "modules/portfolio/data/useCompleteEvent";
import useExportPdfEvent from "modules/portfolio/data/useExportPdfEvent";
import useAttachmentDownload from "modules/portfolio/data/useAttachmentDownload";
import { createEventBody } from "modules/portfolio/helpers";

type EventSubmissionModalState =
  | "confirmReview"
  | "confirmExit"
  | "confirmDelete"
  | "confirmCompletion"
  | "confirmCompletionNoFeedback"
  | "confirmSave"
  | "viewFeedback"
  | "viewQRCode"
  | "assignAssessor"
  | "optionalAssessor"
  | "wizardPrompt"
  | null;

interface UseEventActions {
  eventId: string;
  eventType: EventType;
  formElements: FormElement[];
  ownerId: string;
  getValues: UseFormGetValues<FieldValues>;
  initialFormValues: FormValues;
  needFeedback: boolean;
  assignAssessorsType: AssignAssessorsType | null;
  isEventOwner: boolean;
}
const useEventActions = ({
  eventId,
  eventType,
  formElements,
  ownerId,
  getValues,
  initialFormValues,
  needFeedback = false,
  assignAssessorsType,
  isEventOwner = false,
}: UseEventActions) => {
  const { t } = useTranslation();

  const { push, replace, location } = useHistory();
  const { url } = useRouteMatch();
  const { showNotification, hideNotification } = useNotifications();

  const { saveEvent, savingEvent } = useSaveEvent();
  const { submitEvent, submitingEvent } = useSubmitEvent();
  const { completeEvent, completingEvent } = useCompleteEvent();
  const { deleteEvent, deletingEvent, deleteSuccess } = useDeleteEvent({
    onSuccess: () => push(removeLastUrlPath(url, 2)),
    onError: () => {
      showNotification(createErrorNotification(PortfolioErrorMessages.DELETE_EVENT_ERROR));
      setModalState(null);
    },
  });

  const { download } = useAttachmentDownload();

  const { exportPdfEvent, exportingPdfEvent } = useExportPdfEvent(getValues("title"));

  const [modalState, setModalState] = useState<EventSubmissionModalState>(null);

  const [draftForm, setDraftForm] = useState(initialFormValues);

  useEffect(() => {
    if (initialFormValues) {
      setDraftForm(initialFormValues);
    }
  }, [initialFormValues]);

  /**
   * Closes the modal that is opened
   */
  const onCloseModal = useCallback(() => setModalState(null), [setModalState]);

  /**
   * Opens the ConfirmDelete modal
   */
  const onClickDelete = useCallback(() => {
    setModalState("confirmDelete");
  }, []);

  /**
   * Opens the ConfirmSubmit modal
   */
  const onClickSubmit = useCallback(() => {
    setModalState("confirmReview");
  }, []);

  /**
   * Opens the ConfirmComplete modal
   */
  const onClickComplete = useCallback(() => {
    if (!needFeedback || assignAssessorsType === AssignAssessorsType.OPTIONAL_SINGLE_ASSIGN) {
      setModalState("confirmCompletionNoFeedback");
      return;
    }
    setModalState("confirmCompletion");
  }, [needFeedback, assignAssessorsType]);

  /**
   * Opens optional assign assessor or Complete event.
   */
  const onClickContinue = useCallback(() => {
    setModalState("optionalAssessor");
  }, []);

  /**
   * Opens AssignAssessor modal
   */
  const onAssignAssessor = useCallback(() => {
    setModalState("assignAssessor");
  }, []);

  /**
   * Opens the ViewQRCode modal
   */
  const onViewQRCode = useCallback(() => {
    setModalState("viewQRCode");
  }, []);

  /**
   * Opens the ViewFeedback modal
   */
  const onViewFeedback = useCallback(() => {
    setModalState("viewFeedback");
  }, []);

  /**
   * Opens the ConfirmExist modal if the form has any changes
   */
  const onLeaveEvent = useCallback(
    (url: string) => {
      if (!draftForm || !getValues) {
        push(url);
        return;
      }

      const formValues = getValues() as FormValues;
      if (formHasChanges(draftForm, formValues)) {
        setModalState("confirmExit");
      } else {
        push(url);
      }
    },
    [draftForm, getValues, push],
  );

  /**
   * Re-routes the user to the EventsList page
   */
  const onConfirmExit = useCallback(() => {
    const toRemovePath = eventId.length > 0 ? 2 : 1;
    push(removeLastUrlPath(url, toRemovePath));
  }, [push, url, eventId]);

  /**
   * Saves the event draft
   */
  const onConfirmSave = useCallback(() => {
    const formValues = getValues() as FormValues;
    const eventBody = createEventBody(eventType, ownerId, formValues, formElements);
    setModalState("confirmSave");
    saveEvent(
      { eventId, eventBody },
      {
        onSuccess: (event?: EventForm) => {
          if (event) {
            trackEvent(AnalyticsEvents.PORTFOLIO_EVENT_SAVED, { eventType });
            if (!eventId) {
              replace(`${location.pathname}/${event.id}`);
            }
          }
        },
        onError: () => {
          showNotification(createErrorNotification(PortfolioErrorMessages.SAVE_EVENT_ERROR));
          setModalState(null);
        },
      },
    );
  }, [
    eventId,
    eventType,
    ownerId,
    formElements,
    getValues,
    saveEvent,
    replace,
    location,
    showNotification,
  ]);

  /**
   * Saves the event draft and re-routes the user to the EventsList
   */
  const onSaveAndExit = useCallback(() => {
    onConfirmSave();
    onConfirmExit();
  }, [onConfirmExit, onConfirmSave]);

  /**
   * Submits the event for review
   */
  const onConfirmSubmit = useCallback(
    (email?: string | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const formValues = getValues() as FormValues;

      if (typeof email === "string") formValues.assignAssessor = email;

      const eventBody = createEventBody(eventType, ownerId, formValues, formElements);
      submitEvent(
        { eventId, eventBody },
        {
          onSuccess: (submittedEvent) => {
            trackEvent(AnalyticsEvents.PORTFOLIO_EVENT_SUBMITTED, { eventType });

            if (eventBody.eventRequest) {
              trackEvent(AnalyticsEvents.ASSIGN_ASSESSOR, { eventType });
            }

            setModalState(null);

            if (submittedEvent?.areAttachmentsShared) {
              trackEvent(AnalyticsEvents.ATTACHMENT_SHARED_WITH_REVIEWER_EVENT_SUBMISSION, {
                eventType,
              });
            }

            if (!eventId) {
              const attachmentElement = submittedEvent?.formElements.find(
                (element) => element.fieldName === PortfolioFieldNames.attachEvidence,
              );
              if (attachmentElement?.value) {
                trackEvent(AnalyticsEvents.ATTACHMENT_ADDED_EVENT_CREATION, {
                  eventType,
                });
              }
              replace(`${location.pathname}/${submittedEvent?.id}`);
            }
          },
          onError: () => {
            showNotification(createErrorNotification(PortfolioErrorMessages.SUBMIT_EVENT_ERROR));
            setModalState(null);
          },
        },
      );
    },
    [
      eventId,
      ownerId,
      location,
      replace,
      getValues,
      eventType,
      formElements,
      submitEvent,
      showNotification,
    ],
  );

  const onAssignAndSubmit = useCallback(
    (email: string) => {
      onConfirmSubmit(email);
    },
    [onConfirmSubmit],
  );

  /**
   * Deletes the event
   */
  const onConfirmDelete = useCallback(() => {
    if (eventId.length > 0) {
      deleteEvent(eventId);
    }
  }, [deleteEvent, eventId]);

  /**
   * Completes the event
   */
  const onConfirmComplete = useCallback(() => {
    const formValues = getValues() as FormValues;
    const eventBody = createEventBody(eventType, ownerId, formValues, formElements);

    completeEvent(
      { eventId, eventBody },
      {
        onSuccess: (copletedEvent) => {
          trackEvent(AnalyticsEvents.PORTFOLIO_EVENT_COMPLETED, { eventType });

          if (needFeedback && assignAssessorsType === null) {
            setModalState("viewFeedback");
          } else {
            setModalState(null);
          }

          if (!eventId) {
            const attachmentElement = copletedEvent?.formElements.find(
              (element) => element.fieldName === PortfolioFieldNames.attachEvidence,
            );
            if (attachmentElement?.value) {
              trackEvent(AnalyticsEvents.ATTACHMENT_ADDED_EVENT_CREATION, {
                eventType,
              });
            }
            replace(`${location.pathname}/${copletedEvent?.id}`);
          }
        },
        onError: () => {
          showNotification(createErrorNotification(PortfolioErrorMessages.COMPLETE_EVENT_ERROR));
          setModalState(null);
        },
      },
    );
  }, [
    completeEvent,
    showNotification,
    getValues,
    formElements,
    replace,
    ownerId,
    eventId,
    eventType,
    location,
    needFeedback,
    assignAssessorsType,
  ]);

  /**
   * Downloads an attachment from the event
   */
  const onDownloadAttachment = useCallback(
    (attachment: AttachmentItem) => {
      showNotification({
        type: "loading",
        title: t("portfolio_getting_attachment"),
        id: PortfolioConstants.ATTACHMENT_NOTIFICATION_ID,
      });
      download(attachment, {
        onSuccess: () => {
          trackEvent(AnalyticsEvents.ATTACHMENT_DOWNLOADED_EVENT_CREATION, {
            fileId: attachment.blob?.id,
          });
          hideNotification(PortfolioConstants.ATTACHMENT_NOTIFICATION_ID);
        },
        onError: () => {
          showNotification(
            createErrorNotification(PortfolioErrorMessages.DOWNLOAD_ATTACHMENT_ERROR),
          );
        },
      });
    },
    [t, download, hideNotification, showNotification],
  );

  /**
   * Opens an attachment of the event.
   */
  const onClickAttachment = useCallback(
    (attachment: AttachmentItem) => {
      if (attachment.link) {
        window.open(attachment.link.url, "_blank");
        return;
      }

      const pathLength = eventId ? 3 : 2;
      const fileViewerPath = attachment.blob?.attachmentId
        ? `attachment/${attachment.blob?.attachmentId}`
        : `file/${attachment.id}`;

      window.open(`${removeLastUrlPath(url, pathLength)}/${fileViewerPath}`, "_blank");

      trackEvent(AnalyticsEvents.ATTACHMENT_OPENED_EVENT_CREATION, {
        fileId: attachment.blob?.id,
      });
    },
    [url, eventId],
  );

  /**
   * Downloads a pdf with the event data
   */
  const onExportPdfEvent = useCallback(() => {
    if (eventId.length > 0) {
      trackEvent(
        isEventOwner
          ? AnalyticsEvents.PORTFOLIO_EXPORT_PDF_EVENT
          : AnalyticsEvents.SUPERVISOR_EXPORT_SUPERVISEES_EVENT,
      );
      exportPdfEvent(eventId, {
        onError: () => {
          showNotification(createErrorNotification(PortfolioErrorMessages.EXPORT_EVENT_PDF_ERROR));
        },
      });
    }
  }, [exportPdfEvent, showNotification, eventId, isEventOwner]);

  /**
   * Opens the Portoflio wizard prompt modal
   */
  const onClickWizardButton = useCallback(() => {
    setModalState("wizardPrompt");
  }, []);

  return {
    modalState,
    deletingEvent,
    deleteSuccess,
    completingEvent,
    savingEvent,
    submitingEvent,
    exportingPdfEvent,
    setModalState,
    onClickDelete,
    onClickSubmit,
    onClickComplete,
    onClickContinue,
    onCloseModal,
    onConfirmDelete,
    onConfirmExit,
    onConfirmSave,
    onConfirmSubmit,
    onDownloadAttachment,
    onClickAttachment,
    onLeaveEvent,
    onConfirmComplete,
    onSaveAndExit,
    onViewQRCode,
    onViewFeedback,
    onAssignAssessor,
    onAssignAndSubmit,
    onExportPdfEvent,
    onClickWizardButton,
  };
};

export default useEventActions;
