import { FeedbackForm } from "modules/portfolio/types";
import { getOrCreateFeedbackForm } from "../client/portfolio.client";
import { useEolasQuery, QueryEnvironment } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { PortfolioError } from "Pages/MeTab/pages/Portfolio/errors";
import { mapFeedbackForm } from "../helpers";
import { hasProp } from "@eolas-medical/core";

interface UseFeedbackForm {
  feedbackForm?: FeedbackForm;
  feedbackFormLoading: boolean;
}
const useFeedbackForm = (eventId: string): UseFeedbackForm => {
  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: () => getOrCreateFeedbackForm(eventId),
    queryKey: portfolioKeys.feedbackDetail(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), // 15 mins
      useErrorBoundary(error: unknown) {
        if (hasProp(error, "message")) {
          return error?.message !== PortfolioError.EVENT_ALREADY_COMPLETED;
        }

        return true;
      },
    },
  });

  return {
    feedbackForm: data ? mapFeedbackForm(data) : data,
    feedbackFormLoading: isLoading,
    ...rest,
  };
};

export default useFeedbackForm;
