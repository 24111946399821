import { useCallback, useEffect } from "react";
import useGetContentInfoItem from "modules/bnf/data/useGetContentInfoItem";
import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import { InnerPageWrapper } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { MonographContextProvider } from "../../../contexts/ MonographTypeContext";
import { SearchSortList } from "shared/components/Lists";
import { AnalyticsEvents, BnfPayload, InfoSection, MonographType } from "@eolas-medical/core";
import { InfoSectionComponent } from "../components/InfoSectionComponent";
import { useRunOnMountUnmount } from "Hooks";
import { bnfStore } from "../../../stores/bnf.store";
import { trackEvent } from "API/Analytics";

const InfoContentDetails = ({ type }: { type: MonographType }) => {
  const { idOrSid, title: paramsTitle } = useGetBnfSearchParams();
  const { itemArray, isLoading, title, infoType } = useGetContentInfoItem({ type, idOrSid });

  const renderItem = useCallback(
    (item: InfoSection) => {
      if (!idOrSid) {
        return null;
      }
      return (
        <InfoSectionComponent
          section={item}
          shouldMountExpanded={itemArray.length === 1}
          itemId={idOrSid}
          sectionId={item.id}
        />
      );
    },
    [itemArray, idOrSid],
  );

  useRunOnMountUnmount({
    onUnmount: () => {
      bnfStore.cleanupItem(idOrSid);
    },
  });

  const titleForAnalytics = paramsTitle ?? title;

  useEffect(() => {
    if (titleForAnalytics && itemArray && infoType) {
      trackEvent<BnfPayload>(
        infoType === "treatmentSummaries"
          ? AnalyticsEvents.BNF_TREATMENT_SUMMARIES_VIEWED
          : AnalyticsEvents.BNF_GUIDANCE_VIEWED,
        { name: titleForAnalytics, type },
      );
    }
  }, [itemArray, infoType, titleForAnalytics, type]);

  return (
    <InnerPageWrapper>
      <BnfHeader title={title} type={type} goBackBehaviourPreference="goBackHistory" />
      <MonographContextProvider type={type ?? "bnf"}>
        <SearchSortList<InfoSection>
          items={itemArray}
          renderItem={renderItem}
          isLoading={isLoading}
        />
      </MonographContextProvider>
    </InnerPageWrapper>
  );
};

export const InfoContentDetailsAdult = () => <InfoContentDetails type="bnf" />;
export const InfoContentDetailsChild = () => <InfoContentDetails type="bnfc" />;
