import { NationalResourceEntityType } from "@eolas-medical/core";
import { LDFlagNames, LDFlags } from "Utilities/types";
import { isBnfEnabled } from "modules/bnf/helpers";

export const getEntityTypeBasedOnFlag = (flags: LDFlags) => {
  const BASE_COPILOT_ENTITIES: NationalResourceEntityType[] = [
    NationalResourceEntityType.LEAFLET,
    NationalResourceEntityType.GUIDELINE,
    NationalResourceEntityType.CALCULATOR,
  ];

  const isSmpcEnabled = Boolean(flags[LDFlagNames.PHARMA_RESOURCES]);
  const isNiceEnabled = Boolean(flags[LDFlagNames.NICE_GUIDELINES_SECTION]);
  const isClinicalQuestionsEnabled = Boolean(
    flags[LDFlagNames.SHOULD_SHOW_COPILOT_CLINICAL_QUESTIONS],
  );

  if (isSmpcEnabled) {
    BASE_COPILOT_ENTITIES.push(NationalResourceEntityType.SMPC_SECTION);
  }
  if (isNiceEnabled) {
    BASE_COPILOT_ENTITIES.push(NationalResourceEntityType.NICE);
  }

  if (isBnfEnabled(flags)) {
    BASE_COPILOT_ENTITIES.push(NationalResourceEntityType.BNF);
    BASE_COPILOT_ENTITIES.push(NationalResourceEntityType.BNFC);
  }

  if (isClinicalQuestionsEnabled) {
    BASE_COPILOT_ENTITIES.push(NationalResourceEntityType.CLINICAL_QUESTION_FLASHCARD);
  }

  return BASE_COPILOT_ENTITIES;
};
