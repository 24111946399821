import { twMerge } from "tailwind-merge";

export type DividerDirection = "x" | "y";

interface DividerProps {
  children: React.ReactNode;
  direction?: DividerDirection;
  className?: string;
}

export const Divider = ({ children, className, direction = "y" }: DividerProps) => {
  const dividerClass = `divide-${direction}`;

  return (
    <div className={twMerge("divider divide-grey-300", dividerClass, className)}>{children}</div>
  );
};
