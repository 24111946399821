import * as icons from "Assets/Icons";
import { useGetTemplates } from "./hooks/useGetTemplates";
import { Template } from "./types";
import { TemplateCard } from "./components/TemplateCard";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

interface SpaceTemplateSelectorProps {
  onChange: (spaceTemplateId: string) => void;
  spaceTemplateId?: string;
}
const SpaceTemplateSelector = ({ onChange, spaceTemplateId }: SpaceTemplateSelectorProps) => {
  const templates: Template[] = useGetTemplates();
  trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_STARTED);
  const handleCardClick = (selectedTemplateId: string) => {
    if (selectedTemplateId === spaceTemplateId) {
      onChange("");
    } else {
      onChange(selectedTemplateId);
    }
  };

  return (
    <>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-4 max-h-50vh overflow-y-auto">
        {templates.map((template) => {
          const Icon = icons[template.iconName];

          return (
            <TemplateCard
              key={template.id}
              title={template.title}
              description={template.description}
              icon={Icon ? <Icon className="w-10 h-10" /> : null}
              onClick={() => handleCardClick(template.id)}
            />
          );
        })}
      </div>
    </>
  );
};

export default SpaceTemplateSelector;
