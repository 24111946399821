import { EventType, EventStatus, FeedbackStatus, eventTypeLabels } from "modules/portfolio/types";
import useEventIcons from "Pages/MeTab/pages/Portfolio/hooks/useEventIcons";
import { useMediaQuery } from "Hooks";
import useTimelineButtonConfig from "../FilterSection/hooks/useTimelineButtonConfig";
import { Link } from "react-router-dom";
import { Text } from "UIKit";
import { format } from "date-fns";
import { localeDateConverter } from "Utilities/helpers";
import { isFeedbackStatus } from "Pages/MeTab/pages/Portfolio/helpers";
import { useMemo } from "react";

interface TimelineCardProps {
  title: string;
  type: EventType;
  status: EventStatus | FeedbackStatus;
  date: string;
  url: string;
  disabled?: boolean;
}

const TimelineCard: React.FC<TimelineCardProps> = ({
  title,
  type,
  status,
  date,
  url,
  disabled,
}: TimelineCardProps) => {
  const icons = useEventIcons();
  const media = useMediaQuery();
  const { eventStatusButtonConfig, feedbackStatusButtonConfig } = useTimelineButtonConfig();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  const Icon = icons[type];

  const { badgeText, backgroundColor, fill, badgeTextColor } = useMemo(() => {
    let badgeText;
    let backgroundColor;
    let fill;
    let badgeTextColor;

    if (isFeedbackStatus(status)) {
      badgeText = feedbackStatusButtonConfig[status].badgeText;
      backgroundColor = feedbackStatusButtonConfig[status].backgroundColor;
      fill = feedbackStatusButtonConfig[status].fill;
      badgeTextColor = feedbackStatusButtonConfig[status].badgeTextColor;
    } else {
      badgeText = eventStatusButtonConfig[status].badgeText;
      backgroundColor = eventStatusButtonConfig[status].backgroundColor;
      fill = eventStatusButtonConfig[status].fill;
      badgeTextColor = eventStatusButtonConfig[status].badgeTextColor;
    }

    return { badgeText, backgroundColor, fill, badgeTextColor };
  }, [status, eventStatusButtonConfig, feedbackStatusButtonConfig]);

  const linkPointer = disabled ? "pointer-events-none" : "pointer-events-auto";

  return (
    <Link to={url} className={`${linkPointer}`}>
      <div
        data-testid="timeline-event-card"
        className="bg-white border border-grey-300 rounded-lg flex flex-row justify-between space-x-4 p-4 sm:p-6 h-28 sm:h-32"
      >
        <div
          className={`flex items-center rounded-3xl self-center ${backgroundColor} p-3.5 h-12 w-12 sm:h-16 sm:w-16`}
        >
          <Icon color={backgroundColor} fill={fill} className="w-10 h-10" />
        </div>

        <div style={{ flexGrow: 1 }} className="flex flex-col space-y-3">
          <div className="flex justify-between items-center">
            <div>
              <Text className="text-grey-600" level={1}>
                {eventTypeLabels[type]}
              </Text>
            </div>
            <div className="justify-end">
              <Text level={1} className="text-grey-600 text-right">
                {isDesktop ? format(new Date(date), "do MMMM yyyy") : localeDateConverter(date)}
              </Text>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center space-x-2">
            <div className="line-clamp-2">
              <Text className="text-sm sm:text-lg" level={1}>
                {title}
              </Text>
            </div>
            <div className={`${backgroundColor} w-max p-1.5 rounded-lg sm:p-2`}>
              <Text level={2} className={`${badgeTextColor} justify-self-center`}>
                {badgeText}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TimelineCard;
