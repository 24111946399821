import { useQuery } from "@tanstack/react-query";
import { sponsoredSlotQueryKeys } from "./sponsoredSlots.queryKeys";
import {
  KnowledgeContentDomains,
  SponsoredInfoSection,
  isSponsoredContentSection,
  knowledgeContentClient,
} from "@eolas-medical/core";

export const useGetAllSponsoredSlots = ({ mainSectionId }: { mainSectionId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: sponsoredSlotQueryKeys.getAllSponsoredSlots(mainSectionId),
    queryFn: async () => {
      const result = await knowledgeContentClient.getSectionsByParentId({
        parentId: mainSectionId,
        domain: KnowledgeContentDomains.sponsoredSlots,
      });
      const sponsoredSlotSections: SponsoredInfoSection[] = [];
      result.sections.forEach((section) => {
        if (isSponsoredContentSection(section)) {
          sponsoredSlotSections.push(section);
        } else {
          throw new Error("useGetAllSponsoredSlots: should all be sponsored slots sections");
        }
      });
      return sponsoredSlotSections;
    },
  });

  return { sponsoredSlotSections: data ?? [], isLoading };
};
