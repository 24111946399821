import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ContentItem, KnowledgeContentDomain, knowledgeContentClient } from "@eolas-medical/core";
import knowledgeContentKeys from "./knowledgeContent.queryKeys";
import { UseContentItemsHook } from "shared/pages/ContentRepository/context/types";

const useKnowledgeContentItems = ({
  domain,
  sectionId,
}: {
  domain: KnowledgeContentDomain;
  sectionId: string | null;
}): UseContentItemsHook => {
  const { data, isInitialLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: knowledgeContentKeys.items(sectionId ?? "", domain),
      queryFn: ({ pageParam }) => {
        return knowledgeContentClient.getContentByParentId({
          parentId: sectionId ?? "",
          pageNumber: pageParam,
        });
      },
      getNextPageParam: (lastPage) => (lastPage?.nextPage ? lastPage.nextPage : undefined),
      staleTime: 20000,
      retry: false,
      enabled: Boolean(sectionId),
    });

  const contentItems: ContentItem[] = useMemo(() => {
    return data?.pages?.flatMap((page) => page.content) ?? [];
  }, [data?.pages]);

  return {
    contentItems,
    contentItemsLoading: isInitialLoading,
    hasMoreItems: hasNextPage,
    fetchMoreItems: fetchNextPage,
    isFetchingMoreItems: isFetchingNextPage,
  };
};

export default useKnowledgeContentItems;
