import { useQuery } from "@tanstack/react-query";

import { MonographType, bnfClient } from "@eolas-medical/core";
import { BNF_CACHE_TIME, BNF_STALE_TIME } from "../constants";
import bnfKeys from "./bnf.queryKeys";
import { DrugForSelect } from "./useListInteractions";

const queryFn = async (type: MonographType) => {
  return bnfClient.getInteractants({ type });
};

export const useGetInteractants = ({ type }: { type: MonographType }) => {
  const { data, isInitialLoading, error } = useQuery({
    queryKey: bnfKeys.getInteractants(type),
    queryFn: () => queryFn(type),
    select: (returnData): DrugForSelect[] => {
      return [...returnData]
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((interactant) => ({ value: interactant.sid, label: interactant.title }));
    },
    staleTime: BNF_STALE_TIME,
    cacheTime: BNF_CACHE_TIME,
  });

  return { interactantsList: data ?? [], isInitialLoading, error };
};
