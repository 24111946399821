import { useCallback, useState } from "react";
import {
  isFileCompleted,
  sectionStore,
  EolasFile,
  CompletedContentStatus,
} from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { useSelectFile, useCompleteFile } from "Hooks";
import { VideoState } from "../components/VideoComponent";

export const useEducationPortal = () => {
  const isAdmin = sectionStore.isAdmin;

  const { error } = useRequestStatus();
  const { onCompleteFile } = useCompleteFile();
  const { onSelectFile: selectFile } = useSelectFile();

  const [modal, setModal] = useState<React.ReactNode>(null);
  const [videoState, setVideoState] = useState<VideoState | null>(null);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onSelectFile = async ({ id = "", key = "", type = "", moreInfoTwo = "" }: EolasFile) => {
    const url = await selectFile({ id, type, key, parentID: "", ownerID: "", sharedID: "" });

    if (type === "mp4") {
      setVideoState({ name: moreInfoTwo ?? "Video", url, fileId: id });
    } else if (!isFileCompleted(id)) {
      if (isAdmin) return;
      onCompleteFile(id, CompletedContentStatus.SEEN);
    }
  };

  return {
    error,
    modal,
    videoState,
    setVideoState,
    setModal,
    onSelectFile,
    onCloseModal,
  };
};
