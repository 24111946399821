import { fileStore } from "@eolas-medical/core";
import { NewsFeed } from "../types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useRunOnMountUnmount, useCompleteFile } from "Hooks";

type Props = {
  onSelectFile: (file: NewsFeed) => void;
};

export const useSelectNewsFeedItemOnMount = ({ onSelectFile }: Props) => {
  const { fileId } = useGetSearchParams({ paramList: ["fileId"] });
  const { getCompletedFileProps } = useCompleteFile();

  useRunOnMountUnmount({
    onMount: () => {
      if (fileId) {
        const file = fileStore.getFile(fileId);
        if (file) {
          const newsFeedItem = {
            ...file,
            isHighlighted: !!file.highlighted && file.highlighted !== "false",
            ...getCompletedFileProps(file),
            isRead: getCompletedFileProps(file).isCompleted,
            createdBy: file.info,
          };
          onSelectFile(newsFeedItem);
        }
      }
    },
  });
};
