import { fromAwsJSON, hasStringProp, parseEolasEditorFileMetadata } from "@eolas-medical/core";

// This is needed as metadata content may not yet be a compressed string:

export const extractEditorContent = (metadata: unknown) => {
  if (!metadata) {
    return "";
  }
  const oldContent = parseEolasEditorFileMetadata(metadata)?.eolasEditorContent ?? "";
  // Error with parsing (ie string is not compressed) makes string of 1 char:
  if (oldContent.length < 2) {
    const json = fromAwsJSON(metadata);
    if (hasStringProp(json, "eolasEditorContent")) {
      return json.eolasEditorContent;
    }
  }
  return oldContent;
};
