import { EolasMainSection } from "@eolas-medical/core";
import { MeTabIconsType } from "Assets/Icons/meTabIcons";
import { useIcons } from "Hooks";
import { SectionIcon } from "UIKit";
import React from "react";

const useIconComponent = (iconClassName?: string) => {
  const { getMainSectionIcon, getMeTabIcon } = useIcons();

  const iconClass = iconClassName ? iconClassName : "h-8 w-8 lg:h-14 lg:w-14";

  const getIconComponent = React.useCallback(
    (iconUrl: string) => {
      const isRemoteIcon = /\./.test(iconUrl);

      if (isRemoteIcon) {
        return <SectionIcon iconURL={iconUrl} iconClassname={iconClass} />;
      }

      const MeTabIcon = getMeTabIcon(iconUrl as MeTabIconsType);
      if (MeTabIcon) {
        return <MeTabIcon className={iconClass} />;
      }

      const Icon = getMainSectionIcon(iconUrl as EolasMainSection);
      if (Icon) {
        return <Icon className={iconClass} />;
      }
    },
    [getMainSectionIcon, getMeTabIcon, iconClass],
  );

  return {
    getIconComponent,
  };
};

export default useIconComponent;
