export interface WizardProgressProps {
  currentStep: number;
  maxSteps: number;
}

export const WizardProgress: React.FC<WizardProgressProps> = ({
  maxSteps,
  currentStep,
}: WizardProgressProps) => {
  return (
    <>
      <div style={{ height: "6px" }} className="absolute top-0 left-0 right-0 bg-grey-500" />
      <div
        style={{
          height: "6px",
          width: `${(100 / maxSteps) * (currentStep + 1)}%`,
        }}
        className="absolute top-0 left-0 right-0 bg-blue-500"
      />
    </>
  );
};
