import { twMerge } from "tailwind-merge";
import { SupportedTags } from "@eolas-medical/core";
import { preprocessHtml } from "./preprocessHtml";
import { makeHtmlToReactNodeParser } from "shared/functions/makeHtmlToReactNodeParser";
import bnfProcessingInstructions from "./bnfProcessingInstructions";

interface BnfContentElement {
  htmlContent: string;
  className?: string;
  addTags?: SupportedTags;
}

const { convertHtmlToReactComponents } = makeHtmlToReactNodeParser({
  mode: "defaultEolasStyles",
  overrideProcessingInstructions: bnfProcessingInstructions,
});

const BnfContentElement = ({ htmlContent, className, addTags }: BnfContentElement) => {
  const preprocessedHtml = preprocessHtml({ htmlContent, addTags });

  const parsedHtml = convertHtmlToReactComponents(preprocessedHtml);

  return <div className={twMerge("space-y-2", className)}>{parsedHtml}</div>;
};

export default BnfContentElement;
