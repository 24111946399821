import useMedicationsHighlightsSection from "modules/medications/data/useMedicationsHighlightsSection";
import ContentItems from "shared/pages/ContentRepository/ContentItems/ContentItems";
import MedicationsHighlightsProvider from "./MedicationsHighlightsProvider";

const MedicationsHighlights = () => {
  const medicationsHighlightsSection = useMedicationsHighlightsSection();

  return (
    <MedicationsHighlightsProvider>
      <ContentItems
        contentRepositoryId={medicationsHighlightsSection?.id || ""}
        subSectionId={medicationsHighlightsSection?.id || ""}
        isAdmin
        defaultAddItemWizard="flashcard-item"
        flashcardsHaveSpecialties
      />
    </MedicationsHighlightsProvider>
  );
};

export default MedicationsHighlights;
