import magicFileType from "magic-bytes.js";
import { ValidVideoExtension } from "./types";
import { BlobTypes } from "modules/generic.types";
import {
  EolasFile,
  SectionNonAppsync,
  eolasLogger,
  hasStringProp,
  sectionStore,
} from "@eolas-medical/core";

export const parseFileKey = (key: string) => {
  const typeMatch = key.match(/[^.]+$/);
  const fileType = typeMatch && typeMatch[0] ? typeMatch[0] : "";
  const groups = key.split("/");
  const fileSlug = groups[groups.length - 1];
  const parts = fileSlug?.split("-");
  const fileName = parts?.slice(0, parts.length - 1)?.join("-");

  return { fileName, fileType };
};

export const getFileExtension = (file: File | Blob) => {
  if (!hasStringProp(file, "name")) {
    return getBlobType(file.type);
  }
  const splitName = file.name.split(".");
  return splitName[splitName.length - 1];
};

export const ensureFileType = async (file: File, extension: string) => {
  const bufferArray = await file.arrayBuffer();
  const realFileTypes = magicFileType(new Uint8Array(bufferArray));

  return realFileTypes.some(
    (fileType) =>
      fileType.typename === extension.toLowerCase() ||
      fileType.extension === extension.toLowerCase() ||
      (fileType.typename === ValidVideoExtension.MP4 &&
        extension.toLowerCase() === ValidVideoExtension.MOV) ||
      (fileType.extension === ValidVideoExtension.MP4 &&
        extension.toLowerCase() === ValidVideoExtension.MOV),
  );
};

export const getFileName = (file: File) => {
  const ext = getFileExtension(file);
  return decodeURIComponent(file.name.replace(`.${ext}`, ""));
};

export const getBlobType = (mimeType: string): BlobTypes => {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "video/mp4":
      return "mp4";
    case "image/jpeg":
      return "jpeg";
    case "image/jpg":
      return "jpg";
    case "application/vnd.ms-powerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx";
    case "application/msword":
      return "doc";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx";
    case "application/vnd.ms-excel":
      return "xls";
    case "image/png":
      return "png";
    case "image/apng":
      return "apng";
    case "image/heic":
      return "heic";
    case "video/quicktime":
      return "mov";
    default:
      throw new Error(`Unsupported MIME type: ${mimeType}`);
  }
};

export const isVideoType = (type?: unknown): boolean => {
  if (type === "mp4" || type === "mov") {
    return true;
  }
  return false;
};

export const getItemOwnerType = (
  item: EolasFile | SectionNonAppsync,
): "spaces" | "organisation" | null => {
  const organisationId = sectionStore.organisationID;
  const spaceId = sectionStore.appID;

  if (item.ownerID === organisationId) {
    return "organisation";
  }

  if (item.ownerID === spaceId) {
    return "spaces";
  }

  eolasLogger.error(new Error("Item owner type not found"), { item });

  return null;
};
