import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Text, Title } from "UIKit";

export type FeedbackButtonType = {
  control?: Control<FieldValues>;
  title: string;
  fieldName: string;
};

const numbers = [1, 2, 3, 4, 5];

export const NumberFeedbackButtons: FC<FeedbackButtonType> = ({
  control,
  title,
  fieldName,
}: FeedbackButtonType) => {
  const { t } = useTranslation();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: fieldName });

  return (
    <div className="flex justify-items-center justify-self-center flex-col my-5">
      <div className="flex flex-col items-center mx-auto mt-0 mb-8">
        <Title level={8} className="text-sm sm:text-lg">
          {title}
        </Title>
        <Text level={2} className="text-grey-700">
          {t("portfolio_teaching_feedback_rating")}
        </Text>
      </div>
      <div className="flex flex-row justify-between max-w-lg w-4/5 mx-auto">
        {numbers.map((number) => {
          return (
            <Button
              key={number}
              onClick={() => (value === number ? onChange(null) : onChange(number))}
              variant={value === number ? "solid" : "outline"}
              color={value === number ? "green" : "grey"}
              className="rounded-full border-grey-400 border-2 sm:min-h-10 h-10 min-w-10 w-10 px-4"
            >
              <Text className="text-sm sm:text-lg" level={1}>
                {number}
              </Text>
            </Button>
          );
        })}
      </div>
      <Text level={3} className="text-red-700 justify-center mx-auto">
        {error?.message || " "}
      </Text>
    </div>
  );
};
