import {
  clinicalQuestionsClient,
  clinicalQuestionStore,
  CreateClinicalQuestionParams,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

const createClinicalQuestionAPI = async ({ text, notes }: CreateClinicalQuestionParams) => {
  const response = await clinicalQuestionsClient.createClinicalQuestion({
    text,
    notes,
    userID: userStore.userID,
  });
  return response;
};

export const useCreateClinicalQuestion = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const { mutateAsync, data } = useMutation({
    mutationFn: createClinicalQuestionAPI,
    mutationKey: ["createClinicalQuestion"],
    onSuccess: (response, args) => {
      clinicalQuestionStore.updateResponsesMap(response.requestID, {
        originalString: args.text,
        requestID: response.requestID,
        jobStatus: "PENDING",
        body: "",
      });
    },
    onError: (error, args) => {
      eolasLogger.error(new Error("Error creating clinical question"), {
        error,
        question: args.text,
      });
      showNotification({
        type: "error",
        description: t("create_question_error"),
        autoHideTimeout: 5000,
      });
    },
  });

  return { createClinicalQuestion: mutateAsync, data };
};
