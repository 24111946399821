import { SectionTileProps } from "./EolasSectionTile.types";
import { expectNever } from "Utilities/helpers";
import { SectionTileWithChildDetails } from "./components/SectionTileWithChildDetails";
import { SectionAdminTile } from "./components/SectionAdminTile";
import { SectionBasicTile } from "./components/SectionBasicTile";

export const EolasSectionTile = (props: SectionTileProps) => {
  const { variant } = props;

  if (variant === "withChildDetails") {
    return <SectionTileWithChildDetails {...props} />;
  }

  if (variant === "admin") {
    return <SectionAdminTile {...props} />;
  }

  if (variant === "basic") {
    return <SectionBasicTile {...props} />;
  }

  expectNever(variant);
};
