import { Route, RouteProps } from "react-router-dom";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";

import { OrganisationLevelSection } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import OrganisationAdmin from "./OrganisationAdmin";
import { OrganisationAdminRoute } from "Pages/Spaces/types";
import { ContentManagement } from "../Space/pages/ContentManagement";
import { DrugMonitoring } from "../Space/pages/DrugMonitoring";
import { ActivateMedusaPage } from "../Space/pages/ContentManagement/pages/MedusaActivationPage";
import {
  LearningManagement,
  LearningManagementSection,
} from "../Space/pages/MiniApp/LearningManagementSystem";
import { CommunicationsPortal } from "../Space/pages/MiniApp/CommunicationsPortal";
import {
  OrganisationWellbeing,
  OrganisationWellbeingSection,
} from "../Space/pages/OrganisationWellbeing";
import { GenericGuidelines, GenericGuidelinesSection } from "../Space/pages/GenericGuidelines";
import { Guidelines, GuidelinesSection } from "../Space/pages/Guidelines";
import { AdminContactsPage, AdminContactsSection } from "../Space/pages/MiniApp/Contacts";
import ManageUsers from "../Space/pages/ManageUsers/ManageUsers";
import { ExpiredFilesPage } from "Pages/Spaces/components/ExpiredFiles";
import SpaceContentRepository from "../Space/pages/SpaceContentRepository/SpaceContentRepository";
import { useGetAdminStatus } from "../hooks/useGetAdminStatus";
import { observer } from "mobx-react-lite";
import { OrgPatientLeaflets } from "../Space/pages/MiniApp/PatientLeaflets";
import { OrgPatientLeafletsSection } from "../Space/pages/MiniApp/PatientLeaflets/pages/Organisation/OrgPatientLeafletsSection";

export const adminOrganisationRoutes: Record<OrganisationAdminRoute, string> = {
  contentManagement: `/${BaseAppRoute.organisation}/:organisationName/content-management`,
  drugMonitoring: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.drugMonitoring}`,
  hospitalContacts: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.hospitalContacts}`,
  hospitalContactsSubsection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.hospitalContacts}/:sectionID`,
  hospitalGuidelines: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.hospitalGuidelines}`,
  hospitalGuidelinesSubsection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.hospitalGuidelines}/:sectionID`,
  expiredGuidelines: `/${BaseAppRoute.organisation}/:organisationName/content-management/guidelines-expired`,
  medusaMedications: `/${BaseAppRoute.organisation}/:organisationName/content-management/medusaMedications`,
  learningManagement: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.learningManagement}`,
  learningManagementSubsection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.learningManagement}/:sectionID`,
  communicationPortal: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.communicationPortal}`,
  organisationWellbeing: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.organisationWellbeing}`,
  organisationWellbeingSection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.organisationWellbeing}/:sectionID`,
  genericOrganisationGuidelines: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.genericOrganisationGuidelines}`,
  genericOrganisationGuidelinesSection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.genericOrganisationGuidelines}/:sectionID`,
  genericOrganisationGuidelinesExpired: `/${BaseAppRoute.organisation}/:organisationName/content-management/generic-expired-guidelines`,
  contentRepository: `/${BaseAppRoute.organisation}/:organisationName/content-management/content-repository/:contentRepositoryId`,
  orgPatientLeaflets: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.orgPatientLeaflets}`,
  orgPatientLeafletsSubsection: `/${BaseAppRoute.organisation}/:organisationName/content-management/${OrganisationLevelSection.orgPatientLeaflets}/:sectionID`,
};

export const OrganisationAdminRoutes = observer(() => {
  const { flags } = useLaunchDarkly();
  const showContentRepository = !!flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];
  const status = useGetAdminStatus({ activeTab: "organisation" });

  const isFullAdmin = status === "admin";

  return (
    <>
      <Route
        exact
        path={`/${BaseAppRoute.organisation}/:organisationName`}
        component={OrganisationAdmin}
      />

      {showContentRepository && (
        <Route
          path={adminOrganisationRoutes.contentRepository}
          component={SpaceContentRepository}
        />
      )}

      <Route exact path={adminOrganisationRoutes.contentManagement} component={ContentManagement} />

      <Route exact component={Guidelines} path={adminOrganisationRoutes.hospitalGuidelines} />
      <Route
        exact
        component={GuidelinesSection}
        path={adminOrganisationRoutes.hospitalGuidelinesSubsection}
      />
      <Route
        exact
        path={adminOrganisationRoutes.expiredGuidelines}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage
            mainSection={OrganisationLevelSection.hospitalGuidelines}
            {...routeProps}
          />
        )}
      />

      <Route exact component={AdminContactsPage} path={adminOrganisationRoutes.hospitalContacts} />
      <Route
        exact
        component={AdminContactsSection}
        path={adminOrganisationRoutes.hospitalContactsSubsection}
      />

      <Route exact component={DrugMonitoring} path={adminOrganisationRoutes.drugMonitoring} />

      {!flags[LDFlagNames.DISABLE_MEDUSA_ACTIVATION] && (
        <Route
          exact
          component={ActivateMedusaPage}
          path={adminOrganisationRoutes.medusaMedications}
        />
      )}

      <Route
        exact
        component={LearningManagement}
        path={adminOrganisationRoutes.learningManagement}
      />
      <Route
        exact
        component={LearningManagementSection}
        path={adminOrganisationRoutes.learningManagementSubsection}
      />

      <Route
        exact
        component={CommunicationsPortal}
        path={adminOrganisationRoutes.communicationPortal}
      />

      <Route
        exact
        component={OrganisationWellbeing}
        path={adminOrganisationRoutes.organisationWellbeing}
      />

      <Route
        exact
        component={OrganisationWellbeingSection}
        path={adminOrganisationRoutes.organisationWellbeingSection}
      />

      <Route
        exact
        component={GenericGuidelines}
        path={adminOrganisationRoutes.genericOrganisationGuidelines}
      />

      <Route
        exact
        component={GenericGuidelinesSection}
        path={adminOrganisationRoutes.genericOrganisationGuidelinesSection}
      />

      <Route
        exact
        path={adminOrganisationRoutes.genericOrganisationGuidelinesExpired}
        render={(routeProps: RouteProps) => (
          <ExpiredFilesPage
            mainSection={OrganisationLevelSection.genericOrganisationGuidelines}
            {...routeProps}
          />
        )}
      />

      <Route
        exact
        component={OrgPatientLeaflets}
        path={adminOrganisationRoutes.orgPatientLeaflets}
      />
      <Route
        exact
        component={OrgPatientLeafletsSection}
        path={adminOrganisationRoutes.orgPatientLeafletsSubsection}
      />

      {isFullAdmin ? (
        <Route
          exact
          path={`/${BaseAppRoute.organisation}/:organisationName/manageUsers`}
          component={ManageUsers}
        />
      ) : null}
    </>
  );
});
