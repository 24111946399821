import { SupportedTags } from "@eolas-medical/core";
import { wrapInTags } from "modules/bnf/helpers";

export const preprocessHtml = ({
  htmlContent,
  addTags,
}: {
  htmlContent: string;
  addTags?: SupportedTags;
}) => {
  let content = htmlContent.replace(/\n/g, "");
  if (addTags) {
    content = wrapInTags(content, addTags);
  }
  return content;
};
